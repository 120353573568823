import { useEffect } from 'react'
import LoginPage from './../LoginPage'
import { setMetadata } from '../../bugsnagMetadata'

const SessionHandler = ({ user, loading, children }) => {
  useEffect(() => {
    if (user) {
      const profile = user.profile
      profile &&
        setMetadata({
          id: profile.sub,
          email: profile.email,
          name: profile.name
        })
    }
  }, [user])

  if (loading) return children
  return !loading && (!user || user.expired) ? <LoginPage /> : children
}

export default SessionHandler
