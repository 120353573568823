import { Button, Heading, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import routes from '../../constants/routes'
import PaymentForm from '../shared/payment-form'
import { useLocationOrigin } from '../shared/payment-form/utils'
import OnboardingLayout from './Layout'

const Payment = () => {
  const history = useHistory()
  const origin = useLocationOrigin()
  const nextStep = routes.onboarding.billing

  return (
    <OnboardingLayout>
      <Stack spacing={5}>
        <Heading as="h1" size="3xl" textAlign="center">
          Payment method
        </Heading>
        <Text color="gray.500" size="md" fontWeight="normal" textAlign="center">
          {`You won't be able to access computing resources until you add a valid
          payment method.`}
        </Text>
        <PaymentForm successLink={`${origin}${nextStep}`} />
        <Button
          variant="outline"
          colorScheme="offBlack"
          onClick={() => history.push(nextStep)}
        >
          Skip step
        </Button>
      </Stack>
    </OnboardingLayout>
  )
}

export default Payment
