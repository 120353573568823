import { useEffect } from 'react'
import {
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Skeleton
} from '@chakra-ui/react'
import { Error } from '../../../shared/Error'
import { useField, useFormikContext } from 'formik'
import { TypeCards } from '../../../shared/RadioCard'
import { useConfigs } from '../../../shared/hooks/configs'
import { useCategories, useType } from '../../../shared/hooks/configs/instance'
import {
  DEFAULT_REGION,
  DEFAULT_IMAGE,
  DEFAULT_CATALOG
} from '../../../../constants/defaults'

const Type = () => {
  const [field, ,] = useField('typeSlug')

  const { values, setValues } = useFormikContext()
  const isTypeEmpty = Object.keys(values?.type).length === 0
  const tabIndex = values?.type?.category?.index

  const { instances, isValidatingConfigs, isErrorConfigs } = useConfigs({})

  const categories = useCategories({
    config: instances,
    region: values?.region
  })

  useEffect(() => {
    if (instances && isTypeEmpty && !isValidatingConfigs && !isErrorConfigs) {
      const defaultType = useType({
        config: instances,
        region: DEFAULT_REGION
      })

      setValues((prev) => ({
        ...prev,
        type: defaultType,
        typeSlug: defaultType.slug,
        typeConfigSlug: defaultType.configurations.selected.slug
      }))
    }
  }, [isValidatingConfigs])

  const handleChangeTabs = (index) =>
    setValues((prev) => ({
      ...prev,
      type: {
        ...prev.type,
        category: { ...prev.type.category, index }
      }
    }))

  const handleChangeCards = (typeSlug, category) => {
    const newType = category.types.find((item) => item.slug === typeSlug)

    setValues((prev) => ({
      ...prev,
      type: {
        ...prev.type,
        ...newType
      },
      typeSlug,
      typeConfigSlug: newType.configurations.selected.slug,
      image: DEFAULT_IMAGE,
      catalog: DEFAULT_CATALOG
    }))
  }

  return (
    <Stack spacing={8}>
      <Heading size="xl">Select Instance Type</Heading>
      {isValidatingConfigs ? (
        <Skeleton h="360px" />
      ) : isErrorConfigs ? (
        <Error />
      ) : (
        <Tabs index={tabIndex} onChange={handleChangeTabs}>
          <TabList>
            {categories?.map((category) => (
              <Tab key={category.slug}>{category.title}</Tab>
            ))}
          </TabList>
          <TabPanels>
            {categories?.map((category) => (
              <TabPanel key={category.slug}>
                <TypeCards
                  {...field}
                  activeType={field.value}
                  types={category.types}
                  onChange={(value) => handleChangeCards(value, category)}
                />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      )}
    </Stack>
  )
}

export default Type
