import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Skeleton
} from '@chakra-ui/react'
import { Error } from '../../../../shared/Error'
import { useFormikContext } from 'formik'
import {
  useProjectVersion,
  useConfigs,
  useOrgImages,
  useAccountImages,
  useOrgCatalogs,
  useAccountCatalogs,
  useOrgSnapshots,
  useAccountSnapshots
} from '../../../../shared/hooks'
import {
  generateSelectedImages,
  updateSelectedImage
} from '../../../../shared/hooks/configs/image'
import Images from './Images'
import Snapshots from './Snapshots'
import Catalogs from './Catalogs'
import { DEFAULT_OPTIONS } from '../../../../../constants/defaults'

const Image = () => {
  const [tabIndex, setTabIndex] = useState(0)

  const { orgId, projectId } = useParams()
  const { isB2 } = useProjectVersion({ orgId, projectId })

  const { values, setValues } = useFormikContext()
  const region = values.region
  const snapshotFromURL = values.params.snapshot
  const hasSnapshotFromURL = Object.keys(snapshotFromURL).length > 0
  const type = values.typeSlug?.split('_')[0] // e.g. 'nvidia3090_optimized' -> 'nvidia3090'
  const isSelectedEmpty = Object.keys(values.image.selected).length === 0

  const { isValidatingConfigs, isLoadingConfigs, isErrorConfigs } = useConfigs(
    {}
  )
  const {
    transformedImages,
    isValidatingImages,
    isLoadingImages,
    isErrorImages
  } =
    orgId && projectId
      ? useOrgImages({ orgId, projectId, region, type })
      : useAccountImages({ region, type })
  const {
    transformedCatalogs,
    isValidatingCatalogs,
    isLoadingCatalogs,
    isErrorCatalogs
  } =
    orgId && projectId
      ? useOrgCatalogs({ orgId, projectId })
      : useAccountCatalogs({})
  const {
    snapshots: _snapshots,
    isLoadingSnapshots,
    isErrorSnapshots
  } = orgId && projectId
    ? useOrgSnapshots({ orgId, projectId, perPage: 1000, region })
    : useAccountSnapshots({ perPage: 1000, region })

  const filteredSnapshots = _snapshots?.filter((s) => s.status === 'created')
  const hasBaseImages = transformedImages?.baseImages.length > 0
  const hasCloudImages = transformedImages?.cloudImages.length > 0

  const isValidating =
    isValidatingConfigs || isValidatingImages || isValidatingCatalogs
  const isLoading =
    isLoadingConfigs ||
    isLoadingImages ||
    isLoadingCatalogs ||
    isLoadingSnapshots
  const isError =
    isErrorConfigs || isErrorImages || isErrorCatalogs || isErrorSnapshots

  const selected = generateSelectedImages({
    transformedImages,
    transformedCatalogs
  })

  useEffect(() => {
    if (selected && isSelectedEmpty && !isLoading && !isError) {
      const { id, name, tab, hasDriversSupport } = updateSelectedImage({
        selected,
        snapshots: filteredSnapshots,
        hasSnapshotFromURL,
        snapshotFromURL
      })

      setValues((prev) => ({
        ...prev,
        image: {
          id,
          name,
          tab,
          selected
        },
        options: {
          ...DEFAULT_OPTIONS,
          hasDriversSupport
        }
      }))

      const index = hasBaseImages && hasCloudImages && isB2 ? 2 : 1
      hasSnapshotFromURL ? setTabIndex(index) : setTabIndex(0)
    }
  }, [selected])

  return (
    <Stack spacing={8}>
      <Heading size="xl">Select Image</Heading>
      {isValidating || isLoadingSnapshots ? (
        <Skeleton h="230px" />
      ) : isError ? (
        <Error />
      ) : (
        <Tabs index={tabIndex} onChange={setTabIndex}>
          <TabList>
            {hasBaseImages && <Tab>Images</Tab>}
            {hasCloudImages && isB2 && <Tab>Cloud Images</Tab>}
            <Tab>Snapshot</Tab>
            <Tab>Catalog</Tab>
          </TabList>
          <TabPanels>
            {hasBaseImages && (
              <TabPanel>
                <Images items={transformedImages?.baseImages} />
              </TabPanel>
            )}
            {hasCloudImages && isB2 && (
              <TabPanel>
                <Images items={transformedImages?.cloudImages} />
              </TabPanel>
            )}
            <TabPanel>
              <Snapshots items={filteredSnapshots} />
            </TabPanel>
            <TabPanel>
              <Catalogs items={transformedCatalogs} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </Stack>
  )
}

export default Image
