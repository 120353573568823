import React, { useCallback, useState } from 'react'
import { Wrap, WrapItem, Tag } from '@chakra-ui/react'
import { useFormikContext } from 'formik'

const TagItem = ({ label, value, isSelected, onClick }) => (
  <Tag
    color={isSelected ? 'white' : 'gray.700'}
    bg={isSelected ? 'blue.500' : 'gray.100'}
    _hover={{ bg: `${isSelected ? 'blue.600' : 'gray.200'}` }}
    userSelect="none"
    onClick={() => onClick(value)}
  >
    {label}
  </Tag>
)

const TagSelector = ({ name, data, defaultValue }) => {
  const { setFieldValue } = useFormikContext()
  const [selected, setSelected] = useState(defaultValue || [])

  const handleTagClick = useCallback(
    (value) => {
      let values
      if (selected.includes(value)) {
        values = selected.filter((e) => e !== value)
      } else {
        values = [...selected, value]
      }
      setSelected(values)
      setFieldValue(name, values)
    },
    [selected]
  )

  return (
    <Wrap>
      {Object.entries(data).map(([key, value]) => (
        <WrapItem key={key}>
          <TagItem
            label={value}
            value={key}
            isSelected={selected.includes(key)}
            onClick={handleTagClick}
          />
        </WrapItem>
      ))}
    </Wrap>
  )
}

export default TagSelector
