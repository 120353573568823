import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Link,
  PinInput,
  PinInputField,
  Text,
  VStack
} from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSWRConfig } from 'swr'
import { verifyPhone, verifyPhoneCode } from '../../../api/account'
import { toastMessages } from '../../../constants/toast-messages'
import { setIsVerified } from '../../../slices/ui/phoneVerification'
import ApiErrorMessage from '../ApiErrorMessage'
import { useToast } from '../Toast'
import { useIsMounted } from '../hooks/useIsMounted'

const CodeForm = ({ onVerify }) => {
  const { mutate } = useSWRConfig()
  const [code, setCode] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [resendLoading, setResendLoading] = useState(false)
  const [validationError, setValidationError] = useState()
  const [apiError, setApiError] = useState()
  const [fieldBorderColor, setFieldBorderColor] = useState()
  const phoneNumber = useSelector(
    (state) => state.ui.phoneVerification.phoneNumber
  )
  const countryId = useSelector((state) => state.ui.phoneVerification.countryId)
  const dispatch = useDispatch()
  const isMounted = useIsMounted()
  const toast = useToast()

  useEffect(() => {
    if (validationError) {
      setFieldBorderColor('red.500')
    } else {
      setFieldBorderColor('gray.200')
    }
  }, [validationError])

  const handleVerifyAction = () => {
    if (onVerify) {
      onVerify()
      toast({
        status: 'success',
        message: toastMessages.accountVerifyPhoneSuccess
      })
      dispatch(setIsVerified({ isVerified: false }))
      mutate('/account')
      window.location.hash = ''
    }
  }

  const handleVerifyCode = useCallback(
    (event) => {
      event.preventDefault()
      if (!code) {
        setValidationError('Code is required')
        return
      }
      if (code) {
        setIsLoading(true)
        verifyPhoneCode({ code, phoneNumber, country: countryId })
          .then(() => {
            handleVerifyAction()
          })
          .catch(async (e) => {
            try {
              const data = await e.response.json()
              setApiError(data.code)
            } catch (e) {
              setApiError(e)
            }
          })
          .finally(() => {
            if (isMounted()) {
              setIsLoading(false)
            }
          })
      }
    },
    [code, countryId, phoneNumber]
  )

  const handleResendCode = useCallback(() => {
    setResendLoading(true)
    verifyPhone({ phoneNumber, country: countryId })
      .then(() => {})
      .catch((error) => {
        error.response.json().then((data) => {
          setApiError(data.code)
        })
      })
      .finally(() => {
        setResendLoading(false)
      })
  }, [countryId, phoneNumber])

  return (
    <VStack spacing={4}>
      <Text color="gray.500" size="md" fontWeight="normal" textAlign="center">
        Enter the 6-digit code sent to your phone number:
        <Text as="span" color="black" ml={1}>
          {phoneNumber}.
        </Text>
        <Link
          as="button"
          colorScheme="offBlack"
          ml={2}
          onClick={() => {
            dispatch(setIsVerified({ isVerified: false }))
          }}
        >
          Change phone number
        </Link>
      </Text>
      <form onSubmit={handleVerifyCode} style={{ width: '100%' }}>
        <Box py={5}>
          <FormControl isInvalid={validationError}>
            <HStack justifyContent="center">
              <PinInput
                autoFocus={true}
                onChange={(value) => {
                  if (value) {
                    setValidationError(null)
                  }
                  setCode(value)
                }}
                isDisabled={isLoading}
              >
                <PinInputField borderColor={fieldBorderColor} />
                <PinInputField borderColor={fieldBorderColor} />
                <PinInputField borderColor={fieldBorderColor} />
                <PinInputField borderColor={fieldBorderColor} />
                <PinInputField borderColor={fieldBorderColor} />
                <PinInputField borderColor={fieldBorderColor} />
              </PinInput>
            </HStack>
            <FormErrorMessage>{validationError}</FormErrorMessage>
            <ApiErrorMessage mt={2} code={apiError} />
          </FormControl>
        </Box>
        <Button isFullWidth isLoading={isLoading} type="submit">
          Verify
        </Button>
        <Text
          color="gray.500"
          size="md"
          fontWeight="normal"
          textAlign="center"
          mt={4}
        >
          {`Didn’t get a code?`}
          <Text
            as="span"
            ml={1}
            color={resendLoading ? 'gray.500' : 'black'}
            textDecoration="underline"
            cursor={resendLoading ? 'wait' : 'pointer'}
            onClick={() => {
              if (!resendLoading) {
                handleResendCode()
              }
            }}
          >
            Resend code
          </Text>
          <Text as="span" color="gray.500" ml={1}>
            or Contact our
          </Text>
          <Link
            href={process.env.REACT_APP_TICKET_PAGE_URL}
            colorScheme="offBlack"
            ml={1}
            isExternal
          >
            Support team
          </Link>
        </Text>
      </form>
    </VStack>
  )
}

export default CodeForm
