import useSWR from 'swr'
import {
  AccountSecurityGroups,
  OrgSecurityGroups
} from '../../../api/securityGroups'
import { serialize } from '../../../helpers/serializers'
import { INITIAL_PAGE, PER_PAGE } from '../Table'
import { swrOptions } from '../../../constants/swr-options'

export function useOrgSecurityGroup({ orgId, projectId, securityGroupId }) {
  const { data, error } = useSWR(
    orgId &&
      projectId &&
      `/projects/${projectId}/security-groups/${securityGroupId}?org=${orgId}`,
    () => OrgSecurityGroups.fetchById({ orgId, projectId, securityGroupId }),
    swrOptions
  )
  return {
    securityGroup: serialize(data?.securityGroup),
    isError: error,
    isLoading: !data && !error
  }
}

export function useOrgSecurityGroups({
  orgId,
  projectId,
  page = INITIAL_PAGE,
  perPage = PER_PAGE,
  region
}) {
  const regionArg = region ? `&region=${region}` : ''
  const key = `/projects/${projectId}/security-groups?page=${page}&per_page=${perPage}&org=${orgId}${regionArg}`
  const fetcher = () =>
    OrgSecurityGroups.fetchAll({ orgId, projectId, page, perPage, region })

  const { data, isValidating, isLoading, error } = useSWR(
    key,
    fetcher,
    swrOptions
  )

  return {
    securityGroups: serialize(data),
    isValidating,
    isLoading,
    isError: Boolean(error)
  }
}

export function useOrgSecurityGroupInstances({
  projectId,
  orgId,
  securityGroupId,
  page,
  perPage
}) {
  const { data, error } = useSWR(
    orgId &&
      projectId &&
      `/projects/${projectId}/security-groups/${securityGroupId}/instances?page=${page}&perPage=${perPage}&org=${orgId}`,
    () =>
      OrgSecurityGroups.fetchInstances({
        orgId,
        projectId,
        securityGroupId,
        page,
        perPage
      }),
    swrOptions
  )
  return {
    instances: serialize(data),
    isError: error,
    isLoading: !data && !error
  }
}

export function useAccountSecurityGroup({ securityGroupId }) {
  const { data, error } = useSWR(
    `/security-groups/${securityGroupId}`,
    () => AccountSecurityGroups.fetchById({ securityGroupId }),
    swrOptions
  )
  return {
    securityGroup: serialize(data?.securityGroup),
    isError: error,
    isLoading: !data && !error
  }
}

export function useAccountSecurityGroups({
  page = INITIAL_PAGE,
  perPage = PER_PAGE,
  region
}) {
  const regionArg = region ? `&region=${region}` : ''
  const key = `/security-groups?page=${page}&per_page=${perPage}${regionArg}`
  const fetcher = () =>
    AccountSecurityGroups.fetchAll({ page, perPage, region })

  const { data, isValidating, isLoading, error } = useSWR(
    key,
    fetcher,
    swrOptions
  )

  return {
    securityGroups: serialize(data),
    isValidating,
    isLoading,
    isError: Boolean(error)
  }
}

export function useAccountSecurityGroupInstances({
  securityGroupId,
  page,
  perPage
}) {
  const { data, error } = useSWR(
    `/security-groups/${securityGroupId}/instances?page=${page}&perPage=${perPage}`,
    () =>
      AccountSecurityGroups.fetchInstances({ securityGroupId, page, perPage }),
    swrOptions
  )
  return {
    instances: serialize(data),
    isError: error,
    isLoading: !data && !error
  }
}
