import {
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Link,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { formatDate, formatDateTime } from '../../../../helpers/date'
import Accelerator from '../../../shared/Accelerator'
import { Card } from '../../../shared/Cards'
import CopyButton from '../../../shared/CopyButton'
import { Edit } from '../../../shared/Icons'
import PublicIp from '../../../shared/PublicIp'
import Region from '../../../shared/Region'
import { useCpuDescription, useProjectVersion } from '../../../shared/hooks'
import { RenameModal } from '../Modals'

const TextValue = (props) => (
  <Text color="gray.500" {...props}>
    {props.children}
  </Text>
)

const Details = ({ instance, hasPermission }) => {
  const renameDisclosure = useDisclosure()
  const { projectId, orgId } = useParams()
  const { isB2 } = useProjectVersion({ projectId, orgId })

  const {
    id,
    name,
    hostname,
    flavorName,
    region,
    createdAt,
    privateIp,
    publicIp,
    publicIpType,
    gpuCount,
    cpuCount,
    image,
    memory,
    storage,
    billingType,
    billingNextInvoiceAt,
    reverseProxyUrl,
    publicIpv6
  } = instance
  const hasGpu = gpuCount > 0
  const cpuDescription = !hasGpu
    ? useCpuDescription({ flavorName, region })
    : null
  const privateIpValue = privateIp ? `${privateIp} (dynamic)` : '-'
  const publicIpIsStatic = publicIpType === 'static' || !!instance.floatingIp
  const publicIpValue = publicIp
    ? `${publicIp} (${publicIpIsStatic ? 'static' : 'dynamic'})`
    : '-'
  const publicIpv6Value = publicIpv6 ? `${publicIpv6}` : '-'
  const billingNextInvoiceAtValue = billingNextInvoiceAt
    ? formatDate(billingNextInvoiceAt)
    : '-'

  return (
    <Card as={Stack} spacing={8}>
      <Flex justify="space-between">
        <Heading size="xl">Instance details</Heading>
        {hasPermission && (
          <>
            <Button
              colorScheme="offBlack"
              variant="outline"
              leftIcon={<Edit />}
              onClick={renameDisclosure.onOpen}
            >
              Rename Instance
            </Button>
            <RenameModal disclosure={renameDisclosure} instance={instance} />
          </>
        )}
      </Flex>
      <Grid templateColumns="1fr 1fr" columnGap={16} rowGap={5}>
        <Stack spacing={0}>
          <Text>Location</Text>
          <Region slug={region} />
        </Stack>
        <Stack spacing={0}>
          <Text>Created</Text>
          <TextValue>{formatDateTime(createdAt)}</TextValue>
        </Stack>
        <Stack spacing={0}>
          <Text>Hostname</Text>
          <TextValue noOfLines={2}>{hostname}</TextValue>
        </Stack>
        <Stack spacing={0}>
          <Text>Name</Text>
          <TextValue noOfLines={2}>{name}</TextValue>
        </Stack>
        <Stack spacing={0}>
          <Text>Instance ID</Text>
          <CopyButton color="gray.500" value={id} />
        </Stack>
        <GridItem rowSpan={1} colSpan={2}>
          <Divider />
        </GridItem>
        <Stack spacing={0}>
          <Text>Configuration</Text>
          <Grid
            templateColumns="1fr 2fr"
            columnGap={5}
            rowGap={0}
            w="fit-content"
          >
            {hasGpu && <TextValue>GPU</TextValue>}
            {hasGpu && (
              <TextValue>
                {gpuCount > 0 ? (
                  <Accelerator gpuCount={gpuCount} flavorName={flavorName} />
                ) : (
                  '-'
                )}
              </TextValue>
            )}
            <TextValue>vCPU</TextValue>
            <TextValue>
              {cpuCount} {cpuDescription}
            </TextValue>
            <TextValue>Memory</TextValue>
            <TextValue>{memory} GiB</TextValue>
            <TextValue>Disk</TextValue>
            <TextValue>{storage} GiB</TextValue>
          </Grid>
        </Stack>
        <Stack spacing={5}>
          <Stack spacing={0}>
            <Text>Image</Text>
            <TextValue>{image.name}</TextValue>
          </Stack>
          <Stack spacing={0}>
            <Text>SSH Command</Text>
            <PublicIp publicIp={publicIp} />
          </Stack>
        </Stack>
        {reverseProxyUrl && (
          <Stack spacing={0}>
            <Text>Catalog URL</Text>
            <Link
              isExternal
              href={reverseProxyUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {reverseProxyUrl}
            </Link>
          </Stack>
        )}
        <GridItem rowSpan={1} colSpan={2}>
          <Divider />
        </GridItem>
        <Stack spacing={0}>
          <Text>Private IPs</Text>
          {privateIp ? (
            <CopyButton
              color="gray.500"
              value={privateIpValue}
              copyValue={privateIp}
            />
          ) : (
            <TextValue>{privateIpValue}</TextValue>
          )}
        </Stack>
        <Stack spacing={0}>
          <Text>Public IPs</Text>
          {publicIp ? (
            <CopyButton
              color="gray.500"
              value={publicIpValue}
              copyValue={publicIp}
            />
          ) : (
            <TextValue>{publicIpValue}</TextValue>
          )}
        </Stack>

        {isB2 && (
          <Stack spacing={0}>
            <Text>Public IPv6</Text>
            {publicIpv6 ? (
              <CopyButton
                color="gray.500"
                value={publicIpv6Value}
                copyValue={publicIpv6}
              />
            ) : (
              <TextValue>{publicIpv6Value}</TextValue>
            )}
          </Stack>
        )}

        <GridItem rowSpan={1} colSpan={2}>
          <Divider />
        </GridItem>
        <Stack spacing={0}>
          <Text>Billing type</Text>
          <TextValue>{billingType}</TextValue>
        </Stack>
        <Stack spacing={0}>
          <Text>Next invoice at</Text>
          <TextValue>{billingNextInvoiceAtValue}</TextValue>
        </Stack>
      </Grid>
    </Card>
  )
}

export default Details
