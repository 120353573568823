const Checkbox = {
  parts: ['control'],
  baseStyle: {
    control: {
      alignSelf: 'baseline',
      mt: 1
    }
  },
  defaultProps: {
    colorScheme: 'blue'
  }
}

export default Checkbox
