import { Text } from '@chakra-ui/react'

const AccountError = () => (
  <Text size="sm">
    Something went wrong loading your account.
    <br />
    Please refresh this page or contact Genesis Cloud support if the error
    persists.
  </Text>
)

export default AccountError
