import { Button, Heading, Text, VStack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Link as ReactLink, useHistory } from 'react-router-dom'
import routes from '../../../../../../constants/routes'
import { withOrgPath, withOrgPathFallback } from '../../../../../../router'
import { Plus } from '../../../../../shared/Icons'
import {
  OrganizationIdStorage,
  ProjectIdStorage
} from '../../../../../shared/storage'
import Layout from './Layout'

const Success = () => {
  const history = useHistory()
  const [validatedId, setValidatedId] = useState()
  const [validatedProjectId, setValidatedProjectId] = useState()
  const id = OrganizationIdStorage.get()
  const projectId = ProjectIdStorage.get()

  useEffect(() => {
    if (id && projectId) {
      setValidatedId(id)
      setValidatedProjectId(projectId)
      OrganizationIdStorage.clear()
      ProjectIdStorage.clear()
    }
  }, [id, projectId])

  useEffect(() => {
    if (!validatedId && !id) {
      history.push(
        withOrgPathFallback(routes.dashboard.organizations.create.index)
      )
    }
  }, [id, validatedId, history])

  if (!validatedId) {
    return null
  }

  return (
    <Layout>
      <VStack spacing={8} w="100%">
        <Heading as="h1" size="3xl">
          Organization is created
        </Heading>
        <Text size="md" color="gray.500" fontWeight="normal">
          You can now invite members to this organization.
        </Text>
        <VStack spacing={4} w="100%">
          <Button
            as={ReactLink}
            to={withOrgPath(
              routes.dashboard.settings,
              validatedId,
              validatedProjectId
            )}
            isFullWidth
          >
            Manage Organization
          </Button>
          <Button
            as={ReactLink}
            colorScheme="offBlack"
            variant="outline"
            leftIcon={<Plus color="black" />}
            to={`${withOrgPath(
              routes.dashboard.members,
              validatedId,
              validatedProjectId
            )}?invite_members=true`}
            isFullWidth
          >
            Invite Members
          </Button>
        </VStack>
      </VStack>
    </Layout>
  )
}

export default Success
