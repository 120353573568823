import {
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@chakra-ui/react'
import { useField, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { types } from '../../../../../helpers/billing'
import { Card } from '../../../../shared/Cards'
import {
  transformSubs,
  transformUnusedSubs
} from '../../../../shared/hooks/configs/instance'
import LongTerm from './LongTerm'
import OnDemand from './OnDemand'
import Reservations from './Reservations'

const DEFAULT_TAB_INDEX = 0

const Payments = ({
  billing,
  unusedSubscriptions,
  configs,
  reservations,
  enableQuotaReservation
}) => {
  const { values, setValues } = useFormikContext()
  const [field, ,] = useField('billing.id')
  const [tabIndex, setTabIndex] = useState(DEFAULT_TAB_INDEX)

  const configurations = configs?.instanceTypes
    .find((item) => item.slug === values.typeSlug)
    ?.configurations?.find((item) => item.slug === values.typeConfigSlug)
  const discount = configurations?.discount?.percentOff

  const subs = transformSubs({
    pricing: configurations?.pricing,
    billingTypes: values?.type?.billingTypes,
    enableQuotaReservation
  })
  const exSubs = transformUnusedSubs(unusedSubscriptions)

  const credits = billing?.totalCredits > 0 ? billing.totalCredits : null
  const isLongTermDisabled = subs.find(
    (item) => item.name === types.longTerm
  )?.isDisabled

  useEffect(() => {
    const billing = {
      type: subs[0]?.items[0].slug,
      ...subs[0]?.items[0]
    }
    setTabIndex(DEFAULT_TAB_INDEX)
    setValues({ ...values, billing })
  }, [values?.region])

  useEffect(() => {
    if (isLongTermDisabled) {
      const billing = {
        type: subs[0].items[0].slug,
        ...subs[0].items[0]
      }
      setTabIndex(DEFAULT_TAB_INDEX)
      setValues({ ...values, billing })
    }
  }, [isLongTermDisabled])

  const onChangeRadio = (id) => {
    const exSub = exSubs.find((item) => item.id === id)
    const newSub = subs
      .find((item) => item.name === types.longTerm)
      .items.find((item) => item.id === id)
    const sub = exSub || newSub
    const billing = {
      type: sub?.slug,
      credits,
      discount,
      ...sub
    }
    setValues({ ...values, billing })
  }

  const onChangeTab = (index) => {
    if (subs[index].name === types.reserved) {
      setTabIndex(index)
      return
    }

    const selected = subs[index].items[0]
    const billing = {
      type: selected.slug,
      credits,
      discount,
      ...selected
    }
    setTabIndex(index)
    setValues({ ...values, billing })
  }

  return (
    <Card as={Stack} spacing={5} p={5}>
      <Tabs
        variant="rounded"
        colorScheme="gray"
        onChange={onChangeTab}
        index={tabIndex}
        isLazy
      >
        <TabList>
          {subs.map((sub, index) => (
            <Tab isDisabled={sub.isDisabled} key={index}>
              {sub.name}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {subs.map((sub, index) => (
            <TabPanel key={index}>
              {sub.name === types.onDemand && (
                <OnDemand
                  value={field.value}
                  onChange={onChangeRadio}
                  subs={sub.items}
                  discount={discount}
                />
              )}
              {sub.name === types.longTerm && (
                <LongTerm
                  value={field.value}
                  onChange={onChangeRadio}
                  newSubs={sub.items}
                  exSubs={exSubs}
                  credits={credits}
                  discount={discount}
                />
              )}
              {sub.name === types.reserved && (
                <Reservations reservations={reservations} />
              )}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Card>
  )
}

export default Payments
