import { HStack, Heading, Link, Skeleton, Stack, Text } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import routes from '../../../../constants/routes'
import { externalLinks } from '../../../../helpers/links'
import { withOrgPathFallback } from '../../../../router'
import SupportError from '../../../Errors/SupportError'
import { AlertInfo } from '../../../shared/Alert'
import { ReturnLink } from '../../../shared/Links'
import ResourceStatus from '../../../shared/ResourceStatus'
import {
  useAccountFilesystem,
  useHasWriteProjectPermissions,
  useOrgFilesystem
} from '../../../shared/hooks'
import Delete from './delete'
import Details from './details'

const Loading = () => (
  <Stack spacing={8}>
    <Skeleton h="45px" />
    <Skeleton h="60px" />
    <Skeleton h="445px" />
    <Skeleton h="120px" />
  </Stack>
)

const Error = () => (
  <SupportError pt={6}>
    {`Something went wrong loading the Filesystem's settings.`}
  </SupportError>
)

const Alert = () => (
  <AlertInfo>
    <Text color="blue.500">
      <Link href={externalLinks.filesystemsMount} isExternal>
        Learn how to mount your Filesystem.
      </Link>
    </Text>
  </AlertInfo>
)

const FilesystemsView = () => {
  const { orgId, projectId, filesystemId } = useParams()
  const hasWriteProjectPermissions = orgId
    ? useHasWriteProjectPermissions({
        projectId,
        orgId
      })
    : true

  const { filesystem, isLoadingFilesystem, isErrorFilesystem } = orgId
    ? useOrgFilesystem({ orgId, projectId, filesystemId })
    : useAccountFilesystem({ filesystemId })

  return (
    <Stack spacing={2}>
      <ReturnLink to={withOrgPathFallback(routes.dashboard.filesystems.index)}>
        Filesystem
      </ReturnLink>
      {isLoadingFilesystem ? (
        <Loading />
      ) : isErrorFilesystem ? (
        <Error />
      ) : (
        <Stack spacing={8}>
          <HStack spacing={3}>
            <Heading as="h1" size="4xl" noOfLines={1} maxWidth="600px">
              {filesystem.name}
            </Heading>
            <ResourceStatus status={filesystem.status} />
          </HStack>
          <Alert />
          <Details filesystem={filesystem} />
          {hasWriteProjectPermissions && <Delete filesystem={filesystem} />}
        </Stack>
      )}
    </Stack>
  )
}

export default FilesystemsView
