export {
  DEFAULT_IMAGE,
  DEFAULT_CATALOG,
  DEFAULT_METHOD,
  PASSWORD_OPTIONS,
  DEFAULT_NETWORK,
  DEFAULT_OPTIONS,
  DEFAULT_PARAMS
} from './create-instance'

export const DEFAULT_REGION = 'ARC-IS-HAF-1'
export const DEFAULT_TYPE = 'hdd'

export * from './reservations'
