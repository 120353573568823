import {
  Stack,
  Heading,
  Button,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
  useDisclosure
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { Card } from '../../../../shared/Cards'
import Region from '../../../../shared/Region'
import { ReserveModal } from '../modals'

const Summary = () => {
  const reserveDisclosure = useDisclosure()
  const { values, isSubmitting, validateForm, setErrors, setTouched } =
    useFormikContext()
  const { region, type, size, dates, prices } = values
  const noBasePrice = prices.basePrice === 0
  const noQuota = dates.quota.length === 0

  const onReserve = () => {
    validateForm().then((errors) => {
      setTouched({ dates: true })
      const hasErrors = Object.keys(errors).length > 0
      hasErrors ? setErrors(errors) : reserveDisclosure.onOpen()
    })
  }

  return (
    <Card as={Stack} spacing={8} p={7}>
      <Heading size="md">Reserved GPUs</Heading>
      <TableContainer mb={8}>
        <Table variant="card">
          <Tbody>
            <Tr>
              <Td>Location</Td>
              <Td display="flex" justifyContent="right">
                <Region slug={region} color="black" />
              </Td>
            </Tr>
            <Tr>
              <Td>GPU type</Td>
              <Td>{type.toUpperCase()}</Td>
            </Tr>
            <Tr>
              <Td>Size</Td>
              <Td>{size} GiB</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <Button
        isLoading={isSubmitting}
        isDisabled={noBasePrice || noQuota}
        onClick={onReserve}
      >
        Reserve
      </Button>
      <ReserveModal disclosure={reserveDisclosure} />
    </Card>
  )
}

export default Summary
