import { useParams } from 'react-router-dom'
import { Flex, Stack, Heading, Collapse, useDisclosure } from '@chakra-ui/react'
import { Card } from '../../../../shared/Cards'
import { ChevronDown } from '../../../../shared/Icons'
import { none, rotated } from '../../../../../constants/styles'
import Summary from './summary'
import InstancesList from './instances'

const OnDemands = () => {
  const { isOpen, onToggle } = useDisclosure()
  const { orgId } = useParams()

  return (
    <Card>
      <Flex align="flex-start">
        <Heading size="xl" cursor="pointer" flexGrow={1} onClick={onToggle}>
          On-demand resources
          <ChevronDown boxSize={6} ml={3} transform={isOpen ? rotated : none} />
        </Heading>
        <Summary orgId={orgId} show={isOpen} />
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <Stack spacing={8} mt={8}>
          <InstancesList orgId={orgId} />
        </Stack>
      </Collapse>
    </Card>
  )
}

export default OnDemands
