import { Stack, Text, HStack } from '@chakra-ui/react'
import { Iceland, Norway, Germany } from './Icons'
import { useConfigs } from './hooks/configs'
import { regions } from '../../constants/regions'

export const getRegionTitle = (slug) =>
  regions.find((region) => region.slug === slug)?.title

export const enableRegions = (regions, enabledRegions) => {
  if (regions && enabledRegions) {
    return regions.map((region) => ({
      ...region,
      enabled: enabledRegions?.includes(region.slug) ? region.enabled : false
    }))
  }
}

export const regionsIcons = {
  NO: Norway,
  IS: Iceland,
  DE: Germany
}

export const StaticRegion = ({
  slug,
  direction = 'row',
  size = 22,
  spacing = 2,
  color = 'gray.500'
}) => {
  const region = regions.find((region) => region.slug === slug)
  const Icon = regionsIcons[region?.flag]

  return (
    <Stack direction={direction} spacing={spacing} align="center">
      <Icon width={size} height={size} />
      <Text color={color}>{region.title}</Text>
    </Stack>
  )
}

export function useRegion({ slug }) {
  const { configs, isLoadingConfigs, isErrorConfigs } = useConfigs({})
  const region = configs?.regions.find((item) => item.slug === slug)
  const Icon = regionsIcons[region?.flag]

  return {
    region,
    Icon,
    isLoadingRegion: isLoadingConfigs,
    isErrorRegion: isErrorConfigs
  }
}

export const RegionTag = ({ slug }) => {
  const { region, Icon, isErrorRegion } = useRegion({ slug })

  if (isErrorRegion || !region) {
    return null
  }

  return (
    <HStack
      spacing={1}
      align="center"
      py={0}
      px={1}
      borderRadius="4px"
      bg="gray.100"
    >
      <Icon width={3} height={3} />
      <Text
        color="gray.500"
        fontSize="10px"
        textTransform="uppercase"
        fontWeight="500"
      >
        {region.title}
      </Text>
    </HStack>
  )
}

const Region = ({
  slug,
  direction = 'row',
  size = 22,
  spacing = 2,
  color = 'gray.500'
}) => {
  const { region, Icon, isErrorRegion } = useRegion({ slug })

  if (isErrorRegion || !region) {
    return null
  }

  return (
    <RegionBadge
      title={region.title}
      Icon={Icon}
      direction={direction}
      size={size}
      spacing={spacing}
      color={color}
    />
  )
}

export const RegionBadge = ({
  title,
  Icon,
  direction = 'row',
  size = 22,
  spacing = 2,
  color = 'gray.500'
}) => {
  return (
    <Stack direction={direction} spacing={spacing} align="center">
      <Icon width={size} height={size} />
      <Text color={color}>{title}</Text>
    </Stack>
  )
}

export default Region
