export function getInvitationUrl(organizationId, projectId, code) {
  return `${window.origin}/dashboard/organizations/org/${organizationId}/project/${projectId}/accept/${code}`
}

export function getInvitationUrlInternal(organizationId, projectId, code) {
  return `/dashboard/organizations/org/${organizationId}/project/${projectId}/accept/${code}`
}

export function updatePath(path) {
  const array = path.split('/')
  array.splice(array.indexOf('org') + 1, 1, ':orgId')
  array.splice(array.indexOf('project') + 1, 1, ':projectId')
  return array.join('/')
}

export function getDefaultProjectId(organization) {
  return organization?.projects?.find((project) => project.isDefault === true)
    .id
}
