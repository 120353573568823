import { Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import { useState } from 'react'
import { useAccount } from '../../../../../shared/hooks/account'
import { SkeletonTable } from '../../../../../shared/Skeletons'
import {
  INITIAL_PAGE,
  TableCell,
  TableError,
  TablePagination
} from '../../../../../shared/Table'
import { useOrganizations } from '../../../../../shared/hooks/organization'
import TableRow from './table-row'

const List = () => {
  const [page, setPage] = useState(INITIAL_PAGE)
  const {
    organizations,
    isError: isErrorOrganizations,
    isLoading: isLoadingOrganizations
  } = useOrganizations({ page })
  const isTableEmpty = organizations?.organizations.length === 0
  const { account, isErrorAccount, isLoadingAccount } = useAccount()

  const isError = isErrorOrganizations || isErrorAccount
  const isLoading = isLoadingOrganizations || isLoadingAccount

  return (
    <>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th w="40%">Organization</Th>
              <Th w="15%">Role</Th>
              <Th w="15%">2FA</Th>
              <Th w="15%">Created</Th>
              <Th w="15%" />
            </Tr>
          </Thead>
          {isLoading ? (
            <SkeletonTable rows={10} columns={4} />
          ) : isError ? (
            <TableError>
              Something went wrong loading the organizations.
            </TableError>
          ) : (
            <Tbody>
              {isTableEmpty ? (
                <TableCell>There are no Organizations yet.</TableCell>
              ) : (
                organizations?.organizations?.map((organization) => (
                  <TableRow
                    key={organization.id}
                    organization={organization}
                    account={account}
                  />
                ))
              )}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      {!isError && (
        <TablePagination
          page={page}
          setPage={setPage}
          totalCount={organizations?.totalCount}
        />
      )}
    </>
  )
}

export default List
