import { Button, HStack, Stack, useDisclosure } from '@chakra-ui/react'
import { useEffect } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import routes from '../../../../constants/routes'
import { withOrgPathFallback } from '../../../../router'
import { Plus } from '../../../shared/Icons'
import {
  useHasWriteProjectPermissions,
  useProjectVersion
} from '../../../shared/hooks'
import { useQuery } from '../../../shared/hooks/useQuery'
import { CreateModal } from './modals'
import List from './list'
import useSuspended from '../../../shared/hooks/useSuspended'

const FloatingIPs = () => {
  const disclosure = useDisclosure()
  const { create } = useQuery()

  const { orgId, projectId } = useParams()
  const { isB2, isLoadingProjectVersion } = useProjectVersion({
    projectId,
    orgId
  })
  const hasWriteProjectPermissions = orgId
    ? useHasWriteProjectPermissions({
        projectId,
        orgId
      })
    : true

  const isFloatingIPsAvailable = !isLoadingProjectVersion && isB2
  const { isSuspended } = useSuspended()

  useEffect(() => {
    if (hasWriteProjectPermissions && create) {
      disclosure.onOpen()
    }
  }, [hasWriteProjectPermissions, create])

  if (!isFloatingIPsAvailable) {
    return (
      <Redirect
        to={withOrgPathFallback(routes.dashboard.securityGroups.index)}
      />
    )
  }

  return (
    <Stack spacing={8}>
      {hasWriteProjectPermissions && (
        <HStack justifyContent="flex-end">
          <Button
            colorScheme="offBlack"
            variant="outline"
            leftIcon={<Plus color="black" />}
            onClick={disclosure.onOpen}
            isDisabled={isSuspended}
          >
            Create Floating IP
          </Button>
          <CreateModal disclosure={disclosure} />
        </HStack>
      )}
      <List hasPermission={hasWriteProjectPermissions} />
    </Stack>
  )
}

export default FloatingIPs
