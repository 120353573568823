import { useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { RegionField } from '../../../shared/Fields'
import { hasRegion, useType } from '../../../shared/hooks/configs/instance'
import {
  useConfigs,
  useOrgSnapshots,
  useAccountSnapshots
} from '../../../shared/hooks'
import {
  DEFAULT_IMAGE,
  DEFAULT_CATALOG,
  DEFAULT_NETWORK
} from '../../../../constants/defaults'

const Region = () => {
  const { values, setValues } = useFormikContext()
  const type = values.type
  const isFirstLoad = values.params.isFirstLoad

  const { orgId, projectId } = useParams()
  const { search } = useLocation()
  const snapshotIdFromURL = new URLSearchParams(search).get('image')

  const { instances, isLoadingConfigs, isErrorConfigs } = useConfigs({})

  const { snapshots, isLoadingSnapshots, isErrorSnapshots } =
    orgId && projectId
      ? useOrgSnapshots({ orgId, projectId, perPage: 999 })
      : useAccountSnapshots({ perPage: 999 })

  const isLoading = isLoadingConfigs || isLoadingSnapshots
  const isError = isErrorConfigs || isErrorSnapshots

  const handleUpdateRegion = (region, params) => {
    const result = hasRegion({ region, type })
    const newType = result ? type : useType({ region, config: instances })

    setValues((prev) => ({
      ...prev,
      region,
      type: newType,
      typeSlug: newType?.slug,
      typeConfigSlug: newType?.configurations.selected.slug,
      image: DEFAULT_IMAGE,
      catalog: DEFAULT_CATALOG,
      network: DEFAULT_NETWORK,
      volumes: [],
      params: params ?? {
        snapshot: {},
        isFirstLoad: false
      }
    }))
  }

  useEffect(() => {
    if (
      snapshotIdFromURL &&
      isFirstLoad &&
      snapshots &&
      !isLoading &&
      !isError
    ) {
      const snapshot = snapshots?.find(
        (snapshot) => snapshot.id === snapshotIdFromURL
      )

      if (snapshot) {
        const params = {
          snapshot,
          isFirstLoad: false
        }

        handleUpdateRegion(snapshot.region, params)
      }
    }
  }, [snapshotIdFromURL, snapshots])

  return <RegionField name="region" onUpdate={handleUpdateRegion} />
}

export default Region
