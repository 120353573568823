import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Text,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { formatDate } from '../../../../../../helpers/date'
import { Bin, Dots } from '../../../../../shared/Icons'
import { useAccount } from '../../../../../shared/hooks/account'
import {
  useIsOwner,
  useIsRole,
  useOrganizationWithId,
  useSelfRole
} from '../../../../../shared/hooks/organization'
import { mapBackendRole } from '../utils'
import { RemoveMemberModal } from '../modals'
import RoleCell from './role-cell'

const TableRow = ({ member }) => {
  const memberDisclosure = useDisclosure()
  const { orgId } = useParams()
  const { organization } = useOrganizationWithId(orgId)
  /* Account properties */
  const accountIsAdmin = useIsRole(organization, 'ADMIN')
  const accountIsOwner = useIsOwner(organization)
  const accountRole = useSelfRole(organization)
  const { account } = useAccount()

  /* Member properties */
  const memberIsOwner = organization.ownerId === member.accountId

  return (
    <Tr>
      <Td gridGap={2}>
        <Text color="gray.500">{member.account.name}</Text>
        {member.account.email && (
          <Text size="xs" color="gray.400">
            {member.account.email}
          </Text>
        )}
      </Td>
      <RoleCell
        accountInfo={{
          ...account,
          role: mapBackendRole(accountRole, accountIsOwner)
        }}
        memberInfo={{
          ...member,
          role: mapBackendRole(member.role, memberIsOwner)
        }}
      />
      <Td>{member.account.mfaMechanisms.length > 0 ? 'Activated' : '-'}</Td>
      <Td>{getMemberStatus(member)}</Td>
      <Td>{formatDate(member.createdAt)}</Td>
      <Td>{moment(member.updatedAt).fromNow()}</Td>
      {accountIsAdmin && (
        <Td isNumeric>
          {account?.accountId !== member.accountId && !memberIsOwner && (
            <Menu variant="table" autoSelect={false}>
              <MenuButton variant="icon" as={IconButton} icon={<Dots />} />
              <MenuList>
                <MenuItem onClick={memberDisclosure.onOpen}>
                  <Bin mr={2.5} />
                  Remove member
                </MenuItem>
                <RemoveMemberModal
                  disclosure={memberDisclosure}
                  organization={organization}
                  member={member}
                />
              </MenuList>
            </Menu>
          )}
        </Td>
      )}
    </Tr>
  )
}

const getMemberStatus = (member) => {
  let status = ''

  if (member.account.bannedAt) {
    status = 'Banned'
  } else if (member.account.suspendedAt) {
    status = 'Suspended'
  } else {
    status = 'Joined'
  }

  return status
}

export default TableRow
