import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import moment from 'moment'
import { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import * as yup from 'yup'
import routes from '../../../../constants/routes'
import { calcResourcesPrices } from '../../../../helpers/billing'
import { formatCurrency } from '../../../../helpers/currency'
import { createSnapshot } from '../../../../helpers/instances'
import { externalLinks } from '../../../../helpers/links'
import { withOrgPathFallback } from '../../../../router'
import Asterisk from '../../../shared/Asterisk'
import Price from '../../../shared/Price'
import { useToast } from '../../../shared/Toast'
import { useConfigs } from '../../../shared/hooks'
import { useAccountFF, useOrgFF } from '../../../shared/hooks/featureFlag'
import { useIsMounted } from '../../../shared/hooks/useIsMounted'

const validationSchema = yup.object().shape({
  name: yup.string().required(`Snapshot's name is required`)
})

const PriceSection = ({ storage }) => {
  const { configs, isLoadingConfigs, isErrorConfigs } = useConfigs({})

  if (isLoadingConfigs || isErrorConfigs) {
    return null
  }

  const { price, discount, totalPrice } = calcResourcesPrices({
    types: configs?.storageTypes,
    typeSlug: 'snapshot',
    configSlug: 'snapshot_storage',
    storage
  })

  const items = [
    { label: 'Price', value: `${formatCurrency(price)}/GiB/month` },
    { label: 'Snapshot size', value: `${storage} GiB` },
    ...(discount ? [{ label: 'Discount', value: `${discount}% off` }] : []),
    {
      label: 'Total cost (excl. VAT)',
      value: `${formatCurrency(totalPrice)}/month`
    }
  ]

  return <Price items={items} />
}

export const CreateSnapshotModal = ({ disclosure, instance }) => {
  const { isOpen, onClose } = disclosure
  const history = useHistory()
  const { orgId, projectId } = useParams()
  const isMounted = useIsMounted()
  const toast = useToast()
  const { featureFlag } = orgId
    ? useOrgFF({ orgId, shouldFetch: isOpen })
    : useAccountFF({ shouldFetch: isOpen })

  const handleCreateSnapshotInstance = useCallback(
    async ({ name }, { setSubmitting }) => {
      setSubmitting(true)
      await createSnapshot({
        instanceId: instance.id,
        projectId,
        orgId,
        name,
        toast
      })
      if (isMounted()) {
        setSubmitting(false)
        onClose()
      }
      history.push(withOrgPathFallback(routes.dashboard.snapshots.index))
    },
    [instance, projectId, orgId]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap={8}>
            <Heading size="xl" textAlign="center">
              Create a Snapshot of Instance <br /> “{instance.name}”
            </Heading>
            <Text
              size="md"
              textAlign="center"
              color="red.500"
              fontWeight="normal"
            >
              Make sure to stop all disk-writing processes to prevent data
              corruption.{' '}
              <Link isExternal href={externalLinks.snapshots} colorScheme="red">
                Learn more
              </Link>
            </Text>
            <Formik
              initialValues={{
                name: `${instance.name}_snapshot_${moment(new Date()).format(
                  'YYYY-MM-DD'
                )}`
              }}
              validationSchema={validationSchema}
              onSubmit={handleCreateSnapshotInstance}
            >
              {({ handleSubmit, errors, touched, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <Stack spacing={8}>
                    <FormControl isInvalid={errors.name && touched.name}>
                      <FormLabel>
                        <Asterisk />
                        Name
                      </FormLabel>
                      <Field
                        as={Input}
                        name="name"
                        type="text"
                        autoFocus={true}
                      />
                      <FormErrorMessage>{errors.name}</FormErrorMessage>
                    </FormControl>
                    {featureFlag?.enableExtendedBilling && (
                      <PriceSection storage={instance.storage} />
                    )}
                    <Stack spacing={4}>
                      <Button
                        colorScheme="blue"
                        type="submit"
                        isDisabled={isSubmitting}
                        isLoading={isSubmitting}
                      >
                        Create Snapshot
                      </Button>
                      <Button
                        colorScheme="offBlack"
                        variant="outline"
                        isDisabled={isSubmitting}
                        onClick={onClose}
                        isFullWidth
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
