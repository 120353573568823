import {
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useDisclosure,
  Wrap,
  WrapItem
} from '@chakra-ui/react'
import { createRef, useCallback, useEffect, useState } from 'react'
import { mutate } from 'swr'
import validator from 'validator'
import { Organization } from '../../../../../../api/organizations'
import { externalLinks } from '../../../../../../helpers/links'
import {
  getDefaultProjectId,
  getInvitationUrl
} from '../../../../../../helpers/organizations'
import { apiErrorHandler } from '../../../../../shared/ApiErrorHandler'
import { useOrganization } from '../../../../../shared/hooks/organization'
import { Checkmark, CopyLink, Mail } from '../../../../../shared/Icons'
import { useToast } from '../../../../../shared/Toast'

export const InvitationModal = ({ visible, onVisibilityChange }) => {
  const { organization } = useOrganization()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const textInput = createRef()
  const toast = useToast()

  const [values, setValues] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)
  const [copiedLink, setCopied] = useState(false)

  const [invitationUrl, setInvitationUrl] = useState(null)

  useEffect(() => {
    if (visible === true) {
      onOpen()
    } else if (visible === false) {
      onClose()
    }
  }, [visible])

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ' || e.key === ',') {
      handleAddValue()
    }
  }

  const handleAddValue = () => {
    if (inputValue) {
      const error = checkForError(inputValue)
      if (!error) {
        handleAddTag(inputValue)
      }
    }
  }

  const checkForError = (input) => {
    let errorMessage = ''
    if (!validator.isEmail(input)) {
      errorMessage = 'Input must be in email format'
    } else if (values.includes(input)) {
      errorMessage = 'Duplicate emails not allowed'
    }
    setErrorMessage(errorMessage)
    return errorMessage
  }

  const handleAddTag = (inputValue) => {
    setInvitationUrl(null)
    setCopied(false)
    setValues([...values, inputValue])
    setInputValue('')
  }

  const handleRemove = (tag) => {
    setInvitationUrl(null)
    setCopied(false)
    const newValues = values.filter((v) => v !== tag)
    setValues(newValues)
  }

  const handleInputChange = (e) => {
    const input = e.target.value
    const lastInput = input[input.length - 1]
    // prevent space and comma from changing the input and thus from resetting the error message
    if (lastInput !== ' ' && lastInput !== ',') {
      setInputValue(e.target.value)
      setInvitationUrl(null)
      if (errorMessage) {
        setErrorMessage(null)
      }
    }
  }

  const handleSubmit = async () => {
    // handling a value in the input field when clicking submit button
    // done here to avoid dealing with react state
    let tempValue
    let error = ''

    if (inputValue) {
      error = checkForError(inputValue)
      if (!error) {
        handleAddTag(inputValue)
        tempValue = inputValue
      }
    } else if (!inputValue && !values.length) {
      error = 'At least one email required'
    }

    if (error) {
      setErrorMessage(error)
    } else {
      setSubmitting(true)
      try {
        const { id } = organization
        let emails = [...values]
        if (tempValue) {
          emails = [...emails, tempValue]
        }
        const { code } = await Organization.createInvitation(id, emails)
        const defaultProjectId = getDefaultProjectId(organization)
        const invitationUrl = getInvitationUrl(id, defaultProjectId, code)
        setInvitationUrl(invitationUrl)
        await mutate((key) =>
          key.startsWith(`/organizations/${id}/invitations?`)
        )
      } catch (e) {
        const message = await apiErrorHandler(e)
        toast({
          status: 'error',
          message
        })
        setInvitationUrl(null)
      } finally {
        setSubmitting(false)
      }
    }
  }

  const handleSendLink = () => {
    const mailToUrl = generateMailToUrl(organization, values, invitationUrl)
    window.open(mailToUrl, '_blank', 'noopener,noreferrer')
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(invitationUrl)
    setCopied(true)
  }

  const resetForm = () => {
    setValues([])
    setInputValue('')
    setInvitationUrl('')
    setErrorMessage(null)
    setCopied(false)
  }

  const handleClose = useCallback(() => {
    onClose()
    if (onVisibilityChange) {
      onVisibilityChange(false)
    }
    resetForm()
  }, [onClose, onVisibilityChange])

  return (
    <Modal isOpen={isOpen} onClose={handleClose} px={6} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody align="center">
          <Heading mb={4} size="xl">
            Invite Members to Organization
          </Heading>
          <Text mb={8} fontSize="16px" color="gray.500">
            By default everyone is assigned a{' '}
            <Link href={externalLinks.roles} colorScheme="offBlack" isExternal>
              Member role
            </Link>
            .
          </Text>
          <Text mb={1} fontSize="16px" textAlign="start" lineHeight="150%">
            Email addresses:
          </Text>
          <FormControl isInvalid={errorMessage} mb={8}>
            <Wrap
              py={2}
              px={4}
              minHeight={20}
              borderRadius="md"
              border="1px"
              borderColor={!errorMessage ? 'gray.300' : 'red'}
              focusBorderColor={!errorMessage ? 'gray.400' : 'red'}
              _hover={{ borderColor: !errorMessage ? 'gray.400' : 'red' }}
              onClick={() => textInput.current.focus()}
            >
              {values.map((v) => (
                <Tag key={v} borderRadius="2xl">
                  <TagLabel>{v}</TagLabel>
                  <TagCloseButton
                    onClick={() => {
                      handleRemove(v)
                    }}
                  />
                </Tag>
              ))}
              <WrapItem
                as={Input}
                ref={textInput}
                variant="outline"
                height="fit-content"
                px="0"
                border="none"
                borderRadius="none"
                placeholder={!values.length ? 'Enter email addresses' : ''}
                value={inputValue}
                onKeyDown={handleKeyDown}
                onChange={handleInputChange}
              />
            </Wrap>
            <FormErrorMessage textAlign="left">{errorMessage}</FormErrorMessage>
          </FormControl>
          {invitationUrl ? (
            <>
              <Text
                mb={2}
                px={4}
                py={1}
                verticalAlign="center"
                background="gray.100"
                borderRadius="3xl"
                fontWeight="light"
                fontSize="16px"
                textAlign="start"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {invitationUrl}
              </Text>
              <Text mb={4} size="sm" color="gray.500">
                This link can be used only by the people you listed
              </Text>
              <Button
                mb={4}
                isFullWidth
                onClick={handleCopyLink}
                leftIcon={copiedLink ? <Checkmark /> : <CopyLink />}
              >
                {copiedLink ? 'Copied' : 'Copy link'}
              </Button>
              <Button
                isFullWidth
                variant="outline"
                colorScheme="offBlack"
                onClick={handleSendLink}
                leftIcon={<Mail />}
              >
                Send link by email
              </Button>
            </>
          ) : (
            <>
              <Button
                mb={4}
                isFullWidth
                disabled={errorMessage}
                isLoading={isSubmitting}
                onClick={handleSubmit}
              >
                Create link
              </Button>
              <Button
                isFullWidth
                variant="outline"
                colorScheme="offBlack"
                isDisabled={isSubmitting}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

function generateMailToUrl(organization, emails, invitationUrl) {
  const recipients = emails.join(',')
  const subject = `Genesis Cloud Organization Invitation`
  const body = `Hello friend,

I am inviting you to join my organization “${organization.name}” on Genesis Cloud.
Please use the following link to join.

${invitationUrl}

Greetings`
  return encodeURI(`mailto:${recipients}?subject=${subject}&body=${body}`)
}
