import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Textarea
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import * as yup from 'yup'
import { Project } from '../../../../../../api/projects'
import { toastMessages } from '../../../../../../constants/toast-messages'
import Asterisk from '../../../../../shared/Asterisk'
import { useToast } from '../../../../../shared/Toast'
import { useOrgFF } from '../../../../../shared/hooks/featureFlag'
import { useIsMounted } from '../../../../../shared/hooks/useIsMounted'

export const CreateModal = ({ disclosure, onCreate }) => {
  const { isOpen, onClose } = disclosure
  const isMounted = useIsMounted()
  const { orgId } = useParams()
  const { featureFlag, isLoading, isError } = useOrgFF({ orgId })
  const showInfrav1Arch =
    featureFlag?.enableInfrav1Legacy && !isLoading && !isError

  const toast = useToast()

  const schema = yup.object().shape({
    name: yup.string().required('Project name is required'),
    description: yup.string()
  })

  const handleSubmit = useCallback(
    async (
      { name, description, b2, makeDefault, legacy },
      { setSubmitting }
    ) => {
      setSubmitting(true)
      try {
        const project = await Project.create({
          orgId,
          name,
          description,
          isDefault: makeDefault,
          b2,
          legacy
        })
        await mutate((key) =>
          key.startsWith(`/organizations/${orgId}/projects?`)
        )
        toast({
          status: 'success',
          message: toastMessages.projectCreateSuccess
        })
        onCreate(project.id)
      } catch {
        toast({
          status: 'error',
          message: toastMessages.projectCreateError
        })
      } finally {
        if (isMounted()) {
          setSubmitting(false)
        }
      }
    },
    [history]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Heading size="xl" mb={8} textAlign="center">
            Create Project
          </Heading>
          <Formik
            initialValues={{
              name: '',
              description: '',
              b2: false
            }}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, errors, touched, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <Stack spacing={8}>
                  <FormControl isInvalid={errors.name && touched.name}>
                    <FormLabel>
                      <Asterisk />
                      Name
                    </FormLabel>
                    <Field
                      as={Input}
                      placeholder="Enter project name"
                      name="name"
                      type="text"
                      autoFocus={true}
                    />
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={errors.description && touched.description}
                  >
                    <FormLabel>Description</FormLabel>
                    <Field
                      as={Textarea}
                      placeholder="Enter project description"
                      name="description"
                      type="text"
                      rows="3"
                    />
                    <FormErrorMessage>{errors.description}</FormErrorMessage>
                  </FormControl>
                  <Stack spacing={0}>
                    <FormControl>
                      <Field as={Checkbox} name="makeDefault">
                        Make default
                      </Field>
                    </FormControl>
                    {showInfrav1Arch && (
                      <FormControl>
                        <Field as={Checkbox} name="legacy">
                          Create using legacy architecture
                        </Field>
                      </FormControl>
                    )}
                  </Stack>
                  <Stack spacing={4}>
                    <Button
                      type="submit"
                      isDisabled={isSubmitting}
                      isLoading={isSubmitting}
                    >
                      Save
                    </Button>
                    <Button
                      colorScheme="offBlack"
                      variant="outline"
                      isDisabled={isSubmitting}
                      onClick={onClose}
                      isFullWidth
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
