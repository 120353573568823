import moment from 'moment'

export const getCurrentTimeInSeconds = () => new Date().getTime() / 1000

export const formatDate = (dateStr) => {
  return dateStr ? moment(new Date(dateStr)).format('MMM DD, YYYY') : ''
}

export const formatDateMonth = (dateStr) => {
  return dateStr ? moment(new Date(dateStr * 1000)).format('MMM DD') : ''
}

export const formatDateTime = (dateStr) => {
  return dateStr
    ? moment(new Date(dateStr)).format('MMM DD, YYYY HH:mm:ss')
    : ''
}

export const formatDateTimeShort = (dateStr) => {
  return dateStr ? moment(new Date(dateStr)).format('YYYY-MM-DD HH:mm:ss') : ''
}

export const getMonthFromTime = (time) => {
  return moment(new Date(time)).format('MMMM')
}
export const getYearFromTime = (time) => {
  return moment(new Date(time)).format('Y')
}
