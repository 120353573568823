import { Skeleton, Stack } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { formatCurrency } from '../../../../helpers/currency'
import { Card } from '../../../shared/Cards'
import Price from '../../../shared/Price'

const Billing = ({ isLoading }) => {
  const { values } = useFormikContext()
  const { gpuCost, gpuCount, storage, storageCost } =
    values.configuration.selected
  const price = gpuCost * gpuCount + storageCost * storage
  const value = `${formatCurrency(Number(price))}/hr`

  const items = [
    { label: 'Price', value },
    {
      label: 'Total cost (excl. VAT)',
      value
    }
  ]

  return isLoading ? (
    <Skeleton h="120px" />
  ) : (
    <Card as={Stack} spacing={5} p={7}>
      <Price items={items} />
    </Card>
  )
}

export default Billing
