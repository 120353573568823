import useSWR from 'swr'
import { OrgAvailability, AccountAvailability } from '../../../api/availability'
import { swrOptions } from '../../../constants/swr-options'

export const useOrgAvailability = ({ orgId, projectId, isB2, regions }) => {
  const regionsSlugs = getRegions(regions, isB2)
  const hasRegion = regionsSlugs.length > 0

  const key = hasRegion
    ? `/projects/${projectId}/availability?org=${orgId}`
    : null
  const fetcher = () =>
    Promise.all(
      regionsSlugs.map((region) =>
        OrgAvailability.fetchAvailability({
          orgId,
          projectId,
          region
        }).catch((e) => e)
      )
    )

  const { data, isValidating, isLoading, error } = useSWR(key, fetcher, {
    ...swrOptions,
    revalidateOnFocus: false
  })

  const availability = handleAvailabilityData(data)

  return {
    availability,
    isValidatingAvailability: isValidating,
    isLoadingAvailability: !availability || isLoading,
    isErrorAvailability: Boolean(error)
  }
}

export const useAccountAvailability = ({ isB2, regions }) => {
  const regionsSlugs = getRegions(regions, isB2)
  const hasRegion = regionsSlugs.length > 0

  const key = hasRegion ? '/availability' : null
  const fetcher = () =>
    Promise.all(
      regionsSlugs.map((region) =>
        AccountAvailability.fetchAvailability({
          region
        }).catch((e) => e)
      )
    )

  const { data, isValidating, isLoading, error } = useSWR(key, fetcher, {
    ...swrOptions,
    revalidateOnFocus: false
  })

  const availability = handleAvailabilityData(data)

  return {
    availability,
    isValidatingAvailability: isValidating,
    isLoadingAvailability: !availability || isLoading,
    isErrorAvailability: Boolean(error)
  }
}

const handleAvailabilityData = (data) => {
  if (!data) return undefined

  const availability = Object.fromEntries(
    data
      .filter(({ availability }) => !!availability)
      .map(({ availability }) => [
        availability.region,
        Object.fromEntries(
          availability.instanceTypes.map(({ type, available }) => [
            type,
            available
          ])
        )
      ])
  )

  return Object.keys(availability).length > 0 ? availability : undefined
}

const getRegions = (regions, isB2) =>
  regions
    ?.filter((region) => {
      if (region.slug === 'EUC-DE-MUC-1' && !isB2) return false
      return region.enabled
    })
    .map((region) => region.slug) || []
