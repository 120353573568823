import {
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Skeleton,
  Stack,
  Text
} from '@chakra-ui/react'
import { useField, useFormikContext } from 'formik'
import { WarningError } from '../../../Errors'
import { TypeCards } from '../../../shared/RadioCard'
import { useVolumeConfigs } from '../../../shared/hooks/configs'

export const TypeField = (props) => {
  const [field, meta, helpers] = useField(props)
  const { values } = useFormikContext()
  const { types, isErrorVolumeConfigs, isLoadingVolumeConfigs } =
    useVolumeConfigs({ region: values.region })

  return (
    <Stack spacing={8}>
      <Heading size="xl">Select Type</Heading>
      {isErrorVolumeConfigs ? (
        <WarningError>{`Unable to load volume's type`}</WarningError>
      ) : (
        <FormControl isInvalid={meta.error && meta.touched}>
          {isLoadingVolumeConfigs ? (
            <Skeleton h="76px" />
          ) : (
            <TypeCards
              {...field}
              {...props}
              activeType={field.value}
              types={types}
              onChange={(value) => helpers.setValue(value)}
            />
          )}
          <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
      )}
    </Stack>
  )
}

export const FileField = () => (
  <Stack spacing="px">
    <Text>File System</Text>
    <Flex
      h={10}
      w="100%"
      color="black"
      bg="gray.100"
      borderRadius="18px"
      px={4}
      align="center"
    >
      Ext4
    </Flex>
  </Stack>
)
