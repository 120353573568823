import { useState } from 'react'
import { Stack, Button, useDisclosure } from '@chakra-ui/react'
import { OrganizationNotFound } from '../../../../Errors/OrganizationNotFound'
import {
  useIsRole,
  useOrganization
} from '../../../../shared/hooks/organization'
import { Plus } from '../../../../shared/Icons'
import { CreateModal, MembersModal } from './modals'
import List from './list'

const Projects = () => {
  const { organization } = useOrganization()
  const isAdmin = useIsRole(organization, 'ADMIN')

  const [projectId, setProjectId] = useState('')
  const createDisclosure = useDisclosure()
  const membersDisclosure = useDisclosure()

  if (!organization) return <OrganizationNotFound />

  const onCreate = (projectId) => {
    setProjectId(projectId)
    createDisclosure.onClose()
    setTimeout(() => {
      membersDisclosure.onOpen()
    }, 500)
  }

  return (
    <Stack spacing={8}>
      {isAdmin && (
        <>
          <Button
            onClick={createDisclosure.onOpen}
            variant="outline"
            colorScheme="offBlack"
            leftIcon={<Plus color="black" />}
            alignSelf="flex-end"
            width="fit-content"
          >
            Create Project
          </Button>
          <CreateModal disclosure={createDisclosure} onCreate={onCreate} />
          <MembersModal disclosure={membersDisclosure} projectId={projectId} />
        </>
      )}
      <List />
    </Stack>
  )
}

export default Projects
