import { Box, Button, HStack, Text } from '@chakra-ui/react'
import { Card } from '../../../../../shared/Cards'
import { GitHub, Google, GoogleBlack, Logo } from '../../../../../shared/Icons'
import authPrompt from '../../../../../../helpers/auth/authPrompt'

const Method = ({ active, children, ...props }) => (
  <Box
    py={3}
    pl={4}
    width={250}
    border="1px"
    borderColor="black"
    borderRadius="10px"
    opacity={active ? 1 : 0.3}
    {...props}
  >
    {children}
  </Box>
)

export const SigninCard = ({ gcSignin, googleSignin, githubSignin }) => (
  <Card>
    <Text mb={2} size="xl">
      Sign-in method
    </Text>
    <Text mb={5} size="sm" color="gray.500">
      You can use your Genesis Cloud credentials or another provider such as
      Google or GitHub to your Genesis Cloud account. We strongly recommend
      enable Two-Factor Authentication (2FA) for sign-in with Genesis Cloud
      credentials.
    </Text>
    <HStack spacing={3}>
      <Method width="auto" minWidth={250} active={gcSignin}>
        <Logo mr={1} width="24px" height="24px" />
        Genesis Cloud
        {gcSignin && ' (Enabled)'}
        {gcSignin && (
          <Button
            ml={10}
            mr={4}
            size="xs"
            variant="outline"
            colorScheme="offBlack"
            onClick={() => {
              authPrompt('change-password')
            }}
          >
            Change password
          </Button>
        )}
      </Method>
      <Method active={googleSignin}>
        {googleSignin ? <Google mr={1} /> : <GoogleBlack mr={1} />}
        Google
        {googleSignin && ' (Enabled)'}
      </Method>
      <Method active={githubSignin}>
        <GitHub mr={1} />
        GitHub
        {githubSignin && ' (Enabled)'}
      </Method>
    </HStack>
  </Card>
)
