import routes from '../constants/routes'
import {
  getOrgParam,
  getProjectParam,
  withOrgPath,
  withOrgPathFallback
} from '../router'
import { getInvitationUrlInternal } from './organizations'

const getOrgId = (id) => id || getOrgParam()

export const externalLinks = {
  solutions:
    'https://support.genesiscloud.com/a/solutions/categories/47000396795/folders/47000784484',
  attachVolumes:
    'https://support.genesiscloud.com/support/solutions/articles/47001111882-how-to-create-volumes-and-attach-detach-to-instances',
  attachInstances:
    'https://support.genesiscloud.com/support/solutions/articles/47001101437-first-steps-connecting-to-a-linux-instance-with-gpus',
  snapshots:
    'https://support.genesiscloud.com/support/solutions/articles/47001112375-taking-instance-snapshots',
  snapshotsUploadCustomImage:
    'https://support.genesiscloud.com/support/solutions/articles/47001260907',
  securityGroups:
    'https://support.genesiscloud.com/support/solutions/articles/47001117269',
  sshKeys:
    'https://support.genesiscloud.com/support/solutions/articles/47001104012-how-to-create-ssh-keys-with-putty-on-windows',
  sshKeyPair:
    'https://support.genesiscloud.com/support/solutions/articles/47001140719-how-to-securely-access-your-instance-via-ssh',
  apiTokens:
    'https://support.genesiscloud.com/support/solutions/articles/47001126146-how-to-generate-an-api-token',
  regions: 'https://developers.genesiscloud.com/regions',
  instanceDrivers:
    'https://support.genesiscloud.com/support/solutions/articles/47001122478-how-to-configure-instances-via-startup-scripts',
  twoFA:
    'https://support.genesiscloud.com/support/solutions/articles/47001217071-how-to-enable-or-disable-two-factor-authentication-2fa-',
  staticPublicIP:
    'https://support.genesiscloud.com/a/solutions/articles/47001185436',
  newTicket: 'https://support.genesiscloud.com/support/tickets/new',
  nvidia:
    'https://docs.google.com/forms/d/e/1FAIpQLSfMJ2AI1fJJpMnzdwa3FL6iw7UEIJRDu3anIg5opfh-Wl73DQ/viewform?pli=1',
  terms: 'https://genesiscloud.com/terms',
  paymentMethods:
    'https://support.genesiscloud.com/support/solutions/articles/47001108985-payment-methods',
  roles:
    'https://support.genesiscloud.com/support/solutions/articles/47001237772-overview-of-organizational-roles',
  permissions:
    'https://support.genesiscloud.com/support/solutions/articles/47001243575-overview-of-project-permissions',
  knowledgeBase: 'https://support.genesiscloud.com',
  termsOfService: 'https://genesiscloud.com/legal/terms-of-service',
  privacyPolicy: 'https://genesiscloud.com/legal/privacy-policy',
  homeInstances:
    'https://support.genesiscloud.com/support/solutions/articles/47001101437-first-steps-connecting-to-a-linux-instance-with-gpu',
  homeVolumes:
    'https://support.genesiscloud.com/support/solutions/articles/47001111882-how-to-create-volumes-and-attach-detach-it-to-instance',
  homeImages:
    'https://support.genesiscloud.com/a/solutions/articles/47001203300',
  homeSecurityGroups:
    'https://support.genesiscloud.com/support/solutions/articles/47001117269-how-to-create-security-groups-to-control-your-instance-network-traffi',
  objectStorage:
    'https://support.genesiscloud.com/support/solutions/articles/47001255868',
  blockStorageVolume:
    'https://support.genesiscloud.com/support/solutions/articles/47001259016-how-to-format-and-mount-block-storage-volume',
  filesystemsMount:
    'https://developers.genesiscloud.com/filesystems#filesystem-schema',
  filesystemsLegacy: 'mailto:bizdev@genesiscloud.com'
}

export const eventLinks = [
  {
    id: 'account_reinstated',
    hasLink: false
  },
  {
    id: 'account_suspended',
    hasLink: false
  },
  {
    id: 'add_phone_number',
    hasLink: true,
    isExternal: false,
    getURL: () =>
      withOrgPathFallback(routes.dashboard.accountSettingsVerifyPhoneNumber)
  },
  {
    id: 'assigned_credits',
    hasLink: false
  },
  {
    id: 'billing_alarm_triggered',
    hasLink: true,
    isExternal: false,
    getURL: ({ organizationId }) =>
      organizationId
        ? withOrgPath(
            routes.dashboard.instances.index,
            organizationId,
            getProjectParam() || organizationId
          )
        : routes.dashboard.instances.index
  },
  {
    id: 'expired_cc',
    hasLink: true,
    isExternal: false,
    getURL: ({ organizationId }) =>
      organizationId
        ? withOrgPath(
            routes.dashboard.billingAddCreditCard,
            organizationId,
            getProjectParam() || organizationId
          )
        : routes.dashboard.billingAddCreditCard
  },
  {
    id: 'expiring_cc',
    hasLink: true,
    isExternal: false,
    getURL: ({ organizationId }) =>
      organizationId
        ? withOrgPath(
            routes.dashboard.billingAddCreditCard,
            organizationId,
            getProjectParam() || organizationId
          )
        : routes.dashboard.billingAddCreditCard
  },
  {
    id: 'failed_payment',
    hasLink: true,
    isExternal: true,
    getURL: ({ url }) => url
  },
  {
    id: 'member_removed',
    hasLink: true,
    isExternal: true,
    getURL: () => externalLinks.solutions
  },
  {
    id: 'member_role_changed',
    hasLink: true,
    isExternal: false,
    getURL: ({ organizationId }) =>
      withOrgPath(
        routes.dashboard.members,
        getOrgId(organizationId),
        organizationId
      )
  },
  {
    id: 'new_invoice',
    hasLink: true,
    isExternal: true,
    getURL: ({ url }) => url
  },
  {
    id: 'organization_deleted',
    hasLink: true,
    isExternal: true,
    getURL: () => externalLinks.solutions
  },
  {
    id: 'organization_invite',
    hasLink: true,
    isExternal: false,
    getURL: ({ organizationId, code }) =>
      getInvitationUrlInternal(getOrgId(organizationId), organizationId, code)
  },
  {
    id: 'organization_transferred',
    hasLink: true,
    isExternal: true,
    getURL: () => externalLinks.solutions
  },
  {
    id: 'payment_pending_3ds',
    hasLink: true,
    isExternal: true,
    getURL: ({ url }) => url
  },
  {
    id: 'quotas_changed',
    hasLink: false
  },
  {
    id: 'subscription_ending',
    hasLink: false
  },
  {
    id: 'updated_terms',
    hasLink: true,
    isExternal: false,
    getURL: () => routes.updatedTerms
  }
]
