import { VisaCard, MasterCard, AmericanExpress, CreditCard } from './Icons'

const CreditCardIcon = ({ brand }) => {
  switch (brand) {
    case 'visa':
      return <VisaCard />
    case 'mastercard':
      return <MasterCard />
    case 'amex':
      return <AmericanExpress />
    default:
      return <CreditCard />
  }
}

export default CreditCardIcon
