import { Button, Flex, Heading, Stack, Text } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { fetchInstance } from '../../../../api/instances'
import { swrOptions } from '../../../../constants/swr-options'
import { serialize } from '../../../../helpers/serializers'
import { openConsole } from '../../../../helpers/instances'
import { Card } from '../../../shared/Cards'
import { useToast } from '../../../shared/Toast'
import { useProjectVersion } from '../../../shared/hooks'

const ConsoleAccess = () => {
  const { projectId, orgId } = useParams()
  const { isB2 } = useProjectVersion({ projectId, orgId })
  const { instanceId } = useParams()
  const toast = useToast()
  const { data } = useSWR(
    `/instances/${instanceId}`,
    () => fetchInstance(instanceId),
    swrOptions
  )

  const instance = useMemo(() => {
    return data && serialize(data.instance)
  }, [data])

  const canShowSerialConsole = instance?.status !== 'stopped'

  const handleOpenConsole = () => {
    openConsole(instanceId, toast)
  }

  return (
    <>
      {isB2 && (
        <Card>
          <Flex>
            <Stack>
              <Heading size="xl">Console access</Heading>
              <Text color="gray.500">
                This will open up a serial console connection to your Instance
                and is the equivalent of plugging a monitor and keyboard
                directly to your virtual server.
              </Text>
            </Stack>
            <Button
              pr={8}
              pl={8}
              ml={4}
              colorScheme="offBlack"
              variant="outline"
              onClick={handleOpenConsole}
              disabled={!canShowSerialConsole}
            >
              Launch console
            </Button>
          </Flex>
        </Card>
      )}
    </>
  )
}

export default ConsoleAccess
