import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text,
  Textarea,
  Select,
  Link
} from '@chakra-ui/react'
import { useField } from 'formik'
import { externalLinks } from '../../../../helpers/links'

export const FloatingIPField = ({ name, floatingIps }) => {
  const [field, meta] = useField(name)

  return (
    <FormControl isInvalid={meta.touched && meta.error}>
      <FormLabel>Assign Floating IP</FormLabel>
      <Select placeholder="Select" {...field}>
        {floatingIps?.map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </Select>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}

export const ScriptsField = ({ name }) => {
  const [field, meta] = useField(name)

  return (
    <FormControl isInvalid={meta.touched && meta.error}>
      <Text color="gray.500" size="sm">
        Metadata is a service provided to Genesis Cloud Instances that allows an
        Instance to access data about itself. Metadata also lets you provide
        arbitrary user data to Instances during creation, which CloudInit can
        consume during the Instance’s first boot to speed up provisioning and
        configuration.{' '}
        <Link href={externalLinks.instanceDrivers} isExternal>
          Learn more about user data
        </Link>
      </Text>
      <Textarea {...field} mt={2} autoFocus />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}
