import { mutate } from 'swr'
import { AccountSnapshots, OrgSnapshots } from '../../api/images'
import {
  AccountInstances,
  OrgInstances,
  fetchSerialConsoleSignature,
  actions
} from '../../api/instances'
import { apiErrorHandler } from '../../components/shared/ApiErrorHandler'
import { toastMessages } from '../../constants/toast-messages'

async function changeAction({ instanceId, projectId, orgId, action }) {
  if (orgId) {
    await OrgInstances.changeAction({
      orgId,
      projectId,
      instanceId,
      action
    })
    await mutate((key) =>
      key.startsWith(
        `/projects/${projectId}/instances/${instanceId}?org=${orgId}`
      )
    )
  } else {
    await AccountInstances.changeAction({
      instanceId,
      action
    })
    await mutate((key) => key.startsWith(`/instances`))
  }
}

export async function startInstance({ instanceId, projectId, orgId, toast }) {
  try {
    await changeAction({
      instanceId,
      projectId,
      orgId,
      action: actions.START
    })
    toast({
      status: 'success',
      message: toastMessages.saved
    })
  } catch (error) {
    const message = await apiErrorHandler(error)
    toast({
      status: 'error',
      message
    })
  }
}

export async function stopInstance({ instanceId, projectId, orgId, toast }) {
  try {
    await changeAction({
      instanceId,
      projectId,
      orgId,
      action: actions.STOP
    })
    toast({
      status: 'success',
      message: toastMessages.saved
    })
  } catch (error) {
    const message = await apiErrorHandler(error)
    toast({
      status: 'error',
      message
    })
  }
}

export async function resetInstance({ instanceId, projectId, orgId, toast }) {
  try {
    await changeAction({
      instanceId,
      projectId,
      orgId,
      action: actions.RESET
    })
    toast({
      status: 'success',
      message: toastMessages.saved
    })
  } catch (error) {
    const message = await apiErrorHandler(error)
    toast({
      status: 'error',
      message
    })
  }
}

export async function createSnapshot({
  instanceId,
  projectId,
  orgId,
  name,
  toast
}) {
  try {
    if (orgId) {
      await OrgSnapshots.create({
        orgId,
        projectId,
        instanceId,
        name
      })
      await mutate((key) => key.startsWith(`/projects/${projectId}/snapshots?`))
    } else {
      await AccountSnapshots.create({
        instanceId,
        name
      })
      await mutate((key) => key.startsWith(`/snapshots?`))
    }
    toast({
      status: 'success',
      message: toastMessages.snapshotCreateSuccess
    })
  } catch (error) {
    const message = await apiErrorHandler(error)
    toast({
      status: 'error',
      message
    })
  }
}

export async function deleteInstance({
  instanceId,
  projectId,
  orgId,
  toast,
  redirect
}) {
  try {
    if (orgId) {
      await OrgInstances.destroyById({
        orgId,
        projectId,
        instanceId
      })
      redirect()
      await mutate((key) => key.startsWith(`/projects/${projectId}/instances?`))
    } else {
      await AccountInstances.destroyById({
        instanceId
      })
      redirect()
      await mutate((key) => key.startsWith(`/instances?`))
    }
    toast({
      status: 'success',
      message: toastMessages.instanceDestroySuccess
    })
  } catch (error) {
    const message = await apiErrorHandler(error)
    toast({
      status: 'error',
      message
    })
  }
}

export const getRegionBySlug = (settings, slug) => {
  return settings.data.regions.filter((r) => r.slug === slug)[0]
}

export const getTypeByFlavorName = (settings, flavorName) => {
  return settings.instance_types.filter((r) => r.flavor_name === flavorName)[0]
}

export const status = {
  enqueued: 'enqueued', // legacy
  creating: 'creating',
  updating: 'updating',
  created: 'created',
  pendingPayment: 'pending_payment',
  pendingDelete: 'pending_delete',
  active: 'active',
  stopping: 'stopping',
  stopped: 'stopped',
  starting: 'starting',
  resetting: 'resetting',
  shutoff: 'shutoff',
  error: 'error',
  deleting: 'deleting'
}

export function getBadgeColor(instanceStatus) {
  switch (instanceStatus) {
    case status.active:
    case status.created:
      return 'green.700'
    case status.enqueued:
    case status.creating:
    case status.pendingPayment:
    case status.pendingDelete:
    case status.stopping:
    case status.stopped:
    case status.shutoff:
    case status.starting:
    case status.resetting:
    case status.deleting:
    case status.updating:
      return 'gray.500'
    case status.error:
      return 'red.500'
    default:
      return 'blue.500'
  }
}

export const instanceAnimatedStates = [
  status.enqueued,
  status.creating,
  status.updating,
  status.pendingDelete,
  status.deleting,
  status.starting,
  status.stopping,
  status.resetting
]

export const BILLING_TYPE_ON_DEMAND = 'on-demand'
export const BILLING_TYPE_RESERVED = 'reserved'
export const BILLING_TYPE_PREPAID_MONTHLY = 'prepaid-monthly'
export const BILLING_TYPE_PREPAID_3_MONTH = 'prepaid-3-month'
export const BILLING_TYPE_PREPAID_6_MONTH = 'prepaid-6-month'
export const BILLING_TYPE_PREPAID_12_MONTH = 'prepaid-12-month'
export const SUBSCRIPTION_TYPE_EXISTING = 'existing'
export const SUBSCRIPTION_TYPE_NEW = 'new'

export const getBorderColor = (availabilityError, capacityError) =>
  availabilityError && capacityError
    ? 'red.500'
    : availabilityError
    ? 'orange.500'
    : capacityError
    ? 'red.500'
    : 'gray.300'

export const openConsole = (instanceId, toast) => {
  const windowFeatures =
    'width=1200,height=700,top=200,left=100,resizable=yes,popup=true'
  // workaround for safari in ipad
  const consoleWindow = window.open('', '_blank', windowFeatures)

  fetchSerialConsoleSignature(instanceId)
    .then((response) => {
      const url = new URL(response.websocket_endpoint, 'http://placeholder')
      consoleWindow.location = `/serial-console${url.search}`
    })
    .catch((error) => {
      error.response.json().then((data) => {
        toast({
          status: 'error',
          message: data.message
        })
      })
    })
}
