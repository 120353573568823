import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack
} from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import { AccountVolumes, OrgVolumes } from '../../../../api/volumes'
import { toastMessages } from '../../../../constants/toast-messages'
import { useToast } from '../../../shared/Toast'
import { useIsMounted } from '../../../shared/hooks/useIsMounted'

export const DetachModal = ({ volume, disclosure, instanceId }) => {
  const { projectId, orgId } = useParams()
  const { isOpen, onClose } = disclosure
  const [isSubmitting, setIsSubmitting] = useState()
  const isMounted = useIsMounted()
  const toast = useToast()

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true)
    try {
      const volumeId = volume.id
      if (orgId) {
        await OrgVolumes.detachById({
          orgId,
          projectId,
          volumeId,
          instanceId
        })
        await mutate(
          `/projects/${projectId}/instances/${instanceId}/volumes?org=${orgId}`
        )
        await mutate(`/projects/${projectId}/volumes/${volumeId}?org=${orgId}`)
      } else {
        await AccountVolumes.detachById({ volumeId, instanceId })
        await mutate(`/instances/${instanceId}/volumes`)
        await mutate(`/volumes/${volumeId}`)
      }
      onClose()
      toast({
        status: 'success',
        message: toastMessages.saved
      })
    } catch {
      toast({
        status: 'error',
        message: toastMessages.notSaved
      })
    } finally {
      if (isMounted()) {
        setIsSubmitting(false)
      }
    }
  }, [volume])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <Heading size="xl" mb={4} textAlign="center">
              Are you sure you want to detach “{volume.name}”?
            </Heading>
            <Stack spacing={4}>
              <Button
                type="submit"
                isLoading={isSubmitting}
                colorScheme="red"
                onClick={handleSubmit}
              >
                Detach Instance
              </Button>
              <Button
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isSubmitting}
                onClick={onClose}
                isFullWidth
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
