import { Grid, Text } from '@chakra-ui/react'

const Info = ({ info }) => {
  const { gpu, vcpu, memory, storage } = info

  return (
    <Grid
      templateColumns="1fr 1fr 2fr"
      p={5}
      border="1px"
      borderColor="gray.300"
      borderRadius="10px"
    >
      {gpu && (
        <>
          <Text>GPU</Text>
          <Text fontWeight={600}>{gpu.value}</Text>
          <Text color="gray.500" isTruncated>
            {gpu.description}
          </Text>
        </>
      )}
      <Text>vCPU</Text>
      <Text fontWeight={600}>{vcpu.value}</Text>
      <Text color="gray.500" isTruncated>
        {vcpu.description}
      </Text>
      <Text>Memory</Text>
      <Text fontWeight={600}>{memory.value} GiB</Text>
      <Text color="gray.500">{memory.description}</Text>
      <Text>Disk</Text>
      <Text fontWeight={600}>{storage.value} GiB</Text>
      <Text color="gray.500">SSD</Text>
    </Grid>
  )
}

export default Info
