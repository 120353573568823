import { Heading, Skeleton, Stack } from '@chakra-ui/react'
import { InputField } from '../../../shared/Fields'

const Name = ({ isLoading }) => {
  const handleUpdateName = () => {}
  return (
    <Stack spacing={8}>
      <Heading size="xl">Name Instance</Heading>
      {isLoading ? (
        <Skeleton h="120px" />
      ) : (
        <InputField
          width="50%"
          name="name"
          label="Name"
          placeholder="Enter Instance name"
          isRequired
          onUpdate={handleUpdateName}
        />
      )}
    </Stack>
  )
}

export default Name
