const typography = {
  fonts: {
    heading: `"Work Sans", system-ui`,
    body: `"Work Sans", system-ui`,
    mono: `SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem'
  },
  fontWeights: {
    normal: 400,
    semibold: 600,
    bold: 700
  },
  lineHeights: {
    none: 1,
    shorter: 1.25,
    base: 1.5
  },
  letterSpacings: {
    tighter: '-0.02em',
    tight: '-0.01em',
    normal: '0'
  }
}

export default typography
