import { keyframes } from '@chakra-ui/system'
import colors from '../foundations/colors'

const bgFade = keyframes({
  from: { borderColor: colors.gray['100'], background: colors.gray['100'] },
  to: { borderColor: colors.gray['200'], background: colors.gray['200'] }
})

export const SkeletonCircle = {
  baseStyle: {
    animation: `0.8s linear infinite alternate ${bgFade}`
  }
}

export const Skeleton = {
  baseStyle: {
    animation: `0.8s linear infinite alternate ${bgFade}`,
    borderRadius: '6px'
  }
}
