import { Text } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { ImageCards } from '../RadioCards'
import {
  DEFAULT_CATALOG,
  DEFAULT_OPTIONS
} from '../../../../../constants/defaults'

const NoData = () => <Text color="gray.500">There are no Images yet.</Text>

const Images = ({ items }) => {
  const { values, setValues } = useFormikContext()
  const currentTab = items ? items[0]?.tab : undefined
  const selectedImages = values.image.selected[currentTab]
  const noData = items?.length === 0 || !selectedImages

  const onChange = (image, family) => {
    const { id, name, hasDriversSupport } = image
    setValues((prev) => ({
      ...prev,
      image: {
        id,
        name,
        tab: currentTab,
        selected: {
          ...prev.image.selected,
          [currentTab]: {
            ...prev.image.selected[currentTab],
            [family]: image
          }
        }
      },
      catalog: DEFAULT_CATALOG,
      options: {
        ...DEFAULT_OPTIONS,
        hasDriversSupport
      }
    }))
  }

  return noData ? (
    <NoData />
  ) : (
    <ImageCards
      items={items}
      value={values.image.id}
      onChange={onChange}
      selectedImages={selectedImages}
      imageTab={values.image.tab}
    />
  )
}

export default Images
