import {
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Link,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  Text
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import { Link as ReactLink, useParams } from 'react-router-dom'
import { mutate } from 'swr'
import * as yup from 'yup'
import {
  AccountSecurityGroups,
  OrgSecurityGroups
} from '../../../../../api/securityGroups'
import routes from '../../../../../constants/routes'
import { toastMessages } from '../../../../../constants/toast-messages'
import { withOrgPathFallback } from '../../../../../router'
import { WarningError } from '../../../../Errors'
import Asterisk from '../../../../shared/Asterisk'
import { Close } from '../../../../shared/Icons'
import { useToast } from '../../../../shared/Toast'
import {
  useAccountInstancesAll,
  useOrgInstancesAll
} from '../../../../shared/hooks'

const validationSchema = yup.object().shape({
  availableInstances: yup.array(),
  selectedInstances: yup
    .array()
    .min(1, 'At least one Instance should be selected.')
})

export const AssignModal = ({ disclosure, region, assigned }) => {
  const { orgId, projectId, securityGroupId } = useParams()
  const toast = useToast()
  const { isOpen, onClose } = disclosure

  const { instances, isLoading, isError } =
    orgId && projectId
      ? useOrgInstancesAll({
          orgId,
          projectId,
          region,
          shouldFetch: isOpen
        })
      : useAccountInstancesAll({
          region,
          shouldFetch: isOpen
        })

  const assignedIds = assigned?.map(({ id }) => id)
  const filteredInstances = instances?.filter(
    (instance) => !assignedIds.includes(instance.id)
  )

  const initialValues = {
    availableInstances: filteredInstances || [],
    selectedInstances: []
  }

  const onSubmit = async ({ selectedInstances }, { setSubmitting }) => {
    setSubmitting(true)
    try {
      if (orgId) {
        await Promise.all(
          selectedInstances.map((instance) =>
            OrgSecurityGroups.attachById({
              orgId,
              projectId,
              securityGroupId,
              instanceId: instance.id
            })
          )
        )
        await mutate((key) =>
          key.startsWith(`/projects/${projectId}/security-groups?`)
        )
      } else {
        await Promise.all(
          selectedInstances.map((instance) =>
            AccountSecurityGroups.attachById({
              securityGroupId,
              instanceId: instance.id
            })
          )
        )
        await mutate((key) => key.startsWith(`/security-groups?`))
      }
      toast({
        status: 'success',
        message: toastMessages.saved
      })
      onClose()
    } catch {
      toast({
        status: 'error',
        message: toastMessages.notSaved
      })
    } finally {
      setSubmitting(false)
    }
  }

  const { values, setFieldValue, errors, handleSubmit, isSubmitting } =
    useFormik({
      validationSchema,
      initialValues,
      onSubmit,
      enableReinitialize: true
    })

  const noAvailableInstances = values.availableInstances?.length === 0
  const noSelectedInstances = values.selectedInstances?.length === 0

  const handleTransfer = (id, source, destination) => {
    const selected = values[source].find((instance) => instance.id === id)

    const newSources = values[source].filter((instance) => instance.id !== id)
    const newDestinations = [...values[destination], selected]

    setFieldValue(source, newSources)
    setFieldValue(destination, newDestinations)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap="8" as="form" onSubmit={handleSubmit}>
            <Heading size="xl" textAlign="center">
              Assign to Instances
            </Heading>
            {!noSelectedInstances && (
              <List>
                {values.selectedInstances.map(({ id, name }) => (
                  <ListItem
                    key={id}
                    py={3}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    borderBottomWidth="1px"
                    borderBottomColor="gray.100"
                  >
                    <Text size="md" color="gray.500" fontWeight="normal">
                      {name}
                    </Text>
                    <Close
                      color="gray.500"
                      cursor="pointer"
                      _hover={{ color: 'gray.600' }}
                      onClick={() =>
                        handleTransfer(
                          id,
                          'selectedInstances',
                          'availableInstances'
                        )
                      }
                    />
                  </ListItem>
                ))}
              </List>
            )}
            <FormControl isInvalid={errors.selectedInstances}>
              <FormLabel>
                <Asterisk />
                Add Instance to Security group
              </FormLabel>
              {isLoading ? (
                <Center w="full" h={10}>
                  <Spinner size="sm" color="gray.500" />
                </Center>
              ) : isError ? (
                <Center h={10}>
                  <WarningError>Unable to load instances</WarningError>
                </Center>
              ) : (
                <>
                  <Select
                    name="availableInstances"
                    placeholder="Select"
                    isDisabled={noAvailableInstances}
                    onChange={(e) =>
                      handleTransfer(
                        e.target.value,
                        'availableInstances',
                        'selectedInstances'
                      )
                    }
                  >
                    {values.availableInstances?.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  {noAvailableInstances && noSelectedInstances && (
                    <FormHelperText>
                      There are no instances. Create instance{' '}
                      <Link
                        colorScheme="offBlack"
                        as={ReactLink}
                        to={withOrgPathFallback(
                          routes.dashboard.instances.create
                        )}
                      >
                        here
                      </Link>
                      .
                    </FormHelperText>
                  )}
                  <FormErrorMessage>
                    {errors.selectedInstances}
                  </FormErrorMessage>
                </>
              )}
            </FormControl>
            <Stack spacing={4}>
              <Button
                type="submit"
                isDisabled={
                  (noAvailableInstances && noSelectedInstances) || isError
                }
                isLoading={isLoading || isSubmitting}
              >
                Assign
              </Button>
              <Button
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isLoading || isSubmitting}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Stack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
