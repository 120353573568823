import { matchPath, useLocation } from 'react-router-dom'
import { ORG_PATH } from '../../../router'

export function useOrgParam() {
  const { pathname } = useLocation()
  const match = matchPath(pathname, {
    path: ORG_PATH
  })
  return match && match.params ? match.params.orgId : undefined
}
