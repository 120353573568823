import { Box, Link, Td, Tr, useDisclosure } from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'
import routes from '../../../../constants/routes'
import { formatDateTime } from '../../../../helpers/date'
import { withOrgPathFallback } from '../../../../router'
import { Close } from '../../../shared/Icons'
import Region from '../../../shared/Region'
import Spinner from '../../../shared/Spinner'
import ResourceStatus from '../../../shared/ResourceStatus'
import { DetachModal } from '../modals'

const TableRow = ({ volume, attachment, hasPermission }) => {
  const deattachDisclosure = useDisclosure()
  const canBeDetached = hasPermission && attachment.status === 'attached'

  return (
    <Tr>
      <Td>
        <Link
          colorScheme="gray"
          as={ReactLink}
          to={withOrgPathFallback(
            routes.dashboard.instances.view.replace(
              ':instanceId',
              attachment.instance.id
            )
          )}
        >
          {attachment.instance.name}
        </Link>
      </Td>
      <Td>
        <Region slug={volume.region} />
      </Td>
      <Td>{formatDateTime(attachment.createdAt)}</Td>
      <Td>
        <ResourceStatus status={attachment.instance.status} />
      </Td>
      <Td isNumeric>
        {canBeDetached ? (
          <>
            <Box
              _hover={{ cursor: 'pointer' }}
              onClick={deattachDisclosure.onOpen}
              role="group"
            >
              <Close color="gray.500" _groupHover={{ color: 'gray.600' }} />
            </Box>
            <DetachModal
              disclosure={deattachDisclosure}
              volume={volume}
              instanceId={attachment.instance.id}
            />
          </>
        ) : (
          <Spinner color="gray.500" />
        )}
      </Td>
    </Tr>
  )
}

export default TableRow
