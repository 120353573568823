const currencyMap = {
  usd: '$',
  euro: '€'
}

export const getCurrencySymbol = (currency) => currencyMap[currency] || currency

export const formatCurrency = (amount, options = {}) => {
  if (amount === undefined) {
    return
  }
  return amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    ...options
  })
}
