import { Organization } from '../../api/organizations'
import authConstants from '../../constants/settings'

export const useIsVip = (settings) =>
  settings?.find((e) => e.name === authConstants.IS_CUSTOMER_VIP)?.value ===
  true

export const isCustomerVip = (account) => {
  const settingsData = account?.settings?.data
  return (
    settingsData?.find((s) => s.name === authConstants.IS_CUSTOMER_VIP)
      ?.value === true
  )
}

export const isOrganizationVip = async (orgId) => {
  const settingsData = await Organization.fetchSettingsById(orgId)
  return (
    settingsData?.find((s) => s.name === authConstants.IS_CUSTOMER_VIP)
      ?.value === true
  )
}
