import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  Td,
  Text,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import { formatDateTime } from '../../../../../helpers/date'
import CopyButton from '../../../../shared/CopyButton'
import { Dots, Bin, Edit } from '../../../../shared/Icons'
import { TdTruncatedLines } from '../../../../shared/Table'
import { DeleteModal, EditModal } from '../modals'

const TableRow = ({ sshKey, hasPermission }) => {
  const editDisclosure = useDisclosure()
  const deleteDisclosure = useDisclosure()

  return (
    <Tr>
      <Td>{sshKey.name}</Td>
      <Td whiteSpace="normal">
        <Text color="gray.500" lineHeight="24px" wordBreak="break-all">
          {sshKey.fingerprint}
        </Text>
      </Td>
      <Td>
        {sshKey.type.toUpperCase()} {sshKey.size} Bit
      </Td>
      <Td whiteSpace="normal">
        <CopyButton noOfLines={2} value={sshKey.id} />
      </Td>
      <TdTruncatedLines noOfLines={2}>
        {formatDateTime(sshKey.createdAt)}
      </TdTruncatedLines>
      <Td>
        {hasPermission && (
          <Menu variant="table" autoSelect={false}>
            <MenuButton variant="icon" as={IconButton} icon={<Dots />} />
            <MenuList>
              <MenuItem onClick={editDisclosure.onOpen}>
                <Edit mr={2} />
                Edit
              </MenuItem>
              <EditModal disclosure={editDisclosure} sshKey={sshKey} />
              <MenuDivider />
              <MenuItem onClick={deleteDisclosure.onOpen}>
                <Bin mr={2.5} />
                Delete
              </MenuItem>
              <DeleteModal disclosure={deleteDisclosure} sshKey={sshKey} />
            </MenuList>
          </Menu>
        )}
      </Td>
    </Tr>
  )
}

export default TableRow
