const Switch = {
  baseStyle: {
    track: {
      _hover: {
        bg: 'gray.400'
      },
      _checked: {
        _hover: {
          bg: 'blue.600'
        }
      },
      _disabled: {
        cursor: 'not-allowed',
        _hover: {
          bg: 'gray.300'
        }
      }
    }
  }
}

export default Switch
