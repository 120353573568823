import textStyles from '../foundations/textStyles'

const Table = {
  baseStyle: {},
  variants: {
    simple: {
      th: {
        px: 3,
        ...textStyles.sm,
        textTransform: 'none',
        color: 'gray.900',
        fontWeight: 'bold'
      },
      td: {
        px: 3,
        py: 2.5,
        ...textStyles.sm,
        color: 'gray.500'
      }
    },
    card: {
      td: {
        px: 1.5,
        py: 1.5,
        ...textStyles.sm,
        color: 'gray.500',
        '&:last-of-type': {
          textAlign: 'right',
          fontWeight: 600,
          color: 'black'
        }
      },
      tr: {
        '&:not(:last-of-type), &:is(:first-of-type)': {
          borderBottom: '1px',
          color: 'gray.100'
        }
      }
    }
  },
  defaultProps: {
    variant: 'simple'
  }
}

export default Table
