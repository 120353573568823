import useSWR from 'swr'
import { AccountVolumes, OrgVolumes } from '../../../api/volumes'
import { serialize } from '../../../helpers/serializers'
import { INITIAL_PAGE, PER_PAGE } from '../Table'
import { swrOptions } from '../../../constants/swr-options'

export function useOrgVolume({ orgId, projectId, volumeId }) {
  const key = `/projects/${projectId}/volumes/${volumeId}?org=${orgId}`
  const fetcher = () => OrgVolumes.fetchById({ orgId, projectId, volumeId })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  let volume
  if (data) ({ volume } = serialize(data))

  return {
    volume,
    isLoadingVolume: isLoading,
    isErrorVolume: Boolean(error)
  }
}

export function useOrgVolumes({
  orgId,
  projectId,
  page = INITIAL_PAGE,
  perPage = PER_PAGE,
  region,
  shouldFetch = true
}) {
  const regionArg = region ? `&region=${region}` : ''
  const key = shouldFetch
    ? `/projects/${projectId}/volumes?page=${page}&per_page=${perPage}&org=${orgId}${regionArg}`
    : null
  const fetcher = () =>
    OrgVolumes.fetchAll({ orgId, projectId, page, perPage, region })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  let volumes, pagination
  if (data) ({ volumes, ...pagination } = serialize(data))

  return {
    volumes,
    pagination,
    isLoadingVolumes: isLoading,
    isErrorVolumes: Boolean(error)
  }
}

export function useAccountVolume({ volumeId }) {
  const key = `/volumes/${volumeId}`
  const fetcher = () => AccountVolumes.fetchById({ volumeId })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  let volume
  if (data) ({ volume } = serialize(data))

  return {
    volume,
    isLoadingVolume: isLoading,
    isErrorVolume: Boolean(error)
  }
}

export function useAccountVolumes({
  page = INITIAL_PAGE,
  perPage = PER_PAGE,
  region,
  shouldFetch = true
}) {
  const regionArg = region ? `&region=${region}` : ''
  const key = shouldFetch
    ? `/volumes?page=${page}&per_page=${perPage}${regionArg}`
    : null
  const fetcher = () => AccountVolumes.fetchAll({ page, perPage, region })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  let volumes, pagination
  if (data) ({ volumes, ...pagination } = serialize(data))

  return {
    volumes,
    pagination,
    isLoadingVolumes: isLoading,
    isErrorVolumes: Boolean(error)
  }
}
