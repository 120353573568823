import {
  accountApiRequest,
  ACCOUNT_ENDPOINT,
  COMPUTE_ENDPOINT,
  apiRequest
} from './index'
import { getFingerprint } from '../fingerprint'
import { serialize } from '../helpers/serializers'

export const fetchAccount = () => {
  return accountApiRequest(
    '/account',
    {
      method: 'GET'
    },
    true
  )
}

export const updateAccount = (update) => {
  return accountApiRequest(
    `/account`,
    {
      method: 'PUT',
      body: JSON.stringify({
        name: update.name
      })
    },
    true
  )
}

export const patchAccount = (data) => {
  return accountApiRequest(
    `/account`,
    {
      method: 'PATCH',
      body: JSON.stringify(data)
    },
    true
  )
}

export const fetchAccountInfo = () => {
  return accountApiRequest(
    '/account/info',
    {
      method: 'GET'
    },
    true
  )
}

export const updateAccountInfo = ({
  interests,
  role,
  interestsOther,
  roleOther,
  usageType = 'PRIVATE_USE'
}) => {
  const jsonBody = {
    usage_type: usageType,
    interests,
    interests_other: interestsOther,
    role,
    role_other: roleOther
  }

  return accountApiRequest(
    `/account/info`,
    {
      method: 'PUT',
      body: JSON.stringify(jsonBody)
    },
    true
  )
}

export const setAccountOnboarding = () => {
  return accountApiRequest(
    `/account`,
    {
      method: 'PATCH',
      body: JSON.stringify({
        onboarded_at: new Date().toISOString()
      })
    },
    true
  )
}

export const fetchOnboardingCheckin = async () => {
  return accountApiRequest(
    '/checkin-onboarding',
    {
      method: 'POST',
      headers: {
        'x-checkin-flow': await getFingerprint()
      }
    },
    true
  )
}

export const verifyPhone = (data) => {
  return accountApiRequest(
    '/verify',
    {
      method: 'POST',
      body: JSON.stringify({
        phone_number: data.phoneNumber,
        country: data.country
      })
    },
    true
  )
}

export const getMetadata = () => {
  return accountApiRequest(
    '/verify/metadata',
    {
      method: 'GET'
    },
    true
  )
}

export const verifyPhoneCode = (data) => {
  return accountApiRequest(
    '/verify/check',
    {
      method: 'POST',
      body: JSON.stringify({
        phone_number: data.phoneNumber,
        country: data.country,
        code: data.code
      })
    },
    true
  )
}

export class Account {
  static fetch = async () =>
    await apiRequest(
      `${ACCOUNT_ENDPOINT}/account`,
      {
        method: 'GET'
      },
      true
    )

  static fetchSettings = async () =>
    await apiRequest(
      `${ACCOUNT_ENDPOINT}/settings`,
      {
        method: 'GET'
      },
      true
    )

  static fetchFF = async () => {
    const result = await apiRequest(
      `${ACCOUNT_ENDPOINT}/ff`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(result)
  }

  static fetchCheckIn = async () => {
    const result = await apiRequest(
      `${ACCOUNT_ENDPOINT}/checkin`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(result)
  }

  static fetchMigration = async () => {
    const migration = await apiRequest(
      `${COMPUTE_ENDPOINT}/migration`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(migration)
  }

  static migrate = async () =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/migration`,
      {
        method: 'POST'
      },
      true
    )
}
