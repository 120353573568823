import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { mutate } from 'swr'
import { Organization } from '../../../../../../api/organizations'
import { toastMessages } from '../../../../../../constants/toast-messages'
import { useToast } from '../../../../../shared/Toast'

export const RemoveInvitationModal = ({
  visible,
  onVisibilityChange,
  invitation,
  organization
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const handleClose = useCallback(() => {
    onClose()
    if (onVisibilityChange) {
      onVisibilityChange(false)
    }
  }, [onClose, onVisibilityChange])

  useEffect(() => {
    if (visible === true) {
      onOpen()
    } else if (visible === false) {
      onClose()
    }
  }, [visible])

  const handleRemove = useCallback(() => {
    setLoading(true)
    Organization.deleteInvitation(organization.id, invitation.id)
      .then(() => {
        mutate((key) =>
          key.startsWith(`/organizations/${organization.id}/invitations?`)
        )
        toast({
          status: 'success',
          message: toastMessages.invitationRemoveSuccess
        })
      })
      .catch(() =>
        toast({
          status: 'error',
          message: toastMessages.invitationRemoveError
        })
      )
      .finally(() => {
        setLoading(false)
        onClose()
      })
  })

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Heading size="xl" mb={8} textAlign="center">
            Remove {invitation?.targetEmail} from {organization.name}?
          </Heading>
          <Button
            bg="red.500"
            colorScheme="red"
            isFullWidth
            isLoading={loading}
            onClick={handleRemove}
          >
            {invitation.declinedAt ? 'Remove' : 'Withdraw'} invitation
          </Button>
          <Button
            isFullWidth
            variant="outline"
            colorScheme="offBlack"
            onClick={handleClose}
            mt={4}
          >
            Cancel
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
