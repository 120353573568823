import { Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { SkeletonTable } from '../../../../shared/Skeletons'
import {
  INITIAL_PAGE,
  TableCell,
  TableError,
  TablePagination
} from '../../../../shared/Table'
import {
  useAccountReservations,
  useOrgReservations
} from '../../../../shared/hooks/useReservations'
import TableRow from './table-row'

const List = ({ hasPermission }) => {
  const { orgId } = useParams()
  const [page, setPage] = useState(INITIAL_PAGE)

  const {
    reservations,
    pagination,
    isLoadingReservations,
    isErrorReservations
  } = orgId
    ? useOrgReservations({ orgId, page, isPolling: true })
    : useAccountReservations({ page, isPolling: true })
  const isTableEmpty = reservations?.length === 0

  return (
    <>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th w="25%">ID</Th>
              <Th w="15%">GPU Type</Th>
              <Th w="15%">Region</Th>
              <Th w="10%">Start</Th>
              <Th w="10%">End</Th>
              <Th w="10%">Quota</Th>
              <Th w="10%">Status</Th>
              <Th w="5%" />
            </Tr>
          </Thead>
          {isLoadingReservations ? (
            <SkeletonTable rows={10} columns={8} />
          ) : isErrorReservations ? (
            <TableError>
              Something went wrong loading the reservations.
            </TableError>
          ) : (
            <Tbody>
              {isTableEmpty ? (
                <TableCell>There are no Reservations yet.</TableCell>
              ) : (
                reservations?.map((reservation) => (
                  <TableRow
                    key={reservation.id}
                    row={reservation}
                    hasPermission={hasPermission}
                    orgId={orgId}
                  />
                ))
              )}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      {!isErrorReservations && (
        <TablePagination
          page={page}
          setPage={setPage}
          totalCount={pagination?.totalCount}
        />
      )}
    </>
  )
}

export default List
