import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import { Select, chakraComponents } from 'chakra-react-select'
import { Field, Form, Formik } from 'formik'
import { useCallback, useMemo } from 'react'
import { Link as ReactLink, useParams } from 'react-router-dom'
import { mutate } from 'swr'
import * as yup from 'yup'
import { Project } from '../../../../../../api/projects'
import routes from '../../../../../../constants/routes'
import { toastMessages } from '../../../../../../constants/toast-messages'
import { externalLinks } from '../../../../../../helpers/links'
import { withOrgPath } from '../../../../../../router'
import { SelectComponents } from '../../../../../../theme/components/Select'
import { useToast } from '../../../../../shared/Toast'
import { useOrganizationMembers } from '../../../../../shared/hooks/organization'
import { useIsMounted } from '../../../../../shared/hooks/useIsMounted'
import { useAccount } from '../../../../../shared/hooks/account'

const validationSchema = yup.object().shape({
  members: yup.array().min(1, 'Members is required')
})

const initialValues = {
  members: []
}

const CustomOption = ({ children, ...props }) => (
  <chakraComponents.Option {...props}>
    <Flex alignItems="center">
      <Box fontSize="16px">
        <Text color="gray.900">{props.data.name}</Text>
        <Text color="gray.500">{props.data.email}</Text>
      </Box>
    </Flex>
  </chakraComponents.Option>
)

export const MembersModal = ({ disclosure, projectId, projectMembers }) => {
  const { isOpen, onClose } = disclosure
  const { orgId } = useParams()
  const { account } = useAccount()
  const { members } = useOrganizationMembers({
    orgId,
    perPage: 100
  })
  const isMounted = useIsMounted()
  const toast = useToast()

  const membersList = useMemo(() => {
    if (members) {
      return members.members
        .filter((m) => m.accountId !== account?.accountId)
        .filter((m) => {
          return (
            !projectMembers ||
            !projectMembers.find((pm) => pm.account.id === m.accountId)
          )
        })
        .map((m) => ({
          label: m.account.name,
          value: m.accountId,
          // used for search
          info: `${m.account.name} ${m.account.email}`,
          name: m.account.name,
          email: m.account.email
        }))
    }
  }, [members, projectMembers])

  const noMembersExist = membersList?.length === 0

  const handleSubmit = useCallback(
    ({ members }, { setSubmitting }) => {
      setSubmitting(true)
      Promise.all(
        members.map((m) =>
          Project.addMember({
            orgId,
            projectId: projectId,
            accountId: m.value
          })
        )
      )
        .then(() => {
          mutate((key) => key.startsWith(`/organizations/${orgId}/projects`))
          toast({
            status: 'success',
            message: toastMessages.memberAddSuccess
          })
          if (isMounted()) {
            setSubmitting(false)
            onClose()
          }
        })
        .catch(() => {
          setSubmitting(false)
          toast({
            status: 'error',
            message: toastMessages.memberAddError
          })
        })
    },
    [history, orgId, projectId, mutate, isMounted, onClose]
  )

  const filterOptions = useCallback((member, input) => {
    return member.data.info.toLowerCase().indexOf(input.toLowerCase()) !== -1
  }, [])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Heading size="xl" mb={4} textAlign="center">
            Add Members to Project
          </Heading>
          <Text padding="0 14px" color="gray.500" textAlign="center" mb={8}>
            By default Project Members have{' '}
            <Link
              href={externalLinks.permissions}
              colorScheme="offBlack"
              isExternal
            >
              viewer permission
            </Link>
            .
          </Text>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, errors, touched, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <Stack spacing={8}>
                  <FormControl isInvalid={errors.members && touched.members}>
                    <Field name="members">
                      {({ field, form }) => (
                        <Select
                          chakraStyles={SelectComponents}
                          name="members"
                          placeholder="Search by name or email"
                          isMulti
                          isDisabled={noMembersExist || isSubmitting}
                          filterOption={filterOptions}
                          components={{ Option: CustomOption }}
                          options={membersList}
                          onChange={(option) => {
                            form.setFieldValue(field.name, option)
                          }}
                        />
                      )}
                    </Field>
                    {noMembersExist && (
                      <FormHelperText>
                        This Organization has no Members, please{' '}
                        <Link
                          colorScheme="offBlack"
                          as={ReactLink}
                          to={withOrgPath(
                            routes.dashboard.members,
                            orgId,
                            projectId
                          )}
                        >
                          invite Members here
                        </Link>
                        .
                      </FormHelperText>
                    )}
                    <FormErrorMessage>{errors.members}</FormErrorMessage>
                  </FormControl>
                  <Text color="red.500" textAlign="center">
                    You can only add Organization Members to the Project.
                  </Text>
                  <Stack spacing={4}>
                    <Button
                      type="submit"
                      isDisabled={noMembersExist}
                      isLoading={isSubmitting}
                    >
                      Save
                    </Button>
                    <Button
                      colorScheme="offBlack"
                      variant="outline"
                      isDisabled={isSubmitting}
                      onClick={onClose}
                      isFullWidth
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
