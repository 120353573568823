import { Stack } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { Card } from '../../../../shared/Cards'
import { formatDate } from '../../../../../helpers/date'
import Dates from '../../../../shared/Price'
import Prices from './prices'

const Billing = () => {
  const { values } = useFormikContext()
  const { dates } = values

  const items = [
    { label: 'Start', value: formatDate(dates.start) || '-' },
    { label: 'End', value: formatDate(dates.end) || '-' }
  ]

  return (
    <Card as={Stack} spacing={5} p={7}>
      <Dates items={items} />
      <Prices />
    </Card>
  )
}

export default Billing
