import useSWR from 'swr'
import { Configs } from '../../../api/configs'
import { serialize } from '../../../helpers/serializers'
import { transformInstanceTypes } from './configs/instance'
import { transformVolumeTypes } from './configs/volume'

export const useConfigs = ({ shouldFetch = true }) => {
  const key = shouldFetch ? '/products-configs' : null
  const fetcher = () => Configs.fetchProducts()

  const { data, isValidating, isLoading, error } = useSWR(key, fetcher, {
    revalidateOnFocus: false
  })

  const configs = serialize(data)
  const instances = transformInstanceTypes(configs)
  const volumes = transformVolumeTypes(configs)
  const regions = configs?.regions

  return {
    instances,
    volumes,
    configs,
    regions,
    isValidatingConfigs: isValidating,
    isLoadingConfigs: isLoading,
    isErrorConfigs: Boolean(error)
  }
}

export const useVolumeConfigs = ({ region }) => {
  const { configs, isLoadingConfigs, isErrorConfigs } = useConfigs({})

  if (configs) {
    const serializedTypes = transformVolumeTypes(configs)

    const types = serializedTypes.find((item) => item.slug === region).types

    return {
      types,
      isErrorVolumeConfigs: isErrorConfigs,
      isLoadingVolumeConfigs: isLoadingConfigs
    }
  } else {
    return {
      types: undefined,
      isErrorVolumeConfigs: isErrorConfigs,
      isLoadingVolumeConfigs: isLoadingConfigs
    }
  }
}
