import { useParams } from 'react-router-dom'
import { useIsAdmin, useProject } from '../../../../../shared/hooks/project'
import Spinner from '../../../../../shared/Spinner'
import Admin from './admin'
import NoAdmin from './no-admin'
import SupportError from '../../../../../Errors/SupportError'

const ProjectSettings = () => {
  const { projectId, orgId } = useParams()
  const { project, isLoadingProject, isErrorProject } = useProject({
    orgId,
    projectId
  })
  const accountIsAdmin = useIsAdmin(project?.userPermission)

  if (isLoadingProject) return <Spinner />

  if (isErrorProject)
    return (
      <SupportError>
        Something went wrong loading the project settings.
      </SupportError>
    )

  return accountIsAdmin ? (
    <Admin project={project} />
  ) : (
    <NoAdmin project={project} />
  )
}

export default ProjectSettings
