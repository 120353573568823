import { useState } from 'react'
import {
  Badge,
  ListItem,
  Text,
  HStack,
  useDisclosure,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuDivider,
  MenuItem
} from '@chakra-ui/react'
import { mutate } from 'swr'
import { OrgBilling, AccountBilling } from '../../../../../api/billing'
import { useToast } from '../../../../shared/Toast'
import { toastMessages } from '../../../../../constants/toast-messages'
import CreditCardIcon from '../../../../shared/CreditCardIcon'
import { Dots, Star, Edit, Trash } from '../../../../shared/Icons'
import { DefaultBadge } from '../../../../shared/Badge'
import { formatDate } from '../../../../../helpers/date'
import { DeleteModal, EditMethodModal } from '../modals'

const CardItem = ({ card, orgId }) => {
  const editDisclosure = useDisclosure()
  const deleteDisclosure = useDisclosure()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const toast = useToast()

  const { id, expYear, expMonth, brand, last4, isDefault, name, created } = card
  const isExpired = new Date(expYear, expMonth) < new Date()

  const deleteData = {
    orgId,
    creditCardId: id,
    last4
  }

  const onDefaultMethod = async () => {
    const payload = { payment_method: id }
    setIsSubmitting(true)
    try {
      if (orgId) {
        await OrgBilling.setDefaultMethod({ orgId, payload })
        await mutate(`/billing/customer?org=${orgId}`)
      } else {
        await AccountBilling.setDefaultMethod({ payload })
        await mutate('/billing/customer')
      }

      toast({
        status: 'success',
        message: toastMessages.saved
      })
    } catch {
      toast({
        status: 'error',
        message: toastMessages.notSaved
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <ListItem
        alignItems="center"
        display="grid"
        gridTemplateColumns="1fr 1fr 1fr 1fr 40px"
        justifyContent="space-between"
        p={2}
        border="1px"
        borderRadius="10px"
        borderColor={isExpired ? 'red.500' : 'gray.500'}
      >
        <HStack spacing={3} ml={3}>
          <CreditCardIcon brand={brand} />
          <Text fontWeight="600">xxxx-{last4}</Text>
          {isDefault && <DefaultBadge />}
          {isExpired && (
            <Badge fontSize="10px" variant="outline" colorScheme="red.500">
              Expired
            </Badge>
          )}
        </HStack>
        <Text color="gray.500">Name on card: {name}</Text>
        <Text color="gray.500">
          Expires: {expMonth}/{expYear}
        </Text>
        <Text color="gray.500">Added on: {formatDate(created * 1000)}</Text>
        <Menu variant="table" autoSelect={false}>
          <MenuButton variant="icon" as={IconButton} icon={<Dots />} />
          <MenuList>
            {!isDefault && (
              <>
                <MenuItem
                  gap={2}
                  onClick={onDefaultMethod}
                  isDisabled={isSubmitting}
                >
                  <Star boxSize={5} />
                  Make default
                </MenuItem>
                <MenuDivider />
              </>
            )}
            <MenuItem gap={2} onClick={editDisclosure.onOpen}>
              <Edit boxSize={5} />
              Edit card
            </MenuItem>
            <EditMethodModal card={card} disclosure={editDisclosure} />
            {!isDefault && (
              <>
                <MenuDivider />
                <MenuItem
                  gap={2}
                  onClick={deleteDisclosure.onOpen}
                  isDisabled={isSubmitting}
                >
                  <Trash boxSize={5} />
                  Delete card
                </MenuItem>
                <DeleteModal disclosure={deleteDisclosure} data={deleteData} />
              </>
            )}
          </MenuList>
        </Menu>
      </ListItem>
    </>
  )
}

export default CardItem
