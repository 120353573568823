export const regions = [
  {
    slug: 'ARC-IS-HAF-1',
    enabled: true,
    title: 'Iceland-HAF1',
    flag: 'IS',
    labels: []
  },
  {
    slug: 'NORD-NO-KRS-1',
    enabled: true,
    title: 'Norway-KRS1',
    flag: 'NO',
    labels: []
  },
  {
    slug: 'EUC-DE-MUC-1',
    enabled: true,
    title: 'Germany-MUC1',
    flag: 'DE',
    labels: ['soon', 'new']
  }
]

export const objectStorageRegions = [
  {
    slug: 'ARC-IS-HAF-1',
    enabled: true,
    title: 'Iceland-HAF1',
    flag: 'IS',
    labels: []
  },
  {
    slug: 'NORD-NO-KRS-1',
    enabled: true,
    title: 'Norway-KRS1',
    flag: 'NO',
    labels: ['soon']
  },
  {
    slug: 'EUC-DE-MUC-1',
    enabled: true,
    title: 'Germany-MUC1',
    flag: 'DE',
    labels: []
  }
]

export const filesystemRegions = [
  {
    slug: 'ARC-IS-HAF-1',
    enabled: true,
    title: 'Iceland-HAF1',
    flag: 'IS',
    labels: []
  },
  {
    slug: 'NORD-NO-KRS-1',
    enabled: true,
    title: 'Norway-KRS1',
    flag: 'NO',
    labels: []
  },
  {
    slug: 'EUC-DE-MUC-1',
    enabled: true,
    title: 'Germany-MUC1',
    flag: 'DE',
    labels: []
  }
]

export const reservationRegions = [
  {
    slug: 'ARC-IS-HAF-1',
    enabled: true,
    title: 'Iceland-HAF1',
    flag: 'IS',
    labels: []
  },
  {
    slug: 'NORD-NO-KRS-1',
    enabled: true,
    title: 'Norway-KRS1',
    flag: 'NO',
    labels: []
  },
  {
    slug: 'EUC-DE-MUC-1',
    enabled: true,
    title: 'Germany-MUC1',
    flag: 'DE',
    labels: []
  }
]
