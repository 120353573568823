export default {
  root: '/',
  resetPassword: '/reset-password',
  updatePassword: '/update-password',
  verifyEmail: '/verify-email',
  authorize: '/authorize',
  silentRenew: '/silent-renew',
  forbidden: '/forbidden',
  authCallback: '/callback',
  prompt: '/prompt/:prompt',
  updatedTerms: '/prompt/updated-terms',
  signin: '/signin',
  signout: '/signout',
  dashboard: {
    index: '/dashboard',
    settings: '/dashboard/settings',
    members: '/dashboard/members',
    projects: '/dashboard/projects',
    quota: '/dashboard/quota',
    reservations: {
      index: '/dashboard/reservations',
      create: '/dashboard/reservations/create'
    },
    security: '/dashboard/security',
    billing: '/dashboard/billing',
    billingAddCreditCard: '/dashboard/billing#addCreditCard',
    accountSettings: '/dashboard/account-settings',
    accountSettingsVerifyPhoneNumber:
      '/dashboard/account-settings#verifyPhoneNumber',
    projectSettings: '/dashboard/project-settings',
    instances: {
      index: '/dashboard/instances',
      create: '/dashboard/instances/create',
      view: '/dashboard/instances/view/:instanceId'
    },
    communityInstances: {
      index: '/dashboard/community-instances',
      create: '/dashboard/community-instances/create',
      view: '/dashboard/community-instances/view/:communityInstanceId'
    },
    snapshots: {
      index: '/dashboard/snapshots'
    },
    securityGroups: {
      index: '/dashboard/security-groups',
      create: '/dashboard/security-groups/create',
      view: '/dashboard/security-groups/view/:securityGroupId'
    },
    floatingIPs: {
      index: '/dashboard/floating-ips'
    },
    volumes: {
      index: '/dashboard/volumes',
      create: '/dashboard/volumes/create',
      view: '/dashboard/volumes/view/:volumeId'
    },
    buckets: {
      index: '/dashboard/buckets',
      create: '/dashboard/buckets/create',
      view: '/dashboard/buckets/view/:bucketName'
    },
    filesystems: {
      index: '/dashboard/filesystems',
      create: '/dashboard/filesystems/create',
      view: '/dashboard/filesystems/view/:filesystemId'
    },
    organizations: {
      accept:
        '/dashboard/organizations/org/:orgId/project/:projectId/accept/:code',
      index: '/dashboard/organizations',
      create: {
        index: '/dashboard/organizations/create',
        setup: '/dashboard/organizations/create/setup',
        details: '/dashboard/organizations/create/details',
        payment: '/dashboard/organizations/create/payment',
        billing: '/dashboard/organizations/create/billing',
        success: '/dashboard/organizations/create/success'
      }
    },
    serialConsole: {
      index: '/serial-console'
    }
  },
  onboarding: {
    index: '/onboarding',
    setup: '/onboarding/setup',
    details: '/onboarding/details',
    payment: '/onboarding/payment',
    billing: '/onboarding/billing',
    phoneVerification: '/onboarding/phone-verification'
  }
}
