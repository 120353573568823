import useSWR from 'swr'
import { AccountQuota, OrgQuota } from '../../../api/quota'
import { serialize } from '../../../helpers/serializers'

export function useOrgQuota({ orgId }) {
  const { data, error } = useSWR(orgId && `/quota?org=${orgId}`, () =>
    OrgQuota.fetchById({ orgId })
  )
  return {
    quota: serialize(data),
    isErrorQuota: error,
    isLoadingQuota: !data && !error
  }
}

export function useAccountQuota() {
  const { data, error } = useSWR('/quota', () => AccountQuota.fetch())
  return {
    quota: serialize(data),
    isErrorQuota: error,
    isLoadingQuota: !data && !error
  }
}
