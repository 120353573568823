import moment from 'moment'

export const getMinDate = () => moment().add(1, 'days').toDate()

export const getMaxDate = (quota) => {
  const lastItem = quota[quota.length - 1]
  return lastItem ? moment(lastItem.date).toDate() : undefined
}

export const getDisabledDates = (quota, size) =>
  quota?.reduce(
    (disabledDates, item) =>
      item.maxQuota < size
        ? [...disabledDates, moment(item.date).toDate()]
        : disabledDates,
    []
  )

export const getReservationInfo = (reservationsInfo, date) =>
  reservationsInfo?.find((info) =>
    moment(info.date).isSame(moment(date), 'day')
  )

export const isDateInRange = (date, start, end) =>
  moment(date).isBetween(moment(start), moment(end), 'day')

export const daysInRange = (start, end) =>
  moment(end).diff(moment(start), 'days') + 1

export const calcDiscount = (discountMap, days) => {
  let discount = 0
  const keys = Object.keys(discountMap)

  for (let i = 0; i < keys.length; i++)
    if (days >= keys[i]) discount = discountMap[keys[i]]

  return discount
}

export const calcPrices = ({ basePrice, size, days, discountMap }) => {
  let priceBySize = 0
  let discount = 0
  let totalPrice = 0

  if (basePrice) {
    priceBySize = basePrice * size
    totalPrice = priceBySize * days
  }

  if (discountMap) discount = calcDiscount(discountMap, days)
  if (discount > 0) totalPrice -= (discount / 100) * totalPrice

  return {
    priceBySize,
    discount,
    totalPrice
  }
}
