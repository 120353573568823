import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import { AccountSnapshots, OrgSnapshots } from '../../../../api/images'
import { toastMessages } from '../../../../constants/toast-messages'
import { useToast } from '../../../shared/Toast'
import { useIsMounted } from '../../../shared/hooks/useIsMounted'

const DeleteModal = ({ snapshot, disclosure }) => {
  const { orgId, projectId } = useParams()
  const { isOpen, onClose } = disclosure
  const [isSubmitting, setIsSubmitting] = useState()
  const isMounted = useIsMounted()
  const toast = useToast()

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true)
    try {
      const snapshotId = snapshot.id
      const response = orgId
        ? await OrgSnapshots.deleteById({
            orgId,
            projectId,
            snapshotId
          })
        : await AccountSnapshots.deleteById({ snapshotId })
      if (response.ok) {
        orgId
          ? mutate((key) => key.startsWith(`/projects/${projectId}/snapshots?`))
          : mutate((key) => key.startsWith(`/snapshots?`))
        toast({
          status: 'success',
          message: toastMessages.snapshotDeleteSuccess
        })
        onClose()
      }
    } catch {
      toast({
        status: 'error',
        message: toastMessages.snapshotDeleteError
      })
    } finally {
      if (isMounted()) {
        setIsSubmitting(false)
      }
    }
  }, [snapshot])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <Heading size="xl" mb={4} textAlign="center">
              Are you sure you want to delete Snapshot “{snapshot.name}”?
            </Heading>
            <Text
              mb={8}
              size="md"
              textAlign="center"
              color="red.500"
              fontWeight="normal"
            >
              This action will permanently delete the snapshot and it cannot be
              recovered.
            </Text>
            <Stack spacing={4}>
              <Button
                type="submit"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                colorScheme="red"
                onClick={handleSubmit}
              >
                Delete Snapshot
              </Button>
              <Button
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isSubmitting}
                onClick={onClose}
                isFullWidth
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default DeleteModal
