import { forwardRef } from 'react'
import { InputGroup, Input, InputRightElement } from '@chakra-ui/react'
import { Calendar } from '../Icons'

const CustomInput = (props, ref) => (
  <InputGroup>
    <Input type="text" autoComplete="off" ref={ref} {...props} pr={12} />
    <InputRightElement w={12}>
      <Calendar opacity={props.disabled ? 0.3 : 1} />
    </InputRightElement>
  </InputGroup>
)

export default forwardRef(CustomInput)
