import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  Link,
  Skeleton,
  Stack,
  Text
} from '@chakra-ui/react'
import {
  AddressElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { MetaTags } from 'react-meta-tags'
import * as yup from 'yup'
import { AccountBilling, OrgBilling } from '../../../api/billing'
import { toastMessages } from '../../../constants/toast-messages'
import { externalLinks } from '../../../helpers/links'
import typography from '../../../theme/foundations/typography'
import Asterisk from '../Asterisk'
import { Stripe } from '../Icons'
import { useToast } from '../Toast'
import { useAccount } from '../hooks/account'
import { getOrgId } from './utils'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY)

const appearance = {
  theme: 'stripe',
  variables: {
    borderRadius: '20px',
    spacingUnit: '6px',
    fontFamily: typography.fonts.body,
    fontSizeBase: '15px'
  },
  rules: {
    '.Tab': {
      border: '1px solid #c8c8c8',
      boxShadow: 'none'
    },
    '.Input': {
      border: '1px solid #c8c8c8',
      boxShadow: 'none',
      padding: '10px 16px'
    }
  }
}

const initialValues = {
  terms: false,
  isDefault: false
}

const validationSchema = yup.object().shape({
  terms: yup
    .boolean()
    .oneOf([true], 'Accepting the terms of service is required.')
})

const Terms = () => (
  <Text color="gray.500" textAlign="justify" size="sm">
    <Asterisk />
    {`I agree to Genesis Cloud's `}
    <Link href={externalLinks.termsOfService} colorScheme="offBlack" isExternal>
      Terms of Service
    </Link>
    {` and `}
    <Link href={externalLinks.privacyPolicy} colorScheme="offBlack" isExternal>
      Privacy Policy
    </Link>
    {
      ' and authorize Genesis Cloud to send instructions to the financial institution that issued my card to take payments from my card account in accordance with the terms of service as well as placing temporary authorization holds on my card based on the service usage and selected billing plans.'
    }
  </Text>
)

const Default = () => (
  <Text color="gray.500" textAlign="justify" size="sm">
    Make this my default payment method
  </Text>
)

const Info = () => (
  <>
    <Text color="gray.500" size="md" fontWeight="normal" textAlign="center">
      You may see a temporary authorization hold on your card, which your bank
      should release soon.
    </Text>
    <Stripe display="flex" ml="auto" />
  </>
)

const Loading = () => (
  <Stack spacing={5}>
    <Skeleton h="64px" />
    <Skeleton h="64px" />
    <Skeleton h="64px" />
    <Skeleton h="64px" />
    <Skeleton h="64px" />
    <Skeleton h="134px" />
    <Skeleton h="64px" />
  </Stack>
)

const Payment = ({ successLink, showDefaultOption }) => {
  const stripe = useStripe()
  const elements = useElements()
  const toast = useToast()
  const [isPaymentLoading, setIsPaymentLoading] = useState(true)
  const [isAddressLoading, setIsAddressLoading] = useState(true)
  const isLoading = isPaymentLoading && isAddressLoading
  const { account } = useAccount()
  const name = account?.name ? account.name : null

  const paymentOptions = {
    terms: { card: 'never' }
  }

  const addressOptions = {
    mode: 'billing',
    defaultValues: {
      name,
      address: {
        country: 'DE'
      }
    }
  }

  const onSubmit = async ({ isDefault }, { setSubmitting }) => {
    if (!stripe || !elements) {
      return
    }

    setSubmitting(true)

    try {
      const isCardDefault = showDefaultOption ? isDefault : true
      const return_url = `${successLink}?is_card_default=${isCardDefault}`
      const payment_method_data = {
        allow_redisplay: 'always'
      }

      await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url,
          payment_method_data
        }
      })
    } catch (error) {
      toast({
        status: 'error',
        message: toastMessages.notSaved
      })
    } finally {
      setSubmitting(false)
    }
  }

  const { values, errors, touched, handleSubmit, handleChange, isSubmitting } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit
    })

  return (
    <Stack as="form" spacing={4} onSubmit={handleSubmit}>
      <PaymentElement
        id="payment-element"
        options={paymentOptions}
        onReady={() => setIsPaymentLoading(false)}
      />
      <AddressElement
        id="address-element"
        options={addressOptions}
        onReady={() => setIsAddressLoading(false)}
      />
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          {showDefaultOption && (
            <FormControl>
              <Checkbox
                name="isDefault"
                value={values.isDefault}
                onChange={handleChange}
              >
                <Default />
              </Checkbox>
            </FormControl>
          )}
          <FormControl isInvalid={errors.terms && touched.terms}>
            <Checkbox name="terms" value={values.terms} onChange={handleChange}>
              <Terms />
            </Checkbox>
            <FormErrorMessage>{errors.terms}</FormErrorMessage>
          </FormControl>
          <Info />
          <Button
            type="submit"
            isFullWidth
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Save
          </Button>
        </>
      )}
    </Stack>
  )
}

const PaymentForm = ({ successLink, showDefaultOption }) => {
  const orgId = getOrgId()
  const [clientSecret, setClientSecret] = useState(null)

  useEffect(() => {
    async function fetchPaymentIntent() {
      try {
        const { client_secret } = orgId
          ? await OrgBilling.createPaymentIntent({
              orgId
            })
          : await AccountBilling.createPaymentIntent()
        setClientSecret(client_secret)
      } catch (error) {
        setClientSecret(null)
      }
    }

    fetchPaymentIntent()
  }, [])

  return (
    <>
      <MetaTags>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </MetaTags>
      {clientSecret && stripePromise ? (
        <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
          <Payment
            successLink={successLink}
            showDefaultOption={showDefaultOption}
          />
        </Elements>
      ) : (
        <Box pt={8}>
          <Loading />
        </Box>
      )}
    </>
  )
}

export default PaymentForm
