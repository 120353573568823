import { createContext, useState, useContext, useMemo } from 'react'
import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Text
} from '@chakra-ui/react'
import Asterisk from './Asterisk'

export const business = 'BUSINESS'
export const institution = 'INSTITUTION'
export const privateUse = 'PRIVATE_USE'

export const UsageTypeEnum = Object.freeze({
  BUSINESS: 'BUSINESS',
  INSTITUTION: 'INSTITUTION',
  PRIVATE_USE: 'PRIVATE_USE'
})

export const UsageTypeContext = createContext({
  usageType: '',
  setUsageType: () => {}
})

export const UsageTypeProvider = ({ children }) => {
  const [usageType, setUsageType] = useState(UsageTypeEnum.BUSINESS)
  const value = useMemo(() => ({ usageType, setUsageType }), [usageType])

  return (
    <UsageTypeContext.Provider value={value}>
      {children}
    </UsageTypeContext.Provider>
  )
}

export const UsageTypeRadioGroup = () => {
  const { usageType, setUsageType } = useContext(UsageTypeContext)
  return (
    <FormControl my={8}>
      <FormLabel>
        <Asterisk />
        Usage type:
      </FormLabel>
      <RadioGroup
        value={usageType}
        onChange={(usageType) => setUsageType(usageType)}
      >
        <Stack>
          <Radio
            type="radio"
            id="business"
            name="usageType"
            value={UsageTypeEnum.BUSINESS}
          >
            Business
            <Text size="sm">
              operated by an organization for business or commercial reasons
            </Text>
          </Radio>
          <Radio
            type="radio"
            id="institution"
            name="usageType"
            value={institution}
          >
            Institution
            <Text size="sm">
              devoted to a public, educational, charitable or social purpose
            </Text>
          </Radio>
          <Radio type="radio" id="private" name="usageType" value={privateUse}>
            Private use
            <Text size="sm">
              intended for individuals not connected with any business or
              institution
            </Text>
          </Radio>
        </Stack>
      </RadioGroup>
    </FormControl>
  )
}
