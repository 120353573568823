import { useDisclosure } from '@chakra-ui/hooks'
import {
  Box,
  Heading,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text
} from '@chakra-ui/react'
import { useCallback, useMemo } from 'react'
import { useSWRConfig } from 'swr'
import { markNotificationAsRead } from '../../../../../api/notifications'
import { Bell } from '../../../../shared/Icons'
import Badge from './Badge'
import NotificationSection from './NotificationSection'
import { ACTION_REQUIRED } from './itemType'

const Notifications = ({ notifications: data }) => {
  const { notifications, metadata } = data
  const { mutate } = useSWRConfig()
  const { onOpen, onClose, isOpen } = useDisclosure()
  const unreadCount = useMemo(() => metadata.unreadCount, [data])
  const required = useMemo(
    () => notifications.filter((item) => item.type === ACTION_REQUIRED),
    [data]
  )
  const read = useMemo(
    () =>
      notifications.filter(
        (item) => item.read && item.type !== ACTION_REQUIRED
      ),
    [data]
  )
  const unread = useMemo(
    () =>
      notifications.filter(
        (item) => !item.read && item.type !== ACTION_REQUIRED
      ),
    [data]
  )
  const isVisible = required.length > 0 || unreadCount > 0

  const handleOnClose = useCallback(async () => {
    for (const notification of notifications) {
      if (!notification.read) {
        await markNotificationAsRead(notification.id)
      }
    }
    await mutate('/notifications')
    onClose()
  }, [onClose, notifications])

  return (
    <Box>
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={handleOnClose}
        placement="bottom-end"
      >
        <PopoverTrigger>
          <Box pos="relative">
            <IconButton
              variant="unstyled"
              size="sm"
              display="flex"
              _focus={{ background: 'none' }}
              icon={<Bell />}
            />
            {isVisible && <Badge unreadCount={unreadCount} />}
          </Box>
        </PopoverTrigger>
        <Box zIndex="10">
          <PopoverContent
            w="460px"
            maxH="600px"
            p={6}
            outline="none"
            borderRadius="6px"
            overflowY="auto"
            _focus={{ outline: 'none' }}
          >
            <PopoverBody p={0} cursor="default">
              <Heading size="xl" mb={5}>
                Notifications
              </Heading>
              {notifications.length === 0 && (
                <Text>There are no notifications yet.</Text>
              )}
              <NotificationSection
                header="New"
                notifications={unread}
                onClose={onClose}
              />
              <NotificationSection
                header="Action required"
                notifications={required}
                onClose={onClose}
              />
              <NotificationSection
                header="Other"
                notifications={read}
                onClose={onClose}
              />
            </PopoverBody>
          </PopoverContent>
        </Box>
      </Popover>
    </Box>
  )
}

export default Notifications
