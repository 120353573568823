import {
  Skeleton,
  Collapse,
  List,
  ListItem,
  Divider,
  Text
} from '@chakra-ui/react'
import { Card } from '../../../../shared/Cards'
import { Error } from '../../../../shared/Error'
import { useOrgBilling, useAccountBilling } from '../../../../shared/hooks'
import { getRangeOfCurrentMonth } from '../utils'
import { formatCurrency } from '../../../../../helpers/currency'

const Summary = ({ orgId, show }) => {
  const { billing, isLoadingBilling, isErrorBilling } = orgId
    ? useOrgBilling({ orgId })
    : useAccountBilling()

  return (
    <Collapse in={show}>
      {isLoadingBilling ? (
        <Skeleton w="220px" h="80px" />
      ) : isErrorBilling ? (
        <Error />
      ) : (
        <Card p={2}>
          <List spacing={1}>
            <ListItem display="flex" justifyContent="space-between" gap={3}>
              <Text color="gray.500">Billing period</Text>
              <Text>{getRangeOfCurrentMonth()}</Text>
            </ListItem>
            <Divider borderColor="gray.300" />
            <ListItem display="flex" justifyContent="space-between" gap={3}>
              <Text color="gray.500">Projected cost</Text>
              <Text>{formatCurrency(billing?.totalMonth)}</Text>
            </ListItem>
          </List>
        </Card>
      )}
    </Collapse>
  )
}

export default Summary
