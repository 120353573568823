const Button = {
  baseStyle: {
    borderRadius: '20px',
    fontWeight: 'semibold',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    _hover: {
      textDecoration: 'none'
    },
    _active: {
      boxShadow: 'none'
    }
  },
  sizes: {
    md: {
      px: 4
    }
  },
  variants: {
    solid: ({ colorScheme }) => ({
      bg: `${colorScheme}.500`,
      color: 'white',
      _hover: {
        bg: `${colorScheme}.600`,
        color: 'white'
      },
      _active: {
        bg: `${colorScheme}.700`
      },
      _disabled: {
        color: 'white'
      }
    }),
    outline: ({ colorScheme }) => ({
      color: `${colorScheme}.500`,
      bg: 'white',
      border: '1px solid',
      borderColor: `${colorScheme}.500`,
      _hover: {
        color: `${colorScheme}.500`,
        bg: `${colorScheme}.100`
      },
      _active: {
        bg: `${colorScheme}.300`
      },
      _disabled: {
        color: `${colorScheme}.500`
      }
    }),
    input: ({ colorScheme }) => ({
      color: 'gray.700',
      bg: `${colorScheme}.100`,
      fontWeight: 'normal',
      _hover: {
        bg: `${colorScheme}.200`,
        _disabled: {
          bg: `${colorScheme}.100`
        }
      },
      _active: {
        bg: `${colorScheme}.300`
      }
    }),
    icon: {
      h: 4,
      bg: 'transparent',
      _hover: {
        '> svg': {
          color: 'black'
        }
      },
      _focus: {
        boxShadow: 'none'
      }
    }
  },
  defaultProps: {
    size: 'md',
    variant: 'solid',
    colorScheme: 'blue'
  }
}

export default Button
