import sshpk from 'sshpk'

export const validatePublicKey = (publicKey) => {
  let details = {}

  try {
    const parsedKey = sshpk.parseKey(publicKey, 'ssh')
    details = {
      isValid: true,
      name: parsedKey.comment
    }
  } catch {
    details = {
      isValid: false,
      name: ''
    }
  }

  return details
}
