import { Fragment } from 'react'
import { Stack, Flex, Divider, Text } from '@chakra-ui/react'

const Price = ({ items }) => (
  <Stack>
    {items.map(({ label, value }) => {
      const isTotal = label.includes('Total')

      return (
        <Fragment key={label}>
          {isTotal && <Divider borderColor="gray.300" />}
          <Flex justifyContent="space-between">
            <Text size="sm" fontWeight={isTotal ? 'semibold' : 'normal'}>
              {label}
            </Text>
            <Text size="sm" fontWeight={isTotal ? 'semibold' : 'normal'}>
              {value}
            </Text>
          </Flex>
        </Fragment>
      )
    })}
  </Stack>
)

export default Price
