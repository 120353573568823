const getCountryName = (region) => {
  const array = region.split(', ')
  const country = array[array.length - 1]
  return country
}

const configurationOptions = ({
  country,
  gpuCount,
  cpuCount,
  ram,
  storage
}) => {
  const countryValue = country.split(' ').join('-').toLowerCase()

  const value = `${countryValue}-${gpuCount}-${cpuCount}-${ram}-${storage}`
  const label = `${country}, GPUs: ${gpuCount}GiB, CPUs: ${cpuCount}, RAM: ${ram}GiB, Storage: ${storage}GiB`

  return { value, label }
}

export const transformInstances = (instances) =>
  instances.map((instance) => {
    const country = getCountryName(instance.region)
    const { value, label } = configurationOptions({ country, ...instance })

    return {
      country,
      value,
      label,
      ...instance
    }
  })

const isNotDuplicate = (acc, instance) =>
  !acc.find((item) => item.value === instance.gpuType)

export const getTypeOptions = (instances) =>
  instances.reduce(
    (acc, instance) =>
      isNotDuplicate(acc, instance)
        ? [...acc, { value: instance.gpuType, label: instance.gpuName }]
        : acc,
    []
  )

export const getInstancesGroupByType = (instances) =>
  instances.reduce(
    (acc, instance) =>
      acc[instance.gpuType]
        ? {
            ...acc,
            [instance.gpuType]: [...acc[instance.gpuType], instance]
          }
        : {
            ...acc,
            [instance.gpuType]: [instance]
          },
    {}
  )

export const transformPayload = (values) => {
  const { name, authentication, configuration } = values
  const { gpuType, storage, cpuCount, gpuCount, ram, region } =
    configuration.selected

  return {
    name,
    password: authentication.password,
    type: gpuType,
    storage,
    cpuCount,
    gpuCount,
    ram,
    region
  }
}
