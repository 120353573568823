import { Heading, Stack, Text } from '@chakra-ui/react'
import { Card } from '../../../shared/Cards'
import PublicIp from '../../../shared/PublicIp'

export const ConnectToYourInstance = ({ publicIp }) => (
  <Card as={Stack} spacing={2}>
    <Heading size="xl">Connect to your Instance</Heading>
    <Stack spacing={1}>
      <Text>SSH Command</Text>
      <PublicIp publicIp={publicIp} />
    </Stack>
  </Card>
)

export const Volumes = () => (
  <Card>
    <Heading size="xl">Volumes</Heading>
  </Card>
)
