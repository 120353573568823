import {
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { Link as ReactLink, useHistory } from 'react-router-dom'
import routes from '../../../../../../constants/routes'
import { formatDateTime } from '../../../../../../helpers/date'
import { getDefaultProjectId } from '../../../../../../helpers/organizations'
import { humanize } from '../../../../../../helpers/string'
import { withOrgPath } from '../../../../../../router'
import { idToDeleteUpdated } from '../../../../../../slices/ui/organizations'
import { DefaultBadge } from '../../../../../shared/Badge'
import { Bin, Close, Dots, Settings, Star } from '../../../../../shared/Icons'
import { RemoveDefaultModal, MakeDefaultModal, LeaveModal } from '../modals'
import { TdTruncated } from '../../../../../shared/Table'
import {
  useIsOwner,
  useSelfRole
} from '../../../../../shared/hooks/organization'

const TableRow = ({ account, organization }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const leaveDisclosure = useDisclosure()
  const makeDefaultDisclosure = useDisclosure()
  const removeDefaultDisclosure = useDisclosure()
  const isOwner = useIsOwner(organization)
  const isDefaultOrganization =
    account?.defaultOrganizationId === organization.id
  const selfRole = useSelfRole(organization)

  return (
    <Tr>
      <TdTruncated>
        <Link
          colorScheme="gray"
          as={ReactLink}
          to={withOrgPath(
            routes.dashboard.settings,
            organization.id,
            getDefaultProjectId(organization)
          )}
        >
          {organization.name}
        </Link>
        {isDefaultOrganization && <DefaultBadge ml={2} />}
      </TdTruncated>
      <Td>{isOwner ? 'Owner' : humanize(selfRole)}</Td>
      <Td>{organization.requiresMfa ? 'Required' : 'Not required'}</Td>
      <Td>{formatDateTime(organization.createdAt)}</Td>
      <Td isNumeric>
        <Menu variant="table" autoSelect={false}>
          <MenuButton variant="icon" as={IconButton} icon={<Dots />} />
          <MenuList>
            <MenuItem
              onClick={() =>
                history.push(
                  withOrgPath(
                    routes.dashboard.settings,
                    organization.id,
                    getDefaultProjectId(organization)
                  )
                )
              }
            >
              <Settings mr={2.5} />
              Organization settings
            </MenuItem>
            {isDefaultOrganization ? (
              <>
                <MenuItem onClick={removeDefaultDisclosure.onOpen}>
                  <Star boxSize={5} mr={2.5} />
                  Remove default
                </MenuItem>
                <RemoveDefaultModal
                  disclosure={removeDefaultDisclosure}
                  orgId={organization.id}
                />
              </>
            ) : (
              <>
                <MenuItem onClick={makeDefaultDisclosure.onOpen}>
                  <Star boxSize={5} mr={2.5} />
                  Make default
                </MenuItem>
                <MakeDefaultModal
                  disclosure={makeDefaultDisclosure}
                  orgId={organization.id}
                />
              </>
            )}
            {isOwner ? (
              <>
                <MenuItem
                  onClick={() => dispatch(idToDeleteUpdated(organization.id))}
                >
                  <Bin mr={2.5} />
                  Delete
                </MenuItem>
              </>
            ) : (
              <>
                <MenuItem onClick={leaveDisclosure.onOpen}>
                  <Close mr={2.5} />
                  Leave
                </MenuItem>
                <LeaveModal
                  disclosure={leaveDisclosure}
                  orgId={organization.id}
                />
              </>
            )}
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  )
}

export default TableRow
