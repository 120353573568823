import {
  getNameBySlug,
  getSlugByName,
  names,
  slugs,
  types,
  units
} from '../../../../helpers/billing'
import { removeUnderscore, toCamel } from '../../../../helpers/serializers'

const transformIsEnabled = ({ type, slug }) =>
  type.regions.some((region) => region === slug)

const transformDescription = ({ category, type }) => ({
  name:
    category !== 'cpu'
      ? type.details.gpu.description
      : type.details.vcpu.description,
  subtitle: type.subtitle
})

const transformConfiguration = ({ configuration, sliderValue, details }) => {
  const newConfiguration = {
    slug: configuration.slug,
    sliderValue,
    memory: {
      value: configuration.memory,
      description: details.memory.description
    },
    vcpu: {
      value: configuration.vcpu,
      description: details.vcpu.description
    },
    storage: {
      value: configuration.storage
    }
  }

  if (details.gpu) {
    newConfiguration.gpu = {
      value: configuration.gpu,
      description: details.gpu.description
    }

    newConfiguration.videoMemory = {
      value: details.gpu.memory.value
    }
  }

  return newConfiguration
}

const transformConfigurations = ({ configurations, details }) =>
  configurations.map((configuration, index) =>
    transformConfiguration({
      configuration,
      sliderValue: index + 1,
      details
    })
  )

const transformSelected = ({ configurations, details }) => {
  const selected = transformConfiguration({
    configuration: configurations[0],
    sliderValue: 1,
    details
  })

  return selected
}

export const transformType = ({ type, region, category }) => ({
  slug: type.slug,
  flavorName: type.flavorName,
  isEnabledAccount: type.enabled,
  isEnabled: transformIsEnabled({ type, slug: region.slug }),
  category: {
    slug: category.slug,
    index: category.index
  },
  description: transformDescription({ category: category.slug, type }),
  configurations: {
    items: transformConfigurations({
      configurations: type.configurations,
      details: type.details
    }),
    selected: transformSelected({
      configurations: type.configurations,
      details: type.details
    })
  },
  regions: type.regions,
  labels: type.labels,
  drivers: type.drivers,
  billingTypes: type.billingTypes
})

const transformTypes = ({ configurations, region, category }) =>
  configurations.instanceTypes
    .filter(
      (type) => type.category === category.slug && type.enabled && !type.legacy
    )
    .map((type) => transformType({ type, region, category }))

export const transformInstanceTypes = (configurations) =>
  configurations?.regions.map((region) => ({
    slug: region.slug,
    categories: configurations.categories.map((category, index) => ({
      slug: category.slug,
      title: category.title,
      labels: category.labels,
      types: transformTypes({
        configurations,
        region,
        category: { ...category, index }
      })
    }))
  }))

const transformSub = (price) => {
  const unit = price.name === names.onDemand ? units.hour : units.month

  let newPrice = {
    id: getSlugByName(price.name),
    slug: getSlugByName(price.name),
    name: price.name,
    amount: price.amount,
    unit
  }

  if (price.name === names.prMonthly) {
    newPrice = {
      ...newPrice,
      intervalCount: 1
    }
  }

  if (price.intervalCount) {
    newPrice = {
      ...newPrice,
      intervalCount: price.intervalCount
    }
  }

  return newPrice
}

export const transformSubs = ({
  pricing,
  billingTypes,
  enableQuotaReservation
}) => {
  if (!pricing) return []
  let onDemand = { name: types.onDemand, isDisabled: true, items: [] }
  let longTerm = { name: types.longTerm, isDisabled: true, items: [] }
  let reserved = { name: types.reserved, isDisabled: true, items: [] }

  const isReservation = Boolean(
    billingTypes?.find((type) => type === slugs.reserved)
  )

  if (pricing.onDemand) {
    onDemand = {
      ...onDemand,
      isDisabled: false,
      items: [transformSub(pricing.onDemand)]
    }
  }
  if (pricing.prepaidMonthly) {
    longTerm = {
      ...longTerm,
      isDisabled: false,
      items: [...longTerm.items, transformSub(pricing.prepaidMonthly)]
    }
  }
  if (pricing['prepaid-3Month']) {
    longTerm = {
      ...longTerm,
      isDisabled: false,
      items: [...longTerm.items, transformSub(pricing['prepaid-3Month'])]
    }
  }
  if (pricing['prepaid-6Month']) {
    longTerm = {
      ...longTerm,
      isDisabled: false,
      items: [...longTerm.items, transformSub(pricing['prepaid-6Month'])]
    }
  }
  if (pricing['prepaid-12Month']) {
    longTerm = {
      ...longTerm,
      isDisabled: false,
      items: [...longTerm.items, transformSub(pricing['prepaid-12Month'])]
    }
  }

  if (enableQuotaReservation && isReservation) {
    reserved = {
      ...reserved,
      isDisabled: false
    }
    return [onDemand, reserved]
  }

  return [onDemand, longTerm]
}

const transformUnusedSub = (item) => ({
  id: item.id,
  type: item.type,
  slug: item.billingType,
  amount: item.amount,
  name: getNameBySlug(item.billingType),
  intervalCount: item.period,
  unit: units.month,
  renewsAt: item.renewsAt
})

export const transformUnusedSubs = (items) =>
  items?.map((item) => transformUnusedSub(item))

export const useType = ({ region, config }) =>
  config
    ?.find((item) => item.slug === region)
    .categories?.find((category) =>
      category.types.find((item) => item.isEnabled === true)
    )
    .types.find((item) => item.isEnabled === true)

export const useCategories = ({ region, config }) =>
  config?.find((item) => item.slug === region).categories

export const useQuota = ({ capacity, type }) => {
  let quota = null
  let count = null
  const data = Object.keys(capacity).length > 0 && type

  if (!data) return { quota, count }

  if (data && type.slug) {
    const removedUnderscore = removeUnderscore(type.slug)
    const slug = toCamel(removedUnderscore)
    count = capacity[slug].count
    quota = capacity[slug].quota
  }

  return { quota, count }
}

export const hasQuotaCapacity = ({ sliderValue, capacity, type }) => {
  const { quota, count } = useQuota({
    capacity,
    type
  })

  return count + sliderValue > quota
}

export const hasClusterAvailability = ({
  availability,
  region,
  typeConfigSlug
}) => {
  let hasAvailability = false
  const cluster = availability && availability[region]

  if (cluster && typeConfigSlug && cluster[typeConfigSlug] === false) {
    hasAvailability = true
  }

  return hasAvailability
}

export const hasRegion = ({ region, type }) =>
  type.isEnabled && type.regions.some((item) => item === region)

export const getInstanceTypeDescription = ({ type }) => {
  return type?.flavorName.startsWith('cpu')
    ? type?.details.vcpu.description
    : type?.details.gpu.description
}
