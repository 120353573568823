import { useParams } from 'react-router-dom'
import { Stack, Heading, Skeleton } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { useConfigs, useProjectVersion } from '../../../../shared/hooks'
import { Error } from '../../../../shared/Error'
import StaticIP from './StaticIP'
import FloatingIP from './FloatingIP'
import Scripts from './Scripts'
import Drivers from './Drivers'

const Options = () => {
  const { isValidatingConfigs, isErrorConfigs } = useConfigs({})

  const { orgId, projectId } = useParams()
  const { isB2 } = useProjectVersion({ orgId, projectId })

  const { values } = useFormikContext()
  const drivers = values.type?.drivers
  const hasDriversSupport = values.options.hasDriversSupport
  const showDrivers = !!drivers && hasDriversSupport

  return (
    <Stack spacing={8}>
      <Heading size="xl">Additional options</Heading>
      {isValidatingConfigs ? (
        <Skeleton h="460px" />
      ) : isErrorConfigs ? (
        <Error />
      ) : (
        <Stack spacing={5}>
          {isB2 ? <FloatingIP /> : <StaticIP />}
          <Scripts />
          {showDrivers && <Drivers drivers={drivers} />}
        </Stack>
      )}
    </Stack>
  )
}

export default Options
