import { Text } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { useQuota } from '../../../../shared/hooks/configs/instance'

const Quota = () => {
  const { values, errors } = useFormikContext()
  const { type, capacity } = values
  const color = errors.capacity ? 'red.500' : 'gray.500'
  const { quota, count } = useQuota({
    capacity,
    type
  })
  const slug = type?.category?.slug
  const quotaValue = quota - count

  return (
    <Text px={5} py={6} color={color}>
      Available Quota: {quotaValue} {slug === 'cpu' ? 'vCPUs' : 'GPUs'}
    </Text>
  )
}

export default Quota
