import { Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { SkeletonTable } from '../../../../shared/Skeletons'
import {
  INITIAL_PAGE,
  TableCell,
  TableError,
  TablePagination
} from '../../../../shared/Table'
import {
  useAccountSecurityGroups,
  useOrgSecurityGroups
} from '../../../../shared/hooks/securityGroups'
import TableRow from './table-row'

const SecurityGroups = ({ hasPermission }) => {
  const { orgId, projectId } = useParams()
  const [page, setPage] = useState(INITIAL_PAGE)

  const { securityGroups, isError, isLoading } =
    orgId && projectId
      ? useOrgSecurityGroups({ orgId, projectId, page })
      : useAccountSecurityGroups({ page })
  const isTableEmpty = securityGroups?.securityGroups.length === 0

  return (
    <>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th w="15%">Name</Th>
              <Th w="15%">Location</Th>
              <Th w="20%">Description</Th>
              <Th w="10%">
                Inbound <br /> Rules
              </Th>
              <Th w="10%">
                Outbound <br /> Rules
              </Th>
              <Th w="10%">Instances</Th>
              <Th w="10%">Created</Th>
              <Th w="5%" />
              <Th w="5%" />
            </Tr>
          </Thead>
          {isLoading && <SkeletonTable rows={10} columns={8} />}
          {isError ? (
            <TableError>
              Something went wrong loading the security groups.
            </TableError>
          ) : (
            <Tbody>
              {isTableEmpty ? (
                <TableCell>There are no security groups yet.</TableCell>
              ) : (
                securityGroups?.securityGroups?.map((securityGroup) => (
                  <TableRow
                    key={securityGroup.id}
                    securityGroup={securityGroup}
                    hasPermission={hasPermission}
                  />
                ))
              )}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        page={page}
        setPage={setPage}
        totalCount={securityGroups?.totalCount}
      />
    </>
  )
}

export default SecurityGroups
