import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  Stack,
  Text
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { useCallback, useMemo } from 'react'
import { Link as ReactLink } from 'react-router-dom'
import { mutate } from 'swr'
import * as yup from 'yup'
import { Organization } from '../../../../../../api/organizations'
import routes from '../../../../../../constants/routes'
import { toastMessages } from '../../../../../../constants/toast-messages'
import { getDefaultProjectId } from '../../../../../../helpers/organizations'
import { serialize } from '../../../../../../helpers/serializers'
import { withOrgPath } from '../../../../../../router'
import Asterisk from '../../../../../shared/Asterisk'
import { useToast } from '../../../../../shared/Toast'
import {
  useOrganization,
  useOrganizationMembers
} from '../../../../../shared/hooks/organization'

const validationSchema = yup.object().shape({
  newOwnerId: yup.string().required('Owner is required')
})

const initialValues = { newOwnerId: '' }

export const OwnershipModal = ({ disclosure }) => {
  const { isOpen, onClose } = disclosure
  const toast = useToast()
  const { organization } = useOrganization()
  const { members, isError } = useOrganizationMembers({
    orgId: organization.id,
    perPage: 100
  })

  const hasMembers = useMemo(() => {
    if (organization && members) {
      return !(
        members.members.length === 1 &&
        members.members[0].accountId === organization.ownerId
      )
    }
  }, [members, organization])

  const onSubmit = useCallback(
    async ({ newOwnerId }, { setSubmitting, resetForm }) => {
      const member = members.members.find((m) => m.accountId === newOwnerId)
      if (!member) {
        return
      }
      setSubmitting(true)
      try {
        const result = await Organization.transferById(organization.id, {
          account_id: newOwnerId
        })
        await mutate(`/organizations/${result.id}`)
        toast({
          status: 'success',
          message: toastMessages.organizationTransferSuccess
        })
        onClose()
        resetForm()
      } catch {
        toast({
          status: 'error',
          message: toastMessages.organizationTransferError
        })
      } finally {
        setSubmitting(false)
      }
    },
    [organization, members]
  )

  if (isError) {
    return null
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Heading size="xl" mb={4} textAlign="center">
            Transfer ownership
          </Heading>
          <Text
            mb={8}
            size="md"
            textAlign="center"
            color="red.500"
            fontWeight="normal"
          >
            All owner permissions will be transferred immediately. The billing
            details and payment method will stay the same.
          </Text>
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, errors, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <Stack spacing={8}>
                  <FormControl isInvalid={errors.newOwnerId}>
                    <FormLabel>
                      <Asterisk />
                      New Organization owner
                    </FormLabel>
                    <Field
                      as={Select}
                      name="newOwnerId"
                      placeholder="Select new owner"
                      isDisabled={!hasMembers}
                    >
                      {members &&
                        serialize(members.members)
                          .filter((m) => m.accountId !== organization.ownerId)
                          .map((m) => (
                            <option key={m.accountId} value={m.accountId}>
                              {m.account.email}
                            </option>
                          ))}
                    </Field>
                    {!hasMembers && (
                      <FormHelperText>
                        This Organization has no Members, please{' '}
                        <Link
                          colorScheme="offBlack"
                          as={ReactLink}
                          to={withOrgPath(
                            routes.dashboard.members,
                            organization.id,
                            getDefaultProjectId(organization)
                          )}
                        >
                          invite Members here
                        </Link>
                        .
                      </FormHelperText>
                    )}
                    <FormErrorMessage>{errors.newOwnerId}</FormErrorMessage>
                  </FormControl>
                  <Stack spacing={4}>
                    <Button
                      type="submit"
                      isDisabled={isSubmitting || !hasMembers}
                      isLoading={isSubmitting}
                    >
                      Transfer ownership
                    </Button>
                    <Button
                      colorScheme="offBlack"
                      variant="outline"
                      isDisabled={isSubmitting}
                      onClick={onClose}
                      isFullWidth
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
