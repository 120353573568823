import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { useCallback } from 'react'
import { useSWRConfig } from 'swr'
import * as yup from 'yup'
import { Organization } from '../../../../../../api/organizations'
import { toastMessages } from '../../../../../../constants/toast-messages'
import Asterisk from '../../../../../shared/Asterisk'
import { useToast } from '../../../../../shared/Toast'
import { useOrganization } from '../../../../../shared/hooks/organization'

const validationSchema = yup.object().shape({
  name: yup.string().required('Organization name is required'),
  website: yup.string(),
  linkedIn: yup.string()
})

export const ProfileModal = ({ disclosure }) => {
  const { isOpen, onClose } = disclosure
  const { mutate } = useSWRConfig()
  const { organization } = useOrganization()
  const toast = useToast()

  const initialValues = {
    name: organization.name || '',
    website: organization.website || '',
    linkedIn: organization.linkedinProfile || ''
  }

  const onSubmit = useCallback(
    async ({ name, website, linkedIn }, { setSubmitting }) => {
      setSubmitting(true)
      try {
        const result = await Organization.updateById(organization.id, {
          name,
          website,
          linkedin_profile: linkedIn
        })
        await mutate(`/organizations/${result.id}`)
        toast({
          status: 'success',
          message: toastMessages.saved
        })
        onClose()
      } catch {
        toast({
          status: 'error',
          message: toastMessages.notSaved
        })
      } finally {
        setSubmitting(false)
      }
    },
    [organization]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Heading size="xl" mb={8} textAlign="center">
            Edit Organization details
          </Heading>
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, errors, touched, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <Stack spacing={8}>
                  <FormControl isInvalid={errors.name && touched.name}>
                    <FormLabel>
                      <Asterisk />
                      Organization name
                    </FormLabel>
                    <Field
                      as={Input}
                      name="name"
                      type="text"
                      autoFocus={true}
                    />
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.website && touched.website}>
                    <FormLabel>Website</FormLabel>
                    <Field as={Input} name="website" placeholder="Enter URL" />
                    <FormErrorMessage>{errors.website}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.linkedIn && touched.linkedIn}>
                    <FormLabel>LinkedIn profile</FormLabel>
                    <Field as={Input} name="linkedIn" placeholder="Enter URL" />
                    <FormErrorMessage>{errors.linkedIn}</FormErrorMessage>
                  </FormControl>
                  <Stack spacing={4}>
                    <Button
                      type="submit"
                      isDisabled={isSubmitting}
                      isLoading={isSubmitting}
                    >
                      Save
                    </Button>
                    <Button
                      colorScheme="offBlack"
                      variant="outline"
                      isDisabled={isSubmitting}
                      onClick={onClose}
                      isFullWidth
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
