import { Tr, Td } from '@chakra-ui/react'
import { TdTruncated, TdTruncatedLines } from '../../../../../shared/Table'
import { formatDateTime } from '../../../../../../helpers/date'
import { formatCurrency } from '../../../../../../helpers/currency'
import { getOnDemandUsage, getOnDemandCost } from '../../utils'

const TableRow = ({ row }) => {
  const { name, product, startedAt, endedAt, unitPrice } = row

  const usage = getOnDemandUsage({ startedAt, endedAt })
  const cost = getOnDemandCost({ unitPrice, startedAt, endedAt })

  return (
    <Tr>
      <TdTruncated>{name}</TdTruncated>
      <TdTruncatedLines noOfLines={2}>{product}</TdTruncatedLines>
      <Td>{formatDateTime(startedAt)}</Td>
      <Td>{formatDateTime(endedAt) || '-'}</Td>
      <Td>{usage}</Td>
      <Td>{formatCurrency(cost)}</Td>
    </Tr>
  )
}

export default TableRow
