import { Flex, Text, useClipboard } from '@chakra-ui/react'
import { Copy, Check } from '../../shared/Icons'

const CopyButton = ({
  value,
  copyValue,
  color = 'gray.500',
  noOfLines = 1,
  lineHeight = '24px',
  ...props
}) => {
  const { onCopy, hasCopied } = useClipboard(copyValue || value)
  const childrenProps = {
    color,
    cursor: 'pointer',
    _groupHover: { color: 'black' }
  }

  return (
    <Flex role="group" gap="1" alignItems="center" onClick={onCopy} {...props}>
      <Text lineHeight={lineHeight} noOfLines={noOfLines} {...childrenProps}>
        {value}
      </Text>
      {hasCopied ? (
        <Check boxSize={4} {...childrenProps} />
      ) : (
        <Copy boxSize={4} {...childrenProps} />
      )}
    </Flex>
  )
}

export default CopyButton
