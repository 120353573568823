export const businessSize = {
  BS_1_10: '1-10',
  BS_11_50: '11-50',
  BS_51_100: '51-100',
  BS_100p: '100+'
}

export const businessIndustries = {
  ADMINISTRATIVE_SERVICES: 'Administrative Services',
  ADVERTISING: 'Advertising',
  AGRICULTURE_AND_FARMING: 'Agriculture and Farming',
  APPS: 'Apps',
  ARTIFICIAL_INTELLIGENCE: 'Artificial Intelligence',
  BIOTECHNOLOGY: 'Biotechnology',
  CLOTHING_AND_APPAREL: 'Clothing and Apparel',
  COMMERCE_AND_SHOPPING: 'Commerce and Shopping',
  COMMUNITY_AND_LIFESTYLE: 'Community and Lifestyle',
  CONSUMER_ELECTRONICS: 'Consumer Electronics',
  CONSUMER_GOODS: 'Consumer Goods',
  CONTENT_AND_PUBLISHING: 'Content and Publishing',
  DATA_ANALYTICS: 'Data Analytics',
  DESIGN: 'Design',
  EDUCATION: 'Education',
  ENERGY: 'Energy',
  EVENTS: 'Events',
  FINANCIAL_SERVICES: 'Financial Services',
  FOOD_AND_BEVERAGES: 'Food and Beverages',
  GAMING: 'Gaming',
  GOVERNMENT_AND_MILITARY: 'Government and Military',
  HARDWARE: 'Hardware',
  HEALTH_CARE: 'Health Care',
  INFORMATION_TECHNOLOGY: 'Information Technology',
  INTERNET_SERVICES: 'Internet Services',
  LENDING_AND_INVESTMENTS: 'Lending and Investments',
  MANUFACTURING: 'Manufacturing',
  MEDIA_AND_ENTERTAINMENT: 'Media and Entertainment',
  MESSAGING_AND_TELECOMMUNICATIONS: 'Messaging and Telecommunications',
  MOBILE: 'Mobile',
  MUSIC_AND_AUDIO: 'Music and Audio',
  NATURAL_RESOURCES: 'Natural Resources',
  NAVIGATION_AND_MAPPING: 'Navigation and Mapping',
  OTHER: 'Other',
  PAYMENTS: 'Payments',
  PLATFORMS: 'Platforms',
  PRIVACY_AND_SECURITY: 'Privacy and Security',
  PROFESSIONAL_SERVICES: 'Professional Services',
  REAL_ESTATE: 'Real Estate',
  SALES_AND_MARKETING: 'Sales and Marketing',
  SCIENCE_AND_ENGINEERING: 'Science and Engineering',
  SOFTWARE: 'Software',
  SPORTS: 'Sports',
  SUSTAINABILITY: 'Sustainability',
  TRANSPORTATION: 'Transportation',
  TRAVEL_AND_TOURISM: 'Travel and Tourism',
  VIDEO: 'Video'
}

export const interests = {
  BLOCKCHAIN_PROCESSING: 'Blockchain processing',
  CAD: 'CAD',
  CLOUD_GAMING: 'Cloud gaming',
  CRYPTO_MINING: 'Crypto mining',
  DATA_ANALYTICS: 'Data analytics',
  DATA_STORAGE: 'Data storage',
  IT_SECURITY: 'IT security',
  MACHINE_LEARNING_MODEL_INFERENCE: 'Machine learning model inference',
  MACHINE_LEARNING_MODEL_TRAINING: 'Machine learning model training',
  NOT_SURE_YET: "I'm not sure yet",
  REMOTE_WORKSTATION: 'Remote workstation',
  RENDERING: 'Rendering',
  VFX: 'VFX',
  OTHER: 'Other'
}

export const roles = {
  ASSISTANT_ASSOCIATE_PROFESSOR: 'Assistant / Associate Professor',
  CDO_HEAD_OF_ANALYTICS: 'CDO / Head of Analytics',
  CEO_FOUNDER: 'CEO / Founder',
  CONSULTANT: 'Consultant',
  COO_HEAD_OF_OPERATIONS: 'COO / Head of Operations',
  CTO_HEAD_OF_IT: 'CTO / Head of IT',
  DATA_SCIENTIST: 'Data Scientist',
  DEVELOPER_ENGINEER: 'Developer / Software Engineer',
  DEVOPS_ENGINEER: 'DevOps Engineer',
  FREELANCER: 'Freelancer',
  MLOPS_ENGINEER: 'MLOps Engineer',
  PHD: 'PhD candidate',
  PROFESSOR: 'Professor',
  RENDERING_ENGINEER: 'Rendering Engineer',
  RESEARCHER: 'Researcher',
  SOLUTIONS_SYSTEM_ARCHITECT: 'Solution / System Architect',
  STUDENT: 'Student',
  TECHNICAL_TEAM_LEAD_MANAGER: 'Technical team lead / Engineering manager',
  VISUAL_DESIGNER: 'Visual Designer',
  WORKER_BOT_OFFICE_DRONE: 'Worker bot / Office drone',
  OTHER: 'Other'
}

export const institutionType = {
  NON_PROFIT: 'Non-profit',
  UNIVERSITY: 'University',
  OTHER_EDUCATIONAL_INSTITUTION: 'Other educational institution',
  GOVERNMENTAL: 'Governmental',
  CHARITY: 'Charity',
  COMMUNITY: 'Community'
}

export const institutionFunding = {
  PRIVATELY: 'Privately',
  PUBLICLY: 'Publicly',
  BOTH: 'Both'
}
