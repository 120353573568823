const textStyles = {
  xs: {
    fontSize: 'xs',
    fontWeight: 'normal',
    lineHeight: 'base',
    letterSpacing: 'normal'
  },
  sm: {
    fontSize: 'sm',
    fontWeight: 'normal',
    lineHeight: 'base',
    letterSpacing: 'normal'
  },
  md: {
    fontSize: 'md',
    fontWeight: 'bold',
    lineHeight: 'base',
    letterSpacing: 'tight'
  },
  lg: {
    fontSize: 'lg',
    fontWeight: 'normal',
    lineHeight: 'shorter',
    letterSpacing: 'tight'
  },
  xl: {
    fontSize: 'xl',
    fontWeight: 'bold',
    lineHeight: 'shorter',
    letterSpacing: 'tight'
  },
  '2xl': {
    fontSize: '2xl',
    fontWeight: 'semibold',
    lineHeight: 'shorter',
    letterSpacing: 'tight'
  },
  '3xl': {
    fontSize: '3xl',
    fontWeight: 'bold',
    lineHeight: 'shorter',
    letterSpacing: 'tight'
  },
  '4xl': {
    fontSize: '4xl',
    fontWeight: 'bold',
    lineHeight: 'shorter',
    letterSpacing: 'tight'
  },
  '5xl': {
    fontSize: '5xl',
    fontWeight: 'bold',
    lineHeight: 'none',
    letterSpacing: 'tighter'
  }
}

export default textStyles
