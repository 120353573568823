// Images
export const DEFAULT_IMAGE = {
  id: '',
  name: '',
  tab: '',
  selected: {}
}

export const DEFAULT_CATALOG = {
  id: '',
  name: '',
  password: '',
  hasPassword: false
}

// authentications
export const SSH_KEYS = 'sshKeys'
export const PASSWORD = 'password'

export const DEFAULT_METHOD = SSH_KEYS

export const methodsName = {
  [SSH_KEYS]: 'SSH Keys',
  [PASSWORD]: 'Password'
}

export const PASSWORD_OPTIONS = {
  length: 16,
  numbers: true
}

// networks
export const DEFAULT_NETWORK = {
  securityGroups: [],
  isEmpty: true
}

// options
export const DEFAULT_OPTIONS = {
  isStaticIPChecked: false,
  isFloatingIPChecked: false,
  floatingIP: '',
  isScriptsChecked: false,
  scripts: '',
  hasDriversSupport: false,
  isDriversRequired: false,
  driver: ''
}

// params
export const DEFAULT_PARAMS = {
  snapshot: {},
  isFirstLoad: true
}
