import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { deleteInstance } from '../../../../helpers/instances'
import { useToast } from '../../../shared/Toast'
import { useIsMounted } from '../../../shared/hooks/useIsMounted'
import { withOrgPathFallback } from '../../../../router'
import routes from '../../../../constants/routes'

export const CancelModal = ({ disclosure, instance }) => {
  const { orgId, projectId } = useParams()
  const { isOpen, onClose } = disclosure
  const [isSubmitting, setIsSubmitting] = useState(false)
  const isMounted = useIsMounted()
  const toast = useToast()
  const history = useHistory()

  const redirect = () =>
    history.push(withOrgPathFallback(routes.dashboard.instances.index))

  const handleCancelInstance = useCallback(async () => {
    setIsSubmitting(true)
    await deleteInstance({
      instanceId: instance.id,
      projectId,
      orgId,
      toast,
      redirect
    })
    if (isMounted()) {
      setIsSubmitting(false)
      onClose()
    }
  }, [instance, projectId, orgId])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap={8}>
            <Heading size="xl" textAlign="center">
              Are you sure you want to cancel Instance “{instance.name}”
              creating?
            </Heading>
            <Text
              size="md"
              textAlign="center"
              color="red.500"
              fontWeight="normal"
            >
              The Instance cannot be recovered.
              <br />
              Volumes and Floating IPs assigned to this Instance will not be
              deleted.
            </Text>
            <Stack spacing={4}>
              <Button
                isLoading={isSubmitting}
                onClick={handleCancelInstance}
                colorScheme="red"
              >
                Cancel creating
              </Button>
              <Button
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isSubmitting}
                onClick={onClose}
              >
                Keep creating
              </Button>
            </Stack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
