import React, { Children, cloneElement } from 'react'
import {
  Box,
  Center,
  Circle as ChakraCircle,
  Flex,
  Text,
  useBreakpointValue,
  useMediaQuery
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import breakpoints from '../../theme/breakpoints'
import { Checkmark } from './Icons'

const MotionFlex = motion(Flex)

const animationConfig = {
  transition: {
    duration: 0.25
  },
  exit: { scale: 0.5, opacity: 0 },
  initial: { scale: 0.5, opacity: 0 },
  animate: { scale: 1, opacity: 1 }
}

const useTextColor = (color) => {
  const [isMobile] = useMediaQuery(`(max-width: ${breakpoints.lg})`)
  const textColor = isMobile ? 'black' : color

  return { textColor, isMobile }
}

const Label = ({ text, type, color }) => {
  const { textColor } = useTextColor(color)
  const size = useBreakpointValue({ base: 'sm', xl: 'lg' })

  return (
    <Text
      color={type === 'future' ? 'gray.500' : textColor}
      fontSize={size}
      whiteSpace="nowrap"
    >
      {text}
    </Text>
  )
}

const Circle = ({ children, ...props }) => {
  const size = useBreakpointValue({ base: 7, lg: 10 })
  const margin = useBreakpointValue({
    base: '5px',
    sm: '10px'
  })

  return (
    <ChakraCircle w={size} h={size} mr={margin} {...props}>
      {children}
    </ChakraCircle>
  )
}

export const Step = ({ index, children: name, type, labelColor }) => {
  const { textColor, isMobile } = useTextColor(labelColor)
  const checkmark = useBreakpointValue({ base: '12px', lg: '16px' })
  const margin = useBreakpointValue({
    base: '5px',
    sm: '10px'
  })

  return (
    <>
      {index !== 1 && (
        <Flex flexGrow={1} alignItems="center">
          <Box
            h="1px"
            w="100%"
            bg={type === 'past' || type === 'current' ? textColor : 'gray.300'}
          />
        </Flex>
      )}
      <Flex alignItems="center" w="fit-content" mx={margin}>
        {type === 'current' && (
          <>
            <Circle border="2px solid" borderColor="blue.500">
              <Label text={index} type={type} color={labelColor} />
            </Circle>
            {isMobile && <Label text={name} type={type} color={labelColor} />}
          </>
        )}
        {type === 'past' && (
          <MotionFlex {...animationConfig}>
            <Circle bg="blue.500">
              <Checkmark width={checkmark} height={checkmark} />
            </Circle>
          </MotionFlex>
        )}
        {type === 'future' && (
          <Circle border="1px solid" borderColor="gray.300">
            <Label text={index} type={type} color={labelColor} />
          </Circle>
        )}
        {!isMobile && <Label text={name} type={type} color={labelColor} />}
      </Flex>
    </>
  )
}

export const Steps = ({ children, labelColor = 'black' }) => {
  let currentIndex = null
  return (
    <Center px={5}>
      <Flex
        w="100%"
        maxW={{ base: '480px', lg: '1170px' }}
        px={{ sm: 10, lg: 6 }}
      >
        {Children.map(children, (child, index) => {
          let type
          if (child.props.path === window.location.pathname) {
            type = 'current'
            currentIndex = index
          } else if (currentIndex !== null && index > currentIndex) {
            type = 'future'
          } else if (!currentIndex) {
            type = 'past'
          }
          return cloneElement(child, {
            key: index,
            index: index + 1,
            label: child,
            type: type,
            labelColor: labelColor
          })
        })}
      </Flex>
    </Center>
  )
}
