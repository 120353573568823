import { Alert } from '../../../shared/Alert'
import { useIncidents } from '../../../shared/hooks/status-page'

const Incidents = () => {
  const { incidents, isLoadingIncidents, isErrorIncidents } = useIncidents()

  if (isLoadingIncidents) return null

  if (isErrorIncidents) return null

  return incidents?.map((incident) => (
    <Alert
      key={incident.id}
      message={incident.name}
      link={incident.shortlink}
    />
  ))
}

export default Incidents
