import {
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Tooltip
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { useState } from 'react'
import {
  label,
  rulesActions,
  rulesPorts,
  updateRules,
  validatePort
} from '../../../helpers/securityGroups'
import { Info } from '../Icons'

export const PortField = ({ rule, rulesName }) => {
  const { values, setFieldValue } = useFormikContext()
  const rules = values[rulesName]
  const [port, setPort] = useState(rule.port)
  const isError = !validatePort(port)

  const handleChange = (event) => {
    setPort(event.target.value)
    setFieldValue(
      rulesName,
      updateRules({
        action: rulesActions.updatePort,
        rules,
        ruleId: rule.id,
        newPort: event.target.value
      })
    )
  }

  return (
    <FormControl isInvalid={isError}>
      <HStack>
        <Input
          size="xs"
          value={port === rulesPorts.all ? '' : port}
          placeholder={rulesPorts.all}
          w={48}
          onChange={handleChange}
        />
        <Tooltip hasArrow label={label} w={64}>
          <div>
            <Info
              color="gray.500"
              _hover={{ cursor: 'pointer', color: 'gray.900' }}
            />
          </div>
        </Tooltip>
      </HStack>
      {isError && (
        <FormErrorMessage fontSize="12px">Invalid value.</FormErrorMessage>
      )}
    </FormControl>
  )
}
