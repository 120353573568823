import { INITIAL_PAGE, PER_PAGE } from '../components/shared/Table'
import { serialize } from '../helpers/serializers'
import { COMPUTE_ENDPOINT, apiRequest, ACCOUNT_ENDPOINT } from './index'

export class OrgSecurity {
  static fetchSSHKeys = async ({
    orgId,
    projectId,
    page = INITIAL_PAGE,
    perPage = PER_PAGE
  }) => {
    const sshKeys = await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/ssh-keys?page=${page}&per_page=${perPage}&org=${orgId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(sshKeys)
  }

  static fetchAPITokens = async ({
    orgId,
    projectId,
    page = INITIAL_PAGE,
    perPage = PER_PAGE
  }) => {
    const tokens = await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}/projects/${projectId}/api-keys?per_page=${perPage}&page=${page}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(tokens)
  }

  static addSSHKey = async ({ orgId, projectId, payload }) => {
    const sshKey = await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/ssh-keys?org=${orgId}`,
      {
        method: 'POST',
        body: JSON.stringify(payload)
      },
      true
    )
    return serialize(sshKey)
  }

  static generateAPIToken = async ({ orgId, projectId, payload }) => {
    const APIToken = await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}/projects/${projectId}/api-keys`,
      {
        method: 'POST',
        body: JSON.stringify({
          description: payload.description,
          api_key_type: payload.type
        })
      },
      true
    )
    return serialize(APIToken)
  }

  static updateSSHKey = async ({ orgId, projectId, sshKeyId, payload }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/ssh-keys/${sshKeyId}?org=${orgId}`,
      {
        method: 'PUT',
        body: JSON.stringify(payload)
      },
      true
    )

  static deleteSSHKey = async ({ orgId, projectId, sshKeyId }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/ssh-keys/${sshKeyId}?org=${orgId}`,
      {
        method: 'DELETE'
      },
      true
    )

  static deleteAPIToken = async ({ orgId, projectId, apiTokenId }) =>
    await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}/projects/${projectId}/api-keys/${apiTokenId}`,
      {
        method: 'DELETE'
      },
      true
    )
}

export class AccountSecurity {
  static fetchSSHKeys = async ({ page = INITIAL_PAGE, perPage = PER_PAGE }) => {
    const sshKeys = await apiRequest(
      `${COMPUTE_ENDPOINT}/ssh-keys?page=${page}&per_page=${perPage}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(sshKeys)
  }

  static fetchAPITokens = async ({
    page = INITIAL_PAGE,
    perPage = PER_PAGE
  }) => {
    const tokens = await apiRequest(
      `${ACCOUNT_ENDPOINT}/api-keys?per_page=${perPage}&page=${page}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(tokens)
  }

  static addSSHKey = async ({ payload }) => {
    const sshKey = await apiRequest(
      `${COMPUTE_ENDPOINT}/ssh-keys`,
      {
        method: 'POST',
        body: JSON.stringify(payload)
      },
      true
    )
    return serialize(sshKey)
  }

  static generateAPIToken = async ({ payload }) => {
    const APIToken = await apiRequest(
      `${ACCOUNT_ENDPOINT}/api-keys`,
      {
        method: 'POST',
        body: JSON.stringify({
          description: payload.description,
          api_key_type: payload.type
        })
      },
      true
    )
    return serialize(APIToken)
  }

  static updateSSHKey = async ({ sshKeyId, payload }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/ssh-keys/${sshKeyId}`,
      {
        method: 'PUT',
        body: JSON.stringify(payload)
      },
      true
    )

  static deleteSSHKey = async ({ sshKeyId }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/ssh-keys/${sshKeyId}`,
      {
        method: 'DELETE'
      },
      true
    )

  static deleteAPIToken = async ({ apiTokenId }) =>
    await apiRequest(
      `${ACCOUNT_ENDPOINT}/api-keys/${apiTokenId}`,
      {
        method: 'DELETE'
      },
      true
    )
}
