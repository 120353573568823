import {
  Button,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { Link as ReactLink } from 'react-router-dom'
import routes from '../../../../../constants/routes'
import { createDate } from '../../../../../helpers/billing'
import { withOrgPathFallback } from '../../../../../router'

const ExistingSubModal = ({ disclosure }) => {
  const { isOpen, onClose } = disclosure
  const { values, submitForm, isSubmitting } = useFormikContext()
  const date = createDate(values?.billing.renewsAt)

  const handleSubmit = () => {
    onClose()
    submitForm()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Heading size="xl" textAlign="center" mb={4}>
            Create Instance
          </Heading>
          <Stack spacing={8}>
            <Text size="sm" textAlign="center" color="gray.500">
              Your subscription is valid until {date}. It will automatically
              renew at the end of the current period.
            </Text>
            <Text size="sm" textAlign="center" color="red.500">
              Note: Destroying the Instance does not cancel your subscription.
              To cancel, please navigate to{' '}
              <Link
                as={ReactLink}
                to={withOrgPathFallback(routes.dashboard.billing)}
                colorScheme="red"
              >
                Billing
              </Link>
              .
            </Text>
            <Stack spacing={4}>
              <Button isLoading={isSubmitting} onClick={handleSubmit}>
                Create Instance
              </Button>
              <Button
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isSubmitting}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ExistingSubModal
