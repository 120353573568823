import { Stack, Text, Button } from '@chakra-ui/react'
import { Card } from '../../../../../shared/Cards'
import authPrompt from '../../../../../../helpers/auth/authPrompt'

export const DeleteCard = ({ isDeletable }) => (
  <Card display="flex" justifyContent="space-between">
    <Stack>
      <Text mb={2} size="xl">
        Delete account
      </Text>
      <Text mb={5} size="sm" color="gray.500">
        This will permanently delete all your resources (Instances, Volumes,
        Snapshots).
      </Text>
      <Text mb={5} size="sm" color="gray.500">
        Note: You cannot delete your account until you transfer the ownership of
        any Organizations you own.
      </Text>
    </Stack>
    <Button
      w={40}
      disabled={!isDeletable}
      alignSelf="start"
      colorScheme="red"
      onClick={() => {
        authPrompt('delete-account')
      }}
    >
      Delete account
    </Button>
  </Card>
)
