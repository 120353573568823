import { Skeleton } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { useParams } from 'react-router-dom'
import { calcMaxSize, hasAvaliableSize } from '../../../helpers/quota'
import { Error } from '../../shared/Error'
import { NumberField } from './index'
import { useAccountQuota, useOrgQuota } from '../hooks/quota'

export const SizeField = () => {
  const { orgId } = useParams()
  const { values } = useFormikContext()
  const { quota, isErrorQuota, isLoadingQuota } = orgId
    ? useOrgQuota({ orgId })
    : useAccountQuota()
  const maxSize = calcMaxSize({ quota, type: values.type })
  const availableSize = hasAvaliableSize({ size: values.size, maxSize })
    ? maxSize - values.size
    : 0

  return isLoadingQuota ? (
    <Skeleton h="70px" />
  ) : isErrorQuota ? (
    <Error />
  ) : (
    <NumberField
      name="size"
      label="Size"
      description={`Available Quota: ${availableSize} GiB`}
    />
  )
}
