import { Heading, Stack, Text, VStack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useSWR from 'swr'
import { fetchAccount } from '../../../../../../api/account'
import routes from '../../../../../../constants/routes'
import { withOrgPathFallback } from '../../../../../../router'
import { BillingDetailsForm } from '../../../../../shared/BillingDetailsForm'
import { PaymentHandler } from '../../../../../shared/payment-form/utils'
import { OrganizationIdStorage } from '../../../../../shared/storage'
import Layout from './Layout'

const Billing = () => {
  const history = useHistory()
  const id = OrganizationIdStorage.get()
  const { data: account } = useSWR('/account', () => fetchAccount())
  const initialValues = {
    email: account?.email || '',
    name: account?.name || ''
  }
  const onSuccess = () => {
    history.push(
      withOrgPathFallback(routes.dashboard.organizations.create.success)
    )
  }

  useEffect(() => {
    if (!id) {
      history.push(
        withOrgPathFallback(routes.dashboard.organizations.create.index)
      )
    }
  }, [id])

  return (
    <Layout>
      <PaymentHandler />
      <VStack spacing={4} w="100%">
        <Heading as="h1" size="3xl">
          Billing details
        </Heading>
        <Stack spacing={8}>
          <Text size="md" fontWeight="normal" color="gray.500">
            This information will be included in your invoices.
          </Text>
          <BillingDetailsForm
            orgId={id}
            initialValues={initialValues}
            onSuccess={onSuccess}
            hideSuccessToast
          />
        </Stack>
      </VStack>
    </Layout>
  )
}

export default Billing
