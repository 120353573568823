import {
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { Card } from '../../../../shared/Cards'
import { SkeletonTable } from '../../../../shared/Skeletons'
import { TableCell, TableError } from '../../../../shared/Table'
import {
  useAccountBillingHistory,
  useOrgBillingHistory
} from '../../../../shared/hooks'
import TableRow from './table-row'

const History = ({ hasPermission }) => {
  const { orgId } = useParams()
  const { invoices, isLoadingHistory, isErrorHistory } = orgId
    ? useOrgBillingHistory({ orgId })
    : useAccountBillingHistory()

  const isTableEmpty = invoices?.length === 0

  return (
    <Card as={Stack} spacing={8}>
      <Heading size="xl">Billing history</Heading>
      <Stack spacing={2}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th w="25%">Invoice Number</Th>
                <Th w="15%">Date</Th>
                <Th w="15%">Billed Amount</Th>
                <Th w="15%">Due Date</Th>
                <Th w="10%">Status</Th>
                <Th w="10%">Invoice</Th>
                {hasPermission && <Th w="10%" />}
              </Tr>
            </Thead>
            {isLoadingHistory && (
              <SkeletonTable
                rows={5}
                columns={hasPermission ? 7 : 6}
                hasAction={false}
              />
            )}
            {isErrorHistory ? (
              <TableError>Something went wrong loading the history.</TableError>
            ) : (
              <Tbody>
                {isTableEmpty ? (
                  <TableCell>
                    You currently have no invoices with a total higher than 0
                    USD.
                  </TableCell>
                ) : (
                  invoices?.map((invoice) => (
                    <TableRow
                      key={invoice.id}
                      invoice={invoice}
                      hasPermission={hasPermission}
                    />
                  ))
                )}
              </Tbody>
            )}
          </Table>
        </TableContainer>
      </Stack>
    </Card>
  )
}

export default History
