import { Info, Warning } from '../../../../shared/Icons'
import { ACTION_REQUIRED, INFO, WARNING } from './itemType'

const Icon = ({ type }) => {
  let Component = Info
  let color = 'gray.500'

  if (type === INFO) {
    Component = Info
    color = 'blue.500'
  } else if (type === ACTION_REQUIRED) {
    Component = Warning
    color = 'red.500'
  } else if (type === WARNING) {
    Component = Warning
    color = 'orange.500'
  }

  return <Component mt={1.5} boxSize={4} color={color} />
}

export default Icon
