import { useSelector } from 'react-redux'
import { externalLinks } from '../../../../helpers/links'
import { Alert } from '../../../shared/Alert'

const message =
  'Your organization is suspended. Please contact support to reinstate.'

const OrgSuspended = () => {
  const isSuspended = useSelector((state) => state.dashboard.isOrgSuspended)

  return isSuspended ? (
    <Alert message={message} link={externalLinks.newTicket} />
  ) : null
}

export default OrgSuspended
