import { Center, Stack } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { OrganizationNotFound } from '../../../../Errors/OrganizationNotFound'
import SupportError from '../../../../Errors/SupportError'
import {
  useIsOwner,
  useOrganizationWithId
} from '../../../../shared/hooks/organization'
import { ProfileCard, OwnershipCard, DeleteCard, LeaveCard } from './cards'
import Spinner from '../../../../shared/Spinner'

const Settings = () => {
  const { orgId } = useParams()
  const { organization, isError, isLoading } = useOrganizationWithId(orgId)
  const isOwner = organization && useIsOwner(organization)

  if (!organization) return <OrganizationNotFound />

  if (isError)
    return (
      <SupportError>
        Something went wrong loading the organization.
      </SupportError>
    )

  if (isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  return (
    <Stack spacing={8}>
      <ProfileCard isVisibleEdit={isOwner} />
      {isOwner ? (
        <>
          <OwnershipCard />
          <DeleteCard organizationId={organization.id} />
        </>
      ) : (
        <LeaveCard orgId={organization.id} />
      )}
    </Stack>
  )
}

export default Settings
