import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  Textarea
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import * as yup from 'yup'
import { AccountSecurity, OrgSecurity } from '../../../../../api/security'
import { toastMessages } from '../../../../../constants/toast-messages'
import Asterisk from '../../../../shared/Asterisk'
import { useToast } from '../../../../shared/Toast'

const validationSchema = yup.object().shape({
  publicKey: yup.string(),
  keyName: yup.string().required('Key name is required')
})

export const EditModal = ({ disclosure, sshKey }) => {
  const { isOpen, onClose } = disclosure
  const { orgId, projectId } = useParams()
  const toast = useToast()
  const { id: sshKeyId, value: publicKey, name: keyName } = sshKey

  const initialValues = {
    publicKey,
    keyName
  }

  const onSubmit = async (values, { setSubmitting }) => {
    const payload = {
      name: values.keyName
    }
    setSubmitting(true)

    try {
      if (orgId) {
        await OrgSecurity.updateSSHKey({
          orgId,
          projectId,
          sshKeyId,
          payload
        })
        await mutate((key) =>
          key.startsWith(`/projects/${projectId}/ssh-keys?`)
        )
      } else {
        await AccountSecurity.updateSSHKey({
          sshKeyId,
          payload
        })
        await mutate((key) => key.startsWith('/ssh-keys?'))
      }
      toast({
        status: 'success',
        message: toastMessages.saved
      })
      onClose()
    } catch {
      toast({
        status: 'error',
        message: toastMessages.notSaved
      })
    } finally {
      setSubmitting(false)
    }
  }

  const { values, handleChange, errors, touched, handleSubmit, isSubmitting } =
    useFormik({
      validationSchema,
      initialValues,
      onSubmit
    })

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap="8" as="form" onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <Heading size="xl" textAlign="center">
                Edit “{keyName}” SSH key
              </Heading>
              <Text
                size="md"
                fontWeight="normal"
                color="gray.500"
                textAlign="center"
              >
                Your public SSH key will be automatically added to all your
                profiles
              </Text>
            </Stack>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>
                  <Asterisk />
                  Public key
                </FormLabel>
                <Textarea
                  rows={4}
                  name="publicKey"
                  value={values.publicKey}
                  isDisabled
                />
              </FormControl>
              <FormControl isInvalid={errors.keyName && touched.keyName}>
                <FormLabel>
                  <Asterisk />
                  Key name
                </FormLabel>
                <Input
                  autoFocus
                  name="keyName"
                  placeholder="Enter your key name"
                  value={values.keyName}
                  onChange={handleChange}
                />
                <FormErrorMessage>{errors.keyName}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack spacing={4}>
              <Button type="submit" isLoading={isSubmitting}>
                Save
              </Button>
              <Button
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isSubmitting}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Stack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
