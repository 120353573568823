import { Heading, VStack } from '@chakra-ui/react'
import {
  UsageTypeProvider,
  UsageTypeRadioGroup
} from '../../../../../../shared/usageType'
import Layout from '../Layout'
import UsageTypeForm from './UsageTypeForm'

const Setup = () => (
  <Layout>
    <VStack spacing={4} w="100%">
      <Heading as="h1" size="3xl">
        Set up your Organization
      </Heading>
      <UsageTypeProvider>
        <UsageTypeRadioGroup />
        <UsageTypeForm />
      </UsageTypeProvider>
    </VStack>
  </Layout>
)

export default Setup
