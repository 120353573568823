import {
  ActiveBadge,
  CanceledBadge,
  DefaultBadge,
  EndedBadge,
  ErrorBadge,
  ExpiredBadge,
  PendingPaymentBadge,
  ScheduledBadge
} from '../components/shared/Badge'

export const statuses = {
  pendingPayment: 'pending_payment',
  canceled: 'canceled',
  expired: 'expired',
  scheduled: 'scheduled',
  active: 'active',
  ended: 'ended',
  error: 'error'
}

export function getBadge(status) {
  if (status === statuses.pendingPayment) {
    return <PendingPaymentBadge />
  } else if (status === statuses.canceled) {
    return <CanceledBadge />
  } else if (status === statuses.expired) {
    return <ExpiredBadge />
  } else if (status === statuses.scheduled) {
    return <ScheduledBadge />
  } else if (status === statuses.active) {
    return <ActiveBadge />
  } else if (status === statuses.ended) {
    return <EndedBadge colorScheme="gray.500" />
  } else if (status === statuses.error) {
    return <ErrorBadge />
  } else {
    return <DefaultBadge />
  }
}
