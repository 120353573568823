// @ts-check
import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  isAccountSuspended: false,
  isOrgSuspended: false
}

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setIsAccountSuspended: (state, action) => {
      state.isAccountSuspended = action.payload
    },
    setIsOrgSuspended: (state, action) => {
      state.isOrgSuspended = action.payload
    }
  }
})

export const { setIsAccountSuspended, setIsOrgSuspended } = slice.actions

export default slice.reducer
