import { Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { SkeletonTable } from '../../../shared/Skeletons'
import {
  INITIAL_PAGE,
  TableCell,
  TableError,
  TablePagination
} from '../../../shared/Table'
import { useOrgVolumes, useAccountVolumes } from '../../../shared/hooks'
import TableRow from './table-row'

const List = ({ hasPermission }) => {
  const { orgId, projectId } = useParams()
  const [page, setPage] = useState(INITIAL_PAGE)

  const { volumes, pagination, isLoadingVolumes, isErrorVolumes } =
    orgId && projectId
      ? useOrgVolumes({ orgId, projectId, page })
      : useAccountVolumes({ page })
  const isTableEmpty = volumes?.length === 0

  return (
    <>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th w="25%">Name</Th>
              <Th w="15%">Location</Th>
              <Th w="20%">Description</Th>
              <Th w="10%">Type</Th>
              <Th w="10%">Size</Th>
              <Th w="15%">Created</Th>
              <Th w="5%" />
            </Tr>
          </Thead>
          {isLoadingVolumes ? (
            <SkeletonTable rows={10} columns={7} />
          ) : isErrorVolumes ? (
            <TableError>Something went wrong loading the volumes.</TableError>
          ) : (
            <Tbody>
              {isTableEmpty ? (
                <TableCell>There are no Volumes yet.</TableCell>
              ) : (
                volumes?.map((volume) => (
                  <TableRow
                    key={volume.id}
                    row={volume}
                    hasPermission={hasPermission}
                  />
                ))
              )}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      {!isErrorVolumes && (
        <TablePagination
          page={page}
          setPage={setPage}
          totalCount={pagination?.totalCount}
        />
      )}
    </>
  )
}

export default List
