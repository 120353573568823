import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'

const ReservationSubModal = ({ disclosure }) => {
  const { isOpen, onClose } = disclosure
  const { submitForm, isSubmitting } = useFormikContext()

  const handleSubmit = () => {
    onClose()
    submitForm()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={8}>
            <Text size="sm" textAlign="center" color="red.500">
              All Instances that use reserved GPUS will be stopped at the end of
              the reservation period.
            </Text>
            <Text size="sm" textAlign="center" color="gray.500">
              Additional resources will be billed on demand.
            </Text>
            <Stack spacing={4}>
              <Button isLoading={isSubmitting} onClick={handleSubmit}>
                Create Instance
              </Button>
              <Button
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isSubmitting}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ReservationSubModal
