import { NavLink as ReactNavLink } from 'react-router-dom'
import ParamLink from '../../../shared/ParamLink'

const NavLink = ({ to, children }) => (
  <ParamLink
    as={ReactNavLink}
    to={to}
    colorScheme="gray"
    variant="menu"
    activeStyle={{
      color: 'black'
    }}
  >
    {children}
  </ParamLink>
)

export default NavLink
