import { INITIAL_PAGE, PER_PAGE } from '../components/shared/Table'
import { serialize } from '../helpers/serializers'
import { COMPUTE_ENDPOINT, apiRequest } from './index'

export class OrgSecurityGroups {
  static create = async ({ orgId, projectId, data }) => {
    const securityGroup = await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/security-groups?org=${orgId}`,
      {
        method: 'POST',
        body: JSON.stringify(data)
      },
      true
    )
    return serialize(securityGroup)
  }

  static fetchById = async ({ orgId, projectId, securityGroupId }) => {
    const securityGroup = await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/security-groups/${securityGroupId}?org=${orgId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(securityGroup)
  }

  static updateById = async ({ orgId, projectId, securityGroupId, data }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/security-groups/${securityGroupId}?org=${orgId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(data)
      },
      true
    )

  static deleteById = async ({ orgId, projectId, securityGroupId }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/security-groups/${securityGroupId}?org=${orgId}`,
      {
        method: 'DELETE'
      },
      true
    )

  static attachById = async ({
    orgId,
    projectId,
    securityGroupId,
    instanceId
  }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/security-groups/${securityGroupId}/control?org=${orgId}`,
      {
        method: 'POST',
        body: JSON.stringify({
          add: {
            instance_id: instanceId
          }
        })
      },
      true
    )

  static detachById = async ({
    orgId,
    projectId,
    securityGroupId,
    instanceId
  }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/security-groups/${securityGroupId}/control?org=${orgId}`,
      {
        method: 'POST',
        body: JSON.stringify({
          remove: {
            instance_id: instanceId
          }
        })
      },
      true
    )

  static fetchInstances = async ({
    orgId,
    projectId,
    securityGroupId,
    page,
    perPage
  }) => {
    const instances = await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/security-groups/${securityGroupId}/instances?page=${page}&perPage=${perPage}&org=${orgId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(instances)
  }

  static fetchAll = async ({
    orgId,
    projectId,
    page = INITIAL_PAGE,
    perPage = PER_PAGE,
    region
  }) => {
    const regionArg = region ? `&region=${region}` : ''
    const securityGroups = await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/security-groups?page=${page}&per_page=${perPage}&org=${orgId}${regionArg}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(securityGroups)
  }
}

export class AccountSecurityGroups {
  static create = async ({ data }) => {
    const securityGroup = await apiRequest(
      `${COMPUTE_ENDPOINT}/security-groups`,
      {
        method: 'POST',
        body: JSON.stringify(data)
      },
      true
    )
    return serialize(securityGroup)
  }

  static fetchById = async ({ securityGroupId }) => {
    const securityGroup = await apiRequest(
      `${COMPUTE_ENDPOINT}/security-groups/${securityGroupId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(securityGroup)
  }

  static updateById = async ({ securityGroupId, data }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/security-groups/${securityGroupId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(data)
      },
      true
    )

  static deleteById = async ({ securityGroupId }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/security-groups/${securityGroupId}`,
      {
        method: 'DELETE'
      },
      true
    )

  static attachById = async ({ securityGroupId, instanceId }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/security-groups/${securityGroupId}/control`,
      {
        method: 'POST',
        body: JSON.stringify({
          add: {
            instance_id: instanceId
          }
        })
      },
      true
    )

  static detachById = async ({ securityGroupId, instanceId }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/security-groups/${securityGroupId}/control`,
      {
        method: 'POST',
        body: JSON.stringify({
          remove: {
            instance_id: instanceId
          }
        })
      },
      true
    )

  static fetchInstances = async ({ securityGroupId, page, perPage }) => {
    const instances = await apiRequest(
      `${COMPUTE_ENDPOINT}/security-groups/${securityGroupId}/instances?page=${page}&perPage=${perPage}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(instances)
  }

  static fetchAll = async ({
    page = INITIAL_PAGE,
    perPage = PER_PAGE,
    region
  }) => {
    const regionArg = region ? `&region=${region}` : ''
    const securityGroups = await apiRequest(
      `${COMPUTE_ENDPOINT}/security-groups?page=${page}&per_page=${perPage}${regionArg}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(securityGroups)
  }
}
