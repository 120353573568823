import { HStack } from '@chakra-ui/react'
import { WarningError } from '../../../Errors'
import CreateButton from './CreateButton'

export const ErrorLayout = () => (
  <HStack spacing={6}>
    <CreateButton />
    <WarningError>Unable to load the data</WarningError>
  </HStack>
)
