import { useParams } from 'react-router-dom'
import Account from './account'
import Organization from './organization'

const Quota = () => {
  const { orgId } = useParams()

  return orgId ? <Organization /> : <Account />
}

export default Quota
