import { Box, Center, Flex, Link, Stack } from '@chakra-ui/react'
import routes from '../../../../constants/routes'
import { sidebarWidth } from '../../../../constants/styles'
import {
  Account,
  ExternalLink,
  Filesystem,
  Home,
  Instances,
  Keys,
  LogoText,
  Network,
  ObjectStorage,
  Organization,
  Snapshot,
  Volume
} from '../../../shared/Icons'
import { useAccountFF, useOrgFF } from '../../../shared/hooks'
import { useIsRole, useOrganization } from '../../../shared/hooks/organization'
import { useOrgParam } from '../../../shared/hooks/useOrgParam'
import NavLink from './NavLink'
import NavMenu from './NavMenu'
const home = [routes.dashboard.index]

const networkRoutes = [
  routes.dashboard.securityGroups.index,
  routes.dashboard.securityGroups.create,
  routes.dashboard.securityGroups.view,
  routes.dashboard.floatingIPs.index
]

const objectStorageRoutes = [
  routes.dashboard.buckets.index,
  routes.dashboard.buckets.create,
  routes.dashboard.buckets.view
]

const accountRoutes = [
  routes.dashboard.quota,
  routes.dashboard.reservations.index,
  routes.dashboard.billing
]

const organizationRoutes = [
  routes.dashboard.settings,
  routes.dashboard.quota,
  routes.dashboard.reservations.index,
  routes.dashboard.members,
  routes.dashboard.billing,
  routes.dashboard.projects
]

const filesystemRoutes = [
  routes.dashboard.filesystems.index,
  routes.dashboard.filesystems.create,
  routes.dashboard.filesystems.view
]

const Sidebar = () => {
  const orgId = useOrgParam()
  const { organization } = useOrganization()
  const canAccessBilling = useIsRole(organization, 'ADMIN', 'BILLING_MANAGER')
  const { featureFlag } = orgId ? useOrgFF({ orgId }) : useAccountFF({})
  const { enableQuotaReservation, enableCommunityInstances } = featureFlag || {}

  return (
    <Box
      zIndex="20"
      position="fixed"
      h="100%"
      w={sidebarWidth}
      bgColor="gray.100"
    >
      <Center h="100px">
        <LogoText />
      </Center>
      <Flex
        direction="column"
        justify="space-between"
        h="calc(100% - 100px)"
        py={14}
        overflowY="auto"
      >
        <Stack spacing={7}>
          <NavMenu label="Home" path={home} icon={<Home />} isExact={true} />
          <Stack spacing={3}>
            <NavMenu
              label="Instances"
              path={[routes.dashboard.instances.index]}
              icon={<Instances />}
            />
            {enableCommunityInstances && (
              <NavMenu
                label="Community Instances"
                path={[routes.dashboard.communityInstances.index]}
                icon={<Instances />}
              />
            )}
            <NavMenu
              label="Snapshots"
              path={[routes.dashboard.snapshots.index]}
              icon={<Snapshot width="20px" height="20px" />}
            />
            <NavMenu label="Network" path={networkRoutes} icon={<Network />} />
            <NavMenu
              label="Volumes"
              path={[routes.dashboard.volumes.index]}
              icon={<Volume />}
            />
          </Stack>
          <Stack spacing={3}>
            <NavMenu
              label="Object Storage"
              path={objectStorageRoutes}
              icon={<ObjectStorage />}
            />
            <NavMenu
              label="Filesystems"
              path={filesystemRoutes}
              icon={<Filesystem />}
            />
          </Stack>
          <NavMenu
            label="Keys & Tokens"
            path={[routes.dashboard.security]}
            icon={<Keys />}
          />
          {orgId && (
            <NavMenu
              label="Organization"
              path={organizationRoutes}
              icon={<Organization />}
            >
              <NavLink to={routes.dashboard.settings}>Settings</NavLink>
              <NavLink to={routes.dashboard.members}>Members</NavLink>
              <NavLink to={routes.dashboard.projects}>Projects</NavLink>
              <NavLink to={routes.dashboard.quota}>Quota</NavLink>
              {enableQuotaReservation && (
                <NavLink to={routes.dashboard.reservations.index}>
                  Reserved GPUs
                </NavLink>
              )}
              {canAccessBilling && (
                <NavLink to={routes.dashboard.billing}>Billing</NavLink>
              )}
            </NavMenu>
          )}
          {!orgId && (
            <NavMenu label="Account" path={accountRoutes} icon={<Account />}>
              <NavLink to={routes.dashboard.quota}>Quota</NavLink>
              {enableQuotaReservation && (
                <NavLink to={routes.dashboard.reservations.index}>
                  Reserved GPUs
                </NavLink>
              )}
              <NavLink to={routes.dashboard.billing}>Billing</NavLink>
            </NavMenu>
          )}
        </Stack>
        <Stack spacing={2.5} px={5} pt={7}>
          <Link
            href={process.env.REACT_APP_ZENDESK_URL}
            isExternal
            colorScheme="gray"
            variant="menu"
            justifyContent="space-between"
          >
            Support <ExternalLink />
          </Link>
          <Link
            href={process.env.REACT_APP_STATUS_PAGE_WEBSITE}
            isExternal
            colorScheme="gray"
            variant="menu"
            justifyContent="space-between"
          >
            Service Status <ExternalLink />
          </Link>
          <Link
            href={process.env.REACT_APP_DEVELOPER_API_URL}
            isExternal
            colorScheme="gray"
            variant="menu"
            justifyContent="space-between"
          >
            Developers API <ExternalLink />
          </Link>
        </Stack>
      </Flex>
    </Box>
  )
}

export default Sidebar
