import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react'
import { useField } from 'formik'
import Asterisk from '../Asterisk'
import DatePicker from '../DatePicker'

export const DatePickerField = ({ isRequired = false, label, ...props }) => {
  const [, meta, helpers] = useField(props)

  return (
    <FormControl
      isInvalid={meta.error && meta.touched}
      isDisabled={props.disabled}
    >
      <FormLabel>
        {isRequired && <Asterisk />}
        {label}
      </FormLabel>
      <DatePicker onBlur={() => helpers.setTouched(true)} {...props} />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}
