/**
 * Extracts the country code from the brower language
 * Example: for 'fr-FR' we get 'fr'
 */
export function getCountryIdFromLanguage() {
  if (
    window.navigator.language &&
    window.navigator.language.split('-').length > 1
  ) {
    return window.navigator.language.split('-')[1]
  }
  return null
}
