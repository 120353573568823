import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { mutate } from 'swr'
import { AccountFilesystems, OrgFilesystems } from '../../../../api/filesystems'
import routes from '../../../../constants/routes'
import { toastMessages } from '../../../../constants/toast-messages'
import { withOrgPathFallback } from '../../../../router'
import { useToast } from '../../../shared/Toast'
import { useIsMounted } from '../../../shared/hooks/useIsMounted'

export const DeleteModal = ({ filesystem, disclosure }) => {
  const { orgId, projectId } = useParams()
  const { isOpen, onClose } = disclosure
  const [isSubmitting, setIsSubmitting] = useState()
  const isMounted = useIsMounted()
  const toast = useToast()
  const history = useHistory()

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true)
    try {
      const filesystemId = filesystem.id
      const response = orgId
        ? await OrgFilesystems.deleteById({
            orgId,
            projectId,
            filesystemId
          })
        : await AccountFilesystems.deleteById({ filesystemId })
      if (response.ok) {
        orgId
          ? await mutate((key) =>
              key.startsWith(`/projects/${projectId}/filesystems?`)
            )
          : await mutate((key) => key.startsWith(`/filesystems?`))
        toast({
          status: 'success',
          message: toastMessages.filesystemDeleteSuccess
        })
        onClose()
        history.push(withOrgPathFallback(routes.dashboard.filesystems.index))
      }
    } catch {
      toast({
        status: 'error',
        message: toastMessages.filesystemDeleteError
      })
    } finally {
      if (isMounted()) {
        setIsSubmitting(false)
      }
    }
  }, [filesystem])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={8}>
            <Heading size="xl" textAlign="center">
              Are you sure you want to Filesystem “{filesystem.name}”?
            </Heading>
            <Text
              size="md"
              textAlign="center"
              color="red.500"
              fontWeight="normal"
            >
              This action will permanently delete the Filesystem and it cannot
              be recovered.
            </Text>
            <Stack spacing={4}>
              <Button
                type="submit"
                isLoading={isSubmitting}
                colorScheme="red"
                onClick={handleSubmit}
              >
                Delete
              </Button>
              <Button
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isSubmitting}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default DeleteModal
