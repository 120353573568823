import {
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Spacer,
  Stack,
  Skeleton,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import routes from '../../../../../constants/routes'
import { formatDateTime } from '../../../../../helpers/date'
import { withOrgPathFallback } from '../../../../../router'
import SupportError from '../../../../Errors/SupportError'
import { Card } from '../../../../shared/Cards'
import { ReturnLink } from '../../../../shared/Links'
import Region from '../../../../shared/Region'
import { useQuery } from '../../../../shared/hooks/useQuery'
import {
  useOrgBucket,
  useAccountBucket,
  useHasWriteProjectPermissions
} from '../../../../shared/hooks'
import { DeleteModal } from '../modals'
import CopyButton from '../../../../shared/CopyButton'

const Loading = () => (
  <Stack spacing={8}>
    <Skeleton h="45px" />
    <Skeleton h="340px" />
    <Skeleton h="120px" />
  </Stack>
)

const Error = () => (
  <SupportError pt={6}>
    {`Something went wrong loading the bucket's settings.`}
  </SupportError>
)

const BucketsView = () => {
  const deleteDisclosure = useDisclosure()
  const { orgId, projectId, bucketName } = useParams()
  const { region } = useQuery()

  const hasWriteProjectPermissions = orgId
    ? useHasWriteProjectPermissions({
        projectId,
        orgId
      })
    : true

  const { bucket, isLoadingBucket, isErrorBucket } = orgId
    ? useOrgBucket({ orgId, projectId, bucketName, region })
    : useAccountBucket({ bucketName, region })

  return (
    <Stack spacing={2}>
      <ReturnLink to={withOrgPathFallback(routes.dashboard.buckets.index)}>
        Object Storage
      </ReturnLink>
      {isLoadingBucket ? (
        <Loading />
      ) : isErrorBucket ? (
        <Error />
      ) : (
        <Stack spacing={8}>
          <Heading as="h1" size="4xl" noOfLines={1} maxWidth="600px">
            {bucket.name}
          </Heading>
          <Card as={Stack} spacing={8}>
            <Heading size="xl">Bucket details</Heading>
            <Grid templateColumns="1fr 1fr" columnGap={16} rowGap={5}>
              <Stack spacing={0}>
                <Text>Location</Text>
                <Region slug={bucket.region} />
              </Stack>
              <Stack spacing={0}>
                <Text>Created</Text>
                <Text color="gray.500">{formatDateTime(bucket.createdAt)}</Text>
              </Stack>
              <Stack spacing={0}>
                <Text>Storage size</Text>
                <Text color="gray.500" noOfLines={2}>
                  {Math.ceil(bucket.size * 100) / 100} GiB
                </Text>
              </Stack>
              <Stack spacing={0}>
                <Text>Object count</Text>
                <Text color="gray.500" noOfLines={2}>
                  {bucket.objectCount || 0}
                </Text>
              </Stack>
              <GridItem rowSpan={1} colSpan={2}>
                <Divider />
              </GridItem>
              <Stack spacing={0}>
                <Text>Bucket ID</Text>
                <CopyButton color="gray.500" value={bucket.name} />
              </Stack>
              <Stack spacing={0}>
                <Text>Bucket URL</Text>
                {bucket.bucketUrl ? (
                  <CopyButton color="gray.500" value={bucket.bucketUrl} />
                ) : (
                  <Text color="gray.500" noOfLines={2}>
                    -
                  </Text>
                )}
              </Stack>
            </Grid>
          </Card>
          {hasWriteProjectPermissions && (
            <Card as={Flex}>
              <Stack>
                <Heading size="xl">Delete Bucket</Heading>
                <Text color="gray.500" size="sm">
                  This action will permanently delete every object inside this
                  bucket and cannot be recovered.
                </Text>
              </Stack>
              <Spacer />
              <Button colorScheme="red" onClick={deleteDisclosure.onOpen}>
                Delete Bucket
              </Button>
              <DeleteModal disclosure={deleteDisclosure} bucket={bucket} />
            </Card>
          )}
        </Stack>
      )}
    </Stack>
  )
}

export default BucketsView
