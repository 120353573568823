import { Flex, Spacer } from '@chakra-ui/react'
import { useOrgParam } from '../../../shared/hooks/useOrgParam'
import RightSide from './RightSide'
import SwitchProject from './SwitchProject'

const Topbar = () => {
  const orgId = useOrgParam()

  return (
    <Flex p={7} align="center">
      {orgId && <SwitchProject />}
      <Spacer />
      <RightSide />
    </Flex>
  )
}

export default Topbar
