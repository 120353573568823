import { INITIAL_PAGE, PER_PAGE } from '../components/shared/Table'
import { serialize } from '../helpers/serializers'
import { apiRequest, COMPUTE_ENDPOINT } from './index'

export class OrgVolumes {
  static create = async ({ orgId, projectId, data }) => {
    const volume = await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/volumes?org=${orgId}`,
      {
        method: 'POST',
        body: JSON.stringify(data)
      },
      true
    )
    return serialize(volume)
  }

  static fetchById = async ({ orgId, projectId, volumeId }) => {
    const volume = await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/volumes/${volumeId}?org=${orgId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(volume)
  }

  static updateById = async ({ orgId, projectId, volumeId, data }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/volumes/${volumeId}?org=${orgId}`,
      {
        method: 'PUT',
        body: JSON.stringify(data)
      },
      true
    )

  static deleteById = async ({ orgId, projectId, volumeId }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/volumes/${volumeId}?org=${orgId}`,
      {
        method: 'DELETE'
      },
      true
    )

  static attachById = async ({ orgId, projectId, volumeId, instanceId }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/volumes/${volumeId}/control?org=${orgId}`,
      {
        method: 'POST',
        body: JSON.stringify({
          volume_attach: { instance_id: instanceId }
        })
      },
      true
    )

  static detachById = async ({ orgId, projectId, volumeId, instanceId }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/volumes/${volumeId}/control?org=${orgId}`,
      {
        method: 'POST',
        body: JSON.stringify({
          volume_detach: { instance_id: instanceId }
        })
      },
      true
    )

  static fetchAll = async ({
    orgId,
    projectId,
    page = INITIAL_PAGE,
    perPage = PER_PAGE,
    region
  }) => {
    const regionArg = region ? `&region=${region}` : ''
    const volumes = await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/volumes?page=${page}&per_page=${perPage}&org=${orgId}${regionArg}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(volumes)
  }
}

export class AccountVolumes {
  static create = async ({ data }) => {
    const volume = await apiRequest(
      `${COMPUTE_ENDPOINT}/volumes`,
      {
        method: 'POST',
        body: JSON.stringify(data)
      },
      true
    )
    return serialize(volume)
  }

  static fetchById = async ({ volumeId }) => {
    const volume = await apiRequest(
      `${COMPUTE_ENDPOINT}/volumes/${volumeId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(volume)
  }

  static updateById = async ({ volumeId, data }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/volumes/${volumeId}`,
      {
        method: 'PUT',
        body: JSON.stringify(data)
      },
      true
    )

  static deleteById = async ({ volumeId }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/volumes/${volumeId}`,
      {
        method: 'DELETE'
      },
      true
    )

  static attachById = async ({ volumeId, instanceId }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/volumes/${volumeId}/control`,
      {
        method: 'POST',
        body: JSON.stringify({
          volume_attach: { instance_id: instanceId }
        })
      },
      true
    )

  static detachById = async ({ volumeId, instanceId }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/volumes/${volumeId}/control`,
      {
        method: 'POST',
        body: JSON.stringify({
          volume_detach: { instance_id: instanceId }
        })
      },
      true
    )

  static fetchAll = async ({
    page = INITIAL_PAGE,
    perPage = PER_PAGE,
    region
  }) => {
    const regionArg = region ? `&region=${region}` : ''
    const volumes = await apiRequest(
      `${COMPUTE_ENDPOINT}/volumes?page=${page}&per_page=${perPage}${regionArg}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(volumes)
  }
}
