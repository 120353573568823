import { HStack, Text } from '@chakra-ui/react'
import { WarningRoundedStroked } from './Icons'
import Spinner from './Spinner'
import { useConfigs } from './hooks/configs'

export const Accelerator = ({ gpuCount, flavorName }) => {
  const { configs, isLoadingConfigs, isErrorConfigs } = useConfigs({})

  const description = configs?.instanceTypes.find(
    (type) => type.flavorName === flavorName
  )?.details?.gpu?.description

  if (isLoadingConfigs) {
    return <Spinner color="gray.500" />
  }

  if (isErrorConfigs) {
    return (
      <HStack spacing={2}>
        <WarningRoundedStroked opacity="0.4" />
        <Text color="gray.500">Unable to load</Text>
      </HStack>
    )
  }

  return `${gpuCount} ${description}`
}

export default Accelerator
