import { ACCOUNT_ENDPOINT, apiRequest } from '.'
import { INITIAL_PAGE, PER_PAGE } from '../components/shared/Table'
import { serialize } from '../helpers/serializers'

export class Project {
  static create = async ({ orgId, name, description, isDefault, legacy }) => {
    const project = await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}/projects`,
      {
        method: 'POST',
        body: JSON.stringify({
          name,
          description,
          isDefault,
          members: [],
          legacy
        })
      },
      true
    )
    return serialize(project)
  }

  static fetchById = async ({ orgId, projectId }) => {
    const project = await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}/projects/${projectId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(project)
  }

  static updateById = async ({ orgId, projectId, data }) => {
    const project = await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}/projects/${projectId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(data)
      },
      true
    )
    return serialize(project)
  }

  static deleteById = async ({ orgId, projectId }) =>
    await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}/projects/${projectId}`,
      {
        method: 'DELETE'
      },
      true
    )

  static fetchAll = async ({
    orgId,
    page = INITIAL_PAGE,
    perPage = PER_PAGE
  }) => {
    const projects = await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}/projects?page=${page}&per_page=${perPage}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(projects)
  }

  static addMember = async ({ orgId, projectId, accountId }) => {
    const member = await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}/projects/${projectId}/members`,
      {
        method: 'POST',
        body: JSON.stringify({
          account_id: accountId,
          role: 'VIEWER'
        })
      },
      true
    )
    return serialize(member)
  }

  static deleteMember = async ({ orgId, projectId, memberId }) =>
    await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}/projects/${projectId}/members/${memberId}`,
      {
        method: 'DELETE'
      },
      true
    )

  static updateMember = async ({ orgId, projectId, data, memberId }) =>
    await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}/projects/${projectId}/members/${memberId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(data)
      },
      true
    )

  static fetchAllMembers = async ({
    orgId,
    projectId,
    page = INITIAL_PAGE,
    perPage = PER_PAGE
  }) => {
    const members = await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}/projects/${projectId}/members?page=${page}&per_page=${perPage}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(members)
  }
}
