import { useParams } from 'react-router-dom'
import { Box, Heading, Stack, Skeleton, Grid, HStack } from '@chakra-ui/react'
import { AlertWarning } from '../../../shared/Alert'
import { ConnectToYourInstance } from './Cards'
import Termination from './Termination'
import Manage from './Manage'
import Destroy from './Destroy'
import Authentication from './Authentication'
import SecurityGroups from './SecurityGroups'
import ConsoleAccess from './ConsoleAccess'
import routes from '../../../../constants/routes'
import { ReturnLink } from '../../../shared/Links'
import { withOrgPathFallback } from '../../../../router'
import ResourceStatus from '../../../shared/ResourceStatus'
import { useHasWriteProjectPermissions } from '../../../shared/hooks/project'
import {
  useOrgInstance,
  useAccountInstance
} from '../../../shared/hooks/useInstance'
import Details from './Details'
import Volumes from './Volumes'

const Loading = () => (
  <Stack spacing={8}>
    <Skeleton h="45px" />
    <Skeleton h="150px" />
    <HStack spacing={8}>
      <Skeleton width="100%" h="150px" />
      <Skeleton width="100%" h="150px" />
    </HStack>
    <Skeleton h="340px" />
    <Skeleton h="200px" />
  </Stack>
)

const Error = () => (
  <Box pt={6}>
    <AlertWarning>
      The instance has not been found or has been deleted.
    </AlertWarning>
  </Box>
)

const InstanceDetail = () => {
  const { orgId, projectId, instanceId } = useParams()
  const hasWriteProjectPermissions = orgId
    ? useHasWriteProjectPermissions({
        projectId,
        orgId
      })
    : true

  const { instance, isError, isLoading } = orgId
    ? useOrgInstance({ orgId, projectId, instanceId })
    : useAccountInstance({ instanceId })

  return (
    <Stack spacing={2}>
      <ReturnLink to={withOrgPathFallback(routes.dashboard.instances.index)}>
        Instances
      </ReturnLink>
      {isLoading ? (
        <Loading />
      ) : isError ? (
        <Error />
      ) : (
        <Stack spacing={8}>
          <HStack spacing={3}>
            <Heading as="h1" size="4xl" noOfLines={1}>
              {instance.name}
            </Heading>
            <ResourceStatus status={instance.status} />
          </HStack>
          {hasWriteProjectPermissions && (
            <Grid templateColumns="1fr 1fr" columnGap={8}>
              <ConnectToYourInstance publicIp={instance.publicIp} />
              <Termination instance={instance} />
            </Grid>
          )}
          <Details
            instance={instance}
            hasPermission={hasWriteProjectPermissions}
          />
          {hasWriteProjectPermissions && (
            <Grid templateColumns="3fr 2fr" columnGap={8}>
              <Manage instance={instance} />
              <Destroy instance={instance} />
            </Grid>
          )}
          <ConsoleAccess />
          <Authentication sshKeys={instance.sshKeys} />
          <SecurityGroups
            instance={instance}
            hasPermission={hasWriteProjectPermissions}
          />
          <Volumes
            instance={instance}
            hasPermission={hasWriteProjectPermissions}
          />
        </Stack>
      )}
    </Stack>
  )
}

export default InstanceDetail
