import { Button } from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'
import routes from '../../constants/routes'
import Error from './ErrorTemplate'

export const OrganizationNotFound = () => (
  <Error
    statusCode="404"
    title="Organization not found"
    message="Seems like this organization does not exist or you are not allowed to access this organization."
  >
    <Button
      as={ReactLink}
      to={routes.dashboard.organizations.index}
      isFullWidth
      colorScheme="offBlack"
      variant="outline"
    >
      Back to Organizations
    </Button>
  </Error>
)
