const Badge = {
  baseStyle: {
    px: '4px',
    borderRadius: '4px',
    fontWeight: 'normal'
  },
  variants: {
    solid: ({ colorScheme }) => ({
      bg: `${colorScheme}`,
      color: 'white'
    }),
    outline: ({ colorScheme }) => ({
      px: '8px',
      bg: `white`,
      color: `${colorScheme}`,
      borderColor: `${colorScheme}`,
      border: '1px solid'
    })
  },
  defaultProps: {
    variant: 'solid'
  }
}

export default Badge
