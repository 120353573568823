import { useHistory } from 'react-router-dom'
import { Box, Button, Heading, Stack, Tooltip } from '@chakra-ui/react'
import { useIsSuspended } from '../../../../shared/hooks/account'
import routes from '../../../../../constants/routes'
import { withOrgPathFallback } from '../../../../../router'
import { Plus } from '../../../../shared/Icons'
import { DeleteModal } from './modals'
import List from './list'

const Organizations = () => {
  const isSuspended = useIsSuspended()
  const history = useHistory()

  return (
    <Stack spacing={8}>
      <Heading as="h1" size="4xl">
        My Organizations
      </Heading>
      <Box alignSelf="flex-end">
        <Tooltip
          isDisabled={!isSuspended}
          closeOnClick={false}
          label="Your account is suspended"
          shouldWrapChildren
        >
          <Box>
            <Button
              onClick={() =>
                history.push(
                  withOrgPathFallback(
                    routes.dashboard.organizations.create.index
                  )
                )
              }
              variant="outline"
              colorScheme="offBlack"
              leftIcon={<Plus color="black" />}
              width="fit-content"
              isDisabled={isSuspended}
            >
              Create Organization
            </Button>
          </Box>
        </Tooltip>
      </Box>
      <List />
      <DeleteModal />
    </Stack>
  )
}

export default Organizations
