import { useState } from 'react'
import { Stack, HStack, Text, Button } from '@chakra-ui/react'
import { mutate } from 'swr'
import { useToast } from './Toast'
import { toastMessages } from '../../constants/toast-messages'
import { Account } from '../../api/account'
import { LegacyBadge } from './Badge'
import TransitionBadge from './TransitionBadge'

const statusMap = {
  'not-migrated': 'Upgrade now!',
  migrating: 'Upgrading...',
  migrated: 'Upgraded!'
}

const defaultMessage = 'Something went wrong'

const migrationErrorHandler = async (error) => {
  let message = ''

  try {
    const response = await error.response.json()
    message = response?.message ?? defaultMessage
  } catch {
    message = defaultMessage
  }

  return message
}

const ProjectVersion = ({ status }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const toast = useToast()

  const notMigrated = status === 'not-migrated'
  const isMigrating = status === 'migrating'
  const show = notMigrated || isMigrating

  const handleMigrate = async () => {
    setIsSubmitting(true)

    try {
      await Account.migrate()
      await mutate('/migration')

      toast({
        status: 'success',
        message: toastMessages.projectMigrateSuccess
      })
    } catch (error) {
      const message = await migrationErrorHandler(error)
      toast({
        status: 'error',
        message
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  return show ? (
    <Stack spacing={0}>
      <Text>Project version</Text>
      <HStack>
        <LegacyBadge />
        {notMigrated && (
          <Button size="xs" isLoading={isSubmitting} onClick={handleMigrate}>
            {statusMap[status]}
          </Button>
        )}
        {isMigrating && <TransitionBadge>{statusMap[status]}</TransitionBadge>}
      </HStack>
    </Stack>
  ) : null
}
export default ProjectVersion
