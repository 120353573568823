import {
  Button,
  Flex,
  Spacer,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { Card } from '../../../../../shared/Cards'
import { LeaveModal } from '../../manage/modals'

export const LeaveCard = ({ orgId }) => {
  const leaveDisclosure = useDisclosure()

  return (
    <Card as={Flex}>
      <Stack flex={7}>
        <Text mb={2} size="xl">
          Leave Organization
        </Text>
        <Text mb={5} size="sm" color="gray.500">
          Once you have left the organization, you will not be able to see and
          manage its resources.
        </Text>
      </Stack>
      <Spacer />
      <Button w={48} colorScheme="red" onClick={leaveDisclosure.onOpen}>
        Leave Organization
      </Button>
      <LeaveModal disclosure={leaveDisclosure} orgId={orgId} />
    </Card>
  )
}
