import {
  Button,
  Heading,
  Skeleton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { methodsName } from '../../../../constants/create-instance'
import {
  getTypeBySlug,
  isUsedSubscription,
  slugs
} from '../../../../helpers/billing'
import { useIsVip } from '../../../../helpers/vip'
import {
  ValidationErrors,
  isUserValidToCreateResources
} from '../../../Errors/ValidationErrors'
import { Card } from '../../../shared/Cards'
import Region from '../../../shared/Region'
import { TdTruncated } from '../../../shared/Table'
import {
  useAccountBilling,
  useAccountInstanceInfo,
  useAccountUnusedSubscriptions,
  useOrgBilling,
  useOrgInstanceInfo,
  useOrgUnusedSubscriptions
} from '../../../shared/hooks'
import { useAccount, useAccountSettings } from '../../../shared/hooks/account'
import { useConfigs } from '../../../shared/hooks/configs'
import { useOrgSettings } from '../../../shared/hooks/organization'
import useSuspended from '../../../shared/hooks/useSuspended'
import ExistingSubModal from './Modals/ExistingSubModal'
import NewSubModal from './Modals/NewSubModal'
import OnDemandSubModal from './Modals/OnDemanSubModal'
import ReservationSubModal from './Modals/ReservationSubModal'

const Summary = () => {
  const { values, isSubmitting, isValid: isFormValid } = useFormikContext()
  const { region, type, volumes, authentication, image, catalog } = values
  const { orgId, projectId } = useParams()
  const { isValidatingConfigs, isErrorConfigs } = useConfigs({})
  const { account, isErrorAccount } = useAccount()
  const { billing, isValidatingBilling, isErrorBilling } = orgId
    ? useOrgBilling({ orgId })
    : useAccountBilling()
  const { settings, isErrorSettings, isLoadingSettings } = orgId
    ? useOrgSettings({ orgId })
    : useAccountSettings()
  const { isValidatingInfo, isErrorInfo } = orgId
    ? useOrgInstanceInfo({ orgId, projectId })
    : useAccountInstanceInfo({})
  const {
    isValidating: isValidatingSubscriptions,
    isError: isErrorSubscriptions
  } = orgId
    ? useOrgUnusedSubscriptions({ orgId })
    : useAccountUnusedSubscriptions()

  const isVip = useIsVip(settings)
  const [isUserValid, setIsUserValid] = useState(false)
  const selected = type?.configurations?.selected
  const billingType = values?.billing.type
  const billingTypeValue = getTypeBySlug(billingType)

  const existingSubDisclosure = useDisclosure()
  const newSubDisclosure = useDisclosure()
  const onDemandSubDisclosure = useDisclosure()
  const reservationDisclosure = useDisclosure()

  const isUsed = isUsedSubscription(values?.billing.id)
  const isExisting = values?.billing?.slug?.startsWith('prepaid') && isUsed
  const isNew = billingType?.startsWith('prepaid') && !isUsed
  const isOnDemand = billingType === slugs.onDemand
  const isReservation = billingType === slugs.reserved
  const isReservationActive = Boolean(values?.billing?.reservationId)

  const { isSuspended } = useSuspended()

  const isDisabled =
    isErrorConfigs ||
    isErrorAccount ||
    isErrorBilling ||
    isErrorSettings ||
    isErrorSubscriptions ||
    isUserValid ||
    isErrorInfo ||
    !isFormValid ||
    isSuspended ||
    (isReservation && !isReservationActive)

  const isLoading =
    isValidatingConfigs ||
    isValidatingBilling ||
    isLoadingSettings ||
    isValidatingInfo ||
    isValidatingSubscriptions

  useEffect(() => {
    setIsUserValid(
      !isUserValidToCreateResources({
        account,
        billing,
        isVip
      })
    )
  }, [account, billing, isVip])

  const handleSubmit = useCallback(() => {
    if (isExisting) {
      existingSubDisclosure.onOpen()
    } else if (isNew) {
      newSubDisclosure.onOpen()
    } else if (isOnDemand) {
      onDemandSubDisclosure.onOpen()
    } else if (isReservation) {
      reservationDisclosure.onOpen()
    }
  }, [orgId, projectId, isExisting, isNew, isOnDemand, isReservation])

  const imageName = catalog.name
    ? `${catalog.name} (${image.name}) `
    : image.name

  return isLoading ? (
    <Skeleton h="520px" />
  ) : (
    <>
      <Card as={Stack} spacing={5} p={5}>
        <Heading size="md">Instance</Heading>
        <TableContainer mb={8}>
          <Table variant="card">
            <Tbody>
              <Tr>
                <Td>Location</Td>
                <Td display="flex" justifyContent="right">
                  <Region slug={region} color="black" />
                </Td>
              </Tr>
              <Tr>
                <Td>Instance type</Td>
                <Td>{type?.description?.name}</Td>
              </Tr>
              {selected?.gpu && (
                <>
                  <Tr>
                    <Td>GPUs</Td>
                    <Td>{selected?.gpu.value}x</Td>
                  </Tr>
                  <Tr>
                    <Td>Video memory</Td>
                    <Td>
                      {selected?.gpu.value}x{selected?.videoMemory.value} GiB
                    </Td>
                  </Tr>
                </>
              )}
              <Tr>
                <Td>vCPUs</Td>
                <Td>{selected?.vcpu.value}x</Td>
              </Tr>
              <Tr>
                <Td>Memory</Td>
                <Td>{selected?.memory.value} GiB</Td>
              </Tr>
              <Tr>
                <Td>Disk</Td>
                <Td>{selected?.storage.value} GiB</Td>
              </Tr>
              <Tr>
                <Td>Volume</Td>
                <Td>
                  {volumes?.reduce((total, volume) => total + volume?.size, 0)}{' '}
                  GiB
                </Td>
              </Tr>
              <Tr>
                <Td>Image</Td>
                <TdTruncated>
                  {imageName.length > 30 ? (
                    <Tooltip hasArrow placement="top" label={imageName}>
                      <Text
                        overflow="hidden"
                        textOverflow="ellipsis"
                        lineHeight="normal"
                      >
                        {imageName}
                      </Text>
                    </Tooltip>
                  ) : (
                    imageName
                  )}
                </TdTruncated>
              </Tr>
              <Tr>
                <Td>Authentication</Td>
                <Td>{methodsName[authentication.method]}</Td>
              </Tr>
              <Tr>
                <Td>Billing type</Td>
                <Td>{billingTypeValue}</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
        <Button
          isFullWidth
          onClick={handleSubmit}
          isLoading={isSubmitting}
          isDisabled={isDisabled}
        >
          Create Instance
        </Button>
        <ValidationErrors account={account} billing={billing} isVip={isVip} />
      </Card>
      {isOnDemand && <OnDemandSubModal disclosure={onDemandSubDisclosure} />}
      {isExisting && <ExistingSubModal disclosure={existingSubDisclosure} />}
      {isNew && <NewSubModal disclosure={newSubDisclosure} />}
      {isReservation && (
        <ReservationSubModal disclosure={reservationDisclosure} />
      )}
    </>
  )
}

export default Summary
