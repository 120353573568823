import { useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { useToast } from '../../../../shared/Toast'
import { toastMessages } from '../../../../../constants/toast-messages'
import {
  useOrgReservationsInfo,
  useAccountReservationsInfo
} from '../../../../shared/hooks'
import { getInfoByType } from './index'

export const InfoHandler = () => {
  const { orgId } = useParams()
  const { values, setValues } = useFormikContext()
  const { region, type, isEmpty } = values

  const { info, isLoadingInfo, isErrorInfo } = orgId
    ? useOrgReservationsInfo({ orgId, region, type })
    : useAccountReservationsInfo({
        region,
        type
      })

  useEffect(() => {
    if (info && isEmpty && !isLoadingInfo && !isErrorInfo) {
      const { basePrice, priceMap, quota } = getInfoByType(info, type)

      setValues((prev) => ({
        ...prev,
        dates: {
          ...prev.dates,
          quota: quota || []
        },
        prices: {
          ...prev.prices,
          basePrice: basePrice || 0,
          discountMap: priceMap?.dayCount || {}
        },
        isEmpty: false
      }))
    }
  }, [info])

  return null
}

export const SuccessHandler = () => {
  const { search } = useLocation()
  const success = new URLSearchParams(search).get('success')
  const toast = useToast()

  useEffect(() => {
    if (success === 'true')
      toast({
        status: 'success',
        message: toastMessages.reservationCreateSuccess
      })

    if (success === 'false')
      toast({
        status: 'warning',
        message: toastMessages.reservationCreateError
      })
  }, [success])

  return null
}
