import {
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Link,
  Spacer,
  Skeleton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import routes from '../../../../constants/routes'
import { formatDateTime } from '../../../../helpers/date'
import { externalLinks } from '../../../../helpers/links'
import { status } from '../../../../helpers/volumes'
import { withOrgPathFallback } from '../../../../router'
import SupportError from '../../../Errors/SupportError'
import { Edit, Plus } from '../../../shared/Icons'
import { Card } from '../../../shared/Cards'
import { ReturnLink } from '../../../shared/Links'
import Region from '../../../shared/Region'
import { TableCell } from '../../../shared/Table'
import {
  useOrgVolume,
  useAccountVolume,
  useHasWriteProjectPermissions
} from '../../../shared/hooks'
import { AttachModal, DeleteModal, RenameModal } from '../modals'
import TableRow from './table-row'
import CopyButton from '../../../shared/CopyButton'
import ResourceStatus from '../../../shared/ResourceStatus'

const Loading = () => (
  <Stack spacing={8}>
    <Skeleton h="45px" />
    <Skeleton h="340px" />
    <Skeleton h="200px" />
    <Skeleton h="120px" />
  </Stack>
)

const Error = () => (
  <SupportError pt={6}>
    {`Something went wrong loading the volume's settings.`}
  </SupportError>
)

const VolumesView = () => {
  const deleteDisclosure = useDisclosure()
  const attachDisclosure = useDisclosure()
  const renameDisclosure = useDisclosure()
  const { orgId, projectId, volumeId } = useParams()
  const hasWriteProjectPermissions = orgId
    ? useHasWriteProjectPermissions({
        projectId,
        orgId
      })
    : true
  const { volume, isLoadingVolume, isErrorVolume } = orgId
    ? useOrgVolume({ orgId, projectId, volumeId })
    : useAccountVolume({ volumeId })

  const isAttached = volume?.attachments.length > 0
  const canBeDeleted = volume?.status !== status.deleting && !isAttached
  const isTableEmpty = volume?.attachments.length === 0

  return (
    <Stack spacing={2}>
      <ReturnLink to={withOrgPathFallback(routes.dashboard.volumes.index)}>
        Volumes
      </ReturnLink>
      {isLoadingVolume ? (
        <Loading />
      ) : isErrorVolume ? (
        <Error />
      ) : (
        <Stack spacing={8}>
          <HStack spacing={3}>
            <Heading as="h1" size="4xl" noOfLines={1} maxWidth="600px">
              {volume.name}
            </Heading>
            <ResourceStatus status={volume.status} />
          </HStack>
          <Card as={Stack} spacing={8}>
            <HStack justify="space-between">
              <Heading size="xl">Volume details</Heading>
              {hasWriteProjectPermissions && (
                <Button
                  colorScheme="offBlack"
                  variant="outline"
                  leftIcon={<Edit />}
                  onClick={renameDisclosure.onOpen}
                >
                  Rename Volume
                </Button>
              )}
            </HStack>
            <Grid templateColumns="1fr 1fr" columnGap={16} rowGap={5}>
              <Stack spacing={0}>
                <Text>Location</Text>
                <Region slug={volume.region} />
              </Stack>
              <Stack spacing={0}>
                <Text>Created</Text>
                <Text color="gray.500">{formatDateTime(volume.createdAt)}</Text>
              </Stack>
              <Stack spacing={0}>
                <Text>Name</Text>
                <Text color="gray.500" noOfLines={2}>
                  {volume.name}
                </Text>
              </Stack>
              <Stack spacing={0}>
                <Text>Description</Text>
                <Text color="gray.500" noOfLines={2}>
                  {volume.description || '-'}
                </Text>
              </Stack>
              <Stack spacing={0}>
                <Text>Volume ID</Text>
                <CopyButton color="gray.500" value={volume.id} />
              </Stack>
              <GridItem rowSpan={1} colSpan={2}>
                <Divider />
              </GridItem>
              <Stack spacing={0}>
                <Text>Type</Text>
                <Text color="gray.500">
                  {volume.type.toUpperCase()} Block Storage
                </Text>
              </Stack>
              <Stack spacing={0}>
                <Text>Configuration</Text>
                <Grid
                  templateColumns="1fr 1fr"
                  columnGap={5}
                  rowGap={0}
                  w="fit-content"
                >
                  <Text color="gray.500">Size</Text>
                  <Text color="gray.500">{volume.size} GiB</Text>
                  <Text color="gray.500">File system</Text>
                  <Text color="gray.500">Ext4</Text>
                </Grid>
              </Stack>
            </Grid>
          </Card>
          <Card as={Stack} spacing={8}>
            <HStack justify="space-between">
              <Stack>
                <Heading size="xl">Instances</Heading>
                <Text color="gray.500" size="sm">
                  Attached volumes are automatically mounted and formatted.{' '}
                  <Link
                    href={externalLinks.attachVolumes}
                    isExternal
                    colorScheme="offBlack"
                  >
                    Learn more.
                  </Link>
                  <br />
                  This volume can only be attached to one instance at a time.
                </Text>
              </Stack>
              {hasWriteProjectPermissions && (
                <Button
                  colorScheme="offBlack"
                  variant="outline"
                  leftIcon={<Plus color="black" />}
                  isDisabled={isAttached}
                  onClick={attachDisclosure.onOpen}
                >
                  Attach to Instance
                </Button>
              )}
            </HStack>
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th w="20%">Instance name</Th>
                    <Th w="20%">Location</Th>
                    <Th w="20%">Created</Th>
                    <Th w="20%">Status</Th>
                    <Th w="10%" />
                  </Tr>
                </Thead>
                <Tbody>
                  {isTableEmpty ? (
                    <TableCell>There are no attached instances yet.</TableCell>
                  ) : (
                    volume?.attachments.map((attachment) => (
                      <TableRow
                        key={attachment.instance.id}
                        volume={volume}
                        attachment={attachment}
                        hasPermission={hasWriteProjectPermissions}
                      />
                    ))
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Card>
          {hasWriteProjectPermissions && (
            <Card as={Flex}>
              <Stack>
                <Heading size="xl">Delete Volume</Heading>
                <Text color="gray.500" size="sm">
                  To delete the Volume, please detach all Instances from this
                  Volume.
                </Text>
              </Stack>
              <Spacer />
              <Button
                colorScheme="red"
                isDisabled={!canBeDeleted}
                onClick={deleteDisclosure.onOpen}
              >
                Delete Volume
              </Button>
            </Card>
          )}
          <DeleteModal disclosure={deleteDisclosure} volume={volume} />
          <AttachModal disclosure={attachDisclosure} volume={volume} />
          <RenameModal disclosure={renameDisclosure} volume={volume} />
        </Stack>
      )}
    </Stack>
  )
}

export default VolumesView
