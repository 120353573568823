import geoData from './data/geo.json'

// Reference: https://github.com/totemstech/country-reverse-geocoding/blob/master/lib/country_reverse_geocoding.js

/**
 * Checks if a point is contained in a polygon
 * (based on the Jordan curve theorem), for more info:
 * http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
 * @param polygon array a series of the polygon's coordinates
 * @param point object representing the point's coordinates
 * @return boolean true if the point lies within the polygon, false otherwise
 */
function pointInPolygon(polygon, point) {
  const nvert = polygon.length
  let c = false
  let i = 0
  let j = 0
  for (i = 0, j = nvert - 1; i < nvert; j = i++) {
    if (
      polygon[i][1] > point[1] !== polygon[j][1] > point[1] &&
      point[0] <
        ((polygon[j][0] - polygon[i][0]) * (point[1] - polygon[i][1])) /
          (polygon[j][1] - polygon[i][1]) +
          polygon[i][0]
    ) {
      c = !c
    }
  }
  return c
}

/**
 * Get country information from coordinates
 * @param lat number latitude
 * @param lng number longitude
 * @return object { code: '', name: '' }
 *         information about a country, null if not in a country
 */
export function getCountryIdFromGeo(lat, lng) {
  if (typeof lat !== 'number' || typeof lng !== 'number') {
    return new Error('Wrong coordinates (' + lat + ',' + lng + ')')
  }

  const point = [lng, lat]
  let i = 0
  let found = false
  do {
    const country = geoData[i]
    if (country.geometry.type === 'Polygon') {
      found = pointInPolygon(country.geometry.coordinates[0], point)
    } else if (country.geometry.type === 'MultiPolygon') {
      let j = 0
      do {
        found = pointInPolygon(country.geometry.coordinates[j][0], point)
        j++
      } while (j < country.geometry.coordinates.length && !found)
    }
    i++
  } while (i < geoData.length && !found)

  if (found) {
    return geoData[i - 1].id
  } else {
    return null
  }
}
