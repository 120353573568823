import { Button, Link as ChakraLink, Grid, Stack, Text } from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'
import routes from '../../constants/routes'
import { externalLinks } from '../../helpers/links'
import { withOrgPathFallback } from '../../router'
import { Nvidia } from './Icons'

const Wrapper = ({ children }) => (
  <Grid
    templateColumns="1fr 160px"
    columnGap={8}
    p={10}
    borderRadius="10px"
    bg="blue.100"
  >
    {children}
  </Grid>
)

export const BannerReservation = () => (
  <Wrapper>
    <Stack spacing={5}>
      <Nvidia />
      <Text size="md" fontWeight={400}>
        NVIDIA® HGX H100s are here. Starting at $1.99/day. Reserve your GPUs
        now!
      </Text>
    </Stack>
    <Button
      as={ReactLink}
      to={withOrgPathFallback(routes.dashboard.reservations.create)}
    >
      Reserve now
    </Button>
  </Wrapper>
)

export const BannerH100 = () => (
  <Wrapper>
    <Stack spacing={5}>
      <Nvidia />
      <Text size="md" fontWeight={400}>
        NVIDIA HGX™ H100s are here. Available from Q4 2023 and starting at
        $1.99/h. Reserve your instance now!
      </Text>
    </Stack>
    <Button as={ChakraLink} isExternal href={externalLinks.nvidia}>
      Find out more!
    </Button>
  </Wrapper>
)
