import { apiRequest, COMPUTE_ENDPOINT } from './index'
import { serialize } from '../helpers/serializers'

export class OrgAvailability {
  static fetchAvailability = async ({ orgId, projectId, region }) => {
    const availability = await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/availability/${region}/instances?org=${orgId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(availability)
  }
}

export class AccountAvailability {
  static fetchAvailability = async ({ region }) => {
    const availability = await apiRequest(
      `${COMPUTE_ENDPOINT}/availability/${region}/instances`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(availability)
  }
}
