import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { mutate } from 'swr'
import {
  AccountSecurityGroups,
  OrgSecurityGroups
} from '../../../../../api/securityGroups'
import routes from '../../../../../constants/routes'
import { toastMessages } from '../../../../../constants/toast-messages'
import { withOrgPathFallback } from '../../../../../router'
import { useToast } from '../../../../shared/Toast'
import { useIsMounted } from '../../../../shared/hooks/useIsMounted'

export const DeleteModal = ({ securityGroup, disclosure }) => {
  const { orgId, projectId } = useParams()
  const { isOpen, onClose } = disclosure
  const [isSubmitting, setIsSubmitting] = useState()
  const isMounted = useIsMounted()
  const history = useHistory()
  const toast = useToast()

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true)
    try {
      const securityGroupId = securityGroup.id
      const response = orgId
        ? await OrgSecurityGroups.deleteById({
            orgId,
            projectId,
            securityGroupId
          })
        : await AccountSecurityGroups.deleteById({ securityGroupId })
      if (response.ok) {
        orgId
          ? await mutate((key) =>
              key.startsWith(`/projects/${projectId}/security-groups?`)
            )
          : await mutate((key) => key.startsWith(`/security-groups?`))
        toast({
          status: 'success',
          message: toastMessages.securityGroupDeleteSuccess
        })
        onClose()
        history.push(withOrgPathFallback(routes.dashboard.securityGroups.index))
      }
    } catch {
      toast({
        status: 'error',
        message: toastMessages.securityGroupDeleteError
      })
    } finally {
      if (isMounted()) {
        setIsSubmitting(false)
      }
    }
  }, [securityGroup])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <Heading size="xl" mb={4} textAlign="center">
              Are you sure you want to delete security group “
              {securityGroup.name}”?
            </Heading>
            <Text
              mb={8}
              size="md"
              textAlign="center"
              color="red.500"
              fontWeight="normal"
            >
              This action will permanently delete the security group and it
              cannot be recovered.
            </Text>
            <Stack spacing={4}>
              <Button
                type="submit"
                isLoading={isSubmitting}
                colorScheme="red"
                onClick={handleSubmit}
              >
                Delete Security Group
              </Button>
              <Button
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isSubmitting}
                onClick={onClose}
                isFullWidth
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
