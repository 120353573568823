import { Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { SkeletonTable } from '../../../../../shared/Skeletons'
import {
  INITIAL_PAGE,
  TableError,
  TablePagination
} from '../../../../../shared/Table'
import {
  useIsRole,
  useOrganizationMembers,
  useOrganizationWithId
} from '../../../../../shared/hooks/organization'
import TableRow from './table-row'

const MembersList = () => {
  const { orgId } = useParams()
  const [page, setPage] = useState(INITIAL_PAGE)
  const { organization } = useOrganizationWithId(orgId)
  const { members, isError, isLoading } = useOrganizationMembers({
    orgId,
    page
  })
  const userIsAdmin = useIsRole(organization, 'ADMIN')

  return (
    <>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th w="40%">Name</Th>
              <Th w="10%">Role</Th>
              <Th w="10%">2FA</Th>
              <Th w="10%">Status</Th>
              <Th w="10%">Joined</Th>
              <Th w="10%">Last active</Th>
              {userIsAdmin && <Th w="10%" />}
            </Tr>
          </Thead>
          {isLoading && <SkeletonTable rows={3} columns={6} />}
          {isError ? (
            <TableError>Something went wrong loading the members.</TableError>
          ) : (
            <Tbody>
              {members?.members?.map((member) => (
                <TableRow key={member.accountId} member={member} />
              ))}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      {!isError && (
        <TablePagination
          page={page}
          setPage={setPage}
          totalCount={members?.totalCount}
        />
      )}
    </>
  )
}

export default MembersList
