import { Button, Flex, Heading, Stack, Text, VStack } from '@chakra-ui/react'
import { APITokenTypes, getTokenType } from '../../../../../../helpers/security'
import CopyInput from '../../../../../shared/CopyInput'

const ComputeToken = ({ token, onClose }) => (
  <Flex direction="column" gap="8">
    <VStack spacing={4}>
      <Heading size="xl">Generate new API Token</Heading>
      <Text color="red.500" size="md" fontWeight={400} textAlign="center">
        The secret token will only be shown once. Save it in a safe place.
      </Text>
    </VStack>
    <Stack spacing={2}>
      <Text size="md" fontWeight={400}>
        Token type
      </Text>
      <Text size="md" color="gray.500" fontWeight={400}>
        {getTokenType(token.keyType)}
      </Text>
    </Stack>
    <Stack spacing={2}>
      <Text size="md" fontWeight={400}>
        Secret token
      </Text>
      <CopyInput value={token.secretKey} />
    </Stack>
    <Button onClick={onClose}>Done</Button>
  </Flex>
)

const ObjectStorageToken = ({ token, onClose }) => {
  return (
    <Flex direction="column" gap="8">
      <VStack spacing={4}>
        <Heading size="xl">Generate new API Token</Heading>
        <Text color="red.500" size="md" fontWeight={400} textAlign="center">
          The access key and secret token will only be shown once. Save them in
          a safe place.
        </Text>
      </VStack>
      <Stack spacing={2}>
        <Text size="md" fontWeight={400}>
          Token type
        </Text>
        <Text size="md" color="gray.500" fontWeight={400}>
          {getTokenType(token.keyType)}
        </Text>
      </Stack>
      <Stack spacing={2}>
        <Text size="md" fontWeight={400}>
          Access key
        </Text>
        <CopyInput value={token.accessKey} />
      </Stack>
      <Stack spacing={2}>
        <Text size="md" fontWeight={400}>
          Secret token
        </Text>
        <CopyInput value={token.secretKey} />
      </Stack>
      <Button onClick={onClose}>Done</Button>
    </Flex>
  )
}

const Token = ({ token, onClose }) => {
  if (!token) {
    return null
  }

  return (
    <>
      {token.keyType === APITokenTypes.compute && (
        <ComputeToken token={token} onClose={onClose} />
      )}
      {token.keyType === APITokenTypes.objectStorage && (
        <ObjectStorageToken token={token} onClose={onClose} />
      )}
    </>
  )
}

export default Token
