import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Link,
  Stack,
  Text
} from '@chakra-ui/react'
import { externalLinks } from '../../../../../helpers/links'
import { statuses } from '../../../../../helpers/reservations'
import {
  OrgReservations,
  AccountReservations
} from '../../../../../api/reservations'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { mutate } from 'swr'
import { useIsMounted } from '../../../../shared/hooks/useIsMounted'
import { useToast } from '../../../../shared/Toast'
import { toastMessages } from '../../../../../constants/toast-messages'

export const CancelModal = ({ disclosure, reservation }) => {
  const { orgId } = useParams()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const isMounted = useIsMounted()
  const { isOpen, onClose } = disclosure
  const isPending = reservation.status === statuses.pendingPayment
  const reservationId = reservation.id
  const toast = useToast()

  const onSubmit = async () => {
    setIsSubmitting(true)

    try {
      if (orgId) {
        await OrgReservations.cancel({ orgId, reservationId })
      } else {
        await AccountReservations.cancel({ reservationId })
      }
      await mutate((key) => key.startsWith('/reservations?'))

      toast({
        status: 'success',
        message: toastMessages.saved
      })
      onClose()
    } catch {
      toast({
        status: 'error',
        message: toastMessages.notSaved
      })
    } finally {
      if (isMounted()) {
        setIsSubmitting(false)
      }
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={8}>
            <Heading size="xl" textAlign="center">
              Cancel reservation
            </Heading>
            {isPending ? (
              <Text
                size="md"
                textAlign="center"
                color="red.500"
                fontWeight="normal"
              >
                This action will permanently delete the reservation and cancel
                any pending payments.
              </Text>
            ) : (
              <Text size="md" textAlign="center" fontWeight="normal">
                To cancel a reservation please{' '}
                <Link
                  isExternal
                  href={externalLinks.newTicket}
                  colorScheme="offBlack"
                >
                  contact support
                </Link>
                .
              </Text>
            )}
            <Stack spacing={4}>
              {isPending && (
                <Button
                  isLoading={isSubmitting}
                  onClick={onSubmit}
                  colorScheme="red"
                >
                  Cancel reservation
                </Button>
              )}
              <Button
                colorScheme="offBlack"
                variant="outline"
                onClick={onClose}
                isFullWidth
              >
                Close
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
