import {
  Box,
  Slider as ChakraSlider,
  HStack,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'

const Slider = () => {
  const { values, setValues } = useFormikContext()
  const configurations = values.type?.configurations
  const max = configurations?.items.length
  const selected = configurations?.selected
  const isGPU = selected?.gpu
  const text = isGPU ? 'GPUs' : 'Size'
  const maxValue = isGPU
    ? configurations?.items[configurations.items.length - 1].gpu.value
    : max
  const selectedValue = isGPU ? selected?.gpu?.value : selected?.sliderValue
  const isOneItem = max === 1

  const handleChange = (value) => {
    const selected = configurations.items.find(
      (item) => item.sliderValue === value
    )
    setValues({
      ...values,
      typeConfigSlug: selected?.slug,
      type: {
        ...values.type,
        configurations: { ...values.type.configurations, selected }
      }
    })
  }

  return (
    <HStack spacing={6} p={5}>
      <Text>{text}</Text>
      <ChakraSlider
        defaultValue={1}
        value={selected?.sliderValue}
        min={isOneItem ? 0 : 1}
        max={max}
        step={1}
        onChange={handleChange}
        isDisabled={isOneItem}
      >
        <SliderTrack bg="gray.200">
          <Box position="relative" right={10} />
          <SliderFilledTrack bg="blue.500" />
        </SliderTrack>
        <SliderThumb boxSize={6} />
      </ChakraSlider>
      <Box whiteSpace="nowrap">
        {selectedValue} / {maxValue}
      </Box>
    </HStack>
  )
}

export default Slider
