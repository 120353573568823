import { Text, Heading as HeadingChakra, Link, VStack } from '@chakra-ui/react'
import MetaTags from 'react-meta-tags'
import ResponsiveContainer from '../shared/Layout/ResponsiveContainer'
import { externalLinks } from '../../helpers/links'

const Error = ({ statusCode, title, message, children }) => (
  <ResponsiveContainer logo={true}>
    <MetaTags>
      <meta name="robots" content="noindex" />
    </MetaTags>
    <VStack spacing={8}>
      <HeadingChakra size="3xl" textAlign="center">
        {statusCode}. {title}
      </HeadingChakra>
      <Text color="gray.500" textAlign="center">
        {message} If you need any further help, visit our{' '}
        <Link
          colorScheme="offBlack"
          href={externalLinks.knowledgeBase}
          isExternal
        >
          knowledge base
        </Link>{' '}
        or{' '}
        <Link colorScheme="offBlack" href={externalLinks.newTicket} isExternal>
          contact us
        </Link>
        .
      </Text>
      {children}
    </VStack>
  </ResponsiveContainer>
)

export default Error
