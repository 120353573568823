import { Button } from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as AccountApi from '../../../api/account'
import routes from '../../../constants/routes'
import { toastMessages } from '../../../constants/toast-messages'
import * as profileType from '../../../constants/profile-type'
import { useToast } from '../../shared/Toast'
import { OrganizationIdStorage, ProjectIdStorage } from '../../shared/storage'

const PersonalForm = () => {
  const history = useHistory()
  const [isSubmitting, setIsSubmitting] = useState()
  const toast = useToast()

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault()
      setIsSubmitting(true)
      try {
        const data = {
          profile_type: profileType.personal
        }
        await AccountApi.patchAccount(data)
        OrganizationIdStorage.clear()
        ProjectIdStorage.clear()
        history.push(routes.onboarding.details)
      } catch {
        toast({
          status: 'error',
          message: toastMessages.notSaved
        })
      }
    },
    [history]
  )

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit}>
      <Button
        mt={4}
        onClick={handleSubmit}
        type="submit"
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
        isFullWidth
      >
        Save and continue
      </Button>
    </form>
  )
}
export default PersonalForm
