import {
  Link,
  Td,
  Tr,
  Menu,
  MenuButton,
  MenuList,
  MenuDivider,
  MenuItem,
  IconButton,
  useDisclosure
} from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'
import routes from '../../../../../constants/routes'
import { formatDateTime } from '../../../../../helpers/date'
import { withOrgPathFallback } from '../../../../../router'
import Region from '../../../../shared/Region'
import { TdTruncated } from '../../../../shared/Table'
import { Bin, Dots, Settings } from '../../../../shared/Icons'
import { DeleteModal } from '../modals'

const TableRow = ({ row, hasPermission }) => {
  const { name, region, bucketUrl, createdAt } = row
  const disclosure = useDisclosure()

  const link = `${withOrgPathFallback(
    routes.dashboard.buckets.view.replace(':bucketName', name)
  )}?region=${region}`

  return (
    <Tr>
      <TdTruncated data-hj-suppress>
        <Link colorScheme="gray" as={ReactLink} to={link}>
          {name}
        </Link>
      </TdTruncated>
      <Td>
        <Region slug={region} />
      </Td>
      <TdTruncated data-hj-suppress color="gray.500">
        {bucketUrl || '-'}
      </TdTruncated>
      <Td>{formatDateTime(createdAt)}</Td>
      <Td>
        <Menu variant="table" autoSelect={false}>
          <MenuButton variant="icon" as={IconButton} icon={<Dots />} />
          <MenuList>
            <MenuItem
              _hover={{ textDecoration: 'none', color: 'black' }}
              as={ReactLink}
              to={link}
            >
              <Settings mr={2.5} />
              Bucket settings
            </MenuItem>
            {hasPermission && (
              <>
                <MenuDivider />
                <MenuItem onClick={disclosure.onOpen}>
                  <Bin mr={2.5} />
                  Delete
                </MenuItem>
                <DeleteModal disclosure={disclosure} bucket={row} />
              </>
            )}
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  )
}

export default TableRow
