export const toastMessages = {
  saved: 'Changes saved',
  notSaved: 'Changes could not be saved',
  instanceCreateSuccess: 'The Instance is being created',
  instanceDestroySuccess: 'Instance destroyed',
  volumeCreateSuccess: 'Volume created',
  volumeDeleteSuccess: 'Volume deleted',
  volumeDeleteError: 'Volume could not be deleted',
  snapshotCreateSuccess: 'Snapshot is being created',
  snapshotRenameSuccess: 'Snapshot renamed',
  snapshotRenameError: 'Snapshot could not be renamed',
  snapshotDeleteSuccess: 'Snapshot deleted',
  snapshotDeleteError: 'Snapshot could not be deleted',
  APITokenCreateSuccess: 'API Token generated',
  APITokenCreateError: 'Unable to generate API Token',
  APITokenDeleteSuccess: 'API Token deleted',
  APITokenDeleteError: 'API Token could not be deleted',
  SSHKeyAddSuccess: 'SSH Key added',
  SSHKeyDeleteSuccess: 'SSH Key deleted',
  SSHKeyDeleteError: 'SSH Key could not be deleted',
  floatingIPCreateSuccess: 'Floating IP is being created',
  floatingIPDeleteSuccess: 'Floating IP is being deleted',
  floatingIPDeleteError: 'Floating IP could not be deleted',
  securityGroupCreateSuccess: 'Security Group created',
  securityGroupCreateError: 'Unable to create Security Group',
  securityGroupDeleteSuccess: 'Security Group deleted',
  securityGroupDeleteError: 'Unable to delete Security Group',
  objectStorageEnableSuccess: 'Object Storage enabled',
  objectStorageEnableError: 'Could not enable Object Storage',
  bucketCreateSuccess: 'Bucket created',
  bucketCreateError: 'Unable to create Bucket',
  bucketDeleteSuccess: 'Bucket deleted',
  bucketDeleteError: 'Unable to delete Bucket',
  filesystemCreateSuccess: 'Filesystem created',
  filesystemCreateError: 'Unable to create Filesystem',
  filesystemDeleteSuccess: 'Filesystem deleted',
  filesystemDeleteError: 'Unable to delete Filesystem',
  quotaUpdateSuccess:
    'After approving the request, an automated email will be sent to your email',
  quotaUpdateError: 'Your request has not been submitted',
  accountFetchError: 'Could not load profile details',
  accountVerifyPhoneSuccess: 'Phone number verified',
  organizationCreateError: 'Unable to create Organization',
  organizationUpdateError: 'Unable to update Organization',
  organizationLeaveSuccess: 'You have left the Organization',
  organizationLeaveError: 'Unable to leave Organization',
  organizationTransferSuccess: 'Organization ownership transferred',
  organizationTransferError: 'Unable to transfer Organization ownership',
  organizationRemoveDefaultSuccess: 'Organization is no longer set to default',
  organizationRemoveDefaultError: 'Unable to remove default Organization',
  organizationDeleteSuccess: 'Organization deleted',
  organizationDeleteError: 'Could not delete Organization',
  projectCreateSuccess: 'Project created',
  projectCreateError: 'Unable to create Project',
  projectDeleteSuccess: 'Project deleted',
  projectDeleteError: 'Unable to delete Project',
  memberUpdateSuccess: 'Member updated',
  memberUpdateError: 'Unable to update Member',
  memberAddSuccess: 'Member(s) added to the Project',
  memberAddError: 'Unable to add Member(s) to the Project',
  memberDeleteSuccess: 'Member removed',
  memberDeleteError: 'Unable to remove Member',
  invitationRemoveSuccess: 'Invitation removed',
  invitationRemoveError: 'Unable to remove invitation',
  billingDetailsUpdateSuccess: 'Billing details updated',
  creditCardAddSuccess: 'Credit card added',
  creditCardUpdateSuccess: 'Credit card updated',
  creditCardAddError: 'Could not add credit card',
  payNowError: 'Unable to retrieve payment information',
  reservationCreateSuccess: 'Reservation has been created successfully',
  reservationCreateError: 'Reservation is pending payment',
  communityInstanceCreateSuccess: 'Community Instance created',
  projectMigrateSuccess:
    'The Project is being migrated. This process may take several minutes to complete',
  rechargeSuccess: 'Account Balance recharge successful',
  rechargeProcessing: 'Account Balance recharge is being processed',
  rechargeError: 'Unable to complete Account Balance recharge'
}
