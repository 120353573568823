import {
  Link,
  Menu,
  IconButton,
  MenuButton,
  MenuList,
  MenuItem,
  Tr,
  Td,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'
import routes from '../../../../constants/routes'
import { formatDateTime } from '../../../../helpers/date'
import { withOrgPathFallback } from '../../../../router'
import { Dots, Trash } from '../../../shared/Icons'
import { TdTruncated, TdTruncatedLines } from '../../../shared/Table'
import { DeleteModal } from '../modals'
import CopyButton from '../../../shared/CopyButton'
import ResourceStatus from '../../../shared/ResourceStatus'

const TableRow = ({ row, hasPermission }) => {
  const {
    id,
    name,
    ip,
    typeName,
    gpuCount,
    cpuCount,
    ram,
    storage,
    createdAt,
    status
  } = row
  const disclosure = useDisclosure()

  const link = withOrgPathFallback(
    routes.dashboard.communityInstances.view.replace(':communityInstanceId', id)
  )

  return (
    <Tr>
      <Td whiteSpace="normal">
        <CopyButton noOfLines={2} value={id} />
      </Td>
      <TdTruncated>
        <Link colorScheme="gray" as={ReactLink} to={link}>
          {name}
        </Link>
      </TdTruncated>
      <Td>
        <CopyButton value={ip} />
      </Td>
      <TdTruncatedLines noOfLines={2}>{typeName}</TdTruncatedLines>
      <Td>
        <Text size="xs">
          GPUs: {gpuCount}GiB, CPUs: {cpuCount}GiB,
          <br />
          RAM: {ram}GiB, Storage: {storage}GiB
        </Text>
      </Td>
      <Td>{formatDateTime(createdAt)}</Td>
      <Td>
        <ResourceStatus status={status} />
      </Td>
      <Td>
        {hasPermission && (
          <Menu variant="table" autoSelect={false}>
            <MenuButton variant="icon" as={IconButton} icon={<Dots />} />
            <MenuList>
              <MenuItem gap={2} onClick={disclosure.onOpen}>
                <Trash boxSize={5} />
                Delete
              </MenuItem>
              <DeleteModal disclosure={disclosure} instance={row} />
            </MenuList>
          </Menu>
        )}
      </Td>
    </Tr>
  )
}

export default TableRow
