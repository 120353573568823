import { useEffect } from 'react'
import { processSilentRenew } from 'redux-oidc'

const SilentRenew = () => {
  useEffect(() => {
    processSilentRenew()
  }, [])

  return <div />
}

export default SilentRenew
