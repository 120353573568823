export const MIN_SIZE = 1

const slugs = {
  ssd: 'volumeSSD',
  hdd: 'volumeHDD',
  vast: 'filesystemVast'
}

export const calcMaxSize = ({ quota, type }) => {
  const value = quota?.quota.find((item) => item.slug === slugs[type])
  return value?.quota - value?.count
}

export const hasAvaliableSize = ({ size, maxSize }) => {
  let avaliableSize
  const value = Math.trunc(Number(size))

  if (isNaN(value)) {
    avaliableSize = false
  } else if (value < 0) {
    avaliableSize = false
  } else {
    avaliableSize = maxSize - value >= 0
  }
  return avaliableSize
}
