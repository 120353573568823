import {
  Button,
  Input,
  InputGroup,
  InputRightElement,
  useClipboard
} from '@chakra-ui/react'

const CopyInput = ({ value }) => {
  const { onCopy, hasCopied } = useClipboard(value || '')

  return (
    <InputGroup>
      <Input pr="76px" defaultValue={value} />
      <InputRightElement width="76px">
        <Button
          size="xs"
          variant="input"
          colorScheme="gray"
          width="60px"
          onClick={onCopy}
        >
          {hasCopied ? 'Copied' : 'Copy'}
        </Button>
      </InputRightElement>
    </InputGroup>
  )
}

export default CopyInput
