import { Stack, Heading } from '@chakra-ui/react'
import Edit from './edit'
import Members from './members'
import Leave from './leave'
import Delete from './delete'

const Admin = ({ project }) => {
  return (
    <Stack spacing={8}>
      <Heading size="4xl">Project settings</Heading>
      <Edit project={project} />
      <Members />
      {!project?.isDefault && (
        <>
          <Leave project={project} />
          <Delete project={project} />
        </>
      )}
    </Stack>
  )
}

export default Admin
