import { Divider, List, ListItem, Text } from '@chakra-ui/react'
import { Card } from '../../../../shared/Cards'
import moment from 'moment'

const formateDate = (range) => {
  if (range) {
    const start = moment(range[0]).format('MMM D')
    const end = moment(range[1]).format('MMM D')
    return `${start} - ${end}`
  }
}

const Summary = ({ range, total }) => {
  const date = formateDate(range)
  const totalValue = parseInt(total / 1440 / 1000)

  return (
    <Card p={2} minWidth="240px">
      <List spacing={1}>
        <ListItem display="flex" justifyContent="space-between" gap={3}>
          <Text color="gray.500">Current period</Text>
          <Text>{date || '...'}</Text>
        </ListItem>
        <Divider borderColor="gray.300" />
        <ListItem display="flex" justifyContent="space-between" gap={3}>
          <Text color="gray.500">Aggregated usage</Text>
          <Text>{`${totalValue || 0} GiB`}</Text>
        </ListItem>
      </List>
    </Card>
  )
}

export default Summary
