import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import * as yup from 'yup'
import { AccountInstances, OrgInstances } from '../../../../api/instances'
import { apiErrorHandler } from '../../../shared/ApiErrorHandler'
import Asterisk from '../../../shared/Asterisk'
import { useToast } from '../../../shared/Toast'
import { toastMessages } from '../../../../constants/toast-messages'

const validationSchema = yup.object().shape({
  hostname: yup
    .string()
    .required('Hostname is required')
    .matches(/^[a-z0-9]([-a-z0-9]*[a-z0-9])?$/, 'Invalid hostname')
    .max(63),
  name: yup.string().required('Name is required')
})

export const RenameModal = ({ disclosure, instance }) => {
  const { orgId, projectId } = useParams()
  const toast = useToast()
  const { isOpen, onClose } = disclosure
  const { id: instanceId, name, hostname } = instance

  const initialValues = {
    hostname,
    name
  }

  const onSubmit = async ({ name }, { setSubmitting }) => {
    const payload = { name }
    setSubmitting(true)

    try {
      if (orgId) {
        await OrgInstances.updateById({
          orgId,
          projectId,
          instanceId,
          payload
        })
        await mutate(
          `/projects/${projectId}/instances/${instanceId}?org=${orgId}`
        )
      } else {
        await AccountInstances.updateById({
          instanceId,
          payload
        })
        await mutate(`/instances/${instanceId}`)
      }
      toast({
        status: 'success',
        message: toastMessages.saved
      })
      onClose()
    } catch (error) {
      const message = await apiErrorHandler(error)
      toast({
        status: 'error',
        message
      })
    } finally {
      setSubmitting(false)
    }
  }

  const { values, handleChange, errors, handleSubmit, isSubmitting } =
    useFormik({
      validationSchema,
      initialValues,
      onSubmit
    })

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap="8" as="form" onSubmit={handleSubmit}>
            <Heading size="xl" textAlign="center">
              Rename Instance
            </Heading>
            <FormControl>
              <FormLabel>
                <Asterisk />
                Hostname
              </FormLabel>
              <Input
                isDisabled
                name="hostname"
                value={values.hostname}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isInvalid={errors.name}>
              <FormLabel>
                <Asterisk />
                Name
              </FormLabel>
              <Input
                autoFocus
                name="name"
                value={values.name}
                onChange={handleChange}
              />
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>
            <Stack spacing={4}>
              <Button type="submit" isLoading={isSubmitting}>
                Save
              </Button>
              <Button
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isSubmitting}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Stack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
