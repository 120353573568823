import { useState } from 'react'
import {
  Stack,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th
} from '@chakra-ui/react'
import { SkeletonTable } from '../../../../../shared/Skeletons'
import {
  INITIAL_PAGE,
  TableCell,
  TableError,
  TablePagination
} from '../../../../../shared/Table'
import {
  useOrgBillingOnDemands,
  useAccountBillingOnDemands
} from '../../../../../shared/hooks'
import TableRow from './table-row'

const InstancesList = ({ orgId }) => {
  const [page, setPage] = useState(INITIAL_PAGE)

  const { onDemands, pagination, isLoadingOnDemands, isErrorOnDemands } = orgId
    ? useOrgBillingOnDemands({ orgId, page })
    : useAccountBillingOnDemands({ page })
  const isTableEmpty = onDemands?.length === 0

  return (
    <Stack spacing={2}>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th w="20%">Name</Th>
              <Th w="30%">Product type</Th>
              <Th w="15%">Start</Th>
              <Th w="15%">End</Th>
              <Th w="10%">Usage</Th>
              <Th w="10%">Cost</Th>
            </Tr>
          </Thead>
          {isLoadingOnDemands && (
            <SkeletonTable rows={5} columns={6} hasAction={false} />
          )}
          {isErrorOnDemands ? (
            <TableError>
              Something went wrong loading the On-demand Instances.
            </TableError>
          ) : (
            <Tbody>
              {isTableEmpty ? (
                <TableCell>There are no On-demand Instances yet.</TableCell>
              ) : (
                onDemands?.map((onDemand, index) => (
                  <TableRow key={index} row={onDemand} />
                ))
              )}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      {!isErrorOnDemands && (
        <TablePagination
          page={page}
          setPage={setPage}
          perPage={pagination?.perPage}
          totalCount={pagination?.totalCount}
        />
      )}
    </Stack>
  )
}

export default InstancesList
