import {
  Box,
  FormControl,
  FormErrorMessage,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { useField, useFormikContext } from 'formik'
import { v4 as uuidv4 } from 'uuid'
import { none, rotated } from '../../../constants/styles'
import {
  rulesActions,
  rulesData,
  rulesProtocols,
  rulesTypes,
  updateRules
} from '../../../helpers/securityGroups'
import { ArrowDown, Bin } from '../Icons'
import { PortField } from './PortField'

export const RulesField = ({ name }) => {
  const [, meta] = useField(name)
  const { values, setFieldValue } = useFormikContext()
  const rules = values[name]

  const handleClick = ({
    action,
    rules,
    ruleId,
    newRule = false,
    newProtocol = false
  }) => {
    const updatedRules = updateRules({
      action,
      rules,
      ruleId,
      newRule,
      newProtocol
    })
    setFieldValue(name, updatedRules)
  }

  return (
    <FormControl isInvalid={meta.error && meta.touched}>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th w="30%">Type</Th>
              <Th w="30%">Protocol</Th>
              <Th w="30%">Ports</Th>
              <Th w="10%" isNumeric />
            </Tr>
          </Thead>
          <Tbody>
            {rules.map((rule) => (
              <Tr key={rule.id}>
                <Td>
                  <Menu variant="topbar" autoSelect={false} flip={false}>
                    {({ isOpen }) => (
                      <>
                        <MenuButton type="button">
                          <HStack>
                            <Text color="gray.500">{rule.type}</Text>
                            <ArrowDown
                              color="gray.500"
                              transform={isOpen ? rotated : none}
                            />
                          </HStack>
                        </MenuButton>
                        <MenuList minW={32}>
                          {rulesData.map((newRule) => {
                            const id = uuidv4()
                            return (
                              <Box key={id}>
                                <MenuItem
                                  px={4}
                                  py={2}
                                  onClick={() =>
                                    handleClick({
                                      action: rulesActions.updateType,
                                      rules,
                                      ruleId: rule.id,
                                      newRule: { ...newRule, id }
                                    })
                                  }
                                >
                                  {newRule.type}
                                </MenuItem>
                                <MenuDivider m={0} />
                              </Box>
                            )
                          })}
                        </MenuList>
                      </>
                    )}
                  </Menu>
                </Td>
                <Td>
                  {rule.type === rulesTypes.CUSTOM ? (
                    <Menu variant="topbar" autoSelect={false} flip={false}>
                      {({ isOpen }) => (
                        <>
                          <MenuButton type="button">
                            <HStack>
                              <Text color="gray.500">{rule.protocol}</Text>
                              <ArrowDown
                                color="gray.500"
                                transform={isOpen ? rotated : none}
                              />
                            </HStack>
                          </MenuButton>
                          <MenuList minW={32}>
                            {rulesProtocols.map((newProtocol, index) => (
                              <Box key={index}>
                                <MenuItem
                                  px={4}
                                  py={2}
                                  onClick={() =>
                                    handleClick({
                                      action: rulesActions.updateProtocol,
                                      rules,
                                      ruleId: rule.id,
                                      newProtocol
                                    })
                                  }
                                >
                                  {newProtocol}
                                </MenuItem>
                                <MenuDivider m={0} />
                              </Box>
                            ))}
                          </MenuList>
                        </>
                      )}
                    </Menu>
                  ) : (
                    rule.protocol
                  )}
                </Td>
                <Td>
                  {rule.isPortEditable ? (
                    <PortField rule={rule} rulesName={name} />
                  ) : (
                    rule.port
                  )}
                </Td>
                <Td isNumeric>
                  <Bin
                    color="gray.500"
                    _hover={{ cursor: 'pointer', color: 'gray.900' }}
                    onClick={() =>
                      handleClick({
                        action: rulesActions.delete,
                        rules,
                        ruleId: rule.id
                      })
                    }
                  />
                </Td>
              </Tr>
            ))}
            <Tr>
              <Td>
                <Menu variant="topbar" autoSelect={false} flip={false}>
                  {({ isOpen }) => (
                    <>
                      <MenuButton type="button">
                        <HStack>
                          <Text color="blue.700">New rule</Text>
                          <ArrowDown
                            color="blue.700"
                            transform={isOpen ? rotated : none}
                          />
                        </HStack>
                      </MenuButton>
                      <MenuList minW={32}>
                        {rulesData.map((newRule) => {
                          const id = uuidv4()
                          return (
                            <Box key={id}>
                              <MenuItem
                                px={4}
                                py={2}
                                onClick={() =>
                                  handleClick({
                                    action: rulesActions.create,
                                    rules,
                                    newRule: {
                                      ...newRule,
                                      id,
                                      direction: name
                                    }
                                  })
                                }
                              >
                                {newRule.type}
                              </MenuItem>
                              <MenuDivider m={0} />
                            </Box>
                          )
                        })}
                      </MenuList>
                    </>
                  )}
                </Menu>
              </Td>
              <Td />
              <Td />
              <Td />
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}
