import {
  Button,
  Heading,
  Link,
  Spacer,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { useState } from 'react'
import { Link as ReactLink, useParams, useHistory } from 'react-router-dom'
import routes from '../../../constants/routes'
import { externalLinks } from '../../../helpers/links'
import { withOrgPathFallback } from '../../../router'
import Footer from '../../shared/Footer'
import { Plus } from '../../shared/Icons'
import { SkeletonTable } from '../../shared/Skeletons'
import {
  INITIAL_PAGE,
  TableCell,
  TableError,
  TablePagination
} from '../../shared/Table'
import { useHasWriteProjectPermissions } from '../../shared/hooks/project'
import {
  useAccountInstancesPaginated,
  useAccountUnusedSubscriptions,
  useOrgInstancesPaginated,
  useOrgUnusedSubscriptions
} from '../../shared/hooks/useInstance'
import InstancesTableRow from './InstancesTableRow'
import { mutateHandler } from './Create/utils'

const InstancesList = () => {
  const history = useHistory()
  const { orgId, projectId } = useParams()
  const [page, setPage] = useState(INITIAL_PAGE)
  const hasWriteProjectPermissions = orgId
    ? useHasWriteProjectPermissions({
        projectId,
        orgId
      })
    : true
  const { instances, pagination, isError, isLoading } =
    orgId && projectId
      ? useOrgInstancesPaginated({ orgId, projectId, page })
      : useAccountInstancesPaginated({ page })
  const { unusedSubscriptions } = orgId
    ? useOrgUnusedSubscriptions({ orgId })
    : useAccountUnusedSubscriptions()

  const handleRedirect = () => {
    history.push(withOrgPathFallback(routes.dashboard.instances.create))
    mutateHandler()
  }

  return (
    <Stack spacing={8}>
      <Heading as="h1" size="4xl">
        Instances
      </Heading>
      {unusedSubscriptions?.length > 0 && (
        <Link
          as={ReactLink}
          to={withOrgPathFallback(routes.dashboard.instances.create)}
        >
          Create an Instance based on an existing Long-term subscription
        </Link>
      )}
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th width="20%">Name</Th>
              <Th width="15%">Location</Th>
              <Th width="10%">Public IPv4</Th>
              <Th width="10%">Private IPv4</Th>
              <Th width="25%">Accelerator</Th>
              <Th width="10%">Created</Th>
              <Th width="5%">Status</Th>
              {useHasWriteProjectPermissions && <Th w="5%" />}
            </Tr>
          </Thead>
          {isLoading && <SkeletonTable rows={10} columns={8} />}
          {isError ? (
            <TableError>Something went wrong loading the instances.</TableError>
          ) : (
            <Tbody>
              {instances?.length === 0 ? (
                <TableCell>There are no Instances yet.</TableCell>
              ) : (
                instances?.map((instance) => (
                  <InstancesTableRow
                    key={instance.id}
                    instance={instance}
                    hasWriteProjectPermissions={hasWriteProjectPermissions}
                  />
                ))
              )}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      {!isError && (
        <TablePagination
          page={page}
          setPage={setPage}
          totalCount={pagination?.totalCount}
        />
      )}
      <Footer>
        <Link href={externalLinks.attachInstances} isExternal>
          Learn more about Instances
        </Link>
        <Spacer />
        {hasWriteProjectPermissions && (
          <Button onClick={handleRedirect} leftIcon={<Plus />}>
            Create new Instance
          </Button>
        )}
      </Footer>
    </Stack>
  )
}

export default InstancesList
