import React, { useEffect, useState } from 'react'
import { Input, Select, Box, Center, Spinner, Stack } from '@chakra-ui/react'
import { getCountries, getCountryById } from './country'
import { getCountryIdFromGeo } from './geo'
import { getCountryIdFromLanguage } from './language'
import { parsePhoneNumber } from './phone'

const PhoneInput = ({
  id,
  name,
  countryId,
  isDisabled,
  isLoading,
  onCountryChange,
  onPhoneNumberChange
}) => {
  const [value, setValue] = useState()
  const [country, setCountry] = useState()
  const [phoneNumber, setPhoneNumber] = useState()

  useEffect(() => {
    if (countryId) {
      setCountry(getCountryById(countryId))
    } else if (countryId === null && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const id = getCountryIdFromGeo(
            position.coords.latitude,
            position.coords.longitude
          )
          if (id) {
            const country = getCountryById(id)
            if (country) {
              setCountry(country)
            }
          }
        },
        () => {
          const id = getCountryIdFromLanguage() || 'DE'
          setCountry(getCountryById(id))
        }
      )
    }
  }, [countryId])

  useEffect(() => {
    if (!country) {
      return
    }
    if (onCountryChange) {
      onCountryChange(country)
    }
    if (value) {
      const { phoneNumber } = parsePhoneNumber(value, country.id)
      setPhoneNumber(phoneNumber || value)
    }
  }, [country, value])

  useEffect(() => {
    if (onPhoneNumberChange) {
      onPhoneNumberChange(phoneNumber)
    }
  }, [phoneNumber])

  const handleChange = (number, id) => {
    setValue(number)
    const { phoneNumber, countryId } = parsePhoneNumber(number, id)
    if (countryId) {
      setCountry(getCountryById(countryId))
    }
    setPhoneNumber(phoneNumber || number)
  }

  return (
    <Box position="relative" w="full">
      {country && !isLoading ? (
        <Box position="absolute" zIndex={1}>
          <Select
            isDisabled={isDisabled}
            value={country.id}
            position="absolute"
            opacity={0}
            top={0}
            left={0}
            zIndex={1}
            width="75px"
            cursor="pointer"
            border="none"
            _disabled={{ opacity: 0 }}
            style={{ appearance: 'none' }}
            onChange={(event) => {
              setCountry(getCountryById(event.target.value))
            }}
          >
            {getCountries().map((country) => (
              <option
                key={country.id}
                value={country.id}
              >{`${country.name} (${country.dialCode}) ${country.flag}`}</option>
            ))}
          </Select>
          <Center w="65px" h={10} fontSize="lg">
            {country.flag}
          </Center>
        </Box>
      ) : (
        <Center w="75px" h={10} position="absolute">
          <Spinner size="sm" />
        </Center>
      )}
      <Stack direction="column" spacing={0}>
        <Input
          type="tel"
          id={id}
          name={name}
          borderRadius="20px"
          placeholder="Enter your phone number"
          pl="75px"
          _focus={{ border: '1px solid', borderColor: 'blue.500' }}
          isDisabled={!country || isDisabled}
          onChange={(event) => handleChange(event.target.value, country.id)}
        />
      </Stack>
    </Box>
  )
}

export default PhoneInput
