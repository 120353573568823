const styles = {
  global: () => ({
    body: {
      fontFamily: 'Work Sans',
      fontSize: 14
    }
  })
}

export default styles
