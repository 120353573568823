export const errorCodes = {
  '02001': 'Invalid route',
  '01215': 'You have reached the GPU/CPU limit',
  '01216': 'Provide a payment method to use this service',
  '01221': 'You have reached the Snapshot quota limit',
  '01234': 'Your password needs to be at least 16 characters long',
  '01236': 'Invalid SSH Key',
  '01238': 'This account is not allowed to use this Instance type',
  '01240': 'The selected image cannot be run on this Instance',
  '01241': 'Please add a valid payment method to continue',
  '01242': 'Payment unsucessful. Please check your card details and try again',
  '01243':
    'You need 3DS authentication, contact support to proceed with this payment method',
  '01244': 'This instance has termination protection enabled',
  '01245':
    'Your account is suspended. Contact support to reinstate the account',
  '01246': 'Your account is banned',
  '01247': 'Your account is disabled',
  '01251': 'Your Floating IP quota has been reached',
  '01256':
    'The Instance resources are temporarily out of stock. Please try again later',
  '01258':
    'Unable to create invitation. The emails provided are already Members of the Organization',
  '01264': 'The selected driver is incompatible with the image',
  '01274': 'At least one project admin is required',
  '02003':
    'Please ensure all required fields are filled out correctly in the form',
  '02019': 'Valid payment method required',
  '02040':
    'Your account is suspended. Contact support to reinstate the account',
  '02066': 'Filesystem quota reached',
  '02068': 'Filesystem resize invalid',
  '03215': 'Please add a valid VAT number',
  '03230': 'Reservation already exists for the provided date range',
  '03231': 'Reservation exceeds allowed size for the provided date range',
  '01229': 'The bucket name is already in use. Please choose a different name',
  '01266': 'Mutations to legacy endpoints are no longer supported',
  '01230': 'Your Volume size quota has been reached',
  '01273':
    'Account is already migrated or migrating (Account is already migrated or migrating)',
  '01275': 'Compute Resources must be deleted before migration',
  default: 'Something went wrong'
}
