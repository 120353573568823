import { Button, Flex, Spacer, Stack, Text } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { idToDeleteUpdated } from '../../../../../../slices/ui/organizations'
import { Card } from '../../../../../shared/Cards'
import { useOrgParam } from '../../../../../shared/hooks/useOrgParam'
import { DeleteModal } from '../../manage/modals'

export const DeleteCard = () => {
  const dispatch = useDispatch()
  const orgId = useOrgParam()

  return (
    <Card as={Flex}>
      <Stack flex={7}>
        <Text mb={2} size="xl">
          Delete Organization
        </Text>
        <Text mb={5} size="sm" color="gray.500">
          Everything associated with the Organization (including all Instances,
          backups, and other resources) will be permanently deleted.
        </Text>
      </Stack>
      <Spacer />
      <Button
        w={48}
        colorScheme="red"
        onClick={() => dispatch(idToDeleteUpdated(orgId))}
      >
        Delete Organization
      </Button>
      <DeleteModal />
    </Card>
  )
}
