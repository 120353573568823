import { Stack, Text, Link, Button } from '@chakra-ui/react'
import { Card } from '../../../../../shared/Cards'
import { externalLinks } from '../../../../../../helpers/links'
import authPrompt from '../../../../../../helpers/auth/authPrompt'

export const TFACard = ({ tfaEnabled }) => (
  <Card display="flex" justifyContent="space-between">
    <Stack>
      <Text mb={2} size="xl">
        Two-factor authentication
      </Text>
      <Text mb={5} size="sm" color="gray.500">
        <Link isExternal href={externalLinks.twoFA}>
          Two-factor authentication (2FA)
        </Link>{' '}
        adds an extra layer of security to your account. You will need to
        provide a code along with your username and password to log in.
      </Text>
    </Stack>
    <Button
      w={40}
      alignSelf="start"
      colorScheme="offBlack"
      variant="outline"
      onClick={() => {
        authPrompt('setup-totp')
      }}
    >
      {!tfaEnabled ? 'Enable 2FA' : 'Disable 2FA'}
    </Button>
  </Card>
)
