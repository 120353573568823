import { Heading } from '@chakra-ui/react'
import Notification from './Notification'

const NotificationSection = ({ header, notifications, onClose }) => (
  <>
    {notifications.length > 0 && (
      <>
        <Heading as="h3" size="md" my={2} color="gray.300">
          {header}
        </Heading>
        {notifications.map((item) => (
          <Notification key={item.id} notification={item} onClose={onClose} />
        ))}
      </>
    )}
  </>
)

export default NotificationSection
