import {
  Link as ChakraLink,
  CloseButton,
  HStack,
  ListItem,
  Spacer,
  Stack,
  Text,
  UnorderedList,
  useDisclosure
} from '@chakra-ui/react'
import { InfoFilled, Warning } from './Icons'

const Link = ({ href }) => (
  <ChakraLink isExternal href={href} fontWeight="400" colorScheme="offBlack">
    More Info.
  </ChakraLink>
)

const Close = ({ onClick }) => (
  <CloseButton
    size="sm"
    alignSelf="flex-start"
    position="relative"
    right={-1}
    top={-1}
    onClick={onClick}
  />
)

export const AlertInfo = ({ children, ...props }) => (
  <HStack p={4} borderRadius="10px" bg="blue.100" {...props}>
    <InfoFilled color="blue.500" />
    {children}
  </HStack>
)

export const AlertMaintenances = ({ alerts }) => {
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true })

  return (
    isVisible && (
      <HStack align="flex-start" px={5} py={3} bg="orange.100">
        <Warning mt={0.5} color="orange.500" />
        <Stack spacing={0}>
          <Text fontWeight="600" color="gray.700">
            Scheduled maintenance:
          </Text>
          <UnorderedList pl={4}>
            {alerts.map((alert) => (
              <ListItem key={alert.id}>
                <Text fontWeight="600" color="gray.700">
                  {alert.name} <Link href={alert.shortlink} />
                </Text>
              </ListItem>
            ))}
          </UnorderedList>
        </Stack>
        <Spacer />
        <Close onClick={onClose} />
      </HStack>
    )
  )
}

export const Alert = ({ message, link }) => {
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true })

  return (
    isVisible && (
      <HStack align="flex-start" px={5} py={3} bg="orange.100">
        <Warning mt={0.5} color="orange.500" />
        <Text fontWeight="600" color="gray.700">
          {message} <Link href={link} />
        </Text>
        <Spacer />
        <Close onClick={onClose} />
      </HStack>
    )
  )
}

export const AlertWarning = ({ children, ...props }) => (
  <HStack align="flex-start" px={5} py={3} bg="orange.100" {...props}>
    <Warning mt={0.5} color="orange.500" />
    <Text fontWeight="600" color="gray.700">
      {children}
    </Text>
  </HStack>
)
