import { INITIAL_PAGE, PER_PAGE } from '../components/shared/Table'
import { serialize } from '../helpers/serializers'
import { apiRequest, COMPUTE_ENDPOINT } from './index'

export class OrgSnapshots {
  static fetchAll = async ({
    orgId,
    projectId,
    page = INITIAL_PAGE,
    perPage = PER_PAGE,
    region
  }) => {
    const regionArg = region ? `&region=${region}` : ''
    const snapshots = await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/snapshots?page=${page}&per_page=${perPage}&org=${orgId}${regionArg}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(snapshots)
  }

  static fetchImageOptions = async ({ orgId, projectId }) => {
    const snapshots = await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/images?org=${orgId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(snapshots)
  }

  static fetchCatalogImages = async ({ orgId, projectId }) => {
    const snapshots = await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/catalog?per_page=50&page=1&org=${orgId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(snapshots)
  }

  static create = async ({ orgId, projectId, instanceId, name }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/instances/${instanceId}/snapshots?org=${orgId}`,
      {
        method: 'POST',
        body: JSON.stringify({ name })
      },
      true
    )

  static deleteById = async ({ orgId, projectId, snapshotId }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/snapshots/${snapshotId}?org=${orgId}`,
      {
        method: 'DELETE'
      },
      true
    )

  static renameById = async ({ orgId, projectId, snapshotId, name }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/snapshots/${snapshotId}?org=${orgId}`,
      {
        method: 'PATCH',
        body: JSON.stringify({ name })
      },
      true
    )

  static createImage = async ({ orgId, projectId, instanceId, name }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/instances/${instanceId}/snapshots?org=${orgId}`,
      {
        method: 'POST',
        body: JSON.stringify({ name })
      },
      true
    )
}

export class AccountSnapshots {
  static fetchAll = async ({
    page = INITIAL_PAGE,
    perPage = PER_PAGE,
    region
  }) => {
    const regionArg = region ? `&region=${region}` : ''
    const snapshots = await apiRequest(
      `${COMPUTE_ENDPOINT}/snapshots?page=${page}&per_page=${perPage}${regionArg}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(snapshots)
  }

  static fetchImageOptions = async () => {
    const snapshots = await apiRequest(
      `${COMPUTE_ENDPOINT}/images`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(snapshots)
  }

  static fetchCatalogImages = async () => {
    const snapshots = await apiRequest(
      `${COMPUTE_ENDPOINT}/catalog?per_page=50&page=1`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(snapshots)
  }

  static deleteById = async ({ snapshotId }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/snapshots/${snapshotId}`,
      {
        method: 'DELETE'
      },
      true
    )

  static renameById = async ({ snapshotId, name }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/snapshots/${snapshotId}`,
      {
        method: 'PATCH',
        body: JSON.stringify({ name })
      },
      true
    )

  static create = async ({ instanceId, name }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/instances/${instanceId}/snapshots`,
      {
        method: 'POST',
        body: JSON.stringify({ name })
      },
      true
    )
}
