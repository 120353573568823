import {
  Stack,
  Grid,
  Heading,
  Text,
  Skeleton,
  FormControl,
  FormErrorMessage
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { Card } from '../../../shared/Cards'
import { PasswordField, CopyPassword } from '../../../shared/Fields'

const Authentication = ({ isLoading }) => {
  const { values, setValues, errors, touched } = useFormikContext()

  const onCopy = (hasCopied) =>
    setValues((prev) => ({
      ...prev,
      authentication: { ...prev.authentication, hasCopied }
    }))

  const onChange = (password) =>
    setValues((prev) => ({
      ...prev,
      authentication: { password, hasCopied: false }
    }))

  const passwordError = errors?.authentication?.password
  const passwordTouched = touched?.authentication?.password

  const hasCopiedError = errors?.authentication?.hasCopied
  const hasCopiedTouched = touched?.authentication?.hasCopied

  const isPasswordInvalid = passwordError && passwordTouched
  const isHasCopiedInvalid = hasCopiedError && hasCopiedTouched

  return (
    <Stack spacing={8}>
      <Heading size="xl">Authentication</Heading>
      {isLoading ? (
        <Skeleton h="165px" />
      ) : (
        <FormControl isInvalid={isPasswordInvalid || isHasCopiedInvalid}>
          <Card
            as={Stack}
            spacing={8}
            p={5}
            hasError={isPasswordInvalid || isHasCopiedInvalid}
          >
            <Text color="gray.500">
              Note: The password will only be displayed once. Please copy and
              save it securely.
            </Text>
            <Grid templateColumns="2fr 1fr 1fr" alignItems="end" gap={3}>
              <PasswordField
                name="authentication.password"
                onChange={onChange}
              />
              <CopyPassword
                value={values.authentication.password}
                onChange={onCopy}
              />
            </Grid>
          </Card>
          <FormErrorMessage>{passwordError ?? hasCopiedError}</FormErrorMessage>
        </FormControl>
      )}
    </Stack>
  )
}

export default Authentication
