import {
  Flex,
  Stack,
  SimpleGrid,
  Heading,
  Button,
  Box,
  useDisclosure,
  Skeleton,
  Text,
  HStack
} from '@chakra-ui/react'
import { Card } from '../../../shared/Cards'
import { Edit } from '../../../shared/Icons'
import { useParams } from 'react-router-dom'
import { useAccountBilling, useOrgBilling } from '../../../shared/hooks'
import { Error } from '../../../shared/Error'
import { DetailsModal } from './modals'

const DetailsItem = ({ label, value }) => (
  <HStack
    justifyContent="space-between"
    borderBottom="1px solid"
    borderColor="gray.100"
  >
    <Text color="gray.500">{label}</Text>
    <Text>{value || '-'}</Text>
  </HStack>
)

const Details = () => {
  const editDisclosure = useDisclosure()
  const { orgId } = useParams()
  const { billing, isLoadingBilling, isErrorBilling } = orgId
    ? useOrgBilling({ orgId })
    : useAccountBilling()

  return (
    <Card as={Stack} spacing={8}>
      <Flex justify="space-between">
        <Heading size="xl">Billing details</Heading>
        <Button
          colorScheme="offBlack"
          variant="outline"
          leftIcon={<Edit />}
          onClick={editDisclosure.onOpen}
        >
          Edit details
        </Button>
      </Flex>
      {isLoadingBilling ? (
        <SimpleGrid columns={2} spacing={16}>
          <Skeleton h="140px"></Skeleton>
          <Skeleton h="140px"></Skeleton>
        </SimpleGrid>
      ) : isErrorBilling ? (
        <Error />
      ) : (
        <SimpleGrid columns={2} spacing={16}>
          <Box>
            <DetailsItem label="Billing email" value={billing?.billingEmail} />
            <DetailsItem label="Full name" value={billing?.name} />
            <DetailsItem label="Business name" value={billing?.companyName} />
            <DetailsItem label="VAT number" value={billing?.vat} />
            <DetailsItem label="Phone number" value={billing?.phone} />
          </Box>
          <Box>
            <DetailsItem label="Address" value={billing?.address} />
            <DetailsItem label="City" value={billing?.city} />
            <DetailsItem
              label="ZIP / Postal code"
              value={billing?.postalCode}
            />
            <DetailsItem
              label="Country / region"
              value={billing?.countryCode}
            />
          </Box>
        </SimpleGrid>
      )}
      <DetailsModal disclosure={editDisclosure} />
    </Card>
  )
}

export default Details
