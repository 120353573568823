export default {
  success: 'Success!',
  error: 'Error!',
  emailAlreadyExists:
    'Sorry, that email address is already associated with an account.',
  alreadyFederated:
    'The primary email of your SSO provider is already being used by an existing Account. Try again with a different provider account.',
  signinGoogleAccountNotFound:
    'An account with this Google sign in was not found. Please try a different sign in method or contact support if you are unable to access your account.',
  signinGithubAccountNotFound:
    'An account with this Github sign in was not found. Please try a different sign in method or contact support if you are unable to access your account.',
  unauthorized:
    'Invalid credentials. Please try a different sign in method or contact support if you are unable to access your account.',
  signinUnknownError:
    'There was a problem trying to signin. Please try a different sign in method or contact support if you are unable to access your account.',
  signupUnknownError:
    'There was a problem trying to signup. Please try a different sign up method or contact support if you are unable to create a new account.',
  temporaryEmailsNotAllowed: 'Disposable temporary emails are not allowed.',
  recaptchaMissing: 'You must check the reCAPTCHA checkbox.',
  somethingWentWrong: 'Something went wrong, please try again.',
  pricingError: 'Something went wrong fetching billing information.',
  billingDetailsSavedSuccess: 'Billing details were saved successfully.',
  addCreditCardError: 'There was an error adding your Credit Card',
  addCreditCardSuccess: 'The credit card was successfully added.',
  addCreditCardAddressError:
    'The Address information is missing or incomplete.',
  addCreditCardConsentMessage:
    'Please agree to the terms of service and give us consent to charge you in the future.',
  changeDefaultPaymentMethodTitle: 'Default Payment Method changed',
  changeDefaultPaymentMethodSuccess:
    'Your default payment method will be used in your next invoice.',
  deleteCreditCardSuccess: (last4) =>
    `Your Credit Card xxxx-${last4} was deleted.`,
  deleteCreditCardError: (last4) =>
    `Your Credit Card xxxx-${last4} was not deleted, please try again.`,
  updateCreditCardSuccess: 'The credit card was successfully updated.',
  updateCreditCardError: 'There was an error updating your Credit Card',
  enableComputeService:
    'Setting up the Compute Service for you. This might take a minute.',
  enableComputeServiceError:
    'There was an error while enabling Compute Service.',
  enableComputeServiceSuccess:
    'The Compute Service is set up and ready to be used.',
  genericError: 'Something went wrong.',
  notAllowed: 'Not allowed!',
  accountSuspended:
    'Your account is suspended. Contact support to reinstate the account.',
  accountBanned: 'Your account is banned.',
  accountDisabled: 'Your account is disabled.',
  createInstanceLimitTitle: 'GPU/CPU quota exceeded',
  createInstanceLimitError: 'You already reached the GPU/CPU limit.',
  createInstanceStaticIPLimitTitle: 'Quota exceeded',
  createInstanceStaticIPLimitError:
    'You already reached the Static Public IP limit.',
  createInstanceNoSSHKeysTitle: 'Authentication method missing.',
  createInstanceNoSSHKeysError: 'You must select at least one SSH Key.',
  createInstanceNoSecurityGroupTitle: 'Security group missing.',
  createInstanceNoSecurityGroupError:
    'You must select at least one security group.',
  createInstanceWeakPasswordTitle: 'Weak password.',
  createInstanceWeakPasswordError:
    'The password should be at least 16 characters long.',
  createInstanceTypeError:
    'This account is not allowed to use this instance type.',
  createInstanceNoPaymentError:
    'You need to provide a payment to use this service.',
  createInstancePaymentFailedError:
    'Payment failed, check your Card and try again.',
  createInstancePaymentActionRequiredError:
    'You need 3DS authentication, contact support to proceed with this payment method.',
  createInstanceIncompatibleImageError:
    'The selected image cannot be run on this instance.',
  createInstanceNoClusterCapacityTitle: 'Temporarily unavailable',
  createInstanceNoClusterCapacityError:
    'The instance resources are temporarily out of stock. Try again later.',
  createInstanceImageDriverIncompatibleError:
    'The selected driver is incompatible with the image.',
  createInstancePasswordTitle: 'Required Action',
  createInstancePasswordError:
    'Please copy the generated password to access your instance.',
  createInstanceDriversTitle: 'Required Action',
  createInstanceInputDriverError:
    'You need to install the driver to use a GPU.',
  invalidPublicKey: 'Invalid Public key',
  projectLeaveInfo:
    'Once you have left the Project, you will not be able to see and manage its resources.',
  rulesEmpty:
    'At least one inbound/outbound rule is required to create a Security Group.',
  rulesDuplicate: 'Rules list contains duplicate rules.'
}
