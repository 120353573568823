import { Fragment } from 'react'
import { Link as ReactLink, useParams } from 'react-router-dom'
import {
  Center,
  Flex,
  Stack,
  Button,
  SimpleGrid,
  Heading,
  Text,
  Link,
  useDisclosure
} from '@chakra-ui/react'
import { Card } from '../../../shared/Cards'
import { Rules } from '../../../shared/Rules'
import { Plus } from '../../../shared/Icons'
import Spinner from '../../../shared/Spinner'
import SupportError from '../../../Errors/SupportError'
import { buildCombinedRules } from '../../../../helpers/securityGroups'
import {
  useOrgInstanceSecurityGroups,
  useAccountInstanceSecurityGroups
} from '../../../shared/hooks/useInstance'
import routes from '../../../../constants/routes'
import { withOrgPathFallback } from '../../../../router'
import { AssignModal } from '../Modals'

const Loading = () => (
  <Center>
    <Spinner />
  </Center>
)

const Error = () => (
  <SupportError>
    {`Something went wrong loading the instance's security groups.`}
  </SupportError>
)

const SecurityGroups = ({ instance, hasPermission }) => {
  const assignDisclosure = useDisclosure()
  const { orgId, projectId, instanceId } = useParams()
  const { securityGroups, isLoading, isError } = orgId
    ? useOrgInstanceSecurityGroups({ orgId, projectId, instanceId })
    : useAccountInstanceSecurityGroups({ instanceId })

  const rules = buildCombinedRules(securityGroups)

  return (
    <Card as={Stack} spacing={8}>
      <Flex justify="space-between">
        <Heading size="xl">Security groups</Heading>
        {hasPermission && (
          <>
            <Button
              colorScheme="offBlack"
              variant="outline"
              leftIcon={<Plus color="black" />}
              onClick={assignDisclosure.onOpen}
            >
              Assign Security group
            </Button>
            <AssignModal disclosure={assignDisclosure} instance={instance} />
          </>
        )}
      </Flex>
      {isLoading ? (
        <Loading />
      ) : isError ? (
        <Error />
      ) : (
        <>
          <SimpleGrid columns={2} columnGap={16}>
            <Text mb={1}>Security Groups</Text>
            <Text mb={1}>Description</Text>
            {securityGroups.map(({ id, name, description }) => (
              <Fragment key={id}>
                <Link
                  colorScheme="gray"
                  as={ReactLink}
                  to={withOrgPathFallback(
                    routes.dashboard.securityGroups.view.replace(
                      ':securityGroupId',
                      id
                    )
                  )}
                >
                  {name}
                </Link>
                <Text color="gray.500" noOfLines={1}>
                  {description}
                </Text>
              </Fragment>
            ))}
          </SimpleGrid>
          <Rules rules={rules} isCombined />
        </>
      )}
    </Card>
  )
}
export default SecurityGroups
