import { Link, Text } from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'
import routes from '../../../../../constants/routes'
import { withOrgPathFallback } from '../../../../../router'
import { getRegionTitle } from '../../../../shared/Region'

export const CapacityError = () => (
  <Text color="red.500">
    The selected Instance configuration exceeds your allowed limits.{' '}
    <Link
      colorScheme="red"
      to={withOrgPathFallback(routes.dashboard.quota)}
      as={ReactLink}
    >
      Request more Quota
    </Link>
  </Text>
)

export const AvailabilityError = ({ region }) => (
  <Text color="orange.500">
    The selected instance type is temporarily out of stock in{' '}
    {getRegionTitle(region)}.
  </Text>
)
