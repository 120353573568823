import {
  Button,
  Flex,
  HStack,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Card } from '../../../../../../shared/Cards'
import {
  useIsAdmin,
  useProject,
  useProjectMembers
} from '../../../../../../shared/hooks/project'
import { SkeletonTable } from '../../../../../../shared/Skeletons'
import {
  INITIAL_PAGE,
  TableError,
  TablePagination
} from '../../../../../../shared/Table'
import TableRow from './table-row'
import { Plus } from '../../../../../../shared/Icons'
import { MembersModal } from '../../modals'

const Members = () => {
  const { projectId, orgId } = useParams()
  const [page, setPage] = useState(INITIAL_PAGE)
  const { members, isError, isLoading } = useProjectMembers({
    orgId,
    projectId,
    page
  })
  const { project } = useProject({ orgId, projectId })
  const accountIsAdmin = useIsAdmin(project?.userPermission)
  const membersDisclosure = useDisclosure()

  return (
    <Card as={Flex}>
      <Stack spacing={6} w="full">
        <HStack justifyContent="space-between">
          <Text size="xl">Members</Text>
          {accountIsAdmin && (
            <Button
              w={40}
              colorScheme="offBlack"
              variant="outline"
              leftIcon={<Plus color="black" />}
              onClick={membersDisclosure.onOpen}
            >
              Add Members
            </Button>
          )}
        </HStack>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th w="40%">Name</Th>
                <Th w="15%">Organization role</Th>
                <Th w="15%">Project permission</Th>
                <Th w="15%">Last active</Th>
                <Th w="15%" />
              </Tr>
            </Thead>
            {isLoading && <SkeletonTable rows={10} columns={4} />}
            {isError ? (
              <TableError>Something went wrong loading the members.</TableError>
            ) : (
              <Tbody>
                {members?.members.map((member) => (
                  <TableRow key={member.account.id} member={member} />
                ))}
              </Tbody>
            )}
          </Table>
        </TableContainer>
        {!isError && (
          <TablePagination
            page={page}
            setPage={setPage}
            totalCount={members?.totalCount}
          />
        )}
        <MembersModal
          disclosure={membersDisclosure}
          projectId={projectId}
          projectMembers={members?.members}
        />
      </Stack>
    </Card>
  )
}

export default Members
