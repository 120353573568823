import {
  Badge,
  Box,
  Flex,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Td,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import { useCallback } from 'react'
import { Link as ReactLink, useParams } from 'react-router-dom'
import routes from '../../../constants/routes'
import { formatDateTime } from '../../../helpers/date'
import {
  BILLING_TYPE_ON_DEMAND,
  BILLING_TYPE_RESERVED,
  startInstance,
  openConsole,
  status
} from '../../../helpers/instances'
import { withOrgPathFallback } from '../../../router'
import Accelerator from '../../shared/Accelerator'
import CopyButton from '../../shared/CopyButton'
import {
  Bin,
  Cancel,
  Dots,
  Instances,
  Reset,
  Settings,
  Snapshot,
  Start,
  Stop
} from '../../shared/Icons'
import Region from '../../shared/Region'
import ResourceStatus from '../../shared/ResourceStatus'
import Spinner from '../../shared/Spinner'
import { TdTruncated } from '../../shared/Table'
import { useToast } from '../../shared/Toast'
import { useProjectVersion } from '../../shared/hooks'
import {
  CancelModal,
  CreateSnapshotModal,
  DestroyModal,
  ResetModal,
  StopModal
} from './Modals'

const InstancesTableRow = ({ instance, hasWriteProjectPermissions }) => {
  const stopDisclosure = useDisclosure()
  const createSnapshotDisclosure = useDisclosure()
  const resetDisclosure = useDisclosure()
  const destroyDisclosure = useDisclosure()
  const cancelDisclosure = useDisclosure()
  const { orgId, projectId } = useParams()
  const { isLegacy, isB2 } = useProjectVersion({ projectId, orgId })
  const toast = useToast()

  const isPrepaid = ![BILLING_TYPE_ON_DEMAND, BILLING_TYPE_RESERVED].includes(
    instance.billingType
  )
  const isReserved = instance.billingType === BILLING_TYPE_RESERVED
  const isCreating = instance.status === status.creating
  const isActive = instance.status === status.active
  const isStarting = instance.status === status.starting
  const isStopped = instance.status === status.stopped
  const isStopping = instance.status === status.stopping
  const isShutoff = instance.status === status.shutoff
  const isPendingPayment = instance.status === status.pendingPayment
  const isErrored = instance.status === status.error
  const isResetting = instance.status === status.resetting
  const isDeleting = instance.status === status.deleting

  const instanceId = instance.id
  const opacity = isStopped || isStopping || isDeleting ? '0.4' : '1'

  const canBeDeleted =
    isActive || isStopped || isPendingPayment || isErrored || isShutoff
  const canBeStopped = isActive || isStarting || (isLegacy && isResetting)

  const enableCreateSnapshot = isActive || (isB2 && isStopped)
  const showLoader = (value) =>
    isCreating || (!value && (isActive || isStarting))

  const handleStartInstance = useCallback(async () => {
    await startInstance({ instanceId: instance.id, projectId, orgId, toast })
  }, [instance, projectId, orgId])

  const handleOpenConsole = () => {
    openConsole(instance.id, toast)
  }

  return (
    <Tr>
      <Td data-hj-suppress opacity={opacity} maxW={0}>
        <Flex gap="1">
          <Box overflow="hidden" textOverflow="ellipsis">
            <Link
              colorScheme="gray"
              as={ReactLink}
              to={withOrgPathFallback(
                routes.dashboard.instances.view.replace(
                  ':instanceId',
                  instanceId
                )
              )}
            >
              {instance.name}
            </Link>
          </Box>
          <Box>
            {isPrepaid && (
              <Badge size="small" colorScheme="blue" variant="outline">
                PREPAID
              </Badge>
            )}
            {isReserved && (
              <Badge size="small" colorScheme="blue" variant="outline">
                RESERVED
              </Badge>
            )}
          </Box>
        </Flex>
      </Td>
      <Td opacity={opacity}>
        <Region slug={instance.region} />
      </Td>
      <Td opacity={opacity}>
        {showLoader(instance.publicIp) ? (
          <Spinner color="gray.500" />
        ) : instance.publicIp ? (
          <CopyButton value={instance.publicIp} />
        ) : (
          '-'
        )}
      </Td>
      <Td opacity={opacity}>
        {showLoader(instance.privateIp) ? (
          <Spinner color="gray.500" />
        ) : (
          instance.privateIp || '-'
        )}
      </Td>
      <TdTruncated opacity={opacity}>
        {instance.gpuCount > 0 ? (
          <Accelerator
            gpuCount={instance.gpuCount}
            flavorName={instance.flavorName}
          />
        ) : (
          '-'
        )}
      </TdTruncated>
      <Td opacity={opacity}>{formatDateTime(instance.createdAt)}</Td>
      <Td>
        <ResourceStatus status={instance.status} />
      </Td>
      <Td>
        {hasWriteProjectPermissions && (
          <Menu variant="table" autoSelect={false}>
            <MenuButton variant="icon" as={IconButton} icon={<Dots />} />
            <MenuList>
              <MenuItem
                as={ReactLink}
                _hover={{ textDecoration: 'none', color: 'black' }}
                to={withOrgPathFallback(
                  routes.dashboard.instances.view.replace(
                    ':instanceId',
                    instanceId
                  )
                )}
              >
                <Settings mr={2.5} />
                Instance settings
              </MenuItem>
              {isB2 && isActive && (
                <MenuItem onClick={handleOpenConsole}>
                  <Instances boxSize={4} mr={2.5} />
                  Launch console
                </MenuItem>
              )}
              {isCreating && (
                <>
                  <MenuDivider />
                  <MenuItem onClick={cancelDisclosure.onOpen}>
                    <Cancel mr={2.5} />
                    Cancel
                  </MenuItem>
                </>
              )}
              {isStopped && (
                <>
                  <MenuDivider />
                  <MenuItem onClick={handleStartInstance}>
                    <Start mr={2.5} />
                    Start
                  </MenuItem>
                </>
              )}
              {enableCreateSnapshot && (
                <>
                  <MenuDivider />
                  <MenuItem onClick={createSnapshotDisclosure.onOpen}>
                    <Snapshot mr={2.5} />
                    Create Snapshot
                  </MenuItem>
                </>
              )}
              {(isActive || isShutoff) && (
                <>
                  <MenuDivider />
                  <MenuItem onClick={resetDisclosure.onOpen}>
                    <Reset mr={2} />
                    Reset
                  </MenuItem>
                </>
              )}
              {canBeStopped && (
                <>
                  <MenuDivider />
                  <MenuItem onClick={stopDisclosure.onOpen}>
                    <Stop mr={2.5} />
                    Stop
                  </MenuItem>
                </>
              )}
              {canBeDeleted && (
                <>
                  <MenuDivider />
                  <MenuItem onClick={destroyDisclosure.onOpen}>
                    <Bin mr={2.5} />
                    Destroy
                  </MenuItem>
                </>
              )}
            </MenuList>
            <StopModal disclosure={stopDisclosure} instance={instance} />
            <DestroyModal disclosure={destroyDisclosure} instance={instance} />
            <ResetModal disclosure={resetDisclosure} instance={instance} />
            <CancelModal disclosure={cancelDisclosure} instance={instance} />
            <CreateSnapshotModal
              disclosure={createSnapshotDisclosure}
              instance={instance}
            />
          </Menu>
        )}
      </Td>
    </Tr>
  )
}

export default InstancesTableRow
