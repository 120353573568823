import { useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { Stack, Text, Button } from '@chakra-ui/react'
import { Card } from '../../../../../shared/Cards'
import { Warning } from '../../../../../shared/Icons'
import { PhoneModal } from '../modals'

export const PhoneCard = ({ account }) => {
  const [modalVisible, setModalVisible] = useState(false)
  const location = useLocation()
  const number = account.phoneNumber

  useEffect(() => {
    setModalVisible(location.hash === '#verifyPhoneNumber')
  }, [location])

  const onCloseVerifyNumber = useCallback(
    (visible) => {
      setModalVisible(visible)
    },
    [setModalVisible]
  )

  return (
    <Card display="flex" justifyContent="space-between">
      <Stack>
        <Text mb={2} size="xl">
          Phone number verification
          {!number && <Warning ml={2} color="red.500" />}
        </Text>
        <Text mb={5} size="sm" color="gray.500">
          {number ||
            `You won't be able to access computing resources until you verified your phone number.`}
        </Text>
      </Stack>
      <Button
        w={40}
        alignSelf="start"
        colorScheme="OffBlack"
        variant="outline"
        onClick={() => setModalVisible(true)}
      >
        {!number ? 'Add number' : 'Change number'}
      </Button>
      <PhoneModal
        visible={modalVisible}
        onVisibilityChange={onCloseVerifyNumber}
      />
    </Card>
  )
}
