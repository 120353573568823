import BugsnagPerformance from '@bugsnag/browser-performance'
import bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react'
import { getMetadata } from './bugsnagMetadata'

const RELEASE_STAGES = ['production', 'staging', 'development']

if (RELEASE_STAGES.includes(process.env.REACT_APP_ENV)) {
  BugsnagPerformance.start({ apiKey: process.env.REACT_APP_BUGSNAG_API_KEY })
}

export const bugsnagClient = bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || 'off',
  notifyReleaseStages: RELEASE_STAGES,
  plugins: [new BugsnagPluginReact(React)],
  releaseStage: process.env.REACT_APP_ENV || 'local',
  logger: null,
  beforeSend: function (report) {
    const user = getMetadata()
    if (user) {
      report.user = user
    }
  }
})

const ErrorBoundary = bugsnagClient.getPlugin('react')
export default ErrorBoundary
