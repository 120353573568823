// BaseImages and CloudImages
const filterByBaseImages = (images) =>
  images?.filter(
    ({ type, active }) =>
      (type === 'base-os' || type === 'preconfigured') && active
  )

const filterByCloudImages = (images) =>
  images?.filter(({ type, active }) => type === 'cloud-image' && active)

const groupByFamily = (images, tab) =>
  images?.reduce((acc, image) => {
    const foundFamily = acc.find((acc) => acc.family === image.family)
    foundFamily
      ? foundFamily.versions.push(image)
      : acc.push({
          family: image.family,
          tab,
          versions: [image]
        })
    return acc
  }, [])

const filterByCompatibility = (versions, region, type) =>
  versions.filter(({ regions, compatibleFlavorNames }) => {
    const isRegionCompatible = regions.includes(region)
    const isTypeCompatible = compatibleFlavorNames.includes(type)
    return isRegionCompatible && isTypeCompatible
  })

const updateVersionsForSelect = (versions, family) =>
  versions.map(({ name, id, beta, supportsDrivers }) => {
    const versionTag = name.substring(family.length + 1) // e.g. 'Ubuntu ML PyTorch for NVIDIA' -> 'PyTorch for NVIDIA'
    const betaText = beta ? ' (beta)' : ''

    return {
      value: id,
      label: versionTag + betaText,
      id,
      name,
      beta,
      hasDriversSupport: supportsDrivers
    }
  })

const updateRadioProps = (images, region, type) =>
  images?.map(({ versions, ...rest }) => {
    const filteredVersions = filterByCompatibility(versions, region, type)
    const isDisabled = filteredVersions.length === 0
    const badge = isDisabled ? 'not compatible' : undefined

    return {
      ...rest,
      isDisabled,
      ...(badge && { badge }),
      versions: updateVersionsForSelect(filteredVersions, rest.family)
    }
  })

export const transformImages = ({ images, region, type }) => {
  const noData = !images || !region || !type
  if (noData) return undefined

  const filteredBaseImages = filterByBaseImages(images)
  const filteredCloudImages = filterByCloudImages(images)

  const groupedBaseImages = groupByFamily(filteredBaseImages, 'baseImages')
  const groupedCloudImages = groupByFamily(filteredCloudImages, 'cloudImages')

  const updatedBaseImages = updateRadioProps(groupedBaseImages, region, type)
  const updatedCloudImages = updateRadioProps(groupedCloudImages, region, type)

  return {
    baseImages: updatedBaseImages,
    cloudImages: updatedCloudImages
  }
}

// Catalogs
const updateImagesForSelect = (images) =>
  images?.map(({ id, name }) => ({
    value: id,
    label: name,
    id,
    name
  }))

export const transformCatalogs = (catalogs) =>
  catalogs
    ? catalogs.map(({ baseImageIds, images, name, ...rest }) => ({
        ...rest,
        name,
        images: updateImagesForSelect(images)
      }))
    : undefined

// Selected Images
const baseAndCloudImages = (images) =>
  images.reduce((acc, item) => {
    const hasImages = item.versions?.length > 0

    return {
      ...acc,
      ...(hasImages && { [item.family]: item.versions[0] })
    }
  }, {})

const catalogsImages = (catalogs) =>
  catalogs.reduce((acc, item) => {
    const hasImages = item.images?.length > 0

    return {
      ...acc,
      ...(hasImages && { [item.name]: item.images[0] })
    }
  }, {})

export const generateSelectedImages = ({
  transformedImages,
  transformedCatalogs
}) => {
  const noData = !transformedImages || !transformedCatalogs
  if (noData) return undefined

  const baseImages = baseAndCloudImages(transformedImages.baseImages)
  const cloudImages = baseAndCloudImages(transformedImages.cloudImages)
  const catalogs = catalogsImages(transformedCatalogs)

  return { baseImages, cloudImages, catalogs }
}

export const updateSelectedImage = ({
  selected,
  snapshots,
  hasSnapshotFromURL,
  snapshotFromURL
}) => {
  let id, name, tab, hasDriversSupport

  const baseImagesKeys = Object.keys(selected?.baseImages)
  const cloudImagesKeys = Object.keys(selected?.cloudImages)
  const firstBaseImage = selected?.baseImages[baseImagesKeys[0]]
  const firstCloudImage = selected?.cloudImages[cloudImagesKeys[0]]
  const firstSnapshot = snapshots[0]

  if (hasSnapshotFromURL) {
    id = snapshotFromURL.id
    name = snapshotFromURL.name
    tab = 'snapshots'
    hasDriversSupport = false
  } else if (firstBaseImage) {
    id = firstBaseImage.id
    name = firstBaseImage.name
    tab = 'baseImages'
    hasDriversSupport = firstBaseImage.hasDriversSupport
  } else if (firstCloudImage) {
    id = firstCloudImage.id
    name = firstCloudImage.name
    tab = 'cloudImages'
    hasDriversSupport = firstCloudImage.hasDriversSupport
  } else if (firstSnapshot) {
    id = firstSnapshot.id
    name = firstSnapshot.name
    tab = 'snapshots'
    hasDriversSupport = false
  } else {
    id = ''
    name = ''
    tab = 'baseImages'
    hasDriversSupport = false
  }

  return { id, name, tab, hasDriversSupport }
}
