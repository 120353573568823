import {
  Center,
  Container,
  Grid,
  GridItem,
  Heading,
  Link,
  Skeleton,
  Stack,
  Text
} from '@chakra-ui/react'
import { useEffect } from 'react'
import MetaTags from 'react-meta-tags'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import routes from '../../constants/routes'
import { sidebarWidth } from '../../constants/styles'
import { externalLinks } from '../../helpers/links'
import { getOnboardingLastRoute } from '../../helpers/onboarding'
import { LogoText } from '../shared/Icons'
import { useAccount, useAccountCheckIn } from '../shared/hooks/account'
import RedirectDefaultOrg from './layout/RedirectDefaultOrg'
import Alerts from './layout/alerts'
import Sidebar from './layout/sidebar'
import Topbar from './layout/topbar'

const Wrapper = ({ children }) => (
  <Container minW="1056px" maxW="1300px" mt="50px" px={7} pb="90px">
    {children}
  </Container>
)

const Loading = () => {
  return <Skeleton h="100vh" w={sidebarWidth} borderRadius={0} />
}

const Error = () => {
  return (
    <Grid templateColumns={`${sidebarWidth} 1fr`}>
      <GridItem h="100vh" w={sidebarWidth} bgColor="gray.100">
        <Center h="100px">
          <LogoText />
        </Center>
      </GridItem>
      <GridItem mt={24}>
        <Wrapper>
          <Stack spacing={4}>
            <Heading size="4xl">Account Error</Heading>
            <Text color="gray.500">
              There was a problem retrieving your account information.
              <br /> Please try refreshing the tab. If the issue persists,
              please{' '}
              <Link
                isExternal
                href={externalLinks.newTicket}
                colorScheme="offBlack"
              >
                contact support
              </Link>
              .
            </Text>
          </Stack>
        </Wrapper>
      </GridItem>
    </Grid>
  )
}

const Dashboard = ({ children }) => {
  const history = useHistory()
  const user = useSelector((state) => state.oidc.user)
  const { account, isLoadingAccount, isErrorAccount } = useAccount()
  const { data, isLoadingAccountCheckIn } = useAccountCheckIn()
  const isFirstLogin = data?.isFirstLogin

  useEffect(() => {
    if (account && !account?.onboardedAt) {
      const onboardingLastRoute = getOnboardingLastRoute(account?.accountId)
      history.push(onboardingLastRoute || routes.onboarding.setup)
    }
  }, [account])

  if (!user || isLoadingAccount || isLoadingAccountCheckIn) {
    return <Loading />
  }

  if (isErrorAccount) {
    return <Error />
  }

  return (
    <>
      <MetaTags>
        <meta name="viewport" content="width=1024" />
      </MetaTags>
      <Grid
        id={isFirstLogin ? 'gt-first-login' : null}
        templateColumns={`${sidebarWidth} 1fr`}
        templateRows="auto auto 1fr"
      >
        <GridItem rowSpan={3} colSpan={1}>
          <Sidebar />
        </GridItem>
        <GridItem colSpan={1}>
          <Alerts />
        </GridItem>
        <GridItem colSpan={1}>
          <Topbar />
        </GridItem>
        <GridItem colSpan={1}>
          <Wrapper>{children}</Wrapper>
        </GridItem>
      </Grid>
      <RedirectDefaultOrg />
    </>
  )
}

export default Dashboard
