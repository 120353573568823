import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text
} from '@chakra-ui/react'
import { useToast } from '../../../../shared/Toast'
import { toastMessages } from '../../../../../constants/toast-messages'
import EditCreditCard from '../methods/edit-credit-card'
import {
  useOrgCreditCard,
  useAccountCreditCard
} from '../../../../shared/hooks'
import { useParams } from 'react-router-dom'

const Error = () => (
  <Text size="sm">Something went wrong loading the credit card.</Text>
)

export const EditMethodModal = ({ card, disclosure }) => {
  const creditCardId = card?.id
  const toast = useToast()
  const { isOpen, onClose } = disclosure
  const { orgId } = useParams()
  const { creditCard, isLoadingCreditCard, isErrorCreditCard } = orgId
    ? useOrgCreditCard({
        orgId,
        creditCardId,
        shouldFetch: isOpen
      })
    : useAccountCreditCard({
        creditCardId,
        shouldFetch: isOpen
      })

  const onSuccess = () => {
    toast({
      status: 'success',
      message: toastMessages.creditCardUpdateSuccess
    })
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Heading size="xl" mb={8} textAlign="center">
            Edit payment method
          </Heading>
          {isErrorCreditCard ? (
            <Error />
          ) : (
            <EditCreditCard
              isDefault={card.isDefault}
              creditCard={creditCard}
              isLoading={isLoadingCreditCard}
              onSuccess={onSuccess}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
