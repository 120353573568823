import {
  Button,
  Flex,
  Spacer,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { Card } from '../../../../../shared/Cards'
import messages from '../../../../../../constants/messages'
import { LeaveModal } from '../modals'

const Leave = ({ project }) => {
  const leaveDisclosure = useDisclosure()

  return (
    <Card as={Flex}>
      <Stack spacing={1}>
        <Text size="xl">Leave Project</Text>
        <Text size="md" fontWeight="normal" color="gray.500">
          {messages.projectLeaveInfo}
        </Text>
      </Stack>
      <Spacer />
      <Button w={40} colorScheme="red" onClick={leaveDisclosure.onOpen}>
        Leave Project
      </Button>
      <LeaveModal disclosure={leaveDisclosure} project={project} />
    </Card>
  )
}

export default Leave
