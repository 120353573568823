import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import {
  filterRulesByDirection,
  mapRulesToFrontend,
  rulesDirections
} from '../../../../../../helpers/securityGroups'
import {
  useAccountSecurityGroup,
  useOrgSecurityGroup
} from '../../../../../shared/hooks/securityGroups'
import EditMode from './edit-mode'
import ViewMode from './view-mode'

const Configuration = ({ isEditable }) => {
  const { orgId, projectId, securityGroupId } = useParams()
  const { securityGroup } = orgId
    ? useOrgSecurityGroup({ orgId, projectId, securityGroupId })
    : useAccountSecurityGroup({ securityGroupId })

  const ingress = useMemo(
    () =>
      mapRulesToFrontend(
        filterRulesByDirection(
          securityGroup?.securityGroupRules,
          rulesDirections.ingress
        )
      ),
    [securityGroup]
  )
  const egress = useMemo(
    () =>
      mapRulesToFrontend(
        filterRulesByDirection(
          securityGroup?.securityGroupRules,
          rulesDirections.egress
        )
      ),
    [securityGroup]
  )
  const [isViewMode, setIsViewMode] = useState(true)

  const onClick = useCallback(() => {
    setIsViewMode(!isViewMode)
  })

  return isViewMode ? (
    <ViewMode
      isEditable={isEditable}
      onClick={onClick}
      rules={{ ingress, egress }}
    />
  ) : (
    <EditMode onClick={onClick} rules={{ ingress, egress }} />
  )
}

export default Configuration
