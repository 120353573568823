import routes from '../../../../../../constants/routes'
import { withOrgPathFallback } from '../../../../../../router'
import ResponsiveContainer from '../../../../../shared/Layout/ResponsiveContainer'
import { Step, Steps } from '../../../../../shared/Steps'

const Layout = ({ children }) => {
  return (
    <>
      <Steps>
        <Step
          path={withOrgPathFallback(
            routes.dashboard.organizations.create.setup
          )}
        >
          Set up *
        </Step>
        <Step
          path={withOrgPathFallback(
            routes.dashboard.organizations.create.details
          )}
        >
          Org details
        </Step>
        <Step
          path={withOrgPathFallback(
            routes.dashboard.organizations.create.payment
          )}
        >
          Payment method
        </Step>
        <Step
          path={withOrgPathFallback(
            routes.dashboard.organizations.create.billing
          )}
        >
          Billing details *
        </Step>
      </Steps>
      <ResponsiveContainer logo>{children}</ResponsiveContainer>
    </>
  )
}

export default Layout
