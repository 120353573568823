import { Tr, Td } from '@chakra-ui/react'
import { TdTruncated, TdTruncatedLines } from '../../../../shared/Table'
import { formatDateTime } from '../../../../../helpers/date'
import { formatCurrency } from '../../../../../helpers/currency'

const TableRow = ({ row }) => {
  const { id, productType, startedAt, endedAt, amount } = row

  return (
    <Tr>
      <TdTruncatedLines noOfLines={2}>{id}</TdTruncatedLines>
      <TdTruncated>{productType}</TdTruncated>
      <Td>{formatDateTime(startedAt)}</Td>
      <Td>{formatDateTime(endedAt)}</Td>
      <Td>{formatCurrency(amount)}</Td>
    </Tr>
  )
}

export default TableRow
