import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Textarea
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import * as yup from 'yup'
import { Project } from '../../../../../../api/projects'
import { toastMessages } from '../../../../../../constants/toast-messages'
import Asterisk from '../../../../../shared/Asterisk'
import { useToast } from '../../../../../shared/Toast'
import { useProject } from '../../../../../shared/hooks/project'

export const EditModal = ({ disclosure }) => {
  const { isOpen, onClose } = disclosure
  const { projectId, orgId } = useParams()
  const { project } = useProject({ orgId, projectId })
  const toast = useToast()
  const schema = yup.object().shape({
    name: yup.string().required('Project name is required'),
    description: yup.string()
  })

  const handleSubmit = useCallback(
    async ({ name, description }, { setSubmitting }) => {
      setSubmitting(true)
      try {
        const data = {
          name,
          description,
          is_default: project?.isDefault
        }
        await Project.updateById({ orgId, projectId, data })
        await mutate(`/organizations/${orgId}/projects/${projectId}`)
        toast({
          status: 'success',
          message: toastMessages.saved
        })
        onClose()
      } catch {
        toast({
          status: 'error',
          message: toastMessages.notSaved
        })
      } finally {
        setSubmitting(false)
      }
    },
    [project]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Heading size="xl" mb={8} textAlign="center">
            Edit Project
          </Heading>
          <Formik
            initialValues={{
              name: project?.name || '',
              description: project?.description || ''
            }}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, errors, touched, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <Stack spacing={8}>
                  <FormControl isInvalid={errors.name && touched.name}>
                    <FormLabel>
                      <Asterisk />
                      Name
                    </FormLabel>
                    <Field
                      as={Input}
                      name="name"
                      type="text"
                      autoFocus={true}
                    />
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.description}>
                    <FormLabel>Description</FormLabel>
                    <Field
                      as={Textarea}
                      name="description"
                      placeholder="Enter Project description"
                    />
                    <FormErrorMessage>{errors.description}</FormErrorMessage>
                  </FormControl>
                  <Stack spacing={4}>
                    <Button
                      type="submit"
                      isDisabled={isSubmitting}
                      isLoading={isSubmitting}
                    >
                      Save
                    </Button>
                    <Button
                      colorScheme="offBlack"
                      variant="outline"
                      isDisabled={isSubmitting}
                      onClick={onClose}
                      isFullWidth
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
