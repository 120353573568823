import { Grid, Heading, Skeleton, Stack } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { mutate } from 'swr'
import * as yup from 'yup'
import { AccountVolumes, OrgVolumes } from '../../../../api/volumes'
import { DEFAULT_REGION, DEFAULT_TYPE } from '../../../../constants/defaults'
import routes from '../../../../constants/routes'
import { toastMessages } from '../../../../constants/toast-messages'
import { MIN_SIZE, calcMaxSize } from '../../../../helpers/quota'
import { withOrgPathFallback } from '../../../../router'
import { InputField, RegionField } from '../../../shared/Fields'
import { SizeField } from '../../../shared/Fields/SizeField'
import { ReturnLink } from '../../../shared/Links'
import { useToast } from '../../../shared/Toast'
import { useConfigs } from '../../../shared/hooks/configs'
import { hasRegion } from '../../../shared/hooks/configs/volume'
import { useAccountQuota, useOrgQuota } from '../../../shared/hooks/quota'
import { apiErrorHandler } from '../../../shared/ApiErrorHandler'
import { useIsMounted } from '../../../shared/hooks/useIsMounted'
import { FileField, TypeField } from './fields'
import Summary from './summary'

const initialValues = {
  region: DEFAULT_REGION,
  type: DEFAULT_TYPE,
  name: '',
  description: '',
  size: MIN_SIZE
}

const VolumesCreate = () => {
  const { orgId, projectId } = useParams()
  const history = useHistory()
  const { volumes } = useConfigs({})
  const isMounted = useIsMounted()
  const toast = useToast()
  const { quota, isLoadingQuota } = orgId
    ? useOrgQuota({ orgId })
    : useAccountQuota()

  const validationSchema = yup.object().shape({
    name: yup.string().required(`Volume's name is required.`),
    description: yup.string(),
    size: yup
      .number()
      .required(`Volume's size is required.`)
      .typeError('Invalid value.')
      .test({
        name: 'limit',
        message: 'Size must be within the allowed limits.',
        test: (value, context) =>
          value <= calcMaxSize({ quota, type: context.parent.type }) &&
          value > 0
      })
  })

  const handleUpdateRegion = ({ region, setFieldValue, type }) => {
    const newType = hasRegion({ region, config: volumes, type })
      ? type
      : DEFAULT_TYPE
    setFieldValue('type', newType)
  }

  const handleSubmit = useCallback(
    async (
      { region, type, name, description, size },
      { setSubmitting, resetForm }
    ) => {
      setSubmitting(true)
      try {
        const data = {}
        if (region) {
          data.region = region
        }
        if (type) {
          data.type = type
        }
        if (name) {
          data.name = name
        }
        if (description) {
          data.description = description
        }
        if (size) {
          data.size = parseInt(size)
        }
        if (orgId) {
          await OrgVolumes.create({
            projectId,
            orgId,
            data
          })
          await mutate((key) =>
            key.startsWith(`/projects/${projectId}/volumes?`)
          )
        } else {
          await AccountVolumes.create({ data })
          await mutate((key) => key.startsWith(`/volumes?`))
        }
        toast({
          status: 'success',
          message: toastMessages.volumeCreateSuccess
        })
        resetForm()
        history.push(withOrgPathFallback(routes.dashboard.volumes.index))
      } catch (error) {
        const message = await apiErrorHandler(error)
        toast({
          status: 'error',
          message
        })
      } finally {
        if (isMounted()) {
          setSubmitting(false)
        }
      }
    },
    [orgId, projectId]
  )

  return (
    <Stack spacing={8}>
      <Stack spacing={0}>
        <ReturnLink to={withOrgPathFallback(routes.dashboard.volumes.index)}>
          Volumes
        </ReturnLink>
        <Heading as="h1" size="4xl">
          Create new Volume
        </Heading>
      </Stack>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialTouched={{ size: true }}
      >
        {({ setFieldValue, values }) => (
          <Form width="100%">
            <Grid templateColumns="2fr 1fr" gap={8}>
              <Stack spacing={16}>
                <RegionField
                  name="region"
                  onUpdate={(value) =>
                    handleUpdateRegion({
                      region: value,
                      setFieldValue,
                      type: values.type
                    })
                  }
                />
                <TypeField name="type" />
                <Stack spacing={8}>
                  <Heading size="xl">Configuration</Heading>
                  {isLoadingQuota ? (
                    <Skeleton h="175px" />
                  ) : (
                    <Grid templateColumns="1fr 1fr" rowGap={8} columnGap={5}>
                      <InputField
                        label="Name"
                        name="name"
                        placeholder="Enter volume name"
                        isRequired
                      />
                      <InputField
                        label="Description"
                        name="description"
                        placeholder="Enter volume description"
                      />
                      <SizeField />
                      <FileField />
                    </Grid>
                  )}
                </Stack>
              </Stack>
              <Summary />
            </Grid>
          </Form>
        )}
      </Formik>
    </Stack>
  )
}

export default VolumesCreate
