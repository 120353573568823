import { Grid } from '@chakra-ui/react'
import React from 'react'
import { useIsVip } from '../../../../helpers/vip'
import SupportError from '../../../Errors/SupportError'
import { useAccountBilling } from '../../../shared/hooks'
import { useAccountSettings } from '../../../shared/hooks/account'
import { useAccountQuota } from '../../../shared/hooks/quota'
import QuotaForm from './form'
import Description from './description'
import Loading from './loading'

const Account = () => {
  const { billing, isErrorBilling, isLoadingBilling } = useAccountBilling()
  const { quota, isErrorQuota, isLoadingQuota } = useAccountQuota()
  const { settings, isErrorSettings, isLoadingSettings } = useAccountSettings()
  const isVip = useIsVip(settings)
  const isError = isErrorBilling || isErrorQuota || isErrorSettings
  const isLoading = isLoadingBilling || isLoadingQuota || isLoadingSettings

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <SupportError>Something went wrong loading the quota.</SupportError>
  ) : (
    <Grid templateColumns="0.5fr 1.5fr" gap={5}>
      <Description />
      <QuotaForm quota={quota} billing={billing} isVip={isVip} />
    </Grid>
  )
}

export default Account
