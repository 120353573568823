import { Skeleton } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { Error } from '../../../../shared/Error'
import {
  useAccountBilling,
  useAccountFF,
  useAccountUnusedSubscriptions,
  useConfigs,
  useOrgBilling,
  useOrgFF,
  useOrgUnusedSubscriptions
} from '../../../../shared/hooks'
import {
  useAccountReservations,
  useOrgReservations
} from '../../../../shared/hooks/useReservations'
import Payments from './Payments'

const Billing = () => {
  const { orgId } = useParams()

  const { configs, isValidatingConfigs, isErrorConfigs } = useConfigs({})

  const {
    unusedSubscriptions,
    isValidating: isValidatingSubscriptions,
    isError: isErrorSubscriptions
  } = orgId
    ? useOrgUnusedSubscriptions({ orgId })
    : useAccountUnusedSubscriptions()

  const { billing, isValidatingBilling, isErrorBilling } = orgId
    ? useOrgBilling({ orgId })
    : useAccountBilling()

  const { reservations, isLoadingReservations, isErrorReservations } = orgId
    ? useOrgReservations({ orgId, page: 1 })
    : useAccountReservations({ page: 1 })

  const { featureFlag } = orgId ? useOrgFF({ orgId }) : useAccountFF({})
  const { enableQuotaReservation } = featureFlag || {}

  const isValidating =
    isValidatingConfigs ||
    isValidatingSubscriptions ||
    isValidatingBilling ||
    isLoadingReservations

  const isError =
    isErrorConfigs ||
    isErrorSubscriptions ||
    isErrorBilling ||
    isErrorReservations

  if (isValidating) {
    return <Skeleton h="180px" />
  }

  if (isError) {
    return <Error />
  }

  return (
    <Payments
      billing={billing}
      unusedSubscriptions={unusedSubscriptions}
      configs={configs}
      reservations={reservations}
      enableQuotaReservation={enableQuotaReservation}
    />
  )
}

export default Billing
