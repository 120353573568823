import { Button } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import routes from '../../../../constants/routes'
import { withOrgPathFallback } from '../../../../router'
import { Plus } from '../../../shared/Icons'
import { mutateHandler } from '../../InstancesNew/Create/utils'

const CreateButton = () => {
  const history = useHistory()

  const handleRedirect = () => {
    history.push(withOrgPathFallback(routes.dashboard.instances.create))
    mutateHandler()
  }

  return (
    <Button
      size="xs"
      onClick={handleRedirect}
      leftIcon={<Plus width="12px" height="12px" />}
    >
      Create new Instance
    </Button>
  )
}

export default CreateButton
