import React from 'react'
import {
  Avatar,
  Button,
  Divider,
  Grid,
  HStack,
  Spacer,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { useAccountMigration } from '../../../../../shared/hooks/account'
import accountLogo from '../../../../../shared/img/account-logo.svg'
import { Edit } from '../../../../../shared/Icons'
import { Card } from '../../../../../shared/Cards'
import CopyButton from '../../../../../shared/CopyButton'
import ProjectVersion from '../../../../../shared/ProjectVersion'
import { ProfileModal } from '../modals'

export const ProfileCard = ({ account }) => {
  const profileDisclosure = useDisclosure()
  const { migration } = useAccountMigration()

  return (
    <Card>
      <HStack mb={8}>
        <Avatar
          size="xl"
          name={account.name}
          src={account.picture || accountLogo}
          mr={5}
        />
        <Text size="3xl" data-hj-suppress>
          {account.name}
        </Text>
        <Spacer />
        <Button
          w={40}
          alignSelf="start"
          colorScheme="offBlack"
          variant="outline"
          leftIcon={<Edit />}
          onClick={profileDisclosure.onOpen}
        >
          Edit profile
        </Button>
        <ProfileModal disclosure={profileDisclosure} account={account} />
      </HStack>
      <Divider mb={8} />
      <Grid templateColumns="1fr 1fr" columnGap={16} rowGap={5}>
        <Stack spacing={0}>
          <Text>Email</Text>
          <Text color="gray.500">{account.email}</Text>
        </Stack>
        <Stack spacing={0}>
          <Text>Account ID</Text>
          <CopyButton color="gray.500" value={account.id} />
        </Stack>
        {migration && <ProjectVersion status={migration?.status} />}
      </Grid>
    </Card>
  )
}
