import { Heading, Text } from '@chakra-ui/react'
import { Card } from '../../../shared/Cards'

const Description = () => (
  <Card>
    <Heading size="xl" mb={4}>
      Quota request
    </Heading>
    <Text size="sm" color="gray.500" mb={4} textAlign="left">
      We enforce quotas to protect our current community of users from usage
      spikes that can lead into reduction in Instance availability.
    </Text>
    <Text size="sm" color="gray.500" textAlign="left">
      When applying for a quota you can expect the request to be handled within
      3 business days. After approving the request, an automated email will be
      sent to you.
    </Text>
  </Card>
)

export default Description
