import { Skeleton, Stack, Text, useToken } from '@chakra-ui/react'
import { useMemo } from 'react'
import { Chart as ReactChart } from 'react-charts'
import { formatDateMonth } from '../../../../../helpers/date'

const NoData = () => <Text color="gray.500">There is no data.</Text>

const Loading = () => <Skeleton h="140px" />

const Error = () => (
  <Text color="gray.500">Something went wrong loading the storage usage.</Text>
)

const Chart = ({ data, color, isLoading, isError }) => {
  const [defaultColor] = useToken('colors', [color])
  const isEmpty = data[0].data?.length === 0

  const primaryAxis = useMemo(
    () => ({
      getValue: (datum) => formatDateMonth(datum.startTime)
    }),
    []
  )

  const secondaryAxes = useMemo(
    () => [
      {
        getValue: (datum) => datum.aggregatedValue,
        hardMin: 0,
        elementType: 'bar'
      }
    ],
    []
  )

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <Error />
  ) : isEmpty ? (
    <NoData />
  ) : (
    <Stack spacing={1}>
      <Text size="xs" color="gray.500">
        GiB
      </Text>
      <div style={{ width: '100%', height: '360px' }}>
        <ReactChart
          options={{
            defaultColors: [defaultColor],
            data,
            primaryAxis,
            secondaryAxes
          }}
        />
      </div>
    </Stack>
  )
}

export default Chart
