import {
  Box,
  Divider,
  FormControl,
  FormErrorMessage,
  Heading,
  SimpleGrid,
  Skeleton,
  Stack
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { getBorderColor } from '../../../../../helpers/instances'
import { Error } from '../../../../shared/Error'
import {
  useAccountAvailability,
  useAccountInstanceInfo,
  useConfigs,
  useOrgAvailability,
  useOrgInstanceInfo,
  useProjectVersion
} from '../../../../shared/hooks'
import Info from './Info'
import Quota from './Quota'
import Slider from './Slider'

const Configuration = () => {
  const { values, setValues, errors } = useFormikContext()
  const isAvailabilityEmpty = Object.keys(values.availability).length === 0
  const isCapacityEmpty = Object.keys(values.capacity).length === 0
  const isTypeEmpty = Object.keys(values.type).length === 0
  const selected = values?.type?.configurations?.selected

  const availabilityError = errors?.availability
  const capacityError = errors?.capacity

  const borderColor = getBorderColor(availabilityError, capacityError)

  const { orgId, projectId } = useParams()
  const { isB2 } = useProjectVersion({ projectId, orgId })

  const { regions, isValidatingConfigs, isLoadingConfigs, isErrorConfigs } =
    useConfigs({})

  const { availability, isValidatingAvailability, isErrorAvailability } = orgId
    ? useOrgAvailability({ orgId, projectId, isB2, regions })
    : useAccountAvailability({ isB2, regions })

  const reservationId = values.billing.reservationId
  const { info, isValidatingInfo, isLoadingInfo, isErrorInfo } = orgId
    ? useOrgInstanceInfo({ orgId, projectId, reservationId })
    : useAccountInstanceInfo({ reservationId })

  const hasData = availability && info

  const isValidating =
    isValidatingConfigs || isValidatingAvailability || isValidatingInfo
  const isLoading = isLoadingConfigs || isLoadingInfo || isTypeEmpty
  const isError = isErrorConfigs || isErrorAvailability || isErrorInfo

  useEffect(() => {
    if (
      hasData &&
      isAvailabilityEmpty &&
      isCapacityEmpty &&
      !isValidating &&
      !isError
    ) {
      setValues((prev) => ({
        ...prev,
        capacity: info.capacity,
        availability
      }))
    }
  }, [isValidating, reservationId])

  return (
    <Stack spacing={8}>
      <Heading size="xl">Configuration</Heading>
      {isLoading ? (
        <Skeleton h="150px" />
      ) : isError ? (
        <Error />
      ) : (
        <FormControl isInvalid={capacityError || availabilityError}>
          <SimpleGrid columns={2} gap={3}>
            <Box border="1px" borderColor={borderColor} borderRadius="10px">
              <Slider />
              <Divider borderColor="gray.300" />
              <Quota />
            </Box>
            {selected && <Info info={selected} />}
          </SimpleGrid>
          {capacityError && (
            <FormErrorMessage>{capacityError}</FormErrorMessage>
          )}
          {!capacityError && availabilityError && (
            <FormErrorMessage>{availabilityError}</FormErrorMessage>
          )}
        </FormControl>
      )}
    </Stack>
  )
}

export default Configuration
