import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { externalLinks } from '../../../../helpers/links'
import { setIsAccountSuspended } from '../../../../slices/dashboard'
import { Alert } from '../../../shared/Alert'
import { useAccount } from '../../../shared/hooks/account'

const suspended =
  'Your account is suspended. Please contact support to reinstate.'

const banned = 'Your account is banned. Please contact support to reinstate.'

const AccountSuspended = () => {
  const dispatch = useDispatch()
  const [message, setMessage] = useState(null)
  const { account, isLoadingAccount, isErrorAccount } = useAccount()
  const isBlocked = account?.suspendedAt || account?.bannedAt

  if (isLoadingAccount) return null

  if (isErrorAccount) return null

  useEffect(() => {
    if (account?.suspendedAt || account?.bannedAt) {
      dispatch(setIsAccountSuspended(true))
      setMessage(account?.suspendedAt ? suspended : banned)
    }
  }, [account?.suspendedAt, account?.bannedAt])

  return isBlocked ? (
    <Alert message={message} link={externalLinks.newTicket} />
  ) : null
}

export default AccountSuspended
