import { Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { SkeletonTable } from '../../../../../shared/Skeletons'
import {
  INITIAL_PAGE,
  TableError,
  TablePagination
} from '../../../../../shared/Table'
import { useProjects } from '../../../../../shared/hooks/project'
import TableRow from './table-row'

const List = () => {
  const { orgId } = useParams()
  const [page, setPage] = useState(INITIAL_PAGE)
  const { projects, isError, isLoading } = useProjects({
    orgId,
    page
  })

  return (
    <>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th w="40%">Project</Th>
              <Th w="15%">Permission</Th>
              <Th w="15%">Created</Th>
              <Th w="15%">Last activity</Th>
              <Th w="15%" />
            </Tr>
          </Thead>
          {isLoading && <SkeletonTable rows={3} columns={6} />}
          {isError ? (
            <TableError>Something went wrong loading the projects.</TableError>
          ) : (
            <Tbody>
              {projects?.projects.map((project) => (
                <TableRow key={project.id} project={project} />
              ))}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      {!isError && (
        <TablePagination
          page={page}
          setPage={setPage}
          totalCount={projects?.totalCount}
        />
      )}
    </>
  )
}

export default List
