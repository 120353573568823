import { Box, Flex, Grid, Stack } from '@chakra-ui/react'
import { Link as ReactLink, useRouteMatch } from 'react-router-dom'
import { withOrgPathFallback } from '../../../../router'
import { Semicircle } from '../../../shared/Icons'
import ParamLink from '../../../shared/ParamLink'

const NavMenu = ({ label, path, isExact, icon, children }) => {
  const match = useRouteMatch({
    path: [...path, ...path.map((e) => withOrgPathFallback(e))],
    exact: isExact
  })
  return (
    <Flex direction="column">
      <Box pos="relative">
        {match && <Semicircle pos="absolute" top="6px" left="0" />}
        <ParamLink
          as={ReactLink}
          to={path[0]}
          ml={5}
          variant="menu"
          colorScheme="gray"
          color={match ? 'black' : 'gray.500'}
          sx={{
            '&:hover > div > svg': {
              color: 'black'
            },
            '& > div > svg': {
              color: match ? 'black' : 'gray.500'
            }
          }}
        >
          <Grid alignItems="center" templateColumns="32px 1fr">
            {icon} {label}
          </Grid>
        </ParamLink>
      </Box>
      {children && (
        <Stack ml="52px" mt={3.5} spacing={4}>
          {children}
        </Stack>
      )}
    </Flex>
  )
}

export default NavMenu
