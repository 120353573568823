import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react'
import { useRef, useState } from 'react'
import Form from './form'
import Token from './token'

export const CreateModal = ({ disclosure }) => {
  const { isOpen, onClose } = disclosure
  const focusRef = useRef()
  const [isTokenGenerated, setTokenGenerated] = useState(false)
  const [token, setToken] = useState(null)

  const handleClose = () => {
    onClose()
    setTokenGenerated(false)
    setToken(null)
  }

  const handleChangeLayout = (token) => {
    setTokenGenerated(true)
    setToken(token)
  }

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      onClose={handleClose}
      initialFocusRef={focusRef}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          {isTokenGenerated ? (
            <Token token={token} onClose={handleClose} />
          ) : (
            <Form
              focusRef={focusRef}
              onClose={handleClose}
              onChangeLayout={handleChangeLayout}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
