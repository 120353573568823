import { apiRequest, COMPUTE_ENDPOINT } from './index'
import { serialize } from '../helpers/serializers'

export class OrgCommunityInstances {
  static fetchAll = async ({ orgId, projectId }) => {
    const communityInstances = await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/community/valdi/instances?org=${orgId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(communityInstances)
  }

  static fetchConfigs = async ({ projectId, orgId }) => {
    const configs = await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/community/valdi/instance-configs?org=${orgId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(configs)
  }

  static deleteById = async ({ orgId, projectId, instanceId }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/community/valdi/instances/${instanceId}?org=${orgId}`,
      {
        method: 'DELETE'
      },
      true
    )

  static create = async ({ orgId, projectId, payload }) => {
    const communityInstance = await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/community/valdi/instances?org=${orgId}`,
      {
        method: 'POST',
        body: JSON.stringify(payload)
      },
      true
    )
    return serialize(communityInstance)
  }
}

export class AccountCommunityInstances {
  static fetchAll = async () => {
    const communityInstances = await apiRequest(
      `${COMPUTE_ENDPOINT}/community/valdi/instances`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(communityInstances)
  }

  static fetchConfigs = async () => {
    const configs = await apiRequest(
      `${COMPUTE_ENDPOINT}/community/valdi/instance-configs`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(configs)
  }

  static deleteById = async ({ instanceId }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/community/valdi/instances/${instanceId}`,
      {
        method: 'DELETE'
      },
      true
    )

  static create = async ({ payload }) => {
    const communityInstance = await apiRequest(
      `${COMPUTE_ENDPOINT}/community/valdi/instances`,
      {
        method: 'POST',
        body: JSON.stringify(payload)
      },
      true
    )
    return serialize(communityInstance)
  }
}
