import { errorCodes } from '../../constants/error-codes'

export const apiErrorHandler = async (error) => {
  let message = ''

  try {
    const response = await error.response.json()
    const code = response?.code
    message = errorCodes[code] ?? errorCodes.default
  } catch {
    message = errorCodes.default
  }

  return message
}
