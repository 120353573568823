import { useParams, Link as ReactLink } from 'react-router-dom'
import { Stack, Heading, Skeleton, Text, Link } from '@chakra-ui/react'
import { Error } from '../../../shared/Error'
import { TableField } from '../../../shared/Fields'
import {
  useOrgSecurityGroups,
  useAccountSecurityGroups
} from '../../../shared/hooks/securityGroups'
import { withOrgPathFallback } from '../../../../router'
import routes from '../../../../constants/routes'
import { StaticRegion } from '../../../shared/Region'
import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { buildCombinedRules } from '../../../../helpers/securityGroups'
import { Rules } from '../../../shared/Rules'

const columns = [
  { header: 'Name', field: 'name', width: '30%' },
  {
    header: 'Region',
    field: 'region',
    width: '25%',
    cell: (row) => <StaticRegion slug={row.region} />
  },
  { header: 'Description', field: 'description', width: '45%' }
]

const NoData = () => (
  <Text color="red.500">There are no Security Groups yet.</Text>
)

const ManageLink = () => (
  <Link
    as={ReactLink}
    to={withOrgPathFallback(routes.dashboard.securityGroups.index)}
    alignSelf="start"
  >
    Manage your Security Groups here.
  </Link>
)

const SecurityGroups = () => {
  const { values, setValues } = useFormikContext()
  const isEmpty = values.network.isEmpty
  const region = values.region

  const { orgId, projectId } = useParams()
  const { securityGroups, isValidating, isLoading, isError } =
    orgId && projectId
      ? useOrgSecurityGroups({ orgId, projectId, region, perPage: 1000 })
      : useAccountSecurityGroups({ region, perPage: 1000 })

  useEffect(() => {
    if (securityGroups && isEmpty && !isValidating && !isError) {
      const standard = securityGroups.securityGroups.filter(
        ({ isInternal }) => isInternal
      )

      setValues((prev) => ({
        ...prev,
        network: { securityGroups: standard, isEmpty: false }
      }))
    }
  }, [isValidating, isEmpty])

  const rules = buildCombinedRules(values.network.securityGroups)
  const noData = securityGroups?.securityGroups.length === 0

  return (
    <Stack spacing={8}>
      <Heading size="xl">Network</Heading>
      {isLoading || isEmpty ? (
        <Skeleton h="210px" />
      ) : isError ? (
        <Error />
      ) : (
        <>
          <Stack spacing={3}>
            <Text size="lg">Security Groups</Text>
            {noData ? (
              <NoData />
            ) : (
              <TableField
                name="network.securityGroups"
                columns={columns}
                rows={securityGroups?.securityGroups || []}
              />
            )}
            <ManageLink />
          </Stack>
          <Rules rules={rules} isCombined />
        </>
      )}
    </Stack>
  )
}

export default SecurityGroups
