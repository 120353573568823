import {
  Button,
  Flex,
  Spacer,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { Card } from '../../../../../shared/Cards'
import { OwnershipModal } from '../modals'

export const OwnershipCard = () => {
  const ownershipDisclosure = useDisclosure()

  return (
    <Card as={Flex}>
      <Stack flex={7}>
        <Text mb={2} size="xl">
          Transfer ownership
        </Text>
        <Text mb={5} size="sm" color="gray.500">
          All owner permissions will be transferred immediately. The billing
          details and payment method will stay the same.
        </Text>
      </Stack>
      <Spacer />
      <Button
        w={48}
        alignSelf="start"
        colorScheme="offBlack"
        variant="outline"
        onClick={ownershipDisclosure.onOpen}
      >
        Transfer ownership
      </Button>
      <OwnershipModal disclosure={ownershipDisclosure} />
    </Card>
  )
}
