import useSWR from 'swr'
import {
  AccountObjectStorage,
  OrgObjectStorage
} from '../../../api/objectStorage'
import { serialize } from '../../../helpers/serializers'

export function useOrgCheckObjectStorage({ orgId, projectId }) {
  const key = `/projects/${projectId}/api-access/check?org=${orgId}`
  const fetcher = () => OrgObjectStorage.check({ orgId, projectId })

  const { data, isLoading, error } = useSWR(key, fetcher)

  return {
    isEnabled: data?.access,
    isCheckLoading: isLoading,
    isCheckError: Boolean(error)
  }
}

export function useOrgBuckets({ orgId, projectId }) {
  const key = `/projects/${projectId}/buckets?org=${orgId}`
  const fetcher = () => OrgObjectStorage.fetchBuckets({ orgId, projectId })

  const { data, isLoading, error } = useSWR(key, fetcher)

  let buckets, pagination
  if (data) ({ buckets, ...pagination } = serialize(data))

  return {
    buckets,
    pagination,
    isLoadingBuckets: isLoading,
    isErrorBuckets: Boolean(error)
  }
}

export function useOrgBucket({ orgId, projectId, bucketName, region }) {
  const key =
    bucketName && region
      ? `/projects/${projectId}/${region}/buckets/${bucketName}?org=${orgId}`
      : null

  const fetcher = () =>
    OrgObjectStorage.fetchBucket({ orgId, projectId, bucketName, region })

  const { data, isLoading, error } = useSWR(key, fetcher)

  return {
    bucket: serialize(data),
    isLoadingBucket: isLoading,
    isErrorBucket: Boolean(error)
  }
}

export function useAccountCheckObjectStorage() {
  const key = '/api-access/check'
  const fetcher = () => AccountObjectStorage.check()

  const { data, isLoading, error } = useSWR(key, fetcher)

  return {
    isEnabled: data?.access,
    isCheckLoading: isLoading,
    isCheckError: Boolean(error)
  }
}

export function useAccountBuckets() {
  const key = '/buckets'
  const fetcher = () => AccountObjectStorage.fetchBuckets()

  const { data, isLoading, error } = useSWR(key, fetcher)

  let buckets, pagination
  if (data) ({ buckets, ...pagination } = serialize(data))

  return {
    buckets,
    pagination,
    isLoadingBuckets: isLoading,
    isErrorBuckets: Boolean(error)
  }
}

export function useAccountBucket({ bucketName, region }) {
  const key = bucketName && region ? `/${region}/buckets/${bucketName}` : null
  const fetcher = () => AccountObjectStorage.fetchBucket({ bucketName, region })

  const { data, isLoading, error } = useSWR(key, fetcher)

  return {
    bucket: serialize(data),
    isLoadingBucket: isLoading,
    isErrorBucket: Boolean(error)
  }
}
