import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import { formatDate } from '../../../../../helpers/date'
import { getBadge, statuses } from '../../../../../helpers/reservations'
import CopyButton from '../../../../shared/CopyButton'
import { Cancel, Dots, Pricing } from '../../../../shared/Icons'
import { StaticRegion } from '../../../../shared/Region'
import { useConfigs } from '../../../../shared/hooks/configs'
import { getInstanceTypeDescription } from '../../../../shared/hooks/configs/instance'
import { CancelModal } from '../modals'
import {
  OrgReservations,
  AccountReservations
} from '../../../../../api/reservations'
import { useToast } from '../../../../shared/Toast'
import { toastMessages } from '../../../../../constants/toast-messages'

const TableRow = ({ orgId, row, hasPermission }) => {
  const { id, flavorName, region, startedAt, endedAt, quota, status } = row
  const disclosure = useDisclosure()
  const toast = useToast()
  const { configs } = useConfigs({})
  const type = configs?.instanceTypes.find(
    (type) => type.flavorName === flavorName
  )
  const description = getInstanceTypeDescription({ type }) || flavorName
  const showCancel =
    status === statuses.pendingPayment || status === statuses.scheduled

  const payNow = async () => {
    try {
      const redirect = orgId
        ? await OrgReservations.payNow({ orgId, reservationId: id })
        : await AccountReservations.payNow({ reservationId: id })

      if (redirect.redirectUrl) {
        window.location.assign(redirect.redirectUrl)
      }
    } catch {
      toast({
        status: 'error',
        message: toastMessages.payNowError
      })
    }
  }

  return (
    <Tr>
      <Td>
        <CopyButton value={id} />
      </Td>
      <Td>{description}</Td>
      <Td>
        <StaticRegion slug={region} />
      </Td>
      <Td>{formatDate(startedAt)}</Td>
      <Td>{formatDate(endedAt)}</Td>
      <Td>{quota}</Td>
      <Td>{getBadge(status)}</Td>
      <Td>
        {hasPermission && showCancel && (
          <Menu variant="table" autoSelect={false}>
            <MenuButton variant="icon" as={IconButton} icon={<Dots />} />
            <MenuList>
              {status === statuses.pendingPayment && (
                <MenuItem onClick={payNow}>
                  <Pricing mr={2.5} />
                  Pay now
                </MenuItem>
              )}
              <MenuItem onClick={disclosure.onOpen}>
                <Cancel mr={2.5} />
                Cancel reservation
              </MenuItem>
              <CancelModal disclosure={disclosure} reservation={row} />
            </MenuList>
          </Menu>
        )}
      </Td>
    </Tr>
  )
}

export default TableRow
