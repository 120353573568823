import useSWR from 'swr'
import { OrgReservations, AccountReservations } from '../../../api/reservations'
import { refreshInterval } from '../../../constants/swr-options'
import { serialize } from '../../../helpers/serializers'
import { INITIAL_PAGE, PER_PAGE } from '../Table'

const swrOptions = {
  revalidateOnFocus: false
}

export const useOrgReservations = ({
  orgId,
  page = INITIAL_PAGE,
  perPage = PER_PAGE,
  isPolling = false
}) => {
  const key = `/reservations?org=${orgId}&page=${page}&per_page=${perPage}`
  const fetcher = () => OrgReservations.fetchAll({ orgId, page, perPage })

  const swrOptions = {
    refreshInterval: isPolling && refreshInterval
  }

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  let reservations, pagination
  if (data) ({ reservations, ...pagination } = serialize(data))

  return {
    reservations,
    pagination,
    isLoadingReservations: isLoading,
    isErrorReservations: Boolean(error)
  }
}

export const useOrgReservationsInfo = ({ orgId, region, type }) => {
  const regionArg = region ? `&region=${region}` : ''
  const typeArg = type ? `&flavor_name=${type}` : ''

  const key = `/reservations/info?org=${orgId}${regionArg}${typeArg}`
  const fetcher = () => OrgReservations.fetchInfo({ orgId })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  return {
    info: serialize(data?.reservationsInfo),
    isLoadingInfo: isLoading,
    isErrorInfo: Boolean(error)
  }
}

export const useAccountReservations = ({
  page = INITIAL_PAGE,
  perPage = PER_PAGE,
  isPolling = false
}) => {
  const key = `/reservations?page=${page}&per_page=${perPage}`
  const fetcher = () => AccountReservations.fetchAll({ page, perPage })

  const swrOptions = {
    refreshInterval: isPolling && refreshInterval
  }

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  let reservations, pagination
  if (data) ({ reservations, ...pagination } = serialize(data))

  return {
    reservations,
    pagination,
    isLoadingReservations: isLoading,
    isErrorReservations: Boolean(error)
  }
}

export const useAccountReservationsInfo = ({ region, type }) => {
  const regionArg = region ? `&region=${region}` : ''
  const typeArg = type ? `&flavor_name=${type}` : ''

  const key = `/reservations/info?${regionArg}${typeArg}`
  const fetcher = () => AccountReservations.fetchInfo({ region, type })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  return {
    info: serialize(data?.reservationsInfo),
    isLoadingInfo: isLoading,
    isErrorInfo: Boolean(error)
  }
}
