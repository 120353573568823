import { useParams } from 'react-router-dom'
import { Stack, Heading, Skeleton, Text } from '@chakra-ui/react'
import { Error } from '../../../shared/Error'
import { TableField } from '../../../shared/Fields'
import { useOrgVolumes, useAccountVolumes } from '../../../shared/hooks'
import { useFormikContext } from 'formik'
import { StaticRegion } from '../../../shared/Region'
import ManageVolumesLink from '../../../shared/ManageVolumesLink'

const columns = [
  { header: 'Name', field: 'name', width: '10%' },
  {
    header: 'Region',
    field: 'region',
    width: '25%',
    cell: (row) => <StaticRegion slug={row.region} />
  },
  {
    header: 'Type',
    field: 'type',
    width: '10%',
    cell: (row) => <>{row.type?.toUpperCase()}</>
  },
  {
    header: 'Size',
    field: 'size',
    width: '10%',
    cell: (row) => <>{row.size}GiB</>
  },
  { header: 'Description', field: 'description', width: '45%' }
]

const NoData = () => <Text color="gray.500">There are no Volumes yet.</Text>

const Volumes = () => {
  const { values } = useFormikContext()
  const region = values.region
  const { orgId, projectId } = useParams()
  const { volumes, isLoadingVolumes, isErrorVolumes } =
    orgId && projectId
      ? useOrgVolumes({ orgId, projectId, perPage: 1000, region })
      : useAccountVolumes({ perPage: 1000, region })

  const notInUseVolumes = volumes?.filter(
    (volume) => !volume.attachments?.length
  )
  const noData = notInUseVolumes?.length === 0

  return (
    <Stack spacing={8}>
      <Heading size="xl">Volumes</Heading>
      {isLoadingVolumes ? (
        <Skeleton h="60px" />
      ) : isErrorVolumes ? (
        <Error />
      ) : (
        <Stack spacing={3}>
          {noData ? (
            <NoData />
          ) : (
            <TableField
              name="volumes"
              columns={columns}
              rows={notInUseVolumes}
            />
          )}
          <ManageVolumesLink />
        </Stack>
      )}
    </Stack>
  )
}

export default Volumes
