export const transformVolumeTypes = (configurations) =>
  configurations?.regions.map((region) => ({
    slug: region.slug,
    types: configurations.volumeTypes.map((type) => ({
      slug: type.slug,
      isEnabled: type.enabled && type.regions.some((r) => r === region.slug),
      description: {
        name: `${type.slug.toUpperCase()} Block Storage`
      },
      regions: type.regions,
      configurations: {
        pricing: type.configurations[0].pricing
      }
    }))
  }))

export const hasRegion = ({ region, config, type }) =>
  config
    ?.find((item) => item.slug === region)
    .types.find((item) => item.slug === type)
    .regions.some((item) => item === region)
