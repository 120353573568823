import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import * as yup from 'yup'
import { AccountSecurity, OrgSecurity } from '../../../../../../api/security'
import { toastMessages } from '../../../../../../constants/toast-messages'
import { APITokenTypes } from '../../../../../../helpers/security'
import Asterisk from '../../../../../shared/Asterisk'
import { useToast } from '../../../../../shared/Toast'
import { useIsMounted } from '../../../../../shared/hooks/useIsMounted'

const initialValues = {
  type: APITokenTypes.compute,
  description: ''
}

const validationSchema = yup.object().shape({
  description: yup.string().required('Description is required')
})

const Form = ({ onChangeLayout, onClose, focusRef }) => {
  const { orgId, projectId } = useParams()
  const isMounted = useIsMounted()
  const toast = useToast()

  const onSubmit = async (
    { type, description },
    { setSubmitting, resetForm }
  ) => {
    const payload = { type, description }
    setSubmitting(true)
    try {
      let token = {}
      if (orgId) {
        token = await OrgSecurity.generateAPIToken({
          payload,
          orgId,
          projectId
        })
        await mutate((key) =>
          key.startsWith(
            `/organizations/${orgId}/projects/${projectId}/api-keys?`
          )
        )
      } else {
        token = await AccountSecurity.generateAPIToken({ payload })
        await mutate((key) => key.startsWith(`/api-keys?`))
      }
      toast({
        status: 'success',
        message: toastMessages.APITokenCreateSuccess
      })
      resetForm()
      onChangeLayout(token)
    } catch (error) {
      toast({
        status: 'error',
        message: toastMessages.APITokenCreateError
      })
    } finally {
      if (isMounted()) {
        setSubmitting(false)
      }
    }
  }

  const { values, handleChange, errors, handleSubmit, isSubmitting, touched } =
    useFormik({
      validationSchema,
      initialValues,
      onSubmit
    })

  return (
    <Flex direction="column" gap="8" as="form" onSubmit={handleSubmit}>
      <Heading size="xl" textAlign="center">
        Generate new API Token
      </Heading>
      <FormControl>
        <FormLabel>
          <Asterisk />
          Token type:
        </FormLabel>
        <RadioGroup defaultValue={values.type} name="type">
          <Stack>
            <Radio
              type="radio"
              value={APITokenTypes.compute}
              onChange={handleChange}
            >
              Compute resources
              <Text size="sm">Instances, Snapshots, Network or Volumes</Text>
            </Radio>
            <Radio
              type="radio"
              value={APITokenTypes.objectStorage}
              onChange={handleChange}
            >
              Object Storage
              <Text size="sm">
                For Object Storage use only; no compute resources are associated
                with this token
              </Text>
            </Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
      <FormControl isInvalid={errors.description && touched.description}>
        <FormLabel>
          <Asterisk />
          Token description
        </FormLabel>
        <Textarea
          ref={focusRef}
          name="description"
          value={values.description}
          onChange={handleChange}
          isDisabled={isSubmitting}
          placeholder="Enter token description"
          borderColor="gray.300"
        />
        <FormErrorMessage>{errors.description}</FormErrorMessage>
      </FormControl>
      <Stack spacing={4}>
        <Button type="submit" isLoading={isSubmitting}>
          Generate API Token
        </Button>
        <Button
          colorScheme="offBlack"
          variant="outline"
          isDisabled={isSubmitting}
          onClick={onClose}
        >
          Cancel
        </Button>
      </Stack>
    </Flex>
  )
}

export default Form
