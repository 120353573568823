import { Stack, Heading, Link, Spacer, Button } from '@chakra-ui/react'
import { Link as ReactLink, useParams } from 'react-router-dom'
import { useHasWriteProjectPermissions } from '../../shared/hooks/project'
import { externalLinks } from '../../../helpers/links'
import { withOrgPathFallback } from '../../../router'
import routes from '../../../constants/routes'
import { Plus } from '../../shared/Icons'
import Footer from '../../shared/Footer'
import List from './list'

const CommunityInstances = () => {
  const { orgId, projectId } = useParams()
  const hasWriteProjectPermissions = orgId
    ? useHasWriteProjectPermissions({
        projectId,
        orgId
      })
    : true

  return (
    <Stack spacing={8}>
      <Heading as="h1" size="4xl">
        Community Instances
      </Heading>
      <List hasPermission={hasWriteProjectPermissions} />
      <Footer>
        <Link href={externalLinks.attachInstances} isExternal>
          Learn more about Instances
        </Link>
        <Spacer />
        {hasWriteProjectPermissions && (
          <Button
            as={ReactLink}
            to={withOrgPathFallback(routes.dashboard.communityInstances.create)}
            leftIcon={<Plus />}
          >
            Create new Community Instance
          </Button>
        )}
      </Footer>
    </Stack>
  )
}

export default CommunityInstances
