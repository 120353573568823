const Tag = {
  baseStyle: {
    container: {
      fontWeight: 'normal',
      cursor: 'pointer'
    }
  },
  variants: {
    solid: ({ colorScheme }) => ({
      container: {
        borderRadius: '20px',
        bg: `${colorScheme}.100`,
        color: `${colorScheme}.700`,
        _hover: {
          bg: `${colorScheme}.200`
        },
        _disabled: {
          opacity: 0.4,
          cursor: 'not-allowed'
        }
      }
    })
  },
  defaultProps: {
    variant: 'solid',
    colorScheme: 'gray'
  }
}

export default Tag
