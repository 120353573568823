import {
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Link,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  Text
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import { Link as ReactLink, useParams } from 'react-router-dom'
import { mutate } from 'swr'
import * as yup from 'yup'
import { AccountInstances, OrgInstances } from '../../../../api/instances'
import routes from '../../../../constants/routes'
import { toastMessages } from '../../../../constants/toast-messages'
import { withOrgPathFallback } from '../../../../router'
import { WarningError } from '../../../Errors'
import { apiErrorHandler } from '../../../shared/ApiErrorHandler'
import Asterisk from '../../../shared/Asterisk'
import { Close } from '../../../shared/Icons'
import { useToast } from '../../../shared/Toast'
import { useOrgVolumes, useAccountVolumes } from '../../../shared/hooks'

const validationSchema = yup.object().shape({
  availableVolumes: yup.array(),
  selectedVolumes: yup.array().min(1, 'At least one Volume should be selected.')
})

export const AttachVolume = ({ instance, disclosure }) => {
  const { isOpen, onClose } = disclosure
  const { region, volumes: instanceVolumes } = instance
  const { orgId, projectId, instanceId } = useParams()
  const toast = useToast()

  const { volumes, isLoadingVolumes, isErrorVolumes } =
    orgId && projectId
      ? useOrgVolumes({
          orgId,
          projectId,
          page: 1,
          perPage: 1000,
          region,
          shouldFetch: isOpen
        })
      : useAccountVolumes({
          page: 1,
          perPage: 1000,
          region,
          shouldFetch: isOpen
        })

  const attachedIds = instanceVolumes?.map(({ id }) => id)
  const filteredVolumes = volumes?.filter(
    (volume) =>
      volume.attachments.length === 0 && !attachedIds.includes(volume.id)
  )

  const initialValues = {
    availableVolumes: filteredVolumes || [],
    selectedVolumes: []
  }

  const onSubmit = async ({ selectedVolumes }, { setSubmitting }) => {
    setSubmitting(true)
    try {
      // to avoid repeated volume id. (if volume already attached to instance, it will be removed from payload)
      // edge case when a volume is detaching
      const volumesList = Array.from(
        new Set([
          ...instanceVolumes.map((volume) => volume.id),
          ...selectedVolumes.map((volume) => volume.id)
        ])
      )

      if (orgId) {
        await OrgInstances.updateById({
          orgId,
          projectId,
          instanceId,
          payload: {
            volumes: volumesList
          }
        })
        await mutate(
          `/projects/${projectId}/instances/${instanceId}?org=${orgId}`
        )
      } else {
        await AccountInstances.updateById({
          instanceId,
          payload: {
            volumes: volumesList
          }
        })
        await mutate(`/instances/${instanceId}`)
      }
      toast({
        status: 'success',
        message: toastMessages.saved
      })
      onClose()
    } catch (error) {
      const message = await apiErrorHandler(error)
      toast({
        status: 'error',
        message
      })
    } finally {
      setSubmitting(false)
    }
  }

  const { values, setFieldValue, errors, handleSubmit, isSubmitting } =
    useFormik({
      validationSchema,
      initialValues,
      onSubmit,
      enableReinitialize: true
    })

  const noAvailableVolumes = values.availableVolumes?.length === 0
  const noSelectedVolumes = values.selectedVolumes?.length === 0

  const handleTransfer = (id, source, destination) => {
    const selected = values[source].find((volume) => volume.id === id)

    const newSources = values[source].filter((volume) => volume.id !== id)
    const newDestinations = [...values[destination], selected]

    setFieldValue(source, newSources)
    setFieldValue(destination, newDestinations)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap="8" as="form" onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <Heading size="xl" textAlign="center">
                Attach Volume
              </Heading>
            </Stack>
            {!noSelectedVolumes && (
              <List>
                {values.selectedVolumes.map(({ id, name }) => (
                  <ListItem
                    key={id}
                    py={3}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    borderBottomWidth="1px"
                    borderBottomColor="gray.100"
                  >
                    <Text size="md" fontWeight="normal" color="gray.500">
                      {name}
                    </Text>
                    <Close
                      color="gray.500"
                      cursor="pointer"
                      _hover={{ color: 'gray.600' }}
                      onClick={() =>
                        handleTransfer(
                          id,
                          'selectedVolumes',
                          'availableVolumes'
                        )
                      }
                    />
                  </ListItem>
                ))}
              </List>
            )}
            <FormControl isInvalid={errors.selectedVolumes}>
              <FormLabel>
                <Asterisk />
                Attach Volume to Instance
              </FormLabel>
              {isLoadingVolumes ? (
                <Center w="full" h={10}>
                  <Spinner size="sm" color="gray.500" />
                </Center>
              ) : isErrorVolumes ? (
                <Center h={10}>
                  <WarningError>Unable to load security groups</WarningError>
                </Center>
              ) : (
                <>
                  <Select
                    name="availableVolumes"
                    placeholder="Select"
                    isDisabled={noAvailableVolumes}
                    onChange={(e) =>
                      handleTransfer(
                        e.target.value,
                        'availableVolumes',
                        'selectedVolumes'
                      )
                    }
                  >
                    {values.availableVolumes?.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  {noAvailableVolumes && noSelectedVolumes && (
                    <FormHelperText>
                      There are no Volumes. Create Volume{' '}
                      <Link
                        colorScheme="offBlack"
                        as={ReactLink}
                        to={withOrgPathFallback(
                          routes.dashboard.volumes.create
                        )}
                      >
                        here
                      </Link>
                      .
                    </FormHelperText>
                  )}
                  <FormErrorMessage>{errors.selectedVolumes}</FormErrorMessage>
                </>
              )}
            </FormControl>
            <Stack spacing={4}>
              <Button
                type="submit"
                isDisabled={
                  (noAvailableVolumes && noSelectedVolumes) || isErrorVolumes
                }
                isLoading={isLoadingVolumes || isSubmitting}
              >
                Attach
              </Button>
              <Button
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isLoadingVolumes || isSubmitting}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Stack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
