import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Text,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import { formatDateTime } from '../../../../../helpers/date'
import { getTokenType } from '../../../../../helpers/security'
import CopyButton from '../../../../shared/CopyButton'
import { Bin, Dots } from '../../../../shared/Icons'
import { TdTruncatedLines } from '../../../../shared/Table'
import { DeleteModal } from '../modals'

const TableRow = ({ token, hasPermission }) => {
  const disclosure = useDisclosure()

  return (
    <Tr>
      <Td whiteSpace="normal">
        <CopyButton noOfLines={2} value={token.accessKey} />
      </Td>
      <Td>{getTokenType(token.keyType)}</Td>
      <TdTruncatedLines noOfLines={2}>
        {token.description || '-'}
      </TdTruncatedLines>
      <Td>
        <Text color="gray.500" lineHeight="24px">
          {token.createdBy.name}
        </Text>
        <Text color="gray.400">{token.createdBy.email}</Text>
      </Td>
      <Td>{formatDateTime(token.createdAt)}</Td>
      <Td>{formatDateTime(token.lastUsedAt) || '-'}</Td>
      <Td>
        {hasPermission && (
          <Menu variant="table" autoSelect={false}>
            <MenuButton variant="icon" as={IconButton} icon={<Dots />} />
            <MenuList>
              <MenuItem onClick={disclosure.onOpen}>
                <Bin mr={2.5} />
                Delete
              </MenuItem>
              <DeleteModal disclosure={disclosure} token={token} />
            </MenuList>
          </Menu>
        )}
      </Td>
    </Tr>
  )
}

export default TableRow
