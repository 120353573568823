import { Link } from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'

const NotificationLink = ({ event, title, onClose, data }) => {
  const { isExternal, getURL } = event
  if (isExternal) {
    return (
      <Link
        size="xs"
        href={getURL(data)}
        onClick={onClose}
        isExternal
        alignSelf="self-end"
      >
        {title}
      </Link>
    )
  } else {
    return (
      <Link
        size="xs"
        as={ReactLink}
        to={getURL(data)}
        onClick={onClose}
        alignSelf="self-end"
      >
        {title}
      </Link>
    )
  }
}

export default NotificationLink
