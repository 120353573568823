// size
export const RESERVATION_SIZE_MIN = 8
export const RESERVATION_SIZE_MAX = 64
export const RESERVATION_SIZE_STEP = 8

// dates
export const RESERVATION_DATES = {
  start: null,
  end: null,
  days: 0,
  isRangeValid: true,
  // from API
  quota: []
}

// prices
export const RESERVATION_PRICES = {
  priceBySize: 0,
  discount: 0,
  totalPrice: 0,
  // from API
  basePrice: 0,
  discountMap: {}
}
