import { Button, HStack, Heading, Stack } from '@chakra-ui/react'
import { Card } from '../../../../../shared/Cards'
import { Edit } from '../../../../../shared/Icons'
import { Rules } from '../../../../../shared/Rules'

const ViewMode = ({ isEditable, rules, onClick }) => (
  <Card as={Stack} spacing={8}>
    <HStack justify="space-between">
      <Heading size="xl">Configuration</Heading>
      {isEditable && (
        <Button
          colorScheme="offBlack"
          variant="outline"
          leftIcon={<Edit color="black" />}
          onClick={onClick}
        >
          Edit configuration
        </Button>
      )}
    </HStack>
    <Rules rules={rules} />
  </Card>
)

export default ViewMode
