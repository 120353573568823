import { Button } from '@chakra-ui/react'
import {
  Link as ReactLink,
  Router as ReactRouter,
  Redirect,
  Route,
  Switch,
  matchPath
} from 'react-router-dom'
import AuthCallback from './components/AuthCallback'
import Dashboard from './components/Dashboard'
import Home from './components/Dashboard/Home'
import InstanceCreate from './components/Dashboard/InstancesNew/Create'
import InstanceDetail from './components/Dashboard/InstancesNew/Detail'
import InstanceList from './components/Dashboard/InstancesNew/InstancesList'
import CommunityInstances from './components/Dashboard/community-instances'
import CommunityInstancesCreate from './components/Dashboard/community-instances/create'
import CommunityInstancesView from './components/Dashboard/community-instances/view'
import Profile from './components/Dashboard/profile'
import Quota from './components/Dashboard/profile/quota'
import Billing from './components/Dashboard/profile/billing'
import Reservations from './components/Dashboard/profile/reservations'
import ReservationsCreate from './components/Dashboard/profile/reservations/create'
import Organizations from './components/Dashboard/profile/organization/manage'
import OrganizationsCreate from './components/Dashboard/profile/organization/manage/create'
import AccountSettings from './components/Dashboard/profile/account/settings'
import OrganizationSettings from './components/Dashboard/profile/organization/settings'
import OrganizationMembers from './components/Dashboard/profile/organization/members'
import OrganizationProjects from './components/Dashboard/profile/organization/projects'
import OrganizationProjectsView from './components/Dashboard/profile/organization/projects/view'
import Filesystems from './components/Dashboard/filesystems'
import FilesystemsCreate from './components/Dashboard/filesystems/create'
import FilesystemsView from './components/Dashboard/filesystems/view'
import Network from './components/Dashboard/network'
import FloatingIPs from './components/Dashboard/network/floating-ips'
import SecurityGroups from './components/Dashboard/network/security-groups'
import SecurityGroupsCreate from './components/Dashboard/network/security-groups/create'
import SecurityGroupsView from './components/Dashboard/network/security-groups/view'
import ObjectStorage from './components/Dashboard/object-storage'
import Buckets from './components/Dashboard/object-storage/buckets'
import BucketsCreate from './components/Dashboard/object-storage/buckets/create'
import BucketsView from './components/Dashboard/object-storage/buckets/view'
import Security from './components/Dashboard/security'
import Snapshots from './components/Dashboard/snapshots'
import Volumes from './components/Dashboard/volumes'
import VolumesCreate from './components/Dashboard/volumes/create'
import VolumesView from './components/Dashboard/volumes/view'
import NotFound from './components/Errors/NotFound'
import LoginPage from './components/LoginPage'
import Onboarding from './components/Onboarding'
import PromptPage from './components/PromptPage'
import SignOut from './components/SignOut'
import SilentRenew from './components/SilentRenew'
import LinesBackground from './components/shared/Layout/LinesBackground'
import routes from './constants/routes'
import Console from './components/Console'
import SessionHandler from './containers/SessionHandler'

export const ORG_PATH = '/dashboard/org/:orgId'
export const PROJECT_PATH = '/dashboard/org/:orgId/project/:projectId'

export function getOrgParam() {
  const { pathname } = window.location
  const match = matchPath(pathname, {
    path: ORG_PATH
  })
  return match?.params ? match.params.orgId : undefined
}

export function getProjectParam() {
  const { pathname } = window.location
  const match = matchPath(pathname, {
    path: PROJECT_PATH
  })
  return match?.params ? match.params.projectId : undefined
}

export function withOrgPath(path, orgId, projectId) {
  const orgPath =
    orgId && projectId
      ? PROJECT_PATH.replace(':orgId', orgId).replace(':projectId', projectId)
      : PROJECT_PATH
  return path.replace('/dashboard', orgPath)
}

export function withOrgPathFallback(path) {
  const orgId = getOrgParam()
  const projectId = getProjectParam()
  return orgId ? withOrgPath(path, orgId, projectId) : path
}

function getPaths(path) {
  if (Array.isArray(path)) {
    const paths = [...path]
    path.forEach((e) => paths.push(withOrgPath(e)))
    return paths
  } else {
    const paths = [path]
    paths.push(withOrgPath(path))
    return paths
  }
}

const Router = ({ history }) => (
  <ReactRouter history={history}>
    <Switch>
      <Route
        exact
        path={routes.root}
        render={() => <Redirect to={routes.signin} />}
      />
      <Route exact path={routes.signin}>
        <LoginPage />
      </Route>
      <Route exact path={routes.authCallback}>
        <AuthCallback />
      </Route>
      <Route exact path={routes.silentRenew}>
        <SilentRenew />
      </Route>
      <Route exact path={routes.signout}>
        <SignOut />
      </Route>
      <Route exact path={routes.prompt}>
        <PromptPage />
      </Route>
      <Route path={routes.root}>
        <SessionHandler>
          <Switch>
            <Route path={routes.onboarding.index}>
              <Onboarding />
            </Route>
            <Route exact path={getPaths(routes.dashboard.serialConsole.index)}>
              <Console />
            </Route>
            <Route path={routes.dashboard.index}>
              <Dashboard>
                <Switch>
                  <Route exact path={getPaths(routes.dashboard.index)}>
                    <Home />
                  </Route>
                  <Route
                    exact
                    path={getPaths(routes.dashboard.instances.create)}
                  >
                    <InstanceCreate />
                  </Route>
                  <Route exact path={getPaths(routes.dashboard.instances.view)}>
                    <InstanceDetail />
                  </Route>
                  <Route
                    exact
                    path={getPaths(routes.dashboard.instances.index)}
                  >
                    <InstanceList />
                  </Route>
                  <Route
                    exact
                    path={getPaths(routes.dashboard.communityInstances.index)}
                  >
                    <CommunityInstances />
                  </Route>
                  <Route
                    exact
                    path={getPaths(routes.dashboard.communityInstances.create)}
                  >
                    <CommunityInstancesCreate />
                  </Route>
                  <Route
                    exact
                    path={getPaths(routes.dashboard.communityInstances.view)}
                  >
                    <CommunityInstancesView />
                  </Route>
                  <Route exact path={getPaths(routes.dashboard.volumes.index)}>
                    <Volumes />
                  </Route>
                  <Route exact path={getPaths(routes.dashboard.volumes.create)}>
                    <VolumesCreate />
                  </Route>
                  <Route exact path={getPaths(routes.dashboard.volumes.view)}>
                    <VolumesView />
                  </Route>
                  <Route
                    exact
                    path={getPaths(routes.dashboard.securityGroups.create)}
                  >
                    <SecurityGroupsCreate />
                  </Route>
                  <Route
                    exact
                    path={getPaths(routes.dashboard.securityGroups.view)}
                  >
                    <SecurityGroupsView />
                  </Route>
                  <Route
                    path={getPaths([
                      routes.dashboard.securityGroups.index,
                      routes.dashboard.floatingIPs.index
                    ])}
                  >
                    <Network>
                      <Switch>
                        <Route
                          exact
                          path={getPaths(routes.dashboard.securityGroups.index)}
                        >
                          <SecurityGroups />
                        </Route>
                        <Route
                          exact
                          path={getPaths(routes.dashboard.floatingIPs.index)}
                        >
                          <FloatingIPs />
                        </Route>
                      </Switch>
                    </Network>
                  </Route>
                  <Route exact path={getPaths(routes.dashboard.buckets.create)}>
                    <BucketsCreate />
                  </Route>
                  <Route exact path={getPaths(routes.dashboard.buckets.view)}>
                    <BucketsView />
                  </Route>
                  <Route path={getPaths([routes.dashboard.buckets.index])}>
                    <ObjectStorage>
                      <Switch>
                        <Route
                          exact
                          path={getPaths(routes.dashboard.buckets.index)}
                        >
                          <Buckets />
                        </Route>
                      </Switch>
                    </ObjectStorage>
                  </Route>
                  <Route
                    exact
                    path={getPaths(routes.dashboard.snapshots.index)}
                  >
                    <Snapshots />
                  </Route>
                  <Route
                    exact
                    path={getPaths(routes.dashboard.accountSettings)}
                  >
                    <AccountSettings />
                  </Route>
                  <Route exact path={getPaths(routes.dashboard.security)}>
                    <Security />
                  </Route>
                  <Route
                    exact
                    path={getPaths(routes.dashboard.filesystems.index)}
                  >
                    <Filesystems />
                  </Route>
                  <Route
                    exact
                    path={getPaths(routes.dashboard.filesystems.create)}
                  >
                    <FilesystemsCreate />
                  </Route>
                  <Route
                    exact
                    path={getPaths(routes.dashboard.filesystems.view)}
                  >
                    <FilesystemsView />
                  </Route>
                  <Route
                    path={getPaths([
                      routes.dashboard.projects,
                      routes.dashboard.quota,
                      routes.dashboard.billing,
                      routes.dashboard.settings,
                      routes.dashboard.members,
                      routes.dashboard.reservations.index,
                      routes.dashboard.reservations.create
                    ])}
                  >
                    <Profile>
                      <Switch>
                        <Route exact path={getPaths(routes.dashboard.quota)}>
                          <Quota />
                        </Route>
                        <Route
                          exact
                          path={getPaths(routes.dashboard.reservations.index)}
                        >
                          <Reservations />
                        </Route>
                        <Route
                          exact
                          path={getPaths(routes.dashboard.reservations.create)}
                        >
                          <ReservationsCreate />
                        </Route>
                        <Route exact path={getPaths(routes.dashboard.projects)}>
                          <OrganizationProjects />
                        </Route>
                        <Route exact path={getPaths(routes.dashboard.billing)}>
                          <Billing />
                        </Route>
                        <Switch>
                          <Route
                            exact
                            path={withOrgPath(routes.dashboard.settings)}
                          >
                            <OrganizationSettings />
                          </Route>
                          <Route
                            exact
                            path={withOrgPath(routes.dashboard.members)}
                          >
                            <OrganizationMembers />
                          </Route>
                          <Route>
                            <NotFound />
                          </Route>
                        </Switch>
                      </Switch>
                    </Profile>
                  </Route>
                  <Switch>
                    <Route exact path={routes.dashboard.organizations.index}>
                      <Organizations />
                    </Route>
                    <Route path={routes.dashboard.organizations.create.index}>
                      <OrganizationsCreate />
                    </Route>
                    <Route exact path={routes.dashboard.organizations.accept}>
                      <Home />
                    </Route>
                    <Route
                      exact
                      path={withOrgPath(routes.dashboard.organizations.index)}
                    >
                      <Organizations />
                    </Route>
                    <Route
                      path={withOrgPath(
                        routes.dashboard.organizations.create.index
                      )}
                    >
                      <OrganizationsCreate />
                    </Route>
                    <Route
                      exact
                      path={withOrgPath(routes.dashboard.organizations.accept)}
                    >
                      <Home />
                    </Route>
                    <Route
                      exact
                      path={withOrgPath(routes.dashboard.projectSettings)}
                    >
                      <OrganizationProjectsView />
                    </Route>
                  </Switch>
                </Switch>
              </Dashboard>
            </Route>
            <Route>
              <LinesBackground>
                <NotFound>
                  <Button
                    as={ReactLink}
                    to={routes.dashboard.index}
                    isFullWidth
                  >
                    Go to Dashboard
                  </Button>
                </NotFound>
              </LinesBackground>
            </Route>
          </Switch>
        </SessionHandler>
      </Route>
    </Switch>
  </ReactRouter>
)

export default Router
