export const status = {
  creating: 'creating',
  created: 'created',
  updating: 'updating', // legacy
  error: 'error', // legacy
  deleting: 'deleting'
}

export const label = 'Enter single port (22) or range (8000-9000).'

export const validationName = {
  limit: 'limit',
  duplicated: 'duplicated',
  port: 'port'
}

const ICMP = 'ICMP'
const UDP = 'UDP'
const TCP = 'TCP'
const ALL = 'All'

export const rulesProtocols = [TCP, UDP]

export const rulesDirections = {
  ingress: 'ingress',
  egress: 'egress'
}

export const rulesTypes = {
  SSH: 'SSH',
  ICMP,
  ALL_UDP: 'All UDP',
  ALL_TCP: 'All TCP',
  CUSTOM: 'Custom'
}

export const rulesPorts = {
  all: 'All ports',
  SSH: 22,
  ICMP: '-'
}

export const rulesActions = {
  create: 'create',
  delete: 'delete',
  updateType: 'update-type',
  updateProtocol: 'update-protocol',
  updatePort: 'update-port'
}

export const rulesData = [
  {
    type: rulesTypes.SSH,
    protocol: TCP,
    isPortEditable: false,
    port: rulesPorts.SSH
  },
  {
    type: rulesTypes.ICMP,
    protocol: ICMP,
    isPortEditable: false,
    port: rulesPorts.ICMP
  },
  {
    type: rulesTypes.ALL_TCP,
    protocol: TCP,
    isPortEditable: false,
    port: rulesPorts.all
  },
  {
    type: rulesTypes.ALL_UDP,
    protocol: UDP,
    isPortEditable: false,
    port: rulesPorts.all
  },
  {
    type: rulesTypes.CUSTOM,
    protocol: TCP,
    isPortEditable: true,
    port: rulesPorts.all
  }
]

export function updateRules({
  action,
  rules,
  ruleId,
  newRule = false,
  newProtocol = false,
  newPort = false
}) {
  let updatedRules = []

  if (action === rulesActions.create) {
    updatedRules = [...rules, newRule]
  }

  if (action === rulesActions.delete) {
    updatedRules = rules.filter((rule) => rule.id !== ruleId)
  }

  if (action === rulesActions.updateType) {
    updatedRules = rules.map((rule) =>
      rule.id === ruleId ? { ...newRule, direction: rule.direction } : rule
    )
  }

  if (action === rulesActions.updateProtocol) {
    updatedRules = rules.map((rule) =>
      rule.id === ruleId ? { ...rule, protocol: newProtocol } : rule
    )
  }

  if (action === rulesActions.updatePort) {
    updatedRules = rules.map((rule) =>
      rule.id === ruleId ? { ...rule, port: newPort } : rule
    )
  }

  return updatedRules
}

export function mapRulesPortToFrontend({ min, max }) {
  let value = ''

  if (min === max && min !== null) {
    value = min
  } else if (min === null && max === null) {
    value = rulesPorts.all
  } else if (min !== max) {
    value = `${min}-${max}`
  }

  return value
}

export function mapRulesPortToBackend(port) {
  let range = {}

  if (typeof port === 'number') {
    range = {
      min: port,
      max: port
    }
  }

  if (typeof port === 'string') {
    if (port === rulesPorts.all || port === rulesPorts.ICMP || port === '') {
      range = {
        min: null,
        max: null
      }
    } else {
      const rangeArray = port.trim().split('-')
      const min = Number(rangeArray[0])
      const max = rangeArray.length > 1 ? Number(rangeArray[1]) : min
      range = { min, max }
    }
  }

  return range
}

function mapRuleToFrontend(rule) {
  const protocol = rule.protocol.toUpperCase()
  const { id, portRangeMax: max, portRangeMin: min, direction } = rule
  let newRule = { id, protocol, direction }

  if (protocol === ALL.toUpperCase()) {
    newRule = {
      ...newRule,
      type: ALL,
      protocol: ALL,
      isPortEditable: false,
      port: rulesPorts.all
    }
  }

  if (protocol === ICMP) {
    newRule = {
      ...newRule,
      type: rulesTypes.ICMP,
      isPortEditable: false,
      port: rulesPorts.all
    }
  }

  if (protocol === UDP) {
    const isCustom = mapRulesPortToFrontend({ min, max }) !== rulesPorts.all

    newRule = {
      ...newRule,
      type: isCustom ? rulesTypes.CUSTOM : rulesTypes.ALL_UDP,
      isPortEditable: isCustom,
      port: mapRulesPortToFrontend({ min, max })
    }
  }

  if (protocol === TCP) {
    let type = ''
    const port = mapRulesPortToFrontend({ min, max })
    const isPortEditable = port !== rulesPorts.all && port !== rulesPorts.SSH

    if (port !== rulesPorts.all) {
      type = rulesTypes.CUSTOM
    }
    if (port === rulesPorts.all) {
      type = rulesTypes.ALL_TCP
    }
    if (port === rulesPorts.SSH) {
      type = rulesTypes.SSH
    }

    newRule = {
      ...newRule,
      type,
      isPortEditable,
      port
    }
  }

  return newRule
}

export function mapRulesToFrontend(rules) {
  return rules.map((rule) => mapRuleToFrontend(rule))
}

export function mapRuleToBackend(rule) {
  const range = mapRulesPortToBackend(rule.port)

  return {
    direction: rule.direction,
    protocol: rule.protocol.toLowerCase(),
    port_range_min: range.min,
    port_range_max: range.max
  }
}

export function mapRulesToBackend(rules) {
  return rules.map((rule) => mapRuleToBackend(rule))
}

export function validateDuplicatedRules(rules) {
  return rules
    .map((rule) => rule.protocol + rule.port)
    .some((rule, _, rules) => rules.indexOf(rule) !== rules.lastIndexOf(rule))
}

export function validatePort(port) {
  let isValid = true
  const onePort = /^\d+$/
  const twoPorts = /^\d+-\d+$/

  isValid =
    port === rulesPorts.all ||
    port === '' ||
    typeof port === 'undefined' ||
    onePort.test(port) ||
    twoPorts.test(port)

  if (twoPorts.test(port)) {
    const rangeArray = port.trim().split('-')
    isValid = Number(rangeArray[0]) < Number(rangeArray[1])
  }

  return isValid
}

export function validatePorts(rules) {
  let isValid = true

  isValid = rules
    .filter((rule) => rule.isPortEditable)
    .map((rule) => rule.port)
    .map((port) => validatePort(port))
    .find((isValid) => !isValid)

  if (typeof isValid === 'undefined') {
    isValid = true
  }

  return isValid
}

export const filterRulesByDirection = (rules, direction) =>
  rules.filter((rule) => rule.direction === direction)

export const filterDuplicateRules = (rules) => {
  const uniqueRule = {}
  const filteredRules = []
  const filterProperties = ['protocol', 'type', 'port']

  rules.forEach((obj) => {
    const key = filterProperties.map((property) => obj[property]).join('|')

    if (!uniqueRule[key]) {
      uniqueRule[key] = true
      filteredRules.push(obj)
    }
  })

  return filteredRules
}

export const buildCombinedRules = (securityGroups) => {
  if (!securityGroups)
    return {
      ingress: [],
      egress: []
    }

  const combinedRules = securityGroups?.reduce(
    (accumulator, current) => [...accumulator, ...current.securityGroupRules],
    []
  )

  if (combinedRules) {
    const ingress = mapRulesToFrontend(
      filterRulesByDirection(combinedRules, rulesDirections.ingress)
    )
    const egress = mapRulesToFrontend(
      filterRulesByDirection(combinedRules, rulesDirections.egress)
    )

    const uniqueIngress = filterDuplicateRules(ingress)
    const uniqueEgress = filterDuplicateRules(egress)

    return { ingress: uniqueIngress, egress: uniqueEgress }
  }
}
