const interactionParams = [
  'token',
  'provider',
  'utm_campaign',
  'utm_source',
  'refcode',
  'voucher',
  'email',
  'error'
]

export default interactionParams
