import { Heading, Stack } from '@chakra-ui/react'
import { useField } from 'formik'
import { TypeCards } from '../../../shared/RadioCard'

export const TypeField = ({ types, ...props }) => {
  const [field, , helpers] = useField(props)

  return (
    <Stack spacing={8}>
      <Heading size="xl">Select Type</Heading>
      <TypeCards
        {...field}
        {...props}
        activeType={field.value}
        types={types}
        onChange={(value) => helpers.setValue(value)}
      />
    </Stack>
  )
}
