import { Stack, Heading, Collapse, useDisclosure } from '@chakra-ui/react'
import { Card } from '../../../../shared/Cards'
import { ChevronDown } from '../../../../shared/Icons'
import { none, rotated } from '../../../../../constants/styles'
import ReservationsList from './table'

const Reservations = () => {
  const { isOpen, onToggle } = useDisclosure()

  return (
    <Card>
      <Heading size="xl" cursor="pointer" onClick={onToggle}>
        Reserved resources
        <ChevronDown boxSize={6} ml={3} transform={isOpen ? rotated : none} />
      </Heading>
      <Collapse in={isOpen} animateOpacity>
        <Stack spacing={8} mt={8}>
          <ReservationsList />
        </Stack>
      </Collapse>
    </Card>
  )
}

export default Reservations
