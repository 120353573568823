export const setOnboardingLastRoute = (accountId, route) => {
  window.localStorage.setItem(`onboarding-last-route-${accountId}`, route)
}

export const getOnboardingLastRoute = (accountId) => {
  return window.localStorage.getItem(`onboarding-last-route-${accountId}`)
}

export const removeOnboardingLastRoute = (accountId) => {
  window.localStorage.removeItem(`onboarding-last-route-${accountId}`)
}
