import { serialize } from '../helpers/serializers'
import { COMPUTE_ENDPOINT, apiRequest, projectScopedEndpoint } from './index'

export class Configs {
  static fetchProducts = async () => {
    const configs = await apiRequest(
      projectScopedEndpoint(COMPUTE_ENDPOINT, '/products-configs'),
      {
        method: 'GET'
      },
      true
    )

    return serialize(configs)
  }
}
