import { Button, Stack, Text } from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'
import routes from '../../../constants/routes'

const Error = () => (
  <Stack spacing={8}>
    <Text size="md" color="gray.500" fontWeight="normal">
      Seems like this Organization does not exist.
    </Text>
    <Button
      as={ReactLink}
      to={routes.dashboard.organizations.index}
      isFullWidth
      colorScheme="offBlack"
      variant="outline"
    >
      Back to Organizations
    </Button>
  </Stack>
)

export default Error
