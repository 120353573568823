import { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Stack,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th
} from '@chakra-ui/react'
import { SkeletonTable } from '../../../../../shared/Skeletons'
import {
  INITIAL_PAGE,
  TableCell,
  TableError,
  TablePagination
} from '../../../../../shared/Table'
import {
  useOrgBillingLongTerms,
  useAccountBillingLongTerms
} from '../../../../../shared/hooks'
import TableRow from './table-row'

const InstancesList = ({ hasPermission }) => {
  const { orgId } = useParams()
  const [page, setPage] = useState(INITIAL_PAGE)

  const { longTerms, pagination, isLoadingLongTerms, isErrorLongTerms } = orgId
    ? useOrgBillingLongTerms({ orgId, page })
    : useAccountBillingLongTerms({ page })
  const isTableEmpty = longTerms?.length === 0

  return (
    <Stack spacing={2}>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th w="15%">Name</Th>
              <Th w="25%">Product type</Th>
              <Th w="10%">Start</Th>
              <Th w="15%">Renewal/End</Th>
              <Th w="10%">Status</Th>
              <Th w="10%">Type</Th>
              <Th w="10%">Cost</Th>
              <Th w="5%" />
            </Tr>
          </Thead>
          {isLoadingLongTerms && (
            <SkeletonTable rows={5} columns={7} hasAction={false} />
          )}
          {isErrorLongTerms ? (
            <TableError>
              Something went wrong loading the Long-term Instances.
            </TableError>
          ) : (
            <Tbody>
              {isTableEmpty ? (
                <TableCell>There are no Long-term Instances yet.</TableCell>
              ) : (
                longTerms?.map((longTerm, index) => (
                  <TableRow
                    key={index}
                    row={longTerm}
                    orgId={orgId}
                    hasPermission={hasPermission}
                  />
                ))
              )}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      {!isErrorLongTerms && (
        <TablePagination
          page={page}
          setPage={setPage}
          perPage={pagination?.perPage}
          totalCount={pagination?.totalCount}
        />
      )}
    </Stack>
  )
}

export default InstancesList
