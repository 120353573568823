import { serialize } from '../helpers/serializers'
import { ACCOUNT_ENDPOINT, apiRequest } from './index'

export class OrgQuota {
  static fetchById = async ({ orgId }) => {
    const quota = await apiRequest(
      `${ACCOUNT_ENDPOINT}/quota?org=${orgId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(quota)
  }

  static submit = async ({ orgId, data }) =>
    await apiRequest(
      `${ACCOUNT_ENDPOINT}/quota?org=${orgId}`,
      {
        method: 'POST',
        body: JSON.stringify(data)
      },
      true
    )
}

export class AccountQuota {
  static fetch = async () => {
    const quota = await apiRequest(
      `${ACCOUNT_ENDPOINT}/quota`,
      {
        method: 'GET'
      },
      true
    )

    return serialize(quota)
  }

  static submit = async ({ data }) =>
    await apiRequest(
      `${ACCOUNT_ENDPOINT}/quota`,
      {
        method: 'POST',
        body: JSON.stringify(data)
      },
      true
    )
}
