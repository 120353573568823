import { accountApiRequest } from './index'

export const fetchNotifications = () => {
  return accountApiRequest(
    '/notifications',
    {
      method: 'GET'
    },
    true
  )
}

export const markNotificationAsRead = (id) => {
  return accountApiRequest(
    `/notifications/${id}`,
    {
      method: 'PATCH',
      body: JSON.stringify({
        read: true
      })
    },
    true
  )
}
