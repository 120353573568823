import { keyframes, usePrefersReducedMotion } from '@chakra-ui/react'
import { Spinner as SpinnerIcon } from './Icons'

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`

const Spinner = ({ ...props }) => {
  const prefersReducedMotion = usePrefersReducedMotion()

  const animation = prefersReducedMotion
    ? undefined
    : `${spin} infinite 1s linear`
  return <SpinnerIcon animation={animation} {...props} />
}

export default Spinner
