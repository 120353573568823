import React from 'react'
import 'react-app-polyfill/ie9'
import ReactDOM from 'react-dom'
import { processSilentRenew } from 'redux-oidc'
import App from './App'
import ErrorBoundary from './bugsnag'
import routes from './constants/routes'
import './index.scss'
import * as serviceWorker from './serviceWorker'

// Fix react hot loader iframe issue in local
if (process.env.REACT_APP_ENV === 'local') {
  const style = document.createElement('style')
  style.textContent = 'body > iframe[style*="2147483647"] { display: none; }'
  document.getElementsByTagName('head')[0].appendChild(style)
}

if (window.location.pathname !== routes.silentRenew) {
  ReactDOM.render(
    <ErrorBoundary>
      <App />
    </ErrorBoundary>,
    document.getElementById('root')
  )
} else {
  processSilentRenew()
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
