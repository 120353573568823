import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Stack
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import * as AccountApi from '../../../api/account'
import routes from '../../../constants/routes'
import ApiErrorMessage from '../../shared/ApiErrorMessage'
import { interests, roles } from '../../shared/Survey/constants'
import TagSelector from '../../shared/TagSelector'

const PersonalForm = () => {
  const history = useHistory()
  const [apiError, setApiError] = useState()
  const [usageType, setUsageType] = useState()
  const user = useSelector((state) => state.oidc.user)
  const schema = yup.object().shape({
    interests: yup.array(),
    interestsOther: yup.string(),
    role: yup.string().oneOf(Object.keys(roles), 'Role is invalid'),
    roleOther: yup.string()
  })

  const initialValues = {
    interests: [],
    interestsOther: '',
    role: '',
    roleOther: ''
  }

  useEffect(() => {
    if (user) {
      AccountApi.fetchAccountInfo().then((response) => {
        setUsageType(response.usage_type)
      })
    }
  }, [user, usageType])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async (
        { interests, interestsOther, role, roleOther },
        { setSubmitting }
      ) => {
        setSubmitting(true)
        try {
          const data = {}
          if (usageType) {
            data.usageType = usageType
          }
          if (interests?.length > 0) {
            data.interests = interests
          }
          if (interestsOther) {
            data.interestsOther = interestsOther
          }
          if (role) {
            data.role = role
          }
          if (roleOther) {
            data.roleOther = roleOther
          }
          await AccountApi.updateAccountInfo(data)
          history.push(routes.onboarding.payment)
        } catch (e) {
          try {
            const data = await e.response.json()
            setApiError(data.code)
          } catch (e) {
            setApiError(e)
          }
        } finally {
          setSubmitting(false)
        }
      }}
    >
      {({ values, errors, isSubmitting }) => (
        <Form style={{ width: '100%' }}>
          <Stack spacing={8}>
            <Stack spacing={4}>
              <FormControl isInvalid={errors.interests}>
                <FormLabel>
                  What are you interested in doing with Genesis Cloud?
                </FormLabel>
                <Field as={TagSelector} name="interests" data={interests} />
                <FormErrorMessage>{errors.interests}</FormErrorMessage>
              </FormControl>
              {values.interests.includes('OTHER') && (
                <FormControl>
                  <Field as={Input} name="interestsOther" />
                </FormControl>
              )}
            </Stack>
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>What best describes your role?</FormLabel>
                <Field as={Select} name="role" placeholder="Select">
                  {Object.entries(roles).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </Field>
              </FormControl>
              {values.role === 'OTHER' && (
                <FormControl>
                  <Field
                    as={Input}
                    name="roleOther"
                    placeholder="What else best describes your role?"
                  />
                </FormControl>
              )}
            </Stack>
            <ApiErrorMessage mb={2} code={apiError} />
            <Button
              isFullWidth
              type="submit"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Save and continue
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  )
}

export default PersonalForm
