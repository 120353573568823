import { FormControl, FormErrorMessage } from '@chakra-ui/react'
import { useField } from 'formik'
import { DataTable } from '../../shared/DataTable'

export const TableField = ({ name, columns, rows, ...props }) => {
  const [field, meta, helpers] = useField(name)

  return (
    <FormControl isInvalid={meta.error} {...props}>
      <DataTable
        columns={columns}
        rows={rows}
        selected={field.value}
        onSelect={helpers.setValue}
        borderColor={meta.error ? 'red.500' : 'gray.300'}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}
