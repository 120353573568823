import { useState } from 'react'
import {
  Flex,
  Stack,
  Heading,
  Text,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react'
import { mutate } from 'swr'
import { OrgBilling, AccountBilling } from '../../../../../api/billing'
import { toastMessages } from '../../../../../constants/toast-messages'
import { useToast } from '../../../../shared/Toast'
import { useIsMounted } from '../../../../shared/hooks/useIsMounted'

export const DeleteModal = ({ disclosure, data }) => {
  const { isOpen, onClose } = disclosure
  const { orgId, creditCardId, last4 } = data
  const [isSubmitting, setIsSubmitting] = useState(false)
  const isMounted = useIsMounted()
  const toast = useToast()

  const onSubmit = async () => {
    setIsSubmitting(true)

    try {
      if (orgId) {
        await OrgBilling.deleteCreditCard({ orgId, creditCardId })
        await mutate(`/billing/customer?org=${orgId}`)
      } else {
        await AccountBilling.deleteCreditCard({ creditCardId })
        await mutate('/billing/customer')
      }

      toast({
        status: 'success',
        message: toastMessages.saved
      })
      onClose()
    } catch {
      toast({
        status: 'error',
        message: toastMessages.notSaved
      })
    } finally {
      if (isMounted()) {
        setIsSubmitting(false)
      }
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap={8}>
            <Heading size="xl" textAlign="center">
              Are you sure you want to delete this payment method?
            </Heading>
            <Text
              size="md"
              textAlign="center"
              color="red.500"
              fontWeight="normal"
            >
              This action will permanently delete the Credit Card xxxx-{last4}.
            </Text>
            <Stack spacing={4}>
              <Button
                isLoading={isSubmitting}
                onClick={onSubmit}
                colorScheme="red"
              >
                Delete card
              </Button>
              <Button
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isSubmitting}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Stack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
