import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { ChevronDown } from './Icons'
import { none, rotated } from '../../constants/styles'

const RulesTable = ({ rules }) => (
  <TableContainer>
    <Table>
      <Thead>
        <Tr>
          <Th w="30%">Type</Th>
          <Th w="30%">Protocol</Th>
          <Th w="30%">Ports</Th>
        </Tr>
      </Thead>
      <Tbody>
        {rules.map((rule) => (
          <Tr key={rule.id}>
            <Td>{rule.type}</Td>
            <Td>{rule.protocol}</Td>
            <Td>{rule.port}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  </TableContainer>
)

export const Rules = ({ rules, isCombined = false }) => (
  <Accordion allowMultiple>
    <AccordionItem>
      {({ isExpanded }) => (
        <>
          <AccordionButton>
            <ChevronDown mr={2} transform={isExpanded ? rotated : none} />
            <Text fontWeight="semibold">
              {isCombined && 'Combined '}Inbound Rules
            </Text>
          </AccordionButton>
          <AccordionPanel my={4}>
            <RulesTable rules={rules.ingress} />
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
    <AccordionItem>
      {({ isExpanded }) => (
        <>
          <AccordionButton>
            <ChevronDown mr={2} transform={isExpanded ? rotated : none} />
            <Text fontWeight="semibold">
              {isCombined && 'Combined '}Outbound Rules
            </Text>
          </AccordionButton>
          <AccordionPanel my={4}>
            <RulesTable rules={rules.egress} />
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  </Accordion>
)
