import { Heading, Stack, Text, VStack } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useSWR from 'swr'
import { Organization } from '../../../../../../api/organizations'
import routes from '../../../../../../constants/routes'
import { OrganizationIdStorage } from '../../../../../shared/storage'
import Business from '../../../../../shared/Survey/Business'
import Institution from '../../../../../shared/Survey/Institution'
import PrivateUse from '../../../../../shared/Survey/PrivateUse'
import { UsageTypeEnum } from '../../../../../shared/usageType'
import Layout from './Layout'

const Details = () => {
  const id = OrganizationIdStorage.get()
  const { data: organization, error } = useSWR(
    id && `/organizations/${id}`,
    () => Organization.fetchById(id)
  )
  const history = useHistory()

  useEffect(() => {
    if (!id) {
      history.push(routes.dashboard.organizations.create.index)
    }
  }, [id])

  if (!organization && !error) {
    return null
  }

  return (
    <Layout>
      <VStack spacing={4} w="100%">
        <Heading as="h1" size="3xl">
          Organization details
        </Heading>
        <Stack spacing={8}>
          <Text
            color="gray.500"
            size="md"
            fontWeight="normal"
            textAlign="center"
          >
            Share your interests with us, and we&apos;ll assist you in reaching
            your goals.
          </Text>
          {organization.usageType === UsageTypeEnum.BUSINESS && (
            <Business id={organization.id} />
          )}
          {organization.usageType === UsageTypeEnum.INSTITUTION && (
            <Institution id={organization.id} />
          )}
          {organization.usageType === UsageTypeEnum.PRIVATE_USE && (
            <PrivateUse id={organization.id} />
          )}
        </Stack>
      </VStack>
    </Layout>
  )
}

export default Details
