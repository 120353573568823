import { Flex, Heading, HStack, Stack, Text } from '@chakra-ui/react'
import { DefaultBadge, LegacyBadge } from '../../../../../shared/Badge'
import { Card } from '../../../../../shared/Cards'
import Members from './members'
import Leave from './leave'

const NoAdmin = ({ project }) => (
  <Stack spacing={8}>
    <Heading size="4xl">Project settings</Heading>
    <Card as={Flex}>
      <Stack spacing={1}>
        <HStack>
          <Text size="3xl" noOfLines={1} maxWidth="600px" data-hj-suppress>
            {project?.name}
          </Text>
          {project?.isDefault && <DefaultBadge />}
          {project?.isLegacy && <LegacyBadge />}
        </HStack>
        <Text
          size="md"
          fontWeight="normal"
          color="gray.500"
          noOfLines={2}
          maxWidth="700px"
        >
          {project?.description}
        </Text>
      </Stack>
    </Card>
    <Members />
    {!project?.isDefault && <Leave project={project} />}
  </Stack>
)

export default NoAdmin
