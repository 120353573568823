import {
  Table as ChakraTable,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { Card } from '../../../shared/Cards'

const Table = ({ quota }) => (
  <Card as={Stack} spacing={8}>
    <TableContainer>
      <ChakraTable>
        <Thead>
          <Tr>
            <Th>Quota</Th>
            <Th isNumeric>Profile usage </Th>
            <Th isNumeric>Current quota</Th>
            <Th isNumeric>Requested quota</Th>
          </Tr>
        </Thead>
        <Tbody>
          {quota.quota.map((item, index) => {
            return (
              <Tr key={index}>
                <Td>{item.name}</Td>
                <Td isNumeric>{item.count}</Td>
                <Td isNumeric>
                  {item.quota} {item.unit || ''}
                </Td>
                <Td isNumeric>
                  <NumberInput defaultValue={item.quota} isDisabled>
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </ChakraTable>
    </TableContainer>
  </Card>
)

export default Table
