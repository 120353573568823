export const DEFAULT_VAT_TYPE = 'eu_vat-Germany'
export const VAT_TYPES = [
  {
    country: 'Andorra',
    type: 'ad_nrt',
    description: 'Andorran NRT number ',
    placeholder: 'A-123456-Z'
  },
  {
    country: 'Argentina',
    type: 'ar_cuit',
    description: 'Argentinian tax ID number',
    placeholder: '12-3456789-01'
  },
  {
    country: 'Australia',
    type: 'au_abn',
    description: 'Australian Business Number (AU ABN)',
    placeholder: '12345678912'
  },
  {
    country: 'Australia',
    type: 'au_arn',
    description: 'Australian Taxation Office Reference Number',
    placeholder: '123456789123'
  },
  {
    country: 'Europe',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'VAT number'
  },
  {
    country: 'Austria',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'ATU12345678'
  },
  {
    country: 'Belgium',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'BE0123456789'
  },
  {
    country: 'Bolivia',
    type: 'bo_tin',
    description: 'Bolivian tax ID',
    placeholder: '123456789'
  },
  {
    country: 'Brazil',
    type: 'br_cnpj',
    description: 'Brazilian CNPJ number',
    placeholder: '01.234.456/5432-10'
  },
  {
    country: 'Brazil',
    type: 'br_cpf',
    description: 'Brazilian CPF number',
    placeholder: '123.456.789-87'
  },
  {
    country: 'Bulgaria',
    type: 'bg_uic',
    description: 'Bulgaria Unified Identification Code',
    placeholder: '123456789'
  },
  {
    country: 'Bulgaria',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'BG0123456789'
  },
  {
    country: 'Canada',
    type: 'ca_bn',
    description: 'Canadian BN',
    placeholder: '123456789'
  },
  {
    country: 'Canada',
    type: 'ca_gst_hst',
    description: 'Canadian GST/HST number',
    placeholder: '123456789RT0002'
  },
  {
    country: 'Canada',
    type: 'ca_pst_bc',
    description: 'Canadian PST number (British Columbia)',
    placeholder: 'PST-1234-5678'
  },
  {
    country: 'Canada',
    type: 'ca_pst_mb',
    description: 'Canadian PST number (Manitoba)',
    placeholder: '123456-7'
  },
  {
    country: 'Canada',
    type: 'ca_pst_sk',
    description: 'Canadian PST number (Saskatchewan)',
    placeholder: '1234567'
  },
  {
    country: 'Canada',
    type: 'ca_qst',
    description: 'Canadian QST number (Québec)',
    placeholder: '1234567890TQ1234'
  },
  {
    country: 'Chile',
    type: 'cl_tin',
    description: 'Chilean TIN',
    placeholder: '12.345.678-K'
  },
  {
    country: 'China',
    type: 'cn_tin',
    description: 'Chinese tax ID',
    placeholder: '123456789012345678'
  },
  {
    country: 'Colombia',
    type: 'co_nit',
    description: 'Colombian NIT number',
    placeholder: '123.456.789-0'
  },
  {
    country: 'Costa Rica',
    type: 'cr_tin',
    description: 'Costa Rican tax ID',
    placeholder: '1-234-567890'
  },
  {
    country: 'Croatia',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'HR12345678912'
  },
  {
    country: 'Cyprus',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'CY12345678Z'
  },
  {
    country: 'Czech Republic',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'CZ1234567890'
  },
  {
    country: 'Denmark',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'DK12345678'
  },
  {
    country: 'Dominican Republic',
    type: 'do_rcn',
    description: 'Dominican RCN number',
    placeholder: '123-4567890-1'
  },
  {
    country: 'Ecuador',
    type: 'ec_ruc',
    description: 'Ecuadorian RUC number',
    placeholder: '1234567890001'
  },
  {
    country: 'Egypt',
    type: 'eg_tin',
    description: 'Egyptian Tax Identification Number',
    placeholder: '123456789'
  },
  {
    country: 'El Salvador',
    type: 'sv_nit',
    description: 'El Salvadorian NIT number',
    placeholder: '1234-567890-123-4'
  },
  {
    country: 'Estonia',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'EE123456789'
  },
  {
    country: 'EU',
    type: 'eu_oss_vat',
    description:
      'European One Stop Shop VAT number for non-Union scheme EU123456789'
  },
  {
    country: 'Finland',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'FI12345678'
  },
  {
    country: 'France',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'FRAB123456789'
  },
  {
    country: 'Georgia',
    type: 'ge_vat',
    description: 'Georgian VAT',
    placeholder: '123456789'
  },
  {
    country: 'Germany',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'DE123456789'
  },
  {
    country: 'Greece',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'EL123456789'
  },
  {
    country: 'Hong Kong',
    type: 'hk_br',
    description: 'Hong Kong BR number',
    placeholder: '12345678'
  },
  {
    country: 'Hungary',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'HU12345678'
  },
  {
    country: 'Hungary',
    type: 'hu_tin',
    description: 'Hungary tax number (adószám)',
    placeholder: '12345678-1-23'
  },
  {
    country: 'Iceland',
    type: 'is_vat',
    description: 'Icelandic VAT',
    placeholder: '123456'
  },
  {
    country: 'India',
    type: 'in_gst',
    description: 'Indian GST number',
    placeholder: '12ABCDE3456FGZH'
  },
  {
    country: 'Indonesia',
    type: 'id_npwp',
    description: 'Indonesian NPWP number',
    placeholder: '12.345.678.9-012.345'
  },
  {
    country: 'Ireland',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'IE1234567AB'
  },
  {
    country: 'Israel',
    type: 'il_vat',
    description: 'Israel VAT',
    placeholder: '000012345'
  },
  {
    country: 'Italy',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'IT12345678912'
  },
  {
    country: 'Japan',
    type: 'jp_cn',
    description: 'Japanese Corporate Number (*Hōjin Bangō*)',
    placeholder: '1234567891234'
  },
  {
    country: 'Japan',
    type: 'jp_rn',
    description:
      "Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)",
    placeholder: '12345'
  },
  {
    country: 'Japan',
    type: 'jp_trn',
    description: 'Japanese Tax Registration Number (*Tōroku Bangō*)',
    placeholder: 'T1234567891234'
  },
  {
    country: 'Kenya',
    type: 'ke_pin',
    description: 'Kenya Revenue Authority Personal Identification Number',
    placeholder: 'P000111111A'
  },
  {
    country: 'Latvia',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'LV12345678912'
  },
  {
    country: 'Liechtenstein',
    type: 'li_uid',
    description: 'Liechtensteinian UID number',
    placeholder: 'CHE123456789'
  },
  {
    country: 'Lithuania',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'LT123456789123'
  },
  {
    country: 'Luxembourg',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'LU12345678'
  },
  {
    country: 'Malaysia',
    type: 'my_frp',
    description: 'Malaysian FRP number',
    placeholder: '12345678'
  },
  {
    country: 'Malaysia',
    type: 'my_itn',
    description: 'Malaysian ITN C',
    placeholder: '1234567890'
  },
  {
    country: 'Malaysia',
    type: 'my_sst',
    description: 'Malaysian SST number',
    placeholder: 'A12-3456-78912345'
  },
  {
    country: 'Malta',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'MT12345678'
  },
  {
    country: 'Mexico',
    type: 'mx_rfc',
    description: 'Mexican RFC number',
    placeholder: 'ABC010203AB9'
  },
  {
    country: 'Netherlands',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'NL123456789B12'
  },
  {
    country: 'New Zealand',
    type: 'nz_gst',
    description: 'New Zealand GST number',
    placeholder: '123456789'
  },
  {
    country: 'Norway',
    type: 'no_vat',
    description: 'Norwegian VAT number',
    placeholder: '123456789MVA'
  },
  {
    country: 'Norway',
    type: 'no_voec',
    description: 'Norwegian VAT on e-commerce number',
    placeholder: '1234567'
  },
  {
    country: 'Peru',
    type: 'pe_ruc',
    description: 'Peruvian RUC number',
    placeholder: '12345678901'
  },
  {
    country: 'Philippines',
    type: 'ph_tin',
    description: 'Philippines Tax Identification Number',
    placeholder: '123456789012'
  },
  {
    country: 'Poland',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'PL1234567890'
  },
  {
    country: 'Portugal',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'PT123456789'
  },
  {
    country: 'Romania',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'RO1234567891'
  },
  {
    country: 'Romania',
    type: 'ro_tin',
    description: 'Romanian tax ID number',
    placeholder: '1234567890123'
  },
  { country: 'Russia', type: 'ru_inn', description: 'Russian INN 1234567891' },
  { country: 'Russia', type: 'ru_kpp', description: 'Russian KPP 123456789' },
  {
    country: 'Saudi',
    type: 'Arabia',
    description: 'sa_vat Saudi Arabia VAT 123456789012345'
  },
  {
    country: 'Serbia',
    type: 'rs_pib',
    description: 'Serbian PIB number',
    placeholder: '123456789'
  },
  {
    country: 'Singapore',
    type: 'sg_gst',
    description: 'Singaporean GST M12345678X'
  },
  {
    country: 'Singapore',
    type: 'sg_uen',
    description: 'Singaporean UEN 123456789F'
  },
  {
    country: 'Slovakia',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'SK1234567891'
  },
  {
    country: 'Slovenia',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'SI12345678'
  },
  {
    country: 'Slovenia',
    type: 'si_tin',
    description: 'Slovenia tax number (davčna številka)',
    placeholder: '12345678'
  },
  {
    country: 'South Africa',
    type: 'za_vat',
    description: 'South African VAT number',
    placeholder: '4123456789'
  },
  {
    country: 'South Korea',
    type: 'kr_brn',
    description: 'Korean BRN',
    placeholder: '123-45-67890'
  },
  {
    country: 'Spain',
    type: 'es_cif',
    description: 'Spanish NIF number (previously Spanish CIF number)',
    placeholder: 'A12345678'
  },
  {
    country: 'Spain',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'ESA1234567Z'
  },
  {
    country: 'Sweden',
    type: 'eu_vat',
    description: 'European VAT number',
    placeholder: 'SE123456789123'
  },
  {
    country: 'Switzerland',
    type: 'ch_vat',
    description: 'Switzerland VAT number',
    placeholder: 'CHE-123.456.789 MWST'
  },
  {
    country: 'Taiwan',
    type: 'tw_vat',
    description: 'Taiwanese VAT',
    placeholder: '12345678'
  },
  {
    country: 'Thailand',
    type: 'th_vat',
    description: 'Thai VAT',
    placeholder: '1234567891234'
  },
  {
    country: 'Turkey',
    type: 'tr_tin',
    description: 'Turkish Tax Identification Number',
    placeholder: '0123456789'
  },
  {
    country: 'Ukraine',
    type: 'ua_vat',
    description: 'Ukrainian VAT',
    placeholder: '123456789'
  },
  {
    country: 'United Arab Emirates',
    type: 'ae_trn',
    description: 'United Arab Emirates TRN',
    placeholder: '123456789012345'
  },
  {
    country: 'United Kingdom',
    type: 'eu_vat',
    description: 'Northern Ireland VAT number',
    placeholder: 'XI123456789'
  },
  {
    country: 'United Kingdom',
    type: 'gb_vat',
    description: 'United Kingdom VAT number',
    placeholder: 'GB123456789'
  },
  {
    country: 'United States',
    type: 'us_ein',
    description: 'United States EIN',
    placeholder: '12-3456789'
  },
  {
    country: 'Uruguay',
    type: 'uy_ruc',
    description: 'Uruguayan RUC number',
    placeholder: '123456789012'
  },
  {
    country: 'Venezuela',
    type: 've_rif',
    description: ' Venezuelan RIF number',
    placeholder: 'A-12345678-9'
  },
  {
    country: 'Vietnam',
    type: 'vn_tin',
    description: ' Vietnamese tax ID number',
    placeholder: '1234567890'
  }
]
