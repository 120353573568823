import {
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Td,
  Tr
} from '@chakra-ui/react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { formatDate } from '../../../../../../helpers/date'
import { getInvitationUrl } from '../../../../../../helpers/organizations'
import { Close, CopyLink, Delete, Dots } from '../../../../../shared/Icons'
import {
  useIsRole,
  useOrganizationWithId
} from '../../../../../shared/hooks/organization'
import { RemoveInvitationModal } from '../modals'

const TableRow = ({ invitation }) => {
  const { orgId } = useParams()
  const [removeModalOpen, setRemoveModalOpen] = useState(false)
  const { organization } = useOrganizationWithId(orgId)
  const userIsAdmin = useIsRole(organization, 'ADMIN')

  const handleCopyInvitationLink = (code) => {
    const invitationUrl = getInvitationUrl(
      organization.id,
      organization.id,
      code
    )
    navigator.clipboard.writeText(invitationUrl)
  }
  const isDeclined = !!invitation.declinedAt

  return (
    <Tr>
      <Td>{invitation.targetEmail}</Td>
      <Td textTransform="capitalize">{invitation.role.toLowerCase()}</Td>
      <Td>-</Td>
      <Td>{isDeclined ? 'Declined' : 'Invitation pending'}</Td>
      <Td>{formatDate(invitation.createdAt)}</Td>
      {userIsAdmin && (
        <Td isNumeric>
          <Menu variant="table" autoSelect={false}>
            <MenuButton variant="icon" as={IconButton} icon={<Dots />} />
            <MenuList>
              <MenuDivider />
              {!isDeclined && (
                <MenuItem
                  onClick={() => handleCopyInvitationLink(invitation.code)}
                >
                  <CopyLink mr={2.5} />
                  Copy link
                </MenuItem>
              )}

              <MenuItem onClick={() => setRemoveModalOpen(true)}>
                {isDeclined ? <Delete mr={2.5} /> : <Close mr={2.5} />}
                {isDeclined ? 'Remove Invitation' : 'Withdraw invitation'}
              </MenuItem>
              <RemoveInvitationModal
                organization={organization}
                visible={removeModalOpen}
                invitation={invitation}
                onVisibilityChange={(visible) => setRemoveModalOpen(visible)}
              />
            </MenuList>
          </Menu>
        </Td>
      )}
    </Tr>
  )
}

export default TableRow
