import { Box, Link, Td, Tr, useDisclosure } from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'
import routes from '../../../../../../constants/routes'
import { formatDateTime } from '../../../../../../helpers/date'
import { withOrgPathFallback } from '../../../../../../router'
import { Close } from '../../../../../shared/Icons'
import Region from '../../../../../shared/Region'
import ResourceStatus from '../../../../../shared/ResourceStatus'
import Spinner from '../../../../../shared/Spinner'
import { TdTruncated } from '../../../../../shared/Table'
import { DetachModal } from '../../modals'

const TableRow = ({ instance, securityGroupId, hasPermission }) => {
  const detachDisclosure = useDisclosure()

  const canBeDetached =
    hasPermission && instance.attachment.status === 'attached'

  return (
    <Tr>
      <TdTruncated data-hj-suppress>
        <Link
          colorScheme="gray"
          as={ReactLink}
          to={withOrgPathFallback(
            routes.dashboard.instances.view.replace(':instanceId', instance.id)
          )}
        >
          {instance.name}
        </Link>
      </TdTruncated>

      <Td>
        <Region slug={instance.region} />
      </Td>
      <Td>{formatDateTime(instance.createdAt)}</Td>
      <Td>
        <ResourceStatus status={instance.status} />
      </Td>
      <Td isNumeric>
        {canBeDetached ? (
          <>
            <Box
              _hover={{ cursor: 'pointer' }}
              onClick={detachDisclosure.onOpen}
              role="group"
            >
              <Close color="gray.500" _groupHover={{ color: 'gray.600' }} />
            </Box>
            <DetachModal
              disclosure={detachDisclosure}
              securityGroupId={securityGroupId}
              instance={instance}
            />
          </>
        ) : (
          <Spinner color="gray.500" />
        )}
      </Td>
    </Tr>
  )
}

export default TableRow
