import { Box, Link, Text } from '@chakra-ui/react'

const ErrorText = ({ children, ...props }) => (
  <Text as="span" color="red.500" {...props}>
    {children}
  </Text>
)

const ErrorLink = ({ children, ...props }) => (
  <Link colorScheme="red" {...props}>
    {children}
  </Link>
)

export const errorCodes = {
  invalidVatNumber: '01215',
  accountSuspended: '01227',
  invalidPhoneNumber: '01242',
  phoneNumberAlreadyExists: '01243',
  phoneNumberSetupRateLimited: '01244',
  phoneNumberCheckRateLimited: '01245',
  phoneNumberSetupNotFoundOrExpired: '01246',
  phoneNumberCheckFailed: '01247',
  allFieldsAreMandatory: '02003'
}

const errors = [
  {
    code: errorCodes.invalidVatNumber,
    message: <ErrorText>VAT number is invalid.</ErrorText>
  },
  {
    code: errorCodes.invalidPhoneNumber,
    message: <ErrorText>Phone number format is invalid.</ErrorText>
  },
  {
    code: errorCodes.phoneNumberCheckFailed,
    message: <ErrorText>Invalid code.</ErrorText>
  },
  {
    code: errorCodes.phoneNumberCheckRateLimited,
    message: (
      <ErrorText>
        Too many verification attempts. Your account has been suspended. Please{' '}
        <ErrorLink href={process.env.REACT_APP_TICKET_PAGE_URL} isExternal>
          contact support
        </ErrorLink>
      </ErrorText>
    )
  },
  {
    code: errorCodes.phoneNumberAlreadyExists,
    message: <ErrorText>This phone number is already being used.</ErrorText>
  },
  {
    code: errorCodes.phoneNumberSetupRateLimited,
    message: <ErrorText>Too many numbers provided. Try again later.</ErrorText>
  },
  {
    code: errorCodes.phoneNumberSetupNotFoundOrExpired,
    message: (
      <ErrorText>
        Phone number setup not found. Please provide your phone number again.
      </ErrorText>
    )
  },
  {
    code: errorCodes.accountSuspended,
    message: (
      <ErrorText>
        Your account has been suspended due to too many attempts to enter the
        verification code. Please contact our{' '}
        <ErrorLink href={process.env.REACT_APP_TICKET_PAGE_URL} isExternal>
          Support team
        </ErrorLink>{' '}
        for more information.
      </ErrorText>
    )
  },
  {
    code: errorCodes.allFieldsAreMandatory,
    message: <ErrorText>Missing mandatory information.</ErrorText>
  }
]

const ApiErrorMessage = ({ code, ...props }) => {
  if (!code) return null
  const error = errors.find((e) => e.code === code)
  if (error && error.message) {
    return <Box {...props}>{error.message}</Box>
  } else {
    return (
      <Box {...props}>
        <ErrorText>
          An error occurred. Contact{' '}
          <ErrorLink href={process.env.REACT_APP_TICKET_PAGE_URL} isExternal>
            Support team
          </ErrorLink>{' '}
          for more information.
        </ErrorText>
      </Box>
    )
  }
}

export default ApiErrorMessage
