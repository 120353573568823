import { useOrganization } from '../../shared/hooks/organization'
import Business from '../../shared/Survey/Business'
import Error from '../../shared/Survey/Error'
import Institution from '../../shared/Survey/Institution'
import Loading from '../../shared/Survey/Loading'
import PrivateUse from '../../shared/Survey/PrivateUse'
import { UsageTypeEnum } from '../../shared/usageType'

const OrganizationForm = () => {
  const { organization, error } = useOrganization()

  if (error) {
    return <Error />
  }
  if (!organization && !error) {
    return <Loading />
  }

  return (
    <>
      {organization.usageType === UsageTypeEnum.BUSINESS && (
        <Business id={organization.id} />
      )}
      {organization.usageType === UsageTypeEnum.INSTITUTION && (
        <Institution id={organization.id} />
      )}
      {organization.usageType === UsageTypeEnum.PRIVATE_USE && (
        <PrivateUse id={organization.id} />
      )}
    </>
  )
}

export default OrganizationForm
