import { Divider, HStack, Skeleton, SkeletonCircle } from '@chakra-ui/react'

const Loading = () => (
  <HStack spacing={6}>
    <Skeleton h={3.5} w={44} />
    <Divider h={6} orientation="vertical" />
    <SkeletonCircle size={7} />
    <Skeleton h={3.5} w={36} />
  </HStack>
)

export default Loading
