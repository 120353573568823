import { Tooltip } from '@chakra-ui/react'
import { Refresh } from '../../../../../shared/Icons'

const RenewInfo = () => (
  <Tooltip
    hasArrow
    placement="top"
    label="Subscription will automatically renew at the end of the current period."
  >
    <div>
      <Refresh cursor="pointer" color="gray.500" _hover={{ color: 'black' }} />
    </div>
  </Tooltip>
)

export default RenewInfo
