import React from 'react'
import { Box } from '@chakra-ui/react'
import MetaTags from 'react-meta-tags'
import lines from './lines.svg'

const LinesBackground = ({ children }) => {
  return (
    <>
      <MetaTags>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </MetaTags>
      <Box
        minH="100vh"
        pt={{ base: 12, lg: 16 }}
        pb={{ lg: 12 }}
        bgColor={{ lg: 'black' }}
        bgImage={{ lg: `url(${lines})` }}
        bgSize="cover"
        bgRepeat="no-repeat"
      >
        {children}
      </Box>
    </>
  )
}

export default LinesBackground
