import { useState } from 'react'
import {
  FormControl,
  FormLabel,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  useClipboard
} from '@chakra-ui/react'
import { useField } from 'formik'
import { generate } from 'generate-password'
import Asterisk from '../../shared/Asterisk'
import { Copy, Check, Reset } from '../../shared/Icons'
import { PASSWORD_OPTIONS } from '../../../constants/defaults'

export const PasswordField = ({ name, onChange, label }) => {
  const [field, meta, helpers] = useField(name)
  const [show, setShow] = useState(false)

  const handleChange = (event) => {
    const { value } = event.target
    onChange ? onChange(value) : helpers.setValue(value)
  }

  return (
    <FormControl isInvalid={meta.error}>
      <FormLabel>
        <Asterisk />
        {label ?? 'Password'}
      </FormLabel>
      <InputGroup zIndex={0}>
        <Input
          type={show ? 'text' : 'password'}
          placeholder="Enter password"
          {...field}
          onChange={handleChange}
          pr={16}
        />
        <InputRightElement w={16}>
          <Button
            size="xs"
            variant="input"
            colorScheme="gray"
            onClick={() => setShow(!show)}
          >
            {show ? 'Hide' : 'Show'}
          </Button>
        </InputRightElement>
      </InputGroup>
    </FormControl>
  )
}

export const CopyPassword = ({ value, onChange }) => {
  const { onCopy, hasCopied } = useClipboard(value)

  const handleCopy = (event) => {
    onCopy(event.target.value)
    if (onChange) onChange(true)
  }

  return (
    <Button
      alignItems="center"
      leftIcon={hasCopied ? <Check boxSize={4} /> : <Copy boxSize={4} />}
      onClick={handleCopy}
    >
      {hasCopied ? 'Copied' : 'Copy'}
    </Button>
  )
}

export const RegeneratePassword = ({ onChange }) => {
  const generatedPassword = generate(PASSWORD_OPTIONS)

  const handleRegenerate = () => onChange(generatedPassword)

  return (
    <Button
      variant="outline"
      colorScheme="OffBlack"
      leftIcon={<Reset width="16px" heigh="16px" />}
      onClick={handleRegenerate}
    >
      Regenerate
    </Button>
  )
}
