import {
  Avatar,
  Box,
  Divider,
  Grid,
  HStack,
  LinkBox,
  LinkOverlay,
  MenuButton,
  Menu as MenuChakra,
  MenuItem,
  MenuList,
  Spacer,
  Text
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Link as ReactLink } from 'react-router-dom'
import useSWR from 'swr'
import { accountApiRequest } from '../../../../api'
import accountLogo from '../../../shared/img/account-logo.svg'
import routes from '../../../../constants/routes'
import { withOrgPathFallback } from '../../../../router'
import { ArrowDown } from '../../../shared/Icons'
import { useOrgParam } from '../../../shared/hooks/useOrgParam'
import SwitchAccount from './SwitchAccount'

const Menu = ({ account }) => {
  const orgId = useOrgParam()
  const { data: organization } = useSWR(
    orgId ? `/organizations/${orgId}` : null,
    accountApiRequest
  )
  const [picture, setPicture] = useState(
    account.picture ? account.picture : accountLogo
  )
  const [name, setName] = useState(account.name)

  useEffect(() => {
    if (orgId && organization) {
      setName(organization.name)
      setPicture(accountLogo)
    }
    if (!orgId) {
      setName(account.name)
      setPicture(picture)
    }
  }, [orgId, organization, account])

  return (
    <MenuChakra data-hj-suppress variant="topbar" autoSelect={false}>
      {({ isOpen }) => (
        <Box m={0}>
          <MenuButton>
            <HStack spacing={2}>
              <Avatar size="sm" name={name} src={picture} />
              <Text
                maxW={200}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {name}
              </Text>
              <ArrowDown transform={isOpen ? 'rotate(180deg)' : 'none'} />
            </HStack>
          </MenuButton>
          <MenuList zIndex="10" maxW={200}>
            <MenuItem>
              <Grid>
                <Avatar size="xs" name={name} src={picture} />
                <Text
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {name}
                </Text>
              </Grid>
            </MenuItem>
            <Divider my={1} />
            <>
              <SwitchAccount />
              <MenuItem>
                <LinkBox>
                  <Spacer />
                  <LinkOverlay
                    as={ReactLink}
                    to={withOrgPathFallback(
                      routes.dashboard.organizations.index
                    )}
                  >
                    My Organizations
                  </LinkOverlay>
                </LinkBox>
              </MenuItem>
            </>
            <MenuItem>
              <LinkBox>
                <Spacer />
                <LinkOverlay
                  as={ReactLink}
                  to={withOrgPathFallback(routes.dashboard.accountSettings)}
                >
                  Account Settings
                </LinkOverlay>
              </LinkBox>
            </MenuItem>
            <Divider my={1} />
            <MenuItem>
              <LinkBox>
                <Spacer />
                <LinkOverlay as={ReactLink} to={routes.signout}>
                  Logout
                </LinkOverlay>
              </LinkBox>
            </MenuItem>
          </MenuList>
        </Box>
      )}
    </MenuChakra>
  )
}

export default Menu
