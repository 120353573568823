import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import useSWR from 'swr'
import { Organization } from '../../../../../../api/organizations'
import messages from '../../../../../../constants/messages'
import routes from '../../../../../../constants/routes'
import { withOrgPath } from '../../../../../../router'
import { useIsMounted } from '../../../../../shared/hooks/useIsMounted'
import Spinner from '../../../../../shared/Spinner'

export const AcceptModal = ({ disclosure, id, code }) => {
  const { orgId, projectId } = useParams()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { data: inviteData, error: inviteError } = useSWR(
    `/organizations/${id}/invitations/get?code=${code}`,
    () => Organization.fetchInvitationByCode(id, code)
  )
  const isMounted = useIsMounted()

  const { isOpen, onClose } = disclosure

  const handleAccept = useCallback(async () => {
    setLoading(true)
    try {
      await Organization.acceptInvitation(id, code)
      onClose()
      history.push(withOrgPath(routes.dashboard.index, orgId, projectId))
    } catch {
      if (isMounted()) {
        setError(error)
        setLoading(false)
      }
    }
  }, [id, code])

  const handleDecline = useCallback(async () => {
    setLoading(true)
    try {
      await Organization.declineInvitation(id, code)
      onClose()
      history.push(routes.dashboard.index)
    } catch {
      if (isMounted()) {
        setError(error)
        setLoading(false)
      }
    }
  }, [id, code])

  const renderContent = useCallback(() => {
    if (!inviteError && !inviteData) {
      return <Spinner />
    }

    if (inviteError) {
      return (
        <>
          <Heading size="xl" mb={4} textAlign="center">
            Invalid invitation
          </Heading>
          <Text textAlign="center">
            Contact the person who invited you to generate a new invitation.
          </Text>
          <Button onClick={onClose} isFullWidth mt={8}>
            Close
          </Button>
        </>
      )
    }

    return (
      <>
        <Heading size="xl" mb={4} textAlign="center">
          {inviteData?.createdBy.name} invited you to the organization{' '}
          {inviteData?.organization.name}.
        </Heading>
        {error && <Text color="red">{messages.genericError}</Text>}
        <Stack spacing={4} mt={8}>
          <Button isLoading={loading} onClick={handleAccept}>
            Accept
          </Button>
          <Button
            colorScheme="offBlack"
            variant="outline"
            isDisabled={loading}
            onClick={handleDecline}
            isFullWidth
          >
            Decline
          </Button>
        </Stack>
      </>
    )
  }, [
    inviteError,
    inviteData,
    error,
    loading,
    onClose,
    handleAccept,
    handleDecline
  ])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>{renderContent()}</ModalBody>
      </ModalContent>
    </Modal>
  )
}
