const colors = {
  white: '#fff',
  black: '#000',
  offBlack: {
    100: '#ececec',
    300: '#c8c8c8',
    500: '#000000',
    600: '#383838',
    700: '#1e1e1e'
  },
  gray: {
    100: '#ececec',
    200: '#e2e2e2',
    300: '#c8c8c8',
    400: '#afafaf',
    500: '#767676',
    700: '#383838',
    900: '#1e1e1e'
  },
  red: {
    100: '#fbe8e6',
    300: '#ffc8b9',
    500: '#d72e0a',
    600: '#ac2408',
    700: '#962007'
  },
  orange: {
    100: '#FDF7EB',
    500: '#ffbd00'
  },
  green: {
    100: '#ECF7EF',
    500: '#25855a',
    700: '#2e8540'
  },
  blue: {
    100: '#eaecff',
    300: '#caceff',
    400: '#9ca4ff',
    500: '#3555ff',
    600: '#0027f4',
    700: '#0020cc',
    900: '#000833'
  }
}

export default colors
