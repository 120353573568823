import { AccountBilling, OrgBilling } from '../../../../../api/billing'

export const defaultDay = 30

export const storages = [
  { label: 'Object storage', type: 'object_storage', color: 'blue.500' },
  { label: 'VAST filesystem', type: 'vast_filesystem', color: 'blue.300' }
]

export const mutateStorage = ({
  mutate,
  endingBefore,
  startingAfter,
  orgId,
  type
}) => {
  const key = orgId
    ? `/billing/storage-usage?org=${orgId}&type=${type}&grouping=day`
    : `/billing/storage-usage?type=${type}&grouping=day`

  mutate(
    key,
    async () =>
      orgId
        ? await OrgBilling.fetchStorage({
            orgId,
            type,
            startingAfter,
            endingBefore
          })
        : AccountBilling.fetchStorage({ type, startingAfter, endingBefore }),
    { revalidate: false }
  )
}
