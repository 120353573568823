import { Heading, Text, Button, useDisclosure } from '@chakra-ui/react'
import { Card } from '../../../shared/Cards'
import { DestroyModal } from '../Modals'
import { status as statuses } from '../../../../helpers/instances'

const Destroy = ({ instance }) => {
  const destroyDisclosure = useDisclosure()
  const { isProtected, status } = instance
  const isDeleting = status === statuses.deleting

  return (
    <Card>
      <Heading size="xl">Destroy Instance</Heading>
      <Text color="gray.500" mt={2}>
        This action will permanently destroy the <br /> above Instance and
        cannot be recovered.
      </Text>
      <Button
        colorScheme="red"
        onClick={destroyDisclosure.onOpen}
        isLoading={isDeleting}
        isDisabled={isProtected}
        mt={8}
      >
        Destroy Instance
      </Button>
      <DestroyModal disclosure={destroyDisclosure} instance={instance} />
    </Card>
  )
}

export default Destroy
