import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack
} from '@chakra-ui/react'
import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import {
  AccountSecurityGroups,
  OrgSecurityGroups
} from '../../../../../api/securityGroups'
import { toastMessages } from '../../../../../constants/toast-messages'
import { useToast } from '../../../../shared/Toast'
import { useIsMounted } from '../../../../shared/hooks/useIsMounted'

export const DetachModal = ({ disclosure, instance, securityGroupId }) => {
  const { orgId, projectId } = useParams()
  const { isOpen, onClose } = disclosure
  const [isSubmitting, setIsSubmitting] = useState()
  const isMounted = useIsMounted()
  const toast = useToast()

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true)
    try {
      const instanceId = instance.id
      if (orgId) {
        await OrgSecurityGroups.detachById({
          orgId,
          projectId,
          securityGroupId,
          instanceId
        })
        await mutate((key) =>
          key.startsWith(
            `/projects/${projectId}/security-groups/${securityGroupId}/instances?`
          )
        )
      } else {
        await AccountSecurityGroups.detachById({
          securityGroupId,
          instanceId
        })
        await mutate((key) =>
          key.startsWith(`/security-groups/${securityGroupId}/instances?`)
        )
      }
      toast({
        status: 'success',
        message: toastMessages.saved
      })
      onClose()
    } catch {
      toast({
        status: 'error',
        message: toastMessages.notSaved
      })
    } finally {
      if (isMounted()) {
        setIsSubmitting(false)
      }
    }
  }, [securityGroupId])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <Heading size="xl" mb={4} textAlign="center">
              Are you sure you want to unassign “{instance.name}”?
            </Heading>
            <Stack spacing={4}>
              <Button
                type="submit"
                isLoading={isSubmitting}
                colorScheme="red"
                onClick={handleSubmit}
              >
                Unassign Instance
              </Button>
              <Button
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isSubmitting}
                onClick={onClose}
                isFullWidth
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
