import Error from './ErrorTemplate'

const NotFound = ({ children }) => (
  <Error
    statusCode="404"
    title="Page not found"
    message="The page you are looking for has been removed, renamed or is
      unavailable."
  >
    {children}
  </Error>
)

export default NotFound
