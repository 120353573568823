import React from 'react'
import { Container } from '@chakra-ui/react'
import MetaTags from 'react-meta-tags'
import { Logo } from '../Icons'

const ResponsiveContainer = ({ children, logo }) => {
  return (
    <>
      <MetaTags>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </MetaTags>
      <Container
        bg="white"
        borderRadius={{ lg: '10px' }}
        maxW={{ base: '480px', lg: '624px' }}
        minH={{ base: '100vh', lg: 'auto' }}
        py={{ base: 0, lg: 12 }}
        px={{ base: 6, sm: 10, lg: 28 }}
        mt="35px"
        centerContent
        border={{ base: 'none', lg: '1px' }}
        borderColor={{ base: 'none', lg: 'gray.200' }}
        boxShadow={{ base: 'none', lg: '0 1px 3px 0 #0000001A' }}
      >
        {logo && <Logo mb={5} />}
        {children}
      </Container>
    </>
  )
}

export default ResponsiveContainer
