import useSWR from 'swr'
import { OrgSecurity, AccountSecurity } from '../../../api/security'
import { serialize } from '../../../helpers/serializers'
import { INITIAL_PAGE, PER_PAGE_5 } from '../../shared/Table'
import { swrOptions } from '../../../constants/swr-options'

export const useOrgSSHKeys = ({
  orgId,
  projectId,
  page = INITIAL_PAGE,
  perPage = PER_PAGE_5,
  shouldFetch = true
}) => {
  const key = shouldFetch
    ? `/projects/${projectId}/ssh-keys?page=${page}&per_page=${perPage}&org=${orgId}`
    : null
  const fetcher = () =>
    OrgSecurity.fetchSSHKeys({ orgId, projectId, page, perPage })

  const { data, isValidating, isLoading, error } = useSWR(
    key,
    fetcher,
    swrOptions
  )

  let sshKeys, pagination

  if (data) ({ sshKeys, ...pagination } = serialize(data))

  return (
    shouldFetch && {
      sshKeys,
      pagination,
      isValidatingSSHKeys: isValidating,
      isLoadingSSHKeys: isLoading,
      isErrorSSHKeys: Boolean(error)
    }
  )
}

export const useOrgAPITokens = ({
  orgId,
  projectId,
  page = INITIAL_PAGE,
  perPage = PER_PAGE_5
}) => {
  const key = `/organizations/${orgId}/projects/${projectId}/api-keys?per_page=${perPage}&page=${page}`

  const fetcher = () =>
    OrgSecurity.fetchAPITokens({ orgId, projectId, page, perPage })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  let apiKeys, pagination

  if (data) ({ apiKeys, ...pagination } = serialize(data))

  return {
    apiTokens: apiKeys,
    pagination,
    isLoadingAPITokens: isLoading,
    isErrorAPITokens: Boolean(error)
  }
}

export const useAccountSSHKeys = ({
  page = INITIAL_PAGE,
  perPage = PER_PAGE_5,
  shouldFetch = true
}) => {
  const key = shouldFetch ? `/ssh-keys?page=${page}&per_page=${perPage}` : null
  const fetcher = () => AccountSecurity.fetchSSHKeys({ page, perPage })

  const { data, isValidating, isLoading, error } = useSWR(
    key,
    fetcher,
    swrOptions
  )

  let sshKeys, pagination

  if (data) ({ sshKeys, ...pagination } = serialize(data))

  return (
    shouldFetch && {
      sshKeys,
      pagination,
      isValidatingSSHKeys: isValidating,
      isLoadingSSHKeys: isLoading,
      isErrorSSHKeys: Boolean(error)
    }
  )
}

export const useAccountAPITokens = ({
  page = INITIAL_PAGE,
  perPage = PER_PAGE_5
}) => {
  const key = `/api-keys?per_page=${perPage}&page=${page}`
  const fetcher = () => AccountSecurity.fetchAPITokens({ page, perPage })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  let apiKeys, pagination

  if (data) ({ apiKeys, ...pagination } = serialize(data))

  return {
    apiTokens: apiKeys,
    pagination,
    isLoadingAPITokens: isLoading,
    isErrorAPITokens: Boolean(error)
  }
}
