import { useFormikContext } from 'formik'
import { StaticIPCard } from '../RadioCards'

const StaticIP = () => {
  const { values, setValues } = useFormikContext()
  const isChecked = values.options.isStaticIPChecked

  const onChange = (event) => {
    const isStaticIPChecked = event.target.checked

    setValues((prev) => ({
      ...prev,
      options: {
        ...prev.options,
        isStaticIPChecked
      }
    }))
  }

  return <StaticIPCard isChecked={isChecked} onChange={onChange} />
}

export default StaticIP
