import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useFormikContext } from 'formik'
import {
  useOrgCommunityConfigs,
  useAccountCommunityConfigs
} from '../../../shared/hooks'
import {
  transformInstances,
  getTypeOptions,
  getInstancesGroupByType
} from './index'

export const ConfigsHandler = () => {
  const { orgId, projectId } = useParams()
  const { values, setValues } = useFormikContext()
  const { isEmpty } = values

  const {
    communityConfigs,
    isLoadingCommunityConfigs,
    isErrorCommunityConfigs
  } = orgId
    ? useOrgCommunityConfigs({ orgId, projectId })
    : useAccountCommunityConfigs()

  useEffect(() => {
    if (
      communityConfigs &&
      isEmpty &&
      !isLoadingCommunityConfigs &&
      !isErrorCommunityConfigs
    ) {
      const instances = transformInstances(communityConfigs)
      const typeOptions = getTypeOptions(instances)
      const instancesGroupByType = getInstancesGroupByType(instances)
      const configurationOptions = instancesGroupByType[typeOptions[0].value]

      setValues((prev) => ({
        ...prev,
        type: {
          options: typeOptions,
          selected: typeOptions[0]
        },
        instancesGroupByType,
        configuration: {
          options: configurationOptions,
          selected: configurationOptions[0]
        },
        isEmpty: false
      }))
    }
  }, [communityConfigs])

  return null
}
