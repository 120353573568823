import textStyles from '../foundations/textStyles'

const Text = {
  baseStyle: {
    color: 'black'
  },
  sizes: {
    xs: {
      ...textStyles.xs,
      color: 'gray.500'
    },
    sm: {
      ...textStyles.sm,
      color: 'gray.900'
    },
    md: {
      ...textStyles.md
    },
    lg: {
      ...textStyles.lg
    },
    xl: {
      ...textStyles.xl
    },
    '2xl': {
      ...textStyles['2xl']
    },
    '3xl': {
      ...textStyles['3xl'],
      fontSize: ['xl', '3xl', '3xl']
    },
    '4xl': {
      ...textStyles['4xl']
    },
    '5xl': {
      ...textStyles['5xl']
    }
  }
}

export default Text
