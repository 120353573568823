import { HStack } from '@chakra-ui/react'
import moment from 'moment'
import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import { Calendar as CalendarIcon } from '../../../../shared/Icons'
import { defaultDay, mutateStorage } from './utils'

const Calendar = ({ selected, onSelect, type, setCalendarRange }) => {
  const { orgId } = useParams()
  const endingBefore = moment().format()
  const startingAfter = moment()
    .subtract(selected || defaultDay, 'days')
    .format()
  const [dateRange, setDateRange] = useState([startingAfter, endingBefore])
  const [startDate, endDate] = dateRange

  useEffect(() => {
    if (selected) {
      setDateRange([startingAfter, endingBefore])
      setCalendarRange([startingAfter, endingBefore])
    }
  }, [selected])

  const handleChange = (range) => {
    onSelect(null)

    if (range[1] !== null) {
      const endingBefore = moment(range[1]).format('yyyy-MM-DD')
      const startingAfter = moment(range[0]).format('yyyy-MM-DD')
      setCalendarRange(range)
      mutateStorage({
        mutate,
        endingBefore,
        startingAfter,
        orgId,
        type
      })
    }
    setDateRange(range)
  }

  return (
    <HStack
      spacing={1.5}
      border="1px"
      borderLeft={0}
      borderRadius="6px"
      borderLeftRadius={0}
      borderColor="gray.300"
      px={2}
      py={1}
    >
      <CalendarIcon boxSize={4} color="gray.500" />
      <div className="date-picker-sm">
        <DatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={(range) => handleChange(range)}
          maxDate={new Date()}
          isClearable={true}
          dateFormat="dd/MM/YYYY"
        />
      </div>
    </HStack>
  )
}

export default Calendar
