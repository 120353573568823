import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Stack,
  Heading,
  Text,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { formatCurrency } from '../../../../../helpers/currency'
import Prices from '../create/prices'

const Configuration = ({ type }) => (
  <Stack spacing={0}>
    <TableContainer mb={8}>
      <Table variant="card">
        <Tbody>
          <Tr>
            <Td>GPU type</Td>
            <Td>{type.toUpperCase()}</Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  </Stack>
)

export const ReserveModal = ({ disclosure }) => {
  const { isOpen, onClose } = disclosure
  const { values, submitForm, isSubmitting } = useFormikContext()
  const { type, prices } = values

  const handleSubmit = () => {
    onClose()
    submitForm()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Heading size="xl" textAlign="center" mb={4}>
            Reserve GPUs
          </Heading>
          <Stack spacing={8}>
            <Text size="sm" textAlign="center" color="gray.500">
              To ensure availability of the reserved GPUs for the selected
              period, full payment is required upfront. Additional resources
              will be billed on demand.
            </Text>
            <Configuration type={type} />
            <Prices />
            <Text size="sm" textAlign="center" color="gray.500">
              You may receive an email to autheticate the payment using 3D
              Security, as required by your bank account.
            </Text>
            <Stack spacing={4}>
              <Button isLoading={isSubmitting} onClick={handleSubmit}>
                Create Reservation for{' '}
                {formatCurrency(prices.totalPrice) || '-'}
              </Button>
              <Button
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isSubmitting}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
