import {
  Button,
  Heading,
  Skeleton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useIsVip } from '../../../../helpers/vip'
import {
  ValidationErrors,
  isUserValidToCreateResources
} from '../../../Errors/ValidationErrors'
import { Card } from '../../../shared/Cards'
import { useAccountBilling, useOrgBilling } from '../../../shared/hooks'
import { useAccount, useAccountSettings } from '../../../shared/hooks/account'
import { useOrgSettings } from '../../../shared/hooks/organization'
import useSuspended from '../../../shared/hooks/useSuspended'
import { CreateModal } from '../modals'

const Summary = ({ isLoading: isLoadingConfig }) => {
  const createDisclosure = useDisclosure()
  const { orgId } = useParams()
  const { values, isSubmitting } = useFormikContext()
  const { gpuName, country, gpuCount, cpuCount, ram, storage } =
    values.configuration.selected
  const { account, isErrorAccount, isLoadingAccount } = useAccount()
  const { billing, isErrorBilling, isLoadingBilling } = orgId
    ? useOrgBilling({ orgId })
    : useAccountBilling()
  const { settings, isErrorSettings, isLoadingSettings } = orgId
    ? useOrgSettings({ orgId })
    : useAccountSettings()
  const isVip = useIsVip(settings)
  const [isUserValid, setIsUserValid] = useState(false)
  const { isSuspended } = useSuspended()

  const isLoading =
    isLoadingAccount || isLoadingBilling || isLoadingSettings || isLoadingConfig

  const isDisabled =
    isErrorAccount ||
    isErrorBilling ||
    isErrorSettings ||
    isUserValid ||
    isSuspended

  useEffect(() => {
    setIsUserValid(
      !isUserValidToCreateResources({
        account,
        billing,
        isVip
      })
    )
  }, [account, billing, isVip])

  if (isLoading) {
    return <Skeleton h="360px" />
  }

  return (
    <Card as={Stack} spacing={5} p={5}>
      <Heading size="md">Instance</Heading>
      <TableContainer mb={8}>
        <Table variant="card">
          <Tbody>
            <Tr>
              <Td>Instance type</Td>
              <Td>{gpuName}</Td>
            </Tr>
            <Tr>
              <Td>Location</Td>
              <Td>{country}</Td>
            </Tr>
            <Tr>
              <Td>GPUs</Td>
              <Td>{gpuCount}x</Td>
            </Tr>
            <Tr>
              <Td>CPUs</Td>
              <Td>{cpuCount}x</Td>
            </Tr>
            <Tr>
              <Td>RAM</Td>
              <Td>{ram} GiB</Td>
            </Tr>
            <Tr>
              <Td>Storage</Td>
              <Td>{storage} GiB</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <Button
        isLoading={isSubmitting}
        isDisabled={isDisabled}
        onClick={createDisclosure.onOpen}
      >
        Create Instance
      </Button>
      <ValidationErrors account={account} billing={billing} isVip={isVip} />
      <CreateModal disclosure={createDisclosure} />
    </Card>
  )
}

export default Summary
