import queryString from 'query-string'
import routes from '../../../constants/routes'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { withOrgPath } from '../../../router'
import { getDefaultProjectId } from '../../../helpers/organizations'
import { useOrganizations } from '../../shared/hooks/organization'
import { useAccount } from '../../shared/hooks/account'

const RedirectDefaultOrg = () => {
  const { search } = useLocation()
  const { account } = useAccount()
  const history = useHistory()
  const values = queryString.parse(search)
  const { organizations, isLoading } = useOrganizations({
    perPage: 50
  })

  useEffect(() => {
    if (!isLoading && values.loginRedirect && account) {
      if (!account.defaultOrganizationId) {
        history.push(routes.dashboard.index)
        return
      }
      organizations?.organizations.forEach((org) => {
        if (org.id === account.defaultOrganizationId) {
          history.push(
            withOrgPath(
              routes.dashboard.index,
              org.id,
              getDefaultProjectId(org)
            )
          )
        }
      })
    }
  }, [account, isLoading])

  return <div />
}

export default RedirectDefaultOrg
