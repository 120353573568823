import moment from 'moment'

const getNameOfCurrentMonth = () => moment().format('MMMM')

const getFirstDayOfCurrentMonth = () => moment().startOf('month').format('D')

const getLastDayOfCurrentMonth = () => moment().endOf('month').format('D')

export const getRangeOfCurrentMonth = () =>
  `${getNameOfCurrentMonth()} ${getFirstDayOfCurrentMonth()} to ${getLastDayOfCurrentMonth()}`

const usageDuration = ({ startedAt, endedAt }) => {
  const start = moment(startedAt)
  const end = moment(endedAt || new Date())
  const duration = moment.duration(end.diff(start))

  return duration
}

const durationByMinutes = ({ startedAt, endedAt }) => {
  const duration = usageDuration({ startedAt, endedAt })
  return Math.ceil(duration.asMinutes())
}

export const getOnDemandUsage = ({ startedAt, endedAt }) => {
  const usage = durationByMinutes({ startedAt, endedAt })

  const roundedHours = (usage / 60).toFixed(2)
  const unit = ` hour${usage > 1 ? 's' : ''}`

  return roundedHours + unit
}

export const getOnDemandCost = ({ unitPrice, startedAt, endedAt }) => {
  const usage = durationByMinutes({ startedAt, endedAt })

  return unitPrice * Math.ceil(usage || 0)
}

export const getLongTermPeriod = (period) =>
  period > 1 ? `${period} months` : 'Monthly'
