import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Textarea
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import * as yup from 'yup'
import { AccountVolumes, OrgVolumes } from '../../../../api/volumes'
import { toastMessages } from '../../../../constants/toast-messages'
import Asterisk from '../../../shared/Asterisk'
import { useToast } from '../../../shared/Toast'

export const RenameModal = ({ volume, disclosure }) => {
  const { projectId, orgId } = useParams()
  const toast = useToast()
  const { isOpen, onClose } = disclosure
  const schema = yup.object().shape({
    name: yup.string().required(`Volume's name is required`),
    description: yup.string()
  })

  const handleSubmit = useCallback(
    async ({ name, description }, { setSubmitting }) => {
      setSubmitting(true)
      try {
        const volumeId = volume.id
        const data = {}
        if (name) {
          data.name = name
        }
        if (description) {
          data.description = description
        }
        if (orgId) {
          await OrgVolumes.updateById({
            orgId,
            projectId,
            volumeId,
            data
          })
          await mutate(
            `/projects/${projectId}/volumes/${volumeId}?org=${orgId}`
          )
        } else {
          await AccountVolumes.updateById({ volumeId, data })
          await mutate(`/volumes/${volumeId}`)
        }
        onClose()
        toast({
          status: 'success',
          message: toastMessages.saved
        })
      } catch {
        toast({
          status: 'error',
          message: toastMessages.notSaved
        })
      } finally {
        setSubmitting(false)
      }
    },
    [volume]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Heading size="xl" mb={8} textAlign="center">
            Rename volume
          </Heading>
          <Formik
            initialValues={{
              name: volume?.name || '',
              description: volume?.description || ''
            }}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, errors, touched, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <Stack spacing={8}>
                  <FormControl isInvalid={errors.name && touched.name}>
                    <FormLabel>
                      <Asterisk />
                      Name
                    </FormLabel>
                    <Field
                      as={Input}
                      name="name"
                      type="text"
                      autoFocus={true}
                    />
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.description}>
                    <FormLabel>Description</FormLabel>
                    <Field
                      as={Textarea}
                      name="description"
                      placeholder="Enter volume description"
                    />
                    <FormErrorMessage>{errors.description}</FormErrorMessage>
                  </FormControl>
                  <Stack spacing={4}>
                    <Button
                      type="submit"
                      isDisabled={isSubmitting}
                      isLoading={isSubmitting}
                    >
                      Save
                    </Button>
                    <Button
                      colorScheme="offBlack"
                      variant="outline"
                      isDisabled={isSubmitting}
                      onClick={onClose}
                      isFullWidth
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
