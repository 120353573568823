import { Stack, SimpleGrid, Heading, Text } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { NumberField } from '../../../../shared/Fields'
import {
  RESERVATION_SIZE_MIN,
  RESERVATION_SIZE_MAX,
  RESERVATION_SIZE_STEP
} from '../../../../../constants/defaults'

const description = `Min ${RESERVATION_SIZE_MIN}, max ${RESERVATION_SIZE_MAX} and divide by ${RESERVATION_SIZE_STEP}.`

const Configuration = () => {
  const { setValues, setTouched } = useFormikContext()

  const onUpdate = (size) => {
    setTouched({})
    setValues((prev) => ({
      ...prev,
      size,
      dates: {
        ...prev.dates,
        start: null,
        end: null,
        days: 0,
        isRangeValid: true
      }
    }))
  }

  return (
    <Stack spacing={8}>
      <Stack spacing={1}>
        <Heading size="xl">Configuration</Heading>
        <Text color="gray.500" size="sm">
          Be aware that our Instance size is limited to 8 GPUs. That means 64
          GPUs will let you create 8 Instances x 8 GPUs.
        </Text>
      </Stack>
      <SimpleGrid columns={2} gap={5}>
        <NumberField
          name="size"
          label="Size"
          description={description}
          min={RESERVATION_SIZE_MIN}
          max={RESERVATION_SIZE_MAX}
          step={RESERVATION_SIZE_STEP}
          onUpdate={onUpdate}
          isRequired
        />
      </SimpleGrid>
    </Stack>
  )
}

export default Configuration
