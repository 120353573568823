import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack
} from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import {
  AccountFloatingIPs,
  OrgFloatingIPs
} from '../../../../../api/floatingIPs'
import { toastMessages } from '../../../../../constants/toast-messages'
import { useToast } from '../../../../shared/Toast'

export const DeleteModal = ({ floatingIP, disclosure }) => {
  const { orgId, projectId } = useParams()
  const toast = useToast()
  const { isOpen, onClose } = disclosure
  const [isSubmitting, setIsSubmitting] = useState()

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true)
    try {
      const floatingIPId = floatingIP.id
      const response = orgId
        ? await OrgFloatingIPs.deleteById({
            orgId,
            projectId,
            floatingIPId
          })
        : await AccountFloatingIPs.deleteById({ floatingIPId })
      if (response.ok) {
        orgId
          ? await mutate((key) =>
              key.startsWith(`/projects/${projectId}/floating-ips?`)
            )
          : await mutate((key) => key.startsWith(`/floating-ips?`))
        toast({
          status: 'success',
          message: toastMessages.floatingIPDeleteSuccess
        })
        onClose()
      }
    } catch {
      toast({
        status: 'error',
        message: toastMessages.floatingIPDeleteError
      })
    }
  }, [floatingIP])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <Heading size="xl" mb={4} textAlign="center">
              Are you sure you want to delete Floating IP “{floatingIP.name}”?
            </Heading>
            <Stack spacing={4}>
              <Button
                type="submit"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                colorScheme="red"
                onClick={handleSubmit}
              >
                Delete Floating IP
              </Button>
              <Button
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isSubmitting}
                onClick={onClose}
                isFullWidth
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
