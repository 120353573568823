import {
  Button,
  HStack,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Card } from '../../../../../shared/Cards'
import { Plus } from '../../../../../shared/Icons'
import { SkeletonTable } from '../../../../../shared/Skeletons'
import {
  INITIAL_PAGE,
  PER_PAGE_5,
  TableCell,
  TableError,
  TablePagination
} from '../../../../../shared/Table'
import { useHasWriteProjectPermissions } from '../../../../../shared/hooks/project'
import {
  useAccountSecurityGroupInstances,
  useOrgSecurityGroupInstances
} from '../../../../../shared/hooks/securityGroups'
import TableRow from './table-row'
import { AssignModal } from '../../modals'

const Instances = ({ region }) => {
  const { orgId, projectId, securityGroupId } = useParams()
  const assignDisclosure = useDisclosure()

  const hasWriteProjectPermissions = orgId
    ? useHasWriteProjectPermissions({
        projectId,
        orgId
      })
    : true
  const [page, setPage] = useState(INITIAL_PAGE)

  const { instances, isError, isLoading } = orgId
    ? useOrgSecurityGroupInstances({
        orgId,
        projectId,
        securityGroupId,
        page,
        perPage: PER_PAGE_5
      })
    : useAccountSecurityGroupInstances({
        securityGroupId,
        page,
        perPage: PER_PAGE_5
      })

  const isTableEmpty = instances?.instances.length === 0

  return (
    <Card as={Stack} spacing={8}>
      <HStack justify="space-between">
        <Stack>
          <Heading size="xl">Instances</Heading>
          <Text color="gray.500" size="sm">
            Instances using this Security Group will be affected by the changes.
          </Text>
        </Stack>
        {hasWriteProjectPermissions && (
          <>
            <Button
              colorScheme="offBlack"
              variant="outline"
              leftIcon={<Plus color="black" />}
              onClick={assignDisclosure.onOpen}
            >
              Assign to Instances
            </Button>
            <AssignModal
              disclosure={assignDisclosure}
              region={region}
              assigned={instances?.instances}
            />
          </>
        )}
      </HStack>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th w="25%">Instance name</Th>
              <Th w="25%">Location</Th>
              <Th w="20%">Created</Th>
              <Th w="20%">Status</Th>
              <Th w="10%" />
            </Tr>
          </Thead>
          {isLoading && <SkeletonTable rows={5} columns={5} />}
          {isError ? (
            <TableError>
              Something went wrong loading the security group instances
            </TableError>
          ) : (
            <Tbody>
              {isTableEmpty ? (
                <TableCell>There are no Instances yet.</TableCell>
              ) : (
                instances?.instances?.map((instance) => (
                  <TableRow
                    key={instance.id}
                    instance={instance}
                    securityGroupId={securityGroupId}
                    hasPermission={hasWriteProjectPermissions}
                  />
                ))
              )}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      {!isError && (
        <TablePagination
          page={page}
          setPage={setPage}
          perPage={PER_PAGE_5}
          totalCount={instances?.totalCount}
        />
      )}
    </Card>
  )
}
export default Instances
