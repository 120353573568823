import { Flex } from '@chakra-ui/react'
import { sidebarWidth } from '../../constants/styles'

const Footer = ({ children, ...props }) => {
  return (
    <Flex
      pos="fixed"
      bottom="0"
      right="0"
      left={sidebarWidth}
      zIndex="1"
      justify="end"
      align="center"
      minW="600px"
      h="68px"
      px="44px"
      borderTop="1px"
      borderTopColor="gray.300"
      bgColor="white"
      {...props}
    >
      {children}
    </Flex>
  )
}

export default Footer
