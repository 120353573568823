import { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Stack,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th
} from '@chakra-ui/react'
import { SkeletonTable } from '../../../../shared/Skeletons'
import {
  INITIAL_PAGE,
  TableCell,
  TableError,
  TablePagination
} from '../../../../shared/Table'
import {
  useOrgBillingReservations,
  useAccountBillingReservations
} from '../../../../shared/hooks'
import TableRow from './table-row'

const ReservationsList = () => {
  const { orgId } = useParams()
  const [page, setPage] = useState(INITIAL_PAGE)

  const {
    reservations,
    pagination,
    isLoadingReservations,
    isErrorReservations
  } = orgId
    ? useOrgBillingReservations({ orgId, page })
    : useAccountBillingReservations({ page })
  const isTableEmpty = reservations?.length === 0

  return (
    <Stack spacing={2}>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th w="30%">ID</Th>
              <Th w="35%">GPU Type</Th>
              <Th w="10%">Start</Th>
              <Th w="15%">End</Th>
              <Th w="10%">Cost</Th>
            </Tr>
          </Thead>
          {isLoadingReservations && (
            <SkeletonTable rows={5} columns={7} hasAction={false} />
          )}
          {isErrorReservations ? (
            <TableError>
              Something went wrong loading the Long-term Instances.
            </TableError>
          ) : (
            <Tbody>
              {isTableEmpty ? (
                <TableCell>There are no reserved resources yet.</TableCell>
              ) : (
                reservations?.map((longTerm, index) => (
                  <TableRow key={index} row={longTerm} orgId={orgId} />
                ))
              )}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      {!isErrorReservations && (
        <TablePagination
          page={page}
          setPage={setPage}
          perPage={pagination?.perPage}
          totalCount={pagination?.totalCount}
        />
      )}
    </Stack>
  )
}

export default ReservationsList
