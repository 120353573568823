import {
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Td,
  Text,
  Tooltip,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'
import routes from '../../../../constants/routes'
import { formatDateTime } from '../../../../helpers/date'
import { status } from '../../../../helpers/snapshots'
import { withOrgPathFallback } from '../../../../router'
import { ErrorBadge, PendingDeleteBadge } from '../../../shared/Badge'
import { Bin, Dots, Edit, Settings } from '../../../shared/Icons'
import Region from '../../../shared/Region'
import Spinner from '../../../shared/Spinner'
import DeleteModal from '../modals/delete'
import RenameModal from '../modals/rename'

const label =
  'This instance snapshot is currently in use and cannot be deleted.'

const TableRow = ({ snapshot, hasWriteProjectPermissions }) => {
  const deleteDisclosure = useDisclosure()
  const renameDisclosure = useDisclosure()

  const isVisible =
    snapshot.status === status.created || snapshot.status === status.error
  const isActive = snapshot.status === status.created
  const isPendingDelete = snapshot.status === status.pendingDelete
  const isLoading =
    snapshot.status === status.creating || snapshot.status === status.deleting
  const isError = snapshot.status === status.error
  const deleteDisabled = snapshot.instanceCount > 0

  return (
    <Tr>
      <Td data-hj-suppress whiteSpace="normal">
        <Text noOfLines={2} color="gray.500">
          {snapshot.name}
        </Text>
      </Td>
      <Td>
        <Region slug={snapshot.region} />
      </Td>
      <Td data-hj-suppress whiteSpace="normal">
        <Text noOfLines={2} color="gray.500">
          {snapshot.source}
        </Text>
      </Td>
      <Td>{snapshot.sourceImage || '-'}</Td>
      <Td>{snapshot.size} GiB</Td>
      <Td>{snapshot.instanceCount}</Td>
      <Td>{formatDateTime(snapshot.createdAt)}</Td>
      <Td>
        {isError && <ErrorBadge />}
        {isPendingDelete && <PendingDeleteBadge />}
      </Td>
      {hasWriteProjectPermissions && (
        <Td isNumeric>
          <>
            {isVisible && (
              <Menu variant="table" autoSelect={false}>
                <MenuButton variant="icon" as={IconButton} icon={<Dots />} />
                <MenuList>
                  {isActive && (
                    <>
                      <MenuItem
                        as={ReactLink}
                        _hover={{ textDecoration: 'none', color: 'black' }}
                        to={withOrgPathFallback(
                          `${routes.dashboard.instances.create}?image=${snapshot.id}`
                        )}
                      >
                        <Settings mr={2.5} />
                        Create instance
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem onClick={renameDisclosure.onOpen}>
                        <Edit mr={2.5} />
                        Rename Snapshot
                      </MenuItem>
                      <RenameModal
                        disclosure={renameDisclosure}
                        snapshot={snapshot}
                      />
                      <MenuDivider />
                    </>
                  )}
                  <Tooltip hasArrow label={label} isDisabled={!deleteDisabled}>
                    <div>
                      <MenuItem
                        onClick={deleteDisclosure.onOpen}
                        id={`delete-${snapshot.id}`}
                        isDisabled={deleteDisabled}
                      >
                        <Bin mr={2.5} />
                        Delete
                      </MenuItem>
                    </div>
                  </Tooltip>
                  <DeleteModal
                    disclosure={deleteDisclosure}
                    snapshot={snapshot}
                  />
                </MenuList>
              </Menu>
            )}
          </>
          {isLoading && <Spinner mr={3} color="gray.500" />}
        </Td>
      )}
    </Tr>
  )
}

export default TableRow
