import { parsePhoneNumberWithError, ParseError } from 'libphonenumber-js'

export default function validate(phoneNumber, countryId) {
  try {
    const { number } = parsePhoneNumberWithError(phoneNumber, countryId)
    if (!number) {
      return 'Invalid phone number'
    }
    return null
  } catch (error) {
    if (error instanceof ParseError) {
      if (error.message === 'INVALID_COUNTRY') {
        return 'Invalid country code'
      } else if (error.message === 'NOT_A_NUMBER') {
        return 'Invalid phone number'
      } else {
        return 'Invalid phone number'
      }
    } else {
      return 'Failed to parse the phone number'
    }
  }
}
