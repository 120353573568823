import { createUserManager } from 'redux-oidc'
import { WebStorageStateStore } from 'oidc-client'
import routes from './../../constants/routes'

const OAUTH_ISSUER = process.env.REACT_APP_OAUTH_ISSUER

const domain = window.location.origin

const userManagerConfig = {
  authority: OAUTH_ISSUER,
  client_id: 'console',
  redirect_uri: `${domain}/callback`,
  response_type: 'code',
  scope: 'openid offline_access profile email',
  post_logout_redirect_uri: `${domain}/signin`,
  silent_redirect_uri: `${domain}${routes.silentRenew}`,
  userStore: new WebStorageStateStore({ store: window.localStorage })
}

const userManager = createUserManager(userManagerConfig)

export default userManager
