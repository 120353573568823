import { Button, HStack, Heading, Text, useDisclosure } from '@chakra-ui/react'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { startInstance, status } from '../../../../helpers/instances'
import { Card } from '../../../shared/Cards'
import { Cancel, Reset, Snapshot, Start, Stop } from '../../../shared/Icons'
import { useToast } from '../../../shared/Toast'
import { useProjectVersion } from '../../../shared/hooks'
import {
  CancelModal,
  CreateSnapshotModal,
  ResetModal,
  StopModal
} from '../Modals'

const Manage = ({ instance }) => {
  const createSnapshotDisclosure = useDisclosure()
  const resetDisclosure = useDisclosure()
  const stopDisclosure = useDisclosure()
  const cancelDisclosure = useDisclosure()
  const { orgId, projectId } = useParams()
  const { isLegacy, isB2 } = useProjectVersion({ projectId, orgId })
  const toast = useToast()

  const isCreating = instance.status === status.creating
  const isActive = instance.status === status.active
  const isStarting = instance.status === status.starting
  const isStopped = instance.status === status.stopped
  const isStopping = instance.status === status.stopping
  const isShutoff = instance.status === status.shutoff
  const isResetting = instance.status === status.resetting

  const enableCreateSnapshot = isActive || (isB2 && isStopped)
  const canBeStopped =
    isActive || isStarting || isStopping || (isLegacy && isResetting)

  const handleStartInstance = useCallback(async () => {
    await startInstance({ instanceId: instance.id, projectId, orgId, toast })
  }, [instance, projectId, orgId])

  return (
    <Card>
      <Heading size="xl" mb={2}>
        Manage your Instance
      </Heading>
      <Text color="gray.500">
        Please make sure you are not running any processes that are writing to
        disk during the actions below to avoid data corruption.
      </Text>
      <HStack spacing={4} mt={8}>
        {isCreating && (
          <Button
            onClick={cancelDisclosure.onOpen}
            size="sm"
            colorScheme="offBlack"
            variant="outline"
            leftIcon={<Cancel width="12px" height="12px" />}
          >
            Cancel
          </Button>
        )}
        {(isStopped || isStarting) && (
          <Button
            onClick={handleStartInstance}
            size="sm"
            isDisabled={isStarting}
            colorScheme="offBlack"
            variant="outline"
            leftIcon={<Start width="12px" height="12px" />}
          >
            Start Instance
          </Button>
        )}
        {(enableCreateSnapshot || isStopping || isStarting) && (
          <Button
            onClick={createSnapshotDisclosure.onOpen}
            isDisabled={isStarting || isStopping}
            size="sm"
            colorScheme="offBlack"
            variant="outline"
            leftIcon={<Snapshot width="12px" height="12px" />}
          >
            Create Snapshot
          </Button>
        )}
        {(isActive || isStopping || isShutoff) && (
          <Button
            onClick={resetDisclosure.onOpen}
            size="sm"
            isDisabled={isStopping}
            colorScheme="offBlack"
            variant="outline"
            leftIcon={<Reset width="12px" height="12px" />}
          >
            Reset Instance
          </Button>
        )}
        {canBeStopped && (
          <Button
            onClick={stopDisclosure.onOpen}
            size="sm"
            isDisabled={isStopping}
            colorScheme="offBlack"
            variant="outline"
            leftIcon={<Stop width="12px" height="12px" />}
          >
            Stop Instance
          </Button>
        )}
      </HStack>
      <CreateSnapshotModal
        disclosure={createSnapshotDisclosure}
        instance={instance}
      />
      <ResetModal disclosure={resetDisclosure} instance={instance} />
      <StopModal disclosure={stopDisclosure} instance={instance} />
      <CancelModal disclosure={cancelDisclosure} instance={instance} />
    </Card>
  )
}

export default Manage
