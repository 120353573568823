import { HStack, LinkBox, LinkOverlay, Text } from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'
import { ArrowLeft } from './Icons'

export const ReturnLink = ({ to, children }) => (
  <LinkBox as={HStack} role="group" w="fit-content" cursor="pointer">
    <ArrowLeft _groupHover={{ color: 'blue.500' }} />
    <Text size="md">
      <LinkOverlay
        _groupHover={{ textDecoration: 'none', color: 'blue.500' }}
        as={ReactLink}
        to={to}
      >
        {children}
      </LinkOverlay>
    </Text>
  </LinkBox>
)
