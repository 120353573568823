import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  VStack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Link as ReactLink } from 'react-router-dom'
import { Plus } from '../../../../../shared/Icons'
import routes from '../../../../../../constants/routes'
import {
  OrganizationIdStorage,
  ProjectIdStorage
} from '../../../../../shared/storage'
import { withOrgPath } from '../../../../../../router'

export const IntroModal = ({ isVisible }) => {
  const [id, setId] = useState()
  const [projectId, setProjectId] = useState()
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (isVisible === true) {
      onOpen()
    } else if (isVisible === false) {
      onClose()
    }
  }, [isVisible])

  useEffect(() => {
    setId(OrganizationIdStorage.get())
    setProjectId(ProjectIdStorage.get())
    OrganizationIdStorage.clear()
    ProjectIdStorage.clear()
  }, [])

  if (!id) {
    return null
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Heading size="xl" mb={8} textAlign="center">
            Organization is created
          </Heading>
          <VStack spacing={8} w="100%">
            <Text size="md" color="gray.500" fontWeight="normal">
              You can now invite members to this organization.
            </Text>
            <VStack spacing={4} w="100%">
              <Button
                as={ReactLink}
                to={withOrgPath(routes.dashboard.settings, id, projectId)}
                isFullWidth
              >
                Manage Organization
              </Button>
              <Button
                as={ReactLink}
                colorScheme="offBlack"
                variant="outline"
                leftIcon={<Plus color="black" />}
                to={`${withOrgPath(
                  routes.dashboard.members,
                  id,
                  projectId
                )}?invite_members=true`}
                isFullWidth
              >
                Invite Members
              </Button>
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
