import {
  Stack,
  Heading,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { TypeCards } from '../../../../shared/RadioCard'
import {
  RESERVATION_SIZE_MIN,
  RESERVATION_DATES,
  RESERVATION_PRICES
} from '../../../../../constants/defaults'

const getTypes = (regions) => [
  {
    slug: 'gpu_nvidia',
    title: 'GPU NVIDIA®',
    types: [
      {
        slug: 'nvidia-h100-sxm5',
        regions,
        isEnabled: true,
        description: {
          name: 'NVIDIA® GeForce™ RTX 3090'
        }
      }
    ]
  },
  {
    slug: 'cpu_amd',
    title: 'CPU',
    types: [
      {
        slug: 'cpu',
        regions,
        isEnabled: true,
        description: {
          name: 'CPU'
        }
      }
    ]
  }
]

const getCategories = (regions, types) =>
  getTypes(regions).reduce((acc, item) => {
    const filteredTypes = item.types.filter(({ slug }) => types.includes(slug))

    return filteredTypes.length > 0
      ? [...acc, { ...item, types: filteredTypes }]
      : acc
  }, [])

const Type = ({ regions, types }) => {
  const { values, setValues, setTouched } = useFormikContext()

  const categories = getCategories(regions, types)

  const onChange = (type) => {
    setTouched({})
    setValues((prev) => ({
      ...prev,
      type,
      size: RESERVATION_SIZE_MIN,
      dates: RESERVATION_DATES,
      prices: RESERVATION_PRICES,
      isEmpty: true
    }))
  }

  return (
    <Stack spacing={8}>
      <Heading size="xl">Select Type</Heading>
      <Tabs>
        <TabList>
          {categories?.map((category) => (
            <Tab key={category.slug}>{category.title}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {categories?.map((category) => (
            <TabPanel key={category.slug}>
              <TypeCards
                types={category.types}
                activeType={values.type}
                onChange={onChange}
              />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Stack>
  )
}

export default Type
