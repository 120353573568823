import { Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { SkeletonTable } from '../../../shared/Skeletons'
import {
  INITIAL_PAGE,
  TableCell,
  TableError,
  TablePagination
} from '../../../shared/Table'
import { useOrgFilesystems, useAccountFilesystems } from '../../../shared/hooks'
import TableRow from './table-row'

const List = ({ hasPermission }) => {
  const { orgId, projectId } = useParams()
  const [page, setPage] = useState(INITIAL_PAGE)

  const { filesystems, pagination, isLoadingFilesystems, isErrorFilesystems } =
    orgId && projectId
      ? useOrgFilesystems({ orgId, projectId, page })
      : useAccountFilesystems({ page })

  const isTableEmpty = filesystems?.length === 0

  return (
    <>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th w="15%">Name</Th>
              <Th w="15%">Location</Th>
              <Th w="25%">Description</Th>
              <Th w="10%">Type</Th>
              <Th w="10%">Size</Th>
              <Th w="20%">Created</Th>
              <Th w="5%" />
            </Tr>
          </Thead>
          {isLoadingFilesystems && <SkeletonTable rows={5} columns={7} />}
          {isErrorFilesystems ? (
            <TableError>
              We could not load the Filesystems. Please try again.
            </TableError>
          ) : (
            <Tbody>
              {isTableEmpty ? (
                <TableCell>There are no Filesystems yet.</TableCell>
              ) : (
                filesystems?.map((filesystem) => (
                  <TableRow
                    key={filesystem.id}
                    row={filesystem}
                    hasPermission={hasPermission}
                  />
                ))
              )}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        page={page}
        setPage={setPage}
        perPage={pagination?.perPage}
        totalCount={pagination?.totalCount}
      />
    </>
  )
}

export default List
