import { Heading, Link, Stack, Text } from '@chakra-ui/react'
import { externalLinks } from '../../../helpers/links'
import { AlertInfo } from '../../shared/Alert'
import Footer from '../../shared/Footer'
import List from './list'

const Alert = () => (
  <AlertInfo>
    <Text color="blue.500">
      Learn how{' '}
      <Link href={externalLinks.snapshotsUploadCustomImage} isExternal>
        to upload custom images using the API.
      </Link>
    </Text>
  </AlertInfo>
)

const Snapshots = () => (
  <Stack spacing={8}>
    <Heading as="h1" size="4xl">
      Snapshots
    </Heading>
    <Alert />
    <List />
    <Footer justify="start">
      <Link href={externalLinks.snapshots} isExternal>
        Learn more about Snapshots
      </Link>
    </Footer>
  </Stack>
)

export default Snapshots
