import {
  Box,
  Grid,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
  useRadio,
  useRadioGroup
} from '@chakra-ui/react'
import { RadioCardBadge, getBadgeByEnabledAccount } from './Badge'
import { Radio, RadioChecked } from './Icons'
import { RegionBadge, RegionTag, regionsIcons } from './Region'

export const TypeCards = ({ activeType, types, onChange }) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    value: activeType,
    defaultValue: activeType,
    onChange: (value) => onChange(value)
  })

  return (
    <SimpleGrid columns={2} gap={3} {...getRootProps()}>
      {types?.map((type) => {
        const nameColor = type.slug === activeType ? 'blue.500' : 'gray.900'
        const subtitleColor = type.slug === activeType ? 'blue.500' : 'gray.500'
        const badge = getBadgeByEnabledAccount({
          isEnabled: type.isEnabledAccount,
          labels: type.labels
        })

        return (
          <HRadioCard
            key={type.slug}
            {...getRadioProps({
              value: type.slug,
              isDisabled: !type.isEnabled,
              badge
            })}
          >
            <Stack spacing={0} flexGrow={1}>
              <Text fontWeight={600} color={nameColor}>
                {type.description.name}
              </Text>
              {type.description.subtitle && (
                <Text color={subtitleColor}>{type.description.subtitle}</Text>
              )}
              <HStack pt={1}>
                {type.regions.map((region) => (
                  <RegionTag key={region} slug={region} />
                ))}
              </HStack>
            </Stack>
          </HRadioCard>
        )
      })}
    </SimpleGrid>
  )
}

export const RegionCards = ({
  activeRegion,
  lgScreenSize = 'repeat(5, 1fr)',
  regions,
  onChange
}) => {
  const template = useBreakpointValue({
    base: 'repeat(4, 1fr)',
    '2xl': lgScreenSize
  })
  const { getRootProps, getRadioProps } = useRadioGroup({
    value: activeRegion,
    defaultValue: activeRegion,
    onChange: (value) => onChange(value)
  })

  return (
    <Grid templateColumns={template} gap={5} {...getRootProps()}>
      {regions?.map(({ title, slug, flag, enabled, labels }) => {
        const badge = getBadgeByEnabledAccount({
          isEnabled: enabled,
          labels: labels
        })

        const props = getRadioProps({
          value: slug,
          isDisabled: !enabled,
          badge
        })

        return (
          <VRadioCard key={slug} {...props}>
            <RegionBadge
              color={slug === activeRegion ? 'blue.500' : 'gray.500'}
              slug={slug}
              direction="column"
              Icon={regionsIcons[flag]}
              title={title}
              size={10}
              spacing={0}
            />
            {props.isChecked ? <RadioChecked /> : <Radio />}
          </VRadioCard>
        )
      })}
    </Grid>
  )
}

export const HRadioCard = ({ hasRadioAtRight = false, ...props }) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  return (
    <Box as="label" mb={0} position="relative">
      <input {...getInputProps()} />
      {Boolean(props.badge) && (
        <RadioCardBadge title={props.badge} isDisabled={props.isDisabled} />
      )}
      <Stack
        {...getCheckboxProps()}
        px={4}
        py={3}
        h="full"
        direction={hasRadioAtRight ? 'row-reverse' : 'row'}
        spacing={3}
        align="flex-start"
        border="1px"
        borderColor={props.isChecked ? 'blue.500' : 'gray.300'}
        borderRadius="10px"
        cursor="pointer"
        _hover={{
          borderColor: 'blue.500'
        }}
        _focus={{
          boxShadow: 'outline'
        }}
        _disabled={{
          borderColor: 'gray.500',
          opacity: 0.4,
          cursor: 'not-allowed'
        }}
      >
        {props.isChecked ? <RadioChecked mt={1} /> : <Radio mt={1} />}
        <HStack spacing={3} width="calc(100% - 32px)">
          {props.children}
        </HStack>
      </Stack>
    </Box>
  )
}

export const VRadioCard = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  return (
    <Box as="label" mb={0} position="relative">
      <input {...getInputProps()} />
      {Boolean(props.badge) && (
        <RadioCardBadge title={props.badge} isDisabled={props.isDisabled} />
      )}
      <VStack
        {...getCheckboxProps()}
        p={3}
        border="1px"
        borderColor={props.isChecked ? 'blue.500' : 'gray.300'}
        borderRadius="10px"
        cursor="pointer"
        _hover={{
          borderColor: 'blue.500'
        }}
        _focus={{
          boxShadow: 'outline'
        }}
        _disabled={{
          borderColor: 'gray.500',
          opacity: 0.4,
          cursor: 'not-allowed'
        }}
      >
        {props.children}
      </VStack>
    </Box>
  )
}
