import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react'
import { setIsVerified } from '../../../../../../slices/ui/phoneVerification'
import CodeForm from '../../../../../shared/PhoneVerify/CodeForm'
import PhoneForm from '../../../../../shared/PhoneVerify/PhoneForm'

export const PhoneModal = ({ visible, onVisibilityChange }) => {
  const isVerified = useSelector(
    (state) => state.ui.phoneVerification.isVerified
  )
  const dispatch = useDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (visible === true) {
      onOpen()
    } else if (visible === false) {
      onClose()
    }
  }, [visible])

  const handleClose = useCallback(() => {
    onClose()
    dispatch(setIsVerified({ isVerified: false }))
    if (onVisibilityChange) {
      onVisibilityChange(false)
    }
  }, [onClose, onVisibilityChange])

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Heading size="xl" mb={4} textAlign="center">
              Verify your phone number
            </Heading>
            {isVerified ? <CodeForm onVerify={handleClose} /> : <PhoneForm />}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
