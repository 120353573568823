import {
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { SkeletonTable } from '../../../../../shared/Skeletons'
import {
  INITIAL_PAGE,
  TableCell,
  TableError,
  TablePagination
} from '../../../../../shared/Table'
import {
  useIsRole,
  useOrganizationInvitations,
  useOrganizationWithId
} from '../../../../../shared/hooks/organization'
import TableRow from './table-row'

const InvitationsList = () => {
  const { orgId } = useParams()
  const [page, setPage] = useState(INITIAL_PAGE)
  const { organization } = useOrganizationWithId(orgId)
  const { invitations, isError, isLoading } = useOrganizationInvitations({
    orgId,
    page
  })
  const userIsAdmin = useIsRole(organization, 'ADMIN')
  const isTableEmpty = invitations?.invitations.length === 0

  return (
    <Stack>
      <Heading size="xl">Pending invitations</Heading>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th w="40%">Name</Th>
              <Th w="10%">Role</Th>
              <Th w="10%">2FA</Th>
              <Th w="10%">Status</Th>
              <Th w="10%">Invitation created</Th>
              {userIsAdmin && <Th w="20%" />}
            </Tr>
          </Thead>
          {isLoading ? (
            <SkeletonTable rows={3} columns={5} />
          ) : (
            <Tbody>
              {isTableEmpty ? (
                <TableCell>
                  There are no pending invitations for this Organization yet.
                </TableCell>
              ) : (
                invitations?.invitations?.map((invitation) => (
                  <TableRow key={invitation.id} invitation={invitation} />
                ))
              )}
            </Tbody>
          )}
          {isError && (
            <TableError>
              Something went wrong loading the invitations.
            </TableError>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        page={page}
        setPage={setPage}
        totalCount={invitations?.totalCount}
      />
    </Stack>
  )
}

export default InvitationsList
