import { Badge, Box } from '@chakra-ui/react'

export const ActiveBadge = ({ ...props }) => (
  <Badge variant="outline" colorScheme="green.700" {...props}>
    ACTIVE
  </Badge>
)

export const StoppedBadge = ({ ...props }) => (
  <Badge variant="outline" colorScheme="gray.300" {...props}>
    STOPPED
  </Badge>
)

export const ErrorBadge = ({ ...props }) => (
  <Badge variant="outline" colorScheme="red.500" {...props}>
    ERROR
  </Badge>
)

export const PendingBadge = ({ ...props }) => (
  <Badge variant="outline" colorScheme="blue.500" {...props}>
    PENDING
  </Badge>
)

export const PendingPaymentBadge = ({ ...props }) => (
  <Badge variant="outline" colorScheme="blue.500" {...props}>
    PENDING PAYMENT
  </Badge>
)

export const EndingBadge = ({ ...props }) => (
  <Badge variant="outline" colorScheme="offBlack.600" {...props}>
    ENDING
  </Badge>
)

export const EndedBadge = ({ ...props }) => (
  <Badge variant="outline" colorScheme="red.500" {...props}>
    ENDED
  </Badge>
)

export const ExpiredBadge = ({ ...props }) => (
  <Badge variant="outline" colorScheme="red.500" {...props}>
    EXPIRED
  </Badge>
)

export const CanceledBadge = ({ ...props }) => (
  <Badge variant="outline" colorScheme="red.500" {...props}>
    CANCELED
  </Badge>
)

export const ScheduledBadge = ({ ...props }) => (
  <Badge variant="outline" colorScheme="blue.500" {...props}>
    SCHEDULED
  </Badge>
)

// TODO: This is way too long
export const PendingDeleteBadge = ({ ...props }) => (
  <Badge variant="outline" colorScheme="blue.500" {...props}>
    PENDING DELETE
  </Badge>
)

export const NotCompatibleBadge = ({ ...props }) => (
  <Badge variant="solid" colorScheme="gray.300" {...props}>
    NOT COMPATIBLE
  </Badge>
)

export const BetaBadge = ({ ...props }) => (
  <Badge variant="solid" colorScheme="blue" {...props}>
    BETA
  </Badge>
)

export const LegacyBadge = ({ ...props }) => (
  <Badge
    variant="outline"
    colorScheme="offBlack.600"
    fontSize="10px"
    {...props}
  >
    LEGACY
  </Badge>
)

export const DefaultBadge = ({ ...props }) => (
  <Badge variant="outline" colorScheme="blue.500" fontSize="10px" {...props}>
    DEFAULT
  </Badge>
)

export const PaidBadge = ({ ...props }) => (
  <Badge variant="outline" colorScheme="green.700" {...props}>
    Paid
  </Badge>
)

export const FailedBadge = ({ ...props }) => (
  <Badge variant="outline" colorScheme="red.500" {...props}>
    Failed
  </Badge>
)

export const getBadgeByEnabledAccount = ({ isEnabled, labels }) => {
  let title

  if (isEnabled && labels?.includes('new')) {
    title = 'new'
  } else if (!isEnabled && labels?.includes('soon')) {
    title = 'soon'
  }

  return title
}

const titleColors = {
  new: 'blue.500',
  soon: 'offBlack.600',
  beta: 'blue.500',
  'not compatible': 'red.500'
}

export const RadioCardBadge = ({ title, isDisabled }) => (
  <Box
    position="absolute"
    top={-1.5}
    right={-1.5}
    zIndex={10}
    bg="white"
    borderRadius="4px"
    lineHeight="18px"
  >
    <Badge
      colorScheme={titleColors[title]}
      opacity={isDisabled ? 0.4 : 1}
      fontWeight="600"
    >
      {title}
    </Badge>
  </Box>
)
