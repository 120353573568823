import {
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { SkeletonTable } from '../../../../shared/Skeletons'
import {
  INITIAL_PAGE,
  TableCell,
  TableError,
  TablePagination
} from '../../../../shared/Table'
import { useAccountAPITokens, useOrgAPITokens } from '../../../../shared/hooks'
import TableRow from './table-row'

const List = ({ hasPermission }) => {
  const { orgId, projectId } = useParams()
  const [page, setPage] = useState(INITIAL_PAGE)

  const { apiTokens, pagination, isLoadingAPITokens, isErrorAPITokens } =
    orgId && projectId
      ? useOrgAPITokens({ orgId, projectId, page })
      : useAccountAPITokens({ page })
  const isTableEmpty = apiTokens?.length === 0

  return (
    <Stack spacing={8}>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th w="20%">Access key</Th>
              <Th w="10%">Type</Th>
              <Th w="20%">Description</Th>
              <Th w="15%">Created by</Th>
              <Th w="15%">Created</Th>
              <Th w="15%">Last accessed</Th>
              <Th w="5%" />
            </Tr>
          </Thead>
          {isLoadingAPITokens && <SkeletonTable rows={10} columns={5} />}
          {isErrorAPITokens ? (
            <TableError>
              Something went wrong loading the API Tokens.
            </TableError>
          ) : (
            <Tbody>
              {isTableEmpty ? (
                <TableCell>There are no API Tokens yet.</TableCell>
              ) : (
                apiTokens?.map((token) => (
                  <TableRow
                    key={token.accessKey}
                    token={token}
                    hasPermission={hasPermission}
                  />
                ))
              )}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      {!isErrorAPITokens && (
        <TablePagination
          page={page}
          setPage={setPage}
          totalCount={pagination?.totalCount}
        />
      )}
    </Stack>
  )
}

export default List
