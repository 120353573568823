import {
  Stack,
  Grid,
  Text,
  FormControl,
  FormErrorMessage
} from '@chakra-ui/react'
import { Card } from '../../../../shared/Cards'
import { useFormikContext } from 'formik'
import { PasswordField, CopyPassword } from '../../../../shared/Fields'

const Password = ({ name }) => {
  const { values, errors, setValues } = useFormikContext()

  const onCopy = (hasCopied) =>
    setValues((prev) => ({
      ...prev,
      authentication: { ...prev.authentication, hasCopied }
    }))

  const onChange = (password) =>
    setValues((prev) => ({
      ...prev,
      authentication: { ...prev.authentication, password, hasCopied: false }
    }))

  const passwordError = errors?.authentication?.password
  const hasCopiedError = errors?.authentication?.hasCopied

  return (
    <FormControl isInvalid={passwordError || hasCopiedError}>
      <Card
        as={Stack}
        spacing={8}
        p={5}
        hasError={passwordError || hasCopiedError}
      >
        <Text color="gray.500">
          Note: The password will only be displayed once. Please copy and save
          it securely.
        </Text>
        <Grid templateColumns="2fr 1fr 1fr" alignItems="end" gap={3}>
          <PasswordField name={name} onChange={onChange} />
          <CopyPassword
            value={values.authentication.password}
            onChange={onCopy}
          />
        </Grid>
      </Card>
      <FormErrorMessage>{passwordError ?? hasCopiedError}</FormErrorMessage>
    </FormControl>
  )
}

export default Password
