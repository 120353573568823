import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './styles.scss'

import CustomInput from './input'

const DatePicker = (props) => (
  <div className="date-picker-lg">
    <ReactDatePicker
      showPopperArrow={false}
      focusSelectedMonth
      disabledKeyboardNavigation
      customInput={<CustomInput />}
      dateFormat={'dd/MM/yyyy'}
      {...props}
    />
  </div>
)

export default DatePicker
