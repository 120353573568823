import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { calcResourcesPrices, slugs } from '../../../../helpers/billing'
import { formatCurrency } from '../../../../helpers/currency'
import { stopInstance } from '../../../../helpers/instances'
import Price from '../../../shared/Price'
import { useToast } from '../../../shared/Toast'
import { useAccountFF, useConfigs, useOrgFF } from '../../../shared/hooks'
import { useIsMounted } from '../../../shared/hooks/useIsMounted'

const PriceSection = ({ storage }) => {
  const { configs, isLoadingConfigs, isErrorConfigs } = useConfigs({})

  if (isLoadingConfigs || isErrorConfigs) {
    return null
  }

  const { price, discount, totalPrice } = calcResourcesPrices({
    types: configs?.storageTypes,
    typeSlug: 'boot_volume',
    configSlug: 'boot_volume_storage',
    storage
  })

  const items = [
    { label: 'Price', value: `${formatCurrency(price)}/GiB/month` },
    { label: 'Boot Volume size', value: `${storage} GiB` },
    ...(discount ? [{ label: 'Discount', value: `${discount}% off` }] : []),
    {
      label: 'Total cost (excl. VAT)',
      value: `${formatCurrency(totalPrice)}/month`
    }
  ]

  return <Price items={items} />
}

export const StopModal = ({ disclosure, instance }) => {
  const { orgId, projectId } = useParams()
  const { isOpen, onClose } = disclosure
  const { name: instanceName, billingType } = instance
  const isOnDemand = billingType === slugs.onDemand

  const [isSubmitting, setIsSubmitting] = useState(false)
  const isMounted = useIsMounted()
  const toast = useToast()
  const { featureFlag } = orgId ? useOrgFF({ orgId }) : useAccountFF({})
  const { enableExtendedBilling } = featureFlag || {}

  const handleStopInstance = useCallback(async () => {
    setIsSubmitting(true)
    await stopInstance({ instanceId: instance.id, projectId, orgId, toast })

    if (isMounted()) {
      setIsSubmitting(false)
      onClose()
    }
  }, [instance, projectId, orgId])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap={8}>
            <Heading size="xl" textAlign="center">
              Stop Instance “{instanceName}”
            </Heading>
            <Text
              size="md"
              textAlign="center"
              color="red.500"
              fontWeight="normal"
            >
              {enableExtendedBilling && isOnDemand
                ? 'While instances are stopped, storage charges apply, based on allocated space. To avoid costs, consider destroying instances when not in use.'
                : 'This will end all running processes and stop the Instance.'}
            </Text>
            {enableExtendedBilling && isOnDemand && (
              <PriceSection storage={instance.storage} />
            )}
            <Stack spacing={4}>
              <Button isLoading={isSubmitting} onClick={handleStopInstance}>
                Stop
              </Button>
              <Button
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isSubmitting}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Stack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
