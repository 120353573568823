import { Skeleton, Stack } from '@chakra-ui/react'

const Loading = () => (
  <Stack spacing={12} pt={4} w="100%">
    {Array.from({ length: 3 }).map((_, index) => (
      <Stack key={index} spacing={6}>
        <Skeleton height={4} />
        <Skeleton height={4} />
        <Skeleton height={4} />
        <Skeleton height={4} />
        <Skeleton height={4} />
      </Stack>
    ))}
  </Stack>
)

export default Loading
