import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import * as yup from 'yup'
import { AccountSnapshots, OrgSnapshots } from '../../../../api/images'
import { toastMessages } from '../../../../constants/toast-messages'
import Asterisk from '../../../shared/Asterisk'
import { useToast } from '../../../shared/Toast'

const RenameSnapshotModal = ({ disclosure, snapshot }) => {
  const { orgId, projectId } = useParams()
  const toast = useToast()
  const { isOpen, onClose } = disclosure

  const schema = yup.object().shape({
    name: yup.string().required('Snapshot name is required')
  })

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      const { name } = values
      setSubmitting(true)
      try {
        const snapshotId = snapshot.id
        const response = orgId
          ? await OrgSnapshots.renameById({
              orgId,
              projectId,
              snapshotId,
              name
            })
          : await AccountSnapshots.renameById({ snapshotId, name })

        if (response.ok) {
          orgId
            ? await mutate((key) =>
                key.startsWith(`/projects/${projectId}/snapshots?`)
              )
            : await mutate((key) => key.startsWith(`/snapshots?`))
        }

        onClose()
        toast({
          status: 'success',
          message: toastMessages.snapshotRenameSuccess
        })
      } catch {
        toast({
          status: 'error',
          message: toastMessages.snapshotRenameError
        })
      } finally {
        setSubmitting(false)
      }
    },
    [snapshot, orgId, projectId, onClose]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Heading size="xl" mb={8} textAlign="center">
            Rename Snapshot
          </Heading>
          <Formik
            onSubmit={handleSubmit}
            initialValues={{
              name: snapshot.name
            }}
            validationSchema={schema}
          >
            {({
              handleSubmit,
              errors,
              touched,
              isSubmitting,
              values,
              setFieldValue,
              setFieldTouched
            }) => (
              <Form onSubmit={handleSubmit}>
                <FormControl isInvalid={errors.name && touched.name} mb={8}>
                  <FormLabel>
                    <Asterisk />
                    Name
                  </FormLabel>
                  <Field name="name">
                    {({ field }) => (
                      <Input
                        autoFocus
                        value={field.value}
                        onChange={(e) => {
                          setFieldTouched(field.name)
                          setFieldValue(field.name, e.target.value)
                        }}
                      />
                    )}
                  </Field>
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                </FormControl>
                <Button
                  type="submit"
                  isFullWidth
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Save
                </Button>
                <Button
                  mt={4}
                  isFullWidth
                  variant="outline"
                  colorScheme="offBlack"
                  isDisabled={isSubmitting}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default RenameSnapshotModal
