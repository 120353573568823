import { Fragment } from 'react'
import {
  Stack,
  Grid,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Link
} from '@chakra-ui/react'
import Asterisk from '../../../../shared/Asterisk'
import { Select } from 'chakra-react-select'
import { SelectComponents } from '../../../../../theme/components/Select'
import { Card } from '../../../../shared/Cards'
import { useFormikContext } from 'formik'
import {
  PasswordField,
  CopyPassword,
  RegeneratePassword
} from '../../../../shared/Fields'

const CatalogBody = ({ items, selected, onChange }) => {
  const { values, errors, setValues } = useFormikContext()

  const onRegenerate = (password) =>
    setValues((prev) => ({
      ...prev,
      catalog: {
        ...prev.catalog,
        password
      }
    }))

  const passwordError = errors?.catalog?.password

  const catalogs = items?.map(({ name, images, fields, externalUrl }) => (
    <Fragment key={name}>
      <FormControl isInvalid={passwordError}>
        <Card as={Stack} spacing={8} hasError={passwordError}>
          {images?.length > 0 && (
            <Grid templateColumns="1fr 1fr" gap={3}>
              <FormControl>
                <FormLabel>
                  <Asterisk />
                  Base Image
                </FormLabel>
                <Select
                  isSearchable={false}
                  chakraStyles={SelectComponents}
                  options={images}
                  value={selected[name]}
                  onChange={(image) => onChange(image, name)}
                />
              </FormControl>
            </Grid>
          )}
          {fields.map(
            ({ type, key, label }) =>
              type === 'password' && (
                <Grid
                  templateColumns="2fr 1fr 1fr"
                  alignItems="end"
                  gap={3}
                  key={key}
                >
                  <PasswordField name="catalog.password" label={label} />
                  <CopyPassword value={values.catalog.password} />
                  <RegeneratePassword onChange={onRegenerate} />
                </Grid>
              )
          )}
        </Card>
        <FormErrorMessage>{passwordError}</FormErrorMessage>
      </FormControl>
      {externalUrl && (
        <Link href={externalUrl} isExternal>
          Read more about {name}.
        </Link>
      )}
    </Fragment>
  ))

  return catalogs
    ? catalogs.filter(({ key }) => key === values.catalog.name)
    : null
}

export default CatalogBody
