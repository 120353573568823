const Radio = {
  parts: ['control', 'container'],
  baseStyle: {
    control: {
      alignSelf: 'baseline',
      mt: 1
    },
    container: {
      m: 0
    }
  },
  defaultProps: {
    colorScheme: 'blue'
  }
}

export default Radio
