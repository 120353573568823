import { useCallback } from 'react'
import { mutate } from 'swr'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import { updatePath } from '../../../../helpers/organizations'
import { withOrgPath } from '../../../../router'
import { useOrgParam } from '../useOrgParam'

export function useSwitchProject() {
  const history = useHistory()
  const location = useLocation()
  const orgId = useOrgParam()

  const switchFn = useCallback(
    (projectId, route = undefined) => {
      if (route) {
        history.push(withOrgPath(route, orgId, projectId))
      } else {
        history.push(
          generatePath(updatePath(location.pathname), { orgId, projectId })
        )
      }
      mutate((key) => key.startsWith(`/products-configs`))
    },
    [orgId, location.pathname]
  )

  return switchFn
}
