import { useMemo } from 'react'
import { useParams, Link as ReactLink } from 'react-router-dom'
import { Stack, Text, Link, Box, Skeleton } from '@chakra-ui/react'
import { Error } from '../../../../shared/Error'
import { useFormikContext } from 'formik'
import {
  useOrgFloatingIPs,
  useAccountFloatingIPs
} from '../../../../shared/hooks/floatingIPs'
import { withOrgPathFallback } from '../../../../../router'
import routes from '../../../../../constants/routes'
import { FloatingIPCard } from '../RadioCards'
import { FloatingIPField } from '../Fields'

const NoData = () => (
  <Stack spacing={0}>
    <Text>Assign Floating IP</Text>
    <Text color="red.500">
      There are no Floating IPs.{' '}
      <Link
        as={ReactLink}
        colorScheme="red"
        to={`${withOrgPathFallback(
          routes.dashboard.floatingIPs.index
        )}?create=true`}
      >
        Create a new Floating IP
      </Link>
    </Text>
  </Stack>
)

const AssignFloatingIP = ({ floatingIps }) => {
  const noData = floatingIps?.length === 0

  return noData ? (
    <NoData />
  ) : (
    <FloatingIPField name="options.floatingIP" floatingIps={floatingIps} />
  )
}

const FloatingIP = () => {
  const { values, setValues } = useFormikContext()
  const isChecked = values.options.isFloatingIPChecked

  const { orgId, projectId } = useParams()
  const { floatingIps, isLoading, isError } =
    orgId && projectId
      ? useOrgFloatingIPs({ orgId, projectId, perPage: 999 })
      : useAccountFloatingIPs({ perPage: 999 })

  const filteredFloatingIPs = useMemo(
    () =>
      floatingIps?.filter(
        ({ instance, status }) => instance === null && status === 'created'
      ),
    [floatingIps]
  )

  const onChange = (event) => {
    const isFloatingIPChecked = event.target.checked

    setValues((prev) => ({
      ...prev,
      options: {
        ...prev.options,
        isFloatingIPChecked,
        floatingIP: ''
      }
    }))
  }

  return (
    <FloatingIPCard isChecked={isChecked} onChange={onChange}>
      <Box pl={8} w="385px">
        {isLoading ? (
          <Skeleton h="55px" />
        ) : isError ? (
          <Error />
        ) : (
          <AssignFloatingIP floatingIps={filteredFloatingIPs} />
        )}
      </Box>
    </FloatingIPCard>
  )
}

export default FloatingIP
