export const status = {
  creating: 'creating',
  created: 'created',
  pendingDelete: 'pending_delete',
  error: 'error', // legacy
  deleting: 'deleting'
}

export const types = {
  ssd: 'ssd',
  hdd: 'hdd'
}
