import { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Stack,
  Heading,
  Text,
  Button
} from '@chakra-ui/react'
import { mutate } from 'swr'
import {
  OrgCommunityInstances,
  AccountCommunityInstances
} from '../../../../api/communityInstances'
import { toastMessages } from '../../../../constants/toast-messages'
import { useToast } from '../../../shared/Toast'
import { useIsMounted } from '../../../shared/hooks/useIsMounted'

export const DeleteModal = ({ instance, disclosure }) => {
  const { id: instanceId, name: instanceName } = instance
  const { isOpen, onClose } = disclosure
  const [isSubmitting, setIsSubmitting] = useState()
  const { orgId, projectId } = useParams()
  const isMounted = useIsMounted()
  const toast = useToast()

  const handleSubmit = async () => {
    setIsSubmitting(true)
    try {
      if (orgId) {
        await OrgCommunityInstances.deleteById({
          orgId,
          projectId,
          instanceId
        })
        await mutate(
          `/projects/${projectId}/community/valdi/instances/${instanceId}?org=${orgId}`
        )
      } else {
        await AccountCommunityInstances.deleteById({ instanceId })
        await mutate(`/community/valdi/instances/${instanceId}`)
      }

      toast({
        status: 'success',
        message: toastMessages.saved
      })
      onClose()
    } catch {
      toast({
        status: 'error',
        message: toastMessages.notSaved
      })
    } finally {
      if (isMounted()) {
        setIsSubmitting(false)
      }
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={8}>
            <Heading size="xl" textAlign="center">
              Are you sure you want to delete Instance “{instanceName}”?
            </Heading>
            <Text
              size="md"
              textAlign="center"
              color="red.500"
              fontWeight="normal"
            >
              This action will permanently delete the Instance and it cannot be
              recovered.
            </Text>
            <Stack spacing={4}>
              <Button
                colorScheme="red"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                onClick={handleSubmit}
              >
                Delete Instance
              </Button>
              <Button
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isSubmitting}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
