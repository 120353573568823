import {
  Box,
  Stack,
  Flex,
  Grid,
  SimpleGrid,
  Icon,
  Text,
  Tooltip,
  Link,
  Checkbox,
  useBreakpointValue,
  useRadioGroup
} from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { SelectComponents } from '../../../../theme/components/Select'
import { HRadioCard, VRadioCard } from '../../../shared/RadioCard'
import {
  Radio,
  RadioChecked,
  Ubuntu,
  TensorFlow,
  PyTorch,
  CloudImages,
  Docker,
  JupyterLab,
  Snapshots
} from '../../../shared/Icons'
import { externalLinks } from '../../../../helpers/links'

const Icons = {
  Ubuntu,
  TensorFlow,
  PyTorch,
  Docker,
  JupyterLab
}

export const ImageCards = ({
  items,
  value,
  onChange,
  selectedImages,
  imageTab,
  ...props
}) => {
  const template = useBreakpointValue({
    base: 'repeat(4, 1fr)',
    '2xl': 'repeat(5, 1fr)'
  })

  const valueFamily = items?.find(({ versions }) =>
    versions.find((version) => version.value === value)
  )?.family

  const radioGroupValue = valueFamily ? `${imageTab}-${valueFamily}` : ''

  const onRadioChange = (radioValue) => {
    const family = radioValue.split('-').pop()
    onChange(selectedImages[family], family)
  }

  const { getRootProps, getRadioProps } = useRadioGroup({
    value: radioGroupValue,
    onChange: onRadioChange
  })

  const onVersionSelect = (image, family) => onChange(image, family)

  const versionsBadge = (family) =>
    selectedImages[family]?.beta ? 'beta' : undefined

  return (
    <Grid templateColumns={template} gap={5} {...getRootProps()} {...props}>
      {items?.map(({ family, badge, isDisabled, tab, versions }) => {
        const props = getRadioProps({
          value: `${tab}-${family}`,
          badge: badge ?? versionsBadge(family),
          isDisabled
        })
        const color = props.isChecked ? 'blue.500' : 'gray.500'

        return (
          <VRadioCard key={family} {...props}>
            {tab === 'cloudImages' ? (
              <CloudImages color={color} width="40px" height="40px" />
            ) : (
              <Icon
                as={Icons[family]}
                color={color}
                width="40px"
                height="40px"
              />
            )}
            <Text color={color}>{family}</Text>
            {props.isChecked ? <RadioChecked /> : <Radio />}
            <Box w="100%" onClick={(e) => e.preventDefault()}>
              <Select
                size="sm"
                isSearchable={false}
                chakraStyles={SelectComponents}
                isDisabled={isDisabled}
                options={versions}
                value={selectedImages[family]}
                onChange={(version) => onVersionSelect(version, family)}
              />
            </Box>
          </VRadioCard>
        )
      })}
    </Grid>
  )
}

export const SnapshotCards = ({ items, value, onChange, ...props }) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    value,
    onChange: (id) => {
      const name = items?.find((item) => item.id === id)?.name
      onChange({ id, name })
    }
  })

  return (
    <SimpleGrid columns={3} gap={3} {...getRootProps()} {...props}>
      {items?.map(({ id, name }) => {
        const color = id === value ? 'blue.500' : 'gray.900'
        const nameElement = (
          <Text fontWeight={600} color={color} noOfLines={1}>
            {name}
          </Text>
        )

        return (
          <HRadioCard
            key={id}
            {...getRadioProps({
              value: id
            })}
            hasRadioAtRight
          >
            <Snapshots color={color} />
            {name.length > 20 ? (
              <Tooltip hasArrow placement="top" label={name}>
                {nameElement}
              </Tooltip>
            ) : (
              nameElement
            )}
          </HRadioCard>
        )
      })}
    </SimpleGrid>
  )
}

export const CatalogCards = ({ items, value, onChange, ...props }) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    value,
    onChange: (catalogId) => {
      const { id, name } = items?.find(({ id }) => id === catalogId)
      onChange({ id, name })
    }
  })

  return (
    <SimpleGrid columns={2} gap={3} {...getRootProps()} {...props}>
      {items?.map(({ id, name }) => {
        const color = id === value ? 'blue.500' : 'gray.900'
        return (
          <HRadioCard
            key={id}
            {...getRadioProps({
              value: id
            })}
            hasRadioAtRight
          >
            <Icon as={Icons[name]} color={color} width="24px" height="24px" />
            <Text fontWeight={600} color={color}>
              {name}
            </Text>
          </HRadioCard>
        )
      })}
    </SimpleGrid>
  )
}

export const AuthenticationCards = ({ items, value, onChange, ...props }) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    value,
    onChange
  })

  return (
    <SimpleGrid columns={2} gap={3} {...getRootProps()} {...props}>
      {items?.map(({ slug, name, description }) => {
        const color = slug === value ? 'blue.500' : 'gray.900'
        return (
          <HRadioCard
            key={slug}
            {...getRadioProps({
              value: slug
            })}
          >
            <Stack spacing={0} flexGrow={1}>
              <Text fontWeight={600} color={color}>
                {name}
              </Text>
              <Text color="gray.500">{description}</Text>
            </Stack>
          </HRadioCard>
        )
      })}
    </SimpleGrid>
  )
}

const CheckboxCard = ({
  isChecked,
  onChange,
  isDisabled,
  children,
  customBody = null
}) => (
  <Stack
    as="label"
    mb={0}
    p={5}
    spacing={5}
    border="1px"
    borderRadius="10px"
    borderColor={isChecked ? 'blue.500' : 'gray.300'}
    opacity={isDisabled ? 0.4 : 1}
    cursor={isDisabled ? 'not-allowed' : 'pointer'}
    userSelect={isDisabled ? 'none' : 'auto'}
    _hover={{
      borderColor: isDisabled ? 'gray.300' : 'blue.500'
    }}
  >
    <Flex gap={3}>
      <Checkbox
        isChecked={isChecked}
        onChange={onChange}
        isDisabled={isDisabled}
        isInvalid={false}
        size="lg"
        mb={0}
      />
      <Stack spacing={1} flexGrow={1}>
        {children}
      </Stack>
    </Flex>
    {isChecked && customBody}
  </Stack>
)

export const StaticIPCard = (props) => (
  <CheckboxCard {...props}>
    <Text fontWeight={600}>Static Public IP</Text>
    <Text color="gray.500" size="sm">
      The IP address assigned when creating the instance will not change when
      stopping and starting the instance. Read more about{' '}
      <Link href={externalLinks.staticPublicIP} isExternal>
        using Static Public IP.
      </Link>
    </Text>
  </CheckboxCard>
)

export const FloatingIPCard = ({ children, ...props }) => (
  <CheckboxCard {...props} customBody={children}>
    <Text fontWeight={600}>Floating IP</Text>
    <Text color="gray.500" size="sm">
      Floating IP address points to one of your Instances. It allows you to
      redirect network traffic to any of your Instances in the same datacenter.
    </Text>
  </CheckboxCard>
)

export const ScriptsCard = ({ children, ...props }) => (
  <CheckboxCard {...props} customBody={children}>
    <Text fontWeight={600}>Add Initialization scripts</Text>
    <Text color="gray.500" size="sm">
      Add scripts to run on initial Instance boot up - great for repetitive or
      initialization tasks.
    </Text>
  </CheckboxCard>
)
