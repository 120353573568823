import {
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Td,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import { Fragment } from 'react'
import { Link as ReactLink } from 'react-router-dom'
import routes from '../../../../../constants/routes'
import { HIDDEN_SECURITY_GROUP } from '../../../../../constants/security-groups'
import { formatDateTime } from '../../../../../helpers/date'
import {
  filterRulesByDirection,
  rulesDirections,
  status
} from '../../../../../helpers/securityGroups'
import { withOrgPathFallback } from '../../../../../router'
import { ErrorBadge } from '../../../../shared/Badge'
import { Bin, Dots, Settings } from '../../../../shared/Icons'
import Region from '../../../../shared/Region'
import Spinner from '../../../../shared/Spinner'
import { TdTruncated } from '../../../../shared/Table'
import { DeleteModal } from '../modals'

const TableRow = ({ securityGroup, hasPermission }) => {
  const deleteDisclosure = useDisclosure()
  const isLoading =
    [status.creating, status.updating, status.deleting].indexOf(
      securityGroup.status
    ) !== -1

  const isError = securityGroup.status === status.error
  const ingress = filterRulesByDirection(
    securityGroup?.securityGroupRules,
    rulesDirections.ingress
  )
  const egress = filterRulesByDirection(
    securityGroup?.securityGroupRules,
    rulesDirections.egress
  )
  const isInUse = securityGroup.instanceCount > 0
  const canBeDeleted = hasPermission && !isInUse

  if (
    securityGroup.isInternal &&
    securityGroup.name === HIDDEN_SECURITY_GROUP
  ) {
    return <Fragment key={securityGroup.id} />
  }

  return (
    <Tr>
      <TdTruncated data-hj-suppress>
        <Link
          colorScheme="gray"
          as={ReactLink}
          to={withOrgPathFallback(
            routes.dashboard.securityGroups.view.replace(
              ':securityGroupId',
              securityGroup.id
            )
          )}
        >
          {securityGroup.name}
        </Link>
      </TdTruncated>
      <Td>
        <Region slug={securityGroup.region} />
      </Td>
      <TdTruncated data-hj-suppress>
        {securityGroup.description || '-'}
      </TdTruncated>
      <Td>{ingress.length}</Td>
      <Td>{egress.length}</Td>
      <Td>{securityGroup.instanceCount}</Td>
      <Td>{formatDateTime(securityGroup.createdAt)}</Td>
      <Td>{isError && <ErrorBadge />}</Td>
      <Td isNumeric>
        {isLoading && <Spinner mr={3} color="gray.500" />}
        {!isLoading && !securityGroup.isInternal && (
          <Menu variant="table" autoSelect={false}>
            <MenuButton variant="icon" as={IconButton} icon={<Dots />} />
            <MenuList>
              <MenuItem
                as={ReactLink}
                _hover={{ textDecoration: 'none', color: 'black' }}
                to={withOrgPathFallback(
                  routes.dashboard.securityGroups.view.replace(
                    ':securityGroupId',
                    securityGroup.id
                  )
                )}
              >
                <Settings mr={2.5} />
                Security Group settings
              </MenuItem>
              {canBeDeleted && (
                <>
                  <MenuDivider />
                  <MenuItem onClick={deleteDisclosure.onOpen}>
                    <Bin mr={2.5} />
                    Delete
                  </MenuItem>
                  <DeleteModal
                    disclosure={deleteDisclosure}
                    securityGroup={securityGroup}
                  />
                </>
              )}
            </MenuList>
          </Menu>
        )}
      </Td>
    </Tr>
  )
}

export default TableRow
