import { useParams } from 'react-router-dom'
import { TableContainer, Table, Thead, Tbody, Tr, Th } from '@chakra-ui/react'
import { SkeletonTable } from '../../../shared/Skeletons'
import { TableCell, TableError } from '../../../shared/Table'
import {
  useOrgCommunityInstances,
  useAccountCommunityInstances
} from '../../../shared/hooks'
import TableRow from './table-row'

const List = ({ hasPermission }) => {
  const { orgId, projectId } = useParams()

  const {
    communityInstances,
    isLoadingCommunityInstances,
    isErrorCommunityInstances
  } =
    orgId && projectId
      ? useOrgCommunityInstances({ orgId, projectId })
      : useAccountCommunityInstances()
  const isTableEmpty = communityInstances?.length === 0

  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th w="20%">Id</Th>
            <Th w="15%">Name</Th>
            <Th w="10%">IPv4</Th>
            <Th w="15%">Type</Th>
            <Th w="10%">Configs</Th>
            <Th w="15%">Created</Th>
            <Th w="10%">Status</Th>
            <Th w="5%" />
          </Tr>
        </Thead>
        {isLoadingCommunityInstances ? (
          <SkeletonTable rows={10} columns={8} />
        ) : isErrorCommunityInstances ? (
          <TableError>
            Something went wrong loading the community instances.
          </TableError>
        ) : (
          <Tbody>
            {isTableEmpty ? (
              <TableCell>There are no community instances yet.</TableCell>
            ) : (
              communityInstances?.map((communityInstance) => (
                <TableRow
                  key={communityInstance.id}
                  row={communityInstance}
                  hasPermission={hasPermission}
                />
              ))
            )}
          </Tbody>
        )}
      </Table>
    </TableContainer>
  )
}

export default List
