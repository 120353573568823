import { Box, LinkBox, Grid } from '@chakra-ui/react'

export const Card = ({
  children,
  hasError = false,
  isDisabled = false,
  ...props
}) => (
  <Box
    p={8}
    border="1px"
    borderRadius="10px"
    borderColor={hasError ? 'red.500' : 'gray.300'}
    opacity={isDisabled ? 0.4 : 1}
    cursor={isDisabled ? 'not-allowed' : 'auto'}
    userSelect={isDisabled ? 'none' : 'auto'}
    {...props}
  >
    {children}
  </Box>
)

export const LinkCard = ({ children }) => (
  <LinkBox
    py={3}
    px={5}
    borderRadius="10px"
    border="1px"
    borderColor="gray.300"
    transition="all 0.3s ease"
    _hover={{ borderColor: 'gray.500' }}
  >
    <Grid templateColumns="20px 1fr 15px" gap={2.5} height="100%">
      {children}
    </Grid>
  </LinkBox>
)
