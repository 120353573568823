import { Heading, Stack, Text, VStack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import routes from '../../constants/routes'
import { setOnboardingLastRoute } from '../../helpers/onboarding'
import { BillingDetailsForm } from '../shared/BillingDetailsForm'
import { useAccount } from '../shared/hooks/account'
import { PaymentHandler } from '../shared/payment-form/utils'
import { OrganizationIdStorage } from '../shared/storage'
import AccountError from './Error'
import OnboardingLayout from './Layout'

const Billing = () => {
  const id = OrganizationIdStorage.get()
  const user = useSelector((state) => state.oidc.user)
  const history = useHistory()
  const { account, isLoadingAccount, isErrorAccount } = useAccount()
  const initialValues = {
    email: account?.email || '',
    name: account?.name || ''
  }

  const onSuccess = () => {
    history.push(routes.onboarding.phoneVerification)
  }

  useEffect(() => {
    if (user) {
      setOnboardingLastRoute(user.profile.sub, routes.onboarding.billing)
    }
  }, [user])

  if (isLoadingAccount) return null

  if (isErrorAccount) return <AccountError />

  return (
    <OnboardingLayout>
      <PaymentHandler />
      <VStack spacing={4} w="100%">
        <Heading as="h1" size="3xl">
          Billing details
        </Heading>
        <Stack spacing={8}>
          <Text size="md" fontWeight="normal" color="gray.500">
            This information will be included in your invoices.
          </Text>
          <BillingDetailsForm
            orgId={id}
            initialValues={initialValues}
            onSuccess={onSuccess}
            hideSuccessToast
          />
        </Stack>
      </VStack>
    </OnboardingLayout>
  )
}

export default Billing
