import { Text, Stack } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { CatalogCards } from '../RadioCards'
import CatalogBody from './CatalogBody'
import { generate } from 'generate-password'
import {
  PASSWORD_OPTIONS,
  DEFAULT_OPTIONS
} from '../../../../../constants/defaults'

const NoData = () => <Text color="gray.500">There are no Catalogs yet.</Text>

const Catalogs = ({ items }) => {
  const { values, setValues } = useFormikContext()
  const selectedImages = values.image.selected.catalogs
  const noData = items?.length === 0 || !selectedImages

  const drivers = values.type?.drivers

  const onCatalogChange = (catalog) => {
    const fields = items.find((item) => item.name === catalog.name)?.fields
    const hasPassword = fields.some((field) => field.key === 'password')
    const image = selectedImages[catalog.name]

    setValues((prev) => ({
      ...prev,
      catalog: {
        id: catalog.id,
        name: catalog.name,
        password: hasPassword ? generate(PASSWORD_OPTIONS) : '',
        hasPassword
      },
      image: {
        ...prev.image,
        id: image.id,
        name: image.name,
        tab: 'catalogs'
      },
      options: {
        ...DEFAULT_OPTIONS,
        hasDriversSupport: true,
        isDriversRequired: !!drivers
      }
    }))
  }

  const onImageChange = (image, catalogName) => {
    const { id, name } = image
    setValues((prev) => ({
      ...prev,
      image: {
        id,
        name,
        tab: 'catalogs',
        selected: {
          ...prev.image.selected,
          catalogs: {
            ...prev.image.selected.catalogs,
            [catalogName]: image
          }
        }
      }
    }))
  }

  return noData ? (
    <NoData />
  ) : (
    <Stack spacing={5}>
      <CatalogCards
        items={items}
        value={values.catalog.id}
        onChange={onCatalogChange}
      />
      <CatalogBody
        items={items}
        selected={selectedImages}
        onChange={onImageChange}
      />
    </Stack>
  )
}

export default Catalogs
