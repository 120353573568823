import { Link } from '@chakra-ui/react'
import { useParams, Link as ReactLink } from 'react-router-dom'
import { useProjectVersion } from './hooks'
import { withOrgPathFallback } from '../../router'
import routes from '../../constants/routes'
import { externalLinks } from '../../helpers/links'

const ManageVolumesLink = () => {
  const { orgId, projectId } = useParams()
  const { isB2 } = useProjectVersion({ orgId, projectId })

  return (
    <span>
      <Link
        as={ReactLink}
        to={withOrgPathFallback(routes.dashboard.volumes.index)}
        alignSelf="start"
      >
        Manage your Volumes here
      </Link>
      {isB2 && (
        <>
          <span> or learn </span>
          <Link href={externalLinks.blockStorageVolume} isExternal>
            how to mount additional Block Storage to your Instances
          </Link>
        </>
      )}
      .
    </span>
  )
}

export default ManageVolumesLink
