import moment from 'moment'

const HOURS_PER_MONTH = 730

export const types = {
  onDemand: 'On-demand',
  longTerm: 'Long-term',
  reserved: 'Reserved GPUs'
}

export const slugs = {
  onDemand: 'on-demand',
  prMonthly: 'prepaid-monthly',
  prQuarterly: 'prepaid-3-month',
  prHalfYearly: 'prepaid-6-month',
  prYear: 'prepaid-12-month',
  reserved: 'reserved'
}

export const names = {
  onDemand: 'On-demand',
  prMonthly: '1 month -20%',
  prQuarterly: '3 month -30%',
  prHalfYearly: '6 month -40%',
  prYear: '12 month -50%'
}

export const units = {
  month: 'mo',
  hour: 'hr'
}

export const format = 'D MMM YYYY'

export const createDate = (date) => moment(date).format(format)

export const createNextRenewalDate = (interval) =>
  moment().add(interval, 'month').format(format)

export const getConfiguration = ({ types, typeSlug, configSlug }) =>
  types
    ?.find((type) => type.slug === typeSlug)
    ?.configurations.find((config) => config.slug === configSlug)

export const getPrice = ({ types, typeSlug, configSlug }) => {
  const configuration = getConfiguration({ types, typeSlug, configSlug })

  const price = configuration?.pricing?.onDemand?.amount || 0

  if (configuration && 'MiB' in configuration) {
    return price * 1024
  }

  return price
}

export const getDiscount = ({ types, typeSlug, configSlug }) => {
  const configuration = getConfiguration({ types, typeSlug, configSlug })

  const discount = configuration?.discount?.percentOff || 0

  return discount
}

export const caclMonthlyPrice = (amount, interval) => {
  const intervalValue = interval || 1
  return amount / intervalValue
}

export const calcPrices = (amount, discount, credits) => {
  let creditsValue = credits || 0
  let totalPrice = amount || 0

  if (totalPrice === 0) {
    creditsValue = null
    return { totalPrice, creditsValue }
  }

  if (discount) {
    totalPrice = totalPrice - discount
  }

  if (credits) {
    if (totalPrice <= creditsValue) {
      creditsValue = -totalPrice
      totalPrice = 0
    } else {
      totalPrice = totalPrice - creditsValue
      creditsValue = -creditsValue
    }
  }

  return { totalPrice, creditsValue }
}

export const calcDiscount = (amount, discount) => {
  if (!discount) {
    return
  }
  const discountValue = amount * (discount / 100)
  if (discountValue === 0) {
    return
  }
  return discountValue
}

export const calcPriceMonthly = (price) => price * HOURS_PER_MONTH

export const calcResourcesPrices = ({
  types,
  typeSlug,
  configSlug,
  storage = 1
}) => {
  const apiPrice = getPrice({ types, typeSlug, configSlug })
  const apiDiscount = getDiscount({ types, typeSlug, configSlug })

  const updatedPrice = apiPrice ? calcPriceMonthly(apiPrice) : 0
  const updatedDiscount = calcDiscount(updatedPrice, apiDiscount)

  const priceByGiB = updatedPrice * storage
  const discountByGiB = updatedDiscount * storage

  const { totalPrice } = calcPrices(priceByGiB, discountByGiB)

  return { price: updatedPrice, discount: apiDiscount, totalPrice }
}

export const getTypeBySlug = (slug) => {
  switch (slug) {
    case slugs.onDemand:
      return types.onDemand
    case slugs.prMonthly:
      return types.longTerm
    case slugs.prQuarterly:
      return types.longTerm
    case slugs.prHalfYearly:
      return types.longTerm
    case slugs.prYear:
      return types.longTerm
    case slugs.reserved:
      return types.reserved
    default:
      return ''
  }
}

export const getSlugByName = (name) => {
  switch (name) {
    case names.onDemand:
      return slugs.onDemand
    case names.prMonthly:
      return slugs.prMonthly
    case names.prQuarterly:
      return slugs.prQuarterly
    case names.prHalfYearly:
      return slugs.prHalfYearly
    case names.prYear:
      return slugs.prYear
    default:
      return ''
  }
}

export const getNameBySlug = (slug) => {
  switch (slug) {
    case slugs.onDemand:
      return names.onDemand
    case slugs.prMonthly:
      return names.prMonthly
    case slugs.prQuarterly:
      return names.prQuarterly
    case slugs.prHalfYearly:
      return names.prHalfYearly
    case slugs.prYear:
      return names.prYear
    default:
      return ''
  }
}

export const isUsedSubscription = (id) => {
  const isNewSubscription = Boolean(
    Object.values(slugs).find((slug) => id?.startsWith(slug))
  )
  return !isNewSubscription
}

export const isEmpty = (array) => array?.length === 0
