import { INITIAL_PAGE, PER_PAGE } from '../components/shared/Table'
import { serialize } from '../helpers/serializers'
import { COMPUTE_ENDPOINT, apiRequest } from './index'

export class OrgFilesystems {
  static fetchAll = async ({
    orgId,
    projectId,
    page = INITIAL_PAGE,
    perPage = PER_PAGE
  }) => {
    const filesystems = await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/filesystems?org=${orgId}&page=${page}&per_page=${perPage}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(filesystems)
  }

  static fetchById = async ({ orgId, projectId, filesystemId }) => {
    const filesystem = await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/filesystems/${filesystemId}?org=${orgId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(filesystem)
  }

  static create = async ({ orgId, projectId, payload }) => {
    const filesystem = await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/filesystems?org=${orgId}`,
      {
        method: 'POST',
        body: JSON.stringify(payload)
      },
      true
    )
    return serialize(filesystem)
  }

  static deleteById = async ({ orgId, projectId, filesystemId }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/filesystems/${filesystemId}?org=${orgId}`,
      {
        method: 'DELETE'
      },
      true
    )
}

export class AccountFilesystems {
  static fetchAll = async ({ page = INITIAL_PAGE, perPage = PER_PAGE }) => {
    const filesystems = await apiRequest(
      `${COMPUTE_ENDPOINT}/filesystems?page=${page}&per_page=${perPage}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(filesystems)
  }

  static fetchById = async ({ filesystemId }) => {
    const filesystem = await apiRequest(
      `${COMPUTE_ENDPOINT}/filesystems/${filesystemId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(filesystem)
  }

  static create = async ({ payload }) => {
    const filesystem = await apiRequest(
      `${COMPUTE_ENDPOINT}/filesystems`,
      {
        method: 'POST',
        body: JSON.stringify(payload)
      },
      true
    )
    return serialize(filesystem)
  }

  static deleteById = async ({ filesystemId }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/filesystems/${filesystemId}`,
      {
        method: 'DELETE'
      },
      true
    )
}
