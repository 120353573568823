import { INITIAL_PAGE, PER_PAGE } from '../components/shared/Table'
import { serialize } from '../helpers/serializers'
import { ACCOUNT_ENDPOINT, apiRequest } from './index'
export class Organization {
  static create = async (data) => {
    const org = await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations`,
      {
        method: 'POST',
        body: JSON.stringify(data)
      },
      true
    )
    return serialize(org)
  }

  static fetchById = async (orgId) => {
    const org = await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(org)
  }

  static updateById = async (orgId, data) => {
    const org = await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(data)
      },
      true
    )
    return serialize(org)
  }

  static deleteById = async (orgId) =>
    await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}`,
      {
        method: 'DELETE'
      },
      true
    )

  static transferById = async (orgId, data) => {
    const org = await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}/transfer`,
      {
        method: 'POST',
        body: JSON.stringify(data)
      },
      true
    )
    return serialize(org)
  }

  static leaveById = async (orgId) =>
    await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}/leave`,
      {
        method: 'POST',
        body: JSON.stringify({ organizationId: orgId })
      },
      true
    )

  static updateInfoById = async (orgId, data) => {
    const org = await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}/info`,
      {
        method: 'PATCH',
        body: JSON.stringify(data)
      },
      true
    )
    return serialize(org)
  }

  static fetchSettingsById = async (orgId) => {
    const settings = await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}/settings`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(settings)
  }

  static fetchAll = async ({ page = INITIAL_PAGE, perPage = PER_PAGE }) => {
    const orgs = await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations?page=${page}&per_page=${perPage}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(orgs)
  }

  static updateMember = async (orgId, memberId, data) =>
    await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}/members/${memberId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(data)
      }
    )

  static deleteMember = async (orgId, memberId) =>
    await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}/members/${memberId}`,
      {
        method: 'DELETE'
      },
      true
    )

  static getAllMembers = async ({
    orgId,
    page = INITIAL_PAGE,
    perPage = PER_PAGE
  }) => {
    const members = await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}/members?page=${page}&per_page=${perPage}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(members)
  }

  static createInvitation = async (orgId, data) => {
    const code = await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}/invitations`,
      {
        method: 'POST',
        body: JSON.stringify({ emails: data })
      },
      true
    )
    return serialize(code)
  }

  static deleteInvitation = async (orgId, invitationId) =>
    await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}/invitations/${invitationId}`,
      {
        method: 'DELETE'
      },
      true
    )

  static acceptInvitation = async (orgId, code) => {
    await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}/invitations/accept`,
      {
        method: 'POST',
        body: JSON.stringify({ code })
      },
      true
    )
  }

  static declineInvitation = async (orgId, code) => {
    await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}/invitations/decline`,
      {
        method: 'POST',
        body: JSON.stringify({ code })
      },
      true
    )
  }

  static fetchInvitationByCode = async (orgId, code) => {
    const invitation = await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}/invitations/get?code=${code}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(invitation)
  }

  static fetchAllInvitations = async ({
    orgId,
    page = INITIAL_PAGE,
    perPage = PER_PAGE
  }) => {
    const invitations = await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}/invitations?page=${page}&per_page=${perPage}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(invitations)
  }

  static createForOnboarding = async (data) => {
    const org = await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations-onboarding`,
      {
        method: 'POST',
        body: JSON.stringify(data)
      },
      true
    )
    return serialize(org)
  }

  static fetchFF = async ({ orgId }) => {
    const result = await apiRequest(
      `${ACCOUNT_ENDPOINT}/organizations/${orgId}/ff`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(result)
  }
}
