import countries from './data/countries.json'

export function getCountryById(id) {
  return countries.find((c) => c.id === id)
}

export function getCountryByName(name) {
  return countries.find((c) => c.name === name) || ''
}

export function getCountries() {
  return countries
}
