import {
  Box,
  Flex,
  MenuButton,
  Menu as MenuChakra,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react'
import { none, rotated } from '../../../../../constants/styles'
import { ArrowDown } from '../../../../shared/Icons'

const days = [7, 30, 60]

const getText = (day) => (day === null ? 'Custom' : `${day} days`)

const Menu = ({ selected, onClick }) => (
  <MenuChakra variant="table" autoSelect={false}>
    {({ isOpen }) => (
      <Box m={0}>
        <MenuButton w="100px">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            border="1px"
            borderRadius="6px"
            borderRightRadius={0}
            borderColor="gray.300"
            px={2}
            py={1}
          >
            <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              {getText(selected)}
            </Text>
            <ArrowDown transform={isOpen ? rotated : none} />
          </Flex>
        </MenuButton>
        <MenuList minW="0" w="100px">
          {days.map((day) => (
            <MenuItem key={day} onClick={() => onClick(day)}>
              {getText(day)}
            </MenuItem>
          ))}
        </MenuList>
      </Box>
    )}
  </MenuChakra>
)

export default Menu
