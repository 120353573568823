import React, { useEffect, useRef, useState } from 'react'
import { Terminal } from 'xterm'
import { AttachAddon } from 'xterm-addon-attach'
import { FitAddon } from 'xterm-addon-fit'
import 'xterm/css/xterm.css'
import { useQuery } from '../shared/hooks/useQuery'
import { MAX_RETRIES, RETRY_INTERVAL } from '../../constants/serial-console'

const API_ENDPOINT =
  process.env.REACT_APP_API_URL || `https://${location.hostname}/api`

function Console() {
  const query = useQuery()
  const queryString = new URLSearchParams({ ...query }).toString()
  const terminalRef = useRef(null)
  const socketRef = useRef(null)
  const [, setRetryCount] = useState(0)

  const term = new Terminal({
    cursorBlink: true
  })

  const url = new URL(
    `${API_ENDPOINT}/compute/websocket-v1/serial-console?${queryString}`
  )
  url.protocol = 'wss'

  const attemptReconnection = () => {
    setRetryCount((currentRetryCount) => {
      if (currentRetryCount < MAX_RETRIES) {
        const retriesLeft = MAX_RETRIES - currentRetryCount - 1

        term.writeln(
          `\x1b[31mSession closed. Retrying in 5 seconds (${retriesLeft} ${
            retriesLeft === 1 ? 'retry' : 'retries'
          } left)\x1b[0m`
        )

        setTimeout(initializeSocketConnection, RETRY_INTERVAL) // retry connection every 5 seconds
        return currentRetryCount + 1
      } else {
        term.writeln(
          '\x1b[31mMaximum retry attempts reached. Connection closed.\x1b[0m'
        )
        return currentRetryCount
      }
    })
  }

  const initializeSocketConnection = () => {
    const terminalContainer = terminalRef.current
    terminalContainer.innerHTML = ''

    const socket = new WebSocket(url)
    socketRef.current = socket

    if (terminalContainer) {
      term.open(terminalContainer)

      // fit the terminal to the full screen
      const fitAddon = new FitAddon()
      term.loadAddon(fitAddon)
      fitAddon.fit()

      // Attach the socket to xterm
      const attachAddon = new AttachAddon(socket)
      term.loadAddon(attachAddon)

      // Set focus on the terminal to start receiving input immediately
      term.focus()

      socket.onopen = () => {
        setRetryCount(0)
        term.writeln('\x1b[32mConnection established...\x1b[0m')
      }

      socket.onerror = () => {
        term.writeln('')
        term.writeln('\x1b[31mAn error occurred...\x1b[0m')
      }

      socket.onclose = () => {
        attemptReconnection()
      }
    }
  }

  useEffect(() => {
    initializeSocketConnection()

    return () => {
      // Cleanup the terminal when the component is unmounted
      if (socketRef.current) {
        socketRef.current.close()
      }
      const terminalContainer = terminalRef.current
      if (terminalContainer) {
        terminalContainer.innerHTML = ''
      }
    }
  }, [])

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: 0,
        left: 0
      }}
      ref={terminalRef}
    />
  )
}

export default Console
