import {
  Flex,
  Stack,
  List,
  Heading,
  HStack,
  Button,
  Skeleton,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { Card } from '../../../../shared/Cards'
import { Plus, Warning } from '../../../../shared/Icons'
import SupportError from '../../../../Errors/SupportError'
import { useAccountBilling, useOrgBilling } from '../../../../shared/hooks'
import { AddMethodModal } from '../modals'
import CardItem from './card-item'

const Loading = () => (
  <Stack spacing={3}>
    <Skeleton h="45px" />
    <Skeleton h="45px" />
    <Skeleton h="45px" />
  </Stack>
)

const Error = () => (
  <SupportError pt={6}>
    Something went wrong loading the credit cards.
  </SupportError>
)

const Methods = () => {
  const addDisclosure = useDisclosure()
  const { orgId } = useParams()
  const { billing, isLoadingBilling, isErrorBilling } = orgId
    ? useOrgBilling({ orgId })
    : useAccountBilling()
  const creditCards = billing?.creditCards || []
  const isEmpty = !isLoadingBilling && creditCards.length === 0

  return (
    <Card as={Stack} spacing={8}>
      <Flex justify="space-between">
        <HStack spacing="2">
          <Heading size="xl">Payment methods</Heading>
          {isEmpty && <Warning color="red.500" />}
        </HStack>
        <Button
          colorScheme="offBlack"
          variant="outline"
          leftIcon={<Plus color="black" />}
          onClick={addDisclosure.onOpen}
        >
          Add payment method
        </Button>
      </Flex>
      {isLoadingBilling ? (
        <Loading />
      ) : isErrorBilling ? (
        <Error />
      ) : isEmpty ? (
        <Text color="red.500" mt="0px">
          {`You won't be able to access computing resources until you have a valid
          payment method.`}
        </Text>
      ) : (
        <List spacing="3">
          {creditCards.map((card) => (
            <CardItem key={card.id} card={card} orgId={orgId} />
          ))}
        </List>
      )}
      <AddMethodModal disclosure={addDisclosure} />
    </Card>
  )
}

export default Methods
