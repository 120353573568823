import {
  Button,
  Center,
  Flex,
  Grid,
  HStack,
  Heading,
  LinkOverlay,
  Spacer,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Link as ReactLink, useParams } from 'react-router-dom'
import useSWR from 'swr'
import { Organization } from '../../api/organizations'
import routes from '../../constants/routes'
import { externalLinks } from '../../helpers/links'
import { withOrgPathFallback } from '../../router'
import { Card, LinkCard } from '../shared/Cards'
import {
  Code,
  Compute,
  ExternalLink,
  Jupyter,
  Security,
  Snapshot,
  TensorFlow,
  Volume
} from '../shared/Icons'
import Spinner from '../shared/Spinner'
import { useOrgParam } from '../shared/hooks/useOrgParam'
import { useAccount } from '../shared/hooks/account'
import { useQuery } from '../shared/hooks/useQuery'
import { OrganizationIdStorage } from '../shared/storage'
import { IntroModal } from './profile/organization/manage/modals'
import { AcceptModal } from './profile/organization/members/modals'
import { useOrgFF, useAccountFF } from '../shared/hooks'
import { BannerH100, BannerReservation } from '../shared/Banner'

const Link = ({ url }) => (
  <LinkOverlay href={url} isExternal alignSelf="end">
    <ExternalLink />
  </LinkOverlay>
)

const Cards = () => (
  <Stack spacing={4}>
    <Heading size="xl">Knowledge Base</Heading>
    <Grid templateColumns="1fr 1fr 1fr" gap={5}>
      <LinkCard>
        <Compute width="20px" height="auto" color="black" />
        <Stack spacing="0">
          <Heading as="h3" size="sm">
            GPU Instances
          </Heading>
          <Text size="xs" color="gray.500">
            Create powerful GPU Instances and run your compute intensive
            workloads like transcoding, rendering or machine learning.
          </Text>
        </Stack>
        <Link url={externalLinks.homeInstances} />
      </LinkCard>
      <LinkCard>
        <Volume width="17px" height="auto" mt={1} />
        <Stack spacing="0">
          <Heading as="h3" size="sm">
            Volumes
          </Heading>
          <Text size="xs" color="gray.500">
            Attach storage Volumes to store large datasets.
          </Text>
        </Stack>
        <Link url={externalLinks.homeVolumes} />
      </LinkCard>
      <LinkCard>
        <TensorFlow width="20px" height="20px" mt={1} />
        <Stack spacing="0">
          <Heading as="h3" size="sm">
            Preconfigured Images
          </Heading>
          <Text size="xs" color="gray.500">
            Get started right away with our preconfigured images like PyTorch,
            or Tensorflow.
          </Text>
        </Stack>
        <Link url={externalLinks.homeImages} />
      </LinkCard>
      <LinkCard>
        <Security mt={1} />
        <Stack spacing="0">
          <Heading as="h3" size="sm">
            Security Groups
          </Heading>
          <Text size="xs" color="gray.500">
            Use Security Groups to control your Instance&apos;s Network access.
          </Text>
        </Stack>
        <Link url={externalLinks.homeSecurityGroups} />
      </LinkCard>
      <LinkCard>
        <Snapshot width="17px" height="auto" mt={1} />
        <Stack spacing="0">
          <Heading as="h3" size="sm">
            Instance Snapshots
          </Heading>
          <Text size="xs" color="gray.500">
            Take Snapshots of your Instances to save and reuse your setup.
          </Text>
        </Stack>
        <Link url={externalLinks.snapshots} />
      </LinkCard>
      <LinkCard>
        <Code mt={1} />
        <Stack spacing="0">
          <Heading as="h3" size="sm">
            Public API
          </Heading>
          <Text size="xs" color="gray.500">
            Control your Instance via our Public API directly from your script
            or application.
          </Text>
        </Stack>
        <Link url={process.env.REACT_APP_DEVELOPER_API_URL} />
      </LinkCard>
    </Grid>
  </Stack>
)

const JupyterSection = () => (
  <Card as={Flex}>
    <Stack spacing={2.5} maxW={{ lg: '656px', '2xl': '760px' }}>
      <HStack>
        <Jupyter />
        <Heading>JupyterLab</Heading>
      </HStack>
      <Text size="sm" color="gray.500">
        {`JupyterLab's are one of the most popular tools among data scientists
            and machine learning practitioners. They are one of the simplest ways
            to create, explain, run, and share code.`}
      </Text>
    </Stack>
    <Spacer />
    <Button
      as={ReactLink}
      to={`${withOrgPathFallback(
        routes.dashboard.instances.create
      )}?catalog=3ee98e52-d3f7-401c-901c-ee3da0092710`}
      alignSelf="start"
      width={160}
      colorScheme="offBlack"
      variant="outline"
    >
      Get started
    </Button>
  </Card>
)

const Error = () => (
  <Text size="sm">
    Something went wrong.
    <br />
    Please refresh this page or contact Genesis Cloud support if the error
    persists.
  </Text>
)

const Home = () => {
  const { orgId: id, code, projectId } = useParams()
  const { org_intro } = useQuery()
  const { account, isLoadingAccount, isErrorAccount } = useAccount()
  const orgId = OrganizationIdStorage.get() || useOrgParam()
  const { data: organization } = useSWR(
    orgId ? `/organizations/${orgId}` : null,
    () => Organization.fetchById(orgId)
  )

  const { featureFlag, isLoading, isError } = orgId
    ? useOrgFF({ orgId })
    : useAccountFF({})
  const { enableQuotaReservation } = featureFlag || {}

  const acceptInvitationDisclosure = useDisclosure()
  const [name, setName] = useState()

  useEffect(() => {
    if (id && code) {
      acceptInvitationDisclosure.onOpen()
    }
  }, [id, code])

  useEffect(() => {
    if (orgId && account && organization) {
      setName(`${account.name} (${organization.name})`)
    } else if (account) {
      setName(account.name)
    }
  }, [orgId, account, organization])

  if (isErrorAccount || isError) return <Error />

  if (isLoading || isLoadingAccount)
    return (
      <Center>
        <Spinner />
      </Center>
    )

  return (
    <>
      <Stack spacing={8}>
        <Stack spacing={0} w="fit-content">
          <Text data-hj-suppress size="4xl">
            Hi, {name}
          </Text>
          <Text data-hj-suppress size="md" color="gray.500" fontWeight="normal">
            {account?.email}
          </Text>
        </Stack>
        {enableQuotaReservation ? <BannerReservation /> : <BannerH100 />}
        <Cards />
        <JupyterSection />
        {id && projectId && code && (
          <AcceptModal
            disclosure={acceptInvitationDisclosure}
            id={id}
            code={code}
          />
        )}
      </Stack>
      <IntroModal isVisible={org_intro === 'true'} />
    </>
  )
}

export default Home
