import useSWR from 'swr'
import { AccountSnapshots, OrgSnapshots } from '../../../api/images'
import { refreshInterval } from '../../../constants/swr-options'
import { serialize } from '../../../helpers/serializers'
import { INITIAL_PAGE, PER_PAGE } from '../Table'
import { transformCatalogs, transformImages } from './configs/image'

const swrCustomOptions = {
  revalidateOnFocus: false
}

export const useOrgImages = ({ orgId, projectId, region, type }) => {
  const key = `/projects/${projectId}/images?org=${orgId}`
  const fetcher = () => OrgSnapshots.fetchImageOptions({ orgId, projectId })

  const { data, isValidating, isLoading, error } = useSWR(
    key,
    fetcher,
    swrCustomOptions
  )

  let images, pagination
  if (data) ({ images, ...pagination } = serialize(data))

  const transformedImages = transformImages({ images, region, type })

  return {
    images,
    transformedImages,
    pagination,
    isValidatingImages: isValidating,
    isLoadingImages: isLoading,
    isErrorImages: Boolean(error)
  }
}

export const useOrgSnapshots = ({
  orgId,
  projectId,
  page = INITIAL_PAGE,
  perPage = PER_PAGE,
  region,
  isPolling = false
}) => {
  const regionArg = region ? `&region=${region}` : ''
  const key = `/projects/${projectId}/snapshots?page=${page}&per_page=${perPage}&org=${orgId}${regionArg}`
  const fetcher = () =>
    OrgSnapshots.fetchAll({ orgId, projectId, page, perPage, region })

  const swrOptions = {
    refreshInterval: isPolling && refreshInterval
  }

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  let snapshots, pagination
  if (data) ({ snapshots, ...pagination } = serialize(data))

  return {
    snapshots,
    pagination,
    isLoadingSnapshots: isLoading,
    isErrorSnapshots: Boolean(error)
  }
}

export const useOrgCatalogs = ({ orgId, projectId }) => {
  const key = `/projects/${projectId}/catalog?per_page=50&page=1&org=${orgId}`
  const fetcher = () => OrgSnapshots.fetchCatalogImages({ orgId, projectId })

  const { data, isValidating, isLoading, error } = useSWR(
    key,
    fetcher,
    swrCustomOptions
  )

  let catalog, pagination
  if (data) ({ catalog, ...pagination } = serialize(data))

  const transformedCatalogs = transformCatalogs(catalog)

  return {
    catalogs: catalog,
    transformedCatalogs,
    pagination,
    isValidatingCatalogs: isValidating,
    isLoadingCatalogs: isLoading,
    isErrorCatalogs: Boolean(error)
  }
}

export const useAccountImages = ({ region, type }) => {
  const key = '/images'
  const fetcher = () => AccountSnapshots.fetchImageOptions()

  const { data, isValidating, isLoading, error } = useSWR(
    key,
    fetcher,
    swrCustomOptions
  )

  let images, pagination
  if (data) ({ images, ...pagination } = serialize(data))

  const transformedImages = transformImages({ images, region, type })

  return {
    images,
    transformedImages,
    pagination,
    isValidatingImages: isValidating,
    isLoadingImages: isLoading,
    isErrorImages: Boolean(error)
  }
}

export const useAccountSnapshots = ({
  page = INITIAL_PAGE,
  perPage = PER_PAGE,
  region,
  isPolling = false
}) => {
  const regionArg = region ? `&region=${region}` : ''
  const key = `/snapshots?page=${page}&per_page=${perPage}${regionArg}`
  const fetcher = () => AccountSnapshots.fetchAll({ page, perPage, region })

  const swrOptions = {
    refreshInterval: isPolling && refreshInterval
  }

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  let snapshots, pagination
  if (data) ({ snapshots, ...pagination } = serialize(data))

  return {
    snapshots,
    pagination,
    isLoadingSnapshots: isLoading,
    isErrorSnapshots: Boolean(error)
  }
}

export const useAccountCatalogs = () => {
  const key = '/catalog?per_page=50&page=1'
  const fetcher = () => AccountSnapshots.fetchCatalogImages()

  const { data, isValidating, isLoading, error } = useSWR(
    key,
    fetcher,
    swrCustomOptions
  )

  let catalog, pagination
  if (data) ({ catalog, ...pagination } = serialize(data))

  const transformedCatalogs = transformCatalogs(catalog)

  return {
    catalogs: catalog,
    transformedCatalogs,
    pagination,
    isValidatingCatalogs: isValidating,
    isLoadingCatalogs: isLoading,
    isErrorCatalogs: Boolean(error)
  }
}
