// @ts-check
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  topNotifications: []
}

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action) => {
      const payload = Object.assign({}, { notification: action.payload })
      const index = state.topNotifications.findIndex(
        (n) => n.id === payload.notification.id
      )
      const topNotifications = [...state.topNotifications]
      if (index >= 0) {
        topNotifications[index] = payload.notification
      } else {
        topNotifications.push(payload.notification)
      }
      state.topNotifications = topNotifications
    },
    removeNotification: (state, action) => {
      const payload = Object.assign({}, { id: action.payload })
      const index = state.topNotifications.findIndex((n) => n.id === payload.id)
      const topNotifications = [...state.topNotifications]

      if (index !== -1) {
        topNotifications.splice(index, 1)
      }
      state.topNotifications = topNotifications
    }
  }
})

export const { addNotification, removeNotification } = slice.actions

export default slice.reducer
