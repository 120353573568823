import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { mutate } from 'swr'
import { Organization } from '../../../../../../api/organizations'
import routes from '../../../../../../constants/routes'
import { toastMessages } from '../../../../../../constants/toast-messages'
import { useToast } from '../../../../../shared/Toast'
import { useOrganizationWithId } from '../../../../../shared/hooks/organization'
import { useIsMounted } from '../../../../../shared/hooks/useIsMounted'

export const LeaveModal = ({ disclosure, orgId }) => {
  const history = useHistory()
  const { isOpen, onClose } = disclosure
  const [isSubmitting, setIsSubmitting] = useState()
  const { organization } = useOrganizationWithId(orgId)
  const isMounted = useIsMounted()
  const toast = useToast()

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true)
    try {
      await Organization.leaveById(orgId)
      await mutate((key) => key.startsWith(`/organizations?`))
      toast({
        status: 'success',
        message: toastMessages.organizationLeaveSuccess
      })
      onClose()
      history.push(routes.dashboard.organizations.index)
    } catch {
      toast({
        status: 'error',
        message: toastMessages.organizationLeaveError
      })
    } finally {
      if (isMounted()) {
        setIsSubmitting(false)
      }
    }
  }, [organization])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={8}>
            <Heading size="xl" textAlign="center">
              Are you sure you want to leave {organization?.name}?
            </Heading>
            <Text
              size="md"
              textAlign="center"
              color="red.500"
              fontWeight="normal"
            >
              Once you have left the organization, you will not be able to see
              and manage its resources.
            </Text>
            <Stack spacing={4}>
              <Button
                type="submit"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                colorScheme="red"
                onClick={handleSubmit}
              >
                Leave Organization
              </Button>
              <Button
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isSubmitting}
                onClick={onClose}
                isFullWidth
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
