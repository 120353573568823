import { Stack, Grid, Text, Link } from '@chakra-ui/react'
import { externalLinks } from '../../../../helpers/links'

const Banner = () => {
  return (
    <Grid p={10} borderRadius="10px" bg="blue.100">
      <Stack spacing={5}>
        <Text size="xl">Get Started with VAST Storage</Text>
        <Text size="md" fontWeight={400}>
          <span>
            The filesystem service is only available for projects running on our
            new backend which provides our users a better overall performance.
            Please upgrade to the new backend version by creating a new project
            within your organization or{' '}
            <Link
              color="black"
              href={externalLinks.filesystemsLegacy}
              isExternal
            >
              contact support
            </Link>{' '}
            for help. The upgrade is free of charge.
          </span>
        </Text>
      </Stack>
    </Grid>
  )
}

export default Banner
