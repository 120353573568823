import { Button, Link, Spacer, Stack } from '@chakra-ui/react'
import { Link as ReactLink, useParams } from 'react-router-dom'
import routes from '../../../../constants/routes'
import { externalLinks } from '../../../../helpers/links'
import { withOrgPathFallback } from '../../../../router'
import Footer from '../../../shared/Footer'
import { Plus } from '../../../shared/Icons'
import { useHasWriteProjectPermissions } from '../../../shared/hooks/project'
import List from './list'

const SecurityGroups = () => {
  const { orgId, projectId } = useParams()
  const hasWriteProjectPermissions = orgId
    ? useHasWriteProjectPermissions({
        projectId,
        orgId
      })
    : true

  return (
    <Stack spacing={8}>
      <List hasPermission={hasWriteProjectPermissions} />
      <Footer>
        <Link href={externalLinks.securityGroups} isExternal>
          Learn more about Security Groups
        </Link>
        <Spacer />
        {hasWriteProjectPermissions && (
          <Button
            as={ReactLink}
            to={withOrgPathFallback(routes.dashboard.securityGroups.create)}
            leftIcon={<Plus />}
          >
            Create new Security Group
          </Button>
        )}
      </Footer>
    </Stack>
  )
}

export default SecurityGroups
