import React from 'react'
import { getBadgeColor, instanceAnimatedStates } from '../../helpers/instances'
import { Box, Badge } from '@chakra-ui/react'
import TransitionBadge from './TransitionBadge'

const animatedStatus = (status) => {
  let capitalStatus = status.toUpperCase()
  if (capitalStatus.includes('ING')) {
    capitalStatus += '...'
  }

  return capitalStatus
}

const ResourceStatus = ({ status }) => {
  const isAnimated = instanceAnimatedStates.includes(status)

  return (
    <Box
      wordBreak="nowrap"
      title={
        status === 'pending_payment'
          ? 'Payment in progress, check your email as 3D Secure authorization may be required to complete the payment'
          : ''
      }
    >
      {isAnimated ? (
        <TransitionBadge>{animatedStatus(status)}</TransitionBadge>
      ) : (
        <Badge
          colorScheme={getBadgeColor(status)}
          variant="outline"
          data-id="instances-list-status-badge"
        >
          {status.toUpperCase().replace('_', ' ')}
        </Badge>
      )}
    </Box>
  )
}

export default ResourceStatus
