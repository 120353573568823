import {
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { Card } from '../../../shared/Cards'
import { DeleteModal } from '../modals/delete'

const Delete = ({ filesystem }) => {
  const disclosure = useDisclosure()

  return (
    <Card as={Flex} justify="space-between">
      <Stack>
        <Heading size="xl">Delete Filesystem</Heading>
        <Text color="gray.500">
          This action will permanently delete the Filesystem and it cannot be
          recovered.
        </Text>
      </Stack>
      <Button colorScheme="red" onClick={disclosure.onOpen}>
        Delete Filesystem
      </Button>
      <DeleteModal filesystem={filesystem} disclosure={disclosure} />
    </Card>
  )
}

export default Delete
