import textStyles from '../foundations/textStyles'

const Link = {
  baseStyle: {
    _selection: {
      background: 'none'
    }
  },
  sizes: {
    xs: {
      ...textStyles.xs
    },
    sm: {
      ...textStyles.sm
    },
    md: {
      ...textStyles.md
    }
  },
  variants: {
    menu: ({ colorScheme }) => ({
      display: 'flex',
      alignItems: 'center',
      color: `${colorScheme}.500`,
      _hover: {
        color: `${colorScheme}.700`,
        textDecoration: 'none'
      },
      _active: {
        color: `${colorScheme}.900`,
        boxShadow: 'none'
      },
      _focus: {
        boxShadow: 'none'
      }
    }),
    underlined: ({ colorScheme }) => ({
      color: `${colorScheme}.500`,
      textDecoration: 'underline',
      _hover: {
        color: `${colorScheme}.700`
      },
      _selection: {
        color: `${colorScheme}.900`
      }
    })
  },
  defaultProps: {
    colorScheme: 'blue',
    variant: 'underlined'
  }
}

export default Link
