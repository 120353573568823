import { INITIAL_PAGE, PER_PAGE } from '../components/shared/Table'
import { serialize } from '../helpers/serializers'
import { PRODUCTS_ENDPOINT, apiRequest } from './index'

export class OrgReservations {
  static fetchAll = async ({
    orgId,
    page = INITIAL_PAGE,
    perPage = PER_PAGE
  }) => {
    const reservations = await apiRequest(
      `${PRODUCTS_ENDPOINT}/reservations?org=${orgId}&page=${page}&per_page=${perPage}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(reservations)
  }

  static payNow = async ({ orgId, reservationId }) => {
    const payment = await apiRequest(
      `${PRODUCTS_ENDPOINT}/reservations/${reservationId}/payment?org=${orgId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(payment)
  }

  static fetchInfo = async ({ orgId, region, type }) => {
    const regionArg = region ? `&region=${region}` : ''
    const typeArg = type ? `&flavor_name=${type}` : ''

    const info = await apiRequest(
      `${PRODUCTS_ENDPOINT}/reservations/info?org=${orgId}${regionArg}${typeArg}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(info)
  }

  static create = async ({ orgId, payload }) => {
    const reservation = await apiRequest(
      `${PRODUCTS_ENDPOINT}/reservations?org=${orgId}`,
      {
        method: 'POST',
        body: JSON.stringify(payload)
      },
      true
    )
    return serialize(reservation)
  }

  static cancel = async ({ orgId, reservationId }) => {
    return await apiRequest(
      `${PRODUCTS_ENDPOINT}/reservations/${reservationId}/action?org=${orgId}`,
      {
        method: 'POST',
        body: JSON.stringify({ action: 'cancel' })
      }
    )
  }
}

export class AccountReservations {
  static fetchAll = async ({ page = INITIAL_PAGE, perPage = PER_PAGE }) => {
    const reservations = await apiRequest(
      `${PRODUCTS_ENDPOINT}/reservations?page=${page}&per_page=${perPage}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(reservations)
  }

  static payNow = async ({ reservationId }) => {
    const payment = await apiRequest(
      `${PRODUCTS_ENDPOINT}/reservations/${reservationId}/payment`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(payment)
  }

  static fetchInfo = async ({ region, type }) => {
    const regionArg = region ? `&region=${region}` : ''
    const typeArg = type ? `&flavor_name=${type}` : ''

    const info = await apiRequest(
      `${PRODUCTS_ENDPOINT}/reservations/info?${regionArg}${typeArg}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(info)
  }

  static create = async ({ payload }) => {
    const reservation = await apiRequest(
      `${PRODUCTS_ENDPOINT}/reservations`,
      {
        method: 'POST',
        body: JSON.stringify(payload)
      },
      true
    )
    return serialize(reservation)
  }

  static cancel = async ({ reservationId }) => {
    return await apiRequest(
      `${PRODUCTS_ENDPOINT}/reservations/${reservationId}/action`,
      {
        method: 'POST',
        body: JSON.stringify({ action: 'cancel' })
      }
    )
  }
}
