import textStyles from '../foundations/textStyles'

const Heading = {
  baseStyle: {
    color: 'black'
  },
  sizes: {
    sm: {
      fontSize: 'sm',
      fontWeight: 'semibold',
      lineHeight: 'base',
      letterSpacing: 'normal'
    },
    md: {
      ...textStyles.md
    },
    lg: {
      ...textStyles.lg
    },
    xl: {
      ...textStyles.xl
    },
    '2xl': {
      ...textStyles['2xl']
    },
    '3xl': {
      ...textStyles['3xl'],
      fontSize: ['xl', '3xl', '3xl']
    },
    '4xl': {
      ...textStyles['4xl']
    },
    '5xl': {
      ...textStyles['5xl']
    }
  }
}

export default Heading
