import { useParams } from 'react-router-dom'
import { Stack, Grid, Heading } from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import routes from '../../../../../constants/routes'
import { withOrgPathFallback } from '../../../../../router'
import { ReturnLink } from '../../../../shared/Links'
import { useToast } from '../../../../shared/Toast'
import { apiErrorHandler } from '../../../../shared/ApiErrorHandler'
import {
  RESERVATION_SIZE_MIN,
  RESERVATION_SIZE_MAX,
  RESERVATION_SIZE_STEP,
  RESERVATION_DATES,
  RESERVATION_PRICES
} from '../../../../../constants/defaults'
import Region from './region'
import Type from './type'
import Configuration from './configuration'
import Dates from './dates'
import Billing from './billing'
import Summary from './summary'
import { transformPayload, InfoHandler } from '../utils'
import {
  OrgReservations,
  AccountReservations
} from '../../../../../api/reservations'

const regions =
  process.env.REACT_APP_RESERVATION_ENABLED_REGIONS?.split(',') || []

const types = process.env.REACT_APP_RESERVATION_FLAVOR_NAMES?.split(',') || []

const initialValues = {
  region: regions[0],
  type: types[0],
  size: RESERVATION_SIZE_MIN,
  dates: RESERVATION_DATES,
  prices: RESERVATION_PRICES,
  isEmpty: true
}

const validationSchema = yup.object().shape({
  size: yup
    .number()
    .required('Size is required.')
    .min(RESERVATION_SIZE_MIN)
    .max(RESERVATION_SIZE_MAX)
    .test({
      name: 'step',
      message: 'Size must be divisible by 8.',
      test: (value) => value % RESERVATION_SIZE_STEP === 0
    }),
  dates: yup
    .object()
    .shape({
      start: yup.string().nullable(true),
      end: yup.string().nullable(true),
      isRangeValid: yup.boolean()
    })
    .test({
      name: 'requiredRange',
      message: 'Range is required.',
      test: ({ start, end, isRangeValid }) =>
        isRangeValid ? start && end : true
    })
    .test({
      name: 'invalidRange',
      message: 'The selected range should not include the disabled dates.',
      test: ({ isRangeValid }) => isRangeValid
    })
})

const ReservationsCreate = () => {
  const { orgId } = useParams()
  const toast = useToast()

  const link = withOrgPathFallback(routes.dashboard.reservations.index)

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true)
    const payload = transformPayload(values, link)

    try {
      const response = orgId
        ? await OrgReservations.create({ orgId, payload })
        : await AccountReservations.create({
            payload
          })

      resetForm()
      response?.redirectUrl && window.location.replace(response.redirectUrl)
    } catch (error) {
      const message = await apiErrorHandler(error)
      toast({
        status: 'error',
        message
      })
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Stack spacing={8}>
      <Stack spacing={0}>
        <ReturnLink to={link}>Reserved GPUs</ReturnLink>
        <Heading as="h1" size="4xl">
          Reserve GPUs
        </Heading>
      </Stack>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Grid as={Form} templateColumns="2fr 1fr" gap={8}>
          <Stack spacing={16}>
            <Region regions={regions} />
            <Type regions={regions} types={types} />
            <Configuration />
            <Dates />
          </Stack>
          <div>
            <Stack spacing={5} position="sticky" top={8}>
              <Billing />
              <Summary />
            </Stack>
          </div>
          <InfoHandler />
        </Grid>
      </Formik>
    </Stack>
  )
}

export default ReservationsCreate
