import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Flex,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper
} from '@chakra-ui/react'
import { useField } from 'formik'
import Asterisk from '../Asterisk'

export const NumberField = ({
  isRequired = false,
  label,
  name,
  description,
  onUpdate,
  ...props
}) => {
  const [field, meta, helpers] = useField(name)

  const onChange = (value) => {
    helpers.setValue(Number(value))
    if (onUpdate) onUpdate(Number(value))
  }

  return (
    <FormControl isInvalid={meta.error && meta.touched}>
      <FormLabel>
        {isRequired && <Asterisk />}
        {label}
      </FormLabel>
      <Flex>
        <NumberInput
          {...field}
          {...props}
          value={field.value}
          isInvalid={meta.error}
          focusBorderColor={meta.error && 'none'}
          width="fit-content"
          onChange={onChange}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Flex
          alignItems="center"
          width="100%"
          bg="gray.100"
          color="gray.500"
          borderEndRadius="18px"
          px={3}
        >
          {description}
        </Flex>
      </Flex>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}
