import { Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { SkeletonTable } from '../../../../shared/Skeletons'
import { TableCell, TableError } from '../../../../shared/Table'
import { useOrgBuckets, useAccountBuckets } from '../../../../shared/hooks'
import TableRow from './table-row'

const List = ({ hasPermission }) => {
  const { orgId, projectId } = useParams()

  const { buckets, isLoadingBuckets, isErrorBuckets } =
    orgId && projectId
      ? useOrgBuckets({ orgId, projectId })
      : useAccountBuckets()
  const isTableEmpty = buckets?.length === 0

  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th w="25%">Name</Th>
            <Th w="15%">Location</Th>
            <Th w="20%">Bucket URL</Th>
            <Th w="15%">Created</Th>
            <Th w="5%" />
          </Tr>
        </Thead>
        {isLoadingBuckets && <SkeletonTable rows={10} columns={4} />}
        {isErrorBuckets ? (
          <TableError>Something went wrong loading the Buckets.</TableError>
        ) : (
          <Tbody>
            {isTableEmpty ? (
              <TableCell>There are no Buckets yet.</TableCell>
            ) : (
              buckets?.map((bucket, index) => (
                <TableRow
                  key={index}
                  row={bucket}
                  hasPermission={hasPermission}
                />
              ))
            )}
          </Tbody>
        )}
      </Table>
    </TableContainer>
  )
}

export default List
