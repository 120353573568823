import { Link as ReactLink, useParams } from 'react-router-dom'
import { Stack, Flex, Heading, Button } from '@chakra-ui/react'
import { Plus } from '../../shared/Icons'
import {
  useHasWriteProjectPermissions,
  useProjectVersion
} from '../../shared/hooks'
import { withOrgPathFallback } from '../../../router'
import routes from '../../../constants/routes'
import List from './list'
import Banner from './view/banner'

const Filesystems = () => {
  const { orgId, projectId } = useParams()
  const { isLegacy } = useProjectVersion({ projectId, orgId })
  const hasWriteProjectPermissions = orgId
    ? useHasWriteProjectPermissions({
        projectId,
        orgId
      })
    : true

  return (
    <Stack spacing={8}>
      <Heading as="h1" size="4xl">
        Filesystems
      </Heading>
      {isLegacy ? (
        <Banner />
      ) : (
        <>
          {hasWriteProjectPermissions && (
            <Flex justifyContent="flex-end">
              <Button
                colorScheme="offBlack"
                variant="outline"
                leftIcon={<Plus color="black" />}
                as={ReactLink}
                to={withOrgPathFallback(routes.dashboard.filesystems.create)}
              >
                Create Filesystem
              </Button>
            </Flex>
          )}
          <List hasPermission={hasWriteProjectPermissions} />
        </>
      )}
    </Stack>
  )
}

export default Filesystems
