import { shadowsOutline } from '../../constants/styles'

const NumberInput = {
  parts: ['field'],
  baseStyle: {
    field: {
      w: 24,
      h: 8,
      color: 'gray.700'
    }
  },
  sizes: {
    lg: {
      field: {
        borderRadius: '20px 6px 6px 20px'
      }
    },
    md: {
      field: {
        borderRadius: '20px 6px 6px 20px'
      }
    },
    sm: {
      field: {
        borderRadius: '20px 6px 6px 20px'
      }
    }
  },
  variants: {
    outline: () => ({
      field: {
        _focus: {
          boxShadow: shadowsOutline
        }
      }
    })
  }
}

export default NumberInput
