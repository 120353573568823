import { combineReducers } from 'redux'
import { reducer as oidcReducer } from 'redux-oidc'
import dashboard from './slices/dashboard'
import notifications from './slices/notifications'
import ui from './slices/ui'

const reducers = combineReducers({
  oidc: oidcReducer,
  notifications,
  dashboard,
  ui
})

export default reducers
