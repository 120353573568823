import { Divider, Grid, GridItem, Heading, Stack, Text } from '@chakra-ui/react'
import { formatDateTime } from '../../../../helpers/date'
import { Card } from '../../../shared/Cards'
import CopyButton from '../../../shared/CopyButton'
import Region from '../../../shared/Region'

const TextValue = (props) => (
  <Text color="gray.500" {...props}>
    {props.children}
  </Text>
)

const Details = ({ filesystem }) => {
  const {
    region,
    createdAt,
    name,
    description,
    id,
    type,
    size,
    mountEndpointRange,
    mountBasePath
  } = filesystem

  return (
    <Card as={Stack} spacing={8}>
      <Heading size="xl">Filesystem details</Heading>
      <Grid templateColumns="1fr 1fr" columnGap={16} rowGap={5}>
        <Stack spacing={0}>
          <Text>Location</Text>
          <Region slug={region} />
        </Stack>
        <Stack spacing={0}>
          <Text>Created</Text>
          <TextValue>{formatDateTime(createdAt)}</TextValue>
        </Stack>
        <Stack spacing={0}>
          <Text>Name</Text>
          <TextValue noOfLines={2}>{name}</TextValue>
        </Stack>
        <Stack spacing={0}>
          <Text>Description</Text>
          <TextValue noOfLines={2}>{description || '-'}</TextValue>
        </Stack>
        <Stack spacing={0}>
          <Text>Filesystem ID</Text>
          <CopyButton color="gray.500" value={id} />
        </Stack>
        <GridItem rowSpan={1} colSpan={2}>
          <Divider />
        </GridItem>
        <Stack spacing={0}>
          <Text>Type</Text>
          <TextValue>{type.toUpperCase()}</TextValue>
        </Stack>
        <Stack spacing={0}>
          <Text>Configuration</Text>
          <TextValue>Size {size} GiB</TextValue>
        </Stack>
        <GridItem rowSpan={1} colSpan={2}>
          <Divider />
        </GridItem>
        <Stack spacing={0}>
          <Text>Mount endpoint range</Text>
          <TextValue>
            {mountEndpointRange[0]} - {mountEndpointRange[1]}
          </TextValue>
        </Stack>
        <Stack spacing={0}>
          <Text>Mount base path</Text>
          <CopyButton color="gray.500" value={mountBasePath} />
        </Stack>
      </Grid>
    </Card>
  )
}

export default Details
