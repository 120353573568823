import useSWR from 'swr'
import { AccountFloatingIPs, OrgFloatingIPs } from '../../../api/floatingIPs'
import { serialize } from '../../../helpers/serializers'
import { INITIAL_PAGE, PER_PAGE } from '../Table'
import { swrOptions } from '../../../constants/swr-options'

export function useOrgFloatingIPs({
  orgId,
  projectId,
  page = INITIAL_PAGE,
  perPage = PER_PAGE
}) {
  const key = `/projects/${projectId}/floating-ips?page=${page}&per_page=${perPage}&org=${orgId}`
  const fetcher = () =>
    OrgFloatingIPs.fetchAll({ orgId, projectId, page, perPage })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  let floatingIps, pagination
  if (data) ({ floatingIps, ...pagination } = serialize(data))

  return {
    floatingIps,
    pagination,
    isLoading,
    isError: Boolean(error)
  }
}

export function useAccountFloatingIPs({
  page = INITIAL_PAGE,
  perPage = PER_PAGE
}) {
  const key = `/floating-ips?page=${page}&per_page=${perPage}`
  const fetcher = () => AccountFloatingIPs.fetchAll({ page, perPage })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  let floatingIps, pagination
  if (data) ({ floatingIps, ...pagination } = serialize(data))

  return {
    floatingIps,
    pagination,
    isLoading,
    isError: Boolean(error)
  }
}
