import {
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Td,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'
import routes from '../../../../constants/routes'
import { formatDateTime } from '../../../../helpers/date'
import { withOrgPathFallback } from '../../../../router'
import { Dots, Gear, Trash } from '../../../shared/Icons'
import Region from '../../../shared/Region'
import Spinner from '../../../shared/Spinner'
import { TdTruncated } from '../../../shared/Table'
import { DeleteModal } from '../modals/delete'

const TableRow = ({ row, hasPermission }) => {
  const { id, name, region, description, type, size, createdAt } = row
  const disclosure = useDisclosure()

  const link = withOrgPathFallback(
    routes.dashboard.filesystems.view.replace(':filesystemId', id)
  )
  const isLoading = row.status === 'deleting'

  return (
    <Tr>
      <TdTruncated data-hj-suppress>
        <Link colorScheme="gray" as={ReactLink} to={link}>
          {name}
        </Link>
      </TdTruncated>
      <Td>
        <Region slug={region} />
      </Td>
      <TdTruncated data-hj-suppress color="gray.500">
        {description || '-'}
      </TdTruncated>
      <Td>{type.toUpperCase()}</Td>
      <Td>{size}GiB</Td>
      <Td>{formatDateTime(createdAt)}</Td>
      <Td isNumeric>
        {isLoading && <Spinner mr={3} color="gray.500" />}
        {hasPermission && !isLoading && (
          <Menu variant="table" autoSelect={false}>
            <MenuButton variant="icon" as={IconButton} icon={<Dots />} />
            <MenuList>
              <MenuItem
                gap={2}
                as={ReactLink}
                to={link}
                _hover={{ textDecoration: 'none', color: 'black' }}
              >
                <Gear boxSize={5} />
                Filesystem settings
              </MenuItem>
              <MenuDivider />
              <MenuItem gap={2} onClick={disclosure.onOpen}>
                <Trash boxSize={5} />
                Delete
              </MenuItem>
              <DeleteModal filesystem={row} disclosure={disclosure} />
            </MenuList>
          </Menu>
        )}
      </Td>
    </Tr>
  )
}

export default TableRow
