import { Grid, Stack, Heading } from '@chakra-ui/react'
import { useParams, useHistory } from 'react-router-dom'
import { generate } from 'generate-password'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import { mutate } from 'swr'
import { toastMessages } from '../../../../constants/toast-messages'
import { ReturnLink } from '../../../shared/Links'
import { withOrgPathFallback } from '../../../../router'
import routes from '../../../../constants/routes'
import SupportError from '../../../Errors/SupportError'
import {
  useAccountCommunityConfigs,
  useOrgCommunityConfigs
} from '../../../shared/hooks'
import {
  OrgCommunityInstances,
  AccountCommunityInstances
} from '../../../../api/communityInstances'
import { useToast } from '../../../shared/Toast'
import { apiErrorHandler } from '../../../shared/ApiErrorHandler'
import { PASSWORD_OPTIONS } from '../../../../constants/defaults'
import Authentication from './authentication'
import Billing from './billing'
import Configuration from './configuration'
import Name from './name'
import Type from './type'
import Summary from './summary'
import { ConfigsHandler, transformPayload } from '../utils'

const initialValues = {
  name: '',
  type: {
    options: [],
    selected: {
      value: '',
      label: ''
    }
  },
  instancesGroupByType: {},
  configuration: {
    options: [],
    selected: {
      value: '',
      label: '',
      country: '',
      gpuName: '',
      gpuType: '',
      region: '',
      gpuCost: '',
      cpuCount: 0,
      gpuCount: 0,
      ram: 0,
      storage: 0
    }
  },
  authentication: {
    password: generate(PASSWORD_OPTIONS),
    hasCopied: false
  },
  isEmpty: true
}

const validationSchema = yup.object().shape({
  name: yup.string().required('Instance name is required.'),
  type: yup.object().shape({
    selected: yup.object().shape({
      value: yup.string().required('Instance type is required.'),
      label: yup.string()
    })
  }),
  authentication: yup.object().shape({
    password: yup.string().required('Password is required.'),
    hasCopied: yup.boolean().oneOf([true], 'Copy and save the password.')
  })
})

const CommunityInstancesCreate = () => {
  const { orgId, projectId } = useParams()
  const history = useHistory()
  const toast = useToast()

  const {
    communityConfigs,
    isLoadingCommunityConfigs: isLoading,
    isErrorCommunityConfigs: isError
  } = orgId
    ? useOrgCommunityConfigs({ orgId, projectId })
    : useAccountCommunityConfigs()

  const noData = communityConfigs?.length === 0
  const list = withOrgPathFallback(routes.dashboard.communityInstances.index)

  if (isError) {
    return <SupportError />
  }

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true)
    const payload = transformPayload(values)

    try {
      if (orgId) {
        await OrgCommunityInstances.create({ orgId, projectId, payload })
        await mutate(
          `/projects/${projectId}/community/valdi/instances?org=${orgId}`
        )
      } else {
        await AccountCommunityInstances.create({
          payload
        })
        await mutate('/community/valdi/instances')
      }

      toast({
        status: 'success',
        message: toastMessages.communityInstanceCreateSuccess
      })
      resetForm()
      history.push(list)
    } catch (error) {
      const message = await apiErrorHandler(error)
      toast({
        status: 'error',
        message
      })
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Stack spacing={8}>
      <Stack spacing={0}>
        <ReturnLink to={list}>Community Instances</ReturnLink>
        <Heading as="h1" size="4xl">
          Create new Instance
        </Heading>
      </Stack>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {() => (
          <Form width="100%">
            <Grid templateColumns="2fr 1fr" gap={8}>
              <Stack spacing={16}>
                <Name isLoading={isLoading} />
                <Type isLoading={isLoading} noData={noData} />
                <Configuration isLoading={isLoading} noData={noData} />
                <Authentication isLoading={isLoading} />
              </Stack>
              <div>
                <Stack spacing={5} position="sticky" top={8}>
                  <Billing isLoading={isLoading} />
                  <Summary isLoading={isLoading} />
                </Stack>
              </div>
              <ConfigsHandler />
            </Grid>
          </Form>
        )}
      </Formik>
    </Stack>
  )
}

export default CommunityInstancesCreate
