import React, { useCallback, useState, useEffect } from 'react'
import {
  Button,
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text
} from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import PhoneInput from '../PhoneInput'
import { verifyPhone, getMetadata } from '../../../api/account'
import validate from './validate'
import ApiErrorMessage from '../ApiErrorMessage'
import {
  setIsVerifiedStarted,
  setIsVerified,
  setIsVerifiedFinished
} from '../../../slices/ui/phoneVerification'
import Asterisk from '../Asterisk'
import { useIsMounted } from '../hooks/useIsMounted'

const PhoneForm = () => {
  const [countryId, setCountryId] = useState()
  const [phoneNumber, setPhoneNumber] = useState()
  const [validationError, setValidationError] = useState()
  const [apiError, setApiError] = useState()
  const user = useSelector((state) => state.oidc.user)
  const isLoading = useSelector((state) => state.ui.phoneVerification.isLoading)
  const isMounted = useIsMounted()
  const dispatch = useDispatch()

  useEffect(() => {
    if (user) {
      getMetadata()
        .then((metadata) => {
          if (metadata.country) {
            setCountryId(metadata.country)
          } else {
            setCountryId(null)
          }
        })
        .catch(() => {
          setCountryId(null)
        })
    }
  }, [user])

  const handleSendCode = useCallback(
    (event) => {
      event.preventDefault()
      if (!phoneNumber) {
        setValidationError('Phone number is required')
        return
      }
      const error = validate(phoneNumber, countryId)
      setValidationError(error)
      if (!error) {
        dispatch(setIsVerifiedStarted())
        verifyPhone({ phoneNumber, country: countryId })
          .then(() => {
            dispatch(
              setIsVerified({
                isVerified: true,
                phoneNumber,
                countryId
              })
            )
          })
          .catch(async (e) => {
            try {
              const data = await e.response.json()
              setApiError(data.code)
            } catch (e) {
              setApiError(e)
            }
          })
          .finally(() => {
            if (isMounted()) {
              dispatch(setIsVerifiedFinished())
            }
          })
      }
    },
    [countryId, phoneNumber]
  )

  const resetErrors = () => {
    setValidationError(null)
    setApiError(null)
  }

  return (
    <VStack spacing={8} w="full">
      <Text color="gray.500" size="md" fontWeight="normal" textAlign="center">
        {`You won't be able to access computing resources until your phone number is verified.`}
      </Text>
      <form onSubmit={handleSendCode} style={{ width: '100%' }}>
        <FormControl isInvalid={validationError} mb={8}>
          <FormLabel htmlFor="phoneNumber">
            <Asterisk />
            Phone number
          </FormLabel>
          <PhoneInput
            id="phoneNumber"
            countryId={countryId}
            isDisabled={isLoading}
            onCountryChange={(country) => {
              setCountryId(country.id)
              resetErrors()
            }}
            onPhoneNumberChange={(phoneNumber) => {
              setPhoneNumber(phoneNumber)
              resetErrors()
            }}
          />
          <FormErrorMessage>{validationError}</FormErrorMessage>
          <ApiErrorMessage mt={2} code={apiError} />
        </FormControl>
        <Button isFullWidth isLoading={isLoading} type="submit">
          Send code
        </Button>
      </form>
    </VStack>
  )
}

export default PhoneForm
