import {
  Box,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Td,
  Text
} from '@chakra-ui/react'
import { useCallback } from 'react'
import { mutate } from 'swr'
import { Organization } from '../../../../../../api/organizations'
import { toastMessages } from '../../../../../../constants/toast-messages'
import { humanize } from '../../../../../../helpers/string'
import { ArrowDown } from '../../../../../shared/Icons'
import { canEditRole, getRoles } from '../utils'
import { useToast } from '../../../../../shared/Toast'
import { useOrganization } from '../../../../../shared/hooks/organization'

const RoleCell = ({ accountInfo, memberInfo }) => {
  const { organization } = useOrganization()
  const toast = useToast()

  const handleClick = useCallback(
    async (role) => {
      try {
        await Organization.updateMember(organization.id, memberInfo.accountId, {
          role
        })
        await mutate((key) =>
          key.startsWith(`/organizations/${organization.id}/members?`)
        )
        toast({
          status: 'success',
          message: toastMessages.memberUpdateSuccess
        })
      } catch {
        toast({
          status: 'error',
          message: toastMessages.memberUpdateError
        })
      }
    },
    [memberInfo]
  )

  return (
    <Td textTransform="capitalize">
      {canEditRole(accountInfo, memberInfo) ? (
        <Menu variant="table" autoSelect={false}>
          {({ isOpen }) => (
            <>
              <MenuButton>
                <HStack spacing={2}>
                  <Text color="gray.500">{humanize(memberInfo.role)}</Text>
                  <ArrowDown transform={isOpen ? 'rotate(180deg)' : 'none'} />
                </HStack>
              </MenuButton>
              <MenuList minWidth="min-content">
                {Object.entries(getRoles())
                  .filter(
                    ([key, _]) => key !== memberInfo.role && key !== 'OWNER'
                  )
                  .map(([key, value]) => (
                    <Box key={key} onClick={() => handleClick(key)}>
                      <MenuItem>{value.name}</MenuItem>
                      <MenuDivider />
                    </Box>
                  ))}
              </MenuList>
            </>
          )}
        </Menu>
      ) : (
        <Text color="gray.500">{humanize(memberInfo.role)}</Text>
      )}
    </Td>
  )
}

export default RoleCell
