import {
  Box,
  Divider,
  LinkBox,
  LinkOverlay,
  MenuItem,
  MenuOptionGroup,
  Skeleton,
  Spacer
} from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import routes from '../../../../constants/routes'
import { getDefaultProjectId } from '../../../../helpers/organizations'
import { withOrgPath } from '../../../../router'
import { setIsOrgSuspended } from '../../../../slices/dashboard'
import { Account, ArrowSwitch } from '../../../shared/Icons'
import { useOrganizations } from '../../../shared/hooks/organization'
import { useOrgParam } from '../../../shared/hooks/useOrgParam'

const SwitchAccount = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const orgId = useOrgParam()
  const user = useSelector((state) => state.oidc.user)
  const { organizations, isError, isLoading } = useOrganizations({
    perPage: 50
  })

  if (isLoading) {
    return <Skeleton h={3.5} w={36} />
  }
  if (isError) {
    return null
  }

  return (
    <>
      <MenuOptionGroup>
        <Box color="gray.300" px={4} py={1}>
          <ArrowSwitch mr={3} />
          Switch to
        </Box>
      </MenuOptionGroup>
      {orgId && (
        <MenuItem>
          <LinkBox>
            <Account width="16px" />
            <LinkOverlay
              onClick={() => {
                history.push(routes.dashboard.index)
                dispatch(setIsOrgSuspended(false))
              }}
            >
              {user.profile.name}
            </LinkOverlay>
          </LinkBox>
        </MenuItem>
      )}
      {organizations?.organizations
        .filter((organization) => organization.id !== orgId)
        .map((organization) => (
          <MenuItem key={organization?.id}>
            <LinkBox>
              <Spacer />
              <LinkOverlay
                onClick={() => {
                  const isSuspended = Boolean(organization?.suspendedAt)
                  dispatch(setIsOrgSuspended(isSuspended))
                  history.push(
                    withOrgPath(
                      routes.dashboard.index,
                      organization.id,
                      getDefaultProjectId(organization)
                    )
                  )
                }}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {organization?.name}
              </LinkOverlay>
            </LinkBox>
          </MenuItem>
        ))}
      <Divider my={1} />
    </>
  )
}

export default SwitchAccount
