import {
  Button,
  Grid,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { Link as ReactLink } from 'react-router-dom'
import routes from '../../../../../constants/routes'
import {
  caclMonthlyPrice,
  calcDiscount,
  calcPrices,
  createNextRenewalDate
} from '../../../../../helpers/billing'
import { formatCurrency } from '../../../../../helpers/currency'
import { withOrgPathFallback } from '../../../../../router'

const Configuration = () => {
  const { values } = useFormikContext()
  const selected = values?.type?.configurations?.selected

  return (
    <Stack spacing={0}>
      <Text size="sm" fontWeight={600} ml={1.5}>
        Instance
      </Text>
      <TableContainer mb={8}>
        <Table variant="card">
          <Tbody>
            {selected?.gpu && (
              <>
                <Tr>
                  <Td>GPUs</Td>
                  <Td>{selected?.gpu.value}x</Td>
                </Tr>
              </>
            )}
            <Tr>
              <Td>vCPUs</Td>
              <Td>{selected?.vcpu.value}x</Td>
            </Tr>
            <Tr>
              <Td>Memory</Td>
              <Td>{selected?.memory.value} GiB</Td>
            </Tr>
            <Tr>
              <Td>Disk</Td>
              <Td>{selected?.storage.value} GiB</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  )
}

const Prices = () => {
  const { values } = useFormikContext()
  const { amount, intervalCount, discount, credits } = values.billing
  const monthlyPrice = caclMonthlyPrice(amount, intervalCount)
  const discountValue = calcDiscount(amount, discount, credits)
  const renewalDate = createNextRenewalDate(intervalCount)
  const { totalPrice, creditsValue } = calcPrices(
    amount,
    discountValue,
    credits
  )

  return (
    <Stack spacing={0} flexGrow={1}>
      <Grid templateColumns="2fr 1fr" columnGap={3} px={1.5}>
        <Text color="gray.500">{values?.billing.name}</Text>
        <Text textAlign="end">
          {formatCurrency(monthlyPrice)}/{values?.billing.unit}
        </Text>
        <Text color="gray.500">Price</Text>
        <Text size="sm" textAlign="end">
          {formatCurrency(amount)}
        </Text>
        {Boolean(discountValue) && (
          <>
            <Text color="gray.500">Discount (account level)</Text>
            <Text size="sm" textAlign="end">
              -{formatCurrency(discountValue)}
            </Text>
          </>
        )}
        {Boolean(creditsValue) && (
          <>
            <Text color="gray.500">Credits</Text>
            <Text size="sm" textAlign="end">
              {formatCurrency(creditsValue)}
            </Text>
          </>
        )}
        <Text color="gray.500">Renews automatically</Text>
        <Text size="sm" textAlign="end">
          {renewalDate}
        </Text>
        <Text color="gray.500">Total cost (excl. VAT)</Text>
        <Text fontWeight="600" textAlign="end" color="blue.500">
          {formatCurrency(totalPrice)}
        </Text>
      </Grid>
    </Stack>
  )
}

const NewSubModal = ({ disclosure }) => {
  const { isOpen, onClose } = disclosure
  const { values, submitForm, isSubmitting } = useFormikContext()
  const { amount, discount, credits } = values.billing
  const discountValue = calcDiscount(amount, discount, credits)
  const { totalPrice } = calcPrices(amount, discountValue, credits)

  const handleSubmit = () => {
    onClose()
    submitForm()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Heading size="xl" textAlign="center" mb={4}>
            Create an Instance (Billed: Long-term)
          </Heading>
          <Stack spacing={8}>
            <Text size="sm" textAlign="center" color="gray.500">
              You are required to pay the full amount of the Instance up front
              to guarantee it for the subscription period. Additional resources
              will be billed on demand.
            </Text>
            <Text size="sm" textAlign="center" color="red.500">
              Note: Destroying the Instance does not cancel your subscription.
              To cancel, please navigate to{' '}
              <Link
                as={ReactLink}
                to={withOrgPathFallback(routes.dashboard.billing)}
                colorScheme="red"
              >
                Billing
              </Link>
              .
            </Text>
            <Configuration />
            <Prices />
            <Text size="sm" textAlign="center" color="gray.500">
              You may receive an email to authenticate the payment using 3D
              Security, as required by your bank account.
            </Text>
            <Stack spacing={4}>
              <Button isLoading={isSubmitting} onClick={handleSubmit}>
                Create Instance for {formatCurrency(totalPrice)}
              </Button>
              <Button
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isSubmitting}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default NewSubModal
