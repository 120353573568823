import userManager from './userManager'

const authPrompt = (prompt, args) => {
  userManager.signinRedirect({
    prompt: prompt !== 'login' ? prompt : null,
    ...args
  })
}

export default authPrompt
