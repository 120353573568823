import { configureStore } from '@reduxjs/toolkit'
import { loadUser, processSilentRenew } from 'redux-oidc'
import routes from './constants/routes'
import userManager from './helpers/auth/userManager'
import reducers from './reducers'

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

if (window.location.pathname !== routes.silentRenew) {
  loadUser(store, userManager)

  userManager.events.addAccessTokenExpiring(function () {
    processSilentRenew()
    loadUser(store, userManager)
  })

  userManager.events.addUserSignedOut(function () {
    userManager.signoutRedirect()
  })
}

export default store
