import { createBrowserHistory } from 'history'
import { ChakraProvider } from '@chakra-ui/react'
import React from 'react'
import TagManager from 'react-gtm-module'
import { Provider } from 'react-redux'
import Router from './router'
import store from './store'
import theme from './theme'
import Fonts from './theme/components/Fonts'

const GOOGLE_TAGS_MANAGER = process.env.REACT_APP_GOOGLE_TAGS_MANAGER
const tagManagerArgs = {
  gtmId: GOOGLE_TAGS_MANAGER
}

const history = createBrowserHistory()
const ENVIRONMENT = process.env.REACT_APP_ENV

TagManager.initialize(tagManagerArgs)

history.listen(() => {
  if (ENVIRONMENT === 'staging' || ENVIRONMENT === 'development') {
    window.analytics && window.analytics.page()
  }
})

const App = () => {
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <Fonts />
        <div className="App">
          <Router history={history} />
        </div>
      </ChakraProvider>
    </Provider>
  )
}

export default App
