import { UsageTypeProvider, UsageTypeRadioGroup } from '../../shared/usageType'
import UsageTypeForm from './UsageTypeForm'

const OrganizationForm = () => (
  <UsageTypeProvider>
    <UsageTypeRadioGroup />
    <UsageTypeForm />
  </UsageTypeProvider>
)

export default OrganizationForm
