import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Box,
  Input
} from '@chakra-ui/react'
import { useField } from 'formik'
import Asterisk from '../Asterisk'

export const InputField = ({
  isRequired = false,
  label,
  info,
  onUpdate,
  ...props
}) => {
  const [field, meta, helpers] = useField(props)

  const onChange = (event) => {
    const { value } = event.target
    helpers.setValue(value)
    if (onUpdate) onUpdate(value)
  }

  return (
    <FormControl isInvalid={meta.error && meta.touched}>
      <FormLabel display="flex" me={0}>
        {isRequired && <Asterisk />}
        <Box flexGrow={1}>{label}</Box>
        {info}
      </FormLabel>
      <Input type="text" {...field} {...props} onChange={onChange} />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}
