import { useState, useEffect } from 'react'
import { Stack, Button } from '@chakra-ui/react'
import { Plus } from '../../../../shared/Icons'
import { OrganizationNotFound } from '../../../../Errors/OrganizationNotFound'
import {
  useIsRole,
  useOrganization
} from '../../../../shared/hooks/organization'
import { useQuery } from '../../../../shared/hooks/useQuery'
import { InvitationModal } from './modals'
import MembersList from './list-members'
import InvitationsList from './list-invitations'

const Invitation = ({ isModalOpen }) => {
  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    if (typeof isModalOpen === 'boolean') {
      setModalVisible(isModalOpen)
    }
  }, [isModalOpen])

  return (
    <>
      <Button
        onClick={() => setModalVisible(true)}
        variant="outline"
        colorScheme="offBlack"
        leftIcon={<Plus color="black" />}
        alignSelf="flex-end"
        width="fit-content"
      >
        Invite Members
      </Button>
      <InvitationModal
        visible={modalVisible}
        onVisibilityChange={(visible) => setModalVisible(visible)}
      />
    </>
  )
}

const Members = () => {
  const { organization } = useOrganization()
  const isAdmin = useIsRole(organization, 'ADMIN')
  const { invite_members } = useQuery()

  if (!organization) return <OrganizationNotFound />

  return (
    <Stack spacing={8}>
      {isAdmin && <Invitation isModalOpen={invite_members === 'true'} />}
      <MembersList />
      <InvitationsList />
    </Stack>
  )
}

export default Members
