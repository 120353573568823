import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import routes from '../../../../constants/routes'
import { toastMessages } from '../../../../constants/toast-messages'
import { withOrgPathFallback } from '../../../../router'
import { useConfigs } from '../../../shared/hooks'

export const SwitchHandler = () => {
  const { projectId } = useParams()
  const { resetForm } = useFormikContext()
  const { instances, isLoadingConfigs, isErrorConfigs } = useConfigs({})

  useEffect(() => {
    if (instances && !isLoadingConfigs && !isErrorConfigs) {
      resetForm()
    }
  }, [projectId])

  return null
}

export const mutateHandler = () => {
  mutate((key) => key.startsWith('/products-configs'))
}

export const successHandler = (response, history, toast) => {
  const instanceId = response?.instance?.id

  if (instanceId) {
    toast({
      status: 'success',
      message: toastMessages.instanceCreateSuccess
    })
    history.push(
      withOrgPathFallback(
        routes.dashboard.instances.view.replace(':instanceId', instanceId)
      )
    )
  }
}
