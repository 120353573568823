const roles = {
  OWNER: {
    name: 'Owner',
    weight: 4
  },
  ADMIN: {
    name: 'Admin',
    weight: 3
  },
  BILLING_MANAGER: {
    name: 'Billing Manager',
    weight: 2
  },
  MEMBER: {
    name: 'Member',
    weight: 1
  }
}

export function getRoles() {
  return roles
}

export function mapBackendRole(backendRole, isOwner) {
  if (backendRole === 'ADMIN' && isOwner) {
    return 'OWNER'
  } else {
    return backendRole
  }
}

export function getRoleWeight(role) {
  return roles[role].weight
}

export function canEditRole(self, member) {
  return (
    (self.role === 'OWNER' || self.role === 'ADMIN') &&
    getRoleWeight(self.role) >= getRoleWeight(member.role) &&
    self.accountId !== member.accountId
  )
}
