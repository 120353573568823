import { Grid, Heading, Stack, Tooltip } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { mutate } from 'swr'
import * as yup from 'yup'
import {
  AccountObjectStorage,
  OrgObjectStorage
} from '../../../../../api/objectStorage'
import { DEFAULT_REGION } from '../../../../../constants/defaults'
import { objectStorageRegions } from '../../../../../constants/regions'
import routes from '../../../../../constants/routes'
import { toastMessages } from '../../../../../constants/toast-messages'
import { apiErrorHandler } from '../../../../shared/ApiErrorHandler'
import { withOrgPathFallback } from '../../../../../router'
import { InputField, RegionField } from '../../../../shared/Fields'
import { InfoTooltip } from '../../../../shared/Icons'
import { ReturnLink } from '../../../../shared/Links'
import { enableRegions } from '../../../../shared/Region'
import { useToast } from '../../../../shared/Toast'
import { useIsMounted } from '../../../../shared/hooks/useIsMounted'
import Summary from './summary'

const OBJECT_STORAGE_ENABLED_REGIONS =
  process.env.REACT_APP_OBJECT_STORAGE_ENABLED_REGIONS
const enabledRegions = OBJECT_STORAGE_ENABLED_REGIONS?.split(',') || []

const initialValues = {
  region: DEFAULT_REGION,
  name: ''
}

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required(`Name is required.`)
    .min(3, 'Name must be at least 3 characters.')
    .matches(
      '^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\\.[a-z0-9](?:[a-z0-9]{0,61}[a-z0-9])?)*$',
      'Invalid name.'
    )
})

const BucketNameInfo = () => (
  <Tooltip
    hasArrow
    placement="top"
    label="The name can only contain lowercase letters, numbers, dots & hyphens. Each label can be dot separated, and must start and end with a letter or a number."
  >
    <div>
      <InfoTooltip />
    </div>
  </Tooltip>
)

const BucketsCreate = () => {
  const { orgId, projectId } = useParams()
  const history = useHistory()
  const isMounted = useIsMounted()
  const toast = useToast()
  const regions = enableRegions(objectStorageRegions, enabledRegions)

  const handleSubmit = useCallback(
    async (data, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      try {
        if (orgId) {
          await OrgObjectStorage.createBucket({
            projectId,
            orgId,
            data
          })
          await mutate((key) =>
            key.startsWith(`/projects/${projectId}/buckets`)
          )
        } else {
          await AccountObjectStorage.createBucket(data)
          await mutate((key) => key.startsWith('/buckets'))
        }
        toast({
          status: 'success',
          message: toastMessages.bucketCreateSuccess
        })
        resetForm()
        history.push(withOrgPathFallback(routes.dashboard.buckets.index))
      } catch (error) {
        const message = await apiErrorHandler(error)
        toast({
          status: 'error',
          message
        })
      } finally {
        if (isMounted()) {
          setSubmitting(false)
        }
      }
    },
    [orgId, projectId]
  )

  return (
    <Stack spacing={8}>
      <Stack spacing={0}>
        <ReturnLink to={withOrgPathFallback(routes.dashboard.buckets.index)}>
          Object Storage
        </ReturnLink>
        <Heading as="h1" size="4xl">
          Create new Bucket
        </Heading>
      </Stack>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form width="100%">
          <Grid templateColumns="2fr 1fr" gap={8}>
            <Stack spacing={16}>
              <RegionField name="region" regions={regions} />
              <Stack spacing={8}>
                <Heading size="xl">Configuration</Heading>
                <Grid templateColumns="1fr 1fr" rowGap={8} columnGap={5}>
                  <InputField
                    label="Name"
                    name="name"
                    placeholder="Enter bucket name"
                    info={<BucketNameInfo />}
                    isRequired
                  />
                </Grid>
              </Stack>
            </Stack>
            <Summary />
          </Grid>
        </Form>
      </Formik>
    </Stack>
  )
}

export default BucketsCreate
