const Accordion = {
  baseStyle: {
    container: {
      borderBottomWidth: '1px',
      borderTopWidth: '0px'
    },
    button: {
      pl: '0px'
    },
    panel: { p: '0px', ml: '24px' }
  }
}

export default Accordion
