import { useParams } from 'react-router-dom'
import { Stack, SimpleGrid, Heading, Text, Skeleton } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { Error } from '../../../../shared/Error'
import { DatePickerField } from '../../../../shared/Fields'
import {
  getReservationInfo,
  getDisabledDates,
  isDateInRange,
  daysInRange,
  getMinDate,
  getMaxDate
} from '../utils'
import {
  useOrgReservationsInfo,
  useAccountReservationsInfo
} from '../../../../shared/hooks'

const renderDays = (day, date, reservationsInfo) => {
  const info = getReservationInfo(reservationsInfo, date)
  const hasDiscount = info?.discount > 0

  return (
    <Stack spacing={0} h="full">
      <span>{day}</span>
      {hasDiscount && <Text color="green.300">-20%</Text>}
    </Stack>
  )
}

const Dates = () => {
  const { orgId } = useParams()
  const { values, setValues } = useFormikContext()
  const { region, type, size, dates } = values
  const noQuota = dates.quota.length === 0

  const { isLoadingInfo, isErrorInfo } = orgId
    ? useOrgReservationsInfo({ orgId, region, type })
    : useAccountReservationsInfo({
        region,
        type
      })

  const disabledDates = getDisabledDates(dates.quota, size)

  const onChange = (dates) => {
    let [start, end] = dates
    let isRangeValid = true
    let days = 0

    if (start && end) {
      isRangeValid = !disabledDates?.some((date) =>
        isDateInRange(date, start, end)
      )

      end = isRangeValid ? end : null
      days = isRangeValid ? daysInRange(start, end) : 0
    }

    setValues((prev) => ({
      ...prev,
      dates: {
        ...prev.dates,
        start,
        end,
        days,
        isRangeValid
      }
    }))
  }

  return (
    <Stack spacing={8}>
      <Stack spacing={1}>
        <Heading size="xl">Select dates</Heading>
        <Text color="gray.500" size="sm">
          Contact support for the requests longer than 4 weeks.
        </Text>
      </Stack>
      <SimpleGrid columns={2} gap={5}>
        {isLoadingInfo ? (
          <Skeleton h="70px" />
        ) : isErrorInfo ? (
          <Error />
        ) : (
          <DatePickerField
            popperPlacement="bottom-start"
            name="dates"
            label="Select dates"
            placeholderText="dd/mm/yyyy - dd/mm/yyyy"
            minDate={getMinDate()}
            maxDate={getMaxDate(dates.quota)}
            startDate={dates.start}
            endDate={dates.end}
            onChange={onChange}
            excludeDates={disabledDates}
            disabled={noQuota}
            renderDayContents={(day, date) =>
              renderDays(day, date, dates.quota)
            }
            monthsShown={2}
            selectsRange
            isRequired
          />
        )}
      </SimpleGrid>
    </Stack>
  )
}

export default Dates
