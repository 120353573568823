import {
  Button,
  Grid,
  HStack,
  Heading,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { formatDateTime } from '../../../../../helpers/date'
import { Card } from '../../../../shared/Cards'
import CopyButton from '../../../../shared/CopyButton'
import { Edit } from '../../../../shared/Icons'
import Region from '../../../../shared/Region'
import { RenameModal } from '../modals'

const Rename = ({ securityGroup, isEditable }) => {
  const disclosure = useDisclosure()

  return (
    <Card as={Stack} spacing={8}>
      <HStack justify="space-between">
        <Heading size="xl">Security Group details</Heading>
        {isEditable && (
          <>
            <Button
              colorScheme="offBlack"
              variant="outline"
              leftIcon={<Edit />}
              onClick={disclosure.onOpen}
            >
              Rename Security Group
            </Button>
            <RenameModal
              disclosure={disclosure}
              securityGroup={securityGroup}
            />
          </>
        )}
      </HStack>
      <Grid templateColumns="1fr 1fr" columnGap={16} rowGap={5}>
        <Stack spacing={0}>
          <Text>Location</Text>
          <Region slug={securityGroup.region} />
        </Stack>
        <Stack spacing={0}>
          <Text>Created</Text>
          <Text color="gray.500">
            {formatDateTime(securityGroup.createdAt)}
          </Text>
        </Stack>
        <Stack spacing={0}>
          <Text>Name</Text>
          <Text color="gray.500" noOfLines={2}>
            {securityGroup.name}
          </Text>
        </Stack>
        <Stack spacing={0}>
          <Text>Description</Text>
          <Text color="gray.500" noOfLines={2}>
            {securityGroup.description || '-'}
          </Text>
        </Stack>
        <Stack spacing={0}>
          <Text>Security Group ID</Text>
          <CopyButton color="gray.500" value={securityGroup.id} />
        </Stack>
      </Grid>
    </Card>
  )
}

export default Rename
