import { Heading, Stack } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import routes from '../../../constants/routes'
import { Tab, Tabs } from '../../shared/Tabs'
import { useProjectVersion } from '../../shared/hooks'

const Network = ({ children }) => {
  const { projectId, orgId } = useParams()
  const { isB2, isLoadingProjectVersion } = useProjectVersion({
    projectId,
    orgId
  })

  const isFloatingIPsAvailable = !isLoadingProjectVersion && isB2

  return (
    <Stack spacing={8}>
      <Heading as="h1" size="4xl">
        Network
      </Heading>
      <Tabs>
        <Tab url={routes.dashboard.securityGroups.index}>Security Groups</Tab>
        {isFloatingIPsAvailable && (
          <Tab url={routes.dashboard.floatingIPs.index}>Floating IPs</Tab>
        )}
      </Tabs>
      {children}
    </Stack>
  )
}

export default Network
