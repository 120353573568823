import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { useCallback } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { mutate } from 'swr'
import * as yup from 'yup'
import { Organization } from '../../../api/organizations'
import routes from '../../../constants/routes'
import { toastMessages } from '../../../constants/toast-messages'
import { withOrgPathFallback } from '../../../router'
import TagSelector from '../TagSelector'
import { useToast } from '../Toast'
import { useIsMounted } from '../hooks/useIsMounted'
import { businessIndustries, businessSize, interests, roles } from './constants'

const Business = ({ id }) => {
  const history = useHistory()
  const isMounted = useIsMounted()
  const toast = useToast()
  const match = useRouteMatch({
    path: '/onboarding'
  })

  const initialValues = {
    businessSize: '',
    businessIndustry: '',
    businessIndustryOther: '',
    interests: [],
    interestsOther: '',
    role: '',
    roleOther: ''
  }

  const schema = yup.object().shape({
    businessSize: yup
      .string()
      .oneOf(Object.keys(businessSize), 'Business size type is invalid'),
    businessIndustry: yup
      .string()
      .oneOf(
        Object.keys(businessIndustries),
        'Business industry type is invalid'
      ),
    businessIndustryOther: yup.string(),
    interests: yup.array(),
    interestsOther: yup.string(),
    role: yup.string().oneOf(Object.keys(roles), 'Role is invalid'),
    roleOther: yup.string()
  })

  const handleSubmit = useCallback(
    async (
      {
        businessSize,
        businessIndustry,
        businessIndustryOther,
        interests,
        interestsOther,
        role,
        roleOther
      },
      { setSubmitting }
    ) => {
      setSubmitting(true)
      try {
        const data = {}
        if (businessSize) {
          data.businessSize = businessSize
        }
        if (businessIndustry) {
          data.businessIndustry = businessIndustry
        }
        if (businessIndustryOther) {
          data.businessIndustryOther = businessIndustryOther
        }
        if (interests && interests.length > 0) {
          data.interests = interests
        }
        if (interestsOther) {
          data.interestsOther = interestsOther
        }
        if (role) {
          data.role = role
        }
        if (roleOther) {
          data.roleOther = roleOther
        }
        const result = await Organization.updateInfoById(id, data)
        await mutate(`/organizations/${result.id}`)
        history.push(
          match
            ? routes.onboarding.payment
            : withOrgPathFallback(routes.dashboard.organizations.create.payment)
        )
      } catch {
        toast({
          status: 'error',
          message: toastMessages.organizationUpdateError
        })
      } finally {
        if (isMounted()) {
          setSubmitting(false)
        }
      }
    },
    [history]
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isSubmitting, values, errors }) => (
        <Form style={{ width: '100%' }} onSubmit={handleSubmit}>
          <Stack spacing={8}>
            <FormControl isInvalid={errors.businessSize}>
              <FormLabel>How many people belong to your business?</FormLabel>
              <RadioGroup>
                <Stack spacing={2}>
                  {Object.entries(businessSize).map(([key, value]) => (
                    <Field
                      key={key}
                      as={Radio}
                      type="radio"
                      name="businessSize"
                      id={key}
                      value={key}
                    >
                      {value}
                    </Field>
                  ))}
                </Stack>
              </RadioGroup>
              <FormErrorMessage>{errors.businessSize}</FormErrorMessage>
            </FormControl>
            <Stack spacing={4}>
              <FormControl isInvalid={errors.businessIndustry}>
                <FormLabel>
                  In which industries does your business operate?
                </FormLabel>
                <Field as={Select} name="businessIndustry" placeholder="Select">
                  {Object.entries(businessIndustries).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </Field>
                <FormErrorMessage>{errors.businessIndustry}</FormErrorMessage>
              </FormControl>
              {values.businessIndustry === 'OTHER' && (
                <FormControl>
                  <Field as={Input} name="businessIndustryOther" />
                </FormControl>
              )}
            </Stack>
            <Stack spacing={4}>
              <FormControl isInvalid={errors.interests}>
                <FormLabel>
                  What are you interested in doing with Genesis Cloud?
                </FormLabel>
                <Field as={TagSelector} name="interests" data={interests} />
                <FormErrorMessage>{errors.interests}</FormErrorMessage>
              </FormControl>
              {values.interests.includes('OTHER') && (
                <FormControl>
                  <Field as={Input} name="interestsOther" />
                </FormControl>
              )}
            </Stack>
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>What best describes your role?</FormLabel>
                <Field as={Select} name="role" placeholder="Select">
                  {Object.entries(roles).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </Field>
              </FormControl>
              {values.role === 'OTHER' && (
                <FormControl>
                  <Field
                    as={Input}
                    name="roleOther"
                    placeholder="What else best describes your role?"
                  />
                </FormControl>
              )}
            </Stack>
            <Button
              type="submit"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              isFullWidth
            >
              Save and continue
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  )
}

export default Business
