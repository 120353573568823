import {
  CloseButton,
  Flex,
  HStack,
  Text,
  useToast as useChakraToast
} from '@chakra-ui/react'
import {
  CheckedFilled,
  ErrorFilled,
  InfoFilled,
  WarningFilled
} from '../shared/Icons'

const statuses = {
  success: {
    borderLeftColor: 'green.700',
    background: 'green.100',
    icon: <CheckedFilled color="green.700" />
  },
  error: {
    borderLeftColor: 'red.500',
    background: 'red.100',
    icon: <ErrorFilled color="red.500" />
  },
  warning: {
    borderLeftColor: 'orange.500',
    background: 'orange.100',
    icon: <WarningFilled color="orange.500" />
  },
  info: {
    borderLeftColor: 'blue.600',
    background: 'blue.100',
    icon: <InfoFilled color="blue.600" />
  }
}

const Toast = ({ status, message, onClick }) => (
  <Flex
    bg={statuses[status].background}
    w="350px"
    pt={1}
    px={1}
    borderRadius="6px"
    borderLeft="4px"
    borderLeftColor={statuses[status].borderLeftColor}
    justifyContent="space-between"
  >
    <HStack pt={2} pb={3} spacing={2} align="start">
      {statuses[status].icon}
      <Text size="sm" fontWeight={400}>
        {message}
      </Text>
    </HStack>
    <CloseButton size="sm" onClick={onClick} />
  </Flex>
)

export const useToast = () => {
  const toast = useChakraToast()

  const returnFunction = ({ message, status, ...options }) =>
    toast({
      position: 'top-right',
      render: ({ onClose }) => (
        <Toast status={status} message={message} onClick={onClose} />
      ),
      ...options
    })

  return Object.assign(returnFunction, toast)
}
