import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { AccountInstances, OrgInstances } from '../../../api/instances'
import { serialize } from '../../../helpers/serializers'
import { INITIAL_PAGE, PER_PAGE } from '../../shared/Table'
import { useConfigs } from './configs'
import { swrOptions } from '../../../constants/swr-options'

export const useOrgInstance = ({ orgId, projectId, instanceId }) => {
  const key = `/projects/${projectId}/instances/${instanceId}?org=${orgId}`
  const fetcher = () => OrgInstances.fetchById({ orgId, projectId, instanceId })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  return {
    instance: serialize(data?.instance),
    isLoading,
    isError: Boolean(error)
  }
}

export const useOrgInstanceInfo = ({
  orgId,
  projectId,
  reservationId = undefined
}) => {
  const reservationIdArg = reservationId
    ? `&reservation_id=${reservationId}`
    : ''
  const key = `/projects/${projectId}/instances/info?org=${orgId}${reservationIdArg}`
  const fetcher = () =>
    OrgInstances.fetchInfo({ orgId, projectId, reservationId })

  const { data, isValidating, isLoading, error } = useSWR(key, fetcher, {
    revalidateOnFocus: false
  })

  return {
    info: serialize(data),
    isValidatingInfo: isValidating,
    isLoadingInfo: isLoading,
    isErrorInfo: Boolean(error)
  }
}

export const useOrgInstanceSecurityGroups = ({
  orgId,
  projectId,
  instanceId
}) => {
  const key = `/projects/${projectId}/instances/${instanceId}/security-groups?org=${orgId}`
  const fetcher = () =>
    OrgInstances.fetchSecurityGroups({ orgId, projectId, instanceId })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  return {
    securityGroups: serialize(data?.items),
    isLoading,
    isError: Boolean(error)
  }
}

export const useOrgInstancesPaginated = ({
  orgId,
  projectId,
  page = INITIAL_PAGE,
  perPage = PER_PAGE,
  shouldFetch = true
}) => {
  const key = shouldFetch
    ? `/projects/${projectId}/instances?page=${page}&per_page=${perPage}&org=${orgId}`
    : null
  const fetcher = () =>
    OrgInstances.fetchAll({ orgId, projectId, page, perPage })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  let instances, pagination

  if (data) ({ instances, ...pagination } = serialize(data))

  return (
    shouldFetch && {
      instances,
      pagination,
      isLoading,
      isError: Boolean(error)
    }
  )
}

export const useOrgInstancesAll = ({
  orgId,
  projectId,
  region,
  shouldFetch = true
}) => {
  const [totalCount, setTotalCount] = useState(1)
  const [instances, setInstances] = useState(undefined)

  const key = shouldFetch
    ? `/projects/${projectId}/instances?page=1&per_page=${totalCount}&org=${orgId}`
    : null
  const fetcher = () =>
    OrgInstances.fetchAll({ orgId, projectId, perPage: totalCount, region })

  const { data, isLoading, error, mutate } = useSWR(key, fetcher, swrOptions)

  const initialUpdate = (data) => {
    const serializedData = serialize(data)
    if (serializedData?.totalCount > 1) {
      setTotalCount(serializedData?.totalCount)
      mutate()
    }
    setInstances(serializedData?.instances)
  }

  useEffect(() => {
    if (data) initialUpdate(data)
  }, [data])

  return (
    shouldFetch && {
      instances,
      isLoading,
      isError: Boolean(error)
    }
  )
}

export const useOrgUnusedSubscriptions = ({ orgId }) => {
  const key = orgId && `/billing/subscriptions/unused?org=${orgId}`
  const fetcher = () => OrgInstances.fetchUnusedSubscriptions({ orgId })

  const { data, isValidating, isLoading, error } = useSWR(key, fetcher, {
    revalidateOnFocus: false
  })

  let subscriptions
  if (data) ({ subscriptions } = serialize(data))

  return {
    unusedSubscriptions: subscriptions,
    isValidating,
    isLoading,
    isError: Boolean(error)
  }
}

export const useOrgInstanceVolumes = ({ orgId, projectId, instanceId }) => {
  const { data, isLoading, error } = useSWR(
    `/projects/${projectId}/instances/${instanceId}/volumes?org=${orgId}`,
    () => OrgInstances.fetchVolumes({ orgId, projectId, instanceId }),
    swrOptions
  )

  let volumes
  if (data) ({ items: volumes } = serialize(data))

  return {
    volumes,
    isLoading,
    isError: Boolean(error)
  }
}

export const useAccountInstance = ({ instanceId }) => {
  const key = `/instances/${instanceId}`
  const fetcher = () => AccountInstances.fetchById({ instanceId })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  return {
    instance: serialize(data?.instance),
    isLoading,
    isError: Boolean(error)
  }
}

export const useAccountInstanceInfo = ({ reservationId = undefined }) => {
  const reservationIdArg = reservationId
    ? `?reservation_id=${reservationId}`
    : ''
  const key = `/instances/info${reservationIdArg}`
  const fetcher = () => AccountInstances.fetchInfo({ reservationId })

  const { data, isValidating, isLoading, error } = useSWR(key, fetcher, {
    revalidateOnFocus: false
  })

  return {
    info: serialize(data),
    isValidatingInfo: isValidating,
    isLoadingInfo: isLoading,
    isErrorInfo: Boolean(error)
  }
}

export const useAccountInstanceSecurityGroups = ({ instanceId }) => {
  const key = `/instances/${instanceId}/security-groups`
  const fetcher = () => AccountInstances.fetchSecurityGroups({ instanceId })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  return {
    securityGroups: serialize(data?.items),
    isLoading,
    isError: Boolean(error)
  }
}

export const useAccountInstancesPaginated = ({
  page = INITIAL_PAGE,
  perPage = PER_PAGE,
  shouldFetch = true
}) => {
  const key = shouldFetch ? `/instances?page=${page}&per_page=${perPage}` : null
  const fetcher = () => AccountInstances.fetchAll({ page, perPage })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  let instances, pagination

  if (data) ({ instances, ...pagination } = serialize(data))

  return (
    shouldFetch && {
      instances,
      pagination,
      isLoading,
      isError: Boolean(error)
    }
  )
}

export const useAccountInstancesAll = ({ region, shouldFetch = true }) => {
  const [totalCount, setTotalCount] = useState(1)
  const [instances, setInstances] = useState(undefined)

  const key = shouldFetch ? `/instances?page=1&per_page=${totalCount}` : null
  const fetcher = () =>
    AccountInstances.fetchAll({ perPage: totalCount, region })

  const { data, isLoading, error, mutate } = useSWR(key, fetcher, swrOptions)

  const initialUpdate = (data) => {
    const serializedData = serialize(data)
    if (serializedData?.totalCount > 1) {
      setTotalCount(serializedData?.totalCount)
      mutate()
    }
    setInstances(serializedData?.instances)
  }

  useEffect(() => {
    if (data) initialUpdate(data)
  }, [data])

  return (
    shouldFetch && {
      instances,
      isLoading,
      isError: Boolean(error)
    }
  )
}

export const useAccountUnusedSubscriptions = () => {
  const key = '/billing/subscriptions/unused'
  const fetcher = () => AccountInstances.fetchUnusedSubscriptions()

  const { data, isValidating, isLoading, error } = useSWR(key, fetcher, {
    revalidateOnFocus: false
  })

  let subscriptions
  if (data) ({ subscriptions } = serialize(data))

  return {
    unusedSubscriptions: subscriptions,
    isValidating,
    isLoading,
    isError: Boolean(error)
  }
}

export const useAccountInstanceVolumes = ({ instanceId }) => {
  const { data, isLoading, error } = useSWR(
    `/instances/${instanceId}/volumes`,
    () => AccountInstances.fetchVolumes({ instanceId }),
    swrOptions
  )

  let volumes
  if (data) ({ items: volumes } = serialize(data))

  return {
    volumes,
    isLoading,
    isError: Boolean(error)
  }
}

export const useGpuDescription = ({ flavorName }) => {
  const { configs } = useConfigs({})
  const type = configs?.instanceTypes.find(
    (type) => type.flavorName === flavorName
  )

  return type?.details.gpu.description
}

export const useCpuDescription = ({ flavorName, region }) => {
  const { configs } = useConfigs({})
  const type = configs?.instanceTypes
    .filter((type) => type.flavorName === flavorName)
    .find((type) => type.regions[0] === region)

  return type?.details.vcpu.description
}
