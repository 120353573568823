import { extendTheme } from '@chakra-ui/react'
import breakpoints from './breakpoints/index'
import Accordion from './components/Accordion'
import Avatar from './components/Avatar'
import Badge from './components/Badge'
import Button from './components/Button'
import Checkbox from './components/Checkbox'
import FormLabel from './components/FormLabel'
import Heading from './components/Heading'
import Input from './components/Input'
import Link from './components/Link'
import Menu from './components/Menu'
import Modal from './components/Modal'
import NumberInput from './components/NumberInput'
import Radio from './components/Radio'
import Select from './components/Select'
import { Skeleton, SkeletonCircle } from './components/Skeleton'
import Switch from './components/Switch'
import Table from './components/Table'
import Tabs from './components/Tabs'
import Tag from './components/Tag'
import Text from './components/Text'
import Textarea from './components/Textarea'
import Tooltip from './components/Tooltip'
import colors from './foundations/colors'
import textStyles from './foundations/textStyles'
import typography from './foundations/typography'
import styles from './styles'

const overrides = {
  styles,
  colors,
  ...typography,
  textStyles,
  breakpoints,
  components: {
    Accordion,
    Avatar,
    Badge,
    Button,
    Checkbox,
    FormLabel,
    Heading,
    Input,
    Link,
    Menu,
    Modal,
    NumberInput,
    Radio,
    Select,
    Skeleton,
    SkeletonCircle,
    Switch,
    Table,
    Tabs,
    Tag,
    Text,
    Textarea,
    Tooltip
  },
  config: {
    cssVarPrefix: 'ck'
  }
}

export default extendTheme(overrides)
