const Modal = {
  baseStyle: {
    body: {
      p: 16
    },
    dialog: {
      borderRadius: '10px'
    },
    closeButton: {
      _focus: { border: 'none' }
    }
  }
}

export default Modal
