import { Stack } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { BannerReservation } from '../../../shared/Banner'
import { useHasWriteProjectPermissions } from '../../../shared/hooks'
import { SuccessHandler } from './utils'
import List from './list'

const Reservations = () => {
  const { orgId, projectId } = useParams()
  const hasWriteProjectPermissions = orgId
    ? useHasWriteProjectPermissions({
        projectId,
        orgId
      })
    : true

  return (
    <Stack spacing={8}>
      <BannerReservation />
      <List hasPermission={hasWriteProjectPermissions} />
      <SuccessHandler />
    </Stack>
  )
}

export default Reservations
