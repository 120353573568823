import { INITIAL_PAGE, PER_PAGE } from '../components/shared/Table'
import { serialize } from '../helpers/serializers'
import { apiRequest, PRODUCTS_ENDPOINT } from './index'

const mockAutoRecharge = {
  min_balance: 50,
  topup_amount: 100,
  payment_method: 'pm_1PGy00LObxZ6h60skNpUDoFH'
}

const mockNotification = {
  min_balance: 50
}

export class OrgBilling {
  static fetchCustomer = async ({ orgId }) =>
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/customer?org=${orgId}`,
      {
        method: 'GET'
      },
      true
    )

  static fetchOnDemands = async ({
    orgId,
    page = INITIAL_PAGE,
    perPage = PER_PAGE
  }) => {
    const onDemands = await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/resources/on-demand?page=${page}&per_page=${perPage}&org=${orgId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(onDemands)
  }

  static fetchLongTerms = async ({
    orgId,
    page = INITIAL_PAGE,
    perPage = PER_PAGE
  }) => {
    const longTerms = await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/resources/longterm?page=${page}&per_page=${perPage}&org=${orgId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(longTerms)
  }

  static renewLongTerm = async ({ orgId, resourceId, payload }) =>
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/resources/longterm/${resourceId}/renew?org=${orgId}`,
      {
        method: 'POST',
        body: JSON.stringify(payload)
      },
      true
    )

  static fetchReservations = async ({
    orgId,
    page = INITIAL_PAGE,
    perPage = PER_PAGE
  }) => {
    const resources = await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/reservations?page=${page}&per_page=${perPage}&org=${orgId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(resources)
  }

  static fetchStorage = async ({
    orgId,
    type,
    startingAfter = undefined,
    endingBefore = undefined
  }) => {
    const starting = startingAfter ? `&starting_after=${startingAfter}` : ''
    const ending = endingBefore ? `&ending_before=${endingBefore}` : ''
    const storage = await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/storage-usage?org=${orgId}&type=${type}${starting}${ending}&grouping=day`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(storage)
  }

  static updateBilling = async ({ orgId, payload }) => {
    const longTerms = await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/customer?org=${orgId}`,
      {
        method: 'PUT',
        body: JSON.stringify(payload)
      },
      true
    )
    return serialize(longTerms)
  }

  static fetchHistory = async ({ orgId }) => {
    const history = await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/history?org=${orgId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(history)
  }

  static setDefaultMethod = async ({ orgId, payload }) =>
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/customer/default-payment-method?org=${orgId}`,
      {
        method: 'POST',
        body: JSON.stringify(payload)
      },
      true
    )

  static fetchAlarm = async ({ orgId }) => {
    const alarm = await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/alarms?org=${orgId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(alarm)
  }

  static updateAlarm = async ({ orgId, payload }) => {
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/alarms?org=${orgId}`,
      {
        method: 'POST',
        body: JSON.stringify(payload)
      },
      true
    )
  }

  static deleteAlarm = async ({ orgId }) =>
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/alarms?org=${orgId}`,
      {
        method: 'DELETE'
      },
      true
    )

  static fetchAutoRecharge = async ({ orgId }) => {
    const autoRecharge = await mockAutoRecharge
    return serialize(autoRecharge)
  }

  static updateAutoRecharge = async ({ orgId, payload }) =>
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/auto-recharge?org=${orgId}`,
      {
        method: 'PUT',
        body: JSON.stringify(payload)
      },
      true
    )

  static deleteAutoRecharge = async ({ orgId }) =>
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/auto-recharge?org=${orgId}`,
      {
        method: 'DELETE'
      },
      true
    )

  static fetchNotification = async ({ orgId }) => {
    const notification = await mockNotification
    return serialize(notification)
  }

  static updateNotification = async ({ orgId, payload }) =>
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/notification?org=${orgId}`,
      {
        method: 'PUT',
        body: JSON.stringify(payload)
      },
      true
    )

  static deleteNotification = async ({ orgId }) =>
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/notification?org=${orgId}`,
      {
        method: 'DELETE'
      },
      true
    )

  static createPaymentIntent = async ({ orgId }) =>
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/credit-card/init?org=${orgId}`,
      {
        method: 'POST'
      },
      true
    )

  static fetchCreditCard = async ({ orgId, creditCardId }) => {
    const creditCard = await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/credit-card/${creditCardId}?org=${orgId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(creditCard)
  }

  static addCreditCard = async ({ payload, orgId }) =>
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/credit-card/finish?org=${orgId}`,
      {
        method: 'POST',
        body: JSON.stringify(payload)
      },
      true
    )

  static updateCreditCard = async ({ orgId, creditCardId, payload }) =>
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/credit-card/${creditCardId}?org=${orgId}`,
      {
        method: 'PUT',
        body: JSON.stringify(payload)
      },
      true
    )

  static deleteCreditCard = async ({ orgId, creditCardId }) =>
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/credit-card/${creditCardId}?org=${orgId}`,
      {
        method: 'DELETE'
      },
      true
    )

  static recharge = async ({ orgId, payload }) =>
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/balance/recharge?org=${orgId}`,
      {
        method: 'POST',
        body: JSON.stringify(payload)
      },
      true
    )
}

export class AccountBilling {
  static fetchCustomer = async () =>
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/customer`,
      {
        method: 'GET'
      },
      true
    )

  static fetchOnDemands = async ({
    page = INITIAL_PAGE,
    perPage = PER_PAGE
  }) => {
    const onDemands = await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/resources/on-demand?page=${page}&per_page=${perPage}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(onDemands)
  }

  static fetchLongTerms = async ({
    page = INITIAL_PAGE,
    perPage = PER_PAGE
  }) => {
    const longTerms = await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/resources/longterm?page=${page}&per_page=${perPage}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(longTerms)
  }

  static renewLongTerm = async ({ resourceId, payload }) =>
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/resources/longterm/${resourceId}/renew`,
      {
        method: 'POST',
        body: JSON.stringify(payload)
      },
      true
    )

  static fetchReservations = async ({
    page = INITIAL_PAGE,
    perPage = PER_PAGE
  }) => {
    const resources = await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/reservations?page=${page}&per_page=${perPage}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(resources)
  }

  static fetchStorage = async ({
    type,
    startingAfter = undefined,
    endingBefore = undefined
  }) => {
    const starting = startingAfter ? `&starting_after=${startingAfter}` : ''
    const ending = endingBefore ? `&ending_before=${endingBefore}` : ''
    const storage = await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/storage-usage?type=${type}${starting}${ending}&grouping=day`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(storage)
  }

  static updateBilling = async ({ payload }) => {
    const longTerms = await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/customer`,
      {
        method: 'PUT',
        body: JSON.stringify(payload)
      },
      true
    )
    return serialize(longTerms)
  }

  static fetchHistory = async () => {
    const history = await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/history`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(history)
  }

  static setDefaultMethod = async ({ payload }) =>
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/customer/default-payment-method`,
      {
        method: 'POST',
        body: JSON.stringify(payload)
      },
      true
    )

  static fetchAlarm = async () => {
    const alarm = await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/alarms`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(alarm)
  }

  static updateAlarm = async ({ payload }) => {
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/alarms`,
      {
        method: 'POST',
        body: JSON.stringify(payload)
      },
      true
    )
  }

  static deleteAlarm = async () =>
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/alarms`,
      {
        method: 'DELETE'
      },
      true
    )

  static fetchAutoRecharge = async () => {
    const autoRecharge = await mockAutoRecharge
    return serialize(autoRecharge)
  }

  static updateAutoRecharge = async ({ payload }) =>
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/auto-recharge`,
      {
        method: 'PUT',
        body: JSON.stringify(payload)
      },
      true
    )

  static deleteAutoRecharge = async () =>
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/auto-recharge`,
      {
        method: 'DELETE'
      },
      true
    )

  static fetchNotification = async () => {
    const notification = await mockNotification
    return serialize(notification)
  }

  static updateNotification = async ({ payload }) =>
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/notification`,
      {
        method: 'PUT',
        body: JSON.stringify(payload)
      },
      true
    )

  static deleteNotification = async () =>
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/notification`,
      {
        method: 'DELETE'
      },
      true
    )

  static createPaymentIntent = async () =>
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/credit-card/init`,
      {
        method: 'POST'
      },
      true
    )

  static fetchCreditCard = async ({ creditCardId }) => {
    const creditCard = await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/credit-card/${creditCardId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(creditCard)
  }

  static addCreditCard = async ({ payload }) =>
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/credit-card/finish`,
      {
        method: 'POST',
        body: JSON.stringify(payload)
      },
      true
    )

  static updateCreditCard = async ({ creditCardId, payload }) =>
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/credit-card/${creditCardId}`,
      {
        method: 'PUT',
        body: JSON.stringify(payload)
      },
      true
    )

  static deleteCreditCard = async ({ creditCardId }) =>
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/credit-card/${creditCardId}`,
      {
        method: 'DELETE'
      },
      true
    )

  static recharge = async ({ payload }) =>
    await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/balance/recharge`,
      {
        method: 'POST',
        body: JSON.stringify(payload)
      },
      true
    )
}
