import { Grid, Heading, SimpleGrid, Stack } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
import * as yup from 'yup'
import {
  AccountSecurityGroups,
  OrgSecurityGroups
} from '../../../../../api/securityGroups'
import { DEFAULT_REGION } from '../../../../../constants/defaults'
import messages from '../../../../../constants/messages'
import routes from '../../../../../constants/routes'
import { toastMessages } from '../../../../../constants/toast-messages'
import {
  mapRulesToBackend,
  rulesDirections,
  validateDuplicatedRules,
  validatePorts,
  validationName
} from '../../../../../helpers/securityGroups'
import { withOrgPathFallback } from '../../../../../router'
import { InputField, RegionField, RulesField } from '../../../../shared/Fields'
import { ReturnLink } from '../../../../shared/Links'
import { useToast } from '../../../../shared/Toast'
import { useIsMounted } from '../../../../shared/hooks/useIsMounted'
import Summary from './summary'

const validationSchema = yup.object().shape({
  region: yup.string(),
  name: yup.string().required(`Security Group's name is required.`),
  description: yup.string(),
  ingress: yup
    .array()
    .test({
      name: validationName.limit,
      message: messages.rulesEmpty,
      test: (value, context) =>
        value.length > 0 || context.parent.egress.length > 0
    })
    .test({
      name: validationName.duplicated,
      message: messages.rulesDuplicate,
      test: (value) => !validateDuplicatedRules(value)
    })
    .test({
      name: validationName.port,
      message: '',
      test: (value) => validatePorts(value)
    }),
  egress: yup
    .array()
    .test({
      name: validationName.limit,
      message: messages.rulesEmpty,
      test: (value, context) =>
        value.length > 0 || context.parent.ingress.length > 0
    })
    .test({
      name: validationName.duplicated,
      message: messages.rulesDuplicate,
      test: (value) => !validateDuplicatedRules(value)
    })
    .test({
      name: validationName.port,
      message: '',
      test: (value) => validatePorts(value)
    })
})

const initialValues = {
  region: DEFAULT_REGION,
  name: '',
  description: '',
  ingress: [],
  egress: []
}

const SecurityGroupsCreate = () => {
  const { orgId, projectId } = useParams()
  const history = useHistory()
  const isMounted = useIsMounted()
  const toast = useToast()

  const onSubmit = async (
    { region, name, description, ingress, egress },
    { setSubmitting, resetForm }
  ) => {
    setSubmitting(true)
    try {
      const data = { security_group_rules: [] }

      if (region) {
        data.region = region
      }

      if (name) {
        data.name = name
      }

      if (description) {
        data.description = description
      } else {
        data.description = ''
      }

      if (ingress) {
        const ingressMaped = mapRulesToBackend(ingress)
        data.security_group_rules.push(...ingressMaped)
      }

      if (egress) {
        const egressMaped = mapRulesToBackend(egress)
        data.security_group_rules.push(...egressMaped)
      }

      if (orgId) {
        await OrgSecurityGroups.create({
          orgId,
          projectId,
          data
        })
      } else {
        await AccountSecurityGroups.create({
          data
        })
      }
      toast({
        status: 'success',
        message: toastMessages.securityGroupCreateSuccess
      })
      resetForm()
      history.push(withOrgPathFallback(routes.dashboard.securityGroups.index))
    } catch {
      toast({
        status: 'error',
        message: toastMessages.securityGroupCreateError
      })
    } finally {
      if (isMounted()) {
        setSubmitting(false)
      }
    }
  }

  return (
    <Stack spacing={8} pb={40}>
      <Stack spacing={0}>
        <ReturnLink
          to={withOrgPathFallback(routes.dashboard.securityGroups.index)}
        >
          Network
        </ReturnLink>
        <Heading as="h1" size="4xl">
          Create new Security Group
        </Heading>
      </Stack>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Grid templateColumns="2fr 1fr" columnGap={8} as={Form}>
          <Stack spacing={16}>
            <RegionField name="region" />
            <Stack spacing={8}>
              <Heading size="xl">Configuration</Heading>
              <SimpleGrid columns={2} columnGap={5}>
                <InputField
                  label="Name"
                  name="name"
                  placeholder="Enter security group name"
                  isRequired
                />
                <InputField
                  label="Description"
                  name="description"
                  placeholder="Enter security group description"
                />
              </SimpleGrid>
              <Stack spacing={4}>
                <Heading as="h3" size="md">
                  Inbound Rules
                </Heading>
                <RulesField name={rulesDirections.ingress} />
              </Stack>
              <Stack spacing={4}>
                <Heading as="h3" size="md">
                  Outbound Rules
                </Heading>
                <RulesField name={rulesDirections.egress} />
              </Stack>
            </Stack>
          </Stack>
          <Summary />
        </Grid>
      </Formik>
    </Stack>
  )
}

export default SecurityGroupsCreate
