import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getOrgParam } from '../../../../router'
import { setIsOrgSuspended } from '../../../../slices/dashboard'
import { useOrganizationWithId } from '../../../shared/hooks/organization'
import AccountSuspended from './account-suspended'
import Incidents from './incidents'
import Maintenances from './maintenances'
import OrgSuspended from './org-suspended'

const Alerts = () => {
  const dispatch = useDispatch()
  const orgId = getOrgParam()
  const { organization } = useOrganizationWithId(orgId)

  useEffect(() => {
    if (organization?.suspendedAt) {
      dispatch(setIsOrgSuspended(true))
    }
  }, [organization?.suspendedAt])

  return (
    <>
      <Incidents />
      <Maintenances />
      <AccountSuspended />
      {orgId && <OrgSuspended />}
    </>
  )
}

export default Alerts
