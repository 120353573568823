import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Skeleton } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { Error } from '../../../../shared/Error'
import { formatCurrency } from '../../../../../helpers/currency'
import {
  useOrgReservationsInfo,
  useAccountReservationsInfo
} from '../../../../shared/hooks'
import { calcPrices } from '../utils'
import Price from '../../../../shared/Price'

const items = ({ size, dates, prices }) => [
  {
    label: 'Price',
    value: prices.priceBySize
      ? `${formatCurrency(prices.priceBySize)}/day`
      : '-'
  },
  { label: 'Days', value: dates.days },
  { label: 'Size', value: size },
  {
    label: 'Discount',
    value: prices.discount ? `${prices.discount}% off` : '-'
  },
  {
    label: 'Total cost (excl. VAT)',
    value: prices.totalPrice ? formatCurrency(prices.totalPrice) : '-'
  }
]

const Prices = () => {
  const { orgId } = useParams()
  const { values, setValues } = useFormikContext()
  const { region, type, size, dates, prices, isEmpty } = values

  const { isLoadingInfo, isErrorInfo } = orgId
    ? useOrgReservationsInfo({ orgId, region, type })
    : useAccountReservationsInfo({
        region,
        type
      })

  useEffect(() => {
    if (!isEmpty) {
      const { priceBySize, discount, totalPrice } = calcPrices({
        basePrice: prices.basePrice,
        size,
        days: dates.days,
        discountMap: prices.discountMap
      })

      setValues((prev) => ({
        ...prev,
        prices: {
          ...prev.prices,
          priceBySize,
          discount,
          totalPrice
        }
      }))
    }
  }, [isEmpty, size, dates.days])

  return isLoadingInfo ? (
    <Skeleton h="70px" />
  ) : isErrorInfo ? (
    <Error />
  ) : (
    <Price items={items(values)} />
  )
}

export default Prices
