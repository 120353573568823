import { AlertMaintenances } from '../../../shared/Alert'
import { useMaintenances } from '../../../shared/hooks/status-page'

const maintenanceTypes = {
  upcoming: 'upcoming',
  active: 'active'
}

const Maintenances = () => {
  const {
    maintenances: upcoming,
    isLoadingMaintenances: isLoadingMaintenancesUpcoming,
    isErrorMaintenances: isErrorMaintenancesUpcoming
  } = useMaintenances({ type: maintenanceTypes.upcoming })
  const {
    maintenances: active,
    isLoadingMaintenances: isLoadingMaintenancesActive,
    isErrorMaintenances: isErrorMaintenancesActive
  } = useMaintenances({ type: maintenanceTypes.active })
  const hasMaintenances = upcoming?.length > 0 || active?.length > 0

  if (isLoadingMaintenancesUpcoming || isLoadingMaintenancesActive) return null

  if (isErrorMaintenancesUpcoming || isErrorMaintenancesActive) return null

  return (
    hasMaintenances && <AlertMaintenances alerts={[...upcoming, ...active]} />
  )
}

export default Maintenances
