import useSWR from 'swr'
import {
  OrgCommunityInstances,
  AccountCommunityInstances
} from '../../../api/communityInstances'
import { serialize } from '../../../helpers/serializers'
import { v4 as uuidv4 } from 'uuid'

const mockCommunityInstances = (numberOfRows) =>
  [...Array(numberOfRows)].map((_, index) => ({
    id: uuidv4(),
    name: `dummy-instance-${index + 1}`,
    ip: '76.65.45.127',
    type: 'geforcertx4090-pcie-24gb',
    typeName: 'NVIDIA GeForce RTX 4090 24GB',
    gpuCount: 1,
    cpuCount: 20,
    ram: 14,
    storage: 83,
    osType: 'Ubuntu 22.04 LTS',
    status: 'active',
    createdAt: '2024-06-24T12:43:30.406976'
  }))

const swrOptions = {
  revalidateOnFocus: false
}

export const useOrgCommunityInstances = ({ orgId, projectId }) => {
  const key = `/projects/${projectId}/community/valdi/instances?org=${orgId}`
  const fetcher = () => OrgCommunityInstances.fetchAll({ orgId, projectId })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  let communityInstances
  // if (data) ({ communityInstances } = serialize(data))
  if (data) {
    const serializedData = serialize(data)
    communityInstances = [
      ...serializedData.communityInstances,
      ...mockCommunityInstances(5)
    ]
  }

  return {
    communityInstances,
    isLoadingCommunityInstances: isLoading,
    isErrorCommunityInstances: Boolean(error)
  }
}

export const useOrgCommunityConfigs = ({ orgId, projectId }) => {
  const key = `/projects/${projectId}/community/valdi/instance-configs?org=${orgId}`
  const fetcher = () => OrgCommunityInstances.fetchConfigs({ orgId, projectId })

  const { data, isLoading, error } = useSWR(key, fetcher)

  let communityInstanceConfigs
  if (data) ({ communityInstanceConfigs } = serialize(data))

  return {
    communityConfigs: communityInstanceConfigs,
    isLoadingCommunityConfigs: isLoading,
    isErrorCommunityConfigs: Boolean(error)
  }
}

export const useAccountCommunityInstances = () => {
  const key = '/community/valdi/instances'
  const fetcher = () => AccountCommunityInstances.fetchAll()

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  let communityInstances
  // if (data) ({ communityInstances } = serialize(data))
  if (data) {
    const serializedData = serialize(data)
    communityInstances = [
      ...serializedData.communityInstances,
      ...mockCommunityInstances(5)
    ]
  }

  return {
    communityInstances,
    isLoadingCommunityInstances: isLoading,
    isErrorCommunityInstances: Boolean(error)
  }
}

export const useAccountCommunityConfigs = () => {
  const key = '/community/valdi/instance-configs'
  const fetcher = () => AccountCommunityInstances.fetchConfigs()

  const { data, isLoading, error } = useSWR(key, fetcher)

  let communityInstanceConfigs
  if (data) ({ communityInstanceConfigs } = serialize(data))

  return {
    communityConfigs: communityInstanceConfigs,
    isLoadingCommunityConfigs: isLoading,
    isErrorCommunityConfigs: Boolean(error)
  }
}
