import { useState } from 'react'
import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Flex,
  Text
} from '@chakra-ui/react'
import { mutate } from 'swr'
import { OrgBilling, AccountBilling } from '../../../../../api/billing'
import { toastMessages } from '../../../../../constants/toast-messages'
import { useToast } from '../../../../shared/Toast'
import { useIsMounted } from '../../../../shared/hooks/useIsMounted'

export const CancelModal = ({ disclosure, data }) => {
  const { isOpen, onClose } = disclosure
  const { orgId, resourceId, validityDate } = data
  const [isSubmitting, setIsSubmitting] = useState(false)
  const isMounted = useIsMounted()
  const toast = useToast()

  const onSubmit = async () => {
    const payload = { renew: false }
    setIsSubmitting(true)

    try {
      orgId
        ? await OrgBilling.renewLongTerm({ orgId, resourceId, payload })
        : await AccountBilling.renewLongTerm({ resourceId, payload })

      await mutate((key) => key.startsWith('/billing/resources/longterm?'))

      toast({
        status: 'success',
        message: toastMessages.saved
      })
      onClose()
    } catch {
      toast({
        status: 'error',
        message: toastMessages.notSaved
      })
    } finally {
      if (isMounted()) {
        setIsSubmitting(false)
      }
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap={4}>
            <Heading size="xl" textAlign="center">
              Are you sure you want to cancel subscription?
            </Heading>
            <Text
              size="md"
              textAlign="center"
              color="red.500"
              fontWeight="normal"
            >
              By canceling your subscription, your Instance will be destroyed at
              the end of this billing cycle ({validityDate}). To safeguard your
              data and configurations, any running Instances will be snapshotted
              before destruction.
            </Text>
            <Text
              size="md"
              textAlign="center"
              color="red.500"
              fontWeight="normal"
            >
              Note: You can renew your subscription before it ends.
            </Text>
            <Button
              type="submit"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              onClick={onSubmit}
              mt={4}
            >
              Cancel subscription
            </Button>
            <Button
              colorScheme="offBlack"
              variant="outline"
              isDisabled={isSubmitting}
              onClick={onClose}
            >
              Not now
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
