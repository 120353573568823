import { INITIAL_PAGE, PER_PAGE } from '../components/shared/Table'
import { serialize } from '../helpers/serializers'
import {
  COMPUTE_ENDPOINT,
  PRODUCTS_ENDPOINT,
  apiRequest,
  computeApiRequest
} from './index'

export const actions = {
  STOP: 'stop',
  RESET: 'reset',
  START: 'start'
}

export const fetchInstance = (id) => {
  return computeApiRequest(
    `/instances/${id}`,
    {
      method: 'GET'
    },
    true
  )
}

export const fetchSerialConsoleSignature = (id) => {
  return computeApiRequest(
    `/instances/${id}/serial-console`,
    {
      method: 'GET'
    },
    true
  )
}

export class OrgInstances {
  static create = async ({ orgId, projectId, payload }) => {
    const instance = await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/instances?org=${orgId}`,
      {
        method: 'POST',
        body: JSON.stringify(payload)
      },
      true
    )
    return serialize(instance)
  }

  static fetchById = async ({ orgId, projectId, instanceId }) => {
    const instance = await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/instances/${instanceId}?org=${orgId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(instance)
  }

  static fetchSecurityGroups = async ({ orgId, projectId, instanceId }) => {
    const securityGroups = await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/instances/${instanceId}/security-groups?org=${orgId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(securityGroups)
  }

  static fetchAll = async ({
    orgId,
    projectId,
    page = INITIAL_PAGE,
    perPage = PER_PAGE,
    region
  }) => {
    const regionArg = region ? `&region=${region}` : ''
    const instances = await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/instances?page=${page}&per_page=${perPage}&org=${orgId}${regionArg}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(instances)
  }

  static fetchUnusedSubscriptions = async ({ orgId }) => {
    const unusedSubscriptions = await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/subscriptions/unused?org=${orgId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(unusedSubscriptions)
  }

  static fetchVolumes = async ({ orgId, projectId, instanceId }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/instances/${instanceId}/volumes?org=${orgId}`,
      {
        method: 'GET'
      },
      true
    )

  static fetchInfo = async ({ orgId, projectId, reservationId }) => {
    const reservationIdArg = reservationId
      ? `&reservation_id=${reservationId}`
      : ''
    const info = await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/instances/info?org=${orgId}${reservationIdArg}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(info)
  }

  static changeAction = async ({ orgId, projectId, instanceId, action }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/instances/${instanceId}/control?org=${orgId}`,
      {
        method: 'POST',
        body: JSON.stringify({ action })
      },
      true
    )

  static destroyById = async ({ orgId, projectId, instanceId }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/instances/${instanceId}?org=${orgId}`,
      {
        method: 'DELETE'
      },
      true
    )

  static updateById = async ({ orgId, projectId, instanceId, payload }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/instances/${instanceId}?org=${orgId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(payload)
      },
      true
    )
}

export class AccountInstances {
  static create = async ({ payload }) => {
    const instance = await apiRequest(
      `${COMPUTE_ENDPOINT}/instances`,
      {
        method: 'POST',
        body: JSON.stringify(payload)
      },
      true
    )
    return serialize(instance)
  }

  static fetchById = async ({ instanceId }) => {
    const instance = await apiRequest(
      `${COMPUTE_ENDPOINT}/instances/${instanceId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(instance)
  }

  static fetchSecurityGroups = async ({ instanceId }) => {
    const securityGroups = await apiRequest(
      `${COMPUTE_ENDPOINT}/instances/${instanceId}/security-groups`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(securityGroups)
  }

  static fetchAll = async ({
    page = INITIAL_PAGE,
    perPage = PER_PAGE,
    region
  }) => {
    const regionArg = region ? `&region=${region}` : ''
    const instances = await apiRequest(
      `${COMPUTE_ENDPOINT}/instances?page=${page}&per_page=${perPage}${regionArg}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(instances)
  }

  static fetchUnusedSubscriptions = async () => {
    const unusedSubscriptions = await apiRequest(
      `${PRODUCTS_ENDPOINT}/billing/subscriptions/unused`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(unusedSubscriptions)
  }

  static fetchVolumes = async ({ instanceId }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/instances/${instanceId}/volumes`,
      {
        method: 'GET'
      },
      true
    )

  static fetchInfo = async ({ reservationId }) => {
    const reservationIdArg = reservationId
      ? `?reservation_id=${reservationId}`
      : ''
    const info = await apiRequest(
      `${COMPUTE_ENDPOINT}/instances/info${reservationIdArg}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(info)
  }

  static changeAction = async ({ instanceId, action }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/instances/${instanceId}/control`,
      {
        method: 'POST',
        body: JSON.stringify({ action })
      },
      true
    )

  static destroyById = async ({ instanceId }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/instances/${instanceId}`,
      {
        method: 'DELETE'
      },
      true
    )

  static resetById = async ({ instanceId }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/instances/${instanceId}/control`,
      {
        method: 'POST',
        body: JSON.stringify({ action: 'reset' })
      },
      true
    )

  static updateById = async ({ instanceId, payload }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/instances/${instanceId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(payload)
      },
      true
    )
}
