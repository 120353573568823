import { Stack, SimpleGrid } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import {
  useAccountFF,
  useHasWriteProjectPermissions,
  useOrgFF
} from '../../../shared/hooks'
import { PaymentHandler } from '../../../shared/payment-form/utils'
import Alarm from './alarm'
import Balance from './balance'
import OnDemands from './on-demands'
import LongTerms from './long-terms'
import Reservations from './reservations'
import Methods from './methods'
import Details from './details'
import History from './history'
import Storage from './storage'

const Billing = () => {
  const { orgId, projectId } = useParams()
  const hasWriteProjectPermissions = orgId
    ? useHasWriteProjectPermissions({
        projectId,
        orgId
      })
    : true
  const { featureFlag } = orgId ? useOrgFF({ orgId }) : useAccountFF({})
  const {
    enableQuotaReservation,
    enableAccountBalance,
    enableFilesystemBilling
  } = featureFlag || {}

  return (
    <Stack spacing={8}>
      <PaymentHandler showToast />
      {enableAccountBalance && (
        <SimpleGrid columns={2} gap={8}>
          <Balance />
          <Alarm />
        </SimpleGrid>
      )}
      {!enableAccountBalance && <Alarm />}
      <OnDemands />
      <LongTerms hasPermission={hasWriteProjectPermissions} />
      {enableFilesystemBilling && <Storage />}
      {enableQuotaReservation && <Reservations />}
      <Methods />
      <Details />
      <History hasPermission={hasWriteProjectPermissions} />
    </Stack>
  )
}

export default Billing
