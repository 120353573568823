// @ts-check
import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  isLoading: false,
  isVerified: false,
  phoneNumber: null,
  countryId: null
}

const slice = createSlice({
  name: 'phoneVerification',
  initialState,
  reducers: {
    setIsVerifiedStarted: (state) => {
      state.isLoading = true
    },
    setIsVerified: (state, action) => {
      state.isVerified = action.payload.isVerified
      state.phoneNumber = action.payload.phoneNumber
      state.countryId = action.payload.countryId
      state.isLoading = false
    },
    setIsVerifiedFinished: (state) => {
      state.isLoading = false
    }
  }
})

export const { setIsVerifiedStarted, setIsVerified, setIsVerifiedFinished } =
  slice.actions

export default slice.reducer
