import { Box } from '@chakra-ui/react'
import React from 'react'

const TransitionBadge = ({ children }) => (
  <Box
    px={2}
    w="fit-content"
    fontSize="xs"
    sx={{
      '--borderWidth': '1px',
      background: 'white',
      position: 'relative',
      borderRadius: '3px',
      '&:after': {
        content: '""',
        position: 'absolute',
        top: 'calc(-1 * var(--borderWidth))',
        left: 'calc(-1 * var(--borderWidth))',
        height: 'calc(100% + var(--borderWidth) * 2)',
        width: 'calc(100% + var(--borderWidth) * 2)',
        background:
          'linear-gradient(60deg, white, #767676, #706E6E, #AFAFAF, #E2E2E2, #ECECEC, #EDEDED)',
        borderRadius: '4px',
        zIndex: '-1',
        animation: 'animatedgradient 1s ease alternate infinite',
        backgroundSize: '300% 300%'
      },
      '@keyframes animatedgradient': {
        '0%': {
          backgroundPosition: '0% 50%'
        },
        '50%': {
          backgroundPosition: '100% 50%'
        },
        '100%': {
          backgroundPosition: '0% 50%'
        }
      }
    }}
  >
    {children}
  </Box>
)

export default TransitionBadge
