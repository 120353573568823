import { useParams } from 'react-router-dom'
import { Stack, Heading, Skeleton } from '@chakra-ui/react'
import Banner from './banner'
import routes from '../../../constants/routes'
import { Tab, Tabs } from '../../shared/Tabs'
import { Error } from '../../shared/Error'
import {
  useOrgCheckObjectStorage,
  useAccountCheckObjectStorage
} from '../../shared/hooks/useObjectStorage'

const TabSection = ({ children }) => (
  <>
    <Tabs>
      <Tab url={routes.dashboard.buckets.index}>Buckets</Tab>
    </Tabs>
    {children}
  </>
)

const ObjectStorage = ({ children }) => {
  const { orgId, projectId } = useParams()
  const { isEnabled, isCheckLoading, isCheckError } = orgId
    ? useOrgCheckObjectStorage({
        orgId,
        projectId
      })
    : useAccountCheckObjectStorage()

  return (
    <Stack spacing={8}>
      <Heading as="h1" size="4xl">
        Object Storage
      </Heading>
      {isCheckLoading ? (
        <Skeleton h="200px" />
      ) : isCheckError ? (
        <Error />
      ) : isEnabled ? (
        <TabSection>{children}</TabSection>
      ) : (
        <Banner />
      )}
    </Stack>
  )
}

export default ObjectStorage
