import { serialize } from '../helpers/serializers'
import { OBJECT_STORAGE_ENDPOINT, apiRequest } from './index'

export class OrgObjectStorage {
  static createBucket = async ({ orgId, projectId, data }) => {
    const bucket = await apiRequest(
      `${OBJECT_STORAGE_ENDPOINT}/projects/${projectId}/${data.region}/buckets?org=${orgId}`,
      {
        method: 'POST',
        body: JSON.stringify({ name: data.name })
      },
      true
    )
    return serialize(bucket)
  }

  static deleteBucket = async ({ orgId, projectId, region, name }) =>
    await apiRequest(
      `${OBJECT_STORAGE_ENDPOINT}/projects/${projectId}/${region}/buckets/${name}?org=${orgId}`,
      {
        method: 'DELETE'
      },
      true
    )

  static fetchBuckets = async ({ orgId, projectId }) => {
    const buckets = await apiRequest(
      `${OBJECT_STORAGE_ENDPOINT}/projects/${projectId}/buckets?org=${orgId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(buckets)
  }

  static fetchBucket = async ({ orgId, projectId, bucketName, region }) => {
    const bucket = await apiRequest(
      `${OBJECT_STORAGE_ENDPOINT}/projects/${projectId}/${region}/buckets/${bucketName}?org=${orgId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(bucket)
  }

  static check = async ({ orgId, projectId }) => {
    const access = await apiRequest(
      `${OBJECT_STORAGE_ENDPOINT}/projects/${projectId}/api-access/check?org=${orgId}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(access)
  }

  static enable = async ({ orgId, projectId }) =>
    await apiRequest(
      `${OBJECT_STORAGE_ENDPOINT}/projects/${projectId}/api-access/enable?org=${orgId}`,
      {
        method: 'POST'
      },
      true
    )
}

export class AccountObjectStorage {
  static createBucket = async ({ region, name }) => {
    const bucket = await apiRequest(
      `${OBJECT_STORAGE_ENDPOINT}/${region}/buckets`,
      {
        method: 'POST',
        body: JSON.stringify({ name })
      },
      true
    )
    return serialize(bucket)
  }

  static deleteBucket = async ({ region, name }) =>
    await apiRequest(
      `${OBJECT_STORAGE_ENDPOINT}/${region}/buckets/${name}`,
      {
        method: 'DELETE'
      },
      true
    )

  static fetchBuckets = async () => {
    const buckets = await apiRequest(
      `${OBJECT_STORAGE_ENDPOINT}/buckets`,
      {
        method: 'GET'
      },
      true
    )

    return serialize(buckets)
  }

  static fetchBucket = async ({ bucketName, region }) => {
    const bucket = await apiRequest(
      `${OBJECT_STORAGE_ENDPOINT}/${region}/buckets/${bucketName}`,
      {
        method: 'GET'
      },
      true
    )

    return serialize(bucket)
  }

  static check = async () => {
    const access = await apiRequest(
      `${OBJECT_STORAGE_ENDPOINT}/api-access/check`,
      {
        method: 'GET'
      },
      true
    )

    return serialize(access)
  }

  static enable = async () =>
    await apiRequest(
      `${OBJECT_STORAGE_ENDPOINT}/api-access/enable`,
      {
        method: 'POST'
      },
      true
    )
}
