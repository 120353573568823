import { Box, HStack, Skeleton, Text } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { formatCurrency } from '../../helpers/currency'
import { WarningError } from '../Errors'
import {
  useAccountBilling,
  useAccountFF,
  useOrgBilling,
  useOrgFF
} from './hooks'

const Loading = () => <Skeleton h="50px" w="320px" />

const Amount = ({ children, ...props }) => (
  <Text size="2xl" textAlign="end" {...props}>
    {children}
  </Text>
)

const Description = ({ children, ...props }) => (
  <Text size="sm" textAlign="end" {...props}>
    {children}
  </Text>
)

const CreditsLayout = ({ billing }) => {
  const { orgId } = useParams()
  const {
    balancePendingAmount,
    balance,
    totalCurrent,
    totalCredits,
    outstanding
  } = billing
  const { featureFlag } = orgId ? useOrgFF({ orgId }) : useAccountFF({})
  const { enableAccountBalance } = featureFlag || {}

  return (
    <HStack spacing={10} alignItems="flex-end">
      {enableAccountBalance && (
        <Box>
          {balancePendingAmount && (
            <Description color="gray.500">
              + {formatCurrency(balancePendingAmount)} pending
            </Description>
          )}
          <Amount color="black">{formatCurrency(balance)}</Amount>
          <Description color="black">Account Balance</Description>
        </Box>
      )}
      {!enableAccountBalance && (
        <Box>
          <Amount>{formatCurrency(totalCredits)}</Amount>
          <Description>Credits</Description>
        </Box>
      )}
      <Box>
        <Amount color="blue.500">{formatCurrency(totalCurrent)}</Amount>
        <Description color="blue.500">Current Total</Description>
      </Box>
      {outstanding && (
        <Box>
          <Amount color="red.500">-{formatCurrency(outstanding)}</Amount>
          <Description color="red.500">Outstanding</Description>
        </Box>
      )}
    </HStack>
  )
}

export const Credits = () => {
  const { orgId } = useParams()
  const { billing, isLoadingBilling, isErrorBilling } = orgId
    ? useOrgBilling({ orgId })
    : useAccountBilling()

  return isLoadingBilling ? (
    <Loading />
  ) : isErrorBilling ? (
    <WarningError>Unable to load the data</WarningError>
  ) : (
    <CreditsLayout billing={billing} />
  )
}
