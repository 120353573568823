import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text
} from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { mutate } from 'swr'
import { Organization } from '../../../../../../api/organizations'
import { toastMessages } from '../../../../../../constants/toast-messages'
import { useToast } from '../../../../../shared/Toast'

export const RemoveMemberModal = ({ disclosure, member, organization }) => {
  const { isOpen, onClose } = disclosure
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const handleRemove = useCallback(() => {
    setLoading(true)
    Organization.deleteMember(organization.id, member.accountId)
      .then(() => {
        mutate((key) =>
          key.startsWith(`/organizations/${organization.id}/members?`)
        )
        toast({
          status: 'success',
          message: toastMessages.memberDeleteSuccess
        })
      })
      .catch(() =>
        toast({
          status: 'error',
          message: toastMessages.memberDeleteError
        })
      )
      .finally(() => {
        setLoading(false)
        onClose()
      })
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Heading size="xl" mb={8} textAlign="center">
            Remove {member?.account.name} from {organization.name}?
          </Heading>
          <Text
            size="md"
            mb="8"
            fontWeight="400"
            color="red.500"
            textAlign="center"
          >
            User will no longer have access to organization including all
            instances, backups, and other resources.
          </Text>
          <Button
            bg="red.500"
            colorScheme="red"
            isFullWidth
            isLoading={loading}
            onClick={handleRemove}
          >
            Remove member
          </Button>
          <Button
            isFullWidth
            variant="outline"
            colorScheme="offBlack"
            onClick={onClose}
            mt={4}
          >
            Cancel
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
