import {
  TableContainer,
  Table,
  Tbody,
  Thead,
  Tr,
  Th,
  Td,
  Checkbox
} from '@chakra-ui/react'
import { Card } from './Cards'
import { TdTruncated } from './Table'

export const DataTable = ({ columns, rows, selected, onSelect, ...props }) => {
  const handleSelectRows = (event) =>
    event.target.checked ? onSelect([...rows]) : onSelect([])

  const handleSelectRow = (row, event) =>
    event.target.checked
      ? onSelect([...selected, row])
      : onSelect(selected.filter(({ id }) => id !== row.id))

  return (
    <Card px={3} py={0} {...props}>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th w="44px">
                <Checkbox
                  isChecked={selected?.length === rows.length}
                  onChange={handleSelectRows}
                  isInvalid={false}
                  size="lg"
                  mb={0}
                />
              </Th>
              {columns.map((column) => (
                <Th w={column.width ?? 'auto'} key={column.field}>
                  {column.header}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {rows.map((row) => (
              <Tr
                key={row.id}
                _last={{
                  td: {
                    borderBottom: 'none'
                  }
                }}
              >
                <Td>
                  <Checkbox
                    id={row.id}
                    isChecked={selected?.some(({ id }) => id === row.id)}
                    onChange={(event) => handleSelectRow(row, event)}
                    isInvalid={false}
                    size="lg"
                    mb={0}
                  />
                </Td>
                {columns.map((column) => (
                  <TdTruncated key={column.field}>
                    {column.cell ? column.cell(row) : row[column.field] || '-'}
                  </TdTruncated>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Card>
  )
}
