import { Heading, Text, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import routes from '../../../constants/routes'
import { setOnboardingLastRoute } from '../../../helpers/onboarding'
import * as profileType from '../../../constants/profile-type'
import { OrganizationIdStorage } from '../../shared/storage'
import OnboardingLayout from '../Layout'
import OrganizationForm from './OrganizationForm'
import PersonalForm from './PersonalForm'

const Details = () => {
  const user = useSelector((state) => state.oidc.user)
  const [type, setType] = useState()

  useEffect(() => {
    if (user) {
      if (OrganizationIdStorage.get()) {
        setType(profileType.organization)
      } else {
        setType(profileType.personal)
      }
      setOnboardingLastRoute(user.profile.sub, routes.onboarding.details)
    }
  }, [user])

  return (
    <OnboardingLayout>
      <VStack spacing={4}>
        <Heading align="center" as="h1" size="3xl">
          Profile details
        </Heading>
        <Text
          pb={4}
          color="gray.500"
          size="md"
          fontWeight="normal"
          textAlign="center"
        >
          Tell us what you are interested in. We will help you get there.
        </Text>
        {type === profileType.personal && <PersonalForm />}
        {type === profileType.organization && <OrganizationForm />}
      </VStack>
    </OnboardingLayout>
  )
}

export default Details
