import { Text } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { SnapshotCards } from '../RadioCards'
import {
  DEFAULT_CATALOG,
  DEFAULT_OPTIONS
} from '../../../../../constants/defaults'

const NoData = () => <Text color="gray.500">There are no Snapshots yet.</Text>

const Snapshots = ({ items }) => {
  const { values, setValues } = useFormikContext()
  const noData = items?.length === 0

  const onChange = (image) => {
    const { id, name } = image
    setValues((prev) => ({
      ...prev,
      image: {
        ...prev.image,
        id,
        name,
        tab: 'snapshots'
      },
      catalog: DEFAULT_CATALOG,
      options: DEFAULT_OPTIONS
    }))
  }

  return noData ? (
    <NoData />
  ) : (
    <SnapshotCards items={items} value={values.image.id} onChange={onChange} />
  )
}

export default Snapshots
