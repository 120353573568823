import textStyles from '../foundations/textStyles'

const Tabs = {
  parts: ['tabpanels', 'tabpanel', 'tab', 'tablist'],
  baseStyle: {
    tabpanels: {
      mt: 5
    },
    tabpanel: {
      p: 0
    }
  },
  variants: {
    rounded: ({ colorScheme }) => ({
      tablist: {
        px: 1,
        py: 1,
        borderRadius: '22px',
        bg: `${colorScheme}.100`
      },
      tab: {
        ...textStyles.sm,
        px: 6,
        width: '100%',
        color: `${colorScheme}.500`,
        fontWeight: 'bold',
        borderRadius: '20px',
        _selected: { color: 'black', bg: 'white' },
        _disabled: { color: `${colorScheme}.300` }
      }
    })
  },
  defaultProps: {
    colorScheme: 'black'
  }
}

export default Tabs
