import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Stack
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import React, { useCallback, useContext } from 'react'
import { Link as ReactLink, useHistory } from 'react-router-dom'
import { mutate } from 'swr'
import * as yup from 'yup'
import { Organization } from '../../../../../../../api/organizations'
import routes from '../../../../../../../constants/routes'
import { toastMessages } from '../../../../../../../constants/toast-messages'
import { getDefaultProjectId } from '../../../../../../../helpers/organizations'
import { withOrgPathFallback } from '../../../../../../../router'
import Asterisk from '../../../../../../shared/Asterisk'
import { useToast } from '../../../../../../shared/Toast'
import { useIsMounted } from '../../../../../../shared/hooks/useIsMounted'
import {
  OrganizationIdStorage,
  ProjectIdStorage
} from '../../../../../../shared/storage'
import { UsageTypeContext } from '../../../../../../shared/usageType'

const UsageTypeForm = () => {
  const { usageType } = useContext(UsageTypeContext)
  const isMounted = useIsMounted()
  const history = useHistory()
  const toast = useToast()
  const schema = yup.object().shape({
    name: yup.string().required('Organization name is required'),
    website: yup.string(),
    linkedIn: yup.string()
  })

  const handleSubmit = useCallback(
    async ({ name, website, linkedIn }, { setSubmitting }) => {
      setSubmitting(true)
      try {
        const data = {
          usageType,
          name
        }
        if (website) {
          data.website = website
        }
        if (linkedIn) {
          data.linkedin_profile = linkedIn
        }
        const organization = await Organization.create(data)
        OrganizationIdStorage.set(organization.id)
        ProjectIdStorage.set(getDefaultProjectId(organization))
        await mutate((key) => key.startsWith(`/organizations?`))
        await mutate(`/organizations/${organization.id}`)
        history.push(
          withOrgPathFallback(routes.dashboard.organizations.create.details)
        )
      } catch {
        toast({
          status: 'error',
          message: toastMessages.organizationCreateError
        })
      } finally {
        if (isMounted()) {
          setSubmitting(false)
        }
      }
    },
    [history, usageType]
  )

  return (
    <Formik
      initialValues={{
        name: '',
        website: '',
        linkedIn: ''
      }}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, errors, touched, isSubmitting }) => (
        <Form style={{ width: '100%' }} onSubmit={handleSubmit}>
          <Stack spacing={8}>
            <FormControl isInvalid={errors.name && touched.name}>
              <FormLabel>
                <Asterisk />
                Organization name
              </FormLabel>
              <Field
                as={Input}
                name="name"
                placeholder="Enter your organization name"
              />
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.website && touched.website}>
              <FormLabel>Website</FormLabel>
              <Field as={InputGroup}>
                <Input name="website" placeholder="Enter URL" />
              </Field>
              <FormErrorMessage>{errors.website}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.linkedIn && touched.linkedIn}>
              <FormLabel>LinkedIn profile</FormLabel>
              <Field as={InputGroup}>
                <Input name="linkedIn" placeholder="Enter URL" />
              </Field>
              <FormErrorMessage>{errors.linkedIn}</FormErrorMessage>
            </FormControl>
            <Stack spacing={4}>
              <Button
                type="submit"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Save and continue
              </Button>
              <Button
                as={ReactLink}
                to={withOrgPathFallback(routes.dashboard.organizations.index)}
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isSubmitting}
                isFullWidth
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  )
}

export default UsageTypeForm
