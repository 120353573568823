import { enableRegions } from '../../../../shared/Region'
import { reservationRegions } from '../../../../../constants/regions'
import { RegionField } from '../../../../shared/Fields'

const Region = ({ regions }) => {
  const enabledRegions = enableRegions(reservationRegions, regions)

  return <RegionField name="region" regions={enabledRegions} />
}

export default Region
