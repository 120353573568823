import {
  Button,
  Grid,
  HStack,
  Spacer,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { Card } from '../../../../../shared/Cards'
import { Edit as EditIcon } from '../../../../../shared/Icons'
import { DefaultBadge, LegacyBadge } from '../../../../../shared/Badge'
import { EditModal } from '../modals'
import CopyButton from '../../../../../shared/CopyButton'

const Edit = ({ project }) => {
  const editDisclosure = useDisclosure()

  return (
    <Card>
      <HStack mb={8}>
        <Text size="3xl" noOfLines={1} maxWidth="600px" data-hj-suppress>
          {project?.name}
        </Text>
        {project?.isDefault && <DefaultBadge />}
        {project?.isLegacy && <LegacyBadge />}
        <Spacer />
        <Button
          w={40}
          colorScheme="offBlack"
          variant="outline"
          leftIcon={<EditIcon />}
          onClick={editDisclosure.onOpen}
        >
          Edit Project
        </Button>
      </HStack>
      <Grid templateColumns="1fr 1fr" columnGap={16} rowGap={5}>
        <Stack spacing={0}>
          <Text>Description</Text>
          <Text color="gray.500">{project?.description || '-'}</Text>
        </Stack>
        <Stack spacing={0}>
          <Text>Project ID</Text>
          <CopyButton color="gray.500" value={project?.id} />
        </Stack>
      </Grid>
      <EditModal disclosure={editDisclosure} />
    </Card>
  )
}

export default Edit
