import {
  RadioGroup,
  Radio,
  Link,
  Text,
  Stack,
  FormControl,
  FormErrorMessage
} from '@chakra-ui/react'
import { Card } from '../../../../shared/Cards'
import { useFormikContext } from 'formik'
import { externalLinks } from '../../../../../helpers/links'

const Drivers = ({ drivers }) => {
  const { values, setValues, errors } = useFormikContext()
  const isDisabled = values.options.isScriptsChecked
  const value = values.options.driver
  const error = errors?.options?.driver

  const onChange = (driver) =>
    setValues((prev) => ({
      ...prev,
      options: {
        ...prev.options,
        driver
      }
    }))

  return (
    <FormControl isInvalid={error}>
      <Card
        as={Stack}
        spacing={5}
        p={5}
        hasError={error}
        isDisabled={isDisabled}
      >
        <Stack spacing={1}>
          <Text fontWeight={600}>Drivers</Text>
          <Text color="gray.500" size="sm">
            Note: the installation will happen during the first minutes of your{' '}
            <Link href={externalLinks.instanceDrivers} isExternal>
              Instance being active.
            </Link>
          </Text>
        </Stack>
        <RadioGroup value={value} onChange={onChange} isDisabled={isDisabled}>
          <Stack spacing={3}>
            {drivers.map(({ slug, name }) => (
              <Radio key={slug} value={slug} isInvalid={false}>
                {name}
              </Radio>
            ))}
            <Radio value="" isInvalid={false}>
              None
            </Radio>
          </Stack>
        </RadioGroup>
      </Card>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}

export default Drivers
