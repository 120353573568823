import { Link, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import moment from 'moment'
import { useEffect } from 'react'
import { Link as ReactLink, useParams } from 'react-router-dom'
import routes from '../../../../../constants/routes'
import { slugs } from '../../../../../helpers/billing'
import { formatDate } from '../../../../../helpers/date'
import { statuses } from '../../../../../helpers/reservations'
import { withOrgPathFallback } from '../../../../../router'
import {
  useAccountInstanceInfo,
  useOrgInstanceInfo
} from '../../../../shared/hooks'
import { Error } from '../../../../shared/Error'

const defaults = {
  id: slugs.reserved,
  slug: slugs.reserved,
  type: slugs.reserved
}

const findEarliestReservation = (reservations) => {
  let earliest = reservations[0]
  reservations.forEach((item) => {
    if (moment(item.startedAt).isBefore(moment(earliest.startedAt))) {
      earliest = item
    }
  })
  return earliest
}

const FirstItem = ({ children }) => <Text color="gray.500">{children}</Text>

const SecondItem = ({ children }) => (
  <Text color="gray.900" textAlign="end">
    {children}
  </Text>
)

const NoReservations = () => {
  const { values, setValues } = useFormikContext()

  useEffect(() => {
    const billing = {
      ...defaults,
      reservationId: ''
    }
    setValues({ ...values, billing })
  }, [])

  return (
    <Stack>
      <Text size="xs" color="red.500">
        There are no active reservations <br />
        <Link
          as={ReactLink}
          colorScheme="red"
          to={withOrgPathFallback(routes.dashboard.reservations.index)}
        >
          Click here to manage reservations
        </Link>
        .
      </Text>
    </Stack>
  )
}

const ActiveReservation = ({ reservations }) => {
  const { orgId, projectId } = useParams()
  const { values, setValues } = useFormikContext()
  const reservation = reservations[0]
  const { startedAt, endedAt, id: reservationId } = reservation
  const { isLoadingInfo, isErrorInfo } = orgId
    ? useOrgInstanceInfo({ orgId, projectId, reservationId })
    : useAccountInstanceInfo({ reservationId })

  useEffect(() => {
    if (reservation) {
      const billing = {
        ...defaults,
        reservationId
      }
      setValues({ ...values, billing })
    }
  }, [reservationId])

  return isLoadingInfo ? null : isErrorInfo ? (
    <Error />
  ) : (
    <Stack>
      <Text size="xs">
        A reservation is active. Instances will be stopped at the end of the
        reservation period.{' '}
        <Link
          as={ReactLink}
          to={withOrgPathFallback(routes.dashboard.reservations.index)}
        >
          Manage reservations here
        </Link>
        .
      </Text>
      <SimpleGrid columns={2} justifyContent="space-between">
        <FirstItem>Start</FirstItem>
        <SecondItem>{formatDate(startedAt)}</SecondItem>
        <FirstItem>End</FirstItem>
        <SecondItem>{formatDate(endedAt)}</SecondItem>
      </SimpleGrid>
    </Stack>
  )
}

const ScheduledReservation = ({ reservations }) => {
  const { values, setValues } = useFormikContext()
  const reservation = findEarliestReservation(reservations)
  const { startedAt, endedAt } = reservation
  const startDate = moment(startedAt).fromNow()

  useEffect(() => {
    const billing = {
      ...defaults,
      reservationId: ''
    }
    setValues({ ...values, billing })
  }, [])

  return (
    <Stack>
      <Text size="xs" color="red.500">
        Unable to create instance. <br /> A reservation will start {startDate}.
      </Text>
      <SimpleGrid columns={2} justifyContent="space-between">
        <FirstItem>Start</FirstItem>
        <SecondItem>{formatDate(startedAt)}</SecondItem>
        <FirstItem>End</FirstItem>
        <SecondItem>{formatDate(endedAt)}</SecondItem>
      </SimpleGrid>
    </Stack>
  )
}

const Reservation = ({ reservations }) => {
  const activeReservations = reservations.filter(
    (res) => res.status === statuses.active
  )
  const scheduledReservations = reservations.filter(
    (res) => res.status === statuses.scheduled
  )

  const hasActiveReservations = activeReservations?.length > 0
  const hasScheduledReservations = scheduledReservations?.length > 0

  if (hasScheduledReservations && !hasActiveReservations) {
    return <ScheduledReservation reservations={scheduledReservations} />
  }

  if (hasActiveReservations) {
    return <ActiveReservation reservations={activeReservations} />
  }

  return <NoReservations />
}

const Reservations = ({ reservations }) => {
  const { values } = useFormikContext()

  const filtredReservations = reservations
    .filter((res) => res.region === values?.region)
    .filter((res) => res.flavorName === values?.type?.flavorName)
  const hasReservations =
    reservations?.length === 0 || filtredReservations?.length === 0

  return hasReservations ? (
    <NoReservations />
  ) : (
    <Reservation reservations={filtredReservations} />
  )
}

export default Reservations
