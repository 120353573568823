import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Stack,
  Text,
  Button
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'

export const CreateModal = ({ disclosure }) => {
  const { isOpen, onClose } = disclosure
  const { isSubmitting, submitForm } = useFormikContext()

  const handleSubmit = () => {
    onClose()
    submitForm()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={8}>
            <Text size="xl" textAlign="center" color="red.500">
              Billed on-demand once the Instance is created and until the
              Instance is deleted.
            </Text>
            <Stack spacing={4}>
              <Button isLoading={isSubmitting} onClick={handleSubmit}>
                Create Instance
              </Button>
              <Button
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isSubmitting}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
