import {
  Tr,
  Td,
  HStack,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
  useDisclosure
} from '@chakra-ui/react'
import { TdTruncated, TdTruncatedLines } from '../../../../../shared/Table'
import {
  EndingBadge,
  ActiveBadge,
  EndedBadge
} from '../../../../../shared/Badge'
import { Dots, Cancel, Refresh } from '../../../../../shared/Icons'
import { formatDateTime } from '../../../../../../helpers/date'
import { formatCurrency } from '../../../../../../helpers/currency'
import { getLongTermPeriod } from '../../utils'
import RenewInfo from './renew-info'
import { RenewModal, CancelModal } from '../../modals'

const TableRow = ({ row, orgId, hasPermission }) => {
  const renewDisclosure = useDisclosure()
  const cancelDisclosure = useDisclosure()

  const {
    id: resourceId,
    name,
    product,
    startedAt,
    endedAt,
    renewsAt,
    endingAt,
    status,
    period,
    amount
  } = row

  const endDate = endedAt || renewsAt || endingAt

  const isActive = status === 'active'
  const isEnded = status === 'ended'

  const isRenewing = isActive && !!renewsAt
  const isEnding = isActive && !!endingAt

  const renewData = {
    orgId,
    resourceId,
    validityDate: formatDateTime(endingAt)
  }

  const cancelData = {
    orgId,
    resourceId,
    validityDate: formatDateTime(renewsAt)
  }

  return (
    <Tr>
      <TdTruncated>{name || '-'}</TdTruncated>
      <TdTruncatedLines noOfLines={2}>{product}</TdTruncatedLines>
      <Td>{formatDateTime(startedAt)}</Td>
      <Td>
        <HStack spacing={2}>
          <Text color="gray.500">{formatDateTime(endDate)}</Text>
          {isRenewing && <RenewInfo />}
        </HStack>
      </Td>
      <Td>
        {isEnding ? (
          <EndingBadge />
        ) : isActive ? (
          <ActiveBadge />
        ) : isEnded ? (
          <EndedBadge />
        ) : (
          '-'
        )}
      </Td>
      <Td>{getLongTermPeriod(period)}</Td>
      <Td>{formatCurrency(amount)}</Td>
      <Td>
        {hasPermission && !isEnded && (
          <Menu variant="table" autoSelect={false}>
            <MenuButton variant="icon" as={IconButton} icon={<Dots />} />
            <MenuList>
              {isEnding ? (
                <>
                  <MenuItem gap={2} onClick={renewDisclosure.onOpen}>
                    <Refresh boxSize={5} />
                    Renew subscription
                  </MenuItem>
                  <RenewModal disclosure={renewDisclosure} data={renewData} />
                </>
              ) : (
                <>
                  <MenuItem gap={2} onClick={cancelDisclosure.onOpen}>
                    <Cancel boxSize={5} />
                    Cancel subscription
                  </MenuItem>
                  <CancelModal
                    disclosure={cancelDisclosure}
                    data={cancelData}
                  />
                </>
              )}
            </MenuList>
          </Menu>
        )}
      </Td>
    </Tr>
  )
}

export default TableRow
