import useSWR from 'swr'
import { AccountBilling, OrgBilling } from '../../../api/billing'
import { serialize } from '../../../helpers/serializers'
import { storages } from '../../Dashboard/profile/billing/storage/utils'
import { INITIAL_PAGE, PER_PAGE_5 } from '../../shared/Table'

const swrOptions = {
  revalidateOnFocus: false
}

export const useOrgBilling = ({ orgId }) => {
  const key = `/billing/customer?org=${orgId}`
  const fetcher = () => OrgBilling.fetchCustomer({ orgId })

  const { data, isValidating, isLoading, error } = useSWR(
    key,
    fetcher,
    swrOptions
  )

  return {
    billing: serialize(data),
    isValidatingBilling: isValidating,
    isLoadingBilling: isLoading,
    isErrorBilling: Boolean(error)
  }
}

export const useOrgCreditCard = ({
  orgId,
  creditCardId,
  shouldFetch = true
}) => {
  const key = shouldFetch
    ? `/billing/credit-card/${creditCardId}?org=${orgId}`
    : null
  const fetcher = () => OrgBilling.fetchCreditCard({ orgId, creditCardId })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  return {
    creditCard: serialize(data),
    isLoadingCreditCard: isLoading,
    isErrorCreditCard: Boolean(error)
  }
}

export const useOrgBillingOnDemands = ({
  orgId,
  page = INITIAL_PAGE,
  perPage = PER_PAGE_5
}) => {
  const key = `/billing/resources/on-demand?page=${page}&per_page=${perPage}&org=${orgId}`
  const fetcher = () => OrgBilling.fetchOnDemands({ orgId, page, perPage })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  let items, pagination
  if (data) ({ items, ...pagination } = serialize(data))

  return {
    onDemands: items,
    pagination,
    isLoadingOnDemands: isLoading,
    isErrorOnDemands: Boolean(error)
  }
}

export const useOrgBillingLongTerms = ({
  orgId,
  page = INITIAL_PAGE,
  perPage = PER_PAGE_5
}) => {
  const key = `/billing/resources/longterm?page=${page}&per_page=${perPage}&org=${orgId}`
  const fetcher = () => OrgBilling.fetchLongTerms({ orgId, page, perPage })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  let items, pagination
  if (data) ({ items, ...pagination } = serialize(data))

  return {
    longTerms: items,
    pagination,
    isLoadingLongTerms: isLoading,
    isErrorLongTerms: Boolean(error)
  }
}

export const useOrgBillingReservations = ({
  orgId,
  page = INITIAL_PAGE,
  perPage = PER_PAGE_5
}) => {
  const key = `/billing/reservations?page=${page}&per_page=${perPage}&org=${orgId}`
  const fetcher = () => OrgBilling.fetchReservations({ orgId, page, perPage })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  let items, pagination
  if (data) ({ items, ...pagination } = serialize(data))

  return {
    reservations: items,
    pagination,
    isLoadingReservations: isLoading,
    isErrorReservations: Boolean(error)
  }
}

export const useOrgBillingStorage = ({
  orgId,
  type,
  startingAfter = undefined,
  endingBefore = undefined
}) => {
  const key = `/billing/storage-usage?org=${orgId}&type=${type}&grouping=day`
  const fetcher = () =>
    OrgBilling.fetchStorage({ orgId, type, startingAfter, endingBefore })

  const { data, isLoading, error } = useSWR(key, fetcher, {
    revalidateOnFocus: false
  })
  let items, total
  if (data) {
    ;({ items, total } = serialize(data))
    items = items.map(({ aggregatedValue, ...item }) => ({
      ...item,
      aggregatedValue: aggregatedValue / 1440 / 1000
    }))
  }
  const label = storages.find((storage) => storage.type === type).label

  const storage = [{ label, data: items || [], total }]

  return {
    storage,
    isLoadingStorage: isLoading,
    isErrorStorage: Boolean(error)
  }
}

export const useOrgBillingHistory = ({ orgId }) => {
  const key = `/billing/history?org=${orgId}`
  const fetcher = () => OrgBilling.fetchHistory({ orgId })

  const { data, isLoading, error } = useSWR(key, fetcher)

  let invoices
  if (data) ({ invoices } = serialize(data))

  return {
    invoices,
    isLoadingHistory: isLoading,
    isErrorHistory: Boolean(error)
  }
}

export const useOrgBillingAlarm = ({ orgId, shouldFetch = true }) => {
  const key = shouldFetch ? `/billing/alarms?org=${orgId}` : null
  const fetcher = () => OrgBilling.fetchAlarm({ orgId })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  return {
    alarm: serialize(data),
    isLoadingAlarm: isLoading,
    isErrorAlarm: Boolean(error)
  }
}

export const useOrgBillingAutoRecharge = ({ orgId, shouldFetch = true }) => {
  const key = shouldFetch ? `/billing/auto-recharge?org=${orgId}` : null
  const fetcher = () => OrgBilling.fetchAutoRecharge({ orgId })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  return {
    autoRecharge: serialize(data),
    isLoadingAutoRecharge: isLoading,
    isErrorAutoRecharge: Boolean(error)
  }
}

export const useOrgBillingNotification = ({ orgId, shouldFetch = true }) => {
  const key = shouldFetch ? `/billing/notification?org=${orgId}` : null
  const fetcher = () => OrgBilling.fetchNotification({ orgId })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  return {
    notification: serialize(data),
    isLoadingNotification: isLoading,
    isErrorNotification: Boolean(error)
  }
}

export const useAccountBilling = () => {
  const key = '/billing/customer'
  const fetcher = () => AccountBilling.fetchCustomer()

  const { data, isValidating, isLoading, error } = useSWR(
    key,
    fetcher,
    swrOptions
  )

  return {
    billing: serialize(data),
    isValidatingBilling: isValidating,
    isLoadingBilling: isLoading,
    isErrorBilling: Boolean(error)
  }
}

export const useAccountCreditCard = ({ creditCardId, shouldFetch = true }) => {
  const key = shouldFetch ? `/billing/credit-card/${creditCardId}` : null
  const fetcher = () => AccountBilling.fetchCreditCard({ creditCardId })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  return {
    creditCard: serialize(data),
    isLoadingCreditCard: isLoading,
    isErrorCreditCard: Boolean(error)
  }
}

export const useAccountBillingOnDemands = ({
  page = INITIAL_PAGE,
  perPage = PER_PAGE_5
}) => {
  const key = `/billing/resources/on-demand?page=${page}&per_page=${perPage}`
  const fetcher = () => AccountBilling.fetchOnDemands({ page, perPage })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  let items, pagination
  if (data) ({ items, ...pagination } = serialize(data))

  return {
    onDemands: items,
    pagination,
    isLoadingOnDemands: isLoading,
    isErrorOnDemands: Boolean(error)
  }
}

export const useAccountBillingLongTerms = ({
  page = INITIAL_PAGE,
  perPage = PER_PAGE_5
}) => {
  const key = `/billing/resources/longterm?page=${page}&per_page=${perPage}`
  const fetcher = () => AccountBilling.fetchLongTerms({ page, perPage })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  let items, pagination
  if (data) ({ items, ...pagination } = serialize(data))

  return {
    longTerms: items,
    pagination,
    isLoadingLongTerms: isLoading,
    isErrorLongTerms: Boolean(error)
  }
}

export const useAccountBillingReservations = ({
  page = INITIAL_PAGE,
  perPage = PER_PAGE_5
}) => {
  const key = `/billing/reservations?page=${page}&per_page=${perPage}`
  const fetcher = () => AccountBilling.fetchReservations({ page, perPage })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  let items, pagination
  if (data) ({ items, ...pagination } = serialize(data))

  return {
    reservations: items,
    pagination,
    isLoadingReservations: isLoading,
    isErrorReservations: Boolean(error)
  }
}

export const useAccountBillingStorage = ({
  type,
  startingAfter = undefined,
  endingBefore = undefined
}) => {
  const key = `/billing/storage-usage?type=${type}&grouping=day`
  const fetcher = () =>
    AccountBilling.fetchStorage({ type, startingAfter, endingBefore })

  const { data, isLoading, error } = useSWR(key, fetcher, {
    revalidateOnFocus: false
  })

  let items
  if (data) ({ items } = serialize(data))

  const label = storages.find((storage) => storage.type === type).label

  const storage = [{ label, data: items || [] }]

  return {
    storage,
    isLoadingStorage: isLoading,
    isErrorStorage: Boolean(error)
  }
}

export const useAccountBillingHistory = () => {
  const key = '/billing/history'
  const fetcher = () => AccountBilling.fetchHistory()

  const { data, isLoading, error } = useSWR(key, fetcher)

  let invoices
  if (data) ({ invoices } = serialize(data))

  return {
    invoices,
    isLoadingHistory: isLoading,
    isErrorHistory: Boolean(error)
  }
}

export const useAccountBillingAlarm = ({ shouldFetch = true }) => {
  const key = shouldFetch ? '/billing/alarms' : null
  const fetcher = () => AccountBilling.fetchAlarm()

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  return {
    alarm: serialize(data),
    isLoadingAlarm: isLoading,
    isErrorAlarm: Boolean(error)
  }
}

export const useAccountBillingAutoRecharge = ({ shouldFetch = true }) => {
  const key = shouldFetch ? '/billing/auto-recharge' : null
  const fetcher = () => AccountBilling.fetchAutoRecharge()

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  return {
    autoRecharge: serialize(data),
    isLoadingAutoRecharge: isLoading,
    isErrorAutoRecharge: Boolean(error)
  }
}

export const useAccountBillingNotification = ({ shouldFetch = true }) => {
  const key = shouldFetch ? '/billing/notification' : null
  const fetcher = () => AccountBilling.fetchNotification()

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  return {
    notification: serialize(data),
    isLoadingNotification: isLoading,
    isErrorNotification: Boolean(error)
  }
}

export const transformBillingPayload = (values) => ({
  name: values.name,
  company_name: values.companyName,
  vat: values.vat,
  address: values.address,
  city: values.city,
  state: values.state,
  postal_code: values.postalCode,
  country_code: values.countryCode,
  phone: values.phone,
  email: values.email,
  vat_type_id: values.vatType.split('-')[0]
})
