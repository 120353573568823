import { Grid, Heading, Stack } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { generate } from 'generate-password'
import { useHistory, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { AccountInstances, OrgInstances } from '../../../../api/instances'
import { PASSWORD, SSH_KEYS } from '../../../../constants/create-instance'
import {
  DEFAULT_CATALOG,
  DEFAULT_IMAGE,
  DEFAULT_METHOD,
  PASSWORD_OPTIONS,
  DEFAULT_NETWORK,
  DEFAULT_OPTIONS,
  DEFAULT_PARAMS,
  DEFAULT_REGION
} from '../../../../constants/defaults'
import routes from '../../../../constants/routes'
import { slugs } from '../../../../helpers/billing'
import { debounce } from '../../../../helpers/utils'
import { withOrgPathFallback } from '../../../../router'
import { apiErrorHandler } from '../../../shared/ApiErrorHandler'
import { ReturnLink } from '../../../shared/Links'
import { useToast } from '../../../shared/Toast'
import { useProjectVersion } from '../../../shared/hooks'
import {
  hasClusterAvailability,
  hasQuotaCapacity
} from '../../../shared/hooks/configs/instance'
import { transformPayload } from '../../../shared/hooks/configs/payload'
import { useIsMounted } from '../../../shared/hooks/useIsMounted'
import Authentication from './Authentication'
import Billing from './Billing'
import Configuration from './Configuration'
import { AvailabilityError, CapacityError } from './Configuration/Errors'
import Image from './Image'
import Name from './Name'
import Options from './Options'
import Region from './Region'
import SecurityGroups from './SecurityGroups'
import Summary from './Summary'
import Type from './Type'
import Volumes from './Volumes'
import { SwitchHandler, successHandler } from './utils'

const validationSchema = yup.object().shape({
  name: yup.object().shape({
    hostname: yup
      .string()
      .required('Hostname is required')
      .matches(/^[a-z0-9]([-a-z0-9]*[a-z0-9])?$/, 'Invalid hostname')
      .max(63),
    name: yup.string().required('Name is required')
  }),
  capacity: yup.object().test({
    name: 'capacity limit',
    test: (_, { createError, parent: { capacity, type } }) =>
      !hasQuotaCapacity({
        sliderValue: type?.configurations?.selected.sliderValue,
        capacity,
        type
      }) ||
      createError({
        message: <CapacityError />
      })
  }),
  availability: yup.mixed().test({
    name: 'availability limit',
    test: (
      _,
      { createError, parent: { availability, region, typeConfigSlug } }
    ) =>
      !hasClusterAvailability({
        availability,
        region,
        typeConfigSlug
      }) ||
      createError({
        message: <AvailabilityError region={region} />
      })
  }),
  hasAvailability: yup
    .boolean()
    .oneOf([true], 'Availability or quota not available'),
  image: yup.object().shape({
    id: yup.string().required('Image is required.'),
    name: yup.string(),
    tab: yup.string(),
    selected: yup.object()
  }),
  catalog: yup.object().shape({
    id: yup.string(),
    name: yup.string(),
    hasPassword: yup.boolean(),
    password: yup.string().when('hasPassword', {
      is: true,
      then: yup.string().required('Password is required.')
    })
  }),
  authentication: yup.object().shape({
    method: yup.string().oneOf([SSH_KEYS, PASSWORD]).required(),
    sshKeys: yup.array().when('method', {
      is: (method) => method === SSH_KEYS,
      then: () => yup.array().min(1, 'At least one SSH key must be selected.')
    }),
    isEmpty: yup.boolean(),
    password: yup.string().when('method', {
      is: (method) => method === PASSWORD,
      then: yup.string().required('Password is required.')
    }),
    hasCopied: yup.boolean().when('method', {
      is: (method) => method === PASSWORD,
      then: yup.boolean().oneOf([true], 'Copy and save the password.')
    })
  }),
  network: yup.object().shape({
    securityGroups: yup
      .array()
      .min(1, 'At least one Security Group must be selected.'),
    isEmpty: yup.boolean()
  }),
  volumes: yup.array(),
  options: yup.object().shape({
    isStaticIPChecked: yup.boolean(),
    isFloatingIPChecked: yup.boolean(),
    floatingIP: yup.string().when('isFloatingIPChecked', {
      is: true,
      then: yup.string().required('Assigning floating ip is required.')
    }),
    isScriptsChecked: yup.boolean(),
    scripts: yup.string().when('isScriptsChecked', {
      is: true,
      then: yup.string().required('User data is required.')
    }),
    hasDriversSupport: yup.boolean(),
    isDriversRequired: yup.boolean(),
    driver: yup.string().when(['isScriptsChecked', 'isDriversRequired'], {
      is: (isScriptsChecked, isDriversRequired) =>
        !isScriptsChecked && isDriversRequired,
      then: yup.string().required('Driver is required.')
    })
  }),
  params: yup.object().shape({
    snapshot: yup.object(),
    isFirstLoad: yup.boolean()
  })
})

const initialValues = {
  region: DEFAULT_REGION,
  type: {},
  typeSlug: '',
  typeConfigSlug: '',
  name: {
    hostname: '',
    name: ''
  },
  capacity: {},
  availability: {},
  image: DEFAULT_IMAGE,
  catalog: DEFAULT_CATALOG,
  authentication: {
    method: DEFAULT_METHOD,
    sshKeys: [],
    isEmpty: true,
    password: generate(PASSWORD_OPTIONS),
    hasCopied: false
  },
  network: DEFAULT_NETWORK,
  volumes: [],
  options: DEFAULT_OPTIONS,
  billing: {
    id: slugs.onDemand,
    type: slugs.onDemand,
    reservationId: ''
  },
  params: DEFAULT_PARAMS
}

const InstanceCreate = () => {
  const { orgId, projectId } = useParams()
  const history = useHistory()
  const isMounted = useIsMounted()
  const { isB2 } = useProjectVersion({ orgId, projectId })
  const toast = useToast()

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    const payload = transformPayload(values)

    try {
      const response = orgId
        ? await OrgInstances.create({
            orgId,
            projectId,
            payload
          })
        : await AccountInstances.create({
            payload
          })
      successHandler(response, history, toast)
    } catch (error) {
      const message = await apiErrorHandler(error)
      toast({
        status: 'error',
        message
      })
    } finally {
      if (isMounted()) {
        setSubmitting(false)
      }
    }
  }

  return (
    <Stack spacing={8}>
      <Stack spacing={0}>
        <ReturnLink to={withOrgPathFallback(routes.dashboard.instances.index)}>
          Instances
        </ReturnLink>
        <Heading as="h1" size="4xl">
          Create new Instance
        </Heading>
      </Stack>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={debounce(onSubmit)}
        validateOnMount
      >
        <Form width="100%">
          <Grid templateColumns="2fr 1fr" gap={8}>
            <Stack spacing={16}>
              <Region />
              <Name />
              <Type />
              <Configuration />
              <Image />
              <Authentication />
              <SecurityGroups />
              {isB2 && <Volumes />}
              <Options />
            </Stack>
            <div>
              <Stack spacing={5} position="sticky" top={8}>
                <Billing />
                <Summary />
              </Stack>
            </div>
          </Grid>
          <SwitchHandler />
        </Form>
      </Formik>
    </Stack>
  )
}

export default InstanceCreate
