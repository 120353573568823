import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import { Project } from '../../../../../../api/projects'
import messages from '../../../../../../constants/messages'
import routes from '../../../../../../constants/routes'
import { getDefaultProjectId } from '../../../../../../helpers/organizations'
import { apiErrorHandler } from '../../../../../shared/ApiErrorHandler'
import { useToast } from '../../../../../shared/Toast'
import { useAccount } from '../../../../../shared/hooks/account'
import { useOrganization } from '../../../../../shared/hooks/organization'
import { useSwitchProject } from '../../../../../shared/hooks/project/useSwitchProject'

export const LeaveModal = ({ disclosure, project }) => {
  const { isOpen, onClose } = disclosure
  const { orgId } = useParams()
  const switchProject = useSwitchProject()
  const { organization } = useOrganization()
  const defaultProjectId = getDefaultProjectId(organization)
  const { account } = useAccount()
  const toast = useToast()

  const handleSubmit = useCallback(
    async (_, { setSubmitting }) => {
      setSubmitting(true)
      try {
        const response = await Project.deleteMember({
          orgId,
          projectId: project.id,
          memberId: account.accountId
        })
        if (response.ok) {
          mutate((key) => key.startsWith(`/organizations/${orgId}/projects?`))
          onClose()
          switchProject(defaultProjectId, routes.dashboard.projects)
        }
      } catch (error) {
        const message = await apiErrorHandler(error)
        toast({
          status: 'error',
          message
        })
      } finally {
        setSubmitting(false)
      }
    },
    [orgId, project, account]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Heading size="xl" mb={4} textAlign="center">
            Are you sure you want to leave {project?.name}?
          </Heading>
          <Text
            mb={8}
            size="md"
            textAlign="center"
            color="red.500"
            fontWeight="normal"
          >
            {messages.projectLeaveInfo}
          </Text>
          <Formik
            initialValues={{ confirmation: '' }}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <Stack spacing={8}>
                  <Stack spacing={4}>
                    <Button
                      type="submit"
                      isDisabled={isSubmitting}
                      isLoading={isSubmitting}
                      colorScheme="red"
                    >
                      Leave Project
                    </Button>
                    <Button
                      colorScheme="offBlack"
                      variant="outline"
                      isDisabled={isSubmitting}
                      onClick={onClose}
                      isFullWidth
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
