import { HStack, IconButton, Tbody, Td, Text, Tr } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import {
  ArrowBack,
  ArrowBackDouble,
  ArrowForward,
  ArrowForwardDouble
} from './Icons'

export const INITIAL_PAGE = 1
export const PER_PAGE = 10
export const PER_PAGE_5 = 5

export const TdTruncated = ({ children, ...props }) => {
  return (
    <Td {...props} overflow="hidden" textOverflow="ellipsis" maxWidth="0">
      {children}
    </Td>
  )
}

export const TdTruncatedLines = ({
  noOfLines = 1,
  color = 'gray.500',
  lineHeight = '24px',
  children
}) => {
  return (
    <Td whiteSpace="normal">
      <Text noOfLines={noOfLines} color={color} lineHeight={lineHeight}>
        {children}
      </Text>
    </Td>
  )
}

export const TableCell = ({ children, ...props }) => (
  <Tr {...props}>
    <Td borderBottom="none">
      <Text size="sm" color="gray.500" mt={2}>
        {children}
      </Text>
    </Td>
  </Tr>
)

export const TableError = ({ children, ...props }) => (
  <Tbody {...props}>
    <Tr>
      <Td borderBottom="none">
        <Text size="sm" color="gray.500" mt={2}>
          {children}
          <br />
          Please refresh this page or contact Genesis Cloud support if the error
          persists.
        </Text>
      </Td>
    </Tr>
  </Tbody>
)

export const TablePagination = ({
  page,
  setPage,
  totalCount,
  perPage = PER_PAGE
}) => {
  const [pages, setPages] = useState()
  const from = (page - 1) * perPage + 1
  const perCurrentPage = page * perPage
  const to = perCurrentPage < totalCount ? perCurrentPage : totalCount

  useEffect(() => {
    if (totalCount === 0 && page > 1) {
      setPage(page - 1)
    }
    setPages(Math.ceil(totalCount / perPage))
  }, [totalCount, page])

  return (
    totalCount > perPage && (
      <HStack spacing={1} alignSelf="end">
        <Text size="xs" color="gray.500">
          {from} - {to} of {totalCount}
        </Text>
        <IconButton
          variant="icon"
          icon={<ArrowBackDouble />}
          onClick={() => setPage(1)}
          isDisabled={page === 1}
        />
        <IconButton
          variant="icon"
          icon={<ArrowBack />}
          onClick={() => setPage(page - 1)}
          isDisabled={page === 1}
        />
        <IconButton
          variant="icon"
          icon={<ArrowForward />}
          onClick={() => setPage(page + 1)}
          isDisabled={page === pages}
        />
        <IconButton
          variant="icon"
          icon={<ArrowForwardDouble />}
          onClick={() => setPage(pages)}
          isDisabled={page === pages}
        />
      </HStack>
    )
  )
}
