import {
  Button,
  Flex,
  Spacer,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { Card } from '../../../../../shared/Cards'
import { DeleteModal } from '../modals'

const Delete = ({ project }) => {
  const disclosure = useDisclosure()

  return (
    <Card as={Flex}>
      <Stack spacing={1}>
        <Text size="xl">Delete Project</Text>
        <Text size="md" fontWeight="normal" color="gray.500">
          To delete a Project, you must delete all resources within it.
        </Text>
      </Stack>
      <Spacer />
      <Button w={40} colorScheme="red" onClick={disclosure.onOpen}>
        Delete Project
      </Button>
      <DeleteModal disclosure={disclosure} projectId={project.id} />
    </Card>
  )
}

export default Delete
