import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react'
import PaymentForm from '../../../../shared/payment-form'

export const AddMethodModal = ({ disclosure }) => {
  const { isOpen, onClose } = disclosure
  const successLink = window.location.href

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Heading size="xl" mb={8} textAlign="center">
            Add payment method
          </Heading>
          <PaymentForm successLink={successLink} showDefaultOption />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
