import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import { AccountSecurity, OrgSecurity } from '../../../../../api/security'
import { toastMessages } from '../../../../../constants/toast-messages'
import { useToast } from '../../../../shared/Toast'
import { useIsMounted } from '../../../../shared/hooks/useIsMounted'

export const DeleteModal = ({ token, disclosure }) => {
  const { orgId, projectId } = useParams()
  const { isOpen, onClose } = disclosure
  const [isSubmitting, setIsSubmitting] = useState()
  const isMounted = useIsMounted()
  const toast = useToast()

  const handleSubmit = useCallback(async () => {
    const apiTokenId = token.accessKey
    setIsSubmitting(true)
    try {
      if (orgId) {
        await OrgSecurity.deleteAPIToken({
          orgId,
          projectId,
          apiTokenId
        })
        await mutate((key) =>
          key.startsWith(
            `/organizations/${orgId}/projects/${projectId}/api-keys?`
          )
        )
      } else {
        await AccountSecurity.deleteAPIToken({ apiTokenId })
        await mutate((key) => key.startsWith(`/api-keys?`))
      }
      toast({
        status: 'success',
        message: toastMessages.APITokenDeleteSuccess
      })
    } catch {
      toast({
        status: 'error',
        message: toastMessages.APITokenDeleteError
      })
    } finally {
      if (isMounted()) {
        setIsSubmitting(false)
        onClose()
      }
    }
  }, [token])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={8}>
            <Heading size="xl" textAlign="center">
              Are you sure you want to delete API Token “{token.description}”?
            </Heading>
            <Text
              size="md"
              textAlign="center"
              color="red.500"
              fontWeight="normal"
            >
              This action will permanently delete the API Token and it cannot be
              recovered.
            </Text>
            <Stack spacing={4}>
              <Button
                type="submit"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                colorScheme="red"
                onClick={handleSubmit}
              >
                Delete API Token
              </Button>
              <Button
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isSubmitting}
                onClick={onClose}
                isFullWidth
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
