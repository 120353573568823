import { Grid } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { useIsVip } from '../../../../helpers/vip'
import SupportError from '../../../Errors/SupportError'
import { useOrgBilling } from '../../../shared/hooks'
import {
  useIsOwner,
  useOrgSettings,
  useOrganizationWithId
} from '../../../shared/hooks/organization'
import { useOrgQuota } from '../../../shared/hooks/quota'
import Description from './description'
import Form from './form'
import Loading from './loading'
import Table from './table'

const Organization = () => {
  const { orgId } = useParams()
  const { billing, isLoadingBilling, isErrorBilling } = useOrgBilling({ orgId })
  const { quota, isLoadingQuota, isErrorQuota } = useOrgQuota({ orgId })
  const { settings, isLoadingSettings, isErrorSettings } = useOrgSettings({
    orgId
  })
  const {
    organization,
    isLoading: isLoadingOrganization,
    isError: isErrorOrganization
  } = useOrganizationWithId(orgId)
  const isOwner = organization && useIsOwner(organization)
  const isFormDisabled = organization && !isOwner
  const isVip = useIsVip(settings)
  const isLoading =
    isLoadingBilling ||
    isLoadingQuota ||
    isLoadingSettings ||
    isLoadingOrganization
  const isError =
    isErrorBilling || isErrorQuota || isErrorSettings || isErrorOrganization

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <SupportError>Something went wrong loading the quota.</SupportError>
  ) : (
    <Grid templateColumns="0.5fr 1.5fr" gap={5}>
      <Description />
      {isFormDisabled ? (
        <Table quota={quota} />
      ) : (
        <Form quota={quota} billing={billing} isVip={isVip} />
      )}
    </Grid>
  )
}

export default Organization
