import {
  Avatar,
  Button,
  Divider,
  Grid,
  HStack,
  Spacer,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import accountLogo from '../../../../../shared/img/account-logo.svg'
import { Edit } from '../../../../../shared/Icons'
import { Card } from '../../../../../shared/Cards'
import { useOrganization } from '../../../../../shared/hooks/organization'
import { ProfileModal } from '../modals'
import CopyButton from '../../../../../shared/CopyButton'

export const ProfileCard = ({ isVisibleEdit }) => {
  const profileDisclosure = useDisclosure()
  const { organization } = useOrganization()

  return (
    <Card>
      <HStack mb={8}>
        <Avatar
          size="xl"
          name={organization.name}
          src={organization.picture || accountLogo}
          mr={5}
        />
        <Text size="3xl" noOfLines={1} maxWidth="600px" data-hj-suppress>
          {organization.name}
        </Text>
        {isVisibleEdit && (
          <>
            <Spacer />
            <Button
              w={48}
              colorScheme="offBlack"
              variant="outline"
              leftIcon={<Edit />}
              onClick={profileDisclosure.onOpen}
            >
              Edit Organization
            </Button>
          </>
        )}
        <ProfileModal disclosure={profileDisclosure} />
      </HStack>
      <Divider mb={8} />
      <Grid templateColumns="1fr 1fr" columnGap={16} rowGap={5}>
        <Stack spacing={0}>
          <Text>Website</Text>
          <Text color="gray.500">{organization.website || '-'}</Text>
        </Stack>
        <Stack spacing={0}>
          <Text>Linkedin profile</Text>
          <Text color="gray.500">{organization.linkedinProfile || '-'}</Text>
        </Stack>
        <Stack spacing={0}>
          <Text>Organization ID</Text>
          <CopyButton color="gray.500" value={organization.id} />
        </Stack>
      </Grid>
    </Card>
  )
}
