import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Flex,
  Heading,
  Text,
  Switch,
  Button,
  Skeleton
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { mutate } from 'swr'
import { toastMessages } from '../../../../../constants/toast-messages'
import { useToast } from '../../../../shared/Toast'
import { Card } from '../../../../shared/Cards'
import { Error } from '../../../../shared/Error'
import {
  useOrgBilling,
  useAccountBilling,
  useOrgBillingAutoRecharge,
  useAccountBillingAutoRecharge
} from '../../../../shared/hooks'
import { OrgBilling, AccountBilling } from '../../../../../api/billing'
import { BalanceField, MethodField } from './fields'

const validationSchema = yup.object().shape({
  isChecked: yup.boolean(),
  minBalance: yup.number().when('isChecked', {
    is: true,
    then: yup
      .number('The minimum balance must be a number')
      .min(1, 'The minimum balance must be greater than 0')
      .required('The minimum balance is required')
  }),
  topupAmount: yup.number().when('isChecked', {
    is: true,
    then: yup
      .number('The topup amount must be a number')
      .min(1, 'The topup amount must be greater than 0')
      .required('The topup amount is required')
  }),
  paymentMethod: yup.object().when('isChecked', {
    is: true,
    then: yup.object().required('The payment method is required')
  })
})

export const AutoRechargeModal = ({ disclosure }) => {
  const { isOpen, onClose } = disclosure
  const { orgId } = useParams()
  const toast = useToast()

  const { billing, isLoadingBilling, isErrorBilling } = orgId
    ? useOrgBilling({ orgId })
    : useAccountBilling()
  const methods = billing?.creditCards || []

  const { autoRecharge, isLoadingAutoRecharge, isErrorAutoRecharge } = orgId
    ? useOrgBillingAutoRecharge({ orgId, shouldFetch: isOpen })
    : useAccountBillingAutoRecharge({ shouldFetch: isOpen })

  const isLoading = isLoadingBilling || isLoadingAutoRecharge
  const isError = isErrorBilling || isErrorAutoRecharge

  const paymentMethod =
    methods?.find((method) => method.id === autoRecharge?.paymentMethod) ??
    methods[0]

  const initialValues = {
    isChecked: !!autoRecharge,
    minBalance: autoRecharge?.minBalance || 1,
    topupAmount: autoRecharge?.topupAmount || 1,
    paymentMethod
  }

  const onSubmit = async (values, { setSubmitting }) => {
    const { isChecked, minBalance, topupAmount, paymentMethod } = values
    const payload = {
      min_balance: minBalance,
      topup_amount: topupAmount,
      payment_method: paymentMethod.id
    }
    setSubmitting(true)

    try {
      if (orgId) {
        isChecked
          ? await OrgBilling.updateAutoRecharge({ orgId, payload })
          : await OrgBilling.deleteAutoRecharge({ orgId })

        await mutate(`/billing/auto-recharge?org=${orgId}`)
      } else {
        isChecked
          ? await AccountBilling.updateAutoRecharge({ payload })
          : await AccountBilling.deleteAutoRecharge()

        await mutate('/billing/auto-recharge')
      }

      toast({
        status: 'success',
        message: toastMessages.saved
      })
      onClose()
    } catch {
      toast({
        status: 'error',
        message: toastMessages.notSaved
      })
    } finally {
      setSubmitting(false)
    }
  }

  const isDisabled = (values) =>
    initialValues.isChecked === values.isChecked &&
    (!values.isChecked || initialValues.minBalance === values.minBalance) &&
    (!values.isChecked || initialValues.topupAmount === values.topupAmount) &&
    (!values.isChecked || initialValues.paymentMethod === values.paymentMethod)

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ values, handleChange, isSubmitting }) => (
              <Stack spacing={8} as={Form}>
                <Heading size="xl" textAlign="center">
                  Account Balance
                </Heading>
                {isLoading ? (
                  <Skeleton h="105px" />
                ) : isError ? (
                  <Error />
                ) : (
                  <Card as={Flex} gap={2} p={5}>
                    <Stack spacing={3}>
                      <Stack spacing={0}>
                        <Text fontWeight="semibold">Auto recharge</Text>
                        <Text color="gray.500">
                          We will recharge your Account balance using the
                          default payment method.
                        </Text>
                      </Stack>
                      {values.isChecked && (
                        <>
                          <BalanceField
                            name="minBalance"
                            label="When balance goes below the value:"
                          />
                          <BalanceField
                            name="topupAmount"
                            label="Bring my balance back up to:"
                          />
                          <MethodField
                            name="paymentMethod"
                            label="Using this payment method:"
                            methods={methods}
                          />
                        </>
                      )}
                    </Stack>
                    <Switch
                      name="isChecked"
                      isChecked={values.isChecked}
                      onChange={handleChange}
                    />
                  </Card>
                )}
                <Stack spacing={4}>
                  <Button
                    type="submit"
                    isLoading={isSubmitting}
                    isDisabled={isDisabled(values)}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outline"
                    colorScheme="offBlack"
                    isDisabled={isSubmitting}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Stack>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
