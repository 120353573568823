import {
  Box,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  useRadio,
  useRadioGroup
} from '@chakra-ui/react'
import { Radio, RadioChecked } from '../../../shared/Icons'
import { getCountryByName } from '../../../shared/PhoneInput/country'

const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0

const Description = ({ children }) => (
  <Text fontWeight={600} fontSize="12px" color="gray.900">
    {children}
  </Text>
)

const RadioCard = ({ option, ...props }) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)
  const { country, gpuCount, ram, cpuCount, storage } = option
  const { flag } = isMac ? getCountryByName(country) : ''

  return (
    <Box as="label" mb={0} position="relative">
      <input {...getInputProps()} />
      <Stack
        {...getCheckboxProps()}
        px={1.5}
        py={2}
        h="full"
        spacing={2}
        align="flex-start"
        border="1px"
        direction="row"
        borderColor={props.isChecked ? 'blue.500' : 'gray.300'}
        borderRadius="10px"
        cursor="pointer"
        _hover={{
          borderColor: 'blue.500'
        }}
        _focus={{
          boxShadow: 'outline'
        }}
        _disabled={{
          borderColor: 'gray.500',
          opacity: 0.4,
          cursor: 'not-allowed'
        }}
      >
        {props.isChecked ? <RadioChecked mt={1} /> : <Radio mt={1} />}
        <HStack spacing={3} width="calc(100% - 32px)">
          <Stack spacing={1} flexGrow={1}>
            <HStack spacing={1}>
              <Description>GPUs: {gpuCount}x</Description>
              <Description>|</Description>
              <Description>CPUs: {cpuCount}x</Description>
              <Description>|</Description>
              <Description>RAM: {ram} GiB</Description>
              <Description>|</Description>
              <Description>Storage: {storage} GiB</Description>
            </HStack>
            <Text fontSize="12px" color="gray.500">
              {country} {flag}
            </Text>
          </Stack>
        </HStack>
      </Stack>
    </Box>
  )
}

export const RadioCards = ({ activeOption, options, onChange }) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    value: activeOption,
    defaultValue: activeOption,
    onChange: (value) => onChange(value)
  })

  return (
    <SimpleGrid columns={2} gap={3} {...getRootProps()}>
      {options.map((option) => (
        <RadioCard
          option={option}
          key={option.value}
          {...getRadioProps({ value: option.value })}
        />
      ))}
    </SimpleGrid>
  )
}
