import { Button, Grid, Link, Stack, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import {
  AccountObjectStorage,
  OrgObjectStorage
} from '../../../api/objectStorage'
import { toastMessages } from '../../../constants/toast-messages'
import { externalLinks } from '../../../helpers/links'
import { useToast } from '../../shared/Toast'
import { useAccountFF, useOrgFF } from '../../shared/hooks/featureFlag'

const TICKET_PAGE_URL = process.env.REACT_APP_TICKET_PAGE_URL

const ObjectStorage = () => {
  const { orgId, projectId } = useParams()
  const [submitting, setSubmitting] = useState(false)
  const toast = useToast()
  const { featureFlag, isLoading, isError } = orgId
    ? useOrgFF({ orgId })
    : useAccountFF({})

  const isObjectStorageEnabled =
    featureFlag?.enableObjectStorage && !isLoading && !isError

  const handleOnClick = async () => {
    setSubmitting(true)

    try {
      if (orgId) {
        await OrgObjectStorage.enable({
          orgId,
          projectId
        })
        await mutate(`/projects/${projectId}/api-access/check?org=${orgId}`)
      } else {
        await AccountObjectStorage.enable()
        await mutate('/api-access/check')
      }
      toast({
        status: 'success',
        message: toastMessages.objectStorageEnableSuccess
      })
    } catch {
      setSubmitting(false)
      toast({
        status: 'error',
        message: toastMessages.objectStorageEnableError
      })
    }
  }

  return (
    <Grid
      templateColumns="1fr 160px"
      columnGap={8}
      p={10}
      borderRadius="10px"
      bg="blue.100"
    >
      <Stack spacing={5}>
        <Text size="xl">Enabling Object Storage</Text>
        <Text size="md" fontWeight={400}>
          {isObjectStorageEnabled ? (
            <span>
              Enable Object Storage to streamline your workflow, ensuring that
              any new data seamlessly integrates with inspection datasets and is
              automatically stored in the object storage system. This enhances
              efficiency and simplifies the process of managing and accessing
              your inspection data sets.{' '}
              <Link color="black" href={externalLinks.objectStorage} isExternal>
                Object Storage Quickstart
              </Link>
            </span>
          ) : (
            <span>
              <Link color="black" href={TICKET_PAGE_URL} isExternal>
                Contact our support team
              </Link>{' '}
              to get access to and streamline your workflow with Object Storage.
            </span>
          )}
        </Text>
      </Stack>
      {isObjectStorageEnabled && (
        <Button isLoading={submitting} onClick={handleOnClick}>
          Enable service
        </Button>
      )}
    </Grid>
  )
}

export default ObjectStorage
