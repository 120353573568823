import {
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { SkeletonTable } from '../../../../shared/Skeletons'
import {
  INITIAL_PAGE,
  TableCell,
  TableError,
  TablePagination,
  PER_PAGE_5
} from '../../../../shared/Table'
import { useAccountSSHKeys, useOrgSSHKeys } from '../../../../shared/hooks'
import TableRow from './table-row'

const List = ({ hasPermission }) => {
  const { orgId, projectId } = useParams()
  const [page, setPage] = useState(INITIAL_PAGE)

  const { sshKeys, pagination, isLoadingSSHKeys, isErrorSSHKeys } =
    orgId && projectId
      ? useOrgSSHKeys({ orgId, projectId, page })
      : useAccountSSHKeys({ page })
  const isTableEmpty = sshKeys?.length === 0

  return (
    <Stack spacing={8}>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th w="15%">Name</Th>
              <Th w="25%">Fingerprint</Th>
              <Th w="15%">Type</Th>
              <Th w="20%">Key ID</Th>
              <Th w="20%">Created</Th>
              <Th w="5%" />
            </Tr>
          </Thead>
          {isLoadingSSHKeys && <SkeletonTable rows={5} columns={5} />}
          {isErrorSSHKeys ? (
            <TableError>Something went wrong loading the SSH Keys.</TableError>
          ) : (
            <Tbody>
              {isTableEmpty ? (
                <TableCell>There are no SSH Keys yet.</TableCell>
              ) : (
                sshKeys?.map((sshKey) => (
                  <TableRow
                    key={sshKey.id}
                    sshKey={sshKey}
                    hasPermission={hasPermission}
                  />
                ))
              )}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      {!isErrorSSHKeys && (
        <TablePagination
          page={page}
          setPage={setPage}
          perPage={PER_PAGE_5}
          totalCount={pagination?.totalCount}
        />
      )}
    </Stack>
  )
}

export default List
