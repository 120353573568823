import { useSelector } from 'react-redux'
import useSWR from 'swr'
import { Account } from '../../../../api/account'
import { serialize } from '../../../../helpers/serializers'
import { swrOptions } from '../../../../constants/swr-options'

export function useIsSuspended() {
  const { account } = useAccount()
  const suspendedAt = account?.suspendedAt

  return !!suspendedAt
}

export function useAccount() {
  const user = useSelector((state) => state.oidc.user)
  const key = user ? '/account' : null
  const fetcher = () => Account.fetch()

  const { data, isValidating, isLoading, error } = useSWR(key, fetcher, {
    revalidateOnFocus: false
  })

  return {
    account: serialize(data),
    isValidatingAccount: isValidating,
    isLoadingAccount: isLoading,
    isErrorAccount: Boolean(error)
  }
}

export function useAccountSettings() {
  const { data, error } = useSWR(
    '/settings',
    () => Account.fetchSettings(),
    swrOptions
  )
  return {
    settings: serialize(data),
    isErrorSettings: error,
    isLoadingSettings: !data && !error
  }
}

export function useAccountCheckIn() {
  const user = useSelector((state) => state.oidc.user)
  const key = user ? '/checkin' : null
  const fetcher = () => Account.fetchCheckIn()

  const { data, isLoading, error } = useSWR(key, fetcher)

  return {
    data: serialize(data),
    isLoadingAccountCheckIn: isLoading,
    isErrorAccountCheckIn: Boolean(error)
  }
}

export const useAccountMigration = () => {
  const key = '/migration'
  const fetcher = () => Account.fetchMigration()

  const { data, isLoading, error } = useSWR(key, fetcher)

  return {
    migration: serialize(data),
    isLoadingMigration: isLoading,
    isErrorMigration: Boolean(error)
  }
}
