import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Stack, Heading, Skeleton, Text, Link } from '@chakra-ui/react'
import { Error } from '../../../../shared/Error'
import { useFormikContext } from 'formik'
import { externalLinks } from '../../../../../helpers/links'
import { AuthenticationCards } from '../RadioCards'
import {
  useOrgSSHKeys,
  useAccountSSHKeys,
  useOrgInstanceInfo,
  useAccountInstanceInfo
} from '../../../../shared/hooks'
import {
  SSH_KEYS,
  PASSWORD,
  methodsName
} from '../../../../../constants/create-instance'
import SSHKeys from './SSHKeys'
import Password from './Password'

const authentications = [
  {
    slug: SSH_KEYS,
    name: methodsName[SSH_KEYS],
    description: 'Recommended and secure.'
  },
  {
    slug: PASSWORD,
    name: methodsName[PASSWORD],
    description: 'Less secure than SSH key.'
  }
]

const Authentication = () => {
  const { values, setValues } = useFormikContext()
  const isEmpty = values.authentication.isEmpty
  const isSSHKeys = values.authentication.method === SSH_KEYS
  const { orgId, projectId } = useParams()
  const { sshKeys, isValidatingSSHKeys, isLoadingSSHKeys, isErrorSSHKeys } =
    orgId && projectId
      ? useOrgSSHKeys({ orgId, projectId, perPage: 1000 })
      : useAccountSSHKeys({ perPage: 1000 })
  const { isValidatingInfo, isErrorInfo } =
    orgId && projectId
      ? useOrgInstanceInfo({ orgId, projectId })
      : useAccountInstanceInfo({})

  const isError = isErrorSSHKeys || isErrorInfo

  const handleAuthChange = (method) => {
    setValues({
      ...values,
      authentication: { ...values.authentication, method }
    })
  }

  useEffect(() => {
    if (sshKeys && isEmpty && !isValidatingSSHKeys && !isErrorSSHKeys) {
      setValues((prev) => ({
        ...prev,
        authentication: {
          ...prev.authentication,
          sshKeys,
          isEmpty: false
        }
      }))
    }
  }, [isValidatingSSHKeys])

  return (
    <Stack spacing={8}>
      <Stack spacing={1}>
        <Heading size="xl">Authentication</Heading>
        <Text color="gray.500" size="sm">
          We recommend to always{' '}
          <Link href={externalLinks.sshKeys} isExternal>
            use a SSH key
          </Link>{' '}
          pair as an authentication method.
          <br />
          Using a password is less secure.
        </Text>
      </Stack>
      {isValidatingInfo || isLoadingSSHKeys ? (
        <Skeleton h="160px" />
      ) : isError ? (
        <Error />
      ) : (
        <Stack spacing={5}>
          <AuthenticationCards
            items={authentications}
            value={values.authentication.method}
            onChange={handleAuthChange}
          />
          {isSSHKeys ? (
            <SSHKeys name="authentication.sshKeys" sshKeys={sshKeys || []} />
          ) : (
            <Password name="authentication.password" />
          )}
        </Stack>
      )}
    </Stack>
  )
}

export default Authentication
