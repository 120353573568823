const APITokenTypesName = {
  COMPUTE: 'Compute resources',
  OBJECT_STORAGE: 'Object Storage'
}

export const APITokenTypes = {
  compute: 'COMPUTE',
  objectStorage: 'OBJECT_STORAGE'
}

export const getTokenType = (type) => APITokenTypesName[type]
