import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { mutate } from 'swr'
import * as yup from 'yup'
import { Organization } from '../../../../../../api/organizations'
import routes from '../../../../../../constants/routes'
import { toastMessages } from '../../../../../../constants/toast-messages'
import { idToDeleteUpdated } from '../../../../../../slices/ui/organizations'
import Asterisk from '../../../../../shared/Asterisk'
import { useToast } from '../../../../../shared/Toast'
import { useIsMounted } from '../../../../../shared/hooks/useIsMounted'
import { useOrgParam } from '../../../../../shared/hooks/useOrgParam'

export const DeleteModal = () => {
  const dispatch = useDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const history = useHistory()
  const isMounted = useIsMounted()
  const param = useOrgParam()
  const orgId = useSelector((state) => state.ui.organizations.idToDelete)

  const schema = yup.object().shape({
    confirmation: yup
      .string()
      .oneOf(['DELETE'], 'The value must be DELETE')
      .required('Please type DELETE to confirm')
  })

  useEffect(() => {
    if (orgId !== null) {
      onOpen()
    } else {
      onClose()
    }
  }, [orgId])

  const handleClose = useCallback(() => {
    onClose()
    dispatch(idToDeleteUpdated(null))
  }, [onClose, dispatch])

  const handleSubmit = useCallback(
    async (_, { setSubmitting }) => {
      setSubmitting(true)
      try {
        const response = await Organization.deleteById(orgId)
        if (response.ok) {
          await mutate((key) => key.startsWith(`/organizations?`))
          toast({
            status: 'success',
            message: toastMessages.organizationDeleteSuccess
          })
          handleClose()
          if (param === orgId) {
            history.push(routes.dashboard.organizations.index)
          }
        }
      } catch {
        toast({
          status: 'error',
          message: toastMessages.organizationDeleteError
        })
      } finally {
        if (isMounted()) {
          setSubmitting(false)
        }
      }
    },
    [orgId]
  )

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Heading size="xl" mb={4} textAlign="center">
            Delete Organization
          </Heading>
          <Text
            mb={8}
            size="md"
            textAlign="center"
            color="red.500"
            fontWeight="normal"
          >
            Everything associated with the Organization (including all
            instances, backups, and other resources) will be permanently
            deleted.
          </Text>
          <Formik
            initialValues={{ confirmation: '' }}
            validationSchema={schema}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ handleSubmit, errors, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <Stack spacing={8}>
                  <FormControl isInvalid={errors.confirmation}>
                    <FormLabel>
                      <Asterisk />
                      Type DELETE to confirm
                    </FormLabel>
                    <Field as={Input} name="confirmation" autoFocus={true} />
                    <FormErrorMessage>{errors.confirmation}</FormErrorMessage>
                  </FormControl>
                  <Stack spacing={4}>
                    <Button
                      type="submit"
                      isDisabled={isSubmitting}
                      isLoading={isSubmitting}
                      colorScheme="red"
                    >
                      Delete Organization
                    </Button>
                    <Button
                      colorScheme="offBlack"
                      variant="outline"
                      isDisabled={isSubmitting}
                      onClick={handleClose}
                      isFullWidth
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
