import { Box } from '@chakra-ui/react'

const Badge = ({ unreadCount }) => (
  <Box
    pos="absolute"
    top={1}
    left={4}
    minW={unreadCount ? 3.5 : 2.5}
    minH={unreadCount ? 3.5 : 2.5}
    borderRadius="50px"
    bg="red.500"
    fontSize="10px"
    fontWeight="bold"
    textAlign="center"
    color="white"
  >
    {unreadCount || ''}
  </Box>
)

export default Badge
