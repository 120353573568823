import { Button, Flex, Heading, Stack, useDisclosure } from '@chakra-ui/react'
import { Card } from '../../../shared/Cards'
import { Plus } from '../../../shared/Icons'
import { CreateModal } from './modals'
import List from './list'

const APITokens = ({ hasPermission }) => {
  const createDisclosure = useDisclosure()

  return (
    <Card as={Stack} spacing={8}>
      <Flex justifyContent="space-between">
        <Heading size="xl">API Tokens</Heading>
        {hasPermission && (
          <>
            <Button
              colorScheme="offBlack"
              variant="outline"
              leftIcon={<Plus color="black" />}
              onClick={createDisclosure.onOpen}
            >
              Generate new API Token
            </Button>
            <CreateModal disclosure={createDisclosure} />
          </>
        )}
      </Flex>
      <List hasPermission={hasPermission} />
    </Card>
  )
}

export default APITokens
