import { Box, Grid, HStack, Stack, Text, useRadio } from '@chakra-ui/react'
import {
  caclMonthlyPrice,
  calcDiscount,
  calcPrices,
  createDate,
  createNextRenewalDate,
  slugs
} from '../../../../../helpers/billing'
import { formatCurrency } from '../../../../../helpers/currency'
import { Radio, RadioChecked } from '../../../../shared/Icons'

const Existing = ({ sub }) => (
  <>
    <Text color="gray.500">Renews automatically</Text>
    <Text fontWeight="600" textAlign="end">
      {createDate(sub.renewsAt)}
    </Text>
  </>
)

const New = ({ sub }) => {
  const { amount, intervalCount, discount, credits } = sub
  const discountValue = calcDiscount(amount, discount, credits)
  const renewalDate = createNextRenewalDate(intervalCount)
  const { totalPrice, creditsValue } = calcPrices(
    amount,
    discountValue,
    credits
  )

  return (
    <>
      <Text color="gray.500">Price</Text>
      <Text fontWeight="600" textAlign="end">
        {formatCurrency(amount)}
      </Text>
      {Boolean(discountValue) && (
        <>
          <Text color="gray.500">Discount (account level)</Text>
          <Text fontWeight="600" textAlign="end">
            -{formatCurrency(discountValue)}
          </Text>
        </>
      )}
      {Boolean(creditsValue) && (
        <>
          <Text color="gray.500">Credits</Text>
          <Text fontWeight="600" textAlign="end">
            {formatCurrency(creditsValue)}
          </Text>
        </>
      )}
      <Text color="gray.500">Renews automatically</Text>
      <Text fontWeight="600" textAlign="end">
        {renewalDate}
      </Text>
      <Text color="gray.500">Total cost (excl. VAT)</Text>
      <Text fontWeight="600" textAlign="end" color="blue.500">
        {formatCurrency(totalPrice)}
      </Text>
    </>
  )
}

const OnDemand = ({ sub }) => {
  const { amount, discount } = sub
  const discountValue = calcDiscount(amount, discount)
  const { totalPrice } = calcPrices(amount, discountValue)

  return (
    <>
      <Text color="gray.500">Price</Text>
      <Text fontWeight="600" textAlign="end">
        {formatCurrency(amount)}
      </Text>
      {Boolean(discountValue) && (
        <>
          <Text color="gray.500">Discount (account level)</Text>
          <Text fontWeight="600" textAlign="end">
            -{formatCurrency(discountValue)}
          </Text>
        </>
      )}
      <Text color="gray.500">Total cost (excl. VAT)</Text>
      <Text fontWeight="600" textAlign="end" color="blue.500">
        {formatCurrency(totalPrice)}
      </Text>
    </>
  )
}

export const RadioSubscription = ({ ...props }) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)
  const { isChecked, sub } = props
  const { name, amount, unit, intervalCount, renewsAt, discount, credits } = sub
  const color = isChecked ? 'blue.500' : 'black'
  const isExisting = isChecked && renewsAt
  const isOnDemand = isChecked && sub.id.startsWith(slugs.onDemand)
  const isNew = isChecked && sub.id.startsWith('prepaid-')
  const monthlyPrice = caclMonthlyPrice(amount, intervalCount)

  return (
    <Box as="label" mb={0}>
      <input {...getInputProps()} />
      <HStack
        {...getCheckboxProps()}
        h="full"
        spacing={3}
        align="flex-start"
        cursor="pointer"
        _hover={{
          borderColor: 'blue.500'
        }}
        _focus={{
          boxShadow: 'none'
        }}
        _disabled={{
          borderColor: 'gray.500',
          opacity: 0.4,
          cursor: 'not-allowed'
        }}
      >
        {isChecked ? <RadioChecked mt={1} /> : <Radio mt={1} />}
        <Stack spacing={0} flexGrow={1}>
          <Grid templateColumns="2fr 1fr" columnGap={3}>
            <Text color={color}>{name}</Text>
            <Text color={color} fontWeight="600" textAlign="end">
              {formatCurrency(monthlyPrice)}/{unit}
            </Text>
            {isOnDemand && <OnDemand sub={{ ...sub, discount }} />}
            {isNew && <New sub={{ ...sub, discount, credits }} />}
            {isExisting && <Existing sub={sub} />}
          </Grid>
        </Stack>
      </HStack>
    </Box>
  )
}
