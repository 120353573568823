import { Heading, Skeleton, Stack, Text } from '@chakra-ui/react'
import { useField, useFormikContext } from 'formik'
import { RadioCards } from './radio-cards'

const NoData = () => (
  <Text color="gray.500">There are no Configurations yet.</Text>
)

const Configuration = ({ isLoading, noData }) => {
  const { values } = useFormikContext()
  const [field, , helpers] = useField('configuration.selected')
  const options = values?.configuration?.options
  const activeOption = field.value.value

  const onChange = (value) => {
    const selected = options.find((option) => option.value === value)
    helpers.setValue(selected)
  }

  return (
    <Stack spacing={8}>
      <Heading size="xl">Select Instance Configuration</Heading>
      {isLoading ? (
        <Skeleton h="120px" />
      ) : noData ? (
        <NoData />
      ) : (
        <RadioCards
          {...field}
          activeOption={activeOption}
          options={options}
          onChange={onChange}
        />
      )}
    </Stack>
  )
}

export default Configuration
