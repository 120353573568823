import { Skeleton, Tbody, Td, Tr } from '@chakra-ui/react'

export const SkeletonRowTable = ({ columns }) =>
  Array.from({ length: columns }).map((_, index) => (
    <Td key={index}>
      <Skeleton my={1} h="13px" w="70%" />
    </Td>
  ))

export const SkeletonTable = ({ rows, columns, hasAction = true }) => (
  <Tbody>
    {Array.from({ length: rows }).map((_, index) => (
      <Tr key={index}>
        <SkeletonRowTable columns={columns} />
        {hasAction && <Td />}
      </Tr>
    ))}
  </Tbody>
)
