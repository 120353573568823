import { Grid, Heading, Skeleton, Stack } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { mutate } from 'swr'
import * as yup from 'yup'
import { AccountFilesystems, OrgFilesystems } from '../../../../api/filesystems'
import { filesystemRegions } from '../../../../constants/regions'
import routes from '../../../../constants/routes'
import { toastMessages } from '../../../../constants/toast-messages'
import { MIN_SIZE, calcMaxSize } from '../../../../helpers/quota'
import { withOrgPathFallback } from '../../../../router'
import { apiErrorHandler } from '../../../shared/ApiErrorHandler'
import { InputField, RegionField } from '../../../shared/Fields'
import { SizeField } from '../../../shared/Fields/SizeField'
import { ReturnLink } from '../../../shared/Links'
import { enableRegions } from '../../../shared/Region'
import { useToast } from '../../../shared/Toast'
import { useAccountQuota, useOrgQuota } from '../../../shared/hooks/quota'
import { useIsMounted } from '../../../shared/hooks/useIsMounted'
import { TypeField } from './fields'
import Summary from './summary'

const enabledRegions =
  process.env.REACT_APP_FILESYSTEM_ENABLED_REGIONS?.split(',') || []
const regions = enableRegions(filesystemRegions, enabledRegions)

const DEFAULT_REGION = enabledRegions[0]
const DEFAULT_TYPE = 'vast'

const types = [
  {
    slug: DEFAULT_TYPE,
    regions: enabledRegions,
    description: { name: 'VAST' },
    isEnabled: true
  }
]

const initialValues = {
  region: DEFAULT_REGION,
  type: DEFAULT_TYPE,
  name: '',
  description: '',
  size: MIN_SIZE
}

const FilesystemsCreate = () => {
  const { orgId, projectId } = useParams()
  const history = useHistory()
  const toast = useToast()
  const isMounted = useIsMounted()
  const { quota, isLoadingQuota } = orgId
    ? useOrgQuota({ orgId })
    : useAccountQuota()

  const validationSchema = yup.object().shape({
    name: yup.string().required('Name is required.'),
    description: yup.string(),
    size: yup
      .number()
      .required('Size is required.')
      .typeError('Invalid value.')
      .test({
        name: 'limit',
        message: 'Size must be within the allowed limits.',
        test: (value, context) =>
          value <= calcMaxSize({ quota, type: context.parent.type }) &&
          value > 0
      })
  })

  const handleSubmit = useCallback(
    async (
      { region, type, name, description, size },
      { setSubmitting, resetForm }
    ) => {
      setSubmitting(true)
      try {
        const payload = {}
        if (region) {
          payload.region = region
        }
        if (type) {
          payload.type = type
        }
        if (name) {
          payload.name = name
        }
        if (description) {
          payload.description = description
        }
        if (size) {
          payload.size = parseInt(size)
        }
        if (orgId) {
          await OrgFilesystems.create({
            projectId,
            orgId,
            payload
          })
          await mutate((key) =>
            key.startsWith(`/projects/${projectId}/filesystems?`)
          )
        } else {
          await AccountFilesystems.create({ payload })
          await mutate((key) => key.startsWith(`/filesystems?`))
        }
        toast({
          status: 'success',
          message: toastMessages.filesystemCreateSuccess
        })
        resetForm()
        history.push(withOrgPathFallback(routes.dashboard.filesystems.index))
      } catch (error) {
        const message = await apiErrorHandler(error)
        toast({
          status: 'error',
          message: message || toastMessages.filesystemCreateError
        })
      } finally {
        if (isMounted()) {
          setSubmitting(false)
        }
      }
    },
    [orgId, projectId]
  )

  return (
    <Stack spacing={8}>
      <Stack spacing={0}>
        <ReturnLink
          to={withOrgPathFallback(routes.dashboard.filesystems.index)}
        >
          Filesystems
        </ReturnLink>
        <Heading as="h1" size="4xl">
          Create new Filesystem
        </Heading>
      </Stack>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialTouched={{ size: true }}
      >
        <Grid as={Form} templateColumns="2fr 1fr" gap={8}>
          <Stack spacing={16}>
            <RegionField name="region" regions={regions} />
            <TypeField name="type" types={types} />
            <Stack spacing={8}>
              <Heading size="xl">Configuration</Heading>
              {isLoadingQuota ? (
                <Skeleton h="175px" />
              ) : (
                <Grid templateColumns="1fr 1fr" rowGap={8} columnGap={5}>
                  <InputField
                    label="Name"
                    name="name"
                    placeholder="Enter filesystem name"
                    isRequired
                  />
                  <InputField
                    label="Description"
                    name="description"
                    placeholder="Enter filesystem description"
                  />
                  <SizeField />
                </Grid>
              )}
            </Stack>
          </Stack>
          <Summary />
        </Grid>
      </Formik>
    </Stack>
  )
}

export default FilesystemsCreate
