import { Stack, Text } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { calcResourcesPrices } from '../../../../../helpers/billing'
import { formatCurrency } from '../../../../../helpers/currency'
import { useConfigs } from '../../../../shared/hooks'
import { Card } from '../../../../shared/Cards'
import Price from '../../../../shared/Price'

const PriceCard = () => {
  const { values } = useFormikContext()
  const { configs, isLoadingConfigs, isErrorConfigs } = useConfigs({})

  if (isLoadingConfigs || isErrorConfigs) {
    return null
  }

  const { price, discount } = calcResourcesPrices({
    types: configs?.storageTypes,
    typeSlug: 'object_storage',
    configSlug: `object_storage`,
    storage: values.size
  })

  if (price === 0) {
    return null
  }

  const items = [
    { label: 'Price', value: `${formatCurrency(price)}/GiB/month` },
    ...(discount ? [{ label: 'Discount', value: `${discount}% off` }] : [])
  ]

  return (
    <Card as={Stack} spacing={8} p={7}>
      <Text size="xs" color="gray.500" textAlign="justify">
        Billed on-demand once the bucket is created and until the bucket is
        deleted. Charged hourly.
      </Text>
      <Price items={items} />
    </Card>
  )
}

export default PriceCard
