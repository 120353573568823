import { INITIAL_PAGE, PER_PAGE } from '../components/shared/Table'
import { serialize } from '../helpers/serializers'
import { COMPUTE_ENDPOINT, apiRequest } from './index'

export class OrgFloatingIPs {
  static fetchAll = async ({
    orgId,
    projectId,
    page = INITIAL_PAGE,
    perPage = PER_PAGE,
    region
  }) => {
    const regionArg = region ? `&region=${region}` : ''
    const floatingIps = await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/floating-ips?page=${page}&per_page=${perPage}&org=${orgId}${regionArg}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(floatingIps)
  }

  static create = async ({ orgId, projectId, payload }) => {
    const floatingIP = await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/floating-ips?&org=${orgId}`,
      {
        method: 'POST',
        body: JSON.stringify(payload)
      },
      true
    )
    return serialize(floatingIP)
  }

  static deleteById = async ({ orgId, projectId, floatingIPId }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/projects/${projectId}/floating-ips/${floatingIPId}?org=${orgId}`,
      {
        method: 'DELETE'
      },
      true
    )
}

export class AccountFloatingIPs {
  static fetchAll = async ({
    page = INITIAL_PAGE,
    perPage = PER_PAGE,
    region
  }) => {
    const regionArg = region ? `&region=${region}` : ''
    const floatingIps = await apiRequest(
      `${COMPUTE_ENDPOINT}/floating-ips?page=${page}&per_page=${perPage}${regionArg}`,
      {
        method: 'GET'
      },
      true
    )
    return serialize(floatingIps)
  }

  static create = async ({ payload }) => {
    const floatingIP = await apiRequest(
      `${COMPUTE_ENDPOINT}/floating-ips`,
      {
        method: 'POST',
        body: JSON.stringify(payload)
      },
      true
    )
    return serialize(floatingIP)
  }

  static deleteById = async ({ floatingIPId }) =>
    await apiRequest(
      `${COMPUTE_ENDPOINT}/floating-ips/${floatingIPId}`,
      {
        method: 'DELETE'
      },
      true
    )
}
