import {
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Link,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  Text
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import { Link as ReactLink, useParams } from 'react-router-dom'
import { mutate } from 'swr'
import * as yup from 'yup'
import { AccountInstances, OrgInstances } from '../../../../api/instances'
import routes from '../../../../constants/routes'
import { toastMessages } from '../../../../constants/toast-messages'
import { withOrgPathFallback } from '../../../../router'
import { WarningError } from '../../../Errors'
import { apiErrorHandler } from '../../../shared/ApiErrorHandler'
import Asterisk from '../../../shared/Asterisk'
import { Close } from '../../../shared/Icons'
import { useToast } from '../../../shared/Toast'
import {
  useAccountSecurityGroups,
  useOrgSecurityGroups
} from '../../../shared/hooks'

const validationSchema = yup.object().shape({
  availableSecurityGroups: yup.array(),
  selectedSecurityGroups: yup
    .array()
    .min(1, 'At least one Security Group should be selected.')
})

export const AssignModal = ({ disclosure, instance }) => {
  const { isOpen, onClose } = disclosure
  const { region, securityGroups: instanceSecurityGroups } = instance
  const { orgId, projectId, instanceId } = useParams()
  const toast = useToast()

  const { securityGroups, isLoading, isError } =
    orgId && projectId
      ? useOrgSecurityGroups({
          orgId,
          projectId,
          region,
          perPage: 1000
        })
      : useAccountSecurityGroups({
          region,
          perPage: 1000
        })

  const assignedIds = instanceSecurityGroups?.map(({ id }) => id)
  const filteredSecurityGroups = securityGroups?.securityGroups.filter(
    (securityGroup) => !assignedIds.includes(securityGroup.id)
  )

  const initialValues = {
    availableSecurityGroups: filteredSecurityGroups || [],
    selectedSecurityGroups: []
  }

  const onSubmit = async ({ selectedSecurityGroups }, { setSubmitting }) => {
    setSubmitting(true)
    try {
      if (orgId) {
        await OrgInstances.updateById({
          orgId,
          projectId,
          instanceId,
          payload: {
            security_groups: [
              ...instanceSecurityGroups.map((sg) => sg.id),
              ...selectedSecurityGroups.map((sg) => sg.id)
            ]
          }
        })

        await mutate(
          `/projects/${projectId}/instances/${instanceId}?org=${orgId}`
        )
      } else {
        await AccountInstances.updateById({
          orgId,
          projectId,
          instanceId,
          payload: {
            security_groups: [
              ...instanceSecurityGroups.map((sg) => sg.id),
              ...selectedSecurityGroups.map((sg) => sg.id)
            ]
          }
        })
        await mutate(`/instances/${instanceId}`)
      }
      toast({
        status: 'success',
        message: toastMessages.saved
      })
      onClose()
    } catch (error) {
      const message = await apiErrorHandler(error)
      toast({
        status: 'error',
        message
      })
    } finally {
      setSubmitting(false)
    }
  }

  const { values, setFieldValue, errors, handleSubmit, isSubmitting } =
    useFormik({
      validationSchema,
      initialValues,
      onSubmit,
      enableReinitialize: true
    })

  const noAvailableSecurityGroups = values.availableSecurityGroups?.length === 0
  const noSelectedSecurityGroups = values.selectedSecurityGroups?.length === 0

  const handleTransfer = (id, source, destination) => {
    const selected = values[source].find(
      (securityGroup) => securityGroup.id === id
    )

    const newSources = values[source].filter(
      (securityGroup) => securityGroup.id !== id
    )
    const newDestinations = [...values[destination], selected]

    setFieldValue(source, newSources)
    setFieldValue(destination, newDestinations)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap="8" as="form" onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <Heading size="xl" textAlign="center">
                Assign Security Group
              </Heading>
              <Text
                size="md"
                fontWeight="normal"
                color="gray.500"
                textAlign="center"
              >
                Instances using this Security Group will be affected by the
                changes.
              </Text>
            </Stack>
            {!noSelectedSecurityGroups && (
              <List>
                {values.selectedSecurityGroups.map(({ id, name }) => (
                  <ListItem
                    key={id}
                    py={3}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    borderBottomWidth="1px"
                    borderBottomColor="gray.100"
                  >
                    <Text size="md" fontWeight="normal" color="gray.500">
                      {name}
                    </Text>
                    <Close
                      color="gray.500"
                      cursor="pointer"
                      _hover={{ color: 'gray.600' }}
                      onClick={() =>
                        handleTransfer(
                          id,
                          'selectedSecurityGroups',
                          'availableSecurityGroups'
                        )
                      }
                    />
                  </ListItem>
                ))}
              </List>
            )}
            <FormControl isInvalid={errors.selectedSecurityGroups}>
              <FormLabel>
                <Asterisk />
                Assign Security Group to Instance
              </FormLabel>
              {isLoading ? (
                <Center w="full" h={10}>
                  <Spinner size="sm" color="gray.500" />
                </Center>
              ) : isError ? (
                <Center h={10}>
                  <WarningError>Unable to load security groups</WarningError>
                </Center>
              ) : (
                <>
                  <Select
                    name="availableSecurityGroups"
                    placeholder="Select"
                    isDisabled={noAvailableSecurityGroups}
                    onChange={(e) =>
                      handleTransfer(
                        e.target.value,
                        'availableSecurityGroups',
                        'selectedSecurityGroups'
                      )
                    }
                  >
                    {values.availableSecurityGroups?.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  {noAvailableSecurityGroups && noSelectedSecurityGroups && (
                    <FormHelperText>
                      There are no Security Groups.{' '}
                      <Link
                        colorScheme="offBlack"
                        as={ReactLink}
                        to={withOrgPathFallback(
                          routes.dashboard.securityGroups.create
                        )}
                      >
                        Create here
                      </Link>
                      .
                    </FormHelperText>
                  )}
                  <FormErrorMessage>
                    {errors.selectedSecurityGroups}
                  </FormErrorMessage>
                </>
              )}
            </FormControl>
            <Stack spacing={4}>
              <Button
                type="submit"
                isDisabled={
                  (noAvailableSecurityGroups && noSelectedSecurityGroups) ||
                  isError
                }
                isLoading={isLoading || isSubmitting}
              >
                Assign
              </Button>
              <Button
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isLoading || isSubmitting}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Stack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
