import { Link } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { withOrgPath, getOrgParam, getProjectParam } from '../../router'

const ParamLink = ({ to, children, ...props }) => {
  const orgId = getOrgParam()
  const projectId = getProjectParam()
  const toWithParam = useMemo(() => {
    if (orgId) {
      return withOrgPath(to, orgId, projectId)
    } else {
      return to
    }
  }, [to, orgId, projectId])
  return (
    <Link to={toWithParam} {...props}>
      {children}
    </Link>
  )
}

export default ParamLink
