import {
  Skeleton,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { toastMessages } from '../../../../../constants/toast-messages'
import { useToast } from '../../../../shared/Toast'
import { Stripe } from '../../../../shared/Icons'
import { Error } from '../../../../shared/Error'
import { useOrgBilling, useAccountBilling } from '../../../../shared/hooks'
import { OrgBilling, AccountBilling } from '../../../../../api/billing'
import { formatCurrency } from '../../../../../helpers/currency'
import { BalanceField, MethodField } from './fields'
import { Elements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { mutate } from 'swr'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY)

const validationSchema = yup.object().shape({
  amount: yup.number().required('You must enter an amount'),
  paymentMethod: yup.object().when('isChecked', {
    is: true,
    then: yup.object().required('The payment method is required')
  })
})

const Recharge = ({ disclosure }) => {
  const { isOpen, onClose } = disclosure
  const stripe = useStripe()
  const { orgId } = useParams()
  const toast = useToast()

  const { billing, isLoadingBilling, isErrorBilling } = orgId
    ? useOrgBilling({ orgId })
    : useAccountBilling()
  const methods = billing?.creditCards || []

  const initialValues = {
    amount: 50,
    paymentMethod: methods[0]
  }

  const handleRechargeStatus = (data) => {
    switch (data.status) {
      case 'succeeded':
        toast({
          status: 'success',
          message: toastMessages.rechargeSuccess
        })
        onClose()
        break
      case 'processing':
        toast({
          status: 'info',
          message: toastMessages.rechargeProcessing
        })
        onClose()
        break
      case 'requires_action':
        stripe
          .handleNextAction({
            clientSecret: data.secret
          })
          .then(function (result) {
            if (result.error) {
              toast({
                status: 'error',
                message: toastMessages.rechargeError
              })
            } else {
              toast({
                status: 'success',
                message: toastMessages.rechargeSuccess
              })
            }
          })
        break
      default:
        toast({
          status: 'error',
          message: toastMessages.rechargeError
        })
        break
    }
  }

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    const payload = {
      balance: values.amount,
      payment_method_id: values.paymentMethod.id,
      return_url: window.location.href
    }
    try {
      let data
      if (orgId) {
        data = await OrgBilling.recharge({ orgId, payload })
        await mutate(`/billing/customer?org=${orgId}`)
      } else {
        data = await AccountBilling.recharge({ payload })
        await mutate('/billing/customer')
      }
      handleRechargeStatus(data)
    } catch (e) {
      toast({
        status: 'error',
        message: toastMessages.rechargeError
      })
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={8}>
            <Heading size="xl" textAlign="center">
              Account Balance
            </Heading>
            {isLoadingBilling ? (
              <Skeleton h="85px" />
            ) : isErrorBilling ? (
              <Error />
            ) : (
              <Stack spacing={8}>
                <Stack spacing={1}>
                  <Text size="2xl">
                    {formatCurrency(billing?.balance || 0)}
                  </Text>
                  <Text fontSize="16px" color="gray.500">
                    Account Balance
                  </Text>
                </Stack>
                <Text fontSize="16px" color="gray.500">
                  Insufficient balance can lead to account suspension.
                </Text>
                <Formik
                  validationSchema={validationSchema}
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  enableReinitialize
                >
                  {({ handleSubmit, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                      <Stack spacing={8}>
                        <BalanceField name="amount" label="Recharge amount:" />
                        <MethodField
                          name="paymentMethod"
                          label="Using this payment method:"
                          methods={methods}
                        />
                        <Text color="gray.500" size="sm">
                          The account balance funds are used for all Genesis
                          Cloud services and charges - insufficient balance can
                          lead to account suspension. Refunds are are processed
                          on a case-by-case basis.
                        </Text>
                      </Stack>
                      <Stack spacing={4}>
                        <Stripe display="flex" />
                        <Button type="submit" isLoading={isSubmitting}>
                          Recharge
                        </Button>
                        <Button
                          variant="outline"
                          colorScheme="offBlack"
                          isDisabled={isSubmitting}
                          onClick={onClose}
                        >
                          Cancel
                        </Button>
                      </Stack>
                    </Form>
                  )}
                </Formik>
              </Stack>
            )}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export const RechargeModal = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <Recharge {...props} />
    </Elements>
  )
}
