import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Select,
  Stack,
  Text
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { mutate } from 'swr'
import { useToast } from './Toast'
import { apiErrorHandler } from './ApiErrorHandler'
import * as yup from 'yup'
import { OrgBilling, AccountBilling } from '../../api/billing'
import { transformBillingPayload } from './hooks'
import Asterisk from './Asterisk'
import { getCountries } from './PhoneInput/country'
import { DEFAULT_VAT_TYPE, VAT_TYPES } from '../../constants/billing'
import { toastMessages } from '../../constants/toast-messages'
import { useIsMounted } from './hooks/useIsMounted'

const countries = getCountries()

const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
  name: yup.string().required('Name is required'),
  companyName: yup.string(),
  vat: yup.string(),
  vatType: yup.string(),
  phone: yup
    .string()
    .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[0-9]*$/, 'Invalid phone number')
    .max(19, 'Phone number must be less than 20 digits'),
  address: yup.string(),
  city: yup.string(),
  postalCode: yup.string(),
  countryCode: yup.string()
})

export const BillingDetailsForm = ({
  orgId,
  initialValues = {},
  onSuccess,
  onCancel,
  saveText = 'Save',
  hideSuccessToast
}) => {
  const isMounted = useIsMounted()
  const toast = useToast()
  const generateVATTypeValue = (type) => `${type.type}-${type.country}`

  const formInitialValues = {
    email: '',
    name: '',
    companyName: '',
    vat: '',
    vatType: DEFAULT_VAT_TYPE,
    phone: '',
    address: '',
    city: '',
    postalCode: '',
    countryCode: '',
    addBillingAddress: false,
    ...initialValues
  }

  VAT_TYPES.forEach((type) => {
    if (type.type === formInitialValues.vatType) {
      formInitialValues.vatType = generateVATTypeValue(type)
    }
  })

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    const payload = transformBillingPayload(values)
    try {
      if (orgId) {
        await OrgBilling.updateBilling({ orgId, payload })
        await mutate(`/billing/customer?org=${orgId}`)
      } else {
        await AccountBilling.updateBilling({ payload })
        await mutate('/billing/customer')
      }
      !hideSuccessToast &&
        toast({
          status: 'success',
          message: toastMessages.billingDetailsUpdateSuccess
        })
      onSuccess()
    } catch (error) {
      const message = await apiErrorHandler(error)
      toast({
        status: 'error',
        message
      })
    } finally {
      if (isMounted()) {
        setSubmitting(false)
      }
    }
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={formInitialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ handleSubmit, errors, touched, isSubmitting, values }) => (
        <Form onSubmit={handleSubmit}>
          <Stack spacing={8}>
            <Stack spacing={2}>
              <FormControl isInvalid={errors.email && touched.email}>
                <FormLabel>
                  <Asterisk />
                  Billing email
                </FormLabel>
                <Field
                  as={Input}
                  placeholder="Enter your email"
                  name="email"
                  type="text"
                  autoFocus={true}
                />
                <FormHelperText>
                  Please provide an email we can send information to regarding
                  billing and payments
                </FormHelperText>
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              </FormControl>
            </Stack>
            <FormControl isInvalid={errors.name && touched.name}>
              <FormLabel>
                <Asterisk />
                Full Name
              </FormLabel>
              <Field
                as={Input}
                placeholder="Enter your full name"
                name="name"
                type="text"
              />
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.companyName && touched.companyName}>
              <FormLabel>Business name</FormLabel>
              <Field
                as={Input}
                placeholder="Enter your business name"
                name="companyName"
                type="text"
              />
              <FormErrorMessage>{errors.companyName}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.vat && touched.vat}>
              <FormLabel>VAT number</FormLabel>
              <HStack gap={2}>
                <Field as={Select} name="vatType" placeholder="VAT type">
                  {VAT_TYPES.map((type) => {
                    const vatTypeVal = generateVATTypeValue(type)
                    return (
                      <option value={vatTypeVal} key={vatTypeVal}>
                        {type.country}
                        {' - '}
                        {type.type.toUpperCase().replace('_', ' ')}
                      </option>
                    )
                  })}
                </Field>
                <Field
                  as={Input}
                  placeholder={
                    VAT_TYPES.find(
                      (vatType) =>
                        generateVATTypeValue(vatType) === values.vatType
                    )?.placeholder
                  }
                  name="vat"
                  type="text"
                />
              </HStack>
              <FormErrorMessage>{errors.vat}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.phone && touched.phone}>
              <FormLabel>Phone number</FormLabel>
              <Field
                as={Input}
                placeholder="Enter your phone number"
                name="phone"
                type="text"
              />
              <FormErrorMessage>{errors.phone}</FormErrorMessage>
            </FormControl>
            <FormControl>
              <Field
                as={Checkbox}
                name="addBillingAddress"
                isChecked={values.addBillingAddress}
              >
                <Text color="gray.500">Add billing address</Text>
              </Field>
            </FormControl>
            {values.addBillingAddress && (
              <>
                <FormControl isInvalid={errors.address && touched.address}>
                  <FormLabel>Address</FormLabel>
                  <Field
                    as={Input}
                    placeholder="Enter your address"
                    name="address"
                    type="text"
                  />
                  <FormErrorMessage>{errors.address}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.city && touched.city}>
                  <FormLabel>City</FormLabel>
                  <Field
                    as={Input}
                    placeholder="Enter your city"
                    name="city"
                    type="text"
                  />
                  <FormErrorMessage>{errors.city}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={errors.postalCode && touched.postalCode}
                >
                  <FormLabel>ZIP / Postal code</FormLabel>
                  <Field
                    as={Input}
                    placeholder="Enter your postalCode"
                    name="postalCode"
                    type="text"
                  />
                  <FormErrorMessage>{errors.postalCode}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.countryCode}>
                  <FormLabel htmlFor="countryCode">Country / region</FormLabel>
                  <Field as={Select} name="countryCode" placeholder="Select">
                    {countries.map((country) => (
                      <option value={country.id} key={country.id}>
                        {country.name} {country.flag}
                      </option>
                    ))}
                  </Field>
                  <FormErrorMessage>{errors.countryCode}</FormErrorMessage>
                </FormControl>
              </>
            )}
            <Stack spacing={4}>
              <Button
                type="submit"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                {saveText}
              </Button>
              {onCancel && (
                <Button
                  colorScheme="offBlack"
                  variant="outline"
                  isDisabled={isSubmitting}
                  onClick={onCancel}
                  isFullWidth
                >
                  Cancel
                </Button>
              )}
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  )
}
