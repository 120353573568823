import { Link as ReactLink } from 'react-router-dom'
import { Stack, Text, Link } from '@chakra-ui/react'
import { TableField } from '../../../../shared/Fields'
import { withOrgPathFallback } from '../../../../../router'
import routes from '../../../../../constants/routes'

const columns = [
  { header: 'Name', field: 'name', width: '25%' },
  {
    header: 'Fingerprints',
    field: 'fingerprint',
    width: '75%'
  }
]

const NoData = () => <Text color="red.500">There are no SSH Keys yet.</Text>

const SSHKeys = ({ name, sshKeys }) => (
  <Stack spacing={3}>
    {sshKeys.length === 0 ? (
      <NoData />
    ) : (
      <TableField name={name} columns={columns} rows={sshKeys} />
    )}
    <Link
      as={ReactLink}
      to={withOrgPathFallback(routes.dashboard.security)}
      alignSelf="start"
    >
      Manage your keys here.
    </Link>
  </Stack>
)

export default SSHKeys
