import { Stack, useRadioGroup } from '@chakra-ui/react'
import { RadioSubscription } from './RadioSubscription'

const OnDemand = ({ subs, value, discount, onChange }) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    value,
    onChange
  })

  return (
    <Stack spacing={4} {...getRootProps()}>
      {subs.map((sub) => (
        <RadioSubscription
          key={sub.id}
          {...getRadioProps({
            value: sub.id,
            sub: { ...sub, discount }
          })}
        />
      ))}
    </Stack>
  )
}

export default OnDemand
