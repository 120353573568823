import {
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { Card } from '../../../../shared/Cards'
import { DeleteModal } from '../modals'

const Delete = ({ securityGroup }) => {
  const disclosure = useDisclosure()
  const isInUse = securityGroup?.instanceCount > 0

  return (
    <Card as={Flex} justify="space-between">
      <Stack>
        <Heading size="xl">Delete Security Group</Heading>
        <Text color="gray.500">
          To delete the Security Group, please remove all Instances from this
          Security Group.
        </Text>
      </Stack>
      <Button
        colorScheme="red"
        isDisabled={isInUse}
        onClick={disclosure.onOpen}
      >
        Delete Security Group
      </Button>
      <DeleteModal disclosure={disclosure} securityGroup={securityGroup} />
    </Card>
  )
}

export default Delete
