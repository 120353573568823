import React from 'react'
import { CallbackComponent } from 'redux-oidc'
import userManager from '../../helpers/auth/userManager'
import routes from '../../constants/routes'

const CallbackPage = () => {
  const redirect = (user) => {
    const redirectTo = user && user.state && user.state.redirectUrl
    window.location.assign(
      redirectTo || `${routes.dashboard.index}?loginRedirect=true`
    )
  }

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={redirect}
      errorCallback={(error) => console.error(error)}
    >
      <div>Redirecting...</div>
    </CallbackComponent>
  )
}

export default CallbackPage
