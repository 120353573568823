import { useParams } from 'react-router-dom'
import {
  Stack,
  Flex,
  Text,
  Heading,
  Button,
  useDisclosure
} from '@chakra-ui/react'
import { Card } from '../../../shared/Cards'
import { Plus, Reset, Bell } from '../../../shared/Icons'
import { useOrgBilling, useAccountBilling } from '../../../shared/hooks'
import { formatCurrency } from '../../../../helpers/currency'
import { AutoRechargeModal, NotificationModal, RechargeModal } from './modals'

const Balance = () => {
  const { orgId } = useParams()
  const { billing } = orgId ? useOrgBilling({ orgId }) : useAccountBilling()
  const pending = billing?.balancePendingAmount

  const rechargeDisclosure = useDisclosure()
  const autoRechargeDisclosure = useDisclosure()
  const notificationDisclosure = useDisclosure()

  return (
    <Card as={Stack} spacing={8}>
      <Stack spacing={2}>
        <Heading size="xl">Account balance</Heading>
        <Text color="gray.500">
          Set up automated account balance to be recharged when it runs low.
        </Text>
        {pending && (
          <Text color="blue.500">
            + {formatCurrency(pending)} pending charge, it might take a couple
            of hours to process
          </Text>
        )}
      </Stack>
      <Flex grow={1} gap={3} alignItems="flex-end">
        <Button
          colorScheme="offBlack"
          variant="outline"
          leftIcon={<Plus boxSize={4} color="black" />}
          onClick={rechargeDisclosure.onOpen}
        >
          Recharge
        </Button>
        <Button
          colorScheme="offBlack"
          variant="outline"
          leftIcon={<Reset boxSize={4} />}
          onClick={autoRechargeDisclosure.onOpen}
        >
          Auto recharge
        </Button>
        <Button
          colorScheme="offBlack"
          variant="outline"
          leftIcon={<Bell boxSize={4} />}
          onClick={notificationDisclosure.onOpen}
        >
          Set up notification
        </Button>
      </Flex>
      <RechargeModal disclosure={rechargeDisclosure} />
      <AutoRechargeModal disclosure={autoRechargeDisclosure} />
      <NotificationModal disclosure={notificationDisclosure} />
    </Card>
  )
}

export default Balance
