import {
  FormControl,
  FormLabel,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VStack
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as AccountApi from '../../../api/account'
import routes from '../../../constants/routes'
import { setOnboardingLastRoute } from '../../../helpers/onboarding'
import Asterisk from '../../shared/Asterisk'
import * as profileType from '../../../constants/profile-type'
import OnboardingLayout from '../Layout'
import OrganizationForm from './OrganizationForm'
import PersonalForm from './PersonalForm'

const Setup = () => {
  const user = useSelector((state) => state.oidc.user)
  const [type, setType] = useState(profileType.personal)

  useEffect(() => {
    AccountApi.fetchOnboardingCheckin()
  }, [])

  useEffect(() => {
    if (user) {
      setOnboardingLastRoute(user.profile.sub, routes.onboarding.setup)
    }
  }, [user])

  return (
    <OnboardingLayout>
      <VStack spacing={4} w="100%">
        <Heading as="h1" size="3xl">
          Set up your profile
        </Heading>
        <FormControl>
          <FormLabel>
            <Asterisk />
            Profile type:
          </FormLabel>
          <RadioGroup value={type} onChange={(type) => setType(type)}>
            <Stack>
              <Radio type="radio" id="personal" value={profileType.personal}>
                Personal
                <Text size="sm">Single-user profile</Text>
              </Radio>
              <Radio
                type="radio"
                id="organization"
                value={profileType.organization}
              >
                Organization
                <Text size="sm">Multiple-user profile</Text>
              </Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
        {type === profileType.personal && <PersonalForm />}
        {type === profileType.organization && <OrganizationForm />}
      </VStack>
    </OnboardingLayout>
  )
}

export default Setup
