import React, { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Heading, Button, VStack } from '@chakra-ui/react'
import { mutate } from 'swr'
import { setAccountOnboarding } from '../../api/account'
import {
  removeOnboardingLastRoute,
  setOnboardingLastRoute
} from '../../helpers/onboarding'
import routes from '../../constants/routes'
import OnboardingLayout from './Layout'
import PhoneForm from '../shared/PhoneVerify/PhoneForm'
import CodeForm from '../shared/PhoneVerify/CodeForm'
import { withOrgPath } from '../../router'
import { OrganizationIdStorage, ProjectIdStorage } from '../shared/storage'

const PhoneVerification = () => {
  const isVerified = useSelector(
    (state) => state.ui.phoneVerification.isVerified
  )
  const isLoading = useSelector((state) => state.ui.phoneVerification.isLoading)
  const history = useHistory()
  const user = useSelector((state) => state.oidc.user)

  const redirectToDashboard = useCallback(async () => {
    await setAccountOnboarding()
    await mutate('/account')
    removeOnboardingLastRoute(user.profile.sub)
    const orgId = OrganizationIdStorage.get()
    const projectId = ProjectIdStorage.get()
    if (orgId && projectId) {
      history.push(
        `${withOrgPath(
          routes.dashboard.index,
          orgId,
          projectId
        )}?org_intro=true`
      )
    } else {
      history.push(routes.dashboard.index)
    }
  }, [history, user])

  useEffect(() => {
    if (user) {
      setOnboardingLastRoute(
        user.profile.sub,
        routes.onboarding.phoneVerification
      )
    }
  }, [user])

  return (
    <OnboardingLayout>
      <Heading as="h1" size="3xl" mb={4}>
        Verify your phone number
      </Heading>
      {isVerified ? (
        <CodeForm onVerify={redirectToDashboard} />
      ) : (
        <VStack spacing={4}>
          <PhoneForm />
          <Button
            variant="outline"
            colorScheme="offBlack"
            isFullWidth
            isDisabled={isLoading}
            onClick={redirectToDashboard}
          >
            Skip step
          </Button>
        </VStack>
      )}
    </OnboardingLayout>
  )
}

export default PhoneVerification
