import { toCamel } from '../../../../../helpers/serializers'
import moment from 'moment'

export const getInfoByType = (info, type) => {
  const typeName = toCamel(type)
  const infoByType = info ? info[typeName] : {}
  return infoByType || {}
}

const formatDate = (date) => moment(date).format('YYYY-MM-DD HH:mm:ss')

export const transformPayload = (values, link) => {
  const { region, type, size, dates } = values

  const redirect_url = window.location.origin + link

  return {
    region,
    flavor_name: type,
    quota: size,
    started_at: formatDate(dates.start),
    ended_at: formatDate(dates.end),
    redirect_url
  }
}
