import { Button } from '@chakra-ui/react'
import React from 'react'
import { Link as ReactLink, Redirect, Route, Switch } from 'react-router-dom'
import routes from '../../constants/routes'
import NotFound from '../Errors/NotFound'
import LinesBackground from '../shared/Layout/LinesBackground'
import Billing from './Billing'
import Details from './Details'
import Payment from './Payment'
import PhoneVerification from './PhoneVerification'
import Setup from './Setup'

const Onboarding = () => (
  <LinesBackground>
    <Switch>
      <Route
        exact
        path={routes.onboarding.index}
        render={() => <Redirect to={routes.onboarding.setup} />}
      />
      <Route exact path={routes.onboarding.setup}>
        <Setup />
      </Route>
      <Route exact path={routes.onboarding.details}>
        <Details />
      </Route>
      <Route exact path={routes.onboarding.payment}>
        <Payment />
      </Route>
      <Route exact path={routes.onboarding.billing}>
        <Billing />
      </Route>
      <Route exact path={routes.onboarding.phoneVerification}>
        <PhoneVerification />
      </Route>
      <NotFound>
        <Button as={ReactLink} to={routes.onboarding} isFullWidth>
          Back to Onboarding
        </Button>
      </NotFound>
    </Switch>
  </LinesBackground>
)

export default Onboarding
