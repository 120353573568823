import { Text } from '@chakra-ui/react'

const SupportError = ({ children, ...props }) => (
  <Text size="sm" {...props}>
    {children}
    <br />
    Please refresh this page or contact Genesis Cloud support if the error
    persists.
  </Text>
)

export default SupportError
