import {
  Center,
  Heading,
  Text,
  Stack,
  Button,
  Flex,
  useDisclosure
} from '@chakra-ui/react'
import { Card } from '../../../shared/Cards'
import {
  useAccountInstanceVolumes,
  useOrgInstanceVolumes
} from '../../../shared/hooks'
import { useParams } from 'react-router-dom'
import Spinner from '../../../shared/Spinner'
import VolumesTable from './VolumesTable'
import SupportError from '../../../Errors/SupportError'
import { Plus } from '../../../shared/Icons'
import { AttachVolume } from '../Modals/AttachVolume'
import ManageVolumesLink from '../../../shared/ManageVolumesLink'

const NoVolumes = () => (
  <div>
    <Text color="gray.500" fontSize="sm">
      No Volumes attached to this Instance.&nbsp;
    </Text>
    <ManageVolumesLink />
  </div>
)

const Volumes = ({ instance, hasPermission }) => {
  const attachVolumeDisclosure = useDisclosure()
  const { orgId, projectId } = useParams()
  const { volumes, isError, isLoading } = orgId
    ? useOrgInstanceVolumes({ orgId, projectId, instanceId: instance.id })
    : useAccountInstanceVolumes({ instanceId: instance.id })

  return (
    <Card as={Stack} spacing={8}>
      <Flex justify="space-between">
        <Heading size="xl">Volumes</Heading>

        {hasPermission && (
          <>
            <Button
              colorScheme="offBlack"
              variant="outline"
              leftIcon={<Plus color="black" />}
              onClick={attachVolumeDisclosure.onOpen}
            >
              Attach Volume
            </Button>
            <AttachVolume
              disclosure={attachVolumeDisclosure}
              instance={instance}
            />
          </>
        )}
      </Flex>

      {isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <>
          {isError && (
            <SupportError>
              Something went wrong loading the instance volumes.
            </SupportError>
          )}
          {volumes.length === 0 ? (
            <NoVolumes />
          ) : (
            <VolumesTable
              instanceId={instance.id}
              volumes={volumes}
              orgId={orgId}
              projectId={projectId}
            />
          )}
        </>
      )}
    </Card>
  )
}

export default Volumes
