export const serialize = (o) => {
  if (Array.isArray(o)) {
    return o.map(serialize)
  } else if (o && typeof o === 'object') {
    const n = {}

    Object.keys(o).forEach((k) => {
      n[toCamel(k)] = serialize(o[k])
    })

    return n
  }

  return o
}

export const serializeArray = (a) => {
  return a.map(serialize)
}

export const toCamel = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

export const removeUnderscore = (text) => {
  const underscoreIndex = text.indexOf('_')
  return underscoreIndex !== -1 ? text.substring(0, underscoreIndex) : text
}
