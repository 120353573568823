const Select = {
  baseStyle: ({ colorScheme }) => ({
    icon: {
      color: `${colorScheme}.700`
    }
  }),
  sizes: {
    sm: {
      field: {
        borderRadius: '20px'
      }
    },
    md: {
      field: {
        borderRadius: '20px'
      }
    }
  },
  variants: {
    outline: ({ colorScheme }) => ({
      field: {
        borderColor: `${colorScheme}.300`,
        color: `${colorScheme}.700`,
        _hover: {
          borderColor: `${colorScheme}.400`
        },
        _focus: {
          boxShadow: 'none'
        },
        _invalid: {
          boxShadow: 'none',
          borderWidth: '2px'
        }
      }
    })
  },
  defaultProps: {
    colorScheme: 'gray',
    size: 'md'
  }
}

export const SelectComponents = {
  dropdownIndicator: (provided) => ({
    ...provided,
    bg: 'transparent',
    px: 2,
    cursor: 'inherit'
  }),
  menu: (provided) => ({
    ...provided,
    mt: '1px',
    borderRadius: '10px',
    '> div': {
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: '10px',
      borderColor: 'gray.300',
      minWidth: 'auto'
    },
    'div[role=button]': {
      borderBottom: 'solid 1px',
      borderColor: 'gray.100'
    }
  }),
  clearIndicator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    textAlign: 'left',
    color: 'gray.500'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  multiValue: (provided) => ({
    ...provided,
    bg: 'gray.100',
    color: 'black',
    borderRadius: '20px',
    ml: -2,
    mr: 4,
    '& span': {
      m: '2px'
    },
    '& svg': {
      color: 'black'
    }
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: 'white'
  })
}

export default Select
