import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import { Project } from '../../../../../../api/projects'
import { toastMessages } from '../../../../../../constants/toast-messages'
import { useToast } from '../../../../../shared/Toast'

export const MakeDefaultModal = ({ disclosure, projectId }) => {
  const { isOpen, onClose } = disclosure
  const { orgId } = useParams()
  const toast = useToast()

  const handleSubmit = useCallback(
    async (_, { setSubmitting }) => {
      setSubmitting(true)
      try {
        await Project.updateById({
          orgId,
          projectId,
          data: { is_default: true }
        })

        mutate((key) => key.startsWith(`/organizations/${orgId}/projects?`))
        toast({
          status: 'success',
          message: toastMessages.saved
        })
        onClose()
      } catch {
        toast({
          status: 'error',
          message: toastMessages.notSaved
        })
      } finally {
        setSubmitting(false)
      }
    },
    [orgId, projectId]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Heading size="xl" mb={4} textAlign="center">
            Set default Project
          </Heading>
          <Text mb={8} size="md" textAlign="center" fontWeight="normal">
            Are you sure you want to make this the default Project?
          </Text>
          <Formik
            initialValues={{ confirmation: '' }}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ handleSubmit, errors, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <Stack spacing={8}>
                  <Stack spacing={4}>
                    <Button
                      type="submit"
                      isDisabled={isSubmitting}
                      isLoading={isSubmitting}
                    >
                      Make default Project
                    </Button>
                    <Button
                      colorScheme="offBlack"
                      variant="outline"
                      isDisabled={isSubmitting}
                      onClick={onClose}
                      isFullWidth
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
