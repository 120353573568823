import { useEffect } from 'react'
import userManager from '../../helpers/auth/userManager'

const SignOut = () => {
  useEffect(() => {
    userManager.signoutRedirect()
  }, [])

  return <div />
}

export default SignOut
