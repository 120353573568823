import { Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import { useHasWriteProjectPermissions } from '../../../shared/hooks/project'
import VolumesTableRow from './VolumesTableRow'

const VolumesTable = ({ volumes, instanceId, orgId, projectId }) => {
  const hasWriteProjectPermissions = orgId
    ? useHasWriteProjectPermissions({
        projectId,
        orgId
      })
    : true
  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th w="20%">Name</Th>
            <Th w="20%">Location</Th>
            <Th w="20%">Description</Th>
            <Th w="10%">Size</Th>
            <Th w="20%">Device name</Th>
            <Th w="10%" />
          </Tr>
        </Thead>
        <Tbody>
          {volumes.map((volume) => (
            <VolumesTableRow
              key={volume.id}
              volume={volume}
              instanceId={instanceId}
              hasWriteProjectPermissions={hasWriteProjectPermissions}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default VolumesTable
