import { Flex, HStack, Heading, Stack } from '@chakra-ui/react'
import moment from 'moment'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import {
  useAccountBillingStorage,
  useOrgBillingStorage
} from '../../../../shared/hooks'
import Calendar from './calendar'
import Chart from './chart'
import Menu from './menu'
import Summary from './summary'
import { defaultDay, mutateStorage } from './utils'

const ChartSection = ({ data }) => {
  const { type, color, label } = data
  const [selected, setSelected] = useState(defaultDay)
  const [range, setRange] = useState(null)
  const { orgId } = useParams()
  const { storage, isLoadingStorage, isErrorStorage } = orgId
    ? useOrgBillingStorage({ orgId, type })
    : useAccountBillingStorage({ type })

  const handleClick = async (value) => {
    setSelected(value)

    if (value === null) {
      return
    }

    const endingBefore = moment().format('yyyy-MM-DD')
    const startingAfter = moment().subtract(value, 'days').format('yyyy-MM-DD')

    mutateStorage({
      mutate,
      endingBefore,
      startingAfter,
      orgId,
      type
    })
  }

  return (
    <>
      <Heading size="xl">{label}</Heading>
      <Stack spacing={2}>
        <Flex justifyContent="space-between">
          <Stack align="flex-end">
            <HStack spacing={0}>
              <Menu selected={selected} onClick={handleClick} />
              <Calendar
                type={type}
                selected={selected}
                onSelect={setSelected}
                setCalendarRange={setRange}
              />
            </HStack>
          </Stack>
          <Summary range={range} total={storage[0]?.total} />
        </Flex>
        <Chart
          data={storage}
          color={color}
          isLoading={isLoadingStorage}
          isError={isErrorStorage}
        />
      </Stack>
    </>
  )
}

export default ChartSection
