import FingerprintJS from '@fingerprintjs/fingerprintjs'

// Initialize an agent at application startup.
const fpAgent = FingerprintJS.load()

export async function getFingerprint() {
  const agent = await fpAgent
  const fp = await agent.get()

  return fp.visitorId
}
