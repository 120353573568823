const Menu = {
  parts: ['list', 'item', 'divider'],
  variants: {
    topbar: {
      list: { py: 0 },
      item: {
        px: 0,
        py: 0,
        '&:not(:first-of-type)': {
          '> div': {
            '&:hover > svg': {
              color: 'black'
            },
            '> a': {
              color: 'gray.500',
              _hover: { color: 'black', textDecoration: 'none' }
            },
            _hover: {
              cursor: 'pointer'
            }
          }
        },
        '> div': {
          display: 'grid',
          gridTemplateColumns: '24px auto',
          alignItems: 'center',
          gap: 2,
          w: '100%',
          px: 3,
          py: 1,
          _hover: {
            cursor: 'default'
          },
          '> svg': {
            justifySelf: 'center'
          }
        },
        _hover: {
          bg: 'none'
        }
      }
    },
    table: {
      list: { py: 0 },
      divider: { my: 0 },
      item: {
        fontSize: 'sm',
        color: 'black',
        _hover: {
          bg: 'gray.100'
        }
      }
    }
  }
}

export default Menu
