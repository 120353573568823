import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { useCallback } from 'react'
import { mutate } from 'swr'
import * as yup from 'yup'
import { updateAccount } from '../../../../../../api/account'
import { toastMessages } from '../../../../../../constants/toast-messages'
import Asterisk from '../../../../../shared/Asterisk'
import { useToast } from '../../../../../shared/Toast'

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .max(50, 'Name length must be less than 50 characters'),
  email: yup.string().email('Invalid email').required('Email is required')
})

export const ProfileModal = ({ disclosure, account }) => {
  const { isOpen, onClose } = disclosure
  const toast = useToast()

  const onSubmit = useCallback(
    async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      try {
        if (values.name === account.name) {
          onClose()
        } else {
          await updateAccount({ name: values.name })
          await mutate('/account')
          onClose()
          toast({
            status: 'success',
            message: toastMessages.saved
          })
          resetForm()
        }
      } catch {
        toast({
          status: 'error',
          message: toastMessages.notSaved
        })
      } finally {
        setSubmitting(false)
      }
    },
    [account]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Heading size="xl" mb={8} textAlign="center">
            Edit profile details
          </Heading>
          <Formik
            validationSchema={validationSchema}
            initialValues={{ name: account.name, email: account.email }}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, errors, touched, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <FormControl isInvalid={errors.name && touched.name} mb={8}>
                  <FormLabel>
                    <Asterisk /> Full name
                  </FormLabel>
                  <Field
                    as={Input}
                    name="name"
                    type="text"
                    placeholder="Name Surname"
                  />
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                </FormControl>
                <FormControl mb={8}>
                  <FormLabel>
                    <Asterisk /> Email
                  </FormLabel>
                  <Field
                    as={Input}
                    name="email"
                    type="email"
                    isDisabled={true}
                  />
                </FormControl>
                <Button
                  type="submit"
                  isFullWidth
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Save
                </Button>
                <Button
                  mt={4}
                  isFullWidth
                  variant="outline"
                  colorScheme="offBlack"
                  isDisabled={isSubmitting}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
