import qs from 'query-string'
import { loadUser } from 'redux-oidc'
import {
  OrganizationIdStorage,
  ProjectIdStorage
} from '../components/shared/storage'
import routes from '../constants/routes'
import userManager from '../helpers/auth/userManager'
import { getOrgParam, getProjectParam } from '../router'
import store from '../store'

// 2 minutes in milliseconds
const expireTime = 2 * 60 * 1000
const API_ENDPOINT = process.env.REACT_APP_API_URL || '/api'
export const ACCOUNT_ENDPOINT = '/account/private-v1'
export const OBJECT_STORAGE_ENDPOINT = '/object-storage/private-v1'
export const COMPUTE_ENDPOINT = '/compute/private-v1'
export const PRODUCTS_ENDPOINT = '/products/private-v1'

const refreshToken = (options) => {
  const { oidc } = store.getState()
  if (options.method === 'GET' && oidc && oidc.user) {
    const expiresAt = oidc.user.expires_at
    if (expiresAt * 1000 - new Date().getTime() < expireTime) {
      loadUser(store, userManager)
    }
  }
}

let signingOutRedirect = false

const request = (endpoint, options) =>
  fetch(endpoint, options).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      if (response.status !== 204) {
        try {
          refreshToken(options)
          if (response.body) {
            const contentType = response.headers.get('Content-Type')
            if (contentType) {
              if (contentType.startsWith('application/json')) {
                return response.json()
              } else if (contentType.startsWith('text/plain')) {
                return response.text()
              }
            }
          }
          return Promise.resolve(null)
        } catch (error) {
          throw new Error(
            `unable to parse response json: ${endpoint} ${response.status}`
          )
        }
      }
      refreshToken(options)
      return response
    } else if ([400, 402, 403, 404, 410].includes(response.status)) {
      const error = new Error(response.status)
      error.response = response
      throw error
    } else if (response.status === 401) {
      // TODO: This does not make sense
      const { oidc } = store.getState()
      if (!signingOutRedirect && oidc && oidc.user) {
        signingOutRedirect = true
        userManager.signoutRedirect()
      } else {
        window.location.assign(routes.root)
      }
    } else {
      throw response.status
    }
  })

export const apiRequest = async (
  endpoint,
  options = {},
  authenticated = true
) => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...options.headers
  }

  if (authenticated) {
    const userState = store.getState().oidc.user
    const token = userState && userState.access_token
    headers.Authorization = `Bearer ${token}`
  }

  const config = {
    method: 'GET',
    ...options,
    headers
  }

  return request(`${API_ENDPOINT}${endpoint}`, config)
}

export const accountApiRequest = async (
  endpoint,
  options = {},
  authenticated = true
) => {
  return apiRequest(`${ACCOUNT_ENDPOINT}${endpoint}`, options, authenticated)
}

export const computeApiRequest = async (
  endpoint,
  options = {},
  authenticated = true
) => {
  return apiRequest(
    projectScopedEndpoint(COMPUTE_ENDPOINT, endpoint),
    options,
    authenticated
  )
}

export const projectScopedEndpoint = (service, endpoint) => {
  const orgId = getOrgParam() || OrganizationIdStorage.get()
  const projectId = getProjectParam() || ProjectIdStorage.get()
  let url
  if (orgId && projectId) {
    url = qs.parseUrl(`${service}/projects/${projectId}${endpoint}`)
  } else {
    url = qs.parseUrl(`${service}${endpoint}`)
  }

  if (orgId) {
    url.query = {
      ...url.query,
      org: orgId
    }
  }
  return qs.stringifyUrl(url)
}
