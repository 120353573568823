import { Button, Heading, Stack, Text } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import routes from '../../../../../../constants/routes'
import { withOrgPathFallback } from '../../../../../../router'
import PaymentForm from '../../../../../shared/payment-form'
import { useLocationOrigin } from '../../../../../shared/payment-form/utils'
import { OrganizationIdStorage } from '../../../../../shared/storage'
import Layout from './Layout'

const Payment = () => {
  const history = useHistory()
  const orgId = OrganizationIdStorage.get()
  const origin = useLocationOrigin()
  const nextStep = withOrgPathFallback(
    routes.dashboard.organizations.create.billing
  )

  useEffect(() => {
    if (!orgId) {
      history.push(
        withOrgPathFallback(routes.dashboard.organizations.create.index)
      )
    }
  }, [orgId])

  return (
    <Layout>
      <Stack spacing={5}>
        <Heading as="h1" size="3xl" textAlign="center">
          Payment method
        </Heading>
        <Text color="gray.500" size="md" fontWeight="normal" textAlign="center">
          {`You won't be able to access computing resources until you add a valid
          payment method.`}
        </Text>
        <PaymentForm successLink={`${origin}${nextStep}`} />
        <Button
          variant="outline"
          colorScheme="offBlack"
          onClick={() => history.push(nextStep)}
        >
          Skip step
        </Button>
      </Stack>
    </Layout>
  )
}

export default Payment
