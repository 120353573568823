import { Box, Td, useDisclosure, Tr, Link } from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'
import routes from '../../../../constants/routes'
import { withOrgPathFallback } from '../../../../router'
import Region from '../../../shared/Region'
import { Close } from '../../../shared/Icons'
import { DetachModal } from '../../volumes/modals'
import { TdTruncated } from '../../../shared/Table'
import Spinner from '../../../shared/Spinner'

const VolumesTableRow = ({
  volume,
  instanceId,
  hasWriteProjectPermissions
}) => {
  const detachDisclosure = useDisclosure()

  let canBeDetached = volume.attachment?.status === 'attached'

  // RS legacy
  if (volume.attachments?.length) {
    canBeDetached = volume.attachments[0].status === 'attached'
  }

  return (
    <Tr key={volume.id}>
      <TdTruncated>
        <Link
          as={ReactLink}
          to={withOrgPathFallback(
            routes.dashboard.volumes.view.replace(':volumeId', volume.id)
          )}
          colorScheme="gray"
        >
          {volume.name}
        </Link>
      </TdTruncated>
      <Td>
        <Region slug={volume.region} />
      </Td>
      <TdTruncated>{volume.description || '-'}</TdTruncated>
      <Td>{volume.size} GB</Td>
      <Td>{volume.device}</Td>
      <Td isNumeric>
        {canBeDetached ? (
          <>
            {hasWriteProjectPermissions && (
              <Box
                _hover={{ cursor: 'pointer' }}
                onClick={detachDisclosure.onOpen}
                role="group"
              >
                <Close color="gray.500" _groupHover={{ color: 'gray.600' }} />
              </Box>
            )}
            <DetachModal
              disclosure={detachDisclosure}
              volume={volume}
              instanceId={instanceId}
            />
          </>
        ) : (
          <Spinner color="gray.500" />
        )}
      </Td>
    </Tr>
  )
}

export default VolumesTableRow
