import { useEffect } from 'react'
import routes from '../../constants/routes'
import authPrompt from '../../helpers/auth/authPrompt'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

const LoginPage = () => {
  const location = useLocation()

  useEffect(() => {
    const values = queryString.parse(location.search)
    const token = values.token
    const error = values.error

    if (token || error) {
      if (token) {
        return authPrompt('token-signin', { extraQueryParams: { token } })
      }
      authPrompt('login', { extraQueryParams: { error } })
    } else {
      let redirectUrl = null
      if (location.pathname !== routes.signin) {
        redirectUrl = location.href
      }
      authPrompt('login', {
        data: {
          redirectUrl
        }
      })
    }
  }, [location])

  return <div>Logging in...</div>
}

export default LoginPage
