import { HStack, Heading, Stack, Skeleton } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import routes from '../../../../../constants/routes'
import { withOrgPathFallback } from '../../../../../router'
import SupportError from '../../../../Errors/SupportError'
import { ReturnLink } from '../../../../shared/Links'
import ResourceStatus from '../../../../shared/ResourceStatus'
import { useHasWriteProjectPermissions } from '../../../../shared/hooks/project'
import {
  useAccountSecurityGroup,
  useOrgSecurityGroup
} from '../../../../shared/hooks/securityGroups'
import Configuration from './configuration'
import Delete from './delete'
import Instances from './instances'
import Rename from './rename'

const Loading = () => (
  <Stack spacing={8}>
    <Skeleton h="45px" />
    <Skeleton h="322px" />
    <Skeleton h="211px" />
    <Skeleton h="120px" />
  </Stack>
)

const Error = () => (
  <SupportError pt={6}>
    {`Something went wrong loading the network's settings.`}
  </SupportError>
)

const SecurityGroupsView = () => {
  const { orgId, projectId, securityGroupId } = useParams()
  const hasWriteProjectPermissions = orgId
    ? useHasWriteProjectPermissions({
        projectId,
        orgId
      })
    : true

  const { securityGroup, isError, isLoading } = orgId
    ? useOrgSecurityGroup({ orgId, projectId, securityGroupId })
    : useAccountSecurityGroup({ securityGroupId })
  const isEditable = hasWriteProjectPermissions && !securityGroup?.isInternal

  return (
    <Stack spacing={2}>
      <ReturnLink
        to={withOrgPathFallback(routes.dashboard.securityGroups.index)}
      >
        Network
      </ReturnLink>
      {isLoading ? (
        <Loading />
      ) : isError ? (
        <Error />
      ) : (
        <Stack spacing={8}>
          <HStack spacing={3}>
            <Heading as="h1" size="4xl" noOfLines={1} maxWidth="600px">
              {securityGroup.name}
            </Heading>
            <ResourceStatus status={securityGroup.status} />
          </HStack>
          <Rename securityGroup={securityGroup} isEditable={isEditable} />
          <Configuration isEditable={isEditable} />
          <Instances region={securityGroup?.region} />
          {isEditable && <Delete securityGroup={securityGroup} />}
        </Stack>
      )}
    </Stack>
  )
}

export default SecurityGroupsView
