import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Stack, Heading, SimpleGrid, Skeleton, Tooltip } from '@chakra-ui/react'
import { Error } from '../../../shared/Error'
import { InputField } from '../../../shared/Fields'
import { InfoTooltip } from '../../../shared/Icons'
import { useFormikContext } from 'formik'
import {
  useOrgInstanceInfo,
  useAccountInstanceInfo
} from '../../../shared/hooks'

const HostnameInfo = () => (
  <Tooltip
    hasArrow
    placement="top"
    label="Hostname can contain the letters a-z, numbers 0-9, and the special character – (dash); must start with a-z and not end with dash."
  >
    <div>
      <InfoTooltip />
    </div>
  </Tooltip>
)

const NameInfo = () => (
  <Tooltip
    hasArrow
    placement="top"
    label="You can give your Instance a different name to easily identify it."
  >
    <div>
      <InfoTooltip />
    </div>
  </Tooltip>
)

let isNameTouched = false

const Name = () => {
  const { setValues } = useFormikContext()
  const { orgId, projectId } = useParams()
  const { info, isValidatingInfo, isErrorInfo } =
    orgId && projectId
      ? useOrgInstanceInfo({ orgId, projectId })
      : useAccountInstanceInfo({})

  const handleUpdateHostname = (hostname) => {
    if (!isNameTouched)
      setValues((prev) => ({
        ...prev,
        name: {
          hostname,
          name: hostname
        }
      }))
  }

  const handleUpdateName = () => (isNameTouched = true)

  useEffect(() => {
    if (info && !isValidatingInfo && !isErrorInfo) {
      setValues((prev) => ({
        ...prev,
        name: {
          hostname: info.nameSuggestion,
          name: info.nameSuggestion
        }
      }))
    }
  }, [isValidatingInfo])

  return (
    <Stack spacing={8}>
      <Heading size="xl">Name Instance</Heading>
      {isValidatingInfo ? (
        <Skeleton h="70px" />
      ) : isErrorInfo ? (
        <Error />
      ) : (
        <SimpleGrid columns={2} gap={5}>
          <InputField
            name="name.hostname"
            label="Hostname"
            placeholder="Enter Instance hostname"
            info={<HostnameInfo />}
            isRequired
            onUpdate={handleUpdateHostname}
          />
          <InputField
            name="name.name"
            label="Name"
            placeholder="Enter Instance name"
            info={<NameInfo />}
            isRequired
            onUpdate={handleUpdateName}
          />
        </SimpleGrid>
      )}
    </Stack>
  )
}

export default Name
