import { combineReducers } from 'redux'
import organizations from './organizations'
import phoneVerification from './phoneVerification'

const ui = combineReducers({
  organizations,
  phoneVerification
})

export default ui
