// @ts-check
import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  idToDelete: null
}

const slice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    idToDeleteUpdated: (state, action) => {
      state.idToDelete = action.payload
    }
  }
})

export const { idToDeleteUpdated } = slice.actions

export default slice.reducer
