import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { Field, Formik, Form as FormikForm } from 'formik'
import { useCallback, useEffect, useMemo, useState } from 'react'
import useSWR, { mutate } from 'swr'
import * as yup from 'yup'
import { Account } from '../../../../api/account'
import { AccountQuota, OrgQuota } from '../../../../api/quota'
import { toastMessages } from '../../../../constants/toast-messages'
import { serialize } from '../../../../helpers/serializers'
import {
  ValidationErrors,
  isUserValidToCreateResources
} from '../../../Errors/ValidationErrors'
import Asterisk from '../../../shared/Asterisk'
import { Card } from '../../../shared/Cards'
import { useToast } from '../../../shared/Toast'
import { useOrgParam } from '../../../shared/hooks/useOrgParam'

const Form = ({ quota, billing, isVip }) => {
  const orgId = useOrgParam()
  const { data: account } = useSWR('/account', () => Account.fetch())
  const [isDisabled, setIsDisabled] = useState(false)
  const toast = useToast()

  /* Build a yup schema shape based on the quota items */
  const shape = useMemo(() => {
    if (!quota.quota) {
      return null
    }
    const result = {}
    for (const item of quota.quota) {
      result[item.slug] = yup.number()
    }
    result.description = yup.string().required('Use-case is required')
    return result
  }, [quota.quota])

  const schema = useMemo(() => {
    return yup.object().shape(shape)
  }, [shape])

  /* Build Formik initial values based on the quota items */
  const initialValues = useMemo(() => {
    if (!quota.quota) {
      return null
    }
    const result = {}
    for (const key in shape) {
      const current = quota.quota.find((q) => q.slug === key)
      if (current) {
        result[key] = current.quota
      } else {
        result[key] = 0
      }
    }
    result.description = ''
    return result
  }, [shape, quota.quota])

  useEffect(() => {
    setIsDisabled(
      !isUserValidToCreateResources({
        account: serialize(account),
        billing,
        quota,
        isVip
      })
    )
  }, [account, quota, billing, isVip])

  const handleSubmit = useCallback(
    async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      try {
        const data = {
          description: values.description,
          quota: []
        }
        for (const key in values) {
          if (key === 'description') {
            continue
          }
          const current = quota.quota.find((q) => q.slug === key)
          if (current && values[key] !== current.quota) {
            data.quota.push({
              slug: key,
              new: values[key],
              name: current.name,
              current: current.quota
            })
          }
        }
        if (orgId) {
          await OrgQuota.submit({ orgId, data })
        } else {
          await AccountQuota.submit({ data })
        }
        await mutate('/quota')
        await mutate(`/quota?org=${orgId}`)
        resetForm()
        toast({
          status: 'success',
          message: toastMessages.quotaUpdateSuccess
        })
      } catch {
        toast({
          status: 'error',
          message: toastMessages.quotaUpdateError
        })
      } finally {
        setSubmitting(false)
      }
    },
    []
  )

  const handleValidate = useCallback(
    (values) => {
      const errors = {}
      if (!values.description) {
        errors.description = 'Use-case is required.'
      }
      if (!quotaChanged(values)) {
        errors.quota = 'At least one quota item should be increased.'
      }
      return errors
    },
    [quota.quota]
  )

  const quotaChanged = useCallback(
    (values) => {
      for (const key in values) {
        const current = quota.quota.find((q) => q.slug === key)
        if (current && current.quota !== values[key]) {
          return true
        }
      }
      return false
    },
    [quota.quota]
  )

  if (!schema || !initialValues) {
    return null
  }

  return (
    <Card hasError={isDisabled}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={handleValidate}
        validationSchema={schema}
      >
        {({ handleSubmit, isSubmitting, errors }) => (
          <FormikForm onSubmit={handleSubmit}>
            <Stack spacing={8}>
              <TableContainer>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>Quota</Th>
                      <Th isNumeric>Profile usage </Th>
                      <Th isNumeric>Current quota</Th>
                      <Th isNumeric>Requested quota</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {quota.quota.map((item, index) => {
                      // NOTE: If an instance type needs to be unavailable, we should pass a boolean from the product-config repo
                      return (
                        <Tr key={index}>
                          <Td>{item.name}</Td>
                          <Td isNumeric>{item.count}</Td>
                          <Td isNumeric>
                            {item.quota} {item.unit || ''}
                          </Td>
                          <Td isNumeric>
                            <Field name={item.slug}>
                              {({ field, form }) => (
                                <FormControl
                                  isInvalid={form.errors[`${item.slug}`]}
                                >
                                  <NumberInput
                                    value={field.value}
                                    min={initialValues[field.name]}
                                    isDisabled={isSubmitting || isDisabled}
                                    onChange={(value) => {
                                      const number = Math.trunc(Number(value))
                                      if (
                                        number !== null &&
                                        number !== undefined &&
                                        !isNaN(number)
                                      ) {
                                        form.setFieldValue(field.name, number)
                                      }
                                    }}
                                  >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                      <NumberIncrementStepper />
                                      <NumberDecrementStepper />
                                    </NumberInputStepper>
                                  </NumberInput>
                                </FormControl>
                              )}
                            </Field>
                          </Td>
                        </Tr>
                      )
                    })}
                  </Tbody>
                </Table>
                {errors.quota && (
                  <Text mt={2} color="red.500">
                    {errors.quota}
                  </Text>
                )}
              </TableContainer>
              <Field name="description">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.description && form.touched.description
                    }
                  >
                    <FormLabel>
                      <Asterisk />
                      Use-case
                    </FormLabel>
                    <Textarea
                      {...field}
                      isDisabled={isSubmitting || isDisabled}
                      placeholder="Please describe your use-case"
                      borderColor="gray.300"
                    />
                    <FormErrorMessage>
                      {form.errors.description}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Box alignSelf="self-end" textAlign="right" maxW="680px">
                <ValidationErrors
                  account={serialize(account)}
                  billing={billing}
                  quota={quota}
                  isVip={isVip}
                />
              </Box>
              <Button
                type="submit"
                alignSelf="end"
                w="160px"
                isDisabled={isSubmitting || isDisabled}
                isLoading={isSubmitting}
              >
                Send request
              </Button>
            </Stack>
          </FormikForm>
        )}
      </Formik>
    </Card>
  )
}

export default Form
