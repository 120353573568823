import { Flex, Heading } from '@chakra-ui/react'
import React from 'react'
import { useParams } from 'react-router-dom'
import routes from '../../../constants/routes'
import { Credits } from '../../shared/Credits'
import { Tab, Tabs } from '../../shared/Tabs'
import { useAccountFF, useOrgFF } from '../../shared/hooks'
import {
  useIsRole,
  useOrganizationWithId
} from '../../shared/hooks/organization'

const Organization = ({ items, enableQuotaReservation }) => {
  const { orgId } = useParams()
  const { organization } = useOrganizationWithId(orgId)
  const canAccessBilling = useIsRole(organization, 'ADMIN', 'BILLING_MANAGER')

  if (!organization) {
    return null
  }

  return (
    <>
      <Flex justify="space-between" minH="51px" mb={8}>
        <Heading size="4xl">Organization</Heading>
        {canAccessBilling && <Credits />}
      </Flex>
      <Tabs>
        <Tab url={routes.dashboard.settings}>Settings</Tab>
        <Tab url={routes.dashboard.members}>Members</Tab>
        <Tab url={routes.dashboard.projects}>Projects</Tab>
        <Tab url={routes.dashboard.quota}>Quota</Tab>
        {enableQuotaReservation && (
          <Tab url={routes.dashboard.reservations.index}>Reserved GPUs</Tab>
        )}
        {canAccessBilling && <Tab url={routes.dashboard.billing}>Billing</Tab>}
      </Tabs>
      {items}
    </>
  )
}

const Account = ({ items, enableQuotaReservation }) => (
  <>
    <Flex justify="space-between" minH="51px" mb={8}>
      <Heading size="4xl">Account</Heading>
      <Credits />
    </Flex>
    <Tabs>
      <Tab url={routes.dashboard.quota}>Quota</Tab>
      {enableQuotaReservation && (
        <Tab url={routes.dashboard.reservations.index}>Reserved GPUs</Tab>
      )}
      <Tab url={routes.dashboard.billing}>Billing</Tab>
    </Tabs>
    {items}
  </>
)

const Profile = ({ children }) => {
  const { orgId } = useParams()
  const { featureFlag } = orgId ? useOrgFF({ orgId }) : useAccountFF({})
  const { enableQuotaReservation } = featureFlag || {}

  return orgId ? (
    <Organization
      items={children}
      enableQuotaReservation={enableQuotaReservation}
    />
  ) : (
    <Account items={children} enableQuotaReservation={enableQuotaReservation} />
  )
}

export default Profile
