import { useEffect, useState } from 'react'
import { Stack, Heading, Skeleton } from '@chakra-ui/react'
import {
  ProfileCard,
  SigninCard,
  TFACard,
  PhoneCard,
  DeleteCard
} from './cards'
import { useAccount } from '../../../../shared/hooks/account'
import SupportError from '../../../../Errors/SupportError'

const Loading = () => (
  <Stack spacing={8}>
    <Skeleton h="280px" />
    <Skeleton h="210px" />
    <Skeleton h="150px" />
    <Skeleton h="130px" />
    <Skeleton h="160px" />
  </Stack>
)

const Error = () => (
  <SupportError>
    {`Something went wrong loading the account settings.`}
  </SupportError>
)

const AccountSettings = () => {
  const [tfaEnabled, setTfaEnabled] = useState(false)
  const [gcSignin, setGcSignin] = useState(false)
  const [googleSignin, setGoogleSignin] = useState(false)
  const [githubSignin, setGithubSignin] = useState(false)

  const { account, isLoadingAccount, isErrorAccount } = useAccount()
  const isDeletable = account?.state?.deletable
  const tfaShow = !account?.federated

  useEffect(() => {
    if (account) {
      const { mfa_mechanisms: mfa, federated } = account

      setTfaEnabled(!!mfa?.includes('totp'))

      if (federated?.includes('google')) {
        setGoogleSignin(true)
      } else if (federated?.includes('github')) {
        setGithubSignin(true)
      } else {
        setGcSignin(true)
      }
    }
  }, [account])

  return (
    <Stack spacing={8}>
      <Heading as="h1" size="4xl">
        Account Settings
      </Heading>
      {isLoadingAccount ? (
        <Loading />
      ) : isErrorAccount ? (
        <Error />
      ) : (
        <>
          <ProfileCard account={account} />
          <SigninCard
            gcSignin={gcSignin}
            googleSignin={googleSignin}
            githubSignin={githubSignin}
          />
          {tfaShow && <TFACard tfaEnabled={tfaEnabled} />}
          <PhoneCard account={account} />
          <DeleteCard isDeletable={isDeletable} />
        </>
      )}
    </Stack>
  )
}

export default AccountSettings
