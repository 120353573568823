import { Stack, Heading } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { useHasWriteProjectPermissions } from '../../shared/hooks'
import SSHKeys from './ssh-keys'
import APITokens from './api-tokens'

const Security = () => {
  const { orgId, projectId } = useParams()
  const hasWriteProjectPermissions = orgId
    ? useHasWriteProjectPermissions({
        projectId,
        orgId
      })
    : true

  return (
    <Stack spacing={8}>
      <Heading as="h1" size="4xl">
        Keys & Tokens
      </Heading>
      <SSHKeys hasPermission={hasWriteProjectPermissions} />
      <APITokens hasPermission={hasWriteProjectPermissions} />
    </Stack>
  )
}

export default Security
