import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Skeleton,
  Stack,
  Text,
  Textarea
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import * as yup from 'yup'
import {
  AccountFloatingIPs,
  OrgFloatingIPs
} from '../../../../../api/floatingIPs'
import { DEFAULT_REGION } from '../../../../../constants/defaults'
import { IPV4 } from '../../../../../constants/floating-ip'
import { toastMessages } from '../../../../../constants/toast-messages'
import { calcResourcesPrices } from '../../../../../helpers/billing'
import { formatCurrency } from '../../../../../helpers/currency'
import { apiErrorHandler } from '../../../../shared/ApiErrorHandler'
import Asterisk from '../../../../shared/Asterisk'
import Price from '../../../../shared/Price'
import { Error } from '../../../../shared/Error'
import { InputField } from '../../../../shared/Fields'
import { RegionCards } from '../../../../shared/RadioCard'
import { useToast } from '../../../../shared/Toast'
import { useAccountFF, useConfigs, useOrgFF } from '../../../../shared/hooks'

const initialValues = {
  region: DEFAULT_REGION,
  name: '',
  description: ''
}

const validationSchema = yup.object().shape({
  region: yup.string().required('Floating IP region is required'),
  name: yup.string().required('Floating IP name is required'),
  description: yup.string()
})

const PriceSection = () => {
  const { configs, isLoadingConfigs, isErrorConfigs } = useConfigs({})

  if (isLoadingConfigs || isErrorConfigs) {
    return null
  }

  const { price, discount, totalPrice } = calcResourcesPrices({
    types: configs?.floatingIpTypes,
    typeSlug: 'ipv4',
    configSlug: 'ipv4_floating_ip'
  })

  const items = [
    { label: 'Price', value: `${formatCurrency(price)}/month` },
    ...(discount ? [{ label: 'Discount', value: `${discount}% off` }] : []),
    {
      label: 'Total cost (excl. VAT)',
      value: `${formatCurrency(totalPrice)}/month`
    }
  ]

  return <Price items={items} />
}

export const CreateModal = ({ disclosure }) => {
  const { orgId, projectId } = useParams()
  const { isOpen, onClose } = disclosure
  const { regions, isLoadingConfigs, isErrorConfigs } = useConfigs({})
  const toast = useToast()
  const { featureFlag } = orgId
    ? useOrgFF({ orgId, shouldFetch: isOpen })
    : useAccountFF({ shouldFetch: isOpen })

  const handleSubmit = useCallback(
    async ({ region, name, description }, { setSubmitting }) => {
      const payload = {
        region,
        name,
        version: IPV4
      }
      if (description) {
        payload.description = description
      }
      setSubmitting(true)
      try {
        if (orgId) {
          await OrgFloatingIPs.create({
            orgId,
            projectId,
            payload
          })
          await mutate((key) =>
            key.startsWith(`/projects/${projectId}/floating-ips?`)
          )
        } else {
          await AccountFloatingIPs.create({ payload })
          await mutate((key) => key.startsWith(`/floating-ips?`))
        }
        toast({
          status: 'success',
          message: toastMessages.floatingIPCreateSuccess
        })
        onClose()
      } catch (error) {
        const message = await apiErrorHandler(error)
        toast({
          status: 'error',
          message
        })
      } finally {
        setSubmitting(false)
      }
    },
    [orgId, projectId]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values, errors, touched, isSubmitting }) => (
              <Stack as={Form} spacing={8} w="full">
                <Heading size="xl" textAlign="center">
                  Create Floating IP
                </Heading>
                {isLoadingConfigs ? (
                  <Skeleton h="120px" />
                ) : isErrorConfigs ? (
                  <Error />
                ) : (
                  <FormControl isInvalid={errors.region && touched.region}>
                    <FormLabel>
                      <Asterisk />
                      Select location
                    </FormLabel>
                    <RegionCards
                      name="region"
                      lgScreenSize={'repeat(3, 1fr)'}
                      activeRegion={values.region}
                      regions={regions}
                      onChange={(value) => setFieldValue('region', value)}
                    />
                    <FormErrorMessage>{errors.region}</FormErrorMessage>
                  </FormControl>
                )}
                <FormControl isInvalid={errors.name}>
                  <InputField
                    label="Name"
                    name="name"
                    placeholder="Enter floating IP name"
                    isRequired
                  />
                </FormControl>
                <FormControl isInvalid={errors.description}>
                  <FormLabel>Description</FormLabel>
                  <Field
                    as={Textarea}
                    placeholder="Enter floating IP description"
                    name="description"
                    type="text"
                    rows="3"
                  />
                  <FormErrorMessage>{errors.description}</FormErrorMessage>
                </FormControl>
                {featureFlag?.enableExtendedBilling && (
                  <>
                    <PriceSection />
                    <Text
                      size="md"
                      textAlign="center"
                      color="gray.500"
                      fontWeight="normal"
                    >
                      Billed on-demand once the Floating IP is created and until
                      the Floating is deleted.
                    </Text>
                  </>
                )}
                <Stack spacing={4}>
                  <Button
                    type="submit"
                    isDisabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    Save
                  </Button>
                  <Button
                    colorScheme="offBlack"
                    variant="outline"
                    isDisabled={isSubmitting}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Stack>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
