import useSWR from 'swr'
import { Account } from '../../../api/account'
import { Organization } from '../../../api/organizations'
import { serialize } from '../../../helpers/serializers'

export const useOrgFF = ({ orgId, shouldFetch = true }) => {
  const key = shouldFetch ? `/organizations/${orgId}/ff` : null
  const fetcher = () => Organization.fetchFF({ orgId })

  const { data, isLoading, error } = useSWR(key, fetcher)

  return {
    featureFlag: serialize(data),
    isLoading,
    isError: Boolean(error)
  }
}

export const useAccountFF = ({ shouldFetch = true }) => {
  const key = shouldFetch ? '/ff' : null
  const fetcher = () => Account.fetchFF()

  const { data, isLoading, error } = useSWR(key, fetcher)

  return {
    featureFlag: serialize(data),
    isLoading,
    isError: Boolean(error)
  }
}
