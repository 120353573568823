import {
  Center,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Td,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'
import routes from '../../../../constants/routes'
import { formatDateTime } from '../../../../helpers/date'
import { status } from '../../../../helpers/volumes'
import { withOrgPathFallback } from '../../../../router'
import { Bin, Dots, Settings } from '../../../shared/Icons'
import { ErrorBadge, PendingDeleteBadge } from '../../../shared/Badge'
import Region from '../../../shared/Region'
import Spinner from '../../../shared/Spinner'
import { TdTruncated } from '../../../shared/Table'
import { DeleteModal } from '../modals'

const TableRow = ({ row, hasPermission }) => {
  const {
    id,
    name,
    attachments,
    status: rowStatus,
    region,
    description,
    type,
    size,
    createdAt
  } = row
  const disclosure = useDisclosure()

  const isAttached = attachments.length > 0
  const isVisible = rowStatus === status.created || rowStatus === status.error
  const isLoading =
    rowStatus === status.creating || rowStatus === status.deleting
  const isPendingDelete = rowStatus === status.pendingDelete
  const isError = rowStatus === status.error
  const canBeDeleted = !isAttached && hasPermission

  const link = withOrgPathFallback(
    routes.dashboard.volumes.view.replace(':volumeId', id)
  )

  return (
    <Tr>
      <TdTruncated data-hj-suppress>
        <Link colorScheme="gray" as={ReactLink} to={link}>
          {name}
        </Link>
      </TdTruncated>
      <Td>
        <Region slug={region} />
      </Td>
      <TdTruncated data-hj-suppress color="gray.500">
        {description || '-'}
      </TdTruncated>
      <Td>{type?.toUpperCase()}</Td>
      <Td>{size}GiB</Td>
      <Td>{formatDateTime(createdAt)}</Td>
      <Td>
        {isLoading && (
          <Center>
            <Spinner color="gray.500" />
          </Center>
        )}
        {isPendingDelete && <PendingDeleteBadge />}
        {isError && <ErrorBadge />}
        {isVisible && (
          <Menu variant="table" autoSelect={false}>
            <MenuButton variant="icon" as={IconButton} icon={<Dots />} />
            <MenuList>
              <MenuItem
                as={ReactLink}
                to={link}
                _hover={{ textDecoration: 'none', color: 'black' }}
              >
                <Settings mr={2.5} />
                Volume settings
              </MenuItem>
              {canBeDeleted && (
                <>
                  <MenuDivider />
                  <MenuItem onClick={disclosure.onOpen}>
                    <Bin mr={2.5} />
                    Delete
                  </MenuItem>
                  <DeleteModal disclosure={disclosure} volume={row} />
                </>
              )}
            </MenuList>
          </Menu>
        )}
      </Td>
    </Tr>
  )
}

export default TableRow
