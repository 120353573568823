import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useSWR from 'swr'
import { accountApiRequest } from '../../api'
import routes from '../../constants/routes'
import ResponsiveContainer from '../shared/Layout/ResponsiveContainer'
import Spinner from '../shared/Spinner'
import { Step, Steps } from '../shared/Steps'
import Error from './Error'
import { Center } from '@chakra-ui/react'

const ENVIRONMENT = process.env.REACT_APP_ENV

const OnboardingLayout = ({ children }) => {
  const history = useHistory()
  const user = useSelector((state) => state.oidc.user)
  const { data: account, error } = useSWR(
    user ? '/account' : null,
    accountApiRequest
  )

  if (error) return <Error />
  if (!error && !account)
    return (
      <Center>
        <Spinner />
      </Center>
    )

  if (ENVIRONMENT === 'production' && account?.onboarded_at) {
    history.push(routes.dashboard.index)
    return null
  }

  return (
    <>
      <Steps labelColor="white">
        <Step path={routes.onboarding.setup}>Set up profile *</Step>
        <Step path={routes.onboarding.details}>Profile details</Step>
        <Step path={routes.onboarding.payment}>Payment method</Step>
        <Step path={routes.onboarding.billing}>Billing details *</Step>
        <Step path={routes.onboarding.phoneVerification}>
          Phone verification
        </Step>
      </Steps>
      <ResponsiveContainer logo={true}>{children}</ResponsiveContainer>
    </>
  )
}

export default OnboardingLayout
