import {
  Stack,
  Text,
  FormControl,
  FormErrorMessage,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper
} from '@chakra-ui/react'
import { useField } from 'formik'
import { Select } from 'chakra-react-select'
import { SelectComponents } from '../../../../../theme/components/Select'
import { DefaultBadge } from '../../../../shared/Badge'
import CreditCardIcon from '../../../../shared/CreditCardIcon'

export const BalanceField = ({ name, label, ...props }) => {
  const [field, meta, helpers] = useField(name)

  const onChange = (_, valueAsNumber) => helpers.setValue(valueAsNumber)

  return (
    <Stack spacing={2}>
      <Text color="gray.500">{label}</Text>
      <FormControl isInvalid={meta.error && meta.touched}>
        <HStack>
          <NumberInput
            {...field}
            onChange={onChange}
            inputMode="numeric"
            size="sm"
            min={0}
            step={10}
            allowMouseWheel
            {...props}
          >
            <NumberInputField w={20} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Text size="sm">USD</Text>
        </HStack>
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      </FormControl>
    </Stack>
  )
}

export const MethodField = ({ name, label, methods, ...props }) => {
  const [field, meta, helpers] = useField(name)

  const options = methods.filter(({ isExpired }) => !isExpired)

  const getOptionValue = (option) => option.id

  const getOptionLabel = (option) => (
    <HStack spacing={3}>
      <CreditCardIcon brand={option.brand} />
      <span>xxxx-{option.last4}</span>
      {option.isDefault && <DefaultBadge />}
    </HStack>
  )

  const handleSelect = (option) => helpers.setValue(option)

  return (
    <Stack spacing={2}>
      <Text color="gray.500">{label}</Text>
      <FormControl isInvalid={meta.touched && meta.error}>
        <Select
          isSearchable={false}
          chakraStyles={SelectComponents}
          options={options}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          value={field.value}
          onChange={handleSelect}
          {...props}
        />
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      </FormControl>
    </Stack>
  )
}
