import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Textarea
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import * as yup from 'yup'
import {
  AccountSecurityGroups,
  OrgSecurityGroups
} from '../../../../../api/securityGroups'
import { toastMessages } from '../../../../../constants/toast-messages'
import Asterisk from '../../../../shared/Asterisk'
import { useToast } from '../../../../shared/Toast'

const validationSchema = yup.object().shape({
  name: yup.string().required("Security Group's name is required"),
  description: yup.string()
})

export const RenameModal = ({ disclosure, securityGroup }) => {
  const { projectId, orgId } = useParams()
  const { isOpen, onClose } = disclosure
  const toast = useToast()

  const initialValues = {
    name: securityGroup?.name,
    description: securityGroup?.description
  }

  const onSubmit = async (data, { setSubmitting }) => {
    const { id: securityGroupId } = securityGroup
    setSubmitting(true)

    try {
      if (orgId) {
        await OrgSecurityGroups.updateById({
          orgId,
          projectId,
          securityGroupId,
          data
        })
        await mutate(
          `/projects/${projectId}/security-groups/${securityGroupId}?org=${orgId}`
        )
      } else {
        await AccountSecurityGroups.updateById({
          securityGroupId,
          data
        })
        await mutate(`/security-groups/${securityGroupId}`)
      }
      toast({
        status: 'success',
        message: toastMessages.saved
      })
      onClose()
    } catch {
      toast({
        status: 'error',
        message: toastMessages.notSaved
      })
    } finally {
      setSubmitting(false)
    }
  }

  const { values, handleChange, errors, handleSubmit, isSubmitting } =
    useFormik({
      validationSchema,
      initialValues,
      onSubmit
    })

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap="8" as="form" onSubmit={handleSubmit}>
            <Heading size="xl" textAlign="center">
              Rename Security Group
            </Heading>
            <FormControl isInvalid={errors.name}>
              <FormLabel>
                <Asterisk />
                Name
              </FormLabel>
              <Input name="name" value={values.name} onChange={handleChange} />
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.description}>
              <FormLabel>Description</FormLabel>
              <Textarea
                placeholder="Enter Security Group description"
                name="description"
                value={values.description}
                onChange={handleChange}
              />
              <FormErrorMessage>{errors.description}</FormErrorMessage>
            </FormControl>
            <Stack spacing={4}>
              <Button type="submit" isLoading={isSubmitting}>
                Save
              </Button>
              <Button
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isSubmitting}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Stack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
