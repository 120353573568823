import { Flex, Heading, Stack, Switch, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import { AccountInstances, OrgInstances } from '../../../../api/instances'
import { toastMessages } from '../../../../constants/toast-messages'
import { Card } from '../../../shared/Cards'
import { useToast } from '../../../shared/Toast'

const Termination = ({ instance }) => {
  const { orgId, projectId } = useParams()
  const toast = useToast()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { isProtected, id: instanceId } = instance

  const handleSwitchChange = async (event) => {
    const isProtected = event.target.checked
    const payload = { is_protected: isProtected }
    setIsSubmitting(true)

    try {
      if (orgId) {
        await OrgInstances.updateById({
          orgId,
          projectId,
          instanceId,
          payload
        })
        await mutate(
          `/projects/${projectId}/instances/${instanceId}?org=${orgId}`
        )
      } else {
        await AccountInstances.updateById({
          instanceId,
          payload
        })
        await mutate(`/instances/${instanceId}`)
      }
      toast({
        status: 'success',
        message: toastMessages.saved
      })
    } catch {
      toast({
        status: 'error',
        message: toastMessages.notSaved
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Card as={Stack} spacing={2}>
      <Flex justify="space-between">
        <Heading size="xl">Termination protection</Heading>
        <Switch
          size="lg"
          mb={0}
          isDisabled={isSubmitting}
          isChecked={isProtected}
          onChange={handleSwitchChange}
        />
      </Flex>
      <Text color="gray.500">
        Termination protection prevents an <br /> Instance from accidental
        termination.
      </Text>
    </Card>
  )
}

export default Termination
