import {
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useRadioGroup
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { isEmpty } from '../../../../../helpers/billing'
import { RadioSubscription } from './RadioSubscription'

const DEFAULT_TAB_INDEX = 1

const NoData = () => (
  <Text color="gray.500">There are no Existing Subscriptions.</Text>
)

const LongTerm = ({ newSubs, exSubs, value, onChange, credits, discount }) => {
  const [tabIndex, setTabIndex] = useState(1)
  const { values, setValues } = useFormikContext()
  const { getRootProps, getRadioProps } = useRadioGroup({
    value,
    onChange
  })
  const subscriptionsByType = exSubs?.filter(
    (item) => item.type === values?.typeConfigSlug
  )
  const noData = isEmpty(exSubs) || isEmpty(subscriptionsByType)

  useEffect(() => {
    if (noData) {
      const billing = {
        type: newSubs[0]?.slug,
        credits,
        discount,
        ...newSubs[0]
      }
      setTabIndex(DEFAULT_TAB_INDEX)
      setValues({ ...values, billing })
    }
  }, [noData])

  const onChangeTab = (index) => {
    const selected = index === 0 ? exSubs : newSubs
    const billing = {
      type: selected[0].slug,
      credits,
      discount,
      ...selected[0]
    }
    setTabIndex(index)
    setValues({ ...values, billing })
  }

  return (
    <Tabs
      variant="rounded"
      colorScheme="gray"
      defaultIndex={DEFAULT_TAB_INDEX}
      index={tabIndex}
      onChange={onChangeTab}
      isLazy
    >
      <TabList>
        <Tab isDisabled={noData}>Existing</Tab>
        <Tab>New</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          {noData ? (
            <NoData />
          ) : (
            <Stack spacing={4} {...getRootProps()}>
              {exSubs.map((sub) => (
                <RadioSubscription
                  key={sub.id}
                  {...getRadioProps({
                    value: sub.id,
                    sub
                  })}
                />
              ))}
            </Stack>
          )}
        </TabPanel>
        <TabPanel>
          <Stack spacing={4} {...getRootProps()}>
            {newSubs.map((sub) => (
              <RadioSubscription
                key={sub.id}
                {...getRadioProps({
                  value: sub.id,
                  sub: { ...sub, credits, discount }
                })}
              />
            ))}
          </Stack>
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default LongTerm
