import { useSelector } from 'react-redux'

const useSuspended = () => {
  const isAccountSuspended = useSelector(
    (state) => state.dashboard.isAccountSuspended
  )
  const isOrgSuspended = useSelector((state) => state.dashboard.isOrgSuspended)
  return { isSuspended: isAccountSuspended || isOrgSuspended }
}

export default useSuspended
