import { Collapse, Flex, Heading, Stack, useDisclosure } from '@chakra-ui/react'
import { none, rotated } from '../../../../../constants/styles'
import { Card } from '../../../../shared/Cards'
import { ChevronDown } from '../../../../shared/Icons'
import ChartSection from './chart-section'
import { storages } from './utils'

const Storage = () => {
  const { isOpen, onToggle } = useDisclosure()

  return (
    <Card>
      <Flex align="flex-start">
        <Heading size="xl" cursor="pointer" w="60%" onClick={onToggle}>
          Storage usage
          <ChevronDown boxSize={6} ml={3} transform={isOpen ? rotated : none} />
        </Heading>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <Stack spacing={8} mt={8}>
          {storages.map((storage) => (
            <ChartSection key={storage.type} data={storage} />
          ))}
        </Stack>
      </Collapse>
    </Card>
  )
}

export default Storage
