import useSWR from 'swr'
import { serialize } from '../../../helpers/serializers'

const STATUS_PAGE_URL = process.env.REACT_APP_STATUS_PAGE_URL

const calcTime = () => Math.floor(new Date().getTime() / 60000)

const fetcher = (url) => fetch(url).then((r) => r.json())

export const useIncidents = () => {
  const key = `${STATUS_PAGE_URL}/incidents/unresolved.json?v=${calcTime()}`

  const { data, isLoading, error } = useSWR(key, fetcher)

  let incidents

  if (data) ({ incidents } = serialize(data))

  return {
    incidents,
    isLoadingIncidents: isLoading,
    isErrorIncidents: Boolean(error)
  }
}

export const useMaintenances = ({ type }) => {
  const key = `${STATUS_PAGE_URL}/scheduled-maintenances/${type}.json?v=${calcTime()}`

  const { data, isLoading, error } = useSWR(key, fetcher)

  let scheduledMaintenances

  if (data) ({ scheduledMaintenances } = serialize(data))

  return {
    maintenances: scheduledMaintenances,
    isLoadingMaintenances: isLoading,
    isErrorMaintenances: Boolean(error)
  }
}
