import { Button, Link, Td, Tr } from '@chakra-ui/react'
import { formatCurrency } from '../../../../../helpers/currency'
import { formatDate } from '../../../../../helpers/date'
import {
  ErrorBadge,
  FailedBadge,
  PaidBadge,
  PendingBadge
} from '../../../../shared/Badge'
import { PDF } from '../../../../shared/Icons'

const getBadge = (status, attempted) => {
  let badge = <ErrorBadge />

  if (status === 'paid') {
    badge = <PaidBadge />
  } else if (status === 'open') {
    badge = attempted ? <FailedBadge /> : <PendingBadge />
  }
  return badge
}

const Payment = ({ status, url }) =>
  status === 'open' ? (
    <Td>
      <Button as={Link} size="sm" colorScheme="red" isExternal href={url}>
        Pay now
      </Button>
    </Td>
  ) : (
    <Td />
  )

const TableRow = ({ invoice, hasPermission }) => {
  const {
    created,
    number,
    total,
    invoicePdf,
    status,
    attempted,
    hostedInvoiceUrl,
    dueDate
  } = invoice

  const createdDate = formatDate(created * 1000)
  const amount = formatCurrency(total / 100)
  const dueDateFormatted = formatDate(dueDate * 1000)

  return (
    <Tr>
      <Td>{number}</Td>
      <Td>{createdDate}</Td>
      <Td>{amount}</Td>
      <Td>{dueDateFormatted || '-'}</Td>
      <Td>{getBadge(status, attempted)}</Td>
      <Td>
        <Link colorScheme="gray" isExternal href={invoicePdf}>
          <PDF />
        </Link>
      </Td>
      {hasPermission ? (
        <Payment status={status} url={hostedInvoiceUrl} />
      ) : null}
    </Tr>
  )
}

export default TableRow
