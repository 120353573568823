import {
  Box,
  Divider,
  Flex,
  HStack,
  LinkBox,
  LinkOverlay,
  MenuButton,
  Menu as MenuChakra,
  MenuItem,
  MenuList,
  Skeleton,
  Spacer,
  Text
} from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'
import routes from '../../../../constants/routes'
import { getProjectParam, withOrgPathFallback } from '../../../../router'
import { WarningError } from '../../../Errors'
import { DefaultBadge } from '../../../shared/Badge'
import { ArrowDown, ArrowSwitch } from '../../../shared/Icons'
import { useProjects } from '../../../shared/hooks/project'
import { useSwitchProject } from '../../../shared/hooks/project/useSwitchProject'
import { useOrgParam } from '../../../shared/hooks/useOrgParam'

const ProjectTitle = ({ project }) => (
  <Text noOfLines={1} maxWidth={project?.isDefault ? 24 : 48}>
    {project?.name}
  </Text>
)

const SwitchProject = () => {
  const switchProject = useSwitchProject()
  const orgId = useOrgParam()
  const projectId = getProjectParam()
  const { projects, isError, isLoading } = useProjects({
    orgId,
    perPage: 50
  })

  const activeProject =
    projects?.projects.find((project) => project.id === projectId) ?? ''
  const restProjects =
    projects?.projects.filter((project) => project.id !== projectId) ?? []

  if (isError) return <WarningError>Unable to load the data</WarningError>
  if (isLoading) return <Skeleton h={3.5} w={56} />
  if (!activeProject) return null

  return (
    <MenuChakra variant="topbar" autoSelect={false}>
      {({ isOpen }) => (
        <>
          <MenuButton>
            <Flex
              justify="space-between"
              align="center"
              h={10}
              w="220px"
              px={4}
              borderRadius="20px"
              border="1px solid"
              borderColor="gray.300"
            >
              <HStack>
                <ProjectTitle project={activeProject} />
                {activeProject?.isDefault && <DefaultBadge />}
              </HStack>
              <ArrowDown transform={isOpen ? 'rotate(180deg)' : 'none'} />
            </Flex>
          </MenuButton>
          <MenuList zIndex="10" maxW={200}>
            <MenuItem>
              <Box px={4} py={1}>
                <Spacer />
                <ProjectTitle project={activeProject} />
              </Box>
            </MenuItem>
            <MenuItem>
              <LinkBox>
                <Spacer />
                <LinkOverlay
                  as={ReactLink}
                  to={withOrgPathFallback(routes.dashboard.projectSettings)}
                >
                  Project settings
                </LinkOverlay>
              </LinkBox>
            </MenuItem>
            {restProjects.length > 0 && (
              <>
                <Divider my={1} />
                <Box color="gray.300" px={4} py={1}>
                  <ArrowSwitch mr={3} />
                  Switch to
                </Box>
                {restProjects?.map((project) => (
                  <MenuItem key={project.id}>
                    <LinkBox>
                      <Spacer />
                      <LinkOverlay onClick={() => switchProject(project.id)}>
                        <HStack>
                          <ProjectTitle project={project} />
                          {project?.isDefault && <DefaultBadge ml={2} />}
                        </HStack>
                      </LinkOverlay>
                    </LinkBox>
                  </MenuItem>
                ))}
              </>
            )}
          </MenuList>
        </>
      )}
    </MenuChakra>
  )
}

export default SwitchProject
