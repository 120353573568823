import {
  Stack,
  FormControl,
  FormErrorMessage,
  Heading,
  Skeleton,
  Text,
  Link
} from '@chakra-ui/react'
import { Error } from '../Error'
import { useField } from 'formik'
import { externalLinks } from '../../../helpers/links'
import { RegionCards } from '../RadioCard'
import { useConfigs } from '../hooks/configs'

export const RegionField = ({ name, onUpdate, regions }) => {
  const [field, meta, helpers] = useField(name)
  const {
    regions: configRegions,
    isValidatingConfigs,
    isErrorConfigs
  } = useConfigs({ shouldFetch: !regions })

  return (
    <Stack spacing={8}>
      <Stack spacing={1}>
        <Heading size="xl">Select Location</Heading>
        <Text color="gray.500" size="sm">
          A location refers to the{' '}
          <Link
            href={externalLinks.regions}
            isExternal
            colorScheme="blue"
            variant="underlined"
          >
            geographical location
          </Link>{' '}
          in which your instance will be created. Please be aware that it is not
          possible to mix compute resources from different location.
        </Text>
      </Stack>
      {isValidatingConfigs ? (
        <Skeleton h="120px" />
      ) : isErrorConfigs ? (
        <Error />
      ) : (
        <FormControl isInvalid={meta.error && meta.touched}>
          <RegionCards
            {...field}
            activeRegion={field.value}
            regions={regions || configRegions}
            onChange={(value) => {
              helpers.setValue(value)
              if (onUpdate) onUpdate(value)
            }}
          />
          <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
      )}
    </Stack>
  )
}
