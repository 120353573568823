import {
  Box,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  Td,
  Text,
  Tr
} from '@chakra-ui/react'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import { Project } from '../../../../../../../api/projects'
import { toastMessages } from '../../../../../../../constants/toast-messages'
import { formatDateTime } from '../../../../../../../helpers/date'
import { humanize } from '../../../../../../../helpers/string'
import { ArrowDown, Bin, Dots } from '../../../../../../shared/Icons'
import { mapBackendRole } from '../../../members/utils'
import { useToast } from '../../../../../../shared/Toast'
import { useOrganizationWithId } from '../../../../../../shared/hooks/organization'
import { useAccount } from '../../../../../../shared/hooks/account'
import {
  projectPermissions,
  useIsAdmin,
  useProject
} from '../../../../../../shared/hooks/project'

const TableRow = ({ member }) => {
  const { projectId, orgId } = useParams()
  const { project } = useProject({ orgId, projectId })
  const { account } = useAccount()
  const accountIsAdmin = useIsAdmin(project?.userPermission)
  const memberIsAdmin = account?.accountId === member.account.id
  const { organization } = useOrganizationWithId(orgId)
  const memberIsOwner = organization?.ownerId === member.account.id
  const toast = useToast()

  const handleRemoveMemberClick = useCallback(
    async (memberId) => {
      try {
        await Project.deleteMember({
          orgId,
          projectId,
          memberId
        })
        await mutate((key) =>
          key.startsWith(
            `/organizations/${orgId}/projects/${projectId}/members?`
          )
        )
        toast({
          status: 'success',
          message: toastMessages.memberDeleteSuccess
        })
      } catch {
        toast({
          status: 'error',
          message: toastMessages.memberDeleteError
        })
      }
    },
    [member]
  )

  const handlePermissionClick = useCallback(
    async (role, memberId) => {
      try {
        await Project.updateMember({
          orgId,
          projectId,
          memberId,
          data: { role }
        })
        await mutate((key) =>
          key.startsWith(
            `/organizations/${orgId}/projects/${projectId}/members?`
          )
        )
        toast({
          status: 'success',
          message: toastMessages.memberUpdateSuccess
        })
      } catch {
        toast({
          status: 'error',
          message: toastMessages.memberUpdateError
        })
      }
    },
    [member]
  )

  return (
    <Tr>
      <Td>
        <Stack spacing={0}>
          <Text color="gray.500">{member.account.name}</Text>
          <Text color="gray.400">{member.account.email}</Text>
        </Stack>
      </Td>
      <Td>
        {humanize(mapBackendRole(member.organization.role, memberIsOwner))}
      </Td>
      {accountIsAdmin && !memberIsAdmin ? (
        <Td>
          <Menu variant="table" autoSelect={false}>
            {({ isOpen }) => (
              <>
                <MenuButton>
                  <HStack spacing={2}>
                    <Text color="gray.500">{humanize(member.role)}</Text>
                    <ArrowDown transform={isOpen ? 'rotate(180deg)' : 'none'} />
                  </HStack>
                </MenuButton>
                <MenuList minWidth="min-content">
                  {Object.entries(projectPermissions)
                    .filter(([_, value]) => value !== member.role)
                    .map(([_, value]) => (
                      <Box
                        key={value}
                        onClick={() =>
                          handlePermissionClick(value, member.account.id)
                        }
                      >
                        <MenuItem>{humanize(value)}</MenuItem>
                        <MenuDivider />
                      </Box>
                    ))}
                </MenuList>
              </>
            )}
          </Menu>
        </Td>
      ) : (
        <Td>{humanize(member.role)}</Td>
      )}
      <Td>{formatDateTime(member.account.lastLoginAt)}</Td>
      <Td isNumeric>
        {!project?.isDefault && accountIsAdmin && !memberIsAdmin && (
          <Menu variant="table" autoSelect={false}>
            <MenuButton variant="icon" as={IconButton} icon={<Dots />} />
            <MenuList>
              <MenuDivider />
              <MenuItem
                onClick={() => handleRemoveMemberClick(member.account.id)}
              >
                <Bin mr={2.5} />
                Remove member
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </Td>
    </Tr>
  )
}

export default TableRow
