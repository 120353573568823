const Textarea = {
  variants: {
    outline: ({ colorScheme }) => ({
      borderColor: `${colorScheme}.300`,
      _hover: {
        borderColor: `${colorScheme}.400`
      },
      _focus: {
        boxShadow: 'none'
      },
      _invalid: {
        boxShadow: 'none'
      }
    })
  },
  defaultProps: {
    colorScheme: 'gray',
    variant: 'outline'
  }
}

export default Textarea
