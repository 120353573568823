import useSWR from 'swr'
import { AccountFilesystems, OrgFilesystems } from '../../../api/filesystems'
import { swrOptions } from '../../../constants/swr-options'
import { serialize } from '../../../helpers/serializers'
import { INITIAL_PAGE, PER_PAGE_5 } from '../Table'

export const useOrgFilesystems = ({
  orgId,
  projectId,
  page = INITIAL_PAGE,
  perPage = PER_PAGE_5
}) => {
  const key = `/projects/${projectId}/filesystems?org=${orgId}&page=${page}&per_page=${perPage}`
  const fetcher = () =>
    OrgFilesystems.fetchAll({ orgId, projectId, page, perPage })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  let filesystems, pagination
  if (data) ({ filesystems, ...pagination } = serialize(data))

  return {
    filesystems,
    pagination,
    isLoadingFilesystems: isLoading,
    isErrorFilesystems: Boolean(error)
  }
}

export const useOrgFilesystem = ({ orgId, projectId, filesystemId }) => {
  const key = `/projects/${projectId}/filesystems/${filesystemId}?org=${orgId}`
  const fetcher = () =>
    OrgFilesystems.fetchById({ orgId, projectId, filesystemId })

  const { data, isLoading, error } = useSWR(key, fetcher)

  let filesystem
  if (data) ({ filesystem } = serialize(data))

  return {
    filesystem,
    isLoadingFilesystem: isLoading,
    isErrorFilesystem: Boolean(error)
  }
}

export const useAccountFilesystems = ({
  page = INITIAL_PAGE,
  perPage = PER_PAGE_5
}) => {
  const key = `/filesystems?page=${page}&per_page=${perPage}`
  const fetcher = () => AccountFilesystems.fetchAll({ page, perPage })

  const { data, isLoading, error } = useSWR(key, fetcher, swrOptions)

  let filesystems, pagination
  if (data) ({ filesystems, ...pagination } = serialize(data))

  return {
    filesystems,
    pagination,
    isLoadingFilesystems: isLoading,
    isErrorFilesystems: Boolean(error)
  }
}

export const useAccountFilesystem = ({ filesystemId }) => {
  const key = `/filesystems/${filesystemId}`
  const fetcher = () => AccountFilesystems.fetchById({ filesystemId })

  const { data, isLoading, error } = useSWR(key, fetcher)

  let filesystem
  if (data) ({ filesystem } = serialize(data))

  return {
    filesystem,
    isLoadingFilesystem: isLoading,
    isErrorFilesystem: Boolean(error)
  }
}
