import {
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'
import routes from '../../../../../constants/routes'
import { formatDateTime } from '../../../../../helpers/date'
import { status } from '../../../../../helpers/floatingIPs'
import { withOrgPathFallback } from '../../../../../router'
import CopyButton from '../../../../shared/CopyButton'
import { Bin, Dots } from '../../../../shared/Icons'
import Region from '../../../../shared/Region'
import Spinner from '../../../../shared/Spinner'
import { TdTruncatedLines } from '../../../../shared/Table'
import { DeleteModal } from '../modals'

const TableRow = ({ floatingIp, hasPermission }) => {
  const disclosure = useDisclosure()
  const isLoading =
    [status.creating, status.updating, status.deleting].indexOf(
      floatingIp.status
    ) !== -1

  let instanceName, instanceId
  if (floatingIp.instance) {
    instanceName = floatingIp.instance.name
    instanceId = floatingIp.instance.id
  }
  const canBeDeleted =
    hasPermission && !instanceName && floatingIp.status !== status.deleting

  return (
    <Tr>
      <TdTruncatedLines noOfLines={2}>{floatingIp.name}</TdTruncatedLines>
      <TdTruncatedLines noOfLines={2}>
        {floatingIp.description || '-'}
      </TdTruncatedLines>
      <Td>{floatingIp.ipAddress || '-'}</Td>
      <Td whiteSpace="normal">
        <CopyButton value={floatingIp.id} noOfLines={2} />
      </Td>
      <Td>
        <Region slug={floatingIp.region} />
      </Td>
      <TdTruncatedLines noOfLines={2}>
        {instanceName ? (
          <Link
            colorScheme="gray"
            as={ReactLink}
            to={withOrgPathFallback(
              routes.dashboard.instances.view.replace(':instanceId', instanceId)
            )}
          >
            {instanceName}
          </Link>
        ) : (
          <>-</>
        )}
      </TdTruncatedLines>
      <TdTruncatedLines noOfLines={2}>
        {formatDateTime(floatingIp.createdAt)}
      </TdTruncatedLines>
      <Td isNumeric>
        {isLoading && <Spinner mr={3} color="gray.500" />}
        {canBeDeleted && (
          <Menu variant="table" autoSelect={false}>
            <MenuButton variant="icon" as={IconButton} icon={<Dots />} />
            <MenuList>
              <MenuItem onClick={disclosure.onOpen}>
                <Bin mr={2.5} />
                Delete
              </MenuItem>
              <DeleteModal disclosure={disclosure} floatingIP={floatingIp} />
            </MenuList>
          </Menu>
        )}
      </Td>
    </Tr>
  )
}

export default TableRow
