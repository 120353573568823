import { Button } from '@chakra-ui/react'
import { Link as ReactLink, Redirect, Route, Switch } from 'react-router-dom'
import routes from '../../../../../../constants/routes'
import { withOrgPathFallback } from '../../../../../../router'
import NotFound from '../../../../../Errors/NotFound'
import Billing from './Billing'
import Details from './Details'
import Payment from './Payment'
import Setup from './Setup'
import Success from './Success'

const OrganizationCreate = () => (
  <Switch>
    <Route
      exact
      path={withOrgPathFallback(routes.dashboard.organizations.create.index)}
      render={() => (
        <Redirect
          to={withOrgPathFallback(routes.dashboard.organizations.create.setup)}
        />
      )}
    />
    <Route
      exact
      path={withOrgPathFallback(routes.dashboard.organizations.create.setup)}
    >
      <Setup />
    </Route>
    <Route
      exact
      path={withOrgPathFallback(routes.dashboard.organizations.create.details)}
    >
      <Details />
    </Route>
    <Route
      exact
      path={withOrgPathFallback(routes.dashboard.organizations.create.payment)}
    >
      <Payment />
    </Route>
    <Route
      exact
      path={withOrgPathFallback(routes.dashboard.organizations.create.billing)}
    >
      <Billing />
    </Route>
    <Route
      exact
      path={withOrgPathFallback(routes.dashboard.organizations.create.success)}
    >
      <Success />
    </Route>
    <NotFound>
      <Button
        as={ReactLink}
        to={withOrgPathFallback(routes.dashboard.organizations.index)}
        isFullWidth
      >
        My organizations
      </Button>
    </NotFound>
  </Switch>
)

export default OrganizationCreate
