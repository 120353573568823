import { useEffect, useMemo } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { mutate } from 'swr'
import { AccountBilling, OrgBilling } from '../../../api/billing'
import { toastMessages } from '../../../constants/toast-messages'
import { useToast } from '../Toast'
import { OrganizationIdStorage } from '../storage'

export const getOrgId = () => {
  const orgIdStorage = OrganizationIdStorage.get()
  const { orgId: orgIdParam } = useParams()
  return orgIdStorage || orgIdParam
}

export const useLocationOrigin = () => window.location.origin.toString()

const useQuery = () => {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

const transformPayload = ({ paymentIntent, isCardDefault }) => ({
  payment_intent_id: paymentIntent,
  skip_pm_attach: true,
  is_default: isCardDefault
})

const isDefault = 'is_card_default'
const PI = 'payment_intent'
const PICS = 'payment_intent_client_secret'
const RS = 'redirect_status'

// used in stripe redirect after account balance recharge
const sourceType = 'source_type'

export const PaymentHandler = ({ showToast }) => {
  const orgId = getOrgId()
  const toast = useToast()
  const query = useQuery()
  const history = useHistory()

  useEffect(() => {
    async function addCreditCard() {
      const isCardDefault = query.get(isDefault)
      const paymentIntent = query.get(PI)
      const paymentIntentClientSecret = query.get(PICS)
      const redirectStatus = query.get(RS)

      if (
        !isCardDefault &&
        !paymentIntent &&
        !paymentIntentClientSecret &&
        !redirectStatus
      )
        return

      try {
        if (isCardDefault !== null) {
          const payload = transformPayload({ paymentIntent, isCardDefault })

          orgId
            ? await OrgBilling.addCreditCard({ payload, orgId })
            : await AccountBilling.addCreditCard({ payload })

          await mutate((key) => key.startsWith('/billing/customer'))
        }

        showToast &&
          toast({
            status: 'success',
            message: toastMessages.saved
          })
      } catch (error) {
        toast({
          status: 'error',
          message: toastMessages.notSaved
        })
      } finally {
        query.delete(isDefault)
        query.delete(PI)
        query.delete(PICS)
        query.delete(RS)
        query.delete(sourceType)

        history.replace({
          search: query.toString()
        })
      }
    }

    addCreditCard()
  }, [])

  return null
}
