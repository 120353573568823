import { Divider, HStack } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import useSWR from 'swr'
import { fetchNotifications } from '../../../../api/notifications'
import { swrOptions } from '../../../../constants/swr-options'
import { serialize } from '../../../../helpers/serializers'
import { getOrgParam, getProjectParam } from '../../../../router'
import { useAccount } from '../../../shared/hooks/account'
import { useHasWriteProjectPermissions } from '../../../shared/hooks/project'
import CreateButton from './CreateButton'
import { ErrorLayout } from './Error'
import Loading from './Loading'
import Menu from './Menu'
import Notifications from './Notifications'

const RightSide = () => {
  const orgId = getOrgParam()
  const projectId = getProjectParam()
  const hasWriteProjectPermissions = useHasWriteProjectPermissions({
    projectId,
    orgId
  })
  const { account, isLoadingAccount, isErrorAccount } = useAccount()

  const user = useSelector((state) => state.oidc.user)
  const key = user ? '/notifications' : null
  const { data: notifications, error: notificationsError } = useSWR(
    key,
    fetchNotifications,
    swrOptions
  )

  const notificationsLoading = !notifications && !notificationsError

  if (isErrorAccount || notificationsError) return <ErrorLayout />
  if (isLoadingAccount || notificationsLoading) return <Loading />

  if (isErrorAccount) return <ErrorLayout />
  if (isLoadingAccount) return <Loading />

  return (
    <HStack spacing={6}>
      {hasWriteProjectPermissions && <CreateButton />}
      <Notifications notifications={serialize(notifications)} />
      <Divider h={6} orientation="vertical" />
      <Menu account={account} />
    </HStack>
  )
}

export default RightSide
