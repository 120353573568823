import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  Textarea
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import * as yup from 'yup'
import { AccountSecurity, OrgSecurity } from '../../../../../api/security'
import messages from '../../../../../constants/messages'
import { toastMessages } from '../../../../../constants/toast-messages'
import { apiErrorHandler } from '../../../../shared/ApiErrorHandler'
import Asterisk from '../../../../shared/Asterisk'
import { useToast } from '../../../../shared/Toast'
import { validatePublicKey } from '../../utils'

const initialValues = {
  publicKey: '',
  keyName: ''
}

const validationSchema = yup.object().shape({
  publicKey: yup
    .string()
    .required('Public key is required')
    .test({
      name: 'validatePublicKey',
      message: messages.invalidPublicKey,
      test: (value) => {
        const { isValid } = validatePublicKey(value)
        return isValid
      }
    }),
  keyName: yup.string().required('Key name is required')
})

export const CreateModal = ({ disclosure }) => {
  const { isOpen, onClose } = disclosure
  const { orgId, projectId } = useParams()
  const toast = useToast()

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    const payload = {
      value: values.publicKey,
      name: values.keyName
    }
    setSubmitting(true)

    try {
      if (orgId) {
        await OrgSecurity.addSSHKey({
          orgId,
          projectId,
          payload
        })
        await mutate((key) =>
          key.startsWith(`/projects/${projectId}/ssh-keys?`)
        )
      } else {
        await AccountSecurity.addSSHKey({
          payload
        })
        await mutate((key) => key.startsWith('/ssh-keys?'))
      }
      toast({
        status: 'success',
        message: toastMessages.SSHKeyAddSuccess
      })
      handleClose()
    } catch (error) {
      const message = await apiErrorHandler(error)
      toast({
        status: 'error',
        message
      })
      setErrors({ publicKey: message })
    } finally {
      setSubmitting(false)
    }
  }

  const {
    values,
    setValues,
    handleChange,
    errors,
    touched,
    setTouched,
    handleSubmit,
    isSubmitting,
    resetForm
  } = useFormik({
    validationSchema,
    initialValues,
    onSubmit
  })

  const handlePublicKeyChange = (event) => {
    const { value } = event.target
    const { isValid, name } = validatePublicKey(value)

    setTouched({ publicKey: true })
    setValues((prev) => ({
      publicKey: value,
      keyName: isValid ? name : prev.keyName
    }))
  }

  const handleClose = () => {
    resetForm()
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap="8" as="form" onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <Heading size="xl" textAlign="center">
                Add a new SSH Key
              </Heading>
              <Text
                size="md"
                fontWeight="normal"
                color="gray.500"
                textAlign="center"
              >
                Your public SSH key will be automatically added to all your
                profiles
              </Text>
            </Stack>
            <Stack spacing={4}>
              <FormControl isInvalid={errors.publicKey && touched.publicKey}>
                <FormLabel>
                  <Asterisk />
                  Public key
                </FormLabel>
                <Textarea
                  autoFocus
                  rows={4}
                  name="publicKey"
                  placeholder="Supported keys start with: 'ssh-rsa', 'ssh-dss', 'ssh-ed25519', 'ecdsa-sha2-nistp256', 'ecdsa-sha2-nistp384', 'ecdsa-sha2-nistp512'"
                  value={values.publicKey}
                  onChange={handlePublicKeyChange}
                />
                <FormErrorMessage>{errors.publicKey}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.keyName && touched.keyName}>
                <FormLabel>
                  <Asterisk />
                  Key name
                </FormLabel>
                <Input
                  name="keyName"
                  placeholder="Enter your key name"
                  value={values.keyName}
                  onChange={handleChange}
                />
                <FormErrorMessage>{errors.keyName}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack spacing={4}>
              <Button type="submit" isLoading={isSubmitting}>
                Save
              </Button>
              <Button
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isSubmitting}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Stack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
