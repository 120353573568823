import {
  Button,
  Heading,
  Skeleton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { calcMaxSize, hasAvaliableSize } from '../../../../helpers/quota'
import { useIsVip } from '../../../../helpers/vip'
import { types as volumeTypes } from '../../../../helpers/volumes'
import {
  ValidationErrors,
  isUserValidToCreateResources
} from '../../../Errors/ValidationErrors'
import { Card } from '../../../shared/Cards'
import Region from '../../../shared/Region'
import { useAccountBilling, useOrgBilling } from '../../../shared/hooks'
import { useAccount, useAccountSettings } from '../../../shared/hooks/account'
import { useAccountFF, useOrgFF } from '../../../shared/hooks/featureFlag'
import { useOrgSettings } from '../../../shared/hooks/organization'
import { useAccountQuota, useOrgQuota } from '../../../shared/hooks/quota'
import useSuspended from '../../../shared/hooks/useSuspended'
import PriceCard from './price'

const Summary = () => {
  const { orgId } = useParams()
  const { values, isSubmitting } = useFormikContext()
  const { quota, isErrorQuota, isLoadingQuota } = orgId
    ? useOrgQuota({ orgId })
    : useAccountQuota()
  const { account, isErrorAccount, isLoadingAccount } = useAccount()
  const { billing, isErrorBilling, isLoadingBilling } = orgId
    ? useOrgBilling({ orgId })
    : useAccountBilling()
  const { settings, isErrorSettings, isLoadingSettings } = orgId
    ? useOrgSettings({ orgId })
    : useAccountSettings()
  const { featureFlag } = orgId ? useOrgFF({ orgId }) : useAccountFF({})
  const isVip = useIsVip(settings)
  const maxSize = calcMaxSize({ quota, type: values.type })
  const hasAvailableSizeValue = hasAvaliableSize({ size: values.size, maxSize })
  const size = hasAvailableSizeValue ? `${values.size} GiB` : '...'
  const [isUserValid, setIsUserValid] = useState(false)
  const { isSuspended } = useSuspended()

  const isDisabled =
    isErrorQuota ||
    isErrorAccount ||
    isErrorBilling ||
    isErrorSettings ||
    isUserValid ||
    isSuspended

  const isLoading =
    isLoadingQuota || isLoadingAccount || isLoadingBilling || isLoadingSettings

  useEffect(() => {
    setIsUserValid(
      !isUserValidToCreateResources({
        account,
        billing,
        isVip
      })
    )
  }, [account, billing, isVip])

  if (isLoading) {
    return <Skeleton h="360px" />
  }

  return (
    <div>
      <Stack spacing={5} position="sticky" top={8}>
        {values.type === volumeTypes.ssd && (
          <PriceCard hasAvaliableSize={hasAvailableSizeValue} />
        )}
        {featureFlag?.enableExtendedBilling &&
          values.type === volumeTypes.hdd && (
            <PriceCard hasAvaliableSize={hasAvailableSizeValue} />
          )}
        <Card as={Stack} spacing={5} p={7}>
          <Heading size="md">Volume</Heading>
          <TableContainer mb={8}>
            <Table variant="card">
              <Tbody>
                <Tr>
                  <Td>Location</Td>
                  <Td display="flex" justifyContent="right">
                    <Region slug={values.region} color="black" />
                  </Td>
                </Tr>
                <Tr>
                  <Td>Type</Td>
                  <Td>{values.type.toUpperCase()} Block Storage</Td>
                </Tr>
                <Tr>
                  <Td>Size</Td>
                  <Td>{size}</Td>
                </Tr>
                <Tr>
                  <Td>File System</Td>
                  <Td>Ext4</Td>
                </Tr>
                <Tr>
                  <Td>Billing type</Td>
                  <Td>On-demand</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
          <Button
            isFullWidth
            type="submit"
            isLoading={isSubmitting}
            isDisabled={isDisabled}
          >
            Create Volume
          </Button>
          <ValidationErrors account={account} billing={billing} isVip={isVip} />
        </Card>
      </Stack>
    </div>
  )
}

export default Summary
