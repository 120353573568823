import { Grid, Stack, Text } from '@chakra-ui/react'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { eventLinks } from '../../../../../helpers/links'
import Icon from './Icon'
import NotificationLink from './NotificationLink'

const Notification = ({ notification, onClose }) => {
  const { title, type, read, actionTitle, text, createdAt, eventId, data } =
    notification
  const [event, setEvent] = useState()
  const color = read ? 'gray.500' : 'gray.900'

  useEffect(() => {
    if (eventId) {
      setEvent(eventLinks.find((e) => e.id === eventId))
    }
  }, [eventId])

  return (
    <Grid templateColumns="20px auto" gap={2.5} mb={3}>
      <Icon type={type} />
      <Stack spacing={1.5}>
        <Text size="md" color={color}>
          {title}
        </Text>
        <Text size="sm" color={color} textAlign="justify">
          {text}
        </Text>
        <Text size="xs" color={color}>
          {moment(createdAt).fromNow()}
        </Text>
        {event?.hasLink && (
          <NotificationLink
            event={event}
            data={data}
            title={actionTitle}
            onClose={onClose}
          />
        )}
      </Stack>
    </Grid>
  )
}

export default Notification
