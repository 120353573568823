import { Text } from '@chakra-ui/layout'
import CopyButton from './CopyButton'

const PublicIp = ({ publicIp }) =>
  publicIp ? (
    <CopyButton color="gray.500" value={`ssh ubuntu@${publicIp}`} />
  ) : (
    <Text color="gray.500">Not available - IP not assigned yet</Text>
  )

export default PublicIp
