import { Stack, Heading, Text, Skeleton } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { SelectField } from '../../../shared/Fields'

const NoData = () => <Text color="gray.500">There are no Types yet.</Text>

const Type = ({ isLoading, noData }) => {
  const { values, setValues } = useFormikContext()

  const handleUpdate = (option) => {
    const configurationOptions = values.instancesGroupByType[option.value]

    setValues((prev) => ({
      ...prev,
      type: {
        ...prev.type,
        selected: option
      },
      configuration: {
        options: configurationOptions,
        selected: configurationOptions[0]
      }
    }))
  }

  return (
    <Stack spacing={8}>
      <Heading size="xl">Select Instance Type</Heading>
      {isLoading ? (
        <Skeleton h="120px" />
      ) : noData ? (
        <NoData />
      ) : (
        <SelectField
          width="50%"
          label="Type"
          name="type.selected"
          options={values.type.options}
          onUpdate={handleUpdate}
          isRequired
        />
      )}
    </Stack>
  )
}

export default Type
