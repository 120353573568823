export class OrganizationIdStorage {
  static KEY = 'genesiscloud.dashboard.orgId'

  static get() {
    return localStorage.getItem(this.KEY)
  }

  static set(value) {
    localStorage.setItem(this.KEY, value)
  }

  static clear() {
    localStorage.removeItem(this.KEY)
  }
}
export class ProjectIdStorage {
  static KEY = 'genesiscloud.dashboard.projectId'

  static get() {
    return localStorage.getItem(this.KEY)
  }

  static set(value) {
    localStorage.setItem(this.KEY, value)
  }

  static clear() {
    localStorage.removeItem(this.KEY)
  }
}
