import { useFormikContext } from 'formik'
import { ScriptsCard } from '../RadioCards'
import { ScriptsField } from '../Fields'

const Scripts = () => {
  const { values, setValues } = useFormikContext()
  const isChecked = values.options.isScriptsChecked
  const isDisabled = values.options.driver !== ''

  const onChange = (event) => {
    const isScriptsChecked = event.target.checked

    setValues((prev) => ({
      ...prev,
      options: {
        ...prev.options,
        isScriptsChecked,
        scripts: '',
        driver: ''
      }
    }))
  }

  return (
    <ScriptsCard
      isChecked={isChecked}
      onChange={onChange}
      isDisabled={isDisabled}
    >
      <ScriptsField name="options.scripts" />
    </ScriptsCard>
  )
}

export default Scripts
