import { Fragment } from 'react'
import { Stack, SimpleGrid, Heading, Text } from '@chakra-ui/react'
import { Card } from '../../../shared/Cards'

const Password = () => (
  <Stack spacing={1}>
    <Text>Password</Text>
    <Text color="gray.500">
      Please note: The password was shown only during the creation of an
      Instance. It can not be changed.
    </Text>
  </Stack>
)

const SSHKeys = ({ sshKeys }) => (
  <SimpleGrid columns={2} columnGap={16}>
    <Text mb={1}>SSH Keys</Text>
    <Text mb={1}>Fingerprint</Text>
    {sshKeys.map(({ id, name }) => (
      <Fragment key={id}>
        <Text color="gray.500">{name}</Text>
        <Text color="gray.500">{id}</Text>
      </Fragment>
    ))}
  </SimpleGrid>
)

const Authentication = ({ sshKeys }) => {
  const isPassword = sshKeys.length === 0

  return (
    <Card as={Stack} spacing={8}>
      <Heading size="xl">Authentication</Heading>
      {isPassword ? <Password /> : <SSHKeys sshKeys={sshKeys} />}
    </Card>
  )
}

export default Authentication
