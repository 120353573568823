import {
  Button,
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  FormLabel,
  Text
} from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import React, { useCallback } from 'react'
import { mutate } from 'swr'
import * as yup from 'yup'
import { useParams } from 'react-router-dom'
import { OrgBilling, AccountBilling } from '../../../../../api/billing'
import Asterisk from '../../../../shared/Asterisk'
import CreditCardDetails from '../../../../shared/CreditCardDetails'
import CreditCardIcon from '../../../../shared/CreditCardIcon'

const validationSchema = yup.object().shape({
  nameOnCard: yup.string().required('Name on card is required'),
  address: yup.string().required('Address is required'),
  city: yup.string().required('City is required'),
  postalCode: yup.string(),
  country: yup.string().required('Country is required')
})

const EditCreditCard = ({ creditCard, isDefault, isLoading, onSuccess }) => {
  const creditCardId = creditCard?.id
  const { orgId } = useParams()
  const handleSubmit = useCallback(
    async (
      { isDefault, nameOnCard, address, city, postalCode, country },
      { setSubmitting }
    ) => {
      setSubmitting(true)
      const payload = {
        name: nameOnCard,
        billing_details: {
          address,
          city,
          postal_code: postalCode,
          country_code: country
        },
        make_default: isDefault
      }
      if (orgId) {
        await OrgBilling.updateCreditCard({
          orgId,
          creditCardId,
          payload
        })
      } else {
        await AccountBilling.updateCreditCard({
          creditCardId,
          payload
        })
      }
      await mutate((key) => key.startsWith(`/billing/customer`))
      setSubmitting(false)
      onSuccess()
    },
    [creditCard]
  )

  const initialValues = {
    nameOnCard: creditCard?.name || '',
    address: creditCard?.billingDetails.address || '',
    city: creditCard?.billingDetails.city || '',
    postalCode: creditCard?.billingDetails.postalCode || '',
    country: creditCard?.billingDetails.countryCode || '',
    isDefault
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, errors, touched, isSubmitting, values }) => (
        <Flex direction="column" gap="8" as={Form} onSubmit={handleSubmit}>
          <FormControl>
            <FormLabel>
              <Asterisk />
              Card number
            </FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none" pl={2}>
                <CreditCardIcon brand={creditCard?.brand} />
              </InputLeftElement>
              <Input
                isDisabled
                value={`xxxx-${creditCard?.last4 || ''}`}
                pl="42px"
              />
              <InputRightElement pointerEvents="none" pr="42px">
                <Text color="gray.400">
                  {creditCard?.expMonth}/{creditCard?.expYear}
                </Text>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <CreditCardDetails
            showDefaultOption
            values={values}
            errors={errors}
            touched={touched}
            isLoading={isSubmitting || isLoading}
          />
          <Button
            type="submit"
            isFullWidth
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Save
          </Button>
        </Flex>
      )}
    </Formik>
  )
}

export default EditCreditCard
