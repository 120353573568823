import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { resetInstance } from '../../../../helpers/instances'
import { useToast } from '../../../shared/Toast'
import { useIsMounted } from '../../../shared/hooks/useIsMounted'

export const ResetModal = ({ instance, disclosure }) => {
  const { orgId, projectId } = useParams()
  const { isOpen, onClose } = disclosure
  const [isSubmitting, setIsSubmitting] = useState()
  const isMounted = useIsMounted()
  const toast = useToast()

  const handleResetInstance = useCallback(async () => {
    setIsSubmitting(true)
    await resetInstance({ instanceId: instance.id, projectId, orgId, toast })
    if (isMounted()) {
      setIsSubmitting(false)
      onClose()
    }
  }, [instance, projectId, orgId])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <Heading size="xl" mb={4} textAlign="center">
              Reset Instance “{instance.name}”
            </Heading>
            <Text
              mb={8}
              size="md"
              textAlign="center"
              color="red.500"
              fontWeight="normal"
            >
              This will force a hard reset on the Instance. All temporary data
              will be lost and the filesystem may be corrupted.
            </Text>
            <Stack spacing={4}>
              <Button
                type="submit"
                isLoading={isSubmitting}
                colorScheme="blue"
                onClick={handleResetInstance}
              >
                Reset
              </Button>
              <Button
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isSubmitting}
                onClick={onClose}
                isFullWidth
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
