import { PASSWORD, SSH_KEYS } from '../../../../constants/create-instance'
import { isUsedSubscription, slugs } from '../../../../helpers/billing'
import { hashPasswordWithRounds } from '../../../../helpers/hash'

const nameSection = (name) => ({
  name: name.name,
  hostname: name.hostname
})

const imageSection = (image, catalog) => {
  const catalog_id = catalog.id
  const password = catalog.password

  const catalog_data = {
    fields: {
      ...(password && { password })
    }
  }

  return {
    image: image.id,
    ...(catalog_id && { catalog_id }),
    ...(catalog_id && { catalog_data })
  }
}

const authenticationSection = (authentication) => {
  const isSSHKeys = authentication.method === SSH_KEYS
  const isPassword = authentication.method === PASSWORD

  return {
    ...(isSSHKeys && { ssh_keys: authentication.sshKeys.map(({ id }) => id) }),
    ...(isPassword && {
      password: hashPasswordWithRounds(authentication.password)
    })
  }
}

const networkSection = (network) => {
  const security_groups = network.securityGroups.map(({ id }) => id)

  return {
    security_groups
  }
}

const volumesSection = (volumesArray) => {
  const volumes = volumesArray.map(({ id }) => id)
  const hasVolumes = volumesArray.length > 0

  return {
    ...(hasVolumes && { volumes })
  }
}

const optionsSection = (options) => {
  const hasStaticIP = options.isStaticIPChecked
  const public_ip_type = 'static'

  const hasFloatingIP = options.isFloatingIPChecked
  const floating_ip = options.floatingIP

  const hasScripts = options.isScriptsChecked
  const metadata = { startup_script: options.scripts }

  const driver = options.driver

  return {
    ...(hasStaticIP && { public_ip_type }),
    ...(hasFloatingIP && { floating_ip }),
    ...(hasScripts && { metadata }),
    ...(driver && { driver })
  }
}

const billingSection = (billing) => {
  const isUsed = isUsedSubscription(billing.id)
  const reuse_long_term_subscription = billing.id
  const isReservedType = billing.id === slugs.reserved
  const reservation_id = billing.reservationId

  return {
    billing_type: billing.slug,
    ...(isUsed && { reuse_long_term_subscription }),
    ...(isReservedType && { reservation_id })
  }
}

export const transformPayload = (values) => {
  const region = values.region
  const type = values.typeConfigSlug
  const name = nameSection(values.name)
  const image = imageSection(values.image, values.catalog)
  const authentication = authenticationSection(values.authentication)
  const network = networkSection(values.network)
  const volumes = volumesSection(values.volumes)
  const options = optionsSection(values.options)
  const billing = billingSection(values.billing)

  return {
    region,
    type,
    ...name,
    ...image,
    ...authentication,
    ...network,
    ...volumes,
    ...options,
    ...billing
  }
}
