import { Grid, Skeleton } from '@chakra-ui/react'

const Loading = () => (
  <Grid templateColumns="0.5fr 1.5fr" gap={5} h="1030px">
    <Skeleton />
    <Skeleton />
  </Grid>
)

export default Loading
