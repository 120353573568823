import { HStack } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import ParamLink from './ParamLink'

export const Tabs = ({ children }) => (
  <HStack
    mb={8}
    pos="relative"
    _before={{
      content: '""',
      position: 'absolute',
      display: 'block',
      bottom: '0',
      height: '2px',
      width: '100%',
      background: 'gray.100',
      zIndex: '-1'
    }}
  >
    {children}
  </HStack>
)

export const Tab = ({ url, children }) => (
  <ParamLink
    as={NavLink}
    to={url}
    variant="menu"
    colorScheme="gray"
    px={4}
    py={1}
    fontSize="16px"
    borderBottom="2px solid transparent"
    activeStyle={{
      color: 'black',
      borderBottom: '2px solid black'
    }}
  >
    {children}
  </ParamLink>
)
