import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { mutate } from 'swr'
import { patchAccount } from '../../../../../../api/account'
import routes from '../../../../../../constants/routes'
import { toastMessages } from '../../../../../../constants/toast-messages'
import { useToast } from '../../../../../shared/Toast'
import { useOrganizationWithId } from '../../../../../shared/hooks/organization'
import { useIsMounted } from '../../../../../shared/hooks/useIsMounted'

export const MakeDefaultModal = ({ disclosure, orgId }) => {
  const history = useHistory()
  const { isOpen, onClose } = disclosure
  const [isSubmitting, setIsSubmitting] = useState()
  const { organization } = useOrganizationWithId(orgId)
  const isMounted = useIsMounted()
  const toast = useToast()

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true)
    try {
      await patchAccount({ default_organization_id: orgId })
      await mutate('/account')
      toast({
        status: 'success',
        message: toastMessages.organizationRemoveDefaultSuccess
      })
      onClose()
      history.push(routes.dashboard.organizations.index)
    } catch {
      toast({
        status: 'error',
        message: toastMessages.organizationRemoveDefaultError
      })
    } finally {
      if (isMounted()) {
        setIsSubmitting(false)
      }
    }
  }, [organization])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={8}>
            <Heading size="xl" textAlign="center">
              Make {organization?.name} your dafault Organization?
            </Heading>
            <Text
              size="md"
              textAlign="center"
              // color="red.500"
              fontWeight="normal"
            >
              Next time you sign in you will be redirected to this
              Organization&apos;s default project.
            </Text>
            <Stack spacing={4}>
              <Button
                type="submit"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                onClick={handleSubmit}
              >
                Make default Organization
              </Button>
              <Button
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isSubmitting}
                onClick={onClose}
                isFullWidth
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
