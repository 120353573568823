import useSWR from 'swr'
import { Project } from '../../../../api/projects'
import { serialize } from '../../../../helpers/serializers'
import { INITIAL_PAGE, PER_PAGE } from '../../Table'
import { swrOptions } from '../../../../constants/swr-options'
import { useAccount } from '../account'

export const projectPermissions = {
  admin: 'ADMIN',
  editor: 'EDITOR',
  viewer: 'VIEWER'
}

export const useIsAdmin = (permission) => {
  return permission === projectPermissions.admin
}

export function useProjectRole(project) {
  const role = project.userPermission?.toLowerCase()

  return role || 'Viewer'
}

export const useHasWriteProjectPermissions = ({ orgId, projectId }) => {
  const { project } = useProject({ orgId, projectId })

  if (!orgId && !projectId) {
    return true
  }

  return (
    project?.userPermission === projectPermissions.admin ||
    project?.userPermission === projectPermissions.editor
  )
}

export const useProject = ({ orgId, projectId }) => {
  const { data, isLoading, error } = useSWR(
    orgId && projectId && `/organizations/${orgId}/projects/${projectId}`,
    () => Project.fetchById({ orgId, projectId }),
    swrOptions
  )
  return {
    project: serialize(data),
    isLoadingProject: isLoading,
    isErrorProject: Boolean(error)
  }
}

export function useProjects({
  orgId,
  page = INITIAL_PAGE,
  perPage = PER_PAGE
}) {
  const { data, error } = useSWR(
    orgId &&
      `/organizations/${orgId}/projects?page=${page}&per_page=${perPage}`,
    () => Project.fetchAll({ orgId, page, perPage }),
    swrOptions
  )
  return {
    projects: serialize(data),
    isError: error,
    isLoading: !data && !error
  }
}

export function useProjectMembers({
  orgId,
  projectId,
  page = INITIAL_PAGE,
  perPage = PER_PAGE
}) {
  const { data, error } = useSWR(
    orgId &&
      projectId &&
      `/organizations/${orgId}/projects/${projectId}/members?page=${page}&per_page=${perPage}`,
    () => Project.fetchAllMembers({ orgId, projectId, page, perPage }),
    swrOptions
  )
  return {
    members: serialize(data),
    isError: error,
    isLoading: !data && !error
  }
}

export const useProjectVersion = ({ orgId, projectId }) => {
  let isLegacy, isLoadingProjectVersion, isErrorProjectVersion

  if (orgId && projectId) {
    const { project, isLoadingProject, isErrorProject } = useProject({
      orgId,
      projectId
    })
    isLegacy = project?.isLegacy
    isLoadingProjectVersion = isLoadingProject
    isErrorProjectVersion = isErrorProject
  } else {
    const { account, isLoadingAccount, isErrorAccount } = useAccount()
    isLegacy = account?.projects[0].isLegacy
    isLoadingProjectVersion = isLoadingAccount
    isErrorProjectVersion = isErrorAccount
  }

  const isB2 = isLegacy === undefined ? undefined : !isLegacy

  return { isLegacy, isB2, isLoadingProjectVersion, isErrorProjectVersion }
}
