import { Stack, Text, Heading, Button, useDisclosure } from '@chakra-ui/react'
import { Card } from '../../../shared/Cards'
import { Alarm as AlarmIcon } from '../../../shared/Icons'
import { AlarmModal } from './modals'

const Alarm = () => {
  const alarmDisclosure = useDisclosure()

  return (
    <Card as={Stack} spacing={8}>
      <Stack spacing={2}>
        <Heading size="xl">Billing alarm</Heading>
        <Text color="gray.500">
          Set up automated billing alarm to receive emails when your Current
          Total are over a specified amount.
        </Text>
      </Stack>
      <Button
        alignSelf="flex-start"
        colorScheme="offBlack"
        variant="outline"
        leftIcon={<AlarmIcon boxSize={4} />}
        onClick={alarmDisclosure.onOpen}
      >
        Set up alarm
      </Button>
      <AlarmModal disclosure={alarmDisclosure} />
    </Card>
  )
}

export default Alarm
