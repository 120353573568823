import React, { useEffect } from 'react'
import { useParams, useLocation } from 'react-router'
import qs from 'query-string'
import authPrompt from '../../helpers/auth/authPrompt'
import { sanitizeObject } from '../../helpers/object'
import signupParams from '../../constants/signup-params'

const PromptPage = () => {
  const { prompt } = useParams()
  const { search } = useLocation()

  useEffect(() => {
    const authParams = sanitizeObject(qs.parse(search), signupParams)
    authPrompt(prompt, { extraQueryParams: authParams })
  }, [prompt])

  return <div>Loading...</div>
}

export default PromptPage
