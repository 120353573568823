import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { Organization } from '../../../../api/organizations'
import { serialize } from '../../../../helpers/serializers'
import { getOrgParam } from '../../../../router'
import { OrganizationIdStorage } from '../../storage'
import { INITIAL_PAGE, PER_PAGE } from '../../Table'
import { swrOptions } from '../../../../constants/swr-options'
import { useAccount } from '../account'

export function useIsOwner(organization) {
  const { account } = useAccount()
  const userId = account?.accountId

  return !!userId && userId === organization?.ownerId
}

export function useIsRole(organization, ...roles) {
  const { account } = useAccount()
  const userId = account?.accountId
  const userRole = organization?.members?.find(
    (m) => m.accountId === userId
  )?.role

  return !!userId && roles.includes(userRole)
}

export function useSelfRole(organization) {
  const { account } = useAccount()
  const userId = account?.accountId
  const [selfRole, setSelfRole] = useState('')
  const role = organization?.members?.find((m) => m.accountId === userId).role

  useEffect(() => {
    setSelfRole(!!userId && role)
  }, [organization])

  return selfRole
}

export const useOrganization = () => {
  const id = getOrgParam() || OrganizationIdStorage.get()
  const { data, error } = useSWR(id && `/organizations/${id}`, () =>
    Organization.fetchById(id)
  )
  return { organization: serialize(data), error }
}

export const useOrganizationWithId = (orgId) => {
  const { data, error } = useSWR(orgId && `/organizations/${orgId}`, () =>
    Organization.fetchById(orgId)
  )

  return {
    organization: serialize(data),
    isError: error,
    isLoading: !data && !error
  }
}

export function useOrganizations({ page = INITIAL_PAGE, perPage = PER_PAGE }) {
  const { data, error } = useSWR(
    `/organizations?page=${page}&per_page=${perPage}`,
    () => Organization.fetchAll({ page, perPage }),
    swrOptions
  )
  return {
    organizations: serialize(data),
    isError: error,
    isLoading: !data && !error
  }
}

export function useOrganizationMembers({
  orgId,
  page = INITIAL_PAGE,
  perPage = PER_PAGE
}) {
  const { data, error } = useSWR(
    `/organizations/${orgId}/members?page=${page}&per_page=${perPage}`,
    () => Organization.getAllMembers({ orgId, page, perPage }),
    swrOptions
  )
  return {
    members: serialize(data),
    isError: error,
    isLoading: !data && !error
  }
}

export function useOrganizationInvitations({
  orgId,
  page = INITIAL_PAGE,
  perPage = PER_PAGE
}) {
  const { data, error } = useSWR(
    `/organizations/${orgId}/invitations?page=${page}&per_page=${perPage}`,
    () => Organization.fetchAllInvitations({ orgId, page, perPage }),
    swrOptions
  )
  return {
    invitations: serialize(data),
    isError: error,
    isLoading: !data && !error
  }
}

export function useOrgSettings({ orgId }) {
  const { data, error } = useSWR(
    orgId && `/organizations/${orgId}/settings`,
    () => Organization.fetchSettingsById(orgId),
    swrOptions
  )
  return {
    settings: serialize(data),
    isErrorSettings: error,
    isLoadingSettings: !data && !error
  }
}
