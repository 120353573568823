import { Button, HStack, Heading, Stack } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useCallback } from 'react'
import { useParams } from 'react-router'
import { mutate } from 'swr'
import * as yup from 'yup'
import {
  AccountSecurityGroups,
  OrgSecurityGroups
} from '../../../../../../api/securityGroups'
import messages from '../../../../../../constants/messages'
import { toastMessages } from '../../../../../../constants/toast-messages'
import {
  mapRulesToBackend,
  rulesDirections,
  validateDuplicatedRules,
  validatePorts,
  validationName
} from '../../../../../../helpers/securityGroups'
import { Card } from '../../../../../shared/Cards'
import { RulesField } from '../../../../../shared/Fields'
import { useToast } from '../../../../../shared/Toast'
import { useIsMounted } from '../../../../../shared/hooks/useIsMounted'

const validationSchema = yup.object().shape({
  ingress: yup
    .array()
    .test({
      name: validationName.limit,
      message: messages.rulesEmpty,
      test: (value, context) =>
        value.length > 0 || context.parent.egress.length > 0
    })
    .test({
      name: validationName.duplicated,
      message: messages.rulesDuplicate,
      test: (value) => !validateDuplicatedRules(value)
    })
    .test({
      name: validationName.port,
      message: '',
      test: (value) => validatePorts(value)
    }),
  egress: yup
    .array()
    .test({
      name: validationName.limit,
      message: messages.rulesEmpty,
      test: (value, context) =>
        value.length > 0 || context.parent.ingress.length > 0
    })
    .test({
      name: validationName.duplicated,
      message: messages.rulesDuplicate,
      test: (value) => !validateDuplicatedRules(value)
    })
    .test({
      name: validationName.port,
      message: '',
      test: (value) => validatePorts(value)
    })
})

const EditMode = ({ onClick, rules }) => {
  const { orgId, projectId, securityGroupId } = useParams()
  const isMounted = useIsMounted()
  const toast = useToast()
  const initialValues = {
    ingress: rules.ingress || [],
    egress: rules.egress || []
  }

  const handleSubmit = useCallback(
    async ({ ingress, egress }, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      try {
        const data = { security_group_rules: [] }

        if (ingress) {
          const ingressMaped = mapRulesToBackend(ingress)
          data.security_group_rules.push(...ingressMaped)
        }
        if (egress) {
          const egressMaped = mapRulesToBackend(egress)
          data.security_group_rules.push(...egressMaped)
        }

        if (orgId) {
          await OrgSecurityGroups.updateById({
            orgId,
            projectId,
            securityGroupId,
            data
          })
          await mutate((key) =>
            key.startsWith(
              `/projects/${projectId}/security-groups/${securityGroupId}?org=${orgId}`
            )
          )
        } else {
          await AccountSecurityGroups.updateById({
            securityGroupId,
            data
          })
          await mutate((key) =>
            key.startsWith(`/security-groups/${securityGroupId}`)
          )
        }
        toast({
          status: 'success',
          message: toastMessages.saved
        })
        resetForm()
        onClick()
      } catch (error) {
        toast({
          status: 'error',
          message: toastMessages.notSaved
        })
      } finally {
        if (isMounted()) {
          setSubmitting(false)
        }
      }
    },
    [orgId, projectId, securityGroupId]
  )

  return (
    <Card as={Stack} spacing={8}>
      <HStack h={10}>
        <Heading size="xl">Configuration</Heading>
      </HStack>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form width="100%">
            <Stack spacing={8}>
              <Stack spacing={4}>
                <Heading as="h3" size="sm">
                  Inbound Rules
                </Heading>
                <RulesField name={rulesDirections.ingress} />
              </Stack>
              <Stack spacing={4}>
                <Heading as="h3" size="sm">
                  Outbound Rules
                </Heading>
                <RulesField name={rulesDirections.egress} />
              </Stack>
              <HStack spacing={3}>
                <Button w={40} type="submit" isLoading={isSubmitting}>
                  Save
                </Button>
                <Button
                  w={40}
                  variant="outline"
                  colorScheme="offBlack"
                  onClick={onClick}
                  isDisabled={isSubmitting}
                >
                  Cancel
                </Button>
              </HStack>
            </Stack>
          </Form>
        )}
      </Formik>
    </Card>
  )
}

export default EditMode
