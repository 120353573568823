import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  Select
} from '@chakra-ui/react'
import { Field } from 'formik'
import Asterisk from './Asterisk'
import { getCountries } from './PhoneInput/country'

const countries = getCountries()

const CreditCardDetails = ({
  showDefaultOption,
  isLoading,
  values,
  errors,
  touched
}) => {
  return (
    <>
      <FormControl isInvalid={errors.nameOnCard && touched.nameOnCard}>
        <FormLabel>
          <Asterisk />
          Name on Card
        </FormLabel>
        <Field
          isDisabled={isLoading}
          as={Input}
          name="nameOnCard"
          placeholder="Name Surname"
        />
        <FormErrorMessage>{errors.nameOnCard}</FormErrorMessage>
      </FormControl>
      <Text color="gray.500" size="md" fontWeight="normal" textAlign="center">
        Please provide the address associated with the credit card you have
        provided above
      </Text>
      <FormControl isInvalid={errors.address && touched.address}>
        <FormLabel>
          <Asterisk />
          Address
        </FormLabel>
        <Field
          isDisabled={isLoading}
          as={Input}
          name="address"
          placeholder="Enter address"
        />
        <FormErrorMessage>{errors.address}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={errors.city && touched.city}>
        <FormLabel>
          <Asterisk />
          City
        </FormLabel>
        <Field
          isDisabled={isLoading}
          as={Input}
          name="city"
          placeholder="Enter city name"
        />
        <FormErrorMessage>{errors.city}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={errors.postalCode && touched.postalCode}>
        <FormLabel>ZIP / Postal code</FormLabel>
        <Field
          isDisabled={isLoading}
          as={Input}
          name="postalCode"
          placeholder="Enter postal code"
        />
        <FormErrorMessage>{errors.postalCode}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={errors.country && touched.country}>
        <FormLabel>
          <Asterisk />
          Country / region
        </FormLabel>
        <Field
          isDisabled={isLoading}
          as={Select}
          name="country"
          placeholder="Select"
        >
          {countries.map((country) => (
            <option value={country.id} key={country.id}>
              {country.name} {country.flag}
            </option>
          ))}
        </Field>
        <FormErrorMessage>{errors.country}</FormErrorMessage>
      </FormControl>
      {showDefaultOption && (
        <FormControl>
          <Field
            as={Checkbox}
            name="isDefault"
            color="gray.500"
            isChecked={values.isDefault}
          >
            Make this my default payment method
          </Field>
        </FormControl>
      )}
    </>
  )
}

export default CreditCardDetails
