import { Stack, Text } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { calcResourcesPrices } from '../../../../helpers/billing'
import { formatCurrency } from '../../../../helpers/currency'
import { useConfigs } from '../../../shared/hooks'
import { Card } from '../../../shared/Cards'
import Price from '../../../shared/Price'

const PriceCard = ({ hasAvaliableSize }) => {
  const { values } = useFormikContext()
  const { configs, isLoadingConfigs, isErrorConfigs } = useConfigs({})

  if (isLoadingConfigs || isErrorConfigs) {
    return null
  }

  const { price, discount, totalPrice } = calcResourcesPrices({
    types: configs?.filesystemTypes,
    typeSlug: 'vast',
    configSlug: `vast_filesystem`,
    storage: values.size
  })

  const items = [
    { label: 'Price', value: `${formatCurrency(price)}/GiB/month` },
    { label: 'Filesystem size', value: `${values.size} GiB` },
    ...(discount ? [{ label: 'Discount', value: `${discount}% off` }] : []),
    {
      label: 'Total cost (excl. VAT)',
      value: hasAvaliableSize ? `${formatCurrency(totalPrice)}/month` : '...'
    }
  ]

  return (
    <Card as={Stack} spacing={8} p={7}>
      <Text size="xs" color="gray.500" textAlign="justify">
        Billed on-demand once the filesystem is created and until the filesystem
        is deleted. Charged hourly.
      </Text>
      <Price items={items} />
    </Card>
  )
}

export default PriceCard
