import { Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { SkeletonTable } from '../../../shared/Skeletons'
import {
  INITIAL_PAGE,
  TableCell,
  TableError,
  TablePagination
} from '../../../shared/Table'
import {
  useAccountSnapshots,
  useHasWriteProjectPermissions,
  useOrgSnapshots
} from '../../../shared/hooks'
import TableRow from './table-row'

const List = () => {
  const { orgId, projectId } = useParams()
  const [page, setPage] = useState(INITIAL_PAGE)
  const hasWriteProjectPermissions = orgId
    ? useHasWriteProjectPermissions({
        projectId,
        orgId
      })
    : true
  const { snapshots, pagination, isLoadingSnapshots, isErrorSnapshots } =
    orgId && projectId
      ? useOrgSnapshots({ orgId, projectId, page, isPolling: true })
      : useAccountSnapshots({ page, isPolling: true })
  const isTableEmpty = snapshots?.length === 0

  return (
    <>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th w="25%">Name</Th>
              <Th w="15%">Location</Th>
              <Th w="10%">Source</Th>
              <Th w="10%">Image</Th>
              <Th w="10%">Image Size</Th>
              <Th w="10%">Instances</Th>
              <Th w="10%">Created</Th>
              <Th w="5%" />
              {hasWriteProjectPermissions && <Th w="5%" />}
            </Tr>
          </Thead>
          {isLoadingSnapshots && <SkeletonTable rows={10} columns={9} />}
          {isErrorSnapshots ? (
            <TableError>Something went wrong loading the snapshots.</TableError>
          ) : (
            <Tbody>
              {isTableEmpty ? (
                <TableCell>There are no Snapshots yet.</TableCell>
              ) : (
                snapshots?.map((snapshot) => (
                  <TableRow
                    key={snapshot.id}
                    snapshot={snapshot}
                    hasWriteProjectPermissions={hasWriteProjectPermissions}
                  />
                ))
              )}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      {!isErrorSnapshots && (
        <TablePagination
          page={page}
          setPage={setPage}
          totalCount={pagination?.totalCount}
        />
      )}
    </>
  )
}

export default List
