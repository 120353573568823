import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { useAccountBilling, useOrgBilling } from '../../../../shared/hooks'
import SupportError from '../../../../Errors/SupportError'
import { BillingDetailsForm } from '../../../../shared/BillingDetailsForm'
import { DEFAULT_VAT_TYPE } from '../../../../../constants/billing'

const Error = () => (
  <SupportError>Something went wrong loading the billing details.</SupportError>
)

export const DetailsModal = ({ disclosure }) => {
  const { orgId } = useParams()
  const { billing, isErrorBilling } = orgId
    ? useOrgBilling({ orgId })
    : useAccountBilling()
  const { isOpen, onClose } = disclosure

  const initialValues = {
    email: billing?.email || '',
    name: billing?.name || '',
    companyName: billing?.companyName || '',
    vat: billing?.vat || '',
    vatType: billing?.vatType || DEFAULT_VAT_TYPE,
    phone: billing?.phone || '',
    address: billing?.address || '',
    city: billing?.city || '',
    postalCode: billing?.postalCode || '',
    countryCode: billing?.countryCode || '',
    addBillingAddress: true
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Heading size="xl" mb={8} textAlign="center">
            Edit billing details
          </Heading>
          {isErrorBilling ? (
            <Error />
          ) : (
            <BillingDetailsForm
              orgId={orgId}
              initialValues={initialValues}
              onSuccess={onClose}
              onCancel={onClose}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
