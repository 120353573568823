import { useParams } from 'react-router-dom'
import { Stack, Heading } from '@chakra-ui/react'
import { ReturnLink } from '../../../shared/Links'
import { withOrgPathFallback } from '../../../../router'
import routes from '../../../../constants/routes'

const CommunityInstancesView = () => {
  const { communityInstanceId } = useParams()

  return (
    <Stack spacing={2}>
      <ReturnLink
        to={withOrgPathFallback(routes.dashboard.communityInstances.index)}
      >
        Community Instances
      </ReturnLink>
      <Heading as="h1" size="4xl" noOfLines={1} maxWidth="600px">
        {communityInstanceId}
      </Heading>
    </Stack>
  )
}

export default CommunityInstancesView
