import { Link, Text, Stack } from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'
import routes from '../../constants/routes'
import { withOrgPathFallback } from '../../router'

export function isUserValidToCreateResources({
  account,
  billing,
  quota = false,
  isVip
}) {
  const isBillingDetailsMissing = billing?.creditCards?.length === 0
  const isPhoneNumberMissing = !account?.phoneNumber
  const isRequestQuotaExceeded = quota?.lastRequest

  if (isVip) {
    return !isRequestQuotaExceeded
  }

  return (
    !isBillingDetailsMissing && !isPhoneNumberMissing && !isRequestQuotaExceeded
  )
}

export const ValidationErrors = ({
  account,
  billing,
  quota = false,
  isVip
}) => {
  const isBillingDetailsMissing = billing?.creditCards?.length === 0
  const isPhoneNumberMissing = !account?.phoneNumber
  const isRequestQuotaExceeded = quota?.lastRequest

  if (isVip) {
    return isRequestQuotaExceeded ? <QuotaError /> : null
  }

  return (
    <Stack spacing={0}>
      {isBillingDetailsMissing && <CreditCardError />}
      {isPhoneNumberMissing && <PhoneError />}
      {isRequestQuotaExceeded && <QuotaError />}
    </Stack>
  )
}

export const CreditCardError = () => (
  <Text mt={1} color="red.500">
    {`*No billing details, set up payment `}
    <Link
      as={ReactLink}
      to={withOrgPathFallback(routes.dashboard.billing)}
      colorScheme="red"
      variant="underlined"
    >
      here
    </Link>
  </Text>
)

export const PhoneError = () => (
  <Text mt={1} color="red.500">
    {`*Verify phone number `}
    <Link
      as={ReactLink}
      to={`${withOrgPathFallback(
        routes.dashboard.accountSettings
      )}#verifyPhoneNumber`}
      colorScheme="red"
      variant="underlined"
    >
      here
    </Link>
  </Text>
)

export const QuotaError = () => (
  <Text mt={1} color="red.500">
    *You will not be able to complete a new request until your previous request
    has been processed. It takes approximately 3 business days.
  </Text>
)
