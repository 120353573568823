import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  Stack,
  Text
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import { Link as ReactLink, useParams } from 'react-router-dom'
import { mutate } from 'swr'
import * as yup from 'yup'
import { AccountVolumes, OrgVolumes } from '../../../../api/volumes'
import routes from '../../../../constants/routes'
import { toastMessages } from '../../../../constants/toast-messages'
import { withOrgPathFallback } from '../../../../router'
import Asterisk from '../../../shared/Asterisk'
import { useToast } from '../../../shared/Toast'
import {
  useAccountInstancesAll,
  useOrgInstancesAll
} from '../../../shared/hooks'

const validationSchema = yup.object().shape({
  instance: yup.string().required('Instance is required')
})

const initialValues = {
  instance: ''
}

export const AttachModal = ({ disclosure, volume }) => {
  const { orgId, projectId } = useParams()
  const toast = useToast()
  const { isOpen, onClose } = disclosure
  const { id: volumeId, region } = volume

  const onSubmit = async (values, { setSubmitting }) => {
    const { instance: instanceId } = values
    setSubmitting(true)
    try {
      if (orgId) {
        await OrgVolumes.attachById({
          orgId,
          projectId,
          volumeId,
          instanceId
        })
        await mutate(`/projects/${projectId}/volumes/${volumeId}?org=${orgId}`)
      } else {
        await AccountVolumes.attachById({
          volumeId,
          instanceId
        })
        await mutate(`/volumes/${volumeId}`)
      }
      toast({
        status: 'success',
        message: toastMessages.saved
      })
      onClose()
    } catch {
      toast({
        status: 'error',
        message: toastMessages.notSaved
      })
    } finally {
      setSubmitting(false)
    }
  }

  const { values, handleChange, errors, handleSubmit, isSubmitting } =
    useFormik({
      validationSchema,
      initialValues,
      onSubmit
    })

  const { instances, isLoading, isError } =
    orgId && projectId
      ? useOrgInstancesAll({
          orgId,
          projectId,
          region,
          shouldFetch: isOpen
        })
      : useAccountInstancesAll({
          region,
          shouldFetch: isOpen
        })

  const noInstanceExist = instances?.length === 0

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap="8" as="form" onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <Heading size="xl" textAlign="center">
                Attach to Instance
              </Heading>
              <Text
                size="md"
                textAlign="center"
                color="gray.500"
                fontWeight="normal"
              >
                This volume can only be attached to one instance at a time.
              </Text>
            </Stack>
            <FormControl isInvalid={errors.instance}>
              <FormLabel htmlFor="instance">
                <Asterisk />
                Attach Volume to Instance
              </FormLabel>
              <Select
                id="instance"
                name="instance"
                placeholder="Select"
                isDisabled={noInstanceExist || isLoading || isError}
                value={values.instance}
                onChange={handleChange}
              >
                {instances?.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </Select>
              {noInstanceExist && (
                <FormHelperText>
                  There are no instances. Create instance{' '}
                  <Link
                    colorScheme="offBlack"
                    as={ReactLink}
                    to={withOrgPathFallback(routes.dashboard.instances.create)}
                  >
                    here
                  </Link>
                  .
                </FormHelperText>
              )}
              <FormErrorMessage>{errors.instance}</FormErrorMessage>
            </FormControl>
            <Stack spacing={4}>
              <Button
                type="submit"
                isDisabled={noInstanceExist}
                isLoading={isLoading || isSubmitting}
              >
                Attach
              </Button>
              <Button
                colorScheme="offBlack"
                variant="outline"
                isDisabled={isLoading || isSubmitting}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Stack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
