import {
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'
import routes from '../../../../../../constants/routes'
import { formatDate } from '../../../../../../helpers/date'
import { withOrgPath } from '../../../../../../router'
import { DefaultBadge, LegacyBadge } from '../../../../../shared/Badge'
import { Bin, Dots, Settings, Star } from '../../../../../shared/Icons'
import { MakeDefaultModal, DeleteModal } from '../modals'
import { TdTruncated } from '../../../../../shared/Table'
import {
  useIsRole,
  useOrganization
} from '../../../../../shared/hooks/organization'
import { useProjectRole } from '../../../../../shared/hooks/project'
import { useSwitchProject } from '../../../../../shared/hooks/project/useSwitchProject'

const TableRow = ({ project }) => {
  const { organization } = useOrganization()
  const accountIsAdmin = useIsRole(organization, 'ADMIN')
  const role = useProjectRole(project)
  const deleteProjectDisclosure = useDisclosure()
  const makeDefaultProjectDisclosure = useDisclosure()
  const switchProject = useSwitchProject()

  return (
    <Tr>
      <TdTruncated
        onClick={() =>
          switchProject(project.id, routes.dashboard.projectSettings)
        }
      >
        <Link
          colorScheme="gray"
          as={ReactLink}
          to={withOrgPath(
            routes.dashboard.projectSettings,
            organization.id,
            project.id
          )}
        >
          {project.name}
        </Link>
        {project.isDefault && <DefaultBadge ml={2} />}
        {project.isLegacy && <LegacyBadge ml={2} />}
      </TdTruncated>
      <Td textTransform="capitalize">{role}</Td>
      <Td>{formatDate(project.createdAt)}</Td>
      <Td>{formatDate(project.updatedAt)}</Td>
      <Td isNumeric>
        <Menu variant="table" autoSelect={false}>
          <MenuButton variant="icon" as={IconButton} icon={<Dots />} />
          <MenuList>
            <MenuItem
              onClick={() =>
                switchProject(project.id, routes.dashboard.projectSettings)
              }
            >
              <Settings mr={2.5} />
              Project settings
            </MenuItem>
            {accountIsAdmin && !project.isDefault && (
              <>
                <MenuItem onClick={makeDefaultProjectDisclosure.onOpen}>
                  <Star boxSize={5} mr={2.5} />
                  Make default
                </MenuItem>
                <MakeDefaultModal
                  disclosure={makeDefaultProjectDisclosure}
                  projectId={project.id}
                />
              </>
            )}
            {accountIsAdmin && !project.isDefault && (
              <>
                <MenuItem onClick={deleteProjectDisclosure.onOpen}>
                  <Bin mr={2.5} />
                  Delete Project
                </MenuItem>
                <DeleteModal
                  disclosure={deleteProjectDisclosure}
                  projectId={project.id}
                />
              </>
            )}
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  )
}

export default TableRow
