import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Flex,
  Heading,
  Text,
  Switch,
  Button,
  Skeleton
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { mutate } from 'swr'
import { toastMessages } from '../../../../../constants/toast-messages'
import { useToast } from '../../../../shared/Toast'
import { Card } from '../../../../shared/Cards'
import { Error } from '../../../../shared/Error'
import {
  useOrgBillingNotification,
  useAccountBillingNotification
} from '../../../../shared/hooks'
import { OrgBilling, AccountBilling } from '../../../../../api/billing'
import { BalanceField } from './fields'

const validationSchema = yup.object().shape({
  isChecked: yup.boolean(),
  minBalance: yup.number().when('isChecked', {
    is: true,
    then: yup
      .number('The minimum balance must be a number')
      .min(1, 'The minimum balance must be greater than 0')
      .required('The minimum balance is required')
  })
})

export const NotificationModal = ({ disclosure }) => {
  const { isOpen, onClose } = disclosure
  const { orgId } = useParams()
  const toast = useToast()

  const { notification, isLoadingNotification, isErrorNotification } = orgId
    ? useOrgBillingNotification({ orgId, shouldFetch: isOpen })
    : useAccountBillingNotification({ shouldFetch: isOpen })

  const initialValues = {
    isChecked: !!notification,
    minBalance: notification?.minBalance || 1
  }

  const onSubmit = async (values, { setSubmitting }) => {
    const { isChecked, minBalance } = values
    const payload = {
      min_balance: minBalance
    }
    setSubmitting(true)

    try {
      if (orgId) {
        isChecked
          ? await OrgBilling.updateNotification({ orgId, payload })
          : await OrgBilling.deleteNotification({ orgId })

        await mutate(`/billing/notification?org=${orgId}`)
      } else {
        isChecked
          ? await AccountBilling.updateNotification({ payload })
          : await AccountBilling.deleteNotification()

        await mutate('/billing/notification')
      }

      toast({
        status: 'success',
        message: toastMessages.saved
      })
      onClose()
    } catch {
      toast({
        status: 'error',
        message: toastMessages.notSaved
      })
    } finally {
      setSubmitting(false)
    }
  }

  const isDisabled = (values) =>
    initialValues.isChecked === values.isChecked &&
    (!values.isChecked || initialValues.minBalance === values.minBalance)

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ values, handleChange, isSubmitting }) => (
              <Stack spacing={8} as={Form}>
                <Heading size="xl" textAlign="center">
                  Account Balance
                </Heading>
                {isLoadingNotification ? (
                  <Skeleton h="105px" />
                ) : isErrorNotification ? (
                  <Error />
                ) : (
                  <Card as={Flex} gap={2} p={5}>
                    <Stack spacing={3}>
                      <Stack spacing={0}>
                        <Text fontWeight="semibold">
                          Account balance threshold
                        </Text>
                        <Text color="gray.500">
                          We will email you when your Account balance goes below
                          a specified amount.
                        </Text>
                      </Stack>
                      {values.isChecked && (
                        <BalanceField
                          name="minBalance"
                          label="Define the threshold value:"
                        />
                      )}
                    </Stack>
                    <Switch
                      name="isChecked"
                      isChecked={values.isChecked}
                      onChange={handleChange}
                    />
                  </Card>
                )}
                <Stack spacing={4}>
                  <Button
                    type="submit"
                    isLoading={isSubmitting}
                    isDisabled={isDisabled(values)}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outline"
                    colorScheme="offBlack"
                    isDisabled={isSubmitting}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Stack>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
