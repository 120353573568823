import { shadowsOutline } from '../../constants/styles'

const Input = {
  parts: ['field'],
  sizes: {
    lg: {
      field: {
        borderRadius: '25px'
      }
    },
    md: {
      field: {
        borderRadius: '20px'
      }
    },
    sm: {
      field: {
        borderRadius: '20px'
      }
    },
    xs: {
      field: {
        borderRadius: '20px'
      }
    }
  },
  baseStyle: ({ colorScheme }) => ({
    field: {
      color: `${colorScheme}.700`,
      _hover: {
        borderColor: `${colorScheme}.400`
      },
      _placeholder: {
        color: `${colorScheme}.300`
      }
    }
  }),
  variants: {
    outline: ({ colorScheme }) => ({
      field: {
        borderColor: `${colorScheme}.300`,
        _hover: {
          borderColor: `${colorScheme}.400`
        },
        _focus: {
          boxShadow: shadowsOutline
        }
      },
      addon: {
        borderRadius: '20px',
        borderColor: `${colorScheme}.300`,
        color: `${colorScheme}.500`
      }
    }),
    filled: ({ colorScheme }) => ({
      field: {
        border: '1px solid',
        _hover: {
          borderColor: `${colorScheme}.200`,
          bg: `${colorScheme}.200`
        },
        _invalid: {
          boxShadow: 'none',
          bg: 'transparent'
        }
      }
    }),
    unstyled: () => ({
      field: {
        borderRadius: 0
      }
    })
  },
  defaultProps: {
    size: 'md',
    colorScheme: 'gray'
  }
}

export default Input
