import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { useField } from 'formik'
import { Select } from 'chakra-react-select'
import { SelectComponents } from '../../../theme/components/Select'
import Asterisk from '../Asterisk'

export const SelectField = ({
  isRequired = false,
  label,
  name,
  options,
  onUpdate,
  width = '100%',
  ...props
}) => {
  const [field, meta, helpers] = useField(name)

  const handleSelect = (option) => {
    helpers.setValue(option)
    if (onUpdate) onUpdate(option)
  }

  return (
    <FormControl isInvalid={meta.error && meta.touched} width={width}>
      <FormLabel>
        {isRequired && <Asterisk />}
        {label}
      </FormLabel>
      <Select
        isSearchable={false}
        chakraStyles={SelectComponents}
        options={options}
        value={field.value}
        onChange={handleSelect}
        {...props}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}
